import React from 'react';

import './Switch.scss';

const Switch = (props) => {
  return (
    <label className="Switch">
      <input type="checkbox" {...props} />
      <span className="Slider"></span>
    </label>
  )
}

export default Switch;
