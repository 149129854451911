import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import LessonAnswerButtons from '../../Composed/LessonAnswerButtons/LessonAnswerButtons';
import MultiButtonChoiceContainer from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceContainer';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import AudioProgressBox from '../../Basic/AudioProgressBox/AudioProgressBox';
import VideoBox from '../../Basic/VideoBox/VideoBox';

import { hasAnsweredAll } from '../../../Util/Helper/QuestionAnswerUtil';
import { instructionsContent } from '../../../Util/Helper/GenericUtil';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';
import { continueLessonLink } from '../../../Util/Helper/CourseUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './ActivityPage.scss';

class ActivityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSectionIndex: 0,
      slide: true,
      displayBookmarkSection: false,
      displayCommentButton: false,
      loading: true,
      submitting: false,
      error: ''
    };
  }

  componentDidMount() {
    const {
      course, lesson, lessons,
      clearQuizProgresses, clearQuizStates, fetchActivity,
      receiveSectionIndex,
    } = this.props;

    clearQuizProgresses();
    clearQuizStates();
    fetchActivity(lesson.id).then(res => {
      if (res.payload?.status === 'archive') {
        this.props.history.push(continueLessonLink(course, lesson, lesson.activity_id, null, null, lessons));
      } else {
        this.setState({ loading: false });
      }
    });
    this.setState({ currentSectionIndex: 0 });
    receiveSectionIndex(0);
  }

  componentDidUpdate(prevProps) {
    const {
      course, lesson, lessonId, lessons,
      clearQuizProgresses, clearQuizStates, fetchActivity,
      receiveSectionIndex,
    } = this.props;

    if (prevProps.lessonId !== lessonId) {
      this.setState({ loading: true });
      clearQuizProgresses();
      clearQuizStates();
      fetchActivity(lesson.id).then(res => {
        if (res.payload?.status === 'archive') {
          this.props.history.push(continueLessonLink(course, lesson, lesson.activity_id, null, null, lessons));
        } else {
          this.setState({ loading: false });
        }
      });
      this.setState({ slide: true, currentSectionIndex: 0 });
      receiveSectionIndex(0);
    }
  }

  componentWillUnmount() {
    this.props.clearSectionIndex();
  }

  handleDirectionIsRight = () => {
    const {
      currentUser,
      activities,
      history,
      courseId,
      lesson,
      lessons,
      course,
      receiveSectionIndex,
      submitActivityResponse,
      quizProgress
    } = this.props;

    const { currentSectionIndex } = this.state;
    const activity = activities[lesson.activity_id];
    const currentSection = activity.sections[currentSectionIndex];

    if (!hasAnsweredAll(currentSection, null, true, quizProgress)) {
      this.setState({ error: 'Please answer the question(s) to proceed.' });
      let scrollElement = document.getElementById('Answered-All-Error-Container');
      window.scrollTo({ top: (scrollElement.offsetTop - 100), behavior: 'smooth' });
      return null;
    }

    if (currentSectionIndex === (activity.sections.length - 1)) {
      const activityResponseData = {
        user_id: currentUser.id,
        activity_id: activity.id,
        activity_data: quizProgress
      }

      this.setState({ submitting: true })
      submitActivityResponse(activityResponseData).then(res => {
        receiveSectionIndex(0);
        this.setState({ submitting: false, error: '', currentSectionIndex: 0, slide: false });
        history.push(continueLessonLink(course, lesson, activity.id, null, null, lessons))
      });
    } else {
      gaEvent('next_slide');
      receiveSectionIndex(currentSectionIndex + 1);
      this.setState({ currentSectionIndex: currentSectionIndex + 1, error: '' });
    }
  }

  handleDirectionIsLeft = () => {
    const {
      currentUser,
      activities,
      history,
      courseId,
      lesson,
      receiveSectionIndex,
      submitActivityResponse,
      quizProgress
    } = this.props;

    const { currentSectionIndex } = this.state;
    const activity = activities[lesson.activity_id];
    const currentSection = activity.sections[currentSectionIndex];

    if (currentSectionIndex === 0) {
      history.push(`/learn/courses/${courseId}/lessons`);
    } else {
      gaEvent('previous_slide');
      receiveSectionIndex(currentSectionIndex - 1);
      this.setState({ currentSectionIndex: currentSectionIndex - 1, error: '' });
    }
  }

  setSectionInstructionAudio = section => () => {
    if (!section)
      return null;

    document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = '';

    let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];

    if (listened.includes(section.id))
      return null;

    listened.push(section.id)
    window.localStorage.setItem('sectionInstructionAudios', JSON.stringify(listened));
  }

  handleInstructions = () => {
    const { activities, lesson, course } = this.props;
    const { currentSectionIndex } = this.state;

    const activity = activities[lesson.activity_id];
    const currentSection = activity.sections[currentSectionIndex];

    let finalInstructions = '';

    if (course?.course_type?.name === 'Compliance') {
      finalInstructions = currentSection.instructions;
    } else {
      finalInstructions = currentSection.instructions ? (`<p>${currentSection.position}. ${currentSection.name}</p><br>${currentSection.instructions}`) : (`${currentSection.position}. ${currentSection.name}`);
    }

    return (
      finalInstructions && instructionsContent(finalInstructions) &&
      <div className="LearningPage-Instructions">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(finalInstructions) }}
        />
      </div>
    )
  }

  handleRender() {
    const { course, activities, lesson, recordQuizAnswer, quizProgress } = this.props;
    const { currentSectionIndex } = this.state;

    const activity = activities[lesson.activity_id];
    const currentSection = activity.sections[currentSectionIndex];

    const newProps = {
      lessonId: lesson.id,
      section: currentSection,
      questions: currentSection.questions,
      recordQuizAnswer,
      quizProgress,
      learning: true,
      isComplianceCourse: (course?.course_type?.name === 'Compliance')
    };

    let component;

    switch (currentSection.section_type) {
      case 'multiple_choice':
        component = <MultiButtonChoiceContainer section={currentSection} newProps={newProps} />
        break;
      default:
        component = null;
    }

    if ((currentSection.text_contents.length === 0) && !currentSection.audio_url && !currentSection.video_url && !component) {
      return '';
    }

    return (
      <div className={`ActivityPage-Content ${currentSection.section_type}`}>
        {
          (currentSection.text_contents.length > 0) &&
          <div className="QuizPage-TextBox">
            <TextContentBox { ...newProps } />
          </div>
        }

        {
          currentSection.audio_url &&
          <div className="QuizPage-AudioPlayButton">
            <AudioPlayButton
              className="AudioPlayButton-QuizPage"
              src={currentSection.audio_url}
            />
          </div>
        }

        {
          currentSection.video_url &&
          <div className="LearningPage-VideoBox">
            <VideoBox src={currentSection.video_url} />
          </div>
        }

        { component }
      </div>
    );
  }

  render () {
    const { courseId, lessonId, lesson, activities } = this.props;
    const { loading, submitting, slide, currentSectionIndex, error } = this.state;

    if (loading) {
      return <MirrorLoader message="Loading, Hang on!" />
    }

    const activity = activities[lesson?.activity_id];

    if (!activity?.sections || !activity.sections.length) {
      return <div className="ActivityPage" />;
    }

    const currentSection = activity.sections[currentSectionIndex];

    return (
      <div className="ActivityPage">
        {submitting ? <MirrorLoader message={'Saving Answer(s)'} /> : ''}

        <div className="LearningPage-LessonAnswerButtons_container">
          {
            (slide) && (
              <LessonAnswerButtons
                currentUser={this.props.currentUser}
                activity={activity}
                currentSection={currentSection}
                displayBookmarkSection={this.state.displayBookmarkSection}
                displayLeftClick={true}
                leftClickHandler={this.handleDirectionIsLeft}
                displayRightClick={true}
                rightClickHandler={this.handleDirectionIsRight}
                displayCommentButton={this.state.displayCommentButton}
              />
            )
          }

          { slide && this.handleInstructions() }

          <div id="Answered-All-Error-Container">
            {
              error && <div id="Answered-All-Error">{error}</div>
            }
          </div>

          {
            slide && currentSection?.instruction_audio_url &&
            <AudioProgressBox
              src={currentSection.instruction_audio_url}
              onEndHandler={this.setSectionInstructionAudio(currentSection)}
            />
          }
        </div>
        {
          this.handleRender()
        }
      </div>
    )
  }
}

ActivityPage.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  course: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  lessonId: PropTypes.string.isRequired,
  lesson: PropTypes.shape({
    activity_id: PropTypes.number,
  }).isRequired,
  lessons: PropTypes.objectOf(PropTypes.object).isRequired,
  quizProgress: PropTypes.objectOf(PropTypes.object).isRequired,
  activities: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchActivity: PropTypes.func.isRequired,
  recordQuizAnswer: PropTypes.func.isRequired,
  clearQuizProgresses: PropTypes.func.isRequired,
  clearSectionIndex: PropTypes.func.isRequired,
  receiveSectionIndex: PropTypes.func.isRequired,
  submitActivityResponse: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ActivityPage;
