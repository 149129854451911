import { connect } from 'react-redux';
import CoursesDashboard from './CoursesDashboard';
import { clearLessons } from '../../../Actions/LessonActions';
import { clearCourseId } from '../../../Actions/NavigationActions';
import { fetchCourses, fetchCourseProgresses } from '../../../Actions/DashboardActions';

const mapStateToProps = (state) => ({
    courses: state.entities.courses,
    courseProgresses: state.statistics.courseProgresses,
    subscriptions: state.access.subscriptions,
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace,
    flippers: state.access.flippers,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourses: () => dispatch(fetchCourses()),
    fetchCourseProgresses: () => dispatch(fetchCourseProgresses()),
    clearLessons: () => dispatch(clearLessons()),
    clearCourseId: () => dispatch(clearCourseId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesDashboard);
