import { connect } from 'react-redux';

import NotificationList from './NotificationList';

import { clearUserNotifications, fetchUserNotifications } from '../../../../Actions/UserNotificationActions';

const mapStateToProps = (state) => ({
  userNotifications: state.notifications.userNotifications,
});

const mapDispatchToProps = (dispatch) => ({
  clearUserNotifications: () => dispatch(clearUserNotifications()),
  fetchUserNotifications: (queryString) => dispatch(fetchUserNotifications(queryString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
