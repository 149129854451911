import { connect } from 'react-redux';

import CanopyAdminDashboard 
  from './CanopyAdminDashboard';
import { fetchInstitutions }
  from '../../../Actions/InstitutionActions';


const mapStateToProps = (state) => {
    return {
      institutions: state.admin.institutions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      fetchInstitutions: () => dispatch(fetchInstitutions()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanopyAdminDashboard);
