import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageBox from '../../../Basic/ImageBox/ImageBox';

import './ScoreCardCongratulation.scss';

const ScoreCardCongratulation = ({ user }) => {
  const generateScoreCardIdFromEmail = email => (
    Math.abs(
      email.split('').reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0)
    )
  )

  return (
    <div className="Score-Card-Congratulation">
      <p className="Congratulation-Heading">Congratulations on completing the Canopy Credential proficiency assessment!</p>
      <div className="Congratulation-Body">
        <div className="Text-Container">
          <div className="Star-Watermark">
            <div id="watermark-badge" />
            <div id="inset-watermark-badge" />
          </div>
          <div className="congrats-text">
            <p>Your eligibility to function as "Qualified Bilingual Staff (QBS)" is determined by the institution where you work. Canopy provides a toolset for hospitals and other care delivery organizations to determine the applicable cutoff level on the Canopy Scale. Please consult with your institution for your QBS status.</p>
            <ul>
              <li>
                <p>
                  This scorecard can be accessed through your <Link to="/account/profile">Canopy Profile</Link>.
                </p>
              </li>
              <li>
                <p>
                  <>
                    {'To interpret the scores using the CEFR, ILR or ACTFL scale, consult the '}
                    <a href="https://www.withcanopy.com/canopy-toolkit/" target="_blank" rel="noopener noreferrer">
                      Canopy Toolkit
                    </a>
                    {' for Qualified Bilingual Proficiency.'}
                  </>
                </p>
              </li>
              <li>
                <p>
                  We recommend test-takers re-certify their proficiency every two to three years, depending on efforts and progress made.
                </p>
              </li>
            </ul>
            <p className="Disclaimer-Paragraph">
              Disclaimer: The results depicted on this scorecard represent the test-taker's performance in a simulated oral proficiency
              interview on the date of the assessment, and do not guarantee or predict any quantitative or qualitative
              performance in an actual healthcare setting and/or with an actual patient.
            </p>
          </div>
        </div>

        <div className="Certificate-Ribbon">
          <ImageBox className="Image" src="canopy-ribbon.png" />
          <div className="ribbon-text">
            <p>Certificate ID</p>
            <p className="id-number">{generateScoreCardIdFromEmail(user.email)}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

ScoreCardCongratulation.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    birthdate: PropTypes.string,
  }).isRequired,
}

export default ScoreCardCongratulation;
