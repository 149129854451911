import './index.css';
import './Util/PolyfillUtil';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { throttle } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './Components/Root';
import * as serviceWorker from './serviceWorker';
import configureStore from './Store/Store';
import axios from './Util/AxiosUtil';
import EnvironmentUtil from './Util/EnvironmentUtil';
import { loadState, saveState } from './Util/StatePersistUtil';

const sessionId = axios.defaults.headers.common['X-Session-ID'];

const {
    REACT_APP_SENTRY_DSN
} = process.env;

Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [EnvironmentUtil().baseDomain],
            beforeNavigate: context => ({
                ...context,
                name: window.location.hash,
            }),
        }),
    ],
    tracesSampleRate: 1.0, // decimal range 0 - 1.0, percentage of events to send
});

Sentry.configureScope((scope) => {
    scope.setTag('session_id', sessionId);
    scope.setTag('application', 'canopy-learnv2-react-app');
});

document.addEventListener('DOMContentLoaded', () => {
    const persistedState = loadState();
    const store = configureStore(persistedState);

    store.subscribe(throttle(() => {
        saveState({
            entities: store.getState().entities,
            session: store.getState().session,
            navigation: store.getState().navigation,
        });
    }, 1000));

    // window.onbeforeunload = () => {
    //   return "Data will be lost if you leave the page, are you sure?";
    // };
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<Root store={store} />);
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
