import React, { Component } from 'react';

import CreateEditActivity from '../../Forms/CreateEditActivity/CreateEditActivity';
import CreateEditSection from '../../Forms/CreateEditSection/CreateEditSection';
import SectionSummaryBox from '../../Composed/SectionSummaryBox/SectionSummaryBoxContainer';

import Modal from '../../Composed/Modal/Modal';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { COLORS } from '../../../Style/Style';

import './CreationActivityDashboard.scss'

class CreationActivityDashboard extends Component {
  componentDidMount() {
    this.props.fetchActivity(this.props.lesson.id);
  }

  render() {
    const { course, lesson, activities, editActivity, createSection } = this.props;
    const activity = activities[lesson.activity_id];

    if (!activity.sections) {
      return <div className="CreationDashboards"></div>
    }

    return (
      <div className="CreationDashboards">
        <div className="CreationDashboards-Menu">
          <TextBox
            text={activity.name}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Edit Activity"
            form={
              close =>
              <CreateEditActivity
                activity={activity}
                submitActivity={editActivity}
                closeModal={close}
              />
            }
          >
            {
              open =>
              <div className="CreationDashboards-Menu-Button">
                <ButtonBox
                  className="CreationDashboards-ButtonBox"
                  text="Edit Activity"
                  onClick={open}
                />
              </div>
            }
          </Modal>
        </div>

        <div className="CreationDashboards-DescriptionBox">
        </div>

        <div className="CreationDashboards-Menu">
          <TextBox
            text="Sections"
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Add A Section"
            form ={
              close =>
              <CreateEditSection
                activity={activity}
                submitSection={createSection}
                closeModal={close}
                isActivity
              />
            }
          >
            {
              open =>
              <div className="CreationDashboards-Menu-Button">
                <ButtonBox
                  className="CreationDashboards-ButtonBox"
                  text="Add A Section"
                  onClick={open}
                />
              </div>
            }
          </Modal>
        </div>

        <SectionSummaryBox activityId={activity.id} course={course} />
      </div>
    );
  }
}

export default CreationActivityDashboard;
