import React
  from 'react';

import './MatchingBoxMini.css';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import FunctionBox
  from '../../Composed/FunctionBox/FunctionBox';
import { COLORS }
  from '../../../Style/Style';

/**
* @class - MatchingBoxMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const MatchingBoxMini = ({ question, creation, editQuestion, deleteQuestion,
    uploadAudio }) => {

  return (
    <div className='MatchingBoxMini'>
      <div
        className="QuestionMini-QuestionBox">
        <div
          className="QuestionMini-QuestionBox-Position"
          style={
            question.answers[0] ?
              {
                backgroundColor: COLORS.green
              }
              :
              {
                backgroundColor: COLORS.redPink
              }
          }>
          <TextBox
            text={question.position}
            style={{
              color: COLORS.Blue
            }} />
        </div>


        <div
          className="QuestionMini-QuestionBox-Question-Split">
          <QuestionBox question={question} creation={creation}>
            <TextBox
              text={question.text}
              style={{
                color: COLORS.Blue
              }} />
          </QuestionBox>

          <TextBox
            text={question.answers[0].text}
            style={{
              color: COLORS.Blue
            }} />
        </div>

        {
          creation &&
          <FunctionBox
            submitMedia={uploadAudio}
            model="question"
            entity={question}
            editEntity={editQuestion}
            deleteEntity={deleteQuestion}
          />
        }
      </div>
    </div>
  );
};

export default MatchingBoxMini;
