import React, { useState, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Filters from '../Filters/Filters';
import Pagination from '../../../Composed/Pagination/Pagination';
import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../../Basic/InputBox/InputBox';
import CheckBox from '../../../Basic/CheckBox/CheckBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { clearRosterManagement, fetchRosterManagement } from '../../../../Actions/RosterManagementActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';

import './UserRosterManagement.scss';

const mapStateToProps = (state) => ({
  rosterManagements: state.admin.rosterManagement || {},
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearRosterManagement: () => dispatch(clearRosterManagement()),
  fetchRosterManagement: (queryString) => dispatch(fetchRosterManagement(queryString))
});

const LearnRosterManagement = ({ rosterManagements, filters, clearRosterManagement, fetchRosterManagement }) => {
  const productType= 'Learn';

  const [loading, setLoading] = useState(true);
  const [contentWidth, setContentWidth] = useState(0);
  const [bulkActionDrop, setBulkActionDrop] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [triggerSearch, setTriggerSearch] = useState('');
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(10);
  const [selectedWorkplaces, setSelectedWorkplaces] = useState([]);

  useLayoutEffect (() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 500;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    setLoading(true);
    clearRosterManagement();
    fetchRosterManagement(buildQueryString()).then(
      res => {
        setLoading(false);
        setSelectedWorkplaces([]);
      }
    );
  }, [fetchRosterManagement, filters, triggerSearch, page, per]);

  const buildQueryString = () => {
    let queryString = '';

    queryString += hashToQueryString(filters);
    queryString += `&page=${page}&per=${per}`;
    queryString += `&search_filter=${searchFilter}`;
    queryString += `&product_type=${productType}`;

    return queryString;
  }

  const handleWorkplaceSelection = (workplace) => {
    let newselectedWorkplaces = JSON.parse(JSON.stringify(selectedWorkplaces));
    let idx = newselectedWorkplaces.indexOf(workplace.id);

    (idx > -1) ? newselectedWorkplaces.splice(idx, 1) : newselectedWorkplaces.push(workplace.id);

    setSelectedWorkplaces(newselectedWorkplaces);
  }

  const renderData = () => {
    let emptyResult = rosterManagements.totalWorkplaces == 0;

    return (
      <>
        <div className="Actions-Container">
          <div className="Actions">
            <div className="Bulk-Action-And-Per-Page">
              {/*<div className="Bulk-Action" onClick={(e) => setBulkActionDrop(!bulkActionDrop)}>
                <i className="fa fa-gear Icon"></i>
                <span className="Text">Bulk Action</span>
                <span className="ToolTip">▾</span>
                {
                  bulkActionDrop &&
                  <div>
                    <ImageBox src="Rectangle.svg" className="Rectangle-Img" />
                    <div className="Drop-Down">
                      <li className="Temporary-Password">Send Temporary Password</li>
                      <li className="Set-Deadline">Set Deadline</li>
                      <li className="Deactivate-List">Deactivate CanopyLearn Access</li>
                    </div>
                  </div>
                }
              </div>*/}
              <div className="Per-Page-Drop-Down">
                <select
                    className="Records-Per-Page"
                    value={per}
                    onChange={(e) => setPer(e.currentTarget.value)}
                  >
                    <option value="10">Show 10 users</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
              </div>
            </div>
            <div className="SearchBar">
              <InputBox
                className="Input"
                value={searchFilter}
                placeholder="First Name, Last Name, or Email"
                onChange={e => setSearchFilter(e.target.value)}
                onBlur={e => setTriggerSearch(searchFilter)}
                onKeyPress={e => (e.key === 'Enter') && e.currentTarget.blur()}
              />
            </div>
          </div>
          <div className="Invite-Users">
            <Link to="/account/invite_users/new">
              <ButtonBox
                className="Static-Primary-Btn"
                text="Invite new users"
              />
            </Link>
          </div>
        </div>

        <div
          className="Content Scrollable-Table"
          style={{
            width: `${contentWidth}px`
          }}
        >
          {
            emptyResult ? renderEmpty() : (
              <table className="Table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Last Name ▾</th>
                    <th>First Name ▾</th>
                    <th>Department ▾</th>
                    <th>Email ▾</th>
                    <th>Last Level / Lesson ▾</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.values(rosterManagements.workplaces || {}).map((workplace, idx) => (
                      <tr key={`workplace-${idx}`}>
                        <td>
                          <div className="CheckBox-Container">
                            <CheckBox
                              checked={selectedWorkplaces.includes(workplace.id)}
                              onClick={(e) => handleWorkplaceSelection(workplace)}
                            />
                          </div>
                        </td>
                        <td><span className="Name">{workplace.user.last_name}</span></td>
                        <td><span className="Name">{workplace.user.first_name}</span></td>
                        <td>{workplace.department_specialty || 'N/A'}</td>
                        <td>{workplace.user.email}</td>
                        <td>
                          {
                            workplace.seatTimes?.lastLevel
                            ? `Level ${workplace.seatTimes.lastLevel } / ${workplace.seatTimes.lastLessonName}`
                            : 'Not yet started'
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            )
          }
        </div>

        {
          !emptyResult &&
          <Pagination
            totalRecords={rosterManagements.totalWorkplaces}
            page={page}
            setPage={(p) => setPage(p)}
            per={per}
          />
        }
      </>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      No users!
    </div>
  )

  const renderContent = () => {
    if (loading)
      return <Loader />

    return renderData();
  }

  return (
    <div className="B2B-Admin-Roster-Management-Container">
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnRosterManagement);
