import {
  RECEIVE_CANOPY_SUBSCRIPTIONS,
  CLEAR_CANOPY_SUBSCRIPTIONS,
  RECEIVE_CANOPY_SUBSCIPTIONS_ERRORS
} from '../../Actions/CanopySubscriptionActions';

const CanopySubscriptionsReducer = (state = {}, action) => {
  Object.freeze(state); 

  switch (action.type) {
    case RECEIVE_CANOPY_SUBSCRIPTIONS:
      return Object.assign({}, state, action.payload);
    case CLEAR_CANOPY_SUBSCRIPTIONS:
      return {};
    default:
      return state;
  }
}

export default CanopySubscriptionsReducer;
