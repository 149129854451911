import './Confirmed.css';

import React from 'react';
import { connect } from 'react-redux';

import { resendConfirmationEmail } from '../../../Actions/SessionActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  resendConfirmationEmail: () => dispatch(resendConfirmationEmail())
});

const Confirmed = ({ currentUser, resendConfirmationEmail }) => {
  const sendEmail = (e) => {
    e.preventDefault();

    document.getElementsByClassName('EmailSent')[0].innerText = '';

    resendConfirmationEmail().then(res => {
      document.getElementsByClassName('EmailSent')[0].innerText = 'Email has been sent to your email address.';
    });
  };

  return (
    <div>
      <div className="Confirmation-Container container">
        <div className="Confirmation-Alert">
          <p className="Alert">You will need to confirm your email address before continuing to use the site. We sent an email to {currentUser.email} with a link to confirm. If you didn't receive the email, please check your junk or spam folder or request a new email using the button below.</p>
          <button onClick={(e) => sendEmail(e)} className="Button">Resend Confirmation Email</button>
          <div className="EmailSent"></div>
        </div>
      </div>
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmed);
