import axios from '../AxiosUtil';

export const createUser = (user) => {
  return axios.post(`/api/onboarding/users`, { user }).then(
    res => res.data
  )
};

export const fetchUser = (email) => {
  return axios.get(`/api/onboarding/users/fetch?email=${encodeURIComponent(email)}`).then(
    res => res.data
  )
};
