import React, { Component } from 'react';

import './Confirm.css';
import { COLORS } from '../../../Style/Style';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';

class Confirm extends Component {
  state = {
    isOpen: false
  }

  open = () => this.setState({ isOpen: true })

  close = () => this.setState({ isOpen: false })

  render() {
    return (
      <>
        {
          this.state.isOpen &&
          <div className="modal">
            <div className="modal-content-confirmation">
              <div
                className="Confirm">
                <TextBox
                  text={this.props.text}
                  style={{
                    color: `${COLORS.Blue}`,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                  tag="h3"
                />

                <div
                  className="Confirm-ButtonBox-container">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Confirm"
                    onClick={e => {
                      this.props.confirm(e);
                      this.close();
                    }} />
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Cancel"
                    onClick={this.close} />
                </div>
              </div>
            </div>
          </div>
        }

        { this.props.children(this.open) }
      </>
    );
  }
}

export default Confirm;
