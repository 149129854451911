import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import RadioBox from '../../../Basic/RadioBox/RadioBox';
import DashboardFormBoxNoEdit from '../../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import UserRosterUploadProgress from './UserRosterUploadProgress/UserRosterUploadProgress';

import './UserRosterUpload.scss';

const UserRosterUpload = ({
  currentUser,
  groups, clearGroups, fetchGroups,
  progressData, clearRosterManagement, receiveUserRosterUploadInitialState, createUserRosterUpload,
}) => {
  const licenses = Object.values(currentUser.perLicenseSeats);
  const fileRef = useRef();

  const [loading, setLoading] = useState(true);
  const [rosterData, setRosterData] = useState({ licenseId: null, groupId: null, file: null });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    clearRosterManagement();
    clearGroups();
    fetchGroups().then(() => setLoading(false));

    if (licenses.length === 1) {
      setRosterData({ ...rosterData, licenseId: licenses[0].id });
    }
  }, [clearRosterManagement, clearGroups, fetchGroups]);

  const validateForm = () => {
    let errors = {};

    if (!rosterData.licenseId) {
      errors['License'] = 'Please select a license.';
    }

    if (!rosterData.file) {
      errors['Excel'] = 'Please attach a file.';
    }

    return errors;
  }

  const handleUpload = e => {
    e.preventDefault();

    let errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append('user_roster_upload[license_id]', rosterData.licenseId ?? '');
      formData.append('user_roster_upload[group_id]', rosterData.groupId ?? '');
      formData.append('user_roster_upload[file]', rosterData.file);

      receiveUserRosterUploadInitialState({
        started: true, finished: false, event: '', message: '',
        users_new:       { emails: [], name: 'Emails in the uploaded file that do not have an account' },
        user_exist:      { emails: [], name: 'Registrations Already Exist' },
        user_created:    { emails: [], name: 'Successfully Registered' },
        user_failed:     { emails: [], name: 'Failed Registrations' }
      });

      createUserRosterUpload(formData);
    }
  }

  if (loading) {
    return <Loader message="Getting things ready..." />
  }

  return (
    <div className="UserRosterUpload-Container">
      <DashboardFormBoxNoEdit title="UPLOAD ROSTER">
        {
          progressData.started &&
          <UserRosterUploadProgress
            progressData={progressData}
            close={() => {
              fileRef.current.value = '';
              setRosterData({ licenseId: null, groupId: null, file: null });
              clearRosterManagement();
            }}
          />
        }

        <ul className="Instructions list-disc">
          <li>Upload an Excel file that contains information about your institution learners to create their accounts.</li>
          <li>The file should be a valid Excel file.</li>
          <li>The sample file can be downloaded by clicking <a href="https://prod-canopy-railsapi-media-bucket.s3.amazonaws.com/UserRosterUpload.xlsx" className="Download-Sample" target="_blank" rel="noopener noreferrer">here</a>.</li>
        </ul>

        <div className="Error-Container mt-4">
          <ul className="Errors list-disc pl-10">
            {
              Object.values(formErrors).map((formError, idx) => (
                <li key={`form-error-${idx}`} children={formError} />
              ))
            }
          </ul>
        </div>

        <div className="License-Field">
          <label className="Label">License<span className="Required-Asterisk">*</span></label>
          <div className="Licenses">
            {
              licenses.reverse().map((license, idx) => (
                <RadioBox
                  key={`license-${idx}`}
                  className="License"
                  name="license-choice"
                  text={license.access_code}
                  value={license.id}
                  checked={(parseInt(rosterData.licenseId) === license.id)}
                  onChange={e => setRosterData({ ...rosterData, licenseId: e.currentTarget.value })}
                />
              ))
            }
          </div>
        </div>

        <div className="Group-Field">
          <label className="Label">Group (<Link to="/account/groups" className="Link">Create New Group</Link>)</label>
          <div className="Groups">
            {
              Object.values(groups).filter(group => group.status === 'active').reverse().map((group, idx) => (
                <RadioBox
                  key={`group-${idx}`}
                  className="Group"
                  name="group-choice"
                  text={group.name}
                  value={group.id}
                  checked={(parseInt(rosterData.groupId) === group.id)}
                  onChange={e => setRosterData({ ...rosterData, groupId: e.currentTarget.value })}
                />
              ))
            }
          </div>
        </div>

        <div className="File-Field">
          <label className="Label">Excel<span className="Required-Asterisk">*</span></label>
          <input
            type="file"
            className="Input"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={fileRef}
            onChange={e => setRosterData({ ...rosterData, file: e.currentTarget.files[0] })}
          />
        </div>

        <div className="Submit-Button">
          <ButtonBox
            className="Btn Primary-Btn"
            text={<span className="Primary-Btn-Text">Upload</span>}
            onClick={(e) => handleUpload(e)}
          />
        </div>
      </DashboardFormBoxNoEdit>
    </div>
  )
}

UserRosterUpload.propTypes = {
  currentUser: PropTypes.shape({
    perLicenseSeats: PropTypes.shape({
      id: PropTypes.number.isRequired,
      access_code: PropTypes.string.isRequired,
    }),
  }).isRequired,
  progressData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  groups: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  clearGroups: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  clearRosterManagement: PropTypes.func.isRequired,
  receiveUserRosterUploadInitialState: PropTypes.func.isRequired,
  createUserRosterUpload: PropTypes.func.isRequired,
}

export default UserRosterUpload;
