import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import ReactTooltip from 'react-tooltip';
import PledgeComplete from './PledgeComplete'

import ImageBox from '../../../Basic/ImageBox/ImageBox';
import InputBox from '../../../Basic/InputBox/InputBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import Confirm from '../../../Composed/Confirm/Confirm';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';

import { languagesList } from '../../../../Constants/LanguageConstants';
import { splitPledge } from '../../../../Util/Helper/PledgeUtil';
import { gaEvent } from '../../../../Util/Helper/GoogleUtil';
import { isValidPledgeTarget } from '../../../../Util/Helper/PledgeUtil'

const Pledge = ({ pledge, currentIdx, language, scaleUpPledge, scaleDownPledge, displayProfileWingsModal }) => {
  const [showPledgeCompleteModal, setShowPledgeCompleteModal] = useState(false);
  const [pledgeAttrs, setPledgeAttrs] = useState({
    level: (pledge.level || ''),
    target: (pledge.target || '')
  });
  const [processing, setProcessing] = useState(false);
  const [pledgeErrors, setPledgeErrors] = useState({});
  const pledgeParts = splitPledge(pledge.text);

  const isBehindTarget = () => {
    return pledge.target && ((new Date(pledge.target)).getTime() < (new Date()).getTime())
  }

  const handleActivatePledge = (e) => {
    e.preventDefault();

    let errors = {};

    if ((pledge.text.indexOf('__level__') >= 0) && (pledgeAttrs.level.length === 0)) {
      errors['level'] = true
    }

    if ((pledge.text.indexOf('__target__') >= 0) && ((pledgeAttrs.target.length === 0) || (!isValidPledgeTarget(pledgeAttrs.target)))) {
      errors['target'] = true
    }

    setPledgeErrors(errors);

    if (Object.keys(errors).length === 0) {
      setProcessing(true);
      scaleUpPledge({ id: pledge.id, status: 'in_progress', ...pledgeAttrs }).then(res => {
        gaEvent('pledge_activate', { pledge_id: pledge.id });
        displayProfileWingsModal && displayProfileWingsModal();
        setProcessing(false);
      });
    }
  }

  const handleCompletePledge = (e) => {
    e.preventDefault();
    setProcessing(true);
    scaleUpPledge({ id: pledge.id }).then(res => {
      gaEvent('pledge_complete', { pledge_id: pledge.id });
      displayProfileWingsModal && displayProfileWingsModal();
      setProcessing(false);
    });
    setShowPledgeCompleteModal(false);
  }

  const handleScaleDownPledge = () => {
    setProcessing(true);
    scaleDownPledge({ id: pledge.id }).then(res => {
      gaEvent('pledge_undo', { pledge_id: pledge.id });
      displayProfileWingsModal && displayProfileWingsModal();
      setProcessing(false);
    });
  }

  const renderPledgePart = (part, mode) => {
    switch (part) {
      case '__language__':
        return <span className="part bold ttc">{languagesList[language]}</span>
      case '__level__':
        return (mode === 'edit') ? (
          <select
            className={`Level-Field ${pledgeErrors.level ? 'error' : ''}`}
            value={pledgeAttrs.level}
            onChange={(e) => setPledgeAttrs({ ...pledgeAttrs, ['level']: e.currentTarget.value })}
          >
            <option value="">Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        ) : (
          <span className="part bold">{pledge.level}</span>
        )
      case '__target__':
        return (mode === 'edit') ? (
          <span data-tip data-for="Pledge-Target-ToolTip">
            <InputBox
              className={`Target-Field ${pledgeErrors.target ? 'error' : ''}`}
              value={pledgeAttrs.target}
              onChange={(e) => setPledgeAttrs({ ...pledgeAttrs, ['target']: e.currentTarget.value })}
              min={(new Date()).toISOString().slice(0, 10)}
              max={(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).toISOString().slice(0, 10)}
              type="date"
            />

            <ReactTooltip type="info" effect="solid" id="Pledge-Target-ToolTip">
              <p>The target date can be set within one year from today.</p>
            </ReactTooltip>
          </span>
        ) : (
          <span className="part bold">{dateFormat(pledge.target, 'mmmm d, yyyy', true)}</span>
        )
      case '__canopy_scale__':
        return (
          <span className="part link">
            <u><a href="https://www.withcanopy.com/canopy-toolkit/" target="_blank" rel="noopener noreferrer">Canopy Scale</a></u>
          </span>
        )
      default:
        return <span className="part">{part}</span>
    }
  };

  const renderPledge = () => {
    switch (pledge.status) {
      case 'in_progress':
        return inProgressPledge();
      case 'outstanding':
        return outstandingPledge();
      case 'completed':
        return completedPledge();
    }
  }

  const inProgressPledge = () => {
    return (
      <div className="Pledge-Card In-Progress-Pledge-Card">
        <div className="Title">
          In-Progress<span className="Arm-Icon">💪</span>
        </div>
        <div className="Card">
          <div className="Details-Container">
            <p className="Details">
              {
                pledgeParts.map((part) => (
                  renderPledgePart(part, 'view')
                ))
              }
            </p>
            {
              isBehindTarget() &&
              <div className="Behind-Target">
                <ImageBox className="WarningIcon" src="warning.svg" />
                Behind Schedule
              </div>
            }
          </div>
          <div className="Buttons-Container">
            <ButtonBox
              className="Move-To-Completed"
              text="Mark as Achieved"
              onClick={(e) => setShowPledgeCompleteModal(true)}
            />
            <Confirm
              text="Are you sure you want to move this pledge back to available pledges?"
              confirm={() => handleScaleDownPledge()}
            >
              {
                open => (
                  <ButtonBox
                    className="Back-To-Outstanding"
                    text="Remove"
                    onClick={open}
                  />
                )
              }
            </Confirm>
          </div>
        </div>
      </div>
    )
  };

  const outstandingPledge = () => {
    return (
      <div className="Pledge-Card Outstanding-Pledge-Card">
        <div className="Title">
          Available Pledge
        </div>
        <div className="Card">
          <div className="Details-Container">
            <p className="Details">
              {
                pledgeParts.map((part) => (
                  renderPledgePart(part, 'edit')
                ))
              }
            </p>
          </div>
          <div className="Buttons-Container">
            <ButtonBox
              className="Move-To-In-Progress"
              text="Activate"
              onClick={(e) => handleActivatePledge(e)}
            />
          </div>
        </div>
      </div>
    )
  };

  const completedPledge = () => {
    return (
      <div className="Pledge-Card Completed-Pledge-Card">
        <div className="Title">
          Achieved! <ImageBox src="icon_pledge_complete.svg" className="Badge-Icon" />
        </div>
        <div className="Card">
          <div className="Details-Container">
            <p className="Details">
              {
                pledgeParts.map((part) => (
                  renderPledgePart(part, 'view')
                ))
              }
            </p>
          </div>
          <div className="Buttons-Container">
            <Confirm
              text="Are you sure you want to move this pledge back to in-progress pledges?"
              confirm={() => handleScaleDownPledge()}
            >
              {
                open => (
                  <ButtonBox
                    className="Back-To-In-Progress"
                    text="Reactivate"
                    onClick={open}
                  />
                )
              }
            </Confirm>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      {processing && <MirrorLoader message="Updating pledge, Hang on!"/>}
      {renderPledge()}
      {
        showPledgeCompleteModal &&
        <PledgeComplete
          pledgeParts={pledgeParts}
          renderPledgePart={(part, view) => renderPledgePart(part, view)}
          submit={(e) => handleCompletePledge(e)}
          close={() => setShowPledgeCompleteModal(false)}
        />
      }
    </>
  )
};

Pledge.propTypes = {
  pledge: PropTypes.object.isRequired,
  currentIdx: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  scaleUpPledge: PropTypes.func.isRequired,
  scaleDownPledge: PropTypes.func.isRequired
}

export default Pledge;
