import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { QuillModules } from '../../../Data/Quill';
import 'react-quill/dist/quill.snow.css';
import ReactDropzone from "react-dropzone";

import './CreateEditTableEntry.css';
import InputBox
  from '../../Basic/InputBox/InputBox';
import CheckBox
  from '../../Basic/CheckBox/CheckBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton
  from '../../Quiz/AudioPlayButton/AudioPlayButton';

class CreateEditTableEntry extends Component {
  state = this.props.tableEntry ?
  {
    text: this.props.tableEntry.text,
    row_index: this.props.tableEntry.row_index,
    col_index: this.props.tableEntry.col_index,
    mediaUrl: this.props.tableEntry.audio_url,
    mediaFile: null,
    purge_audio: false,
  }
  :
  {
    text: "",
    row_index: this.props.rowIndex,
    col_index: this.props.colIndex,
    mediaUrl: "",
    mediaFile: null,
  }
  
  textArea = React.createRef();

  handleChange = value => this.setState({ text: value })

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleAnswerInput = (idx) => {
    return (e) => {
      const answers_attributes = [...this.state.answers_attributes];
      answers_attributes[idx].text = e.currentTarget.value;
      this.setState({
        answers_attributes,
      })
    }
  }

  handleAddAnswer = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const textAreaNode = this.textArea.current;
      const newText =
        prevState.text.slice(0, textAreaNode.selectionStart) + "__blank__" +
        prevState.text.slice(textAreaNode.selectionEnd, prevState.text.length);
      return {
        answers_attributes: [...prevState.answers_attributes, { text: "", index: prevState.answers_attributes.length }],
        text: newText,
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const file = this.state.mediaFile;
    const formData = new FormData();

    formData.append('table_entry[text]', this.state.text);
    formData.append('table_entry[row_index]', this.state.row_index);
    formData.append('table_entry[col_index]', this.state.col_index);

    if (this.props.table) {
      formData.append('table_entry[table_id]', this.props.table.id);
    }

    if (this.props.tableEntry) {
      formData.append('table_entry[id]', this.props.tableEntry.id);
      formData.append('table_entry[purge_audio]', this.state.purge_audio);
    }

    if (file) {
      formData.append('table_entry[audio]', file);
    }

    this.props.submitTableEntry(formData).then(
      this.props.closeModal()
    );
  }

  onDrop = (files) => {
    const reader = new FileReader();
    const file = files[0];
    reader.onloadend = () => this.setState(
      { mediaUrl: reader.result, mediaFile: file }
    );

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({ mediaUrl: "", mediaFile: null });
    }
  }

  handleBlur = () => e => {
    e.currentTarget.id = "";
  };

  handleFocus = () => e => {
    e.currentTarget.id = "active";
  };

  handleAnswersRender() {
    return this.state.answers_attributes.map(
      (answer, idx) => (
        <div
          className="CreationForms-InputBox"
          key={idx}>
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.answers_attributes[idx].text}
            placeholder={`Answer ${idx + 1}`}
            onChange={this.handleAnswerInput(idx)}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>
      )
    );
  }

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-ReactQuill">
          <ReactQuill
            modules={QuillModules}
            value={this.state.text}
            onChange={this.handleChange} />
        </div>

        {
          this.props.tableEntry &&
          <div className="CreateEditLesson-Checkbox-container">
            <div className="CreateEditLesson-Checkbox">
              <CheckBox
                checked={this.state.purge_audio}
                onChange={(e) => this.setState({ purge_audio: !this.state.purge_audio })}
              />
            </div>

            <div>
              Delete Audio
            </div>
          </div>
        }

        <ReactDropzone
          onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div
              className="CreateEditTableEntry-dropzone"
              {...getRootProps()}>
              <input {...getInputProps()} />
              {
                this.state.mediaUrl ?
                  <AudioPlayButton src={this.state.mediaUrl} />
                  :
                  <p>To upload audio, drag 'n' drop file here, or click to select file.</p>
              }
            </div>
          )}
        </ReactDropzone>

        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditTableEntry;
