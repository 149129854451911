import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CreateEditWorkPlace from '../../../Forms/CreateEditWorkPlace/CreateEditWorkPlace';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../../Basic/TextBox/TextBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { createWorkPlace, updateWorkPlace } from '../../../../Actions/WorkPlaceActions';

import './WorkPlace.scss';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  createWorkPlace: workplace => (dispatch(createWorkPlace(workplace))),
  updateWorkPlace: workplace => (dispatch(updateWorkPlace(workplace))),
});

const WorkPlace = ({ currentUser, createWorkPlace, updateWorkPlace, displayProfileWingsModal }) => {
  const [newWorkPlace, setNewWorkPlace] = useState(false);

  return (
    <div className="WorkPlace">
      <div className="WorkPlace-Title-Container Tour-Step-Profile-Workplace-Info-Title">
        <TextBox
          className="Title"
          text="WorkPlace Information"
        />
      </div>

      <div className="WorkPlace-Description-Container">
        <TextBox
          className="Description"
          text="Many organizations have arrangements with Canopy to make access available to their students and/or employees. Please add each employer or school you are affiliated with as a Workplace. Please provide the institution-issued email for each school, hospital or other organization, so that we can facilitate access to the institution-purchased licenses."
        />
      </div>

      <div className="WorkPlace-New-Container">
        <div className="New-WorkPlace-ButtonBox">
          <ButtonBox
            className="New-WorkPlace-Button"
            text={
              <span className="Btn">
                <ImageBox src="add.svg" className="Icon" />
                Add Workplace
              </span>
            }
            onClick={() => setNewWorkPlace(true)}
          />
        </div>
      </div>

      <div className="WorkPlace-My-Container">
        <div className="New-Workplace">
          {
            newWorkPlace &&
            <div className="My-WorkPlace">
              <CreateEditWorkPlace
                currentUser={currentUser}
                createWorkPlace={createWorkPlace}
                cancelNewWorkPlace={() => setNewWorkPlace(false)}
                displayProfileWingsModal={displayProfileWingsModal}
              />
            </div>
          }
        </div>

        <div className="My-Workplaces">
          {
            currentUser.workplaces.map((workPlace, idx) =>
              workPlace.status === 'active' && (
                <div key={idx} className="My-WorkPlace">
                  <CreateEditWorkPlace
                    currentUser={currentUser}
                    workPlace={workPlace}
                    updateWorkPlace={updateWorkPlace}
                    workPlaceNumber={idx + 1}
                    displayProfileWingsModal={displayProfileWingsModal}
                  />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

WorkPlace.propTypes = {
  currentUser: PropTypes.object.isRequired,
  createWorkPlace: PropTypes.func.isRequired,
  updateWorkPlace: PropTypes.func.isRequired,
  displayProfileWingsModal: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkPlace);
