import axios from './AxiosUtil';

export const fetchInventories = () => (
  axios.get('/api/inventories').then(
    res => res.data
  )
)

export const fetchDetailedInventories = () => (
  axios.get('/api/inventories/detailed_inventories').then(
    res => res.data
  )
)

export const grantAccess = (userId, inventoryId) => (
  axios.post('/api/inventories/grant_access', { user_id: userId, inventory_id: inventoryId } ).then(
    res => res.data
  )
);
