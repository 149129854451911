import {
    CLEAR_CREATE_USER_ERRORS,
    CREATE_USER_VALIDATION_ERRORS,
    RECEIVE_CHECK_USER_CONFIRMATION,
    RECEIVE_CHECK_USER_ERRORS,
    RECEIVE_CREATE_USER_CONFIRMATION,
    RECEIVE_CREATE_USER_ERRORS,
} from '../../Actions/CreateUserActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case CLEAR_CREATE_USER_ERRORS:
            return {};
        case RECEIVE_CHECK_USER_CONFIRMATION:
            if (action.exists) {
                return { message: action.message };
            }
            return {};
        case RECEIVE_CHECK_USER_ERRORS:
            return action.errors;
        case CREATE_USER_VALIDATION_ERRORS:
            return action.errors;
        case RECEIVE_CREATE_USER_CONFIRMATION:
            return {};
        case RECEIVE_CREATE_USER_ERRORS:
            return action.errors;
        default:
            return state;
    }
};
