import axios from '../../AxiosUtil';

export const fetchCommunityActivity = () => {
  return axios.get(`/api/stats/users/learns/community_activity`).then(
    res => res.data
  )
};

export const fetchMyBookmarks = () => {
  return axios.get(`/api/stats/users/learns/my_bookmarks`).then(
    res => res.data
  )
};

export const fetchMyComments = () => {
  return axios.get(`/api/stats/users/learns/my_comments`).then(
    res => res.data
  )
};

export const fetchMyProgresses = () => {
  return axios.get(`/api/stats/users/learns/my_progresses`).then(
    res => res.data
  )
};

export const fetchMyActivity = () => {
  return axios.get(`/api/stats/users/learns/my_activity`).then(
    res => res.data
  )
};
