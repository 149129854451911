import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import InvitationBatches from './InvitationBatches';
import Loader from '../../../Composed/Loader/Loader';
import TextBox from '../../../Basic/TextBox/TextBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearInviteUsers, fetchInviteUsers } from '../../../../Actions/InviteUserActions';

import './InviteUsers.scss';

const mapStateToProps = (state) => ({
  invitationBatches: state.admin.inviteUsers || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearInviteUsers: () => dispatch(clearInviteUsers()),
  fetchInviteUsers: () => dispatch(fetchInviteUsers())
});

const InviteUsers = ({ invitationBatches, clearInviteUsers, fetchInviteUsers }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearInviteUsers();
    fetchInviteUsers().then(res => setLoading(false))
  }, [fetchInviteUsers]);

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Invitations-Container">
      <div className="B2BAdminDashboard-Title">
        <TextBox
          text="Invitations"
          tag="h4"
        />
        <Link to="/account/invite_users/new">
          <ButtonBox
            text="Invite Users"
            className="B2BAdminDashboard-Button"
          />
        </Link>
      </div>

      <InvitationBatches invitationBatches={invitationBatches} />
    </div>
  )
}

InviteUsers.propTypes = {
  invitationBatches: PropTypes.object,
  clearInviteUsers: PropTypes.func.isRequired,
  fetchInviteUsers: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUsers);
