import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/SessionApiUtil';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS';
export const CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export const RECEIVE_SESSION_FLASH = 'RECEIVE_SESSION_FLASH';
export const RECEIVE_UNLOCK_FLASH = 'RECEIVE_UNLOCK_FLASH';

export const receiveCurrentUser = payload => ({
    type: RECEIVE_CURRENT_USER,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_SESSION_ERRORS,
    errors,
});

export const clearSessionErrors = () => ({
    type: CLEAR_SESSION_ERRORS,
});

export const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER,
});

export const receiveSessionFlash = payload => ({
    type: RECEIVE_SESSION_FLASH,
    message: payload.message,
});

export const receiveUnlockFlash = payload => ({
    type: RECEIVE_UNLOCK_FLASH,
    payload,
});

export const login = user => dispatch => (
    APIUtil.login(user).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const signup = user => dispatch => (
    APIUtil.signup(user).then(
        payload => dispatch(receiveSessionFlash(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const confirmEmail = query => dispatch => (
    APIUtil.confirmEmail(query).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const logout = () => dispatch => (
    APIUtil.logout().then(
        () => dispatch(logoutCurrentUser()),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const updatePhoto = formData => dispatch => (
    APIUtil.updatePhoto(formData).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const updateAccount = user => dispatch => (
    APIUtil.updateAccount(user).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

// update password from within the site
export const updatePassword = user => dispatch => (
    APIUtil.updatePassword(user).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const syncCurrentUser = () => dispatch => (
    APIUtil.syncCurrentUser().then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const resendConfirmationEmail = () => dispatch => (
    APIUtil.resendConfirmationEmail().then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

// request a change to password when forgotten
export const resetPassword = user => dispatch => (
    APIUtil.resetPassword(user).then(
        payload => dispatch(receiveSessionFlash(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

// reset a password following a link
export const setNewPassword = user => dispatch => (
    APIUtil.setNewPassword(user).then(
        payload => dispatch(receiveCurrentUser(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

// request to unlock account with the token provided by email
export const unlockAccount = token => dispatch => (
    APIUtil.unlockAccount(token).then(
        payload => dispatch(receiveUnlockFlash(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fireClientSideValidationError = errors => receiveErrors(errors);
