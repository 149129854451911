import { connect } from 'react-redux';

import Overview from './Overview';

import { clearNetPromoterScores, fetchNPSOverview } from '../../../../../Actions/NetPromoterScoreActions';

const mapStateToProps = state => ({
  netPromoterScores: state.admin.netPromoterScores || {},
});

const mapDispatchToProps = dispatch => ({
  clearNetPromoterScores: () => dispatch(clearNetPromoterScores()),
  fetchNPSOverview: () => dispatch(fetchNPSOverview()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
