import { RECEIVE_PLEDGES, CLEAR_PLEDGES, RECEIVE_PLEDGES_ERRORS } from '../../Actions/PledgeActions';

const PledgesReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PLEDGES:
      return Object.assign({}, state, action.payload.pledges);
    case CLEAR_PLEDGES:
      return {};
    default:
      return state;  
  }
}

export default PledgesReducer;
