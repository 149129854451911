import {
    CLEAR_MODIFY_USER_ERRORS,
    RECEIVE_MODIFY_USER_CONFIRMATION,
    RECEIVE_MODIFY_USER_ERRORS,
    RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION,
} from '../../Actions/ModifyUserActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case CLEAR_MODIFY_USER_ERRORS:
            return {};
        case RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION:
            if (action?.exists === true) {
                return { message: 'User Found!', user: action.user };
            }
            return {};
        case RECEIVE_MODIFY_USER_CONFIRMATION:
            return { message: action.message };
        case RECEIVE_MODIFY_USER_ERRORS:
            return {};
        default:
            return state;
    }
};
