import { merge } from 'lodash';
import {
  RECEIVE_QUIZ_ANSWER,
  RESET_QUIZ_ANSWER,
  CLEAR_QUIZ_PROGRESSES,
} from '../../Actions/QuizActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_QUIZ_ANSWER:
      let newState = merge({}, state, action.answer);
      return newState;
    case RESET_QUIZ_ANSWER:
      let newState2 = Object.assign({}, state);
      if (newState2[action.answer.lessonId] && newState2[action.answer.lessonId][action.answer.sectionId]) {
        delete newState2[action.answer.lessonId][action.answer.sectionId];
      }
      return newState2;
    case CLEAR_QUIZ_PROGRESSES:
        return {};
    default:
      return state;
  }
};
