import { connect } from 'react-redux';

import CreationTopicsDashboard from './CreationTopicsDashboard';
import {
    fetchTopics,
    clearTopics,
    createTopic,
    clearTopicErrors,
    deleteTopic,
    moveHigher,
    moveLower,
} from '../../../Actions/TopicActions';
import {
    fetchLessons,
    editLesson,
} from '../../../Actions/LessonActions';
import { createQuiz, deleteQuiz, restoreQuiz } from '../../../Actions/QuizActions';
import { createActivity, deleteActivity } from '../../../Actions/ActivityActions';

const mapStateToProps = (state, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    lesson: state.entities.lessons[match.params.lessonId],
    currentLessonId: match.params.lessonId,
    activities: state.entities.activities,
    topics: state.entities.topics,
    quizzes: state.entities.quizzes,
    topicErrors: state.errors.topics,
    topicFlash: state.flash.topics,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLessons: courseId => dispatch(fetchLessons(courseId)),
    clearTopics: () => dispatch(clearTopics()),
    fetchTopics: lessonId => dispatch(fetchTopics(lessonId)),
    editLesson: lesson => dispatch(editLesson(lesson)),
    createTopic: topic => dispatch(createTopic(topic)),
    clearTopicErrors: () => dispatch(clearTopicErrors()),
    createQuiz: quiz => dispatch(createQuiz(quiz)),
    createActivity: activity => dispatch(createActivity(activity)),
    deleteActivity: activityId => dispatch(deleteActivity(activityId)),
    deleteQuiz: quizId => dispatch(deleteQuiz(quizId)),
    restoreQuiz: quizId => dispatch(restoreQuiz(quizId)),
    deleteTopic: topicId => dispatch(deleteTopic(topicId)),
    moveHigher: topicId => dispatch(moveHigher(topicId)),
    moveLower: topicId => dispatch(moveLower(topicId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationTopicsDashboard);
