import { GET_BILLING_INFO_SUCCESS } from '../../../../Actions/Profile/SubscriptionsBilling/BillingInfo';

const initialState = {
    name: '',
    ccNumber: '',
    expDate: '',
    cvv: '',
    zipCode: '',
};

const billingInfo = (state = initialState, action) => {
    switch (action.type) {
        case GET_BILLING_INFO_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

export default billingInfo;
