import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/TableEntryApiUtil';

export const RECEIVE_TABLE_ENTRY_ERRORS = 'RECEIVE_TABLE_ENTRY_ERRORS';
export const RECEIVE_TABLE_ENTRIES = 'RECEIVE_TABLE_ENTRIES';
export const CLEAR_TABLE_ENTRIES = 'CLEAR_TABLE_ENTRIES';
export const RECEIVE_TABLE_ENTRY = 'RECEIVE_TABLE_ENTRY';

export const receiveTableEntries = payload => {
  return {
    type: RECEIVE_TABLE_ENTRIES,
    payload,
  };
};

export const clearTableEntries = () => {
  return {
    type: CLEAR_TABLE_ENTRIES,
  };
};

export const receiveTableEntry = payload => ({
  type: RECEIVE_TABLE_ENTRY,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_TABLE_ENTRY_ERRORS,
  errors
});

export const createTableEntry = (formData) => dispatch => (
  APIUtil.createTableEntry(formData).then(
    payload => dispatch(receiveTableEntry(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editTableEntry = (formData) => dispatch => (
  APIUtil.editTableEntry(formData).then(
    payload => dispatch(receiveTableEntry(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);