import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/TextContentApiUtil';

export const RECEIVE_TEXT_CONTENT_ERRORS = 'RECEIVE_TEXT_CONTENT_ERRORS';
export const RECEIVE_TEXT_CONTENTS = 'RECEIVE_TEXT_CONTENTS';
export const CLEAR_TEXT_CONTENTS = 'CLEAR_TEXT_CONTENTS';
export const RECEIVE_TEXT_CONTENT = 'RECEIVE_TEXT_CONTENT';

export const receiveTextContents = payload => {
  return {
    type: RECEIVE_TEXT_CONTENTS,
    payload,
  };
};

export const clearTextContents = () => {
  return {
    type: CLEAR_TEXT_CONTENTS,
  };
};

export const receiveTextContent = payload => ({
  type: RECEIVE_TEXT_CONTENT,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_TEXT_CONTENT_ERRORS,
  errors
});

export const createTextContent = (textContent) => dispatch => (
  APIUtil.createTextContent(textContent).then(
    payload => dispatch(receiveTextContent(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editTextContent = (textContent) => dispatch => (
  APIUtil.editTextContent(textContent).then(
    payload => dispatch(receiveTextContent(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteTextContent = textContentId => dispatch => (
  APIUtil.deleteTextContent(textContentId).then(
    payload => dispatch(receiveTextContent(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const uploadTextContentAudio = formData => dispatch => (
  APIUtil.uploadAudio(formData).then(
    payload => dispatch(receiveTextContent(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);