import axios from './AxiosUtil';

const transformResult = (array) => {
    const result = {};
    array.forEach(entry => {
        if (!result[entry.course_id]) {
            result[entry.course_id] = [];
        }
        result[entry.course_id].push(entry);
    });
    return result;
};

export const fetchAllLessons = () => axios.get('/api/lessons').then(
    res => transformResult(res.data),
);

export const fetchCourses = () => axios.get('/api/courses').then(
    res => res.data,
);

export const fetchCourseProgresses = () => axios.get('/api/course_progresses').then(
    res => res.data,
);

export const fetchTopicsCount = () => axios.get('/api/topics').then(
    res => res.data,
);

export const fetchActivity = id => axios.get(`/api/user_sessions/${id}`).then(
    res => res.data,
);
