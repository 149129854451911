import { combineReducers } from 'redux';

import professionalInformation from './ProfessionalInformation';
import workplaceSchool from './WorkplaceSchool';

const organizationOccupation = combineReducers({
    professionalInformation,
    workplaceSchool,
});

export default organizationOccupation;
