import { connect } from 'react-redux';

import CanopyLearnPage from './CanopyLearnPage';
import { fetchCourses, clearCourses } from '../../../Actions/CourseActions';
import { fetchCourseProgresses, clearCourseProgresses } from '../../../Actions/CourseProgressActions';
import { fetchBookmarkIds, clearBookmarks } from '../../../Actions/BookmarkActions';
import { syncCurrentUser } from '../../../Actions/SessionActions';
import { fetchQuizResults, clearQuizResults } from '../../../Actions/QuizActions';
import { fetchSubscriptions, clearSubscriptions } from '../../../Actions/SubscriptionActions';
import { fetchLessons } from '../../../Actions/LessonActions';
import { receiveCourseId, clearCourseId } from '../../../Actions/NavigationActions';

const mapStateToProps = (state) => ({
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace,
    currentCourseId: state.navigation.currentCourseId,
    subscriptions: state.access.subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourses: () => dispatch(fetchCourses()),
    clearCourses: () => dispatch(clearCourses()),
    fetchCourseProgresses: () => dispatch(fetchCourseProgresses()),
    clearCourseProgresses: () => dispatch(clearCourseProgresses()),
    fetchBookmarkIds: () => dispatch(fetchBookmarkIds()),
    clearBookmarks: () => dispatch(clearBookmarks()),
    syncCurrentUser: () => dispatch(syncCurrentUser()),
    fetchQuizResults: () => dispatch(fetchQuizResults()),
    clearQuizResults: () => dispatch(clearQuizResults()),
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
    clearSubscriptions: () => dispatch(clearSubscriptions()),
    fetchLessons: (courseId) => dispatch(fetchLessons(courseId)),
    receiveCourseId: (courseId) => dispatch(receiveCourseId(courseId)),
    clearCourseId: () => dispatch(clearCourseId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanopyLearnPage);
