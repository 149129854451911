export const countriesList = {
  '': 'Please select',
  'united_states' : 'United States',
  'afghanistan': 'Afghanistan',
  'aland_islands': 'Åland Islands',
  'albania': 'Albania',
  'algeria': 'Algeria',
  'american_samoa': 'American Samoa',
  'andorra': 'Andorra',
  'angola': 'Angola',
  'anguilla': 'Anguilla',
  'antarctica': 'Antarctica',
  'antigua_barbuda': 'Antigua & Barbuda',
  'argentina': 'Argentina',
  'armenia': 'Armenia',
  'aruba': 'Aruba',
  'australia': 'Australia',
  'austria': 'Austria',
  'azerbaijan': 'Azerbaijan',
  'bahamas': 'Bahamas',
  'bahrain': 'Bahrain',
  'bangladesh': 'Bangladesh',
  'barbados': 'Barbados',
  'belarus': 'Belarus',
  'belgium': 'Belgium',
  'belize': 'Belize',
  'benin': 'Benin',
  'bermuda': 'Bermuda',
  'bhutan': 'Bhutan',
  'bolivia': 'Bolivia',
  'bosnia_herzegovina': 'Bosnia & Herzegovina',
  'botswana': 'Botswana',
  'bouvet_island': 'Bouvet Island',
  'brazil': 'Brazil',
  'british_indian_ocean_territory': 'British Indian Ocean Territory',
  'british_virgin_islands': 'British Virgin Islands',
  'brunei': 'Brunei',
  'bulgaria': 'Bulgaria',
  'burkina_faso': 'Burkina Faso',
  'burundi': 'Burundi',
  'cambodia': 'Cambodia',
  'cameroon': 'Cameroon',
  'canada': 'Canada',
  'cape_verde': 'Cape Verde',
  'caribbean_netherlands': 'Caribbean Netherlands',
  'cayman_islands': 'Cayman Islands',
  'central_african_republic': 'Central African Republic',
  'chad': 'Chad',
  'chile': 'Chile',
  'china': 'China',
  'christmas_island': 'Christmas Island',
  'cocos_keeling_islands': 'Cocos (Keeling) Islands',
  'colombia': 'Colombia',
  'comoros': 'Comoros',
  'congo_brazzaville': 'Congo - Brazzaville',
  'congo_kinshasa': 'Congo - Kinshasa',
  'cook_islands': 'Cook Islands',
  'costa_rica': 'Costa Rica',
  'cote_d_ivoire': 'Côte d’Ivoire',
  'croatia': 'Croatia',
  'cuba': 'Cuba',
  'curacao': 'Curaçao',
  'cyprus': 'Cyprus',
  'czechia': 'Czechia',
  'denmark': 'Denmark',
  'djibouti': 'Djibouti',
  'dominica': 'Dominica',
  'dominican_republic': 'Dominican Republic',
  'ecuador': 'Ecuador',
  'egypt': 'Egypt',
  'el_salvador': 'El Salvador',
  'equatorial_guinea': 'Equatorial Guinea',
  'eritrea': 'Eritrea',
  'estonia': 'Estonia',
  'eswatini': 'Eswatini',
  'ethiopia': 'Ethiopia',
  'falkland_islands' : 'Falkland Islands',
  'faroe_islands': 'Faroe Islands',
  'fiji': 'Fiji',
  'finland': 'Finland',
  'france': 'France',
  'french_guiana': 'French Guiana',
  'french_polynesia': 'French Polynesia',
  'french_southern_territories': 'French Southern Territories',
  'gabon': 'Gabon',
  'gambia': 'Gambia',
  'georgia': 'Georgia',
  'germany': 'Germany',
  'ghana': 'Ghana',
  'gibraltar': 'Gibraltar',
  'greece': 'Greece',
  'greenland': 'Greenland',
  'grenada': 'Grenada',
  'guadeloupe': 'Guadeloupe',
  'guam': 'Guam',
  'guatemala': 'Guatemala',
  'guernsey': 'Guernsey',
  'guinea': 'Guinea',
  'guinea_bissau': 'Guinea-Bissau',
  'guyana' : 'Guyana',
  'haiti' : 'Haiti',
  'heard_mcdonald_islands': 'Heard & McDonald Islands',
  'honduras' : 'Honduras',
  'hong_kong_sar_china' : 'Hong Kong SAR China',
  'hungary' : 'Hungary',
  'iceland' : 'Iceland',
  'india' : 'India',
  'indonesia' : 'Indonesia',
  'iran' : 'Iran',
  'iraq' : 'Iraq',
  'ireland' : 'Ireland',
  'isle_of_man' : 'Isle of Man',
  'israel' : 'Israel',
  'italy' : 'Italy',
  'jamaica' : 'Jamaica',
  'japan' : 'Japan',
  'jersey' : 'Jersey',
  'jordan' : 'Jordan',
  'kazakhstan' : 'Kazakhstan',
  'kenya' : 'Kenya',
  'kiribati' : 'Kiribati',
  'kuwait' : 'Kuwait',
  'kyrgyzstan' : 'Kyrgyzstan',
  'latvia' : 'Latvia',
  'laos': 'Laos',
  'lebanon' : 'Lebanon',
  'lesotho' : 'Lesotho',
  'liberia' : 'Liberia',
  'libya' : 'Libya',
  'liechtenstein' : 'Liechtenstein',
  'lithuania' : 'Lithuania',
  'luxembourg' : 'Luxembourg',
  'macao_sar_china' : 'Macao SAR China',
  'madagascar' : 'Madagascar',
  'malawi' : 'Malawi',
  'malaysia' : 'Malaysia',
  'maldives' : 'Maldives',
  'mali' : 'Mali',
  'malta' : 'Malta',
  'marshall_islands' : 'Marshall Islands',
  'martinique' : 'Martinique',
  'mauritania' : 'Mauritania',
  'mauritius' : 'Mauritius',
  'mayotte' : 'Mayotte',
  'mexico' : 'Mexico',
  'micronesia' : 'Micronesia',
  'moldova' : 'Moldova',
  'monaco' : 'Monaco',
  'mongolia' : 'Mongolia',
  'montenegro' : 'Montenegro',
  'montserrat' : 'Montserrat',
  'morocco' : 'Morocco',
  'mozambique' : 'Mozambique',
  'myanmar_burma': 'Myanmar (Burma)',
  'namibia' : 'Namibia',
  'nauru' : 'Nauru',
  'nepal' : 'Nepal',
  'netherlands' : 'Netherlands',
  'new_caledonia' : 'New Caledonia',
  'new_zealand' : 'New Zealand',
  'nicaragua' : 'Nicaragua',
  'niger' : 'Niger',
  'nigeria' : 'Nigeria',
  'niue' : 'Niue',
  'norfolk_island' : 'Norfolk Island',
  'north_korea' : 'North Korea',
  'north_macedonia' : 'North Macedonia',
  'northern_mariana_islands' : 'Northern Mariana Islands',
  'norway' : 'Norway',
  'oman' : 'Oman',
  'pakistan' : 'Pakistan',
  'palau' : 'Palau',
  'palestinian_territories': 'Palestinian Territories',
  'panama' : 'Panama',
  'papua_new_guinea' : 'Papua New Guinea',
  'paraguay' : 'Paraguay',
  'peru' : 'Peru',
  'philippines' : 'Philippines',
  'pitcairn_islands': 'Pitcairn Islands',
  'poland' : 'Poland',
  'portugal' : 'Portugal',
  'puerto_rico' : 'Puerto Rico',
  'qatar' : 'Qatar',
  'reunion' : 'Réunion',
  'romania' : 'Romania',
  'russia' : 'Russia',
  'rwanda' : 'Rwanda',
  'samoa' : 'Samoa',
  'san_marino' : 'San Marino',
  'sao_tome_principe' : 'São Tomé & Príncipe',
  'saudi_arabia' : 'Saudi Arabia',
  'senegal' : 'Senegal',
  'serbia' : 'Serbia',
  'seychelles' : 'Seychelles',
  'sierra_leone' : 'Sierra Leone',
  'singapore' : 'Singapore',
  'sint_maarten' : 'Sint Maarten',
  'slovakia' : 'Slovakia',
  'slovenia' : 'Slovenia',
  'solomon_islands' : 'Solomon Islands',
  'somalia' : 'Somalia',
  'south_africa' : 'South Africa',
  'south_georgia_south_sandwich_islands': 'South Georgia & South Sandwich Islands',
  'south_korea' : 'South Korea',
  'south_sudan' : 'South Sudan',
  'spain' : 'Spain',
  'sri_lanka' : 'Sri Lanka',
  'st_barthelemy' : 'St. Barthélemy',
  'st_helena' : 'St. Helena',
  'st_kitts_nevis' : 'St. Kitts & Nevis',
  'st_lucia' : 'St. Lucia',
  'st_martin' : 'St. Martin',
  'st_pierre_miquelon' : 'St. Pierre & Miquelon',
  'st_vincent_grenadines' : 'St. Vincent & Grenadines',
  'sudan' : 'Sudan',
  'suriname' : 'Suriname',
  'svalbard_jan_mayen' : 'Svalbard & Jan Mayen',
  'sweden' : 'Sweden',
  'switzerland' : 'Switzerland',
  'syria' : 'Syria',
  'taiwan' : 'Taiwan',
  'tajikistan' : 'Tajikistan',
  'tanzania' : 'Tanzania',
  'thailand' : 'Thailand',
  'timor_leste' : 'Timor-Leste',
  'togo' : 'Togo',
  'tokelau': 'Tokelau',
  'tonga' : 'Tonga',
  'trinidad_tobago' : 'Trinidad & Tobago',
  'tunisia' : 'Tunisia',
  'turkey' : 'Turkey',
  'turkmenistan' : 'Turkmenistan',
  'turks_caicos_islands' : 'Turks & Caicos Islands',
  'tuvalu' : 'Tuvalu',
  'u_s_outlying_islands': 'U.S. Outlying Islands',
  'u_s_virgin_islands' : 'U.S. Virgin Islands',
  'uganda' : 'Uganda',
  'ukraine' : 'Ukraine',
  'united_arab_emirates' : 'United Arab Emirates',
  'united_kingdom' : 'United Kingdom',
  'uruguay' : 'Uruguay',
  'uzbekistan' : 'Uzbekistan',
  'vanuatu' : 'Vanuatu',
  'vatican_city': 'Vatican City',
  'venezuela' : 'Venezuela',
  'vietnam' : 'Vietnam',
  'wallis_futuna' : 'Wallis & Futuna',
  'western_sahara' : 'Western Sahara',
  'yemen' : 'Yemen',
  'zambia': 'Zambia',
  'zimbabwe' : 'Zimbabwe'
};
