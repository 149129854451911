import React, { Component } from 'react';

import './Modal.css';
import { COLORS } from '../../../Style/Style';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import SpanishKeyboard
  from '../../Composed/SpanishKeyboard/SpanishKeyboard';

class Modal extends Component {
  state = {
    isOpen: false
  }

  open = () => this.setState({ isOpen: true })

  close = () => this.setState({ isOpen: false })

  render() {
    return (
      <div>
        {
          this.state.isOpen &&
          <div className="modal">
            <div className={this.props.small ? "modal-content-small" : "modal-content"}>
              <div
                className={this.props.small ? "UploadMedia-Title" : "CreationForms-Title"}>
                <TextBox
                  text={this.props.title}
                  style={{
                    color: `${COLORS.Blue}`,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                  tag="h3"
                />

                <div
                  className={this.props.small ? "UploadMedia-ButtonBox" :"CreationForms-Button"}>
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Exit"
                    onClick={this.close} />
                </div>
              </div>

              {
                this.props.subTitle &&
                <TextBox
                  text={this.props.subTitle}
                  style={{
                    color: `${COLORS.Blue}`,
                    fontWeight: "500",
                    textDecoration: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  tag="h4"
                />
              }

              {
                this.props.spanishKeyboard &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <SpanishKeyboard />
                </div>
              }

              {this.props.form(this.close)}
            </div>
          </div>
        }

        {this.props.children(this.open)}
      </div>
    );
  }
}

export default Modal;
