import React from 'react';
import PropTypes from 'prop-types';

import './InputBox.css';

/**
* @function - InputBox - functional
* @{props} - {Object}
 * className -
 * style - style Object
 * onChange - function
 * value - string
 * placeholder - string
 * blurTo - string
* @return { JSX Object }
*/

const InputBox = ({ blurTo, ...otherProps }) => {

  InputBox.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  };

  InputBox.defaultProps = {
    className: 'InputBox',
    value: "",
    placeholder: "",
  };

  const handleFocus = (e) => {
    e.currentTarget.type = blurTo;
  };

  const handleBlur = (e) => {
    if (e.currentTarget.value === "") {
      e.currentTarget.type = "text";
    }
  };

  return (
    <input
      onFocus={ blurTo ? handleFocus : null }
      onBlur={ blurTo ? handleBlur : null }
      type="text"
      { ...otherProps }>
    </input>
  );
};

export default InputBox;
