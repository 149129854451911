import {
  RECEIVE_BOOKMARK_IDS,
  RECEIVE_BOOKMARK_GEN,
  CLEAR_BOOKMARKS
} from '../../Actions/BookmarkActions';

const BookmarksReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_BOOKMARK_IDS:
      return Object.assign({}, state, action.payload);
    case RECEIVE_BOOKMARK_GEN:
      return Object.assign({}, state, action.payload);
    case CLEAR_BOOKMARKS:
      return {};
    default:
      return state;
  }
}

export default BookmarksReducer;
