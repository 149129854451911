import * as APIUtil from '../Util/PledgeApiUtil';

import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_PLEDGES = 'RECEIVE_PLEDGES';
export const RECEIVE_PLEDGES_ERRORS = 'RECEIVE_PLEDGES_ERRORS';
export const CLEAR_PLEDGES = 'CLEAR_PLEDGES';

export const receivePledges = payload => {
  return {
    type: RECEIVE_PLEDGES,
    payload,
  };
};

export const receiveErrors = errors => {
  return {
    type: RECEIVE_PLEDGES_ERRORS,
    errors
  };
};

export const clearPledges = () => {
  return {
    type: CLEAR_PLEDGES,
  };
};

export const fetchPledges = () => dispatch => (
  APIUtil.fetchPledges().then(
    payload => dispatch(receivePledges(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const scaleUpPledge = (pledge) => dispatch => (
  APIUtil.scaleUpPledge(pledge).then(
    payload => dispatch(receivePledges(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const scaleDownPledge = (pledge) => dispatch => (
  APIUtil.scaleDownPledge(pledge).then(
    payload => dispatch(receivePledges(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
)
