import './ErrorsFlashBox.css';

import PropTypes from 'prop-types';
import React from 'react';

import TextBox from '../../Basic/TextBox/TextBox';

const ErrorFlashBox = ({ errors, flash, style }) => {
    return (
        <div style={style}>
            {
                Object.keys(errors).length > 0
                && (
                    <div
                        className="ErrorsFlashBox-Message-container"
                    >
                        {
                            Object.keys(errors).map(
                                (field, idx) => (
                                    <div
                                        className="ErrorsFlashBox-Error"
                                        key={idx}
                                    >
                                        <TextBox
                                            text={`${field.split('_').join(' ')} ${errors[field]}`}
                                        />
                                    </div>
                                ),
                            )
                        }
                    </div>
                )
            }

            {
                flash.message
                && (
                    <div
                        className="ErrorsFlashBox-Message-container"
                    >
                        <div
                            className="ErrorsFlashBox-Success"
                        >
                            <TextBox
                                text={flash.message}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

ErrorFlashBox.propTypes = {
    errors: PropTypes.shape({
        message: PropTypes.string,
    }),
    flash: PropTypes.shape({
        message: PropTypes.string,
    }),
    style: PropTypes.objectOf(PropTypes.string),
};

ErrorFlashBox.defaultProps = {
    errors: {},
    flash: {},
    style: {},
};


export default ErrorFlashBox;
