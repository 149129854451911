import './QuizReviewBox.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import QuestionBox from '../QuestionBox/QuestionBox';
import ImageBox  from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';
import CommentsPanelHandler from '../../Pages/Comment/CommentsPanelHandler';

const QuizReviewBox = ({ course, section, revealAnswers, quizResult }) => {
    const sectionData = quizResult?.quiz_data?.[section?.id] || {};

    const isAnswerCorrect = (question) => {
        let answersCount = question.answers?.length || 0;
        let correctCount = 0;

        let userAnswers  = sectionData[question?.id] || {};
        Object.keys(userAnswers).map((k) => {
            if (userAnswers[k]?.is_correct) {
                correctCount += 1;
            }
        });

        return correctCount === answersCount;
    }

    const handleQuestionsRender = () => {
        switch (section.section_type) {
            case 'multiple_choice':   return questionsWithOptions();
            case 'true_or_false':     return questionsWithOptions();
            case 'multiple_answer':   return questionsWithMultipleOptions();
            case 'fill_in_the_blank': return questionsWithMultipleParts();
            case 'drop_down':         return questionsWithMultipleParts();
            case 'drag_and_drop':     return questionsWithMultipleParts();
            case 'word_scramble':     return questionsWithMultipleParts();
            case 'matching':          return questionsWithSinglePart();
            default:                  return '';
        }
    }

    const getOptions = (question) => {
        let options = [];

        if (section.section_type === 'multiple_choice' && question.options) {
            question.options.map(opt => options.push(opt.text));
        } else if (section.section_type === 'true_or_false') {
            options = ['True', 'False'];
        } else if (section.section_type === 'multiple_answer' && question.options) {
            question.options.map(opt => options.push(opt.text));
        }

        return options;
    }

    const questionsWithMultipleOptions = () => {
        return section?.questions?.map(question => (
            <div className="QuizReviewBox-QuestionAnswer flex-column" index={question.id} key={question.id}>
                <div className="QuizReviewBox-Question__container">
                    <div className="QuizReviewBox-Question__position">
                        <TextBox className="Position" text={question.position} />
                        {
                            isAnswerCorrect(question) ? <ImageBox className="Tick" src="correct-answer.svg" /> : <ImageBox className="Cross" src="incorrect-answer.svg" />
                        }
                    </div>

                    <QuestionBox question={question}>
                        <TextBox className="QuizReviewBox-Question__question QuizReviewBox-Question__question_text" text={question.text} />
                    </QuestionBox>
                </div>

                <div className="QuizReviewBox-Answer__container">
                    {
                        getOptions(question).map((option, idx) => {
                            let optionClass = (sectionData[question.id]?.[idx]?.text == option) ? sectionData[question.id]?.[idx]?.is_correct ? 'Correct-Answer' : 'Incorrect-Answer' : '';
                            return (
                                <div className="QuizReviewBox-Answer__option" key={`${question.id}${option}`}>
                                    <ButtonBox className={`TrueOrFalseBox-Button Option ${optionClass}`} text={option} />
                                    {
                                        revealAnswers && (question.answers.map(a => a.text).includes(option)) && <ImageBox className="Arrow" src="correct-option.svg" />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        ));
    };

    const questionsWithOptions = () => {
        return section?.questions?.map(question => (
            <div className="QuizReviewBox-QuestionAnswer flex-column" index={question.id} key={question.id}>
                <div className="QuizReviewBox-Question__container">
                    <div className="QuizReviewBox-Question__position">
                        <TextBox className="Position" text={question.position} />
                        {
                            isAnswerCorrect(question) ? <ImageBox className="Tick" src="correct-answer.svg" /> : <ImageBox className="Cross" src="incorrect-answer.svg" />
                        }
                    </div>

                    <QuestionBox question={question}>
                        <TextBox className="QuizReviewBox-Question__question QuizReviewBox-Question__question_text" text={question.text} />
                    </QuestionBox>
                </div>

                <div className="QuizReviewBox-Answer__container">
                    {
                        getOptions(question).map(option => {
                            let optionClass = (sectionData[question.id]?.[0]?.text == option) ? sectionData[question.id]?.[0]?.is_correct ? 'Correct-Answer' : 'Incorrect-Answer' : '';
                            return (
                                <div className="QuizReviewBox-Answer__option" key={`${question.id}${option}`}>
                                    <ButtonBox className={`TrueOrFalseBox-Button Option ${optionClass}`} text={option} />
                                    {
                                        revealAnswers && (option == question.answers[0].text) && <ImageBox className="Arrow" src="correct-option.svg" />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        ));
    };

    const getParts = (question) => {
        let splitter = '';

        if (section.section_type === 'fill_in_the_blank') {
            splitter = '__blank__';
        } else if (section.section_type === 'drop_down') {
            splitter = '__drop__';
        } else if (section.section_type === 'drag_and_drop') {
            splitter = '__drag__';
        } else if (section.section_type === 'word_scramble') {
            splitter = '__word__';
        }

        return question.text.split(splitter);
    }

    const questionsWithMultipleParts = () => {
        return section?.questions?.map(question => (
            <div className="QuizReviewBox-QuestionAnswer flex-column" index={question.id} key={question.id}>
                <div className="QuizReviewBox-QuestionAnswer__container">
                    <div className="QuizReviewBox-Question__position">
                        <TextBox className="Position" text={question.position} />
                    </div>

                    <div className="QuizReviewBox-Question__question">
                        {
                            getParts(question).map((part, idx) => {
                                return (
                                    <div className="QuizReviewBox-QA__part" key={`${question.id}${idx}`}>
                                        <div className="Text-Part">
                                            <TextBox className="QuizReviewBox-Question__question_text" text={part} />
                                        </div>

                                        {
                                            question.answers[idx] && (
                                                <div data-tip data-for={`${question.id}${idx}`} className={`Answer-Part ${sectionData[question.id]?.[idx]?.is_correct ? 'Correct-Answer' : 'Incorrect-Answer'}`}>
                                                    <TextBox className="QuizReviewBox-Question__question_text" text={sectionData[question.id]?.[idx]?.text} />
                                                    {
                                                        sectionData[question.id]?.[idx]?.is_correct ? <ImageBox className="Tick" src="correct-answer.svg" /> : <ImageBox className="Cross" src="incorrect-answer.svg" />
                                                    }
                                                    {
                                                        revealAnswers && (
                                                            <ReactTooltip id={`${question.id}${idx}`} place="bottom" type="info" effect="solid">
                                                                {question.answers[idx]?.text}
                                                            </ReactTooltip>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        ));
    };

    const questionsWithSinglePart = () => {
        return section?.questions?.map(question => (
            <div className="QuizReviewBox-QuestionAnswer flex-row" index={question.id} key={question.id}>
                <div className="QuizReviewBox-HalfQuestion__container">
                    <div className="QuizReviewBox-Question__position">
                        <TextBox className="Position" text={question.position} />
                        {
                            isAnswerCorrect(question) ? <ImageBox className="Tick" src="correct-answer.svg" /> : <ImageBox className="Cross" src="incorrect-answer.svg" />
                        }
                    </div>

                    <QuestionBox question={question}>
                        <TextBox className="QuizReviewBox-Question__question QuizReviewBox-Question__question_text" text={question.text} />
                    </QuestionBox>
                </div>

                <div className="QuizReviewBox-HalfAnswer__container">
                    <div className="QuizReviewBox-QA__part">
                        <div data-tip data-for={`${question.id}0`} className={`Answer-Part ${sectionData[question.id]?.[0]?.is_correct ? 'Correct-Answer' : 'Incorrect-Answer'}`}>
                            <TextBox className="QuizReviewBox-Question__question_text" text={sectionData[question.id]?.[0]?.text} />
                            {
                                revealAnswers && (
                                    <ReactTooltip id={`${question.id}0`} place="bottom" type="info" effect="solid">
                                        {question.answers[0].text}
                                    </ReactTooltip>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="QuizReviewBox">
            <div className="Title">
                <TextBox
                    text={`${section?.position}. ${section?.name}`}
                    tag="h3"
                    style={{
                        color: COLORS.Blue,
                        margin: '30px 0',
                    }}
                />

                {
                    (course?.course_type?.name != 'Compliance') &&
                    <CommentsPanelHandler
                        commentableType="Section"
                        commentableId={section.id}
                    />
                }
            </div>

            {
                section.audio_url &&
                <div className="QuizReviewBox-Audio">
                    <AudioPlayButton
                        className="AudioPlayButton-QuizPage"
                        src={section.audio_url}
                    />
                </div>
            }

            <div className="QuizReviewBox-Questions">
                { handleQuestionsRender() }
            </div>
        </div>
    );
};

QuizReviewBox.propTypes = {
    course: PropTypes.object.isRequired,
    section: PropTypes.shape({
        id: PropTypes.number,
        position: PropTypes.number,
        name: PropTypes.string,
        questions: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    revealAnswers: PropTypes.bool.isRequired,
    quizResult: PropTypes.object.isRequired,
};

export default QuizReviewBox;
