import React, { Component } from 'react';
import ReactToolTip from 'react-tooltip';


import './CreateEditLesson.css';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';
import CheckBox
  from '../../Basic/CheckBox/CheckBox';
import { COLORS }
  from '../../../Style/Style';

class CreateEditLesson extends Component {

  state = this.props.lesson ?
  {
    name: this.props.lesson.name,
    description: this.props.lesson.description,
    ignore_grade: this.props.lesson.ignore_grade,
  }
  :
  {
    name: "",
    description: "",
    is_test: false,
    ignore_grade: false,
  }

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleCheckboxChange = field => e => {
    this.setState({ [field]: e.target.checked })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const lesson = Object.assign({}, this.state);

    if(this.props.course) {
      lesson.course_id = this.props.course.id;
    }

    if(this.props.lesson) {
      lesson.id = this.props.lesson.id;
    }

    this.props.submitLesson(lesson).then(
      this.props.closeModal()
    );
  }

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.name}
            placeholder="Name"
            onChange={this.handleInputChange('name')} />
        </div>

        <div
          className="CreationForms-InputBox">
          <textarea
            className="TextAreaBox-CreationForms"
            value={this.state.description}
            placeholder="Description"
            onChange={this.handleInputChange('description')} />
        </div>

        {
          this.props.course &&
          <div
            className="CreateEditLesson-Checkbox-container">
            <div
              className="CreateEditLesson-Checkbox">
              <CheckBox
                checked={this.state.is_test}
                onChange={this.handleCheckboxChange('is_test')} />
            </div>

            <div>
              <div
                data-tip
                data-for="lesson-tooltip">
                <TextBox
                  text="This Lesson is a Test"
                  style={{
                    color: `${COLORS.Blue}`,
                    textDecoration: "none",
                  }}
                />
              </div>
              <ReactToolTip id="lesson-tooltip" type="info">
                <span>Prevents adding any topics. For Final Tests and Pre-Course Assessments.</span>
              </ReactToolTip>
            </div>
          </div>
        }
  
        <div
          className="CreateEditLesson-Checkbox-container">
          <div
            className="CreateEditLesson-Checkbox">
            <CheckBox
              checked={this.state.ignore_grade}
              onChange={this.handleCheckboxChange('ignore_grade')} />
          </div>

          <div>
            <div
              data-tip
              data-for="ignore-grade-tooltip">
              <TextBox
                text="Ignore This Grade"
                style={{
                  color: `${COLORS.Blue}`,
                  textDecoration: "none",
                }}
              />
            </div>
            <ReactToolTip id="ignore-grade-tooltip" type="info">
              <span>Makes the quiz grade for this lesson not count towards certificates.</span>
            </ReactToolTip>
          </div>
        </div>


        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditLesson;
