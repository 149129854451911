import { GET_PROFILE_SUCCESS } from '../../../../Actions/Profile';
import { UPDATE_PROFESSIONAL_INFORMATION } from '../../../../Actions/Profile/Occupation/ProfessionalInformation';

const initialState = {
    occupation: undefined,
    degree: undefined,
    specialty: undefined,
};

const professionalInformation = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE_SUCCESS:
            return action.payload.data.occupation.professionalInformation;

        case UPDATE_PROFESSIONAL_INFORMATION:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

export default professionalInformation;
