import * as APIUtil from '../Util/FlipperApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_ACTOR_ENABLES = 'RECEIVE_ACTOR_ENABLES';
export const CLEAR_ENABLES = 'CLEAR_ENABLES';
export const RECEIVE_ENABLE_ERRORS = 'RECEIVE_ENABLE_ERRORS';

export const receiveActorEnables = payload => ({
  type: RECEIVE_ACTOR_ENABLES,
  payload
});

export const clearEnables = () => ({
  type: CLEAR_ENABLES
});

export const receiveErrors = errors => ({
  type: RECEIVE_ENABLE_ERRORS,
  errors
});

export const fetchActorEnables = (user) => dispatch => (
  APIUtil.fetchActorEnables(user).then(
    payload => dispatch(receiveActorEnables(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

