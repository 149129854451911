import { connect } from 'react-redux';

import ManageTests from './ManageTests';

import {
  clearCredentialTests,
  fetchUserCredentialTests,
  resetCredentialTest,
  waiveCredentialTest,
  checkExistingUser
} from '../../../../../Actions/CredentialTestActions';

const mapStateToProps = (state) => ({
  credentialTests: state.entities.credentialTests || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearCredentialTests: () => dispatch(clearCredentialTests()),
  fetchUserCredentialTests: (userId) => dispatch(fetchUserCredentialTests(userId)),
  resetCredentialTest: (credentialTestId) => dispatch(resetCredentialTest(credentialTestId)),
  waiveCredentialTest: (credentialTestId) => dispatch(waiveCredentialTest(credentialTestId)),
  checkExistingUser: (user) => dispatch(checkExistingUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTests);
