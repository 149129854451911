import { RECEIVE_COURSE_PLEDGES, RECEIVE_COURSE_PLEDGE_ERRORS, CLEAR_COURSE_PLEDGES } from '../../Actions/CoursePledgeActions';

const CoursePledgesReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_COURSE_PLEDGES:
      return Object.assign({}, state, action.payload.coursePledges);
    case CLEAR_COURSE_PLEDGES:
      return {};
    default:
      return state;
  }
}

export default CoursePledgesReducer;
