import React from 'react';

import './CreateEditQuestion.css';
import CreateEditMultipleChoice from './CreateEditMultipleChoice';
import CreateEditMultipleAnswer from './CreateEditMultipleAnswer';
import CreateEditFillInTheBlank from './CreateEditFillInTheBlank';
import CreateEditTrueOrFalse from './CreateEditTrueOrFalse';
import CreateEditMatching from './CreateEditMatching';
import CreateEditDragAndDrop from './CreateEditDragAndDrop';
import CreateEditDropDown from './CreateEditDropDown';
import CreateEditSentenceOrdering from './CreateEditSentenceOrdering';
import CreateEditWordScramble from './CreateEditWordScramble';
import CreateEditSorting from './CreateEditSorting';

const CreateEditQuestionContainer = (props) => {
    const { section, question } = props;
    const type = section ? section.section_type : question.question_type;

    switch (type) {
        case 'multiple_choice':
            return <CreateEditMultipleChoice {...props} />;
        case 'multiple_answer':
            return <CreateEditMultipleAnswer {...props} />;
        case 'fill_in_the_blank':
            return <CreateEditFillInTheBlank {...props} />;
        case 'true_or_false':
            return <CreateEditTrueOrFalse {...props} />;
        case 'matching':
            return <CreateEditMatching {...props} />;
        case 'drag_and_drop':
            return <CreateEditDragAndDrop {...props} />;
        case 'drop_down':
            return <CreateEditDropDown {...props} />;
        case 'sentence_ordering':
            return <CreateEditSentenceOrdering {...props} />;
        case 'word_scramble':
            return <CreateEditWordScramble {...props} />;
        case 'dialog':
            return <CreateEditFillInTheBlank {...props} />;
        case 'table_fill_in':
            return <CreateEditFillInTheBlank {...props} />;
        case 'sorting':
            return <CreateEditSorting {...props} />;
        default:
            return null;
    }
};

export default CreateEditQuestionContainer;
