import {
    RECEIVE_INVITE_USERS_CONFIRMATION,
    RECEIVE_INVITE_USERS_ERRORS,
    CLEAR_INVITE_USERS_ERRORS,
} from '../../Actions/InviteUserActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_INVITE_USERS_CONFIRMATION:
            return { message: action.message };
        case RECEIVE_INVITE_USERS_ERRORS:
            return {};
        case CLEAR_INVITE_USERS_ERRORS:
            return {};
        default:
            return state;
    }
};
