import axios from '../../AxiosUtil';

export const fetchParticipationSummary = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/participation_summary?${query}`).then(
    res => res.data
  );
};

export const fetchUsageMetrics = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/usage_metrics?${query}`).then(
    res => res.data
  );
};

export const fetchPlaylistsMetrics = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/playlists_metrics?${query}`).then(
    res => res.data
  );
};

export const fetchPhrasesMetrics = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/phrases_metrics?${query}`).then(
    res => res.data
  );
};

export const fetchWorkflowsScores = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/workflows_scores?${query}`).then(
    res => res.data
  );
};

export const fetchSkillsMetrics = (query) => {
  return axios.get(`/api/stats/b2b_admin/speaks/skills_metrics?${query}`).then(
    res => res.data
  );
};

