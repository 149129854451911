import * as APIUtil from '../../../Util/Stats/B2BAdmin/CredentialApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_B2B_ADMIN_CREDENTIAL_STATS = 'RECEIVE_B2B_ADMIN_CREDENTIAL_STATS';
export const RECEIVE_B2B_ADMIN_CREDENTIAL_STAT_ERRORS = 'RECEIVE_B2B_ADMIN_CREDENTIAL_STAT_ERRORS';
export const CLEAR_B2B_ADMIN_CREDENTIAL_STATS = 'CLEAR_B2B_ADMIN_CREDENTIAL_STATS';

export const receiveCredentialStats = (payload) => ({
  type: RECEIVE_B2B_ADMIN_CREDENTIAL_STATS,
  payload,
});

export const receiveCredentialStatErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_CREDENTIAL_STAT_ERRORS,
  errors,
});

export const clearCredentialStats = (key) => ({
  type: CLEAR_B2B_ADMIN_CREDENTIAL_STATS,
  key,
});

export const fetchUserParticipation = (queryString) => dispatch => (
  APIUtil.fetchUserParticipation(queryString).then(
    payload => dispatch(receiveCredentialStats(payload)),
    errors => dispatch(receiveCredentialStatErrors(ErrorResponseUtil(errors)))
  )
)

export const fetchUserPerformance = (queryString) => dispatch => (
  APIUtil.fetchUserPerformance(queryString).then(
    payload => dispatch(receiveCredentialStats(payload)),
    errors => dispatch(receiveCredentialStatErrors(ErrorResponseUtil(errors)))
  )
)
