import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';

import { addBreadcrumb, updateBreadcrumb, removeBreadcrumb } from '../../../Actions/BreadcrumbActions';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  addBreadcrumb: (data) => dispatch(addBreadcrumb(data)),
  updateBreadcrumb: (data) => dispatch(updateBreadcrumb(data)),
  removeBreadcrumb: (data) => dispatch(removeBreadcrumb(data)),
});

class ReactBreadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: uuidv4(),
    };
  }

  componentDidMount() {
    const { id } = this.state;
    const { data, hidden, addBreadcrumb } = this.props;

    if (!hidden) {
      addBreadcrumb({ id, ...data });
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.state;
    const { addBreadcrumb, updateBreadcrumb, removeBreadcrumb } = this.props;

    if (!isEqual(prevProps.data, this.props.data)) {
      updateBreadcrumb({ id, ...this.props.data });
    }

    if (!prevProps.hidden && this.props.hidden) {
      removeBreadcrumb({ id, ...this.props.data });
    } else if (prevProps.hidden && !this.props.hidden) {
      addBreadcrumb({ id, ...this.props.data });
    }
  }

  componentWillUnmount() {
    const { id } = this.state;
    const { data, removeBreadcrumb } = this.props

    removeBreadcrumb({ id, ...data });
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactBreadcrumb);
