import { connect } from 'react-redux';

import Users from './Users';

import { fetchInstitutions } from '../../../../../Actions/InstitutionActions';
import { fetchCourses } from '../../../../../Actions/CourseActions';
import { clearTEAdminUsersStats, fetchTEAdminUsersStats } from '../../../../../Actions/Stats/TEAdmin/UsersActions';

const mapStateToProps = (state) => ({
  institutions: state.admin.institutions,
  courses: state.entities.courses,
  total_workplaces: state.stats.teAdmin.users?.total_workplaces || 0,
  processed_workplaces: state.stats.teAdmin.users?.processed_workplaces || 0,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  fetchCourses: () => dispatch(fetchCourses()),
  clearTEAdminUsersStats: () => dispatch(clearTEAdminUsersStats()),
  fetchTEAdminUsersStats: (institutionsFilter, normalFilters, comparisonFilters) => dispatch(fetchTEAdminUsersStats(institutionsFilter, normalFilters, comparisonFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
