import axios from '../AxiosUtil';

export const createCharge = (charge) => {
  return axios.post(`/api/onboarding/charges`, { charge }).then(
    res => res.data
  )
};

export const verifyCoupon = (coupon_code, course_package_id) => {
  return axios.post(`/api/onboarding/charges/verify_coupon`, { coupon_code, course_package_id }).then(
    res => res.data
  )
}
