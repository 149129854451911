import React from 'react';

import TrueOrFalseBox from './TrueOrFalseBox';

const TrueOrFalseContainer = ({ section, newProps }) => {
  return (
    <div className="TrueOrFalseContainer">
      {
        section.questions.map(
          (_, index) =>
            <TrueOrFalseBox
              key={index}
              currentQuestionIndex={index}
              {...newProps}
            />
        )
      }
    </div>
  );
}

export default TrueOrFalseContainer;
