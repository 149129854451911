import {
  RECEIVE_COURSE_PACKAGES,
  RECEIVE_COURSE_PACKAGE,
  CLEAR_COURSE_PACKAGES,
} from '../../Actions/CoursePackageActions';

const CoursePackageReducer = (state = {}, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_COURSE_PACKAGES:
      return Object.assign({}, state, action.payload.coursePackages);
    case RECEIVE_COURSE_PACKAGE:
      return Object.assign({}, state, action.payload.coursePackages);
    case CLEAR_COURSE_PACKAGES:
      return {};
    default:
      return state;
  }
}

export default CoursePackageReducer;
