import './AudioRecordButton.css';
import ReactTooltip from 'react-tooltip';

import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InformationModal from '../../Composed/InformationModal/InformationModal';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

export class AudioRecordButton extends Component {  
    constraints = { audio: true };

    chunks = [];

    constructor(props) {
        super(props);
        this.state = {
            record: false,
            audioURL: '',
            permissionGranted: false,
            showMicNotAllowedInfoModal: false
        };
    }

    componentDidMount() {
        try {
            navigator.mediaDevices.getUserMedia(this.constraints)
                .then(this.handleGetUserMedia, this.handleUserMediaError);
        } catch (errors) {
            console.log(errors);
        }
    }

    componentWillUnmount() {
        if (this.mediaStream) {
            const tracks = this.mediaStream.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        }
    }

    startRecording = () => {
        gaEvent('voice_recording');
        this.chunks = [];
        this.setState({ record: true });
    }

    stopRecording = () => {
        setTimeout(() => {
            this.props.setReloadDialog()
        }, 1000)
        this.setState({ record: false })
    }

    handleData = blob => {
        console.log('chunk of real-time data is: ', blob);
    }

    handleStop = blob => {
        this.setState({
            audioURL: blob.blobURL,
        });
    }

    handleGetUserMedia = stream => {
        this.setState({ permissionGranted: true });

        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.onstop = e => {
            const blob = new Blob(this.chunks, { type: 'audio/mp3' });
            const audioURL = window.URL.createObjectURL(blob);
            this.setState({
                audioURL,
            });
        };

        this.mediaRecorder.ondataavailable = e => {
            this.chunks.push(e.data);
        };
    }

    handleUserMediaError = errors => {
        Sentry.captureException(errors);
    };

    checkPermissionOrStartRecording = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.permissionGranted) {
            this.mediaRecorder.start();
            this.startRecording();
        } else {
            this.setState({ showMicNotAllowedInfoModal: true });
        }
    }

    render() {
        const { audioURL, record, permissionGranted, showMicNotAllowedInfoModal } = this.state;

        return (
            <div className="AudioRecordButton">
                {
                    !permissionGranted &&
                    showMicNotAllowedInfoModal &&
                    <InformationModal
                        title="Microphone permissions"
                        descriptions={['You have not allowed this site to use microphone. Follow below steps to allow and record:']}
                        instructions={['Reset your browser microphone permissions.', 'Reload page.', 'Allow microphone permissions once prompted by browser.']}
                        actionCallBack={() => this.setState({ showMicNotAllowedInfoModal: false })}
                    />
                }

                {
                    record
                        ? (
                            <ButtonBox
                                className="ButtonBox-AudioPlayButton-icon"
                                text={<i className="fa fa-stop" />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.mediaRecorder.stop();
                                    this.stopRecording();
                                }}
                            />
                        )
                        : (
                            <ButtonBox
                                className="ButtonBox-AudioPlayButton-icon"
                                text={<i className="fas fa-microphone" />}
                                onClick={(e) => this.checkPermissionOrStartRecording(e)}
                            />
                        )
                }
                {
                    audioURL.length === 0 ? (
                        <div data-tip data-for="No-Recording" className="No-Recording">
                            <AudioPlayButton src={audioURL} nextPlay={this.props.nextPlay} />
                            <ReactTooltip id="No-Recording" place="bottom" type="dark" effect="solid">
                                Please record yourself in order to play back the audio.
                            </ReactTooltip>
                        </div>
                    ) : (
                        <AudioPlayButton src={audioURL} nextPlay={this.props.nextPlay} />
                    )
                }
            </div>
        );
    }
}

export default AudioRecordButton;
