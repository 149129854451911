import React, { useState } from 'react';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import * as NotificationPopupChannelContent from '../NotificationPopupChannelContent';

import './NotificationPopup.scss';

const NotificationPopup = ({ userNotification, updateUserNotification }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleNotificationClick = () => {
    setShowPopup(true);

    if (!userNotification.channels.popup_displayed_at) {
      updateUserNotification({ id: userNotification.id, popup_displayed_at: (new Date()) });
    }
  }

  return (
    <div className="Notification-Popup-Container">
      <div
        className="Notification"
        onClick={() => handleNotificationClick()}
      >
        {!userNotification.channels.popup_displayed_at && <div className="Unread" />}
        {NotificationPopupChannelContent.titles[userNotification.notification.name]}
      </div>

      {
        showPopup &&
        <div className="Popup">
          <div className="modal">
            <div className="modal-content">
              <div className="modal-header">
                <div className="Heading">
                  {NotificationPopupChannelContent.titles[userNotification.notification.name]}
                </div>
              </div>
              <div className="modal-body">
                {NotificationPopupChannelContent[userNotification.notification.name]}
              </div>
              <div className="modal-footer">
                <div className="Action">
                  <ButtonBox
                    className="Secondary-Btn"
                    text={<span className="Secondary-Btn-Text">Close</span>}
                    onClick={() => setShowPopup(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default NotificationPopup;
