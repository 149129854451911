import { GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE } from '../../../Actions/Profile';
import { UPDATE_LANGUAGE_BACKGROUND, APPEND_EMPTY_LANGUAGE_BACKGROUND, REMOVE_EMPTY_LANGUAGE_BACKGROUND } from '../../../Actions/Profile/Language';

const initialState = [];
const languageBackground = (state = initialState, action) => {
    const emptyProfile = {
        language: '',
        proficiency: '',
        verified_by: '',
        acquired_through: [],
        misc: [],
    };
    switch (action.type) {
        case GET_PROFILE_SUCCESS:
            return action.payload.data.language;

        case GET_PROFILE_FAILURE:
            return initialState;

        case APPEND_EMPTY_LANGUAGE_BACKGROUND: {
            let newState;
            if (!state) {
                newState = [];
            } else {
                newState = [...state];
            }
            newState.push(emptyProfile);
            return newState;
        }

        case REMOVE_EMPTY_LANGUAGE_BACKGROUND: {
            const newState = [...state];
            newState.pop();
            return newState;
        }

        case UPDATE_LANGUAGE_BACKGROUND: {
            const newState = [...state];
            const { currentIndex, ...rest } = action.payload[1];
            newState[currentIndex] = rest;
            return newState;
        }

        default:
            return state;
    }
};

export default languageBackground;
