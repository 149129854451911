import axios from './AxiosUtil';

export const fetchGroups = () => {
    return axios.get('/api/groups').then(
        res => res.data,
    );
};

export const fetchGroup = (groupId) => {
    return axios.get(`/api/groups/${groupId}`).then(
        res => res.data,
    );
};

export const createGroup = group => {
    return axios.post('/api/groups', { group }).then(
        res => res.data,
    );
};

export const editGroup = group => {
    return axios.patch(`/api/groups/${group.id}`, { group }).then(
        res => res.data,
    );
};

export const deleteGroup = groupId => {
    return axios.delete(`/api/groups/${groupId}`).then(
        res => res.data,
    );
};
