import { connect } from 'react-redux';
import CanopyUserDashboard from './CanopyUserDashboard';
import { updateAccount, updatePhoto, updatePassword } from '../../../Actions/SessionActions';
import { fetchCourses } from '../../../Actions/DashboardActions';
import { fetchCourseProgresses } from '../../../Actions/CourseProgressActions';

const mapStateToProps = (state, otherProps) => ({
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace,
    hasSubscriptions: Object.keys(state.dashboard.courses).length > 0,
    courses: state.dashboard.courses,
    courseProgresses: state.statistics.courseProgresses,
    ...otherProps,
});

const mapDispatchToProps = dispatch => ({
    fetchCourseProgresses: () => dispatch(fetchCourseProgresses()),
    updateAccount: user => dispatch(updateAccount(user)),
    updatePhoto: formData => dispatch(updatePhoto(formData)),
    updatePassword: user => dispatch(updatePassword(user)),
    fetchCourses: () => dispatch(fetchCourses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanopyUserDashboard);
