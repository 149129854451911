export const languagesList = {
  '': 'Please select',
  'abkhazian': 'Abkhazian',
  'achinese': 'Achinese',
  'acoli': 'Acoli',
  'adangme': 'Adangme',
  'adyghe': 'Adyghe',
  'afar': 'Afar',
  'afrihili': 'Afrihili',
  'afrikaans': 'Afrikaans',
  'aghem': 'Aghem',
  'ainu': 'Ainu',
  'akan': 'Akan',
  'akkadian': 'Akkadian',
  'akoose': 'Akoose',
  'alabama': 'Alabama',
  'albanian': 'Albanian',
  'aleut': 'Aleut',
  'algerian_arabic': 'Algerian Arabic',
  'american_english': 'American English',
  'american_sign_language': 'American Sign Language',
  'amharic': 'Amharic',
  'ancient_egyptian': 'Ancient Egyptian',
  'ancient_greek': 'Ancient Greek',
  'angika': 'Angika',
  'ao_naga': 'Ao Naga',
  'arabic': 'Arabic',
  'aragonese': 'Aragonese',
  'aramaic': 'Aramaic',
  'araona': 'Araona',
  'arapaho': 'Arapaho',
  'arawak': 'Arawak',
  'armenian': 'Armenian',
  'aromanian': 'Aromanian',
  'arpitan': 'Arpitan',
  'assamese': 'Assamese',
  'asturian': 'Asturian',
  'asu': 'Asu',
  'atsam': 'Atsam',
  'australian_english': 'Australian English',
  'austrian_german': 'Austrian German',
  'avaric': 'Avaric',
  'avestan': 'Avestan',
  'awadhi': 'Awadhi',
  'aymara': 'Aymara',
  'azerbaijani': 'Azerbaijani',
  'badaga': 'Badaga',
  'bafia': 'Bafia',
  'bafut': 'Bafut',
  'bakhtiari': 'Bakhtiari',
  'balinese': 'Balinese',
  'baluchi': 'Baluchi',
  'bambara': 'Bambara',
  'bamun': 'Bamun',
  'banjar': 'Banjar',
  'basaa': 'Basaa',
  'bashkir': 'Bashkir',
  'basque': 'Basque',
  'batak_toba': 'Batak Toba',
  'bavarian': 'Bavarian',
  'beja': 'Beja',
  'belarusian': 'Belarusian',
  'bemba': 'Bemba',
  'bena': 'Bena',
  'bengali': 'Bengali',
  'betawi': 'Betawi',
  'bhojpuri': 'Bhojpuri',
  'bikol': 'Bikol',
  'bini': 'Bini',
  'bishnupriya': 'Bishnupriya',
  'bislama': 'Bislama',
  'blin': 'Blin',
  'blissymbols': 'Blissymbols',
  'bodo': 'Bodo',
  'bosnian': 'Bosnian',
  'brahui': 'Brahui',
  'braj': 'Braj',
  'brazilian_portuguese': 'Brazilian Portuguese',
  'breton': 'Breton',
  'british_english': 'British English',
  'buginese': 'Buginese',
  'bulgarian': 'Bulgarian',
  'bulu': 'Bulu',
  'buriat': 'Buriat',
  'burmese': 'Burmese',
  'caddo': 'Caddo',
  'cajun_french': 'Cajun French',
  'canadian_english': 'Canadian English',
  'canadian_french': 'Canadian French',
  'cantonese': 'Cantonese',
  'capiznon': 'Capiznon',
  'carib': 'Carib',
  'catalan': 'Catalan',
  'cayuga': 'Cayuga',
  'cebuano': 'Cebuano',
  'central_atlas_tamazight': 'Central Atlas Tamazight',
  'central_dusun': 'Central Dusun',
  'central_kurdish': 'Central Kurdish',
  'central_yupik': 'Central Yupik',
  'chadian_arabic': 'Chadian Arabic',
  'chagatai': 'Chagatai',
  'chamorro': 'Chamorro',
  'chechen': 'Chechen',
  'cherokee': 'Cherokee',
  'cheyenne': 'Cheyenne',
  'chibcha': 'Chibcha',
  'chiga': 'Chiga',
  'chimborazo_highland_quichua': 'Chimborazo Highland Quichua',
  'chinese': 'Chinese',
  'chinook_jargon': 'Chinook Jargon',
  'chipewyan': 'Chipewyan',
  'choctaw': 'Choctaw',
  'church_slavic': 'Church Slavic',
  'chuukese': 'Chuukese',
  'chuvash': 'Chuvash',
  'classical_newari': 'Classical Newari',
  'classical_syriac': 'Classical Syriac',
  'colognian': 'Colognian',
  'comorian': 'Comorian',
  'congo_swahili': 'Congo Swahili',
  'coptic': 'Coptic',
  'cornish': 'Cornish',
  'corsican': 'Corsican',
  'cree': 'Cree',
  'creek': 'Creek',
  'crimean_turkish': 'Crimean Turkish',
  'croatian': 'Croatian',
  'czech': 'Czech',
  'dakota': 'Dakota',
  'danish': 'Danish',
  'dargwa': 'Dargwa',
  'dazaga': 'Dazaga',
  'delaware': 'Delaware',
  'dinka': 'Dinka',
  'divehi': 'Divehi',
  'dogri': 'Dogri',
  'dogrib': 'Dogrib',
  'duala': 'Duala',
  'dutch': 'Dutch',
  'dyula': 'Dyula',
  'dzongkha': 'Dzongkha',
  'eastern_frisian': 'Eastern Frisian',
  'efik': 'Efik',
  'egyptian_arabic': 'Egyptian Arabic',
  'ekajuk': 'Ekajuk',
  'elamite': 'Elamite',
  'embu': 'Embu',
  'emilian': 'Emilian',
  'english': 'English',
  'erzya': 'Erzya',
  'esperanto': 'Esperanto',
  'estonian': 'Estonian',
  'european_portuguese': 'European Portuguese',
  'european_spanish': 'European Spanish',
  'ewe': 'Ewe',
  'ewondo': 'Ewondo',
  'extremaduran': 'Extremaduran',
  'fang': 'Fang',
  'fanti': 'Fanti',
  'faroese': 'Faroese',
  'fiji_hindi': 'Fiji Hindi',
  'fijian': 'Fijian',
  'filipino': 'Filipino',
  'finnish': 'Finnish',
  'flemish': 'Flemish',
  'fon': 'Fon',
  'frafra': 'Frafra',
  'french': 'French',
  'friulian': 'Friulian',
  'fulah': 'Fulah',
  'ga': 'Ga',
  'gagauz': 'Gagauz',
  'galician': 'Galician',
  'gan_chinese': 'Gan Chinese',
  'ganda': 'Ganda',
  'gayo': 'Gayo',
  'gbaya': 'Gbaya',
  'geez': 'Geez',
  'georgian': 'Georgian',
  'german': 'German',
  'gheg_albanian': 'Gheg Albanian',
  'ghomala': 'Ghomala',
  'gilaki': 'Gilaki',
  'gilbertese': 'Gilbertese',
  'goan_konkani': 'Goan Konkani',
  'gondi': 'Gondi',
  'gorontalo': 'Gorontalo',
  'gothic': 'Gothic',
  'grebo': 'Grebo',
  'greek': 'Greek',
  'guarani': 'Guarani',
  'gujarati': 'Gujarati',
  'gusii': 'Gusii',
  'gwich_in': 'Gwichʼin',
  'haida': 'Haida',
  'haitian': 'Haitian',
  'hakka_chinese': 'Hakka Chinese',
  'hausa': 'Hausa',
  'hawaiian': 'Hawaiian',
  'hebrew': 'Hebrew',
  'herero': 'Herero',
  'hiligaynon': 'Hiligaynon',
  'hindi': 'Hindi',
  'hiri_motu': 'Hiri Motu',
  'hittite': 'Hittite',
  'hmong': 'Hmong',
  'hungarian': 'Hungarian',
  'hupa': 'Hupa',
  'iban': 'Iban',
  'ibibio': 'Ibibio',
  'icelandic': 'Icelandic',
  'ido': 'Ido',
  'igbo': 'Igbo',
  'iloko': 'Iloko',
  'inari_sami': 'Inari Sami',
  'indonesian': 'Indonesian',
  'ingrian': 'Ingrian',
  'ingush': 'Ingush',
  'interlingua': 'Interlingua',
  'interlingue': 'Interlingue',
  'inuktitut': 'Inuktitut',
  'inupiaq': 'Inupiaq',
  'irish': 'Irish',
  'italian': 'Italian',
  'jamaican_creole_english': 'Jamaican Creole English',
  'japanese': 'Japanese',
  'javanese': 'Javanese',
  'jju': 'Jju',
  'jola_fonyi': 'Jola-Fonyi',
  'judeo_arabic': 'Judeo-Arabic',
  'judeo_persian': 'Judeo-Persian',
  'jutish': 'Jutish',
  'kabardian': 'Kabardian',
  'kabuverdianu': 'Kabuverdianu',
  'kabyle': 'Kabyle',
  'kachin': 'Kachin',
  'kaingang': 'Kaingang',
  'kako': 'Kako',
  'kalaallisut': 'Kalaallisut',
  'kalenjin': 'Kalenjin',
  'kalmyk': 'Kalmyk',
  'kamba': 'Kamba',
  'kanembu': 'Kanembu',
  'kannada': 'Kannada',
  'kanuri': 'Kanuri',
  'kara_kalpak': 'Kara-Kalpak',
  'karachay_balkar': 'Karachay-Balkar',
  'karelian': 'Karelian',
  'kashmiri': 'Kashmiri',
  'kashubian': 'Kashubian',
  'kawi': 'Kawi',
  'kazakh': 'Kazakh',
  'kenyang': 'Kenyang',
  'khasi': 'Khasi',
  'khmer': 'Khmer',
  'khotanese': 'Khotanese',
  'khowar': 'Khowar',
  'kikuyu': 'Kikuyu',
  'kimbundu': 'Kimbundu',
  'kinaray_a': 'Kinaray-a',
  'kinyarwanda': 'Kinyarwanda',
  'kirmanjki': 'Kirmanjki',
  'klingon': 'Klingon',
  'kom': 'Kom',
  'komi': 'Komi',
  'komi_permyak': 'Komi-Permyak',
  'kongo': 'Kongo',
  'konkani': 'Konkani',
  'korean': 'Korean',
  'koro': 'Koro',
  'kosraean': 'Kosraean',
  'kotava': 'Kotava',
  'koyra_chiini': 'Koyra Chiini',
  'koyraboro_senni': 'Koyraboro Senni',
  'kpelle': 'Kpelle',
  'krio': 'Krio',
  'kuanyama': 'Kuanyama',
  'kumyk': 'Kumyk',
  'kurdish': 'Kurdish',
  'kurukh': 'Kurukh',
  'kutenai': 'Kutenai',
  'kwasio': 'Kwasio',
  'kyrgyz': 'Kyrgyz',
  'k_iche': 'Kʼicheʼ',
  'ladino': 'Ladino',
  'lahnda': 'Lahnda',
  'lakota': 'Lakota',
  'lamba': 'Lamba',
  'langi': 'Langi',
  'lao': 'Lao',
  'latgalian': 'Latgalian',
  'latin': 'Latin',
  'latin_american_spanish': 'Latin American Spanish',
  'latvian': 'Latvian',
  'laz': 'Laz',
  'lezghian': 'Lezghian',
  'ligurian': 'Ligurian',
  'limburgish': 'Limburgish',
  'lingala': 'Lingala',
  'lingua_franca_nova': 'Lingua Franca Nova',
  'literary_chinese': 'Literary Chinese',
  'lithuanian': 'Lithuanian',
  'livonian': 'Livonian',
  'lojban': 'Lojban',
  'lombard': 'Lombard',
  'low_german': 'Low German',
  'lower_silesian': 'Lower Silesian',
  'lower_sorbian': 'Lower Sorbian',
  'lozi': 'Lozi',
  'luba_katanga': 'Luba-Katanga',
  'luba_lulua': 'Luba-Lulua',
  'luiseno': 'Luiseno',
  'lule_sami': 'Lule Sami',
  'lunda': 'Lunda',
  'luo': 'Luo',
  'luxembourgish': 'Luxembourgish',
  'luyia': 'Luyia',
  'maba': 'Maba',
  'macedonian': 'Macedonian',
  'machame': 'Machame',
  'madurese': 'Madurese',
  'mafa': 'Mafa',
  'magahi': 'Magahi',
  'main_franconian': 'Main-Franconian',
  'maithili': 'Maithili',
  'makasar': 'Makasar',
  'makhuwa_meetto': 'Makhuwa-Meetto',
  'makonde': 'Makonde',
  'malagasy': 'Malagasy',
  'malay': 'Malay',
  'malayalam': 'Malayalam',
  'maltese': 'Maltese',
  'manchu': 'Manchu',
  'mandar': 'Mandar',
  'mandingo': 'Mandingo',
  'manipuri': 'Manipuri',
  'manx': 'Manx',
  'maori': 'Maori',
  'mapuche': 'Mapuche',
  'marathi': 'Marathi',
  'mari': 'Mari',
  'marshallese': 'Marshallese',
  'marwari': 'Marwari',
  'masai': 'Masai',
  'mazanderani': 'Mazanderani',
  'medumba': 'Medumba',
  'mende': 'Mende',
  'mentawai': 'Mentawai',
  'meru': 'Meru',
  'meta': 'Metaʼ',
  'mexican_spanish': 'Mexican Spanish',
  'micmac': 'Micmac',
  'middle_dutch': 'Middle Dutch',
  'middle_english': 'Middle English',
  'middle_french': 'Middle French',
  'middle_high_german': 'Middle High German',
  'middle_irish': 'Middle Irish',
  'min_nan_chinese': 'Min Nan Chinese',
  'minangkabau': 'Minangkabau',
  'mingrelian': 'Mingrelian',
  'mirandese': 'Mirandese',
  'mizo': 'Mizo',
  'modern_standard_arabic': 'Modern Standard Arabic',
  'mohawk': 'Mohawk',
  'moksha': 'Moksha',
  'moldavian': 'Moldavian',
  'mongo': 'Mongo',
  'mongolian': 'Mongolian',
  'morisyen': 'Morisyen',
  'moroccan_arabic': 'Moroccan Arabic',
  'mossi': 'Mossi',
  'mundang': 'Mundang',
  'muslim_tat': 'Muslim Tat',
  'myene': 'Myene',
  'nama': 'Nama',
  'nauru': 'Nauru',
  'navajo': 'Navajo',
  'ndonga': 'Ndonga',
  'neapolitan': 'Neapolitan',
  'nepali': 'Nepali',
  'newari': 'Newari',
  'ngambay': 'Ngambay',
  'ngiemboon': 'Ngiemboon',
  'ngomba': 'Ngomba',
  'nheengatu': 'Nheengatu',
  'nias': 'Nias',
  'niuean': 'Niuean',
  'nogai': 'Nogai',
  'north_ndebele': 'North Ndebele',
  'northern_frisian': 'Northern Frisian',
  'northern_sami': 'Northern Sami',
  'northern_sotho': 'Northern Sotho',
  'norwegian': 'Norwegian',
  'norwegian_bokmal': 'Norwegian Bokmål',
  'norwegian_nynorsk': 'Norwegian Nynorsk',
  'novial': 'Novial',
  'nuer': 'Nuer',
  'nyamwezi': 'Nyamwezi',
  'nyanja': 'Nyanja',
  'nyankole': 'Nyankole',
  'nyasa_tonga': 'Nyasa Tonga',
  'nyoro': 'Nyoro',
  'nzima': 'Nzima',
  'n_ko': 'NʼKo',
  'occitan': 'Occitan',
  'ojibwa': 'Ojibwa',
  'old_english': 'Old English',
  'old_french': 'Old French',
  'old_high_german': 'Old High German',
  'old_irish': 'Old Irish',
  'old_norse': 'Old Norse',
  'old_persian': 'Old Persian',
  'old_provencal': 'Old Provençal',
  'oriya': 'Oriya',
  'oromo': 'Oromo',
  'osage': 'Osage',
  'ossetic': 'Ossetic',
  'ottoman_turkish': 'Ottoman Turkish',
  'pahlavi': 'Pahlavi',
  'palatine_german': 'Palatine German',
  'palauan': 'Palauan',
  'pali': 'Pali',
  'pampanga': 'Pampanga',
  'pangasinan': 'Pangasinan',
  'papiamento': 'Papiamento',
  'pashto': 'Pashto',
  'pennsylvania_german': 'Pennsylvania German',
  'persian': 'Persian',
  'phoenician': 'Phoenician',
  'picard': 'Picard',
  'piedmontese': 'Piedmontese',
  'plautdietsch': 'Plautdietsch',
  'pohnpeian': 'Pohnpeian',
  'polish': 'Polish',
  'pontic': 'Pontic',
  'portuguese': 'Portuguese',
  'prussian': 'Prussian',
  'punjabi': 'Punjabi',
  'quechua': 'Quechua',
  'rajasthani': 'Rajasthani',
  'rapanui': 'Rapanui',
  'rarotongan': 'Rarotongan',
  'riffian': 'Riffian',
  'romagnol': 'Romagnol',
  'romanian': 'Romanian',
  'romansh': 'Romansh',
  'romany': 'Romany',
  'rombo': 'Rombo',
  'root': 'Root',
  'rotuman': 'Rotuman',
  'roviana': 'Roviana',
  'rundi': 'Rundi',
  'russian': 'Russian',
  'rusyn': 'Rusyn',
  'rwa': 'Rwa',
  'saho': 'Saho',
  'sakha': 'Sakha',
  'samaritan_aramaic': 'Samaritan Aramaic',
  'samburu': 'Samburu',
  'samoan': 'Samoan',
  'samogitian': 'Samogitian',
  'sandawe': 'Sandawe',
  'sango': 'Sango',
  'sangu': 'Sangu',
  'sanskrit': 'Sanskrit',
  'santali': 'Santali',
  'sardinian': 'Sardinian',
  'sasak': 'Sasak',
  'sassarese_sardinian': 'Sassarese Sardinian',
  'saterland_frisian': 'Saterland Frisian',
  'saurashtra': 'Saurashtra',
  'scots': 'Scots',
  'scottish_gaelic': 'Scottish Gaelic',
  'selayar': 'Selayar',
  'selkup': 'Selkup',
  'sena': 'Sena',
  'seneca': 'Seneca',
  'serbian': 'Serbian',
  'serbo_croatian': 'Serbo-Croatian',
  'serer': 'Serer',
  'seri': 'Seri',
  'shambala': 'Shambala',
  'shan': 'Shan',
  'shona': 'Shona',
  'sichuan_yi': 'Sichuan Yi',
  'sicilian': 'Sicilian',
  'sidamo': 'Sidamo',
  'siksika': 'Siksika',
  'silesian': 'Silesian',
  'simplified_chinese': 'Simplified Chinese',
  'sindhi': 'Sindhi',
  'sinhala': 'Sinhala',
  'skolt_sami': 'Skolt Sami',
  'slave': 'Slave',
  'slovak': 'Slovak',
  'slovenian': 'Slovenian',
  'soga': 'Soga',
  'sogdien': 'Sogdien',
  'somali': 'Somali',
  'soninke': 'Soninke',
  'south_azerbaijani': 'South Azerbaijani',
  'south_ndebele': 'South Ndebele',
  'southern_altai': 'Southern Altai',
  'southern_sami': 'Southern Sami',
  'southern_sotho': 'Southern Sotho',
  'spanish': 'Spanish',
  'sranan_tongo': 'Sranan Tongo',
  'standard_moroccan_tamazight': 'Standard Moroccan Tamazight',
  'sukuma': 'Sukuma',
  'sumerian': 'Sumerian',
  'sundanese': 'Sundanese',
  'susu': 'Susu',
  'swahili': 'Swahili',
  'swati': 'Swati',
  'swedish': 'Swedish',
  'swiss_french': 'Swiss French',
  'swiss_german': 'Swiss German',
  'swiss_high_german': 'Swiss High German',
  'syriac': 'Syriac',
  'tachelhit': 'Tachelhit',
  'tagalog': 'Tagalog',
  'tahitian': 'Tahitian',
  'taita': 'Taita',
  'tajik': 'Tajik',
  'talysh': 'Talysh',
  'tamashek': 'Tamashek',
  'tamil': 'Tamil',
  'taroko': 'Taroko',
  'tasawaq': 'Tasawaq',
  'tatar': 'Tatar',
  'telugu': 'Telugu',
  'tereno': 'Tereno',
  'teso': 'Teso',
  'tetum': 'Tetum',
  'thai': 'Thai',
  'tibetan': 'Tibetan',
  'tigre': 'Tigre',
  'tigrinya': 'Tigrinya',
  'timne': 'Timne',
  'tiv': 'Tiv',
  'tlingit': 'Tlingit',
  'tok_pisin': 'Tok Pisin',
  'tokelau': 'Tokelau',
  'tongan': 'Tongan',
  'tornedalen_finnish': 'Tornedalen Finnish',
  'traditional_chinese': 'Traditional Chinese',
  'tsakhur': 'Tsakhur',
  'tsakonian': 'Tsakonian',
  'tsimshian': 'Tsimshian',
  'tsonga': 'Tsonga',
  'tswana': 'Tswana',
  'tulu': 'Tulu',
  'tumbuka': 'Tumbuka',
  'tunisian_arabic': 'Tunisian Arabic',
  'turkish': 'Turkish',
  'turkmen': 'Turkmen',
  'turoyo': 'Turoyo',
  'tuvalu': 'Tuvalu',
  'tuvinian': 'Tuvinian',
  'twi': 'Twi',
  'tyap': 'Tyap',
  'udmurt': 'Udmurt',
  'ugaritic': 'Ugaritic',
  'ukrainian': 'Ukrainian',
  'umbundu': 'Umbundu',
  'upper_sorbian': 'Upper Sorbian',
  'urdu': 'Urdu',
  'uyghur': 'Uyghur',
  'uzbek': 'Uzbek',
  'vai': 'Vai',
  'venda': 'Venda',
  'venetian': 'Venetian',
  'veps': 'Veps',
  'vietnamese': 'Vietnamese',
  'volapuk': 'Volapük',
  'voro': 'Võro',
  'votic': 'Votic',
  'vunjo': 'Vunjo',
  'walloon': 'Walloon',
  'walser': 'Walser',
  'waray': 'Waray',
  'warlpiri': 'Warlpiri',
  'washo': 'Washo',
  'wayuu': 'Wayuu',
  'welsh': 'Welsh',
  'west_flemish': 'West Flemish',
  'western_frisian': 'Western Frisian',
  'western_mari': 'Western Mari',
  'wolaytta': 'Wolaytta',
  'wolof': 'Wolof',
  'wu_chinese': 'Wu Chinese',
  'xhosa': 'Xhosa',
  'xiang_chinese': 'Xiang Chinese',
  'yangben': 'Yangben',
  'yao': 'Yao',
  'yapese': 'Yapese',
  'yemba': 'Yemba',
  'yiddish': 'Yiddish',
  'yoruba': 'Yoruba',
  'zapotec': 'Zapotec',
  'zarma': 'Zarma',
  'zaza': 'Zaza',
  'zeelandic': 'Zeelandic',
  'zenaga': 'Zenaga',
  'zhuang': 'Zhuang',
  'zoroastrian_dari': 'Zoroastrian Dari',
  'zulu': 'Zulu',
  'zuni': 'Zuni'
}
