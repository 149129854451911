import { RECEIVE_REPORT_ERRORS, RECEIVE_REPORT, RECEIVE_REPORTS } from '../../Actions/ReportActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_REPORT_ERRORS:
            return action.errors.data;
        case RECEIVE_REPORTS:
            return {};
        case RECEIVE_REPORT:
            return {};
        default:
            return state;
    }
};
