import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { COLORS } from '../../../../../Style/Style';
import TextBox from '../../../../Basic/TextBox/TextBox';

const UserDetail = ({ user, userDetail, fetchUser, fetchUserPerformance, fetchUserPerformanceCleanup, ...rest }) => {

    const [course, setCourse] = useState({});

    useEffect(() => {
        fetchUserPerformance(rest.match.params.id);
        return fetchUserPerformanceCleanup;
    }, [fetchUserPerformance]);

    useEffect(() => {
        fetchUser(rest.match.params.id);
    }, [fetchUser]);

    const renderLoader = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ScaleLoader
                color={COLORS.greyBlue}
                height={50}
                width={50}
            />
        </div>
    );

    const renderErrorMessage = () => (
        <div>
            Something went wrong. Please refresh and try again.
        </div>
    );

    const renderUserDetail = () => (
        userDetail.data.map(workplace => (
            <div className="User-Detail-WorkPlace">
                <h4 className="workplace-title">{workplace.name}</h4>
                {renderWorkplaceDetail(workplace)}
            </div>
        ))
    );

    const renderWorkplaceDetail = (workplace) => (
        <div className="workplace">
            <select onChange={(e) => setCourse({...course, [workplace.id]: e.target.selectedIndex})}>
                {
                    workplace.courses.map(data => (
                        <option>
                            {data.name}
                        </option>
                    ))
                }
            </select>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <table style={{ tableLayout: 'fixed', width: '100%', backgroundColor: 'white' }}>
                    <thead>
                        <tr>
                            <td style={{ backgroundColor: '#7DE3BB', width: 120 }} />
                            {
                                workplace.courses[course[workplace.id] || 0]?.lessons?.map((result, idx) => (
                                    <td
                                        style={{
                                            backgroundColor: '#7DE3BB',
                                            textAlign: 'center',
                                            color: '#ffffff',
                                            overflow: 'hidden',
                                            padding: 10,
                                        }}
                                        key={idx}
                                    >
                                        {result.name}
                                    </td>
                                ))
                            }
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style={{
                                textAlign: 'center',
                                padding: 10,
                            }}
                            >
                                SCORE
                            </td>
                            {
                                workplace.courses[course[workplace.id] || 0]?.lessons?.map((result, idx) => (
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            color: result.most_recent_quiz_result * 100 < 70 ? 'grey' : 'green',
                                            padding: 10,
                                        }}
                                        key={idx}
                                    >
                                        {`${result.most_recent_quiz_result * 100}%`}
                                    </td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td style={{
                                textAlign: 'center',
                                padding: 10,
                            }}
                            >
                                ATTEMPTS
                            </td>
                            {
                                workplace.courses[course[workplace.id] || 0]?.lessons?.map((result, idx) => (
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            padding: 10,
                                            color: 'grey',
                                        }}
                                        key={idx}
                                    >
                                        {result.quiz_attempts}
                                    </td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td style={{
                                textAlign: 'center',
                                padding: 10,
                            }}
                            >
                                DATE
                            </td>
                            {
                                workplace.courses[course[workplace.id] || 0]?.lessons?.map((result, idx) => (
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            padding: 10,
                                            color: 'grey',
                                        }}
                                        key={idx}
                                    >
                                        {result.most_recent_quiz_result_date?.split("T")[0]}
                                    </td>
                                ))
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    const renderUserHeader = () => (
        <div>
            <TextBox
                text={`${user.data.first_name} ${user.data.last_name}`}
                tag="h4"
                style={{
                    color: COLORS.Blue,
                }}
            />
            <p>{user.data.email}</p>
        </div>
    );

    const decideWhichComponentsToRender = () => {
        if (userDetail.isLoading) {
            return renderLoader();
        }
        if (userDetail.isError) {
            return renderErrorMessage();
        }
        return renderUserDetail();
    };

    const decideWhichHeaderComponentToRender = () => {
        if (user.isLoading) {
            return renderLoader();
        }
        if (user.isError) {
            return renderErrorMessage();
        }
        return renderUserHeader();
    };

    return (
        <div className="B2BAdminDashboard">
            <div className="B2BAdminDashboard-Title">
                {decideWhichHeaderComponentToRender()}
            </div>
            <div>
                {decideWhichComponentsToRender()}
            </div>
        </div>
    );
};

export default UserDetail;
