import axios from './AxiosUtil';

export const fetchPledges = () => {
  return axios.get(`/api/pledges`).then(
    res => res.data,
  );
};

export const scaleUpPledge = (pledge) => {
  return axios.patch(`/api/pledges/${pledge.id}/scale_up`, { pledge }).then(
    res => res.data
  );
};

export const scaleDownPledge = (pledge) => {
  return axios.patch(`/api/pledges/${pledge.id}/scale_down`).then(
    res => res.data
  );
};
