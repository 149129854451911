import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearLearnStats, fetchMyBookmarks } from '../../../../Actions/Stats/Users/LearnActions';

const mapStateToProps = (state) => ({
  bookmarks: state.stats.users.learns?.myBookmarks || []
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchMyBookmarks: () => dispatch(fetchMyBookmarks())
});

const MyBookmarks = ({ bookmarks, clearLearnStats, fetchMyBookmarks, contentWidth }) => {
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({ attribute: 'addedAt', direction: 1 });

  useEffect(() => {
    clearLearnStats('myBookmarks');
    fetchMyBookmarks().then(
      res => setLoading(false)
    );
  }, [fetchMyBookmarks]);

  const applySort = (attribute) => {
    let newSort = JSON.parse(JSON.stringify(sort));

    if (newSort.attribute === attribute) {
      newSort.direction *= -1;
    } else {
      newSort.attribute = attribute;
      newSort.direction = 1;
    }

    setSort(newSort);
  }

  const sortAngle = (attribute) => (
    sort.attribute === attribute && sort.direction === 1 ? 'up' : 'down'
  )

  const sortedStats = () => {
    let bookmarkStats = JSON.parse(JSON.stringify(bookmarks));

    if (sort.attribute === 'addedAt') {
      bookmarkStats.sort((a, b) => (new Date(a[sort.attribute])).getTime() > (new Date(b[sort.attribute])).getTime() ? (sort.direction * -1) : sort.direction);
    } else if (sort.attribute !== '') {
      bookmarkStats.sort((a, b) => a[sort.attribute]['name'] > b[sort.attribute]['name'] ? (sort.direction * -1) : sort.direction);
    }

    return bookmarkStats;
  }

  const buildSlideLink = (bookmark) => {
    let nextToq = bookmark.toq.type === 'Topic' ? `topics/${bookmark.toq.id}` : 'quiz';
    let nextSec = bookmark.slide.position - 1;

    let link = `/learn/courses/${bookmark.course.id}/lessons/${bookmark.lesson.id}/?nextToq=${nextToq}&nextSec=${nextSec}`;

    return (
      <Link to={link}>View</Link>
    )
  }

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
      />
    </Link>
  );

  const renderMyBookmarks = () => (
    <div
      className="Content Dynamic-Width Scrollable-Table"
      style={{
        width: `${contentWidth}px`
      }}
    >
      <table className="Table">
        <thead>
          <tr>
            <th onClick={() => applySort('course')}>
              Course <i className={`fa fa-angle-${sortAngle('course')}`} />
            </th>
            <th onClick={() => applySort('lesson')}>
              Lesson <i className={`fa fa-angle-${sortAngle('lesson')}`} />
            </th>
            <th onClick={() => applySort('toq')}>
              Topic/Quiz <i className={`fa fa-angle-${sortAngle('toq')}`} />
            </th>
            <th onClick={() => applySort('slide')}>
              Slide <i className={`fa fa-angle-${sortAngle('slide')}`} />
            </th>
            <th onClick={() => applySort('addedAt')}>
              Date Added <i className={`fa fa-angle-${sortAngle('addedAt')}`} />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            sortedStats().map((bookmark, idx) => (
              <tr key={`my-bookmark-${idx}`}>
                <td>{bookmark.course.name}</td>
                <td>{bookmark.lesson.name}</td>
                <td>{bookmark.toq.name}</td>
                <td>{bookmark.slide.name}</td>
                <td>{bookmark.addedAt ? dateFormat(bookmark.addedAt, 'mm/dd/yyyy') : 'N/A'}</td>
                <td>
                  <span className="Link">{buildSlideLink(bookmark)}</span>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )

  const renderContent = () => {
    if (loading)
      return <Loader />;

    if (bookmarks.length > 0)
      return renderMyBookmarks();

    return renderEmpty();
  }

  return (
    <div className="My-Bookmarks-Container">
      <div className="Head">
        <p className="Title">Bookmarks</p>
      </div>
      {renderContent()}
    </div>
  )
}

MyBookmarks.propTypes = {
  bookmarks: PropTypes.object.isRequired,
  contentWidth: PropTypes.number.isRequired,
  clearLearnStats: PropTypes.func.isRequired,
  fetchMyBookmarks: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBookmarks);
