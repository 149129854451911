import * as APIUtil from '../Util/InstitutionResourceUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_INSTITUTION_RESOURCE_USERS = 'RECEIVE_INSTITUTION_RESOURCE_USERS';
export const RECEIVE_INSTITUTION_RESOURCE_USER_ERRORS = 'RECEIVE_INSTITUTION_RESOURCE_USER_ERRORS';

export const receiveInstitutionResourceUsers = payload => ({
  type: RECEIVE_INSTITUTION_RESOURCE_USERS,
  payload,
});

export const receiveInstitutionResourceUserErrors = errors => ({
  type: RECEIVE_INSTITUTION_RESOURCE_USER_ERRORS,
  errors,
});

export const institutionResourceUserInteraction = (institutionResourceUserParams) => dispatch => (
  APIUtil.institutionResourceUserInteraction(institutionResourceUserParams).then(
    payload => dispatch(receiveInstitutionResourceUsers(payload)),
    errors  => dispatch(receiveInstitutionResourceUserErrors(ErrorResponseUtil(errors))),
  )
);
