import * as APIUtil from '../Util/UserNotificationApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_USER_NOTIFICATIONS = 'CLEAR_USER_NOTIFICATIONS';
export const RECEIVE_USER_NOTIFICATIONS = 'RECEIVE_USER_NOTIFICATIONS';
export const RECEIVE_USER_NOTIFICATION = 'RECEIVE_USER_NOTIFICATION';
export const RECEIVE_USER_NOTIFICATION_ERRORS = 'RECEIVE_USER_NOTIFICATION_ERRORS';

export const clearUserNotifications = () => ({
  type: CLEAR_USER_NOTIFICATIONS,
});

export const receiveUserNotifications = payload => ({
  type: RECEIVE_USER_NOTIFICATIONS,
  payload,
});

export const receiveUserNotification = payload => ({
  type: RECEIVE_USER_NOTIFICATION,
  payload,
});

export const receiveUserNotificationErrors = errors => ({
  type: RECEIVE_USER_NOTIFICATION_ERRORS,
  errors,
});

export const fetchUserNotifications = (queryString) => dispatch => (
  APIUtil.fetchUserNotifications(queryString).then(
    payload => dispatch(receiveUserNotifications(payload)),
    errors  => dispatch(receiveUserNotificationErrors(ErrorResponseUtil(errors))),
  )
);

export const updateUserNotification = (userNotification) => dispatch => (
  APIUtil.updateUserNotification(userNotification).then(
    payload => dispatch(receiveUserNotification(payload)),
    errors  => dispatch(receiveUserNotificationErrors(ErrorResponseUtil(errors))),
  )
);
