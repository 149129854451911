import * as APIUtil from '../../Util/Onboarding/UserApiUtil';
import ErrorResponseUtil from '../../Util/ErrorResponseUtil';

export const RECEIVE_ONBOARDING_USER = 'RECEIVE_ONBOARDING_USER';
export const RECEIVE_ONBOARDING_USER_ERRORS = 'RECEIVE_ONBOARDING_USER_ERRORS';

export const receiveUser = (payload) => ({
  type: RECEIVE_ONBOARDING_USER,
  payload,
});

export const receiveErrors = (errors) => ({
  type: RECEIVE_ONBOARDING_USER_ERRORS,
  errors,
});

export const createUser = (user) => dispatch => (
  APIUtil.createUser(user).then(
    payload => dispatch(receiveUser(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);

export const fetchUser = (email) => dispatch => (
  APIUtil.fetchUser(email).then(
    payload => dispatch(receiveUser(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);
