import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../AutoComplete/AutoComplete';

import '../SelectCourseEntity.scss';

const SelectLesson = ({ courses, selectionCallBack }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const handleCourseSelection = (e) => {
    setSelectedCourse(e);
    setSelectedLesson(null);
    selectionCallBack({ model: null, id: null });
  }

  const renderCourseInput = () => {
    const selectableCourses = Object.values(courses || {});

    if (selectableCourses.length === 0) {
      return <div className="Empty-Options">No courses available for selection!</div>
    }

    return (
      <div className="Input-Container Course-Input-Container">
        <label className="Label">Course</label>
        <AutoComplete
          className="Select"
          options={selectableCourses.map((crs) => { return { value: crs.id, label: crs.name } })}
          value={selectedCourse}
          onChange={(e) => handleCourseSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  const handleLessonSelection = (e) => {
    setSelectedLesson(e);
    selectionCallBack({ model: 'Lesson', id: e.value });
  }

  const renderLessonInput = () => {
    const selectableLessons = Object.values(courses[selectedCourse.value].lessons || {});

    if (selectableLessons.length === 0) {
      return <div className="Empty-Options">The selected course has no lessons in it!</div>
    }

    return (
      <div className="Input-Container Lesson-Input-Container">
        <label className="Label">Lesson</label>
        <AutoComplete
          className="Select"
          options={selectableLessons.map((lsn) => { return { value: lsn.id, label: lsn.name } })}
          value={selectedLesson}
          onChange={(e) => handleLessonSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  return (
    <div className="Select-Course-Entity-Container Select-Lesson-Container">
      {renderCourseInput()}
      {selectedCourse && renderLessonInput()}
    </div>
  )
}

SelectLesson.propTypes = {
  courses: PropTypes.object,
  selectionCallBack: PropTypes.func.isRequired,
}

export default SelectLesson;
