import { connect } from 'react-redux';

import CreateEditInstitutionResource from './CreateEditInstitutionResource';

import { createInstitutionResource, updateInstitutionResource } from '../../../Actions/InstitutionResourceActions';

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  institutionResource: (state.institutionEntities.institutionResources?.data || {})[ownProps.institutionResourceId],
  platforms: (state.entities.platforms || {}),
  mediaTypes: (state.entities.mediaTypes || {}),
});

const mapDispatchToProps = (dispatch) => ({
  createInstitutionResource: (formData) => dispatch(createInstitutionResource(formData)),
  updateInstitutionResource: (formData) => dispatch(updateInstitutionResource(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditInstitutionResource);
