import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectSectionable from '../../../Composed/SelectCourseEntity/SelectSectionable/SelectSectionable';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { activitySupportedSectionTypes, topicSupportedSectionTypes, quizSupportedSectionTypes } from '../../../../Constants/CourseConstants';

import '../Clone.scss';

const SectionClone = ({ courses, section, fetchCoursesTree, cloneSection }) => {
  const [loading, setLoading] = useState(true);
  const [cloning, setCloning] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [targetSectionable, setTargetSectionable] = useState(null);

  useEffect(() => {
    fetchCoursesTree().then((res) => {
      setLoading(false);
    });
  }, [fetchCoursesTree]);

  const resetErrorAndFlash = () => {
    setErrors({});
    setFlash({});
  }

  const handleTopicSelection = (e) => {
    resetErrorAndFlash();
    setTargetSectionable(e);
  };

  const sectionAllowedToBeCloned = () => (
    (targetSectionable.model === 'Activity' && activitySupportedSectionTypes.includes(section.section_type)) ||
    (targetSectionable.model === 'Topic' && topicSupportedSectionTypes.includes(section.section_type)) ||
    (targetSectionable.model === 'Quiz' && quizSupportedSectionTypes.includes(section.section_type))
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrorAndFlash();

    if (!targetSectionable?.model || !targetSectionable?.id) {
      setErrors({ '': 'Please select target activity, topic, or a quiz!' });
      return null;
    }

    if (!sectionAllowedToBeCloned()) {
      setErrors({ '': `The section type "${section.section_type}" can not be cloned in ${targetSectionable.model}` });
      return null;
    }

    setCloning(true);
    cloneSection(targetSectionable.model, targetSectionable.id, section.id).then((res) => {
      res.message ? setFlash({ message: res.message }) : setErrors({ '': res.error });
      setCloning(false);
    });
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Clone-Modal-Container Section-Clone-Container">
      {cloning && <MirrorLoader message="Cloning Section!" />}

      <ErrorFlashBox errors={errors} flash={flash} />

      <SelectSectionable
        courses={courses}
        selectionCallBack={(e) => handleTopicSelection(e)}
      />

      <div className="Buttons-Container">
        <ButtonBox
          className="CreationDashboards-ButtonBox Clone-Button"
          text="Clone Section"
          onClick={e => handleSubmit(e)}
        />
      </div>
    </div>
  )
}

SectionClone.propTypes = {
  courses: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  fetchCoursesTree: PropTypes.func.isRequired,
  cloneSection: PropTypes.func.isRequired,
};

export default SectionClone;
