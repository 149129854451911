import React, { Component } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

class CreateEditMultipleChoice extends Component {
    state = this.props.question ?
    {
        text: this.props.question.text,
        hint: this.props.question.hint,
        options_attributes: this.props.question.options
    }
    :
    {
        text: "",
        hint: "",
        options_attributes: [{ text: "", audio_url: "", index: 0 }]
    }

    handleInputChange = field => e => this.setState({
        [field]: e.currentTarget.value,
    });

    handleOptionInput = idx => e => {
        const options_attributes = [...this.state.options_attributes];
        options_attributes[idx].text = e.currentTarget.value;
        this.setState({
            options_attributes,
        })
    };

    handleAddOption = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
        options_attributes: [...prevState.options_attributes, { text: "", audio_url: "", index: 0 }],
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const question = Object.assign({}, this.state);

        if (this.props.section) {
        question.section_id = this.props.section.id;
        question.question_type = this.props.section.section_type;
        }

        if (this.props.question) {
        question.id = this.props.question.id;
        }

        this.props.submitQuestion(question).then(
        this.props.closeModal()
        );
    }

    handleDeleteOption = idx => e => {
        e.preventDefault();
        const options_attributes = [...this.state.options_attributes];
        options_attributes[idx]._destroy = true;
        this.setState({
            options_attributes,
        })
    }

    handleOptionsRender() {
        return this.state.options_attributes.map(
            (option, idx) => {
                if (option._destroy) {
                    return null;
                }

                return (
                    <div
                        className="CreationForms-InputBox"
                        key={idx}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <InputBox
                            className="InputBox-LogInBox"
                            value={this.state.options_attributes[idx].text}
                            placeholder={`Option ${idx + 1}`}
                            onChange={this.handleOptionInput(idx)}
                            onBlur={this.handleBlur()}
                            onFocus={this.handleFocus()}
                        />

                        <ButtonBox
                            className="ButtonBox-EditQuestions"
                            text={<i className="far fa-trash-alt" />}
                            onClick={this.handleDeleteOption(idx)}
                        />
                    </div>
                )
            }
        );
    }

    handleBlur = () => e => {
        e.currentTarget.id = "";
    };

    handleFocus = () => e => {
        e.currentTarget.id = "active";
    };

    render() {
        return (
            <form
                className="CreationForms-Form"
                onSubmit={this.handleSubmit}
            >
                <div className="CreationForms-InputBox">
                    <textarea
                        ref={this.textArea}
                        className="TextAreaBox-CreationForms"
                        value={this.state.text}
                        placeholder="Text Here"
                        onChange={this.handleInputChange('text')}
                        onBlur={this.handleBlur()}
                        onFocus={this.handleFocus()}
                    />
                </div>

                <div className="CreationForms-OptionsBox">
                    {
                        this.handleOptionsRender()
                    }

                    <div className="CreationForms-Button">
                        <ButtonBox
                            className="CreationDashboards-ButtonBox"
                            text="Add an Option"
                            onClick={this.handleAddOption}
                        />
                    </div>
                </div>

                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.hint}
                        placeholder="Hint (Optional)"
                        onChange={this.handleInputChange('hint')}
                        onBlur={this.handleBlur()}
                        onFocus={this.handleFocus()}
                    />
                </div>

                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Submit"
                    />
                </div>
            </form>
        );
    }
}

export default CreateEditMultipleChoice;
