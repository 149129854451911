import React, { Component } from 'react';

import './CreationLearningDashboard.css'
import { COLORS }
  from '../../../Style/Style';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import Modal
  from '../../Composed/Modal/Modal';
import CreateEditTopic
  from '../../Forms/CreateEditTopic/CreateEditTopic';
import CreateEditSection
  from '../../Forms/CreateEditSection/CreateEditSection';
import SectionSummaryBox
  from '../../Composed/SectionSummaryBox/SectionSummaryBoxContainer';
import ErrorFlashBox
  from '../../Composed/ErrorFlashBox/ErrorsFlashBox';

class CreationLearningDashboard extends Component {

  componentDidMount() {
    let queryString = new URLSearchParams(this.props.location.search);

    this.props.clearTopics();
    this.props.clearTopicErrors();
    this.props.fetchTopic(this.props.currentTopicId).then(() => {
      if (queryString.has('nextSec')) {
        let scrollElement = document.getElementsByClassName('SectionBox')[parseInt(queryString.get('nextSec'))];
        window.scrollTo({ top: (scrollElement.offsetTop - 100), behavior: 'smooth' });
        scrollElement.querySelector('.Comments-Container .Comment-Btn').click();
      }
    });
  }

  render() {
    const { course, topic, editTopic, createSection, topicErrors, 
            topicFlash } = this.props;

    if (!topic || !topic.sections || 
        (topic.sections.length && !topic.sections[0].text_contents)) {
      return <div className="CreationDashboards"></div>
    }

    return (
      <div
        className="CreationDashboards">
        <div
          className="CreationDashboards-Menu">
          <TextBox
            text={topic.name}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Edit Topic"
            form={
              close =>
                <CreateEditTopic
                  topic={topic}
                  submitTopic={editTopic}
                  closeModal={close} />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Edit Topic"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <ErrorFlashBox
          errors={topicErrors}
          flash={topicFlash} />

        <div
          className="CreationDashboards-DescriptionBox">
          <TextBox
            text="Time Limit: "
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h5"
          />

          <TextBox
            text={topic.time_limit ? `${topic.time_limit} minutes` : "none"}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="p"
          />
        </div>

        <div
          className="CreationDashboards-Menu">
          <TextBox
            text="Sections"
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Add A Section"
            form={
              close =>
                <CreateEditSection
                  topic={topic}
                  submitSection={createSection}
                  closeModal={close}
                  learning />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Add A Section"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <SectionSummaryBox topicId={topic.id} course={course} />
      </div>
    );
  }
}

export default CreationLearningDashboard;
