import {
  RECEIVE_USER_STREAKS,
  CLEAR_USER_STREAKS,
  RECEIVE_LEADERBOARD,
  CLEAR_LEADERBOARD,
} from '../../Actions/Engage/StreakActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_USER_STREAKS:
      return Object.assign({}, state, action.payload);
    case CLEAR_USER_STREAKS:
      return Object.assign({}, state, { userStreaks: null });
    case RECEIVE_LEADERBOARD:
      return Object.assign({}, state, action.payload);
    case CLEAR_LEADERBOARD:
      return Object.assign({}, state, { leaderboard: null });
    default:
      return state;
  }
};
