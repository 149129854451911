import { schema } from 'normalizr';

export const sibling = new schema.Entity('siblings');

export const lesson = new schema.Entity('lessons', {
  topics: [sibling]
})

export const topic = new schema.Entity('topics', {
  lesson: lesson
});