import {
  RECEIVE_ONBOARDING_USER,
  RECEIVE_ONBOARDING_USER_ERRORS
} from '../../Actions/Onboarding/UserActions';

const UsersReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ONBOARDING_USER:
      return Object.assign({}, state, action.payload);
    default:
      return {};
  }
}

export default UsersReducer;
