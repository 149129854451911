import axios from './AxiosUtil';
import { receiveStreamAndSupplyEachChunk } from './EventSourceUtil';

export const fetchRosterManagement = (queryString) => {
  return axios.get(`/api/roster_managements?${queryString}`).then(
    res => res.data
  )
};

export const createUserRosterUpload = (userRosterUpload, supplyChunk) => {
  return receiveStreamAndSupplyEachChunk(
    '/api/roster_managements',
    supplyChunk,
    userRosterUpload,
  )
}
