import axios from './AxiosUtil';

export const fetchBookmarkIds = () => {
  return axios.get('/api/bookmarks/ids').then(
    res => res.data
  )
}

export const fetchBookmarkGen = () => {
  return axios.get('/api/bookmarks/gen').then(
    res => res.data
  )
}

export const createBookmark = (bookmark) => {
  return axios.post('/api/bookmarks/bookmark', { bookmark: bookmark }).then(
    res => res.data
  )
}

export const deleteBookmark = (bookmark) => {
  return axios.post('/api/bookmarks/unbookmark', { bookmark: bookmark }).then(
    res => res.data
  )
}
