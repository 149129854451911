import sanitizeHtml from 'sanitize-html';

export const sanitizeHtmlString = (htmlString) => (
  sanitizeHtml(htmlString, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      a: ['href', 'name', 'target', 'class', 'style'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading', 'class', 'style'],
      b: ['class', 'style'],
      div: ['class', 'style'],
      em: ['class', 'style'],
      h1: ['class', 'style'],
      h2: ['class', 'style'],
      h3: ['class', 'style'],
      h4: ['class', 'style'],
      h5: ['class', 'style'],
      h6: ['class', 'style'],
      i: ['class', 'style'],
      li: ['class', 'style'],
      ol: ['class', 'style'],
      p: ['class', 'style'],
      pre: ['class', 'style'],
      s: ['class', 'style'],
      span: ['class', 'style'],
      strong: ['class', 'style'],
      sub: ['class', 'style'],
      sup: ['class', 'style'],
      u: ['class', 'style'],
      ul: ['class', 'style'],
    },
    allowedStyles: {
      '*': {
        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'text-align': [/^left$/, /^right$/, /^center$/],
        'font-size': [/^\d+(?:px|em|%)$/],
      },
    },
    allowedSchemesByTag: { img: ['data'] },
  })
)
