import React from 'react';
import { Link } from 'react-router-dom';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './InProgress.scss';

const InProgress = ({ course }) => {
  return (
    <div className="InProgress-Container">
      <div className="Primary-Info-Block">
        <div className="Percentage">
          -
        </div>
        <div className="Description">
          You have not completed this course yet!
        </div>
      </div>

      <div className="Secondary-Info-Block">
        <Link to={`/learn/courses/${course.id}/lessons`} className="Link">
          <ButtonBox
            className="Continue-Btn"
            text={<span className="Text">Complete Now!</span>}
          />
        </Link>
      </div>
    </div>
  )
}

export default InProgress;
