import axios from './AxiosUtil';

export const fetchUserNotifications = (queryString) => {
  return axios.get(`/api/user_notifications?${queryString}`).then(
    res => res.data
  );
};

export const updateUserNotification = (userNotification) => {
  return axios.put(`/api/user_notifications/${userNotification.id}`, { user_notification: userNotification }).then(
    res => res.data,
  );
};
