import * as APIUtil from '../Util/CanopySubscriptionApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_CANOPY_SUBSCRIPTIONS = 'RECEIVE_CANOPY_SUBSCRIPTIONS'
export const RECEIVE_CANOPY_SUBSCRIPTION_ERRORS = 'RECEIVE_CANOPY_SUBSCRIPTION_ERRORS'
export const CLEAR_CANOPY_SUBSCRIPTIONS = 'CLEAR_CANOPY_SUBSCRIPTIONS'

export const receiveCanopySubscription = payload => ({
  type: RECEIVE_CANOPY_SUBSCRIPTIONS,
  payload
});

export const receiveErrors = errors => ({
  type: RECEIVE_CANOPY_SUBSCRIPTION_ERRORS,
  errors,
});

export const clearCanopySubscriptions = () => {
  return {
    type: CLEAR_CANOPY_SUBSCRIPTIONS,
  };
};

export const fetchCanopySubscriptions = () => dispatch => (
  APIUtil.fetchCanopySubscriptions().then(
    payload => dispatch(receiveCanopySubscription(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
