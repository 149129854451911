import {
  RECEIVE_TOPIC,
  CLEAR_TOPIC_ERRORS,
  RECEIVE_TOPIC_ERRORS,
} from '../../Actions/TopicActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TOPIC:
      if (action.message) {
        return { message: action.message }
      }
      return state;
    case RECEIVE_TOPIC_ERRORS:
      return {};
    case CLEAR_TOPIC_ERRORS:
      return {};
    default:
      return state;
  }
};