export const CanopyCredentialLink = (domain, hasLicense, allCredentialTests) => {
  if (!hasLicense)
    return `${domain}/products`;

  let hasAssessments = allCredentialTests?.length > 0;
  if (!hasAssessments)
    return `${domain}/`;

  let activeCredentialTests = allCredentialTests?.filter(ct => !ct.is_waived);
  let hasPendingAssessment  = activeCredentialTests?.find(ct => !['terminated', 'rated'].includes(ct.sapioTest?.status));
  if (hasPendingAssessment)
    return `${domain}/`;

  let mostRecentCredentialTest = activeCredentialTests[0];
  let todayTimeStamp           = (new Date()).getTime();
  let lastAssessmentTimeStamp  = mostRecentCredentialTest?.sapioTest?.end_time ? (new Date(mostRecentCredentialTest.sapioTest?.end_time)).getTime() : todayTimeStamp;
  let lastAssessmentWaited     = ((todayTimeStamp - lastAssessmentTimeStamp) / 86400000) > 90;

  if (lastAssessmentWaited)
    return `${domain}/products`;

  return `${domain}/`;
}
