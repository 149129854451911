import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './CreateEditCourse.css';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

class CreateEditCourse extends Component {
    constructor(props) {
        super(props);
        const { course, courseTypes } = this.props;
        this.state = course
            ? {
                name: course.name,
                description: course.description,
                level: course.level,
                series: course.series,
                language: course.language,
                course_type_id: course.course_type_id,
                education_credits_attributes: course.education_credits,
            }
            : {
                name: '',
                description: '',
                level: '',
                series: '',
                language: '',
                course_type_id: courseTypes[0]?.id,
                education_credits_attributes: [],
            };
    }

    handleInputChange = field => e => this.setState({
        [field]: e.currentTarget.value,
    });

    handleSelectChange = e => {
        this.setState({ course_type_id: e.target.value });
    }

    handleCreditInput = (idx, attr) => {
        return (e) => {
            const education_credits_attributes = [...this.state.education_credits_attributes];
            education_credits_attributes[idx][attr] = e.currentTarget.value;
            this.setState({
                education_credits_attributes,
            });
        };
    }

    handleAddCredit = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            education_credits_attributes: [
                ...prevState.education_credits_attributes,
                { credit_type: '', amount: '', credit_for: '' },
            ],
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const course = Object.assign({}, this.state);

        if (this.props.course) {
            course.id = this.props.course.id;
        }

        this.props.submitCourse(course).then(
            this.props.closeModal(),
        );
    }

    handleCreditsRender() {
        return this.state.education_credits_attributes.map(
            (_, idx) => (
                <div
                    className="CreationForms-Credits"
                    key={idx}
                >
                    <div className="CreationForms-InputBox">
                        <InputBox
                            className="InputBox-LogInBox"
                            value={this.state.education_credits_attributes[idx].amount}
                            placeholder="Credit Amount"
                            onChange={this.handleCreditInput(idx, 'amount')}
                        />
                    </div>

                    <div className="CreationForms-InputBox">
                        <InputBox
                            className="InputBox-LogInBox"
                            value={this.state.education_credits_attributes[idx].credit_type}
                            placeholder="Credit Type"
                            onChange={this.handleCreditInput(idx, 'credit_type')}
                        />
                    </div>

                    <div className="CreationForms-InputBox">
                        <InputBox
                            className="InputBox-LogInBox"
                            value={this.state.education_credits_attributes[idx].credit_for}
                            placeholder="Credit For"
                            onChange={this.handleCreditInput(idx, 'credit_for')}
                        />
                    </div>
                </div>
            ),
        );
    }

    render() {
        return (
            <form
                className="CreationForms-Form"
                onSubmit={this.handleSubmit}
            >
                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.name}
                        placeholder="Name"
                        onChange={this.handleInputChange('name')}
                    />
                </div>

                <div className="CreationForms-InputBox">
                    <textarea
                        className="TextAreaBox-CreationForms"
                        value={this.state.description}
                        placeholder="Description"
                        onChange={this.handleInputChange('description')}
                    />
                </div>

                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.level}
                        placeholder="Level"
                        onChange={this.handleInputChange('level')}
                        type="number"
                    />
                </div>

                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.series}
                        placeholder="Series"
                        onChange={this.handleInputChange('series')}
                    />
                </div>

                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.language}
                        placeholder="Language"
                        onChange={this.handleInputChange('language')}
                    />
                </div>

                <div className="CreationForms-SelectBox CourseType-Select">
                    <select
                        onChange={this.handleSelectChange}
                        defaultValue={this.state.course_type_id}
                    >
                        {
                            Object.values(this.props.courseTypes).map((courseType, idx) => {
                                return (
                                    <option value={courseType.id} key={idx}>
                                        {courseType.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="CreationForms-OptionsBox">
                    {
                        this.handleCreditsRender()
                    }

                    <div className="CreationForms-Button">
                        <ButtonBox
                            className="CreationDashboards-ButtonBox"
                            text="Add Credit"
                            onClick={this.handleAddCredit}
                        />
                    </div>
                </div>

                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Submit"
                    />
                </div>
            </form>
        );
    }
}

CreateEditCourse.propTypes = {
    course: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        level: PropTypes.number,
        series: PropTypes.string,
        education_credits: PropTypes.arrayOf(PropTypes.object),
    })
};

export default CreateEditCourse;
