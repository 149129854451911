import {
    RECEIVE_LICENSES,
    RECEIVE_DELETE_SUCCESS,
} from '../../Actions/LicenseActions';

const InstitutionsReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_LICENSES:
            return Object.assign({}, action.payload);
        case RECEIVE_DELETE_SUCCESS:
            const newState = Object.assign({}, state);
            delete newState[action.licenseId];
            return newState;
        default:
            return state;
    }
};

export default InstitutionsReducer;