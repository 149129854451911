import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

/**
  since the component has a default className for styling, it does not
  accept a classname at all. in case of any design need please wrap it
  in a div in your parent component.
*/
import { STYLE } from './CircularProgressBarStyle';

const CircularProgressBar = ({ totalNumberOfSteps, completedSteps, showText }) => {
  const buildText = () => {
    return showText ? `${completedSteps}/${totalNumberOfSteps}` : '';
  }

  return (
      <CircularProgressbar
        value={ (completedSteps / totalNumberOfSteps) * 100 }
        text={buildText()}
        background
        backgroundPadding={6}
        styles={STYLE}
      />
  );
};

export default CircularProgressBar;
