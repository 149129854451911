import { connect } from 'react-redux';

import UserResources from './UserResources';

import { clearUserResources, fetchUserResources } from '../../../../Actions/UserResourceActions';

const mapStateToProps = (state) => ({
  userResources: state.userEntities.userResources || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearUserResources: () => dispatch(clearUserResources()),
  fetchUserResources: () => dispatch(fetchUserResources()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserResources);

