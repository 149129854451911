import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sum } from 'lodash';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { clearComplianceStats, fetchUserParticipation } from '../../../../Actions/Stats/B2BAdmin/ComplianceActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  userParticipation: state.stats.b2bAdmin.compliances.userParticipation || {},
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearComplianceStats: (key) => dispatch(clearComplianceStats(key)),
  fetchUserParticipation: (queryString) => dispatch(fetchUserParticipation(queryString))
});

const UserParticipation = ({ userParticipation, filters, clearComplianceStats, fetchUserParticipation }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    clearComplianceStats('userParticipation');
    fetchUserParticipation(hashToQueryString(filters)).then(res => setLoading(false))
  }, [fetchUserParticipation, filters]);

  const renderData = () => {
    let userCourseProgresses  = Object.values(userParticipation.userCourseProgresses || {})
    let completedUsers = userCourseProgresses.filter(cp => cp.completedAt).length;
    let startedUsers = userCourseProgresses.length;
    let totalUsers = userParticipation.totalUsers;

    return (
      <div className="Badge-Container">
        <div className="Arrow-Box-Holder Invitations-Holder">
          <div className="Text-Heading">
            Started Course
          </div>

          <div className="Shape">
            <ImageBox src="arrow.svg" className="Arrow-Icon" />
            <h4 className="Box-Inner-Text">
              <div className="Digit">{startedUsers}</div>
              <div className="Text">users</div>
            </h4>
          </div>
        </div>

        <div className="Combo-Badge-Holder Completed-Training-Holder">
          <div className="Text-Heading">
            Completed Training
          </div>
          <div className="Shape">
            <ImageBox src="combo.svg" className="Badge-Icon" />
            <div className="Inner-Text">
              <div className="Digit">{Math.round(((completedUsers / totalUsers) || 0) * 100)}%</div>
              <div className="Text">workforce</div>
            </div>
          </div>
          <div className="Total-Prograss">
            {completedUsers} completed / {totalUsers} total
          </div>
        </div>

        <div className="Arrow-Box-Holder Action-Plan-Holder">
          <div className="Text-Heading">
            <span>Created</span>&nbsp;
            <span>Personal Action Plan</span>
          </div>
          <div className="Shape">
            <ImageBox src="arrow-2.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{userParticipation.userPledges?.activeUsers || 0}</div>
              <div className="Text">user</div>
            </div>
          </div>
        </div>

        <div className="Arrow-Box-Holder Pledge-Progress-Holder">
          <div className="Text-Heading">
            Pledge in Progress
          </div>
          <div className="Shape">
            <ImageBox src="arrow-2.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{userParticipation.userPledges?.inProgress || 0}</div>
              <div className="Text">Pledges</div>
            </div>
          </div>
        </div>

        <div className="Combo-Badge-Holder Pledges-Completed-Holder">
          <div className="Text-Heading">
            Pledges Completed
          </div>
          <div className="Shape">
            <ImageBox src="combo.svg" className="Badge-Icon" />
            <div className="Inner-Text">
              <div className="Digit">{userParticipation.userPledges?.completed || 0}</div>
              <div className="Text">Pledges</div>
            </div>
          </div>
        </div>

        <div className="Arrow-Box-Holder Pledges-Schedule-Holder">
          <div className="Text-Heading">
            <span>Pledges</span>&nbsp;
            <span>Behind Schedule</span>
          </div>
          <div className="Shape">
            <ImageBox src="arrow-3.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{userParticipation.userPledges?.behindSchedule || 0}</div>
              <div className="Text">Users</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      The users has not made any progress yet!
    </div>
  )

  const renderContent = () => {
    if (loading)
      return <Loader />

    if (Object.keys(userParticipation).length === 0)
      return renderEmpty();

    return renderData();
  }

  return (
    <div className="Compliance-User-Participation">
      <h2 className="Title">User Participation</h2>
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserParticipation);
