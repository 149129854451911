import axios from '../../AxiosUtil';

export const fetchUserParticipation = (queryString) => {
  return axios.get(`/api/stats/b2b_admin/learns/user_participation?${queryString}`).then(
    res => res.data
  )
};

export const fetchUserPerformance = (queryString) => {
  return axios.get(`/api/stats/b2b_admin/learns/user_performance?${queryString}`).then(
    res => res.data
  )
};
