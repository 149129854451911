export const RECEIVE_FILTER = 'RECEIVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const receiveFilter = (filter, value) => ({
  type: RECEIVE_FILTER,
  filter,
  value
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS
});
