import {
    RECEIVE_QUIZ_RESULT,
    RECEIVE_QUIZ_RESULT_V2,
    RECEIVE_QUIZ_RESULTS,
    CLEAR_QUIZ_RESULTS
} from '../../Actions/QuizActions';

export default (state = {}, action) => {
    Object.freeze(state);

    switch (action.type) {
        case RECEIVE_QUIZ_RESULT:
            let existingResults = Object.assign([], (state[action.payload.quiz_id] || []));
            existingResults.push(action.payload);
            return Object.assign({}, state, { [action.payload.quiz_id]: existingResults.sort((a, b) => a.num_correct > b.num_correct ? -1 : 1) });
        case RECEIVE_QUIZ_RESULT_V2:
            return Object.assign({}, state, { [action.payload.quiz_id]: [action.payload] });
        case RECEIVE_QUIZ_RESULTS:
            const newState = {};
            action.payload.forEach(result => {
                if (newState[result.quiz_id]) {
                    newState[result.quiz_id].push(result);
                } else {
                    newState[result.quiz_id] = [result];
                }
            });
            return Object.assign({}, state, newState);
        case CLEAR_QUIZ_RESULTS:
            return {};
        default:
            return state;
    }
};
