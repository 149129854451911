export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB';
export const REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB';

export const clearBreadcrumbs = () => ({
  type: CLEAR_BREADCRUMBS,
});

export const addBreadcrumb = (payload) => ({
  type: ADD_BREADCRUMB,
  payload,
});

export const updateBreadcrumb = (payload) => ({
  type: UPDATE_BREADCRUMB,
  payload,
});

export const removeBreadcrumb = (payload) => ({
  type: REMOVE_BREADCRUMB,
  payload,
});
