import {
    CLEAR_CREATE_USER_ERRORS,
    RECEIVE_CHECK_USER_CONFIRMATION,
    RECEIVE_CREATE_USER_CONFIRMATION,
    RECEIVE_CREATE_USER_ERRORS,
} from '../../Actions/CreateUserActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case CLEAR_CREATE_USER_ERRORS:
            return {};
        case RECEIVE_CHECK_USER_CONFIRMATION:
            if (!action.exists) {
                return { message: action.message };
            }
            return {};
        case RECEIVE_CREATE_USER_CONFIRMATION:
            return { message: action.message };
        case RECEIVE_CREATE_USER_ERRORS:
            return {};
        default:
            return state;
    }
};
