import {
    FETCH_ACTIVITY_REQUEST,
    FETCH_ACTIVITY_SUCCESS,
    FETCH_ACTIVITY_ERROR,
    FETCH_ALL_LESSONS_REQUEST,
    FETCH_ALL_LESSONS_SUCCESS,
    FETCH_ALL_LESSONS_ERROR,
    FETCH_COURSES_REQUEST,
    FETCH_COURSES_SUCCESS,
    FETCH_COURSES_ERROR,
    FETCH_COURSE_PROGRESSES_REQUEST,
    FETCH_COURSE_PROGRESSES_SUCCESS,
    FETCH_COURSE_PROGRESSES_ERROR,
    FETCH_TOPICS_COUNTS_REQUEST,
    FETCH_TOPICS_COUNTS_SUCCESS,
    FETCH_TOPICS_COUNTS_ERROR,
} from '../Actions/DashboardActions';

const initialState = {
    allLessons: {},
    courses: {},
    courseProgresses: [],
    topicsCount: {},
    activity: [],
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITY_REQUEST:
            return state;
        case FETCH_ACTIVITY_SUCCESS:
            return { ...state, activity: action.payload };
        case FETCH_ACTIVITY_ERROR:
            return state;
        case FETCH_ALL_LESSONS_REQUEST:
            return state;
        case FETCH_ALL_LESSONS_SUCCESS:
            return { ...state, allLessons: action.payload };
        case FETCH_ALL_LESSONS_ERROR:
            return state;
        case FETCH_COURSES_REQUEST:
            return state;
        case FETCH_COURSES_SUCCESS:
            return { ...state, courses: action.payload };
        case FETCH_COURSES_ERROR:
            return state;
        case FETCH_COURSE_PROGRESSES_REQUEST:
            return state;
        case FETCH_COURSE_PROGRESSES_SUCCESS:
            return { ...state, courseProgresses: Object.values(action.payload) };
        case FETCH_COURSE_PROGRESSES_ERROR:
            return state;
        case FETCH_TOPICS_COUNTS_REQUEST:
            return state;
        case FETCH_TOPICS_COUNTS_SUCCESS:
            return { ...state, topicsCount: action.payload };
        case FETCH_TOPICS_COUNTS_ERROR:
            return state;
        default:
            return state;
    }
};

export default dashboard;
