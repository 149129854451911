import React from 'react';

import './Footer.css';
import TextBox from '../TextBox/TextBox';

const Footer = () => (
    <div className="Footer">
        <TextBox
            tag="h5"
            text={`© Copyright ${(new Date()).getFullYear()} Canopy Innovations.`}
        />
        <TextBox
            tag="h5"
            text="All rights reserved."
        />
    </div>
);


export default Footer;
