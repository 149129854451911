import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import InputBox from '../../../Basic/InputBox/InputBox';
import AutoComplete from '../../../Composed/AutoComplete/AutoComplete';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import Pagination from '../../../Composed/Pagination/Pagination';
import CreateEditInstitutionResourceContainer from '../../../Forms/CreateEditInstitutionResource/CreateEditInstitutionResourceContainer';

import { hashToQueryString, humanizeDate } from '../../../../Util/Helper/GenericUtil';
import { isTEAdmin, isB2BAdmin } from '../../../../Util/Helper/UserWorkplaceUtil';

import './Management.scss';

const Management = ({
  currentUser,
  institutions, clearInstitutions, fetchInstitutions,
  institutionResources, clearInstitutionResources, fetchInstitutionResources,
  platforms, clearPlatforms, fetchPlatforms,
  mediaTypes, clearMediaTypes, fetchMediaTypes,
}) => {
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [selectedInstitutionResourceId, setSelectedInstitutionResourceId] = useState(null);
  const [filters, setFilters] = useState({ platformFilter: '', mediaTypeFilter: '', sortFilter: 'most_recently_updated', searchFilter: '' });
  const [page, setPage] = useState(1);

  const per = 6;
  const selectedInstitutionName = isTEAdmin(currentUser) ? institutions[selectedInstitutionId]?.name : currentUser.currentInstitutionName;

  useEffect(() => {
    if (isB2BAdmin(currentUser)) {
      Promise.all([
        clearPlatforms(), fetchPlatforms(),
        clearMediaTypes(), fetchMediaTypes(),
      ]).then(() => setSelectedInstitutionId(currentUser.institution_id));
    } else if (isTEAdmin(currentUser)) {
      Promise.all([
        clearInstitutions(), fetchInstitutions(),
        clearPlatforms(), fetchPlatforms(),
        clearMediaTypes(), fetchMediaTypes(),
      ]).then(() => setLoading(false));
    }
  }, [clearInstitutions, fetchInstitutions, clearPlatforms, fetchPlatforms, clearMediaTypes, fetchMediaTypes]);

  useEffect(() => {
    if (selectedInstitutionId) {
      setLoading(true);

      Promise.all([
        clearInstitutionResources(),
        fetchInstitutionResources(selectedInstitutionId, buildQueryString()),
      ]).then(() => setLoading(false));
    }
  }, [selectedInstitutionId, clearInstitutionResources, fetchInstitutionResources, filters, page]);

  const buildQueryString = () => {
    let queryString = '';

    queryString += hashToQueryString(filters);
    queryString += `&page=${page}&per=${per}`;

    return queryString;
  };

  const sortedResources = () => (
    Object.values(institutionResources.data || {}).sort((a, b) => a.index > b.index ? 1 : -1)
  );

  const renderInstitutionSelection = () => {
    if (loading) {
      return <Loader message="Getting things ready..." />
    }

    if (!isTEAdmin(currentUser)) {
      return null;
    }

    return (
      <div className="Institution-Selection-Container">
        <label className="Label">Please select an institution to continue!</label>
        <label className="Label">Please select <strong>TE</strong> institution to manage resources that are for all institutions and all users!</label>
        <AutoComplete
          className="Select"
          options={Object.values(institutions).sort((a, b) => a.name > b.name ? 1 : -1).map((inst) => { return { value: inst.id, label: inst.name } })}
          defaultValue={{}}
          onChange={(e) => {
            setSelectedInstitutionId(e.value);
            setLoading(true);
          }}
          isMulti={false}
        />
      </div>
    )
  }

  const renderInstitutionResources = () => (
    <div className="Institution-Resource-Management-Container">
      {loading && <MirrorLoader message="Fetching Resources..." />}

      <div className="Page-Head">
        <div className="Title">Resources</div>
        <ButtonBox
          className="Add-Resource-Button Primary-Btn"
          text={<span className="Primary-Btn-Text">Add Resource</span>}
          icon="fa-plus-square"
          onClick={() => {
            setSelectedInstitutionResourceId(null);
            setShowForm(true);
          }}
        />
      </div>

      <div className="Filters-Container">
        <div className="Dropdown">
          <ImageBox className="Image" src="up-down-triangles.svg" />
          <select
            className="Select"
            value={filters.sortFilter}
            onChange={e => setFilters({ ...filters, sortFilter: e.currentTarget.value })}
          >
            <option value='most_recently_updated' children='Most Recently Updated' />
            <option value='most_recently_uploaded' children='Most Recently Uploaded' />
            <option value='alphabetic' children='Alphabetic' />
          </select>
          <i className="fa fa-caret-down Icon"></i>
        </div>

        <div className="Dropdown">
          <ImageBox className="Image" src="increasing-lines.svg" />
          <select
            className="Select"
            value={filters.mediaTypeFilter}
            onChange={e => setFilters({ ...filters, mediaTypeFilter: e.currentTarget.value })}
          >
            <option value='' children='All Resource Types' />
            {
              Object.values(mediaTypes).map((mediaType, idx) => (
                <option key={`filter-mediaType-${idx}`} value={mediaType.name} children={mediaType.name} />
              ))
            }
          </select>
          <i className="fa fa-caret-down Icon"></i>
        </div>

        <div className="Search">
          <i className="fa fa-search Icon" />
          <input
            className="Input"
            onBlur={(e) => setFilters({ ...filters, searchFilter: e.currentTarget.value })}
            onKeyPress={(e) => (e.key === 'Enter') && e.currentTarget.blur()}
          />
        </div>
      </div>

      <div className="Selected-Institution-Name">
        Institution: {selectedInstitutionName}
      </div>

      <div className="Platform-Filters-And-Institution-Resources">
        <div className="Platform-Filters">
          {
            [''].concat(Object.values(platforms).map(platform => platform.name)).map((platformName, idx) => (
              <div
                key={`filter-platform-${idx}`}
                className={`Platform-Filter ${filters.platformFilter === platformName ? 'Active' : ''}`}
                onClick={() => setFilters({ ...filters, platformFilter: platformName })}
              >
                {platformName || 'All Resources'}
              </div>
            ))
          }
        </div>

        {
          institutionResources.total ? (
            <div className="Institution-Resources">
              {
                sortedResources().map((institutionResource, idx) => (
                  renderInstitutionResource(institutionResource, idx)
                ))
              }

              <Pagination
                totalRecords={institutionResources.total}
                page={page}
                setPage={(p) => setPage(p)}
                per={per}
              />
            </div>
          ) : (
            <div className="No-Records Flash-Message Danger">
              No resources were found! Try clearing the filters.
            </div>
          )
        }
      </div>
    </div>
  )

  const renderInstitutionResource = (institutionResource, idx) => (
    <div className="Institution-Resource">
      <div className="Content">
        <div className="Title">
          {institutionResource.title}
          {institutionResource.archived_at && <span className="Archived">Archived</span>}
        </div>

        <div className="Attribute">
          <div className="Name">Description</div>
          <div className="Value">
            {institutionResource.description}
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Attachments</div>
          <div className="Value">
            {
              Object.values(institutionResource.files || {}).map((file, idx) => (
                <div key={`file-list-${idx}`} className="Value">{file.name}</div>
              ))
            }
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Url</div>
          <div className="Value">
            {
              institutionResource.url &&
              <a className="Link" href={institutionResource.url} target="_blank" rel="noopener noreferrer" >{institutionResource.url}</a>
            }
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Resource Type</div>
          <div className="Value">
            {Object.values(institutionResource.institutionResourceMediaTypes || {}).map(irmt => irmt.mediaType?.name).join(', ')}
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Related Products</div>
          <div className="Value">
            {Object.values(institutionResource.institutionResourcePlatforms || {}).map(irp => irp.platform?.name).join(', ')}
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Favorited By</div>
          <div className="Value">
            {institutionResource.institutionResourceUsers.favorites} users
          </div>
        </div>

        <div className="Attribute">
          <div className="Name">Likes/Dislikes</div>

          <div className="Reactions">
            <div className="Like">
              <i className="fa fa-thumbs-up Icon" />
              <span className="Count">{institutionResource.institutionResourceUsers.likes}</span>
            </div>
            <div className="Dislike">
              <i className="fa fa-thumbs-down Icon" />
              <span className="Count">{institutionResource.institutionResourceUsers.dislikes}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="Actions">
        {
          (isTEAdmin(currentUser) || (isB2BAdmin(currentUser) && currentUser.institution_id === institutionResource.institution_id)) &&
          <ButtonBox
            className="Edit-Button"
            icon="fa-pencil"
            text="Edit Resource"
            onClick={() => {
              setSelectedInstitutionResourceId(institutionResource.id)
              setShowForm(true);
            }}
          />
        }

        <div className="Dates">
          <div className="Updated">
            Last Updated {humanizeDate(institutionResource.updated_at, true)}
          </div>
          <div className="Uploaded">
            First Uploaded {humanizeDate(institutionResource.created_at, true)}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="Institution-Resource-Management-Page">
      {selectedInstitutionId ? renderInstitutionResources() : renderInstitutionSelection()}
      {
        showForm &&
        <CreateEditInstitutionResourceContainer
          selectedInstitutionId={selectedInstitutionId}
          institutionResourceId={selectedInstitutionResourceId}
          closeForm={() => {
            setSelectedInstitutionResourceId(null);
            setShowForm(false);
          }}
        />
      }
    </div>
  )
};

Management.propTypes = {
};

export default Management;
