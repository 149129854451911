import React from 'react';
import PropTypes from 'prop-types';

import Launch from './Launch/Launch';

import { isB2BAdmin, isStudent } from '../../../Util/Helper/UserWorkplaceUtil';
import { institutionalAdminTutorialModules, studentTutorialModules } from './TutorialContent';

import './Tutorial.scss';

const Tutorial = ({ currentUser, profileCompletion, updateProfileCompletion }) => {
  let tutorialModules;

  if (isB2BAdmin(currentUser)) {
    tutorialModules = institutionalAdminTutorialModules;
  } else if (isStudent(currentUser)) {
    tutorialModules = studentTutorialModules;
  } else {
    return '';
  }

  let moduleToStart = Object.keys(tutorialModules).find((tutorialModule) => (
    !profileCompletion.tutorial[tutorialModule] || (profileCompletion.tutorial[tutorialModule]['status'] === 'in-progress')
  ));

  if (!moduleToStart) {
    return '';
  }

  const stepIndexToStart = Number.isInteger(profileCompletion.tutorial[moduleToStart]?.steps) ? (profileCompletion.tutorial[moduleToStart].steps + 1) : 0;

  if (stepIndexToStart >= tutorialModules[moduleToStart].length) {
    profileCompletion.tutorial[moduleToStart] = { status: 'completed', steps: tutorialModules[moduleToStart].length - 1, completed_at: new Date() };
    updateProfileCompletion({ id: profileCompletion.id, tutorial: profileCompletion.tutorial })
    return ''
  }

  return (
    <Launch
      profileCompletion={profileCompletion}
      updateProfileCompletion={updateProfileCompletion}
      tutorialModules={tutorialModules}
    />
  )
};

Tutorial.propTypes = {
  currentUser: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object.isRequired,
  updateProfileCompletion: PropTypes.func.isRequired,
}

export default Tutorial;
