import React from 'react';

import MultiButtonAnswerBox from './MultiButtonAnswerBox';

const MultiButtonAnswerContainer = ({ section, newProps }) => {
  return (
    <div className="MultiButtonAnswerContainer MultiButtonChoiceContainer">
      {
        section.questions.map(
          (_, index) =>
            <MultiButtonAnswerBox
              key={index}
              currentQuestionIndex={index}
              {...newProps}
            />
        )
      }
    </div>
  )
}

export default MultiButtonAnswerContainer;
