import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './CreateEditFlashcard.css';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

class CreateEditFlashcard extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.flashcard
            ? {
                text: this.props.flashcard.text,
                back_text: this.props.flashcard.back_text
            }
            : {
                text: '',
                back_text: '',
            };
    }

    handleInputChange = (field) => {
        return (e) => this.setState({
            [field]: e.currentTarget.value,
        });
    }

    handleQuillChange = value => this.setState({ back_text: value })

    handleSubmit = e => {
        e.preventDefault();
        const flashcard = Object.assign({}, this.state);

        if (this.props.section) {
            flashcard.section_id = this.props.section.id;
            flashcard.course_id = this.props.course.id;
        }

        if (this.props.flashcard) {
            flashcard.id = this.props.flashcard.id;
        }

        this.props.submitFlashcard(flashcard).then(
            this.props.closeModal(),
        );
    }

    render() {
        const { text, back_text } = this.state;
        return (
            <form
                className="CreationForms-Form"
                onSubmit={this.handleSubmit}
            >
                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={text}
                        placeholder="Front Text"
                        onChange={this.handleInputChange('text')}
                    />
                </div>

                <div className="CreationForms-InputBox">
                    <textarea
                        className="TextAreaBox-CreationForms"
                        value={back_text}
                        placeholder="Back Text"
                        onChange={this.handleInputChange('back_text')}
                    />
                </div>


                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Submit"
                    />
                </div>
            </form>
        );
    }
}

export default CreateEditFlashcard;
