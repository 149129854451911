import React, { Component } from 'react';
import { withDragDropContext } from '../../context';
import { connect } from 'react-redux';

import './DragAndDropBox.css';
import { COLORS } from '../../../Style/Style';
import { shuffle } from '../../../Util/Helper/GenericUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import QuestionBox from '../QuestionBox/QuestionBox';
import DragElement from './DragElement';
import DragPreview from './DragPreview';
import DropArea from './DropArea';

const mapStateToProps = (state) => ({
    quizStates: state.statistics.quizStates,
});

class DragAndDropBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lengthyDrag: false,
        };
    }

    componentDidMount() {
        const { questions, quizProgress, quizStates, section, recordQuizState } = this.props;

        if (!quizStates[section.id]) {
            const optionsList = [];
            questions.forEach(
                (question) => {
                    question.answers.forEach(
                        (answer) => {
                            optionsList.push({
                                value: answer.text,
                                questionId: question.id,
                                index: answer.index,
                            });
                        },
                    );
                },
            );

            let alreadyGivenAnswers = [];
            Object.values(quizProgress?.[section.id] || {}).map(givenAnswers => (
                Object.values(givenAnswers || {}).map(givenAnswer => alreadyGivenAnswers.push(givenAnswer.text))
            ));

            recordQuizState({
                [section.id]: {
                    optionsList,
                    options: shuffle(optionsList.slice(0)).map(
                        (option, idx) => {
                            if (alreadyGivenAnswers.includes(option.value)) {
                                return null;
                            }
                            return {
                                answer: option.value,
                                origin: {
                                    section: 'options',
                                    index: idx,
                                },
                            }
                        },
                    ),
                    questions: optionsList.map(
                        (option, idx) => {
                            if (!quizProgress?.[section.id]?.[option.questionId]?.[option.index]?.text) {
                                return null;
                            }
                            return {
                                answer: quizProgress[section.id][option.questionId][option.index].text,
                                origin: {
                                    section: 'questions',
                                    index: idx
                                }
                            }
                        }
                    ),
                },
            });
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        const { questions, quizProgress, quizStates, section, recordQuizState } = this.props;

        if (!quizStates[section.id]) {
            const optionsList = [];
            questions.forEach(
                (question) => {
                    question.answers.forEach(
                        (answer) => {
                            optionsList.push({
                                value: answer.text,
                                questionId: question.id,
                                index: answer.index,
                            });
                        },
                    );
                },
            );

            let alreadyGivenAnswers = [];
            Object.values(quizProgress?.[section.id] || {}).map(givenAnswers => (
                Object.values(givenAnswers || {}).map(givenAnswer => alreadyGivenAnswers.push(givenAnswer.text))
            ));

            recordQuizState({
                [section.id]: {
                    optionsList,
                    options: shuffle(optionsList.slice(0)).map(
                        (option, idx) => {
                            if (alreadyGivenAnswers.includes(option.value)) {
                                return null;
                            }
                            return {
                                answer: option.value,
                                origin: {
                                    section: 'options',
                                    index: idx,
                                },
                            }
                        },
                    ),
                    questions: optionsList.map(
                        (option, idx) => {
                            if (!quizProgress?.[section.id]?.[option.questionId]?.[option.index]?.text) {
                                return null;
                            }
                            return {
                                answer: quizProgress[section.id][option.questionId][option.index].text,
                                origin: {
                                    section: 'questions',
                                    index: idx
                                }
                            }
                        }
                    ),
                },
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const elementProperties = document.getElementsByClassName('DragAndDropBox')?.[0]?.getBoundingClientRect();

        if (elementProperties) {
            this.setState({ lengthyDrag: ((elementProperties.top - 60) <= 0) && (elementProperties.bottom - 200 >= 0) });
        }
    }

    fillAnswers() {
        const { quizStates, section } = this.props;
        const { optionsList, options, questions } = quizStates[section.id];

        questions.map((opt, i) => {
            if (opt && (optionsList[opt.origin.index].value !== opt.answer)) {
                opt['destination'] = {
                    section: 'options',
                    index:   options.indexOf(null)
                }
                this.moveOption(opt);
            }
        });

        options.map((opt, i) => {
            if (opt) {
                opt['destination'] = {
                    section: 'questions',
                    index:   optionsList.indexOf(optionsList.find(ol => ol.value === opt.answer))
                }
                this.moveOption(opt);
            }
        });
    }

    moveOption = (answerData) => {
        const { section, lessonId, recordQuizAnswer, quizStates } = this.props;

        gaEvent('drag_drop_option_move', { section_id: section.id });
        if (answerData.destination.section === 'options') {
            if (answerData.origin.section === 'options') {
                const { options, questions } = quizStates[section.id];
                options[answerData.origin.index] = null;
                answerData.origin = answerData.destination;
                delete answerData.destination;
                options[answerData.origin.index] = answerData;

                this.props.recordQuizState({
                    [section.id]: {
                        options,
                        questions,
                    },
                });
            } else {
                const answer = {
                    [lessonId]: {
                        [section.id]: {
                            [quizStates[section.id].optionsList[answerData.origin.index].questionId]: {
                                [quizStates[section.id].optionsList[answerData.origin.index].index]: {
                                    text: "",
                                    is_correct: false,
                                },
                            },
                        },
                    },
                };

                recordQuizAnswer(answer);

                const { options, questions } = quizStates[section.id];
                questions[answerData.origin.index] = null;
                answerData.origin = answerData.destination;
                delete answerData.destination;
                options[answerData.origin.index] = answerData;

                this.props.recordQuizState({
                    [section.id]: {
                        options,
                        questions,
                    },
                });
            }
        } else {
            if (answerData.origin.section === 'options') {
                const answer = {
                [lessonId]: {
                    [section.id]: {
                    [quizStates[section.id].optionsList[answerData.destination.index].questionId]: {
                        [quizStates[section.id].optionsList[answerData.destination.index].index]: {
                        text: answerData.answer,
                        is_correct: answerData.answer === quizStates[section.id]
                            .optionsList[answerData.destination.index].value,
                        }
                    }
                    }
                }
                };

                recordQuizAnswer(answer);

                const { options, questions } = quizStates[section.id];
                options[answerData.origin.index] = null;
                answerData.origin = answerData.destination;
                delete answerData.destination;
                questions[answerData.origin.index] = answerData;

                this.props.recordQuizState({
                [section.id]: {
                    options,
                    questions,
                }
            })
            } else {
                const answer = {
                [lessonId]: {
                    [section.id]: {
                    [quizStates[section.id].optionsList[answerData.origin.index].questionId]: {
                        [quizStates[section.id].optionsList[answerData.origin.index].index]: {
                        text: "",
                        is_correct: false,
                        }
                    },
                    [quizStates[section.id].optionsList[answerData.destination.index].questionId]: {
                        [quizStates[section.id].optionsList[answerData.destination.index].index]: {
                        text: answerData.answer,
                        is_correct: answerData.answer ===
                            quizStates[section.id]
                                .optionsList[answerData.destination.index].value,
                        }
                    },
                    }
                }};
                recordQuizAnswer(answer);

                const { options, questions } = quizStates[section.id];
                questions[answerData.origin.index] = null;
                answerData.origin = answerData.destination;
                delete answerData.destination;
                questions[answerData.origin.index] = answerData;

                this.props.recordQuizState({
                [section.id]: {
                    options,
                    questions,
                }
                })
            }
        }
    }

    pinUnansweredRender = (question) => {
        const { pinUnanswered, quizProgress, section } = this.props;
        
        return (
            pinUnanswered &&
            (Object.keys(quizProgress?.[section.id]?.[question.id] || {}).length !== question.answers.length) &&
            (
                <div className="Pin-Unanswered">
                  <ImageBox class="Pin-Unanswered-Img" src="pin-unanswered.svg" />
                </div>
            )
        )
    }

    renderQuestions = () => {
        const { quizStates, section, questions, quizProgress } = this.props;
        let blankIndex = 0;

        const questionsList = questions.map((question, index) => {
            const questionParts = question.text.split('__drag__');
            const questionArray = [];
            questionParts.forEach(
                (part, idx) => {
                    part.split(' ').forEach(
                        (word, idx2 )=> questionArray.push(
                            <TextBox
                                key={(idx + 1) * 1000 + idx2}
                                text={word}
                                tag='h5'
                                style={{
                                    color: COLORS.Blue,
                                }}
                            />,
                        ),
                    );

                    if (question.answers[idx]) {
                        if (!quizStates[section.id].questions[blankIndex]) {
                            questionArray.push(
                                <div
                                    className="DragAndDropBox-Draggable__container"
                                    key={`${idx + 1}${blankIndex}`}
                                >
                                    {
                                        this.props.checkAnswers 
                                            && <i className="fas fa-times icon-wrong" />
                                    }
                                    <DropArea
                                        className={
                                            this.props.checkAnswers
                                                ? 'DropArea-Questions DragAndDropBox-wrong'
                                                : 'DropArea-Questions'
                                        }
                                        destination={{
                                            section: 'questions',
                                            index: blankIndex,
                                        }}
                                        dropId={idx}
                                    />
                                </div>,
                            );
                        } else {
                            questionArray.push(
                                <div
                                    className="DragAndDropBox-Draggable__container" key={idx}
                                >
                                    {
                                        this.props.checkAnswers && quizProgress?.[section.id]?.[question.id]?.[idx] &&
                                        (
                                            quizProgress[section.id][question.id][idx].is_correct
                                                ? <i className="fas fa-check icon-correct" />
                                                : <i className="fas fa-times icon-wrong" />
                                        )
                                    }

                                    <DragElement
                                        className={
                                            this.props.checkAnswers
                                                ? (
                                                    quizProgress?.[section.id]?.[question.id]?.[idx]?.is_correct ?
                                                    "DragElement DragAndDropBox-correct"
                                                    :
                                                    "DragElement DragAndDropBox-wrong"
                                                )
                                            : "DragElement"
                                        }
                                        answerData={
                                            quizStates[section.id].questions[blankIndex]
                                        }
                                        handleDrop={ (answerData) => this.moveOption(answerData) }
                                    />
                                </div>,
                            );
                        }

                        blankIndex++;
                    }
                }
            );

            return (
                <div
                    className="DragAndDropBox-Questions-QuestionBox"
                    key={ index }
                >
                    { this.pinUnansweredRender(question) }
                    {
                        !this.props.isComplianceCourse && (
                            <div className="FillInTheBlankBox-QuestionBox-Position">
                                <TextBox text={question.position} />
                            </div>
                        )
                    }

                    <QuestionBox question={question}>
                        { questionArray }
                        {
                            question.hint
                                && (
                                    <div className="Questions-DragAndDropBox-Hint">
                                        <TextBox
                                            text={`(${question.hint} )`}
                                            tag="h5"
                                            style={{
                                                color: '#a0adb8',
                                            }}
                                        />
                                    </div>
                                )
                        }
                    </QuestionBox>
                </div>
            );
        });

        return questionsList;
    }

    renderOptions() {
        const { quizStates, section } = this.props;
        const optionsList = quizStates[section.id].options.map((option, idx) => {
            if (!option) {
                return (
                    <DropArea
                        className="DropArea-Options"
                        destination={{
                            section: 'options',
                            index: idx,
                        }}
                        key={idx}
                    />
                );
            }

            return (
                <DragElement
                    answerData={
                        option
                    }
                    handleDrop={ (answerData) => this.moveOption(answerData) }
                    key={ idx }
                />
            );
        });

        return optionsList;
    }

    render() {
        if (!this.props.quizStates[this.props.section.id]) {
            return <div />;
        }

        if (this.props.revealAnswers) {
            this.fillAnswers();
        }

        return (
            <div className={`DragAndDropBox ${this.state.lengthyDrag ? 'Lengthy-Drag' : ''}`}>
                <div className="DragAndDropBox-OptionsArea">
                    { this.renderOptions() }
                </div>

                <div className="DragAndDropBox-Questions">
                    { this.renderQuestions() }
                </div>

                <DragPreview />
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withDragDropContext(DragAndDropBox));
