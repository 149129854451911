import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import './SentenceOrderingBox.css';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import { shuffle } from "../../../Util/Helper/GenericUtil";
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const mapStateToProps = state => ({
    quizStates: state.statistics.quizStates,
});

class SentenceOrderingBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animationEnabled: false,
        }
        this.animation = null;
    }

    componentDidMount() {
        this.animation = requestAnimationFrame(() => this.setState({ animationEnabled: true }));
        const { recordQuizState, section, questions, quizStates } = this.props;

        if(!quizStates[section.id]) {
            recordQuizState({
                [section.id]: {
                    correctOrder: questions,
                    order: shuffle(questions.slice(0)).map(
                        (question) => question.id
                    ),
                }
            })
        }
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.animation);
        this.setState({ animationEnabled: false });
    }

    onDragEnd = (result) => {
        const { source, destination } = result;
        const { quizStates, section } = this.props

        gaEvent('sentence_order', { section_id: section.id });

        if(!destination ||
            (source.droppableId === destination.droppableId
            && source.index === destination.index)) {
            return;
        }

        const newOrder = quizStates[section.id].order.slice();
        const removed = newOrder.splice(source.index, 1)[0];
        newOrder.splice(destination.index, 0, removed)

        this.recordProgress(newOrder);
    }

    recordProgress = (newOrder) => {
        this.props.recordQuizState({
            [this.props.section.id]: {
                order: newOrder,
            }
        });
    }

    render() {
        const { section, quizStates, revealAnswers } = this.props;

        if (!quizStates[this.props.section.id]) {
            return <div></div>
        }

        if (revealAnswers && quizStates[section.id].order.join(',') != quizStates[section.id].correctOrder.map(o => o.id).join(',')) {
            this.recordProgress(quizStates[section.id].correctOrder.map(o => o.id));
        }

        const questionsList = quizStates[section.id].order.map(
            (questionId, index) => {
                const question = quizStates[section.id].correctOrder.find(
                    questionData => questionData.id === questionId
                );
                const isCorrect = quizStates[section.id].order.indexOf(questionId) === quizStates[section.id].correctOrder.indexOf(question)

                return (
                    <Draggable
                        draggableId={ `draggable=${question.id}` }
                        index={ index }
                        key={ question.id }>
                        {
                        (provided) => (
                            <div
                                className="Question"
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                                ref={ provided.innerRef }>
                                <div className="SentenceOrderingBox-container">
                                    <div className={
                                        this.props.checkAnswers
                                            ? (
                                                isCorrect
                                                    ? "SentenceOrderingBox-QuestionBox correct"
                                                    : "SentenceOrderingBox-QuestionBox wrong"
                                            )
                                            : "SentenceOrderingBox-QuestionBox"
                                        }
                                    >
                                        <QuestionBox question={ question }>
                                            <TextBox text={ question.text }/>
                                        </QuestionBox>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </Draggable>
                );
            }
        );

        const numbersList = quizStates[section.id].order.map((questionId, index) => (
            <div className="Number">
                <div className="FillInTheBlankBox-QuestionBox-Position">
                    <p>{index + 1}</p>
                </div>
            </div>
        ));

        return (
            <DragDropContext onDragEnd={ this.onDragEnd }>
                {
                    this.state.animationEnabled &&
                    <Droppable
                        droppableId={ `draggable=${section.id}` }>
                        {
                        (provided) => (
                            <div
                            className="SentenceOrderingBox"
                            ref={ provided.innerRef }
                            { ...provided.droppableProps }>

                            <div className="Questions-List">{ questionsList }</div>
                            <div className="Numbers-List">{ numbersList }</div>

                            { provided.placeholder }
                            </div>
                        )
                        }
                    </Droppable>
                }
            </DragDropContext>
        );
    }
}

export default connect(mapStateToProps, null)(SentenceOrderingBox);
