import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';

import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import InputBox from '../../Basic/InputBox/InputBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import CommentDetail from './CommentDetail';

import { clearSpecificComments, fetchSpecificComments, createComment } from '../../../Actions/CommentActions';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  comments: state.entities.comments.specific
});

const mapDispatchToProps = (dispatch) => ({
  clearSpecificComments: () => dispatch(clearSpecificComments()),
  fetchSpecificComments: (criteria) => dispatch(fetchSpecificComments(criteria)),
  createComment: (criteria, comment) => dispatch(createComment(criteria, comment))
});

const CommentsPanel = ({ currentUser, comments, clearSpecificComments, fetchSpecificComments, createComment, commentableType, commentableId, closePanel }) => {
  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [sort, setSort] = useState('newest');
  const [commentAttrs, setCommentAttrs] = useState({ text: '', is_sticky: false });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    clearSpecificComments();
    fetchSpecificComments({ action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId }).then(() => setLoading(false));
  }, [clearSpecificComments, fetchSpecificComments, commentableType, commentableId]);

  const sortedComments = () => {
    switch (sort) {
      case 'newest':
        return comments.non_sticky.sort((a, b) => (new Date(a.comment.created_at)) > (new Date(b.comment.created_at)) ? 1 : -1);
      case 'oldest':
        return comments.non_sticky.sort((a, b) => (new Date(a.comment.created_at)) < (new Date(b.comment.created_at)) ? 1 : -1);
      case 'most_helpful':
        return comments.non_sticky.sort((a, b) => a.likes.length < b.likes.length ? 1 : -1);
      default:
        return comments.non_sticky;
    }
  }

  const validateForm = () => {
    let errors = {};

    if (commentAttrs.text.trim() === '') {
      errors['text'] = 'Please type your comment before posting it!';
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm();

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setPosting(true);

      let paramsCriteria = { action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId };
      let paramsComment  = { commentable_type: commentableType, commentable_id: commentableId, ...commentAttrs };

      createComment(paramsCriteria, paramsComment).then(() => {
        gaEvent('post_comment', { entity_id: commentableId })
        setCommentAttrs({ ...commentAttrs, 'text': '' });
        setPosting(false);
      });
    }
  }

  const renderTitle = () => {
    return (
      <div className="Title-Container">
        <div className="Title-And-Btns">
          <h4 className="Title">Comments</h4>
          <div className="Btns">
            <ButtonBox
              className="Close"
              text={<i className="fa fa-close" />}
              onClick={() => closePanel()}
            />
          </div>
        </div>
        <div className="Description">
          Share your thoughts with the Canopy Community
        </div>
      </div>
    )
  }

  const renderSticky = () => {
    if ((comments?.sticky || []).length === 0) {
      return (
        <div className="Comments-Empty"></div>
      )
    }

    return (
      comments.sticky.map((commentData, idx) => (
        <CommentDetail
          commentableType={commentableType}
          commentableId={commentableId}
          commentData={commentData}
        />
      ))
    )
  }

  const renderSort = () => {
    return (
      <div className="Sort-Container">
        <div className="Sort">
          <select onChange={(e) => setSort(e.currentTarget.value)}>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
            <option value="most_helpful">Most Helpful</option>
          </select>
        </div>
      </div>
    )
  }

  const renderNonSticky = () => {
    if (!(comments?.non_sticky || []).find(c => c.flags.length === 0)) {
      return (
        <div className="Comments-Empty">
          No Comments!!!
        </div>
      )
    }

    return (
      sortedComments().map((commentData, idx) => {
        if (commentData.flags.length !== 0)
          return ''

        return (
          <CommentDetail
            key={`non-sticky-${idx}`}
            commentableType={commentableType}
            commentableId={commentableId}
            commentData={commentData}
            showReactions={true}
            showReplies={true}
          />
        )
      })
    )
  }

  const renderForm = () => {
    return (
      <div className="Form-Container">
        {
          Object.keys(formErrors).length > 0 &&
          <ul className="Errors list-disc">
            {
              Object.values(formErrors).map((error, idx) => (
                <li key={`error-${idx}`} children={error} />
              ))
            }
          </ul>
        }
        <form className="Form" onSubmit={(e) => handleSubmit(e)}>
          <div className="Image">
            <ImageBox src={currentUser.image_url ? currentUser.image_url : 'default_user.png'} />
          </div>
          <div className="Field">
            <InputBox
              className="Input"
              value={commentAttrs.text}
              onChange={(e) => { setCommentAttrs({ ...commentAttrs, 'text': e.target.value }) }}
            />

            <ButtonBox
              className="Button"
              text={<i className="fa fa-send" />}
              type="submit"
            />
          </div>
        </form>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="Comments-Load">
        <ScaleLoader color="#fff" />
      </div>
    )
  }


  return (
    <div className="Comments-View">
      {posting && <MirrorLoader message="Posting Comment!"/>}
      <div className="modal">
        <div className="modal-content">
          <div className="Comments-View-Title">
            {renderTitle()}
          </div>

          <div className="Comments-View-Sticky">
            {renderSticky()}
          </div>

          <div className="Comments-View-Sort">
            {renderSort()}
          </div>

          <div className="Comments-View-NonSticky">
            {renderNonSticky()}
          </div>

          <div className="Comments-View-Form">
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  )
}

CommentsPanel.propTypes = {
  currentUser: PropTypes.object.isRequired,
  comments: PropTypes.object.isRequired,
  clearSpecificComments: PropTypes.func.isRequired,
  fetchSpecificComments: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  closePanel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPanel);
