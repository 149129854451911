import { connect } from 'react-redux';

import Exhibition from './Exhibition';

import { clearInstitutionResources, fetchInstitutionResources } from '../../../../Actions/InstitutionResourceActions';
import { clearPlatforms, fetchPlatforms } from '../../../../Actions/PlatformActions';
import { clearMediaTypes, fetchMediaTypes } from '../../../../Actions/MediaTypeActions';
import { institutionResourceUserInteraction } from '../../../../Actions/InstitutionResourceUserActions';

const mapStateToProps = (state) => ({
  currentWorkplace: state.navigation.currentWorkplace,
  institutionResources: (state.institutionEntities.institutionResources || {}),
  platforms: (state.entities.platforms || {}),
  mediaTypes: (state.entities.mediaTypes || {}),
});

const mapDispatchToProps = (dispatch) => ({
  clearInstitutionResources: () => dispatch(clearInstitutionResources()),
  fetchInstitutionResources: (institutionId, queryString) => dispatch(fetchInstitutionResources(institutionId, queryString)),
  clearPlatforms: () => dispatch(clearPlatforms()),
  fetchPlatforms: () => dispatch(fetchPlatforms()),
  clearMediaTypes: () => dispatch(clearMediaTypes()),
  fetchMediaTypes: () => dispatch(fetchMediaTypes()),
  institutionResourceUserInteraction: (institutionResourceUserParams) => dispatch(institutionResourceUserInteraction(institutionResourceUserParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Exhibition);
