import { combineReducers } from 'redux';

import courses from './Errors/CourseErrorsReducer';
import createUser from './Errors/CreateUserErrorsReducer';
import institutions from './Errors/InstitutionErrorsReducer';
import inviteUsers from './Errors/InviteUsersErrorsReducer';
import lessons from './Errors/LessonErrorsReducer';
import license from './Errors/LicenseErrorsReducer';
import manageAccess from './Errors/ManageAccessErrorsReducer';
import modifyUser from './Errors/ModifyUserErrorsReducer';
import reports from './Errors/ReportErrorsReducer';
import session from './Errors/SessionErrorsReducer';
import stripe from './Errors/StripeErrorsReducer';
import topics from './Errors/TopicErrorsReducer';

export default combineReducers({
    courses,
    createUser,
    institutions,
    inviteUsers,
    lessons,
    license,
    manageAccess,
    modifyUser,
    reports,
    session,
    stripe,
    topics,
});
