import * as APIUtil from '../Util/ExamsApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

import { receiveInventory } from './InventoryActions';

export const RECEIVE_EXAM_ERRORS = 'RECEIVE_EXAM_ERRORS';

export const receiveErrors = errors => ({
  type: RECEIVE_EXAM_ERRORS,
  errors,
});

export const updateVersions = (exam) => dispatch => (
  APIUtil.updateVersions(exam).then(
    payload => dispatch(receiveInventory(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);
