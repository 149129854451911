import * as APIUtil from '../Util/BookmarkApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_BOOKMARK_IDS    = 'RECEIVE_BOOKMARK_IDS';
export const RECEIVE_BOOKMARK_GEN    = 'RECEIVE_BOOKMARK_GEN';
export const RECEIVE_BOOKMARK_ERRORS = 'RECEIVE_BOOKMARK_ERRORS';
export const CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS';

export const receiveBookmarkIds = payload => ({
  type: RECEIVE_BOOKMARK_IDS,
  payload
});

export const receiveBookmarkGen = payload => ({
  type: RECEIVE_BOOKMARK_GEN,
  payload
});

export const receiveErrors = errors => ({
  type: RECEIVE_BOOKMARK_ERRORS,
  errors
});

export const clearBookmarks = () => ({
  type: CLEAR_BOOKMARKS
});

export const fetchBookmarkIds = () => dispatch => (
  APIUtil.fetchBookmarkIds().then(
    payload => dispatch(receiveBookmarkIds(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);

export const fetchBookmarkGen = () => dispatch => (
  APIUtil.fetchBookmarkGen().then(
    payload => dispatch(receiveBookmarkGen(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);

export const createBookmark = (bookmark) => dispatch => (
  APIUtil.createBookmark(bookmark).then(
    payload => dispatch(receiveBookmarkIds(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);

export const deleteBookmark = (bookmark) => dispatch => (
  APIUtil.deleteBookmark(bookmark).then(
    payload => dispatch(receiveBookmarkIds(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);
