// Assume that per Sentry documentation, events over 100KB in size will be ignored.
// Assume that we don't want to send the entire Redux store to keep size under 100KB.

export const adaptStore = (state) => ({
    admin: JSON.stringify(state.admin),
    dashboard: JSON.stringify(state.dashboard),
    entities: JSON.stringify(state.entities),
    errors: JSON.stringify(state.errors),
    flash: JSON.stringify(state.flash),
    navigation: JSON.stringify(state.navigation),
    statistics: JSON.stringify(state.statistics),
});

export const adaptUser = (state) => ({
    access_code: state?.session?.currentUser?.access_code,
    id: state?.session?.currentUser?.id,
    institution_id: state?.session?.currentUser?.institution_id,
    license_type: state?.session?.currentUser?.license_type,
    trial: state?.session?.currentUser?.trial,
    user_type: state?.session?.currentUser?.user_type,
});
