import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import NotificationPopupContainer from '../NotificationPopup/NotificationPopupContainer';

import './NotificationList.scss';

const NotificationList = ({ userNotifications, clearUserNotifications, fetchUserNotifications }) => {
  const listRef = useRef();

  const [loading, setLoading] = useState(false);
  const [listOpen, setListOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (event) => {
    if (listOpen && !listRef.current.contains(event.target)) {
      setListOpen(false);
    }
  }

  const toggleList = () => {
    if (listOpen) {
      setListOpen(false);
    } else {
      setLoading(true);
      setListOpen(true);
      clearUserNotifications();
      fetchUserNotifications('page=1&per=10').then(() => setLoading(false));
    }
  }

  const renderList = () => {
    if (loading) {
      return <Loader />
    }

    if (Object.keys(userNotifications?.data || {}).length === 0) {
      return (
        <div className="Empty-Notifications">No Notifications!</div>
      )
    }

    return Object.values(userNotifications.data).reverse().map((userNotification, idx) => (
      <NotificationPopupContainer
        key={`user-notification-list-${idx}`}
        userNotification={userNotification}
      />
    ))
  }

  return (
    <div className="Notification-List-Container" ref={listRef}>
      <div className="Icon-Container">
        <ButtonBox
          className="Button"
          text={<i className="fa fa-bell Icon"></i>}
          onClick={() => toggleList()}
        />
        {
          (userNotifications.notDisplayedUserNotificationsCount || 0) !== 0 &&
          <div className="Not-Displayed-Count">
            {userNotifications.notDisplayedUserNotificationsCount}
          </div>
        }
      </div>

      {
        listOpen &&
        <div className="List-Container">
          <div className="List-Title">
            <div className="Heading">Notifications</div>
            <div className="View-All">
              <Link to="/account/notifications">
                <ButtonBox
                  className="Button"
                  text="View All Notifications"
                  onClick={() => setListOpen(false)}
                />
              </Link>
            </div>
          </div>
          <div className="List">
            {renderList()}
          </div>
        </div>
      }
    </div>
  )
};

export default NotificationList;
