import {
  RECIEVE_CREDENTIAL_TESTS,
  RECIEVE_CREDENTIAL_TEST,
  CLEAR_CREDENTIAL_TESTS,
} from '../../Actions/CredentialTestActions';

const CredentialTestsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECIEVE_CREDENTIAL_TESTS:
      return Object.assign({}, state, action.payload.credentialTests);
    case RECIEVE_CREDENTIAL_TEST:
      return Object.assign({}, state, { [action.payload.id]: action.payload })
    case CLEAR_CREDENTIAL_TESTS:
      return {}
    default:
      return state;
  }
};

export default CredentialTestsReducer;
