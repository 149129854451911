import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import LeaderboardContainer from '../Leaderboard/LeaderboardContainer';
import Loader from '../../../../Composed/Loader/Loader';
import ImageBox from '../../../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';

import { streakTypeHeadLines, streakTypesList } from '../../../../../Constants/EngageConstants';
import { humanizeDate } from '../../../../../Util/Helper/GenericUtil';

import './UserStreaks.scss';

const UserStreaks = ({ userStreaks, clearUserStreaks, fetchUserStreaks }) => {
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState({ show: false, streakType: '' });

  useEffect(() => {
    clearUserStreaks();
    fetchUserStreaks().then(() => setLoading(false));
  }, [clearUserStreaks, fetchUserStreaks]);

  if (loading) {
    return <Loader />
  }

  if (Object.keys(userStreaks).length === 0) {
    return null;
  }

  return (
    <div className="Streak-Container">
      {
        leaderboard.show &&
        <LeaderboardContainer
          streakType={leaderboard.streakType}
          close={() => setLeaderboard({ show: false, streakType: '' })}
        />
      }

      {
        Object.keys(streakTypesList).map((streakType, idx) => (
          <div className="Streak-Box" key={`streak-${idx}`}>
            <div className="Streak-Card">
              <div className="Icon-And-Score">
                <ImageBox className='Icon' src="fire.svg" />
                <div className="Score">
                  {userStreaks[streakType]?.current || 0}
                </div>
              </div>

              <div className="Streak-Type-Heading">
                {streakTypeHeadLines[streakType].title}
              </div>

              <div className="Streak-Type-Instruction">
                {streakTypeHeadLines[streakType].instruction}
              </div>

              <div className="Best-Streak-Container">
                <div className="Heading">
                  All time best streak
                </div>

                <div className="DataRows" data-tip data-for={`Best-Score-Tooltip-${idx}`}>
                  <div className="DataRow">
                    <div className="Label">
                      Score
                    </div>
                    <div className="Value">
                      {userStreaks[streakType]?.bestDailyScore?.tally || '-'}
                    </div>
                  </div>

                  <div className="DataRow">
                    <div className="Label">
                      From
                    </div>
                    <div className="Value">
                      {userStreaks[streakType] ? humanizeDate(userStreaks[streakType]?.bestDailyScore?.started_at) : '-'}
                    </div>
                  </div>

                  <div className="DataRow">
                    <div className="Label">
                      To
                    </div>
                    <div className="Value">
                      {userStreaks[streakType] ? humanizeDate(userStreaks[streakType]?.bestDailyScore?.ended_at) : '-'}
                    </div>
                  </div>

                  <ReactTooltip place="top" type="info" effect="solid" id={`Best-Score-Tooltip-${idx}`}>
                    <p>{`${userStreaks[streakType]?.bestWeeklyScore?.tally || 0} Week(s) -- ${userStreaks[streakType]?.bestMonthlyScore?.tally || 0} Month(s)`}</p>
                  </ReactTooltip>
                </div>

                <div className="Leaderboard-Button">
                  <ButtonBox
                    className="Leaderboard-Link"
                    text="Leaderboard"
                    onClick={() => setLeaderboard({ show: true, streakType })}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

UserStreaks.propTypes = {
  userStreaks: PropTypes.object.isRequired,
  clearUserStreaks: PropTypes.func.isRequired,
  fetchUserStreaks: PropTypes.func.isRequired,
}

export default UserStreaks;
