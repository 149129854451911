import { combineReducers } from 'redux';

import { LOGOUT_CURRENT_USER } from '../Actions/SessionActions';
import admin from './AdminReducer';
import engage from './EngageReducer';
import entities from './EntitiesReducer';
import userEntities from './UserEntitiesReducer';
import institutionEntities from './InstitutionEntities';
import errors from './ErrorsReducer';
import flash from './FlashReducer';
import navigation from './NavigationReducer';
import session from './SessionReducer';
import statistics from './StatisticsReducer';
import stats from './StatsReducer';
import profile from './Profile';
import dashboard from './DashboardReducer';
import access from './AccessReducer';
import onboarding from './OnboardingReducer';
import filters from './FilterReducer';
import notifications from './NotificationsReducer';

const appReducer = combineReducers({
    access,
    admin,
    engage,
    entities,
    userEntities,
    institutionEntities,
    errors,
    flash,
    filters,
    navigation,
    notifications,
    session,
    statistics,
    stats,
    profile,
    dashboard,
    onboarding,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_CURRENT_USER) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
