import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CMECertificateModal from './CMECertificateModal';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './Pass.scss';

const Pass = ({ course, courseProgress, isComplianceCourse }) => {
  const [showCMEOModal, setShowCMEOModal] = useState(false);
  const isLearn = course.course_type?.name === 'Learn';

  useEffect(() => (
    window.scrollTo({ top: 0, behavior: 'smooth' })
  ), []);

  const complianceContent = () => (
    <div className="Pledge-Confirmation">
      <div className="Confirmation-Icon" />
      <div className="Heading">Thanks for pledging</div>
      <div className="Paragraph">
        You have taken an important step forward becoming a linguistically and culturally skilled healthcare provider.
        We know that even the best laid plans don't always come to full fruition, or within the timeframe that is anticipated.
        But there's a lot to celebrate in just setting a goal and giving it your best.
      </div>
      <ButtonBox
        className="CME-Claim-Button"
        text={<span className="Text">Claim Continuing Education Credits</span>}
        onClick={(e) => setShowCMEOModal(true)}
      />
      <Link to='/account/profile' className="Profile-Link">Return to Profile</Link>
    </div>
  );

  const learnContent = () => (
    <div className="Learn-Content">
      <div className="Confirmation-Icon" />
      <div className="Paragraph">
        You have taken an important step forward becoming a linguistically and culturally skilled healthcare provider.
      </div>
      {
        course.series === 'MS' &&
        <ButtonBox
          className="CME-Claim-Button"
          text={<span className="Text">Claim Continuing Education Credits</span>}
          onClick={(e) => setShowCMEOModal(true)}
        />
      }
      <Link to='/account/profile' className="Profile-Link">Return to Profile</Link>
    </div>
  )

  const renderContent = () => {
    if (isComplianceCourse) {
      return complianceContent();
    }

    if (isLearn) {
      return learnContent();
    }

    return (
      <Link to='/learn' className="Link">
        <ButtonBox
          className="Courses-Dashboard-Button"
          text={<span className="Text">Back to Courses</span>}
        />
      </Link>
    )
  }

  return (
    <div className="Pass-Container">
      {showCMEOModal && <CMECertificateModal course={course} setShowCMEOModal={setShowCMEOModal} />}

      <div className="Primary-Info-Block">
        <div className="Percentage">
          {courseProgress.percentage}%
        </div>

        <div className="Description">
          <div className="Congratulations-Banner">Congratulations!</div>
          <div className="Sub-Heading">You've passed the {course.name} course!</div>
        </div>
      </div>

      <div className="Secondary-Info-Block">
        {renderContent()}
      </div>
    </div>
  )
}

Pass.propTypes = {
  course: PropTypes.object.isRequired,
  courseProgress: PropTypes.object.isRequired,
  isComplianceCourse: PropTypes.bool.isRequired,
}

export default Pass;
