import './ImageBox.css';

import PropTypes from 'prop-types';
import React from 'react';

import EnvironmentUtil from '../../../Util/EnvironmentUtil';

/**
* @function - ImageBox - functional
* @{props} - {Object}
 * className
 * src
* @return { JSX Object }
*/
const ImageBox = ({ className, src, ...otherProps }) => {

  // propTypes for the component
  ImageBox.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
  }

  ImageBox.defaultProps = {
    className: "ImageBox",
  }

  if (src[0] === '/') {
    src = EnvironmentUtil().buildURL(src);
  } else {
    src = require(`../../../Assets/Images/${src}`);
  }

  return(
    <img
      className={ className }
      src={ src }
      alt=""
      { ...otherProps }
      >
    </img>
  )
}

export default ImageBox;
