import axios from '../../AxiosUtil';

export const fetchLatestAssessment = () => {
  return axios.get(`/api/stats/users/credentials/latest_assessment`).then(
    res => res.data
  )
};

export const fetchAllAssessments = () => {
  return axios.get(`/api/stats/users/credentials/all_assessments`).then(
    res => res.data
  )
};
