import {
  RECEIVE_LEARN_STATS,
  RECEIVE_LEARN_STAT_ERRORS,
  CLEAR_LEARN_STATS
} from '../../../Actions/Stats/Users/LearnActions';

const LearnsReducer = (state = {}, action) => {
  Object.freeze(state)

  switch (action.type) {
    case RECEIVE_LEARN_STATS:
      return Object.assign({}, state, action.payload);
    case CLEAR_LEARN_STATS:
      return Object.assign({}, state, { [action.key]: {} });
    default:
      return state;
  }
}

export default LearnsReducer;
