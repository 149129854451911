import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBar';

const NewPasswordPage = ({ errors, token, setNewPassword }) => {
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const user = {
            password,
            confirmation,
            reset_password_token: token,
        };

        setNewPassword(user);
    };

    return (
        <div className="AuthPage">
            <ProfileNavBar />
            <form
                className="container"
                onSubmit={handleSubmit}
            >
                <p className="container__header">ENTER A NEW PASSWORD</p>
                <p className="container__subheader">
                    Enter your new password and confirm it.
                </p>

                <div className="container__label-row">
                    <p className="container__label">PASSWORD</p>
                </div>

                <input
                    className={errors.message ? 'container__textinput-error' : 'container__textinput'}
                    type="password"
                    onChange={e => setPassword(e.currentTarget.value)}
                    value={password}
                />

                <div className="container__label-row">
                    <p className="container__label">CONFIRM PASSWORD</p>
                </div>

                <input
                    className={errors.message ? 'container__textinput-error' : 'container__textinput'}
                    type="password"
                    onChange={e => setConfirmation(e.currentTarget.value)}
                    value={confirmation}
                />

                <ErrorFlashBox
                    errors={errors}
                    style={{ marginBottom: '20px' }}
                />

                <button
                    className="container__button"
                    type="submit"
                >
                    SUBMIT
                </button>
            </form>
        </div>
    );
};

NewPasswordPage.propTypes = {
    errors: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    token: PropTypes.string.isRequired,
    setNewPassword: PropTypes.func.isRequired,
};

export default NewPasswordPage;
