import { connect } from 'react-redux';

import CreationLessonsDashboard from './CreationLessonsDashboard';
import {
    fetchLessons,
    createLesson,
    clearLessonErrors,
    uploadImage,
    deleteLesson,
    moveHigher,
    moveLower,
} from '../../../Actions/LessonActions';
import { clearTopics } from '../../../Actions/TopicActions';
import { editCourse, clearCourseErrors } from '../../../Actions/CourseActions';
import { fetchCourseToExport } from '../../../Actions/Conversions/CourseToScormActions';

const mapStateToProps = (state, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    currentCourseId: match.params.courseId,
    courseTypes: state.entities.courseTypes,
    lessons: state.entities.lessons,
    courseErrors: state.errors.courses,
    courseFlash: state.flash.courses,
    lessonErrors: state.errors.lessons,
    lessonFlash: state.flash.lessons,
});

const mapDispatchToProps = (dispatch) => ({
    clearCourseErrors: () => dispatch(clearCourseErrors()),
    fetchLessons: (courseId) => dispatch(fetchLessons(courseId)),
    clearLessonErrors: () => dispatch(clearLessonErrors()),
    clearTopics: () => dispatch(clearTopics()),
    editCourse: (course) => dispatch(editCourse(course)),
    createLesson: (lesson) => dispatch(createLesson(lesson)),
    uploadImage: formData => dispatch(uploadImage(formData)),
    deleteLesson: lessonId => dispatch(deleteLesson(lessonId)),
    moveHigher: lessonId => dispatch(moveHigher(lessonId)),
    moveLower: lessonId => dispatch(moveLower(lessonId)),
    fetchCourseToExport: course => dispatch(fetchCourseToExport(course)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationLessonsDashboard);
