import React from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

const PledgeComplete = ({ pledgeParts, renderPledgePart, submit, close }) => {
  return (
    <div className="Pledge-Complete-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h6>YOU ARE ATTESTING THAT YOU HAVE COMPLETED THIS PLEDGE:</h6>
            <div className="btn-close" >
              <ButtonBox
                text={<ImageBox src="close.svg" />}
                onClick={() => close()}
              />
            </div>
          </div>
          <div className="modal-body">
            <p className="PlegdeText">
              "{
                pledgeParts.map((part) => (
                  renderPledgePart(part, 'view')
                ))
              }"
            </p>
            <ButtonBox
              className="Btn PledgeCompleteButton"
              text={<><ImageBox src="icon_pledge_complete.svg" className="Icon-Pledge-Complete" /> YES, I HAVE SUCCESSFULLY COMPLETED THIS PLEDGE. </>}
              onClick={(e) => submit(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PledgeComplete.propTypes = {
  pledgeParts: PropTypes.array.isRequired,
  renderPledgePart: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default PledgeComplete;
