import axios from './AxiosUtil';

export const fetchInstitutionResources = (institutionId, queryString) => (
  axios.get(`/api/institutions/${institutionId}/institution_resources?${queryString}`).then(
    res => res.data
  )
);

export const createInstitutionResource = (formData) => (
  axios({
    method: 'POST',
    url: `/api/institutions/${formData.get("institution_resource[institution_id]")}/institution_resources`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  )
);

export const updateInstitutionResource = (formData) => (
  axios({
    method: 'PUT',
    url: `/api/institutions/${formData.get("institution_resource[institution_id]")}/institution_resources/${formData.get("institution_resource[id]")}`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  )
)
