import { getCurrentSubscriptions as getSubscriptions } from '../../../../Util/ProfileApiUtil';

export const GET_CURRENT_SUBSCRIPTIONS_REQUEST = 'GET_CURRENT_SUBSCRIPTIONS_REQUEST';
export const GET_CURRENT_SUBSCRIPTIONS_SUCCESS = 'GET_CURRENT_SUBSCRIPTIONS_SUCCESS';
export const GET_CURRENT_SUBSCRIPTIONS_ERROR = 'GET_CURRENT_SUBSCRIPTIONS_ERROR';

export const getCurrentSubscriptions = data => async dispatch => {
    dispatch({
        type: 'GET_CURRENT_SUBSCRIPTIONS_REQUEST',
    });

    try {
        const res = await getSubscriptions(data);
        dispatch({
            type: 'GET_CURRENT_SUBSCRIPTIONS_SUCCESS',
            payload: res,
        });
    } catch (error) {
        dispatch({
            type: 'GET_CURRENT_SUBSCRIPTIONS_ERROR',
            payload: error,
        });
    }
};
