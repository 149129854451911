import React, { useState } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';

import './RevealHideBox.scss';

const RevealHideBox = ({ className = '', buttonText = '', text = '' }) => {
  const [revealed, setRevealed] = useState(false);

  const revealContent = () => {
    return (
      <div className="Content">
        {
          text.length > 0 &&
          <div className="Text">
            {text}
          </div>
        }
      </div>
    )
  }

  return (
    <div className={`RevealHideBox-Container ${className}`}>
      <ButtonBox
        className="Button"
        onClick={() => setRevealed(!revealed)}
        text={revealed ? `Hide ${buttonText}` : `Reveal ${buttonText}`}
      />

      {revealed && revealContent()}
    </div>
  )
};

export default RevealHideBox;
