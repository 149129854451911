import React from 'react';

import './FillInTheBlankBox.css';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ImageBox
  from '../../Basic/ImageBox/ImageBox';
import { COLORS }
  from '../../../Style/Style';

const FillInTheBlankBox = (props) => {

  const { section, questions, quizProgress, lessonId, currentQuestionIndex } = props;
  const currentQuestion = questions[currentQuestionIndex];

  const questionParts = currentQuestion.text.split("__blank__");

  const toggleChangeHandler = (idx) => {
    return (e) => {
      recordProgress(idx, e.currentTarget.value);
    };
  };

  const recordProgress = (idx, userValue) => {
    const answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            [idx]: {
              text:       userValue,
              is_correct: userValue.trim().toLowerCase() === currentQuestion.answers[idx].text.trim().toLowerCase()
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  }

  const pinUnansweredRender = () => {
    return (
      props.pinUnanswered &&
      (Object.keys(quizProgress?.[section.id]?.[currentQuestion.id] || {}).length !== currentQuestion.answers.length) &&
      (
        <div className="Pin-Unanswered">
          <ImageBox className="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  const handleBlur = () => e => {
    e.currentTarget.id = "";
  };

  const handleFocus = () => e => {
    e.currentTarget.id = "active";
  };

  const handleQuestionRender = () => {
    let question = [];
    questionParts.forEach( (part, idx) => {
      part.split(" ").forEach(
        (word, idx2) => {
          question.push(
            <TextBox
              key={(idx+1) * 100 + idx2}
              text={word}
              tag={props.small ? "p" : 'h5'}
              style={{
                color: COLORS.Blue,
                margin: props.small ? "0 5px" : "",
              }} />
          );
        }
      )

      if (currentQuestion.answers[idx]) {
        if (props.revealAnswers && !quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.is_correct) {
          recordProgress(idx, currentQuestion.answers[idx].text)
        }

        question.push(
          <div
            key={idx + 1}>
            {
              props.checkAnswers && quizProgress?.[section.id]?.[currentQuestion.id]?.[idx] &&
              (
                quizProgress[section.id][currentQuestion.id][idx].is_correct ?
                  <i class="fas fa-check icon-correct"></i>
                  :
                  <i class="fas fa-times icon-wrong"></i>
              )
            }

            <input
              className={props.small ? 
                "FillInTheBlankBox-QuestionBox-input-small"
                :
                "FillInTheBlankBox-QuestionBox-input"}
              style={{
                width: `${(currentQuestion.answers[idx].text.length + 2) 
                  * (props.small ? 12 : 14)}px`,
              }}
              value={
                  quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]
                  ?
                  quizProgress[section.id][currentQuestion.id][idx].text
                  :
                  ""
              }
              onChange={toggleChangeHandler(idx)}
              onBlur={handleBlur()}
              onFocus={handleFocus()}
              type='text'
              autocomplete='off'
            />

          </div>
        );
      }
    });
    return question;
  };

  return (
    <div className="FillInTheBlankBox">
      { pinUnansweredRender() }
      {
        !props.isComplianceCourse && (
          !props.small &&
          <div className="FillInTheBlankBox-QuestionBox-Position">
            <TextBox text={currentQuestion.position} />
          </div>
        )
      }

      <div
        className="FillInTheBlankBox-QuestionBox">
        <QuestionBox question={ currentQuestion }>
          {handleQuestionRender()}

          {
            props.questions[props.currentQuestionIndex].hint &&
            <div className="Questions-FillInTheBlankBox-Hint">
              <TextBox
                text={ `(${props.questions[props.currentQuestionIndex].hint} )` }
                tag='h5'
                style={{
                  color: "#a0adb8"
                }}/>
            </div>
          }
        </QuestionBox>
      </div>
    </div>
  );
};

export default FillInTheBlankBox;
