import { connect } from 'react-redux';

import NotificationPopup from './NotificationPopup';

import { updateUserNotification } from '../../../../Actions/UserNotificationActions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateUserNotification: (userNotification) => dispatch(updateUserNotification(userNotification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
