import { connect } from 'react-redux';

import CreationActivityDashboard from './CreationActivityDashboard';

import { fetchActivity, editActivity } from '../../../Actions/ActivityActions';
import { createSection } from '../../../Actions/SectionActions';

const mapStateToProps = (state, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    lesson: state.entities.lessons[match.params.lessonId],
    activities: state.entities.activities
});

const mapDispatchToProps = (dispatch) => ({
    fetchActivity: lessonId => dispatch(fetchActivity(lessonId)),
    editActivity: activity => dispatch(editActivity(activity)),
    createSection: section => dispatch(createSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationActivityDashboard);
