import React from 'react';
import PropTypes from 'prop-types';

import ImageBox from '../../Basic/ImageBox/ImageBox';

import './StatStar.scss';

const StatStar = ({ stat, value, info }) => (
  <div className="StatStar-Container">
    <h3 className="Stat">{stat}</h3>

    <div className={`Star ${info ? 'With-Info' : 'Without-Info'}`}>
      <ImageBox className="Image" src="combo.svg" />
      <h4 className="Value">{value}</h4>
      {info && <div className="Info">{info}</div>}
    </div>
  </div>
)

StatStar.propTypes = {
  stat: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
}

export default StatStar;
