import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/QuizApiUtil';

export const RECEIVE_QUIZ_ANSWER = 'RECEIVE_QUIZ_ANSWER';
export const RECEIVE_QUIZ_RESULT = 'RECEIVE_QUIZ_RESULT';
export const RECEIVE_QUIZ_RESULT_V2 = 'RECEIVE_QUIZ_RESULT_V2';
export const RECEIVE_QUIZ_ERRORS = 'RECEIVE_QUIZ_ERRORS';
export const RECEIVE_QUIZ_STATE = 'RECEIVE_QUIZ_STATE';
export const RESET_QUIZ_ANSWER = 'RESET_QUIZ_ANSWER';
export const RECEIVE_QUIZ = 'RECEIVE_QUIZ';
export const RECEIVE_QUIZ_RESULTS = 'RECEIVE_QUIZ_RESULTS';
export const CLEAR_QUIZ_PROGRESSES = 'CLEAR_QUIZ_PEOGRESSES';
export const CLEAR_QUIZ_STATES = 'CLEAR_QUIZ_STATES';
export const CLEAR_QUIZ_RESULTS = 'CLEAR_QUIZ_RESULTS';

export const recordQuizAnswer = (answer) => ({
    type: RECEIVE_QUIZ_ANSWER,
    answer,
});

export const recieveQuizResult = (payload) => ({
    type: RECEIVE_QUIZ_RESULT,
    payload,
});

export const recieveQuizResultV2 = (payload) => ({
    type: RECEIVE_QUIZ_RESULT_V2,
    payload
});

export const receiveErrors = (errors) => ({
    type: RECEIVE_QUIZ_ERRORS,
    errors,
});

export const recordQuizState = (state) => ({
    type: RECEIVE_QUIZ_STATE,
    state,
});

export const receiveQuiz = (payload) => ({
    type: RECEIVE_QUIZ,
    payload,
});

export const resetQuizAnswer = answer => ({
    type: RESET_QUIZ_ANSWER,
    answer,
});

export const recieveQuizResults = payload => ({
    type: RECEIVE_QUIZ_RESULTS,
    payload,
});

export const clearQuizProgresses = () => ({
    type: CLEAR_QUIZ_PROGRESSES,
});

export const clearQuizStates = () => ({
    type: CLEAR_QUIZ_STATES,
});

export const clearQuizResults = () => ({
    type: CLEAR_QUIZ_RESULTS
});

export const submitQuiz = (data) => dispatch => (
    APIUtil.submitQuiz(data).then(
        payload => dispatch(recieveQuizResult(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const submitQuizV2 = (data) => dispatch => (
    APIUtil.submitQuizV2(data).then(
        payload => dispatch(recieveQuizResultV2(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchQuiz = (lessonId) => dispatch => (
    APIUtil.fetchQuiz(lessonId).then(
        payload => dispatch(receiveQuiz(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createQuiz = (quiz) => dispatch => (
    APIUtil.createQuiz(quiz).then(
        payload => dispatch(receiveQuiz(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editQuiz = (quiz) => dispatch => (
    APIUtil.editQuiz(quiz).then(
        payload => dispatch(receiveQuiz(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteQuiz = (quizId) => dispatch => (
    APIUtil.deleteQuiz(quizId).then(
        payload => dispatch(receiveQuiz(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const restoreQuiz = (quizId) => dispatch => (
    APIUtil.restoreQuiz(quizId).then(
        payload => dispatch(receiveQuiz(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchQuizResults = () => dispatch => (
    APIUtil.fetchQuizResults().then(
        payload => dispatch(recieveQuizResults(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const cloneQuiz = (targetLessonId, quizId) => dispatch => (
    APIUtil.cloneQuiz(targetLessonId, quizId).then(
        payload => payload,
        errors => ErrorResponseUtil(errors),
    )
);
