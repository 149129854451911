import { connect } from 'react-redux';

import UserStreaks from './UserStreaks';

import { clearUserStreaks, fetchUserStreaks } from '../../../../../Actions/Engage/StreakActions';

const mapStateToProps = (state) => ({
  userStreaks: state.engage.streaks.userStreaks || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearUserStreaks: () => dispatch(clearUserStreaks()),
  fetchUserStreaks: () => dispatch(fetchUserStreaks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStreaks);
