import {
    RECEIVE_USER_COURSES,
    CLEAR_USER_COURSES,
} from '../../Actions/ManageUserAccessActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_USER_COURSES:
            return { ...state, ...action.payload };
        case CLEAR_USER_COURSES:
            return {};
        default:
            return state;
    }
};
