import { connect } from 'react-redux';

import ActivationPage from './ActivationPage';
import { confirmEmail } from '../../../Actions/SessionActions';

const mapStateToProps = (state, ownProps) => ({
    errors: state.errors.session,
    token: ownProps.match.params.token,
});

const mapDispatchToProps = (dispatch) => ({
    confirmEmail: token => dispatch(confirmEmail(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);
