import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import appStyles from '!!css-loader?{"sourceMap":false,"exportType":"string"}!../../Components/App.css';
import learningPageStyles from '!!css-loader?{"sourceMap":false,"exportType":"string"}!../../Components/Pages/LearningPage/LearningPage.css';

export const PackageScormCourse = course => {
  const zip = new JSZip();

  let imsManifestContent;
  let imsManifestOrgContent;
  let imsManifestResContent;

  // Generic Functions
  const addSpaces = num => (' '.repeat(num));

  // Basic Elements
  const buildImsManifestInitialContent = () => (
    '<?xml version="1.0" standalone="no" ?>\n'
    + '<manifest identifier="com.scorm.learn.20043rd" version="1"\n'
    + `${addSpaces(10)}xmlns="http://www.imsglobal.org/xsd/imscp_v1p1"\n`
    + `${addSpaces(10)}xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n`
    + `${addSpaces(10)}xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_v1p3"\n`
    + `${addSpaces(10)}xmlns:adlseq="http://www.adlnet.org/xsd/adlseq_v1p3"\n`
    + `${addSpaces(10)}xmlns:adlnav="http://www.adlnet.org/xsd/adlnav_v1p3"\n`
    + `${addSpaces(10)}xmlns:imsss="http://www.imsglobal.org/xsd/imsss"\n`
    + `${addSpaces(10)}xsi:schemaLocation="http://www.imsglobal.org/xsd/imscp_v1p1 imscp_v1p1.xsd\n`
    + `${addSpaces(30)}http://www.adlnet.org/xsd/adlcp_v1p3 adlcp_v1p3.xsd\n`
    + `${addSpaces(30)}http://www.adlnet.org/xsd/adlseq_v1p3 adlseq_v1p3.xsd\n`
    + `${addSpaces(30)}http://www.adlnet.org/xsd/adlnav_v1p3 adlnav_v1p3.xsd\n`
    + `${addSpaces(30)}http://www.imsglobal.org/xsd/imsss imsss_v1p0.xsd"\n`
    + '>\n'
    + `${addSpaces(2)}<metadata>\n`
    + `${addSpaces(4)}<schema>ADL SCORM</schema>\n`
    + `${addSpaces(4)}<schemaversion>2004 3rd Edition</schemaversion>\n`
    + `${addSpaces(2)}</metadata>\n\n`
  );

  const buildImsManifestInitialOrgContent = () => (
    `${addSpaces(2)}<organizations default="${course.name}">\n`
    + `${addSpaces(4)}<organization identifier="course-${course.name}">\n`
    + `${addSpaces(6)}<title>${course.name}</title>\n`
  );

  const includeStyles = () => {
    const stylesFolder = zip.folder('shared').folder('styles');

    stylesFolder.file('appStyles.css', appStyles);
    stylesFolder.file('learningPageStyles.css', learningPageStyles);
  }

  const setupBasicElements = () => {
    imsManifestContent = buildImsManifestInitialContent(course);
    imsManifestOrgContent = buildImsManifestInitialOrgContent(course);
    imsManifestResContent = `${addSpaces(2)}<resources>\n`;

    includeStyles();
  }

  // Chunk Processing
  const buildSlideTypeContent = sectionData => (
    ''
  )

  const buildHTMLContent = sectionData => (
    '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n'
    + '<html xmlns="http://www.w3.org/1999/xhtml" >\n'
    + `${addSpaces(2)}<head>\n`
    + `${addSpaces(4)}<title>${sectionData.name}</title>\n`
    + `${addSpaces(4)}<link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet">\n`
    + `${addSpaces(4)}<link href="https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap" rel="stylesheet">\n`
    + `${addSpaces(4)}<link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">\n`
    + `${addSpaces(4)}<script src="https://kit.fontawesome.com/a855aa50f7.js"></script>\n`
    + `${addSpaces(4)}<link rel="stylesheet" href="../../shared/styles/appStyles.css">\n`
    + `${addSpaces(4)}<link rel="stylesheet" href="../../shared/styles/learningPageStyles.css">\n`
    + `${addSpaces(2)}</head>\n`
    + `${addSpaces(2)}<body>\n`
    + `${addSpaces(4)}<div id="root">\n`
    + `${addSpaces(6)}<div class="CanopyLearnPage">\n`
    + `${addSpaces(8)}<div>\n`
    + `${addSpaces(10)}<div class="LearningPage-Backdrop">\n`
    + `${addSpaces(12)}<div class="LearningPage">\n`
    + `${addSpaces(14)}<div class="LearningPage-Instructions">\n`
    + `${addSpaces(16)}<div class="ql-editor">\n`
    + `${addSpaces(18)}<p>${sectionData.position}. ${sectionData.name}</p>\n`
    + `${addSpaces(18)}${sectionData.instructions || ''}\n`
    + `${addSpaces(16)}</div>\n`
    + `${addSpaces(14)}</div>\n`
    + `${addSpaces(14)}<div class="LearningPage-Content ${sectionData.section_type}">\n`
    + `${buildSlideTypeContent(sectionData)}\n`
    + `${addSpaces(14)}</div>\n`
    + `${addSpaces(12)}</div>\n`
    + `${addSpaces(10)}</div>\n`
    + `${addSpaces(8)}</div>\n`
    + `${addSpaces(6)}</div>\n`
    + `${addSpaces(4)}</div>\n`
    + `${addSpaces(2)}</body>\n`
    + '</html>'
  );

  const processSectionable = (lessonFolder, lessonData, sectionableType, sectionableData) => {
    const sectionableFolder = lessonFolder.folder(`${sectionableType}${sectionableData.code_name}`);

    imsManifestOrgContent += `${addSpaces(8)}<item identifier="org-lesson-${lessonData.code_name}-${sectionableType}-${sectionableData.code_name}">\n`;
    imsManifestOrgContent += `${addSpaces(10)}<title>${sectionableData.code_name}</title>\n`;

    if (sectionableData.sections) {
      Object.values(sectionableData.sections).map(sectionData => {
        const sectionFile = sectionableFolder.file(`section${sectionData.code_name}.html`, buildHTMLContent(sectionData));

        imsManifestOrgContent += `${addSpaces(10)}<item identifier="org-lesson-${lessonData.code_name}-${sectionableType}-${sectionableData.code_name}-section-${sectionData.code_name}" identifierref="res-lesson-${lessonData.code_name}-${sectionableType}-${sectionableData.code_name}-section-${sectionData.code_name}">\n`;
        imsManifestOrgContent += `${addSpaces(12)}<title>${sectionData.code_name}</title>\n`;
        imsManifestOrgContent += `${addSpaces(10)}</item>\n`;

        imsManifestResContent += `${addSpaces(4)}<resource identifier="res-lesson-${lessonData.code_name}-${sectionableType}-${sectionableData.code_name}-section-${sectionData.code_name}" type="webcontent" adlcp:scormtype="asset" href="${sectionFile.root}section${sectionData.code_name}.html">\n`;
        imsManifestResContent += `${addSpaces(6)}<file href="${sectionFile.root}section${sectionData.code_name}.html"/>\n`;
        imsManifestResContent += `${addSpaces(4)}</resource>\n`;

        return null;
      })
    }

    imsManifestOrgContent += `${addSpaces(8)}</item>\n`;
  }

  const processLesson = chunk => {
    Object.values(chunk.data).map(lessonData => {
      const lessonFolder = zip.folder(`lesson${lessonData.code_name}`);

      imsManifestOrgContent += `${addSpaces(6)}<item identifier="org-lesson-${lessonData.code_name}">\n`;
      imsManifestOrgContent += `${addSpaces(8)}<title>${lessonData.code_name}</title>\n`;

      if (lessonData.activity) {
        Object.values(lessonData.activity).map(activityData => (
          processSectionable(lessonFolder, lessonData, 'activity', activityData)
        ))
      }

      if (lessonData.topics) {
        Object.values(lessonData.topics).map(topicData => (
          processSectionable(lessonFolder, lessonData, 'topic', topicData)
        ))
      }

      if (lessonData.quiz) {
        Object.values(lessonData.quiz).map(quizData => (
          processSectionable(lessonFolder, lessonData, 'quiz', quizData)
        ))
      }

      imsManifestOrgContent += `${addSpaces(6)}</item>\n\n`;

      return null;
    });
  }

  // Finalize
  const finalizeImsManifest = () => {
    imsManifestOrgContent += `${addSpaces(4)}</organization>\n`;
    imsManifestOrgContent += `${addSpaces(2)}</organizations>\n\n`;

    imsManifestResContent += `${addSpaces(2)}</resources>\n`;

    imsManifestContent += imsManifestOrgContent;
    imsManifestContent += imsManifestResContent;
    imsManifestContent += '</manifest>\n';

    zip.file('imsmanifest.xml', imsManifestContent);
  }

  // Download
  const download = () => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, `${course.name}.zip`);
    });
  }

  return {
    setupBasicElements,
    processLesson,
    finalizeImsManifest,
    download,
  }
}
