import React, { useState } from 'react';

import './DashboardFormBox.css';
import TextBox from '../../Basic/TextBox/TextBox';

const DashboardFormBox = ({ title, titleClass = '', editModeBtns, editModeFooterBtns, viewModeBtns, form, children }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="DashboardFormBox">
      <div className={`DashboardFormBox-Title_container ${titleClass}`} >
        <div className="DashboardFormBox-Title">
          <TextBox
            className="Title"
            text={title}
          />
        </div>

        {editMode ? editModeBtns(setEditMode) : viewModeBtns(setEditMode)}
      </div>

      <div className="DashboardFormBox-Content_container">
        {editMode ? form(setEditMode) : children}
      </div>

      <div className="DashboardFormBox-Footer_container">
        {editMode && editModeFooterBtns(setEditMode)}
      </div>
    </div>
  );
}

export default DashboardFormBox;
