import './App.css';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { useAuth0 } from '@auth0/auth0-react';
import axios from '../Util/AxiosUtil';
import { logoutCurrentUser, syncCurrentUser } from '../Actions/SessionActions';
import { clearEnables, fetchActorEnables } from '../Actions/FlipperActions';
import { clearWorkplace, receiveWorkplace } from '../Actions/NavigationActions';

import { gaScript } from '../Util/Helper/GoogleUtil';
import { isTEAdmin } from '../Util/Helper/UserWorkplaceUtil';
import { AuthRoute, ProtectedRoute } from '../Util/RouteUtil';
import { setAuthToken } from '../Util/SessionApiUtil';
import CanopyLearnPage from './Pages/CanopyLearnPage/CanopyLearnPageContainer';
import LearnerInterfacePage from './Pages/LearnerInterfacePage/LearnerInterfacePageContainer';
import LearnCreationPage from './Pages/LearnCreationPage/LearnCreationPageContainer';
import ActivationPage from './Pages/ActivationPage/ActivationPageContainer';
import LandingPage from './Pages/LandingPage/LandingPage';
import NewPasswordPage from './Pages/NewPasswordPage/NewPasswordPageContainer';
import PasswordPage from './Pages/PasswordPage/PasswordPageContainer';
import LogInPage from './Pages/SignInPage/SignInPageContainer';
import SignUpPage from './Pages/SignUpPage/SignUpPageContainer';
import UnlockPage from './Pages/UnlockPage/UnlockPageContainer';
import Confirmed from './Pages/Confirmed/Confirmed';
import Indolence from './Composed/Indolence/Indolence';
import NetPromoterScoreContainer from './Forms/NetPromoterScore/NetPromoterScoreContainer';

const mapStateToProps = (state) => ({
    currentUser: state.session.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    logoutCurrentUser: () => dispatch(logoutCurrentUser()),
    setCurrentUser: () => dispatch(syncCurrentUser()),
    clearEnables: () => dispatch(clearEnables()),
    fetchActorEnables: (user) => dispatch(fetchActorEnables(user)),
    clearWorkplace: () => dispatch(clearWorkplace()),
    receiveWorkplace: (workplace) => dispatch(receiveWorkplace(workplace))
});

const {
    REACT_APP_AUDIENCE, REACT_APP_GA_MEASUREMENT_ID
} = process.env;

const App = memo(({ currentUser, logoutCurrentUser, setCurrentUser, clearEnables, fetchActorEnables, clearWorkplace, receiveWorkplace }) => {
    const [hasToken, setHasToken] = useState(false);
    const [hasUser,  setHasUser]  = useState(false);
    const [hasEssential, setHasEssential] = useState(false);
    const {
        isLoading,
        isAuthenticated,
        user,
        loginWithRedirect,
        getAccessTokenSilently
    } = useAuth0();

    useEffect(() => {
        (async function login() {
            if (!isLoading && !user) {
                window.localStorage.setItem('authFallback', window.location.href);
                clearEnables();
                clearWorkplace();
                logoutCurrentUser();
                delete axios.defaults.headers.common["Authorization"];
                await loginWithRedirect();
            }
        })()
    }, [isLoading, loginWithRedirect, user])

    useEffect(() => {
        (async function getAccessToken() {
            try {
                if (isAuthenticated) {
                    let authFallback = window.localStorage.getItem('authFallback')
                    if (authFallback) {
                        window.localStorage.removeItem('authFallback');
                        window.location = authFallback;
                    }

                    const accessToken = await getAccessTokenSilently({
                        audience: REACT_APP_AUDIENCE,
                    })
                    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
                    setHasToken(true);
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        )()
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        if (hasToken) {
            setCurrentUser().then(() => {
                setHasUser(true);
            });
        }
    }, [hasToken]);

    useEffect(() => {
        if (hasUser) {
            receiveWorkplace(currentUser.workplaces.find(w => w.is_current));
            fetchActorEnables(currentUser).then(() => {
                setHasEssential(true);
            });
            gaScript(currentUser.id, REACT_APP_GA_MEASUREMENT_ID);
            axios.interceptors.request.use(async (config) => {
                try {
                    const currentAccessToken = axios.defaults.headers.common['Authorization'].split(' ')[1];
                    const currentDecodedToken = jwtDecode(currentAccessToken);

                    if ((currentDecodedToken.exp * 1000) < Date.now()) {
                        const newAccessToken = await getAccessTokenSilently({
                            audience: REACT_APP_AUDIENCE,
                        });
                        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                        config.headers.Authorization = `Bearer ${newAccessToken}`;
                    }
                } catch (err) {
                    console.log(err.message)
                }

                return config;
            }, (error) => {
                return Promise.reject(error);
            });
        }
    }, [hasUser]);

    if (isAuthenticated && hasToken && hasUser && hasEssential) {
        if (currentUser && !currentUser.confirmed) {
            return (
                <div className="App">
                    <Indolence />
                    <Confirmed />
                </div>
            )
        }

        return (
            <div className="App">
                <Indolence />
                <NetPromoterScoreContainer />
                <Switch>
                    <ProtectedRoute
                        path="/learn"
                        component={CanopyLearnPage}
                    />

                    {
                        isTEAdmin(currentUser) &&
                        <ProtectedRoute
                            path="/creation"
                            component={LearnCreationPage}
                        />
                    }

                    <ProtectedRoute
                        path="/account"
                        component={() => <div className="Backdrop-grey"><LearnerInterfacePage /></div>}
                    />

                    <AuthRoute
                        exact
                        path="/signin"
                        component={() => <div className="Backdrop-grey"><LogInPage /></div>}
                    />

                    <AuthRoute
                        exact
                        path="/signup"
                        component={() => <div className="Backdrop-grey"><SignUpPage /></div>}
                    />

                    <AuthRoute
                        exact
                        path="/signup/:institutionId/:code"
                        component={() => <div className="Backdrop-grey"><SignUpPage /></div>}
                    />

                    <AuthRoute
                        exact
                        path="/password/new"
                        component={() => <div className="Backdrop-grey"><PasswordPage /></div>}
                    />

                    <AuthRoute
                        path="/api/users/confirmation/:token"
                        component={(props) => <div className="Backdrop-white"><ActivationPage {...props} /></div>}
                    />

                    <AuthRoute
                        path="/api/users/password/edit/:token"
                        component={(props) => <div className="Backdrop-grey"><NewPasswordPage {...props} /></div>}
                    />

                    <AuthRoute
                        path="/unlock/:token"
                        component={(props) => <div className="Backdrop-grey"><UnlockPage {...props} /></div>}
                    />

                    <AuthRoute
                        path="/"
                        component={LandingPage}
                    />
                </Switch>
            </div>
        );
    }

    return (
        <div className="App">
            <div className="Load-App">
                <div className="Message" children="Loading App!" />
                <BeatLoader color="#000" />
            </div>
        </div>
    )
}, (prevProps, nextProps) => (
    prevProps.currentUser &&
    nextProps.currentUser &&
    prevProps.currentUser.id === nextProps.currentUser.id &&
    prevProps.currentUser.confirmed === nextProps.currentUser.confirmed &&
    prevProps.currentUser.workplaces.find(w => w.is_current)?.id === nextProps.currentUser.workplaces.find(w => w.is_current)?.id
));

export default connect(mapStateToProps, mapDispatchToProps)(App);
