import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';

import Loader from '../../Composed/Loader/Loader';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import ScoreCardHeader from './ScoreCardHeader/ScoreCardHeader';
import ScoreCardScale from './ScoreCardScale/ScoreCardScale';
import ScoreCardDescription from './ScoreCardDescription/ScoreCardDescription';
import ScoreCardCongratulation from './ScoreCardCongratulation/ScoreCardCongratulation';
import ScoreCardCopyright from './ScoreCardCopyright/ScoreCardCopyright';

import { proficiencyCodeToDesc, competanceCodeToDesc, testScoreRubric } from '../../../Constants/CredentialConstants'
import { isB2BAdmin } from '../../../Util/Helper/UserWorkplaceUtil'

import './CredentialScoreCard.scss';

const {
  REACT_APP_ONE_DOMAIN,
} = process.env;

const CredentialScoreCard = ({
  currentUser,
  ofUser, ofCredentialTestId,
  clearCredentialTests, fetchUserCredentialTests, credentialTests,
}) => {
  const [displayScoreCard, setDisplayScoreCard] = useState(false);
  const [loading, setLoading] = useState(true);

  const scoreCardRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => scoreCardRef.current,
  });

  const fetchCredentialTests = e => {
    e.preventDefault();

    setDisplayScoreCard(true);
    setLoading(true);
    clearCredentialTests();
    fetchUserCredentialTests(ofUser.id).then(() => {
      setLoading(false);
    });
  }

  const canopyToolKitLink = () => (
    <a
      href="https://www.withcanopy.com/canopy-toolkit/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Canopy website
    </a>
  )

  const getOverallProficiencyDesc = (listeningScoreDesc, speakingScoreDesc) => {
    if (!listeningScoreDesc.points || !speakingScoreDesc.points) {
      return null;
    }

    let averagePoint = (listeningScoreDesc.points + speakingScoreDesc.points) / 2;
    if (averagePoint % 2 !== 0) {
      averagePoint = speakingScoreDesc.points > averagePoint ? (averagePoint + 1) : (averagePoint - 1);
    }

    return Object.values(proficiencyCodeToDesc).find(desc => desc.points === averagePoint);
  }

  const getCanopyRate = label => (
    Object.keys(testScoreRubric).reduce((acc, key) => {
      if (testScoreRubric[key].includes(label)) {
        acc = key;
      }

      return acc;
    }, 'Unrateable')
  )

  const getProficiencyDescription = (title, test) => {
    if (!test || !test.sapioTest[title]) {
      return null;
    }

    return proficiencyCodeToDesc[getCanopyRate(test.sapioTest[title])];
  };

  const getCompetenceDescription = (title, test) => {
    if (!test || !test.sapioTest[title]) {
      return null;
    }

    return competanceCodeToDesc[title][test.sapioTest[title].replace('Grade ', '') || ''] || null;
  };

  const getMissingResultsMessage = test => (
    <div className="Missing-Results">
      {
        ['assigned', 'active'].includes(test.sapioTest.status)
          ? (
            isB2BAdmin(currentUser)
              ? <p>This user has not competed the {test.exam.language.name} exam yet.</p>
              : <p>Please complete the <a href={REACT_APP_ONE_DOMAIN} target="_blank">{test.exam.language.name}</a> exam to see your full scores.</p>
          ) : (
            <p>We are still waiting on the grades for the {test.exam.language.name} exam. Please check back again soon.</p>
          )
      }
    </div>
  )

  const renderScoreCard = () => {
    const currentProductId = Object.values(credentialTests).find(ct => ct.id === ofCredentialTestId)?.exam?.productId;
    const currentCredentialTests = Object.values(credentialTests).filter(ct => ct.exam.productId === currentProductId);

    const potentialTargetTest = currentCredentialTests.find(ct => ct.id === ofCredentialTestId);
    const potentialBaseTest = currentCredentialTests.find(ct => !ct.exam.language.is_qbs_target);

    const baseTest = (potentialBaseTest?.id === potentialTargetTest?.id && !potentialBaseTest?.is_qbs)
      ? undefined
      : potentialBaseTest;

    const targetTest = (baseTest && potentialTargetTest?.id !== baseTest.id)
      ? potentialTargetTest
      : currentCredentialTests.find(ct => ct.preAsssessmentScore);

    if (targetTest && targetTest.sapioTest.status !== 'rated') {
      return getMissingResultsMessage(targetTest);
    }

    if (baseTest && !baseTest.is_waived && baseTest.sapioTest.status !== 'rated') {
      return getMissingResultsMessage(baseTest);
    }

    const baseListening = getProficiencyDescription('listening', baseTest);
    const baseSpeaking = getProficiencyDescription('speaking', baseTest);
    const targetListening = getProficiencyDescription('listening', targetTest);
    const targetSpeaking = getProficiencyDescription('speaking', targetTest);

    const baseScccCompetence = getCompetenceDescription('sccc', baseTest);
    const targetScccCompetence = getCompetenceDescription('sccc', targetTest);

    return (
      <div className="Score-Card" ref={scoreCardRef}>
        <ScoreCardHeader
          title="Score Card"
          user={ofUser}
          testEndTime={targetTest?.sapioTest?.end_time}
        />

        <ScoreCardScale
          title="Your Bilingual Proficiency for Healthcare Communication"
          showLegend
          baseOverlay={
            baseTest && baseListening && baseSpeaking
              ? {
                language: `${baseTest.exam.language.name} Score`,
                level: getOverallProficiencyDesc(baseListening, baseSpeaking)?.canopyLevel || '',
              }
              : undefined
          }
          targetOverlay={
            targetTest && targetListening && targetSpeaking
              ? {
                language: `${targetTest.exam.language.name} Score`,
                level: getOverallProficiencyDesc(targetListening, targetSpeaking)?.canopyLevel || '',
              }
              : undefined
          }
          labels={Object.values(proficiencyCodeToDesc)}
          disableFirstItem
          disclaimer="Not covered by this version of the assessment"
        />

        <div className="Score-Card-Row">
          <div className="Score-Card-Col">
            <div className="Score-Card-Description-Container">
              <div className="Description-Summary">
                According to your performance on the Canopy Credential assessment, your medical {targetTest?.exam?.language?.name} was rated at "{(targetSpeaking?.canopyLevel || 'Unrateable')}"
                {baseTest && baseSpeaking && ` and your medical ${baseTest.exam.language.name} was rated at "${baseSpeaking.canopyLevel || 'Unrateable'}"`}
                . Your scores correspond to the following descriptions of communicative ability. For the full set of level descriptions on the Canopy Scale, visit the {canopyToolKitLink()}.
              </div>
              <div className="Description-Columns">
                {
                  baseTest && (
                    <>
                      <div className="Description-Column">
                        <ScoreCardDescription
                          title={`${baseTest.exam.language.name} Listening`}
                          canopyLevel={baseListening?.canopyLevel}
                          description={baseListening?.listeningDescription}
                          isQbsScore={baseListening?.isQbsScore}
                          unrateable={baseTest.is_waived ? undefined : Boolean(!baseListening)}
                          vertical
                        />
                      </div>
                      <div className="Description-Column">
                        <ScoreCardDescription
                          title={`${baseTest.exam.language.name} Speaking`}
                          canopyLevel={baseSpeaking?.canopyLevel}
                          description={baseSpeaking?.speakingDescription}
                          isQbsScore={baseSpeaking?.isQbsScore}
                          unrateable={baseTest.is_waived ? undefined : Boolean(!baseSpeaking)}
                          vertical
                        />
                      </div>
                    </>
                  )
                }
                <div className="Description-Column">
                  <ScoreCardDescription
                    title={`${targetTest?.exam.language.name} Listening`}
                    canopyLevel={targetListening?.canopyLevel}
                    description={targetListening?.listeningDescription}
                    isQbsScore={targetListening?.isQbsScore}
                    unrateable={Boolean(!targetListening)}
                    vertical
                  />
                </div>
                <div className="Description-Column">
                  <ScoreCardDescription
                    title={`${targetTest?.exam.language.name} Speaking`}
                    canopyLevel={targetSpeaking?.canopyLevel}
                    description={targetSpeaking?.speakingDescription}
                    isQbsScore={targetSpeaking?.isQbsScore}
                    unrateable={Boolean(!targetSpeaking)}
                    vertical
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Score-Card-Row">
          <div className="Score-Card-Col">
            <ScoreCardCongratulation user={ofUser} />
          </div>
        </div>

        <ScoreCardScale
          title="Your Socio-Cultural Communicative Competence (SCCC)"
          baseOverlay={
            baseTest && baseScccCompetence
              ? {
                language: `SCCC in ${baseTest.exam.language.name}`,
                level: baseScccCompetence.canopyLevel,
              }
              : undefined
          }
          targetOverlay={
            targetTest && targetScccCompetence
              ? {
                language: `SCCC in ${targetTest.exam.language.name}`,
                level: targetScccCompetence.canopyLevel,
              }
              : undefined
          }
          labels={Object.values(competanceCodeToDesc.sccc)}
          smallScale
        />

        <div className="Score-Card-Row">
          <div className="Score-Card-Col">
            <div className="Score-Card-Description-Container">
              <div className="Description-Summary">
                Socio-Cultural Communicative Competence measures one's ability to deliver accurate content effectively and impartially. Your performance has been rated "{targetScccCompetence?.canopyLevel || 'Unrateable'}"
                for Effectiveness/Impartiality. These ratings do not factor in your Bilingual Proficiency score and are provided for your awareness only. For a detailed description of the competence levels, visit the {canopyToolKitLink()}.
              </div>
              <div className="Description-Columns">
                {
                  baseTest
                  && (
                    <div className="Description-Column sccc">
                      <ScoreCardDescription
                        title={`SCCC in ${baseTest.exam.language.name}`}
                        canopyLevel={baseScccCompetence?.canopyLevel}
                        description={baseScccCompetence?.description}
                        isQbsScore={baseScccCompetence?.isQbsScore}
                        unrateable={Boolean(!baseScccCompetence)}
                      />
                    </div>
                  )
                }
                <div className="Description-Column sccc">
                  <ScoreCardDescription
                    title={`SCCC in ${targetTest?.exam.language.name}`}
                    canopyLevel={targetScccCompetence?.canopyLevel}
                    description={targetScccCompetence?.description}
                    isQbsScore={targetScccCompetence?.isQbsScore}
                    unrateable={Boolean(!targetScccCompetence)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScoreCardCopyright />
      </div>
    )
  }

  const renderModalBody = () => {
    if (loading) {
      return <Loader message="Preparing Scorecard" />
    }

    if (Object.keys(credentialTests).length === 0) {
      return (
        <div className="Error">
          <p>Something went wrong. Please try again later.</p>
        </div>
      )
    }

    return renderScoreCard();
  }

  return (
    <div className="Credential-Score-Card">
      <ButtonBox
        className="Score-Card-Button"
        text="View Scorecard"
        onClick={e => fetchCredentialTests(e)}
      />

      {
        displayScoreCard
        && (
          <div className="Score-Card-Modal">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <ButtonBox
                    className="Print-Button"
                    text={<i className="fa fa-print Print-Icon" aria-hidden="true" />}
                    onClick={handlePrint}
                  />
                  <ButtonBox
                    className="Close-Button"
                    text={<ImageBox src="close.svg" alt="close-button" />}
                    onClick={() => setDisplayScoreCard(false)}
                  />
                </div>
                <div className="modal-body">
                  {renderModalBody()}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
};

CredentialScoreCard.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  ofUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  ofCredentialTestId: PropTypes.number.isRequired,
  credentialTests: PropTypes.shape({
    id: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  fetchUserCredentialTests: PropTypes.func.isRequired,
  clearCredentialTests: PropTypes.func.isRequired,
};

export default CredentialScoreCard;
