import * as APIUtil from '../../../Util/Stats/Users/CredentialApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_CREDENTIAL_STATS = 'RECEIVE_CREDENTIAL_STATS';
export const RECEIVE_CREDENTIAL_STAT_ERRORS = 'RECEIVE_CREDENTIAL_STAT_ERRORS';
export const CLEAR_CREDENTIAL_STATS = 'CLEAR_CREDENTIAL_STATS';

export const receiveCredentialStats = (payload) => ({
  type: RECEIVE_CREDENTIAL_STATS,
  payload,
});

export const receiveCredentialStatErrors = (errors) => ({
  type: RECEIVE_CREDENTIAL_STAT_ERRORS,
  errors,
});

export const clearCredentialStats = (key) => ({
  type: CLEAR_CREDENTIAL_STATS,
  key,
});

export const fetchLatestAssessment = () => dispatch => (
  APIUtil.fetchLatestAssessment().then(
    payload => dispatch(receiveCredentialStats(payload)),
    errors  => dispatch(receiveCredentialStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchAllAssessments = () => dispatch => (
  APIUtil.fetchAllAssessments().then(
    payload => dispatch(receiveCredentialStats(payload)),
    errors  => dispatch(receiveCredentialStatErrors(ErrorResponseUtil(errors))),
  )
);
