import axios from './AxiosUtil';

export const fetchInstitutions = () => {
    return axios.get('/api/institutions').then(
        res => res.data,
    );
};

export const fetchInstitution = (id) => {
    return axios.get(`/api/institutions/${id}`).then(
        res => res.data,
    );
};

export const createInstitution = institution => {
    return axios.post('/api/institutions', { institution }).then(
        res => res.data,
    );
};

export const editInstitution = institution => {
    return axios.patch(`/api/institutions/${institution.id}`, { institution }).then(
        res => res.data,
    );
}

export const deleteInstitution = institutionId => {
    return axios.delete(`/api/institutions/${institutionId}`).then(
        res => res.data,
    );
}