import * as APIUtil from '../Util/CredentialTestApiUtil';
import * as CreateUserApiUtil from '../Util/CreateUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_CREDENTIAL_TESTS = 'CLEAR_CREDENTIAL_TESTS';
export const RECIEVE_CREDENTIAL_TESTS = 'RECIEVE_CREDENTIAL_TESTS';
export const RECIEVE_CREDENTIAL_TEST = 'RECIEVE_CREDENTIAL_TEST';
export const RECIEVE_CREDENTIAL_TEST_ERRORS = 'RECIEVE_CREDENTIAL_TEST_ERRORS';

export const clearCredentialTests = () => ({
  type: CLEAR_CREDENTIAL_TESTS,
});

export const receiveCredentialTests = payload => ({
  type: RECIEVE_CREDENTIAL_TESTS,
  payload,
});

export const receiveCredentialTest = payload => ({
  type: RECIEVE_CREDENTIAL_TEST,
  payload,
});

export const recieveCredentialTestErrors = errors => ({
  type: RECIEVE_CREDENTIAL_TEST_ERRORS,
  errors,
});

export const fetchUserCredentialTests = (userId) => dispatch => (
  APIUtil.fetchUserCredentialTests(userId).then(
    payload => dispatch(receiveCredentialTests(payload)),
    errors => dispatch(recieveCredentialTestErrors(ErrorResponseUtil(errors))),
  )
);

export const resetCredentialTest = (credentialTestId) => dispatch => (
  APIUtil.resetCredentialTest(credentialTestId).then(
    payload => dispatch(receiveCredentialTest(payload)),
    errors => dispatch(recieveCredentialTestErrors(ErrorResponseUtil(errors))),
  )
);

export const waiveCredentialTest = (credentialTestId) => dispatch => (
  APIUtil.waiveCredentialTest(credentialTestId).then(
    payload => dispatch(receiveCredentialTest(payload)),
    errors => dispatch(recieveCredentialTestErrors(ErrorResponseUtil(errors))),
  )
);

export const checkExistingUser = (user) => dispatch => (
  CreateUserApiUtil.checkExistingUser(user).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);
