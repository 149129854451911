import { connect } from 'react-redux';
import UserDashboardSummary from './UserDashboardSummary';
import { fetchTopicsCount } from '../../../../Actions/DashboardActions';

const mapStateToProps = (store, ownProps) => ({
    courses: store.dashboard.courses,
    courseProgresses: Object.values(store.statistics.courseProgresses || {}),
    topicsCount: store.dashboard.topicsCount,
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    fetchTopicsCount: () => dispatch(fetchTopicsCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardSummary);
