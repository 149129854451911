import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../TextBox/TextBox';

/**
* @function - ProgessBar - functional
* @{props} - {Object}
 * className
 * percent
* @return { JSX Object }
*/

const ProgressBar = ({
  percent,
  fractionTop,
  fractionBottom,
  fillerColor,
  barColor,
  textColor,
  moveable
}) => (
  moveable
  ?
  (
    <div className="ProgressBar-Move-Container">
      <div className="ProgressBar">
        <div
          className="ProgressBar-Filler"
          style={{
            width: `${percent}%`,
            backgroundColor: fillerColor,
            borderTopRightRadius: `${percent == 100 ? '13px' : '0'}`,
            borderBottomRightRadius: `${percent == 100 ? '13px' : '0'}`
          }}
        >
        </div>
      </div>

      <div className="ProgressBar-Text">
        <TextBox
          tag="p"
          text={`${percent}%`}
          style={{
            marginLeft: `${percent == 0 ? 0 : percent == 100 ? 95 : percent ? (percent - 1) : 0}%`,
          }}
        />
      </div>
    </div>
  )
  :
  (
    <div className="ProgressBar-Container">
      <div
        className="ProgressBar"
        style={{ backgroundColor: barColor }}
      >
        <div
          className="ProgressBar-Filler"
          style={{ width: `${percent}%`, backgroundColor: fillerColor }}
        >
        </div>
      </div>

      <div className="ProgressBar-Text">
        <TextBox
          tag="p"
          style={{ color: textColor }}
          text="Progress:"
        />

        <TextBox
          tag="p"
          style={{ color: textColor }}
          text={
            fractionBottom
            ? `${fractionTop} / ${fractionBottom}`
            : `${percent}%`
          }
        />
      </div>
    </div>
  )
);


// prop types for the component
ProgressBar.propTypes = {
    percent: PropTypes.number,
    fractionTop: PropTypes.string,
    fractionBottom: PropTypes.string,
    fillerColor: PropTypes.string,
    barColor: PropTypes.string,
    textColor: PropTypes.string,
};

ProgressBar.defaultProps = {
    percent: 0,
    fillerColor: 'rgb(107, 209, 190)',
    barColor: '#F0F0F0',
    textColor: COLORS.greyBlue,
};

export default ProgressBar;
