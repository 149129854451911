import React from 'react';
import { connect } from 'react-redux';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import VideoBox  from '../../Basic/VideoBox/VideoBox';
import DialogBox from '../../Learning/DialogBox/DialogBox';
import Flashcard from '../../Learning/Flashcard/Flashcard';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import MultiButtonChoiceContainer from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceContainer';
import MultiButtonAnswerContainer from '../../Quiz/MultiButtonAnswerBox/MultiButtonAnswerContainer';
import FillInTheBlankContainer    from '../../Quiz/FillInTheBlankBox/FillInTheBlankContainer';
import TrueOrFalseContainer       from '../../Quiz/TrueOrFalseBox/TrueOrFalseContainer';
import DropDownContainer          from '../../Quiz/DropDownBox/DropDownContainer';
import DragAndDropBox             from '../../Quiz/DragAndDropBox/DragAndDropBox';
import MatchingBox                from '../../Quiz/MatchingBox/MatchingBox';
import SentenceOrderingBox        from '../../Quiz/SentenceOrderingBox/SentenceOrderingBox';
import WordScrambleContainer      from '../../Quiz/WordScrambleBox/WordScrambleContainer';
import SortingBox                 from '../../Quiz/SortingBox/SortingBox';
import AudioPlayButton            from '../../Quiz/AudioPlayButton/AudioPlayButton';

import { recordQuizAnswer, recordQuizState } from '../../../Actions/QuizActions';
import './Bookmark.css';

const mapStateToProps = (state) => ({
  quizProgresses: state.statistics.quizProgresses,
  quizStates:     state.statistics.quizStates
});

const mapDispatchToProps = (dispatch) => ({
  recordQuizAnswer: (answer) => dispatch(recordQuizAnswer(answer)),
  recordQuizState:  (state)  => dispatch(recordQuizState(state))
});

const Bookmark = ({
  lesson, toq, section, showBookmarkHandler,
  quizProgresses, quizStates,
  recordQuizAnswer, recordQuizState
}) => {
  const newProps = {
    lessonId: section.lesson_id,
    section,
    questions: section.questions,
    quizProgress: quizProgresses[section.lesson_id],
    quizStates,
    recordQuizAnswer,
    recordQuizState
  };

  let component;

  switch (section.section_type) {
    case 'multiple_choice':
      component =  <MultiButtonChoiceContainer section={section} newProps={newProps} />
      break;
    case 'multiple_answer':
      component = <MultiButtonAnswerContainer section={section} newProps={newProps} />
      break;
    case 'fill_in_the_blank':
      component = <FillInTheBlankContainer section={section} newProps={newProps} />
      break;
    case 'true_or_false':
      component = <TrueOrFalseContainer section={section} newProps={newProps} />
      break;
    case 'drop_down':
      component = <DropDownContainer section={section} newProps={newProps} />
      break;
    case 'drag_and_drop':
      component = <DragAndDropBox {...newProps} />
      break;
    case 'matching':
      component = <MatchingBox {...newProps} />
      break;
    case 'sentence_ordering':
      component = <SentenceOrderingBox {...newProps} />
      break;
    case 'dialog':
      component = <DialogBox {...newProps} />
      break;
    case 'word_scramble':
      component = <WordScrambleContainer section={section} newProps={newProps} />
      break;
    case 'sorting':
      component = <SortingBox {...newProps} />
      break;
    case 'flashcard':
      component = <Flashcard {...newProps} />
      break;
    default:
      component = null
  }

  return(
    <div className="Bookmark-Container">
      <div className="Bookmark-Title">
        <div className="Bookmark-Back" onClick={() => showBookmarkHandler()}>
          <ButtonBox className="Secondary-Btn" text={(<span className="Secondary-Btn-Text">Return Bookmarks</span>)} />
        </div>
        <div className="Bookmark-Heading">
          { section.name }
        </div>
      </div>
      <div className="Bookmark-Content">
        { Boolean(section.text_contents.length) && <TextContentBox {...newProps} /> }
        { section.audio_url && <AudioPlayButton src={section.audio_url} /> }
        { section.video_url && <VideoBox src={section.video_url} /> }
        { component }
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark);
