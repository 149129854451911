import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const ExcelDownloader = (fileName) => {
  const workbook = XLSX.utils.book_new();

  let header = [];

  const addSheetWithRows = (sheetName, rows) => {
    header = Object.keys(rows.at(0));

    const worksheet = XLSX.utils.json_to_sheet(rows, { header });

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  const appendRowsToSheet = (sheetName, rows) => {
    const worksheet = workbook.Sheets[sheetName];

    XLSX.utils.sheet_add_json(worksheet, rows, { origin: -1, skipHeader: true, header });
  }

  const hasSheet = (sheetName) => {
    const worksheet = workbook.Sheets[sheetName];

    return worksheet ? true : false
  }

  const applyFormulas = (sheetName, formulas) => {
    const worksheet = workbook.Sheets[sheetName];

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    const firstRowIndex = range.s.r;
    const lastRowIndex  = range.e.r;
    const firstColIndex = range.s.c;
    const lastColIndex  = range.e.c;

    XLSX.utils.sheet_add_aoa(worksheet, [], { origin: -1 });

    Object.entries(formulas).map(([formulaTitle, formulaName]) => {
      let formulaRow = [];

      for(let currentColIndex = firstColIndex; currentColIndex <= lastColIndex; currentColIndex++) {
        if (currentColIndex === 0) {
          formulaRow.push({ v: formulaTitle });
        } else {
          let columnLetter = XLSX.utils.encode_col(currentColIndex);
          formulaRow.push({ f: `${formulaName}(${columnLetter}${firstRowIndex + 2}:${columnLetter}${lastRowIndex + 1})` });
        }
      }

      XLSX.utils.sheet_add_aoa(worksheet, [formulaRow], { origin: -1 });
    });
  }

  const download = () => {
    const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, `${fileName}-${(new Date()).toLocaleString()}.xlsx`);
  }

  return {
    addSheetWithRows,
    appendRowsToSheet,
    hasSheet,
    applyFormulas,
    download,
  }
}

export const tableToExcelDownloader = (tableId, sheetName, fileName, opts = {}) => {
  const workbook = XLSX.utils.book_new();
  const table = document.getElementById(tableId);
  const worksheet = XLSX.utils.table_to_sheet(table, opts);

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  saveAs(blob, `${fileName}.xlsx`);
}
