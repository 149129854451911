import { combineReducers } from 'redux';

import learns from './B2BAdmin/LearnsReducer';
import compliances from './B2BAdmin/CompliancesReducer';
import credentials from './B2BAdmin/CredentialsReducer';
import speaks from './B2BAdmin/SpeaksReducer';

export default combineReducers({
  learns,
  compliances,
  credentials,
  speaks
});
