import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SectionSummaryBox.css';
import SectionBox from '../../Presentational/SectionBox/SectionBox';

class SectionSummaryBox extends Component {
    handleSectionsRender = () => {
        const { activity, topic, quiz } = this.props;

        const sectionable = activity || topic || quiz;
        return sectionable.sections.map(
            section => (
                <SectionBox
                    section={section}
                    key={section.id}
                    creation
                    { ...this.props }
                />
            ),
        );
    }

    render() {
        return (
            <div className="SectionSummaryBox">
                {
                    this.handleSectionsRender()
                }
            </div>
        );
    }
}

SectionSummaryBox.propTypes = {
    activity: PropTypes.shape({
        sections: PropTypes.objectOf(PropTypes.object),
    }),
    topic: PropTypes.shape({
        sections: PropTypes.objectOf(PropTypes.object),
    }),
    quiz: PropTypes.shape({
        sections: PropTypes.objectOf(PropTypes.object),
    }),
}

export default SectionSummaryBox;
