import React, { useLayoutEffect, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Filters from '../Filters/Filters';
import UserParticipation from './UserParticipation';
import UserPerformance from './UserPerformance';
import LearnRosterManagement from '../UserRosterManagement/LearnRosterManagement';
import Loader from '../../../Composed/Loader/Loader';

import './Learn.scss';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Learn = () => {
  const [loading, setLoading] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  const formNames = ['OVERVIEW', 'USER ROSTER MANAGEMENT'];

  useLayoutEffect (() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 500;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const renderDashboard = () => {
    switch(formIndex) {
      case 0:
        return (
          <div className="OverView-Container">
            <Filters
              showFilters={['licenseFilter', 'coursesFilter', 'timesFilter', 'groupsFilter', 'departmentsFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {
              loading ? <Loader /> : (
                <>
                  <UserPerformance contentWidth={contentWidth} />
                  <UserParticipation />
                </>
              )
            }

          </div>
        );
      case 1:
        return (
          <div className="User-Roster-Management-Container">
            <Filters
              showFilters={['groupsFilter', 'departmentsFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {loading ? <Loader /> : <LearnRosterManagement />}
          </div>
        );
    }
  }

  return (
    <div className="B2B-Admin-Learn-Dashboard-Container">
      <div className="Learn-Dashboard-Tabs">
        {
          formNames.map((formName, idx) => (
            <div
              key={`learn-dashboard-tab-${idx}`}
              className={`Tab-Button ${formIndex === idx ? 'Active' : '' }`}
              onClick={() => {formIndex === idx ? null : setLoading(true); setFormIndex(idx)}}
            >
              {formName}
            </div>
          ))
        }
      </div>

      {renderDashboard()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Learn);
