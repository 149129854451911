import { createLicense, fetchLicenses, deleteLicense, editLicense, deactivateLicenseUsers, migrateLicenseUsers } from '../Util/LicenseApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CREATE_LICENSE_REQUEST = 'CREATE_LICENSE_REQUEST';
export const CREATE_LICENSE_SUCCESS = 'CREATE_LICENSE_SUCCESS';
export const CREATE_LICENSE_ERROR = 'CREATE_LICENSE_ERROR';
export const RECEIVE_LICENSES = 'RECEIVE_LICENSES';
export const RECEIVE_LICENSE = 'RECEIVE_LICENSE';
export const RECEIVE_DELETE_SUCCESS = 'RECEIVE_DELETE_SUCCESS';
export const CLEAR_LICENSE_FLASH = 'CLEAR_LICENSE_FLASH';

export const createLicenseSuccess = payload => ({
    type: CREATE_LICENSE_SUCCESS,
    payload,
});

export const createLicenseError = errors => ({
    type: CREATE_LICENSE_ERROR,
    errors,
});

export const receiveLicenses = payload => ({
    type: RECEIVE_LICENSES,
    payload,
});

export const receiveLicense = payload => ({
    type: RECEIVE_LICENSE,
    payload,
});

export const receiveDeleteSuccess = licenseId => ({
    type: RECEIVE_DELETE_SUCCESS,
    licenseId,
});

export const clearLicenseFlash = () => ({
    type: CLEAR_LICENSE_FLASH,
});

export const getLicenses = () => async dispatch => {
    try {
        const response = await fetchLicenses();
        dispatch(receiveLicenses(response));
    } catch (errors) {
        dispatch(createLicenseError(ErrorResponseUtil(errors)));
    }
};

export const createLicenseRequest = data => async dispatch => {
    try {
        const response = await createLicense(data);
        dispatch(createLicenseSuccess(response.message));
    } catch (errors) {
        dispatch(createLicenseError(ErrorResponseUtil(errors)));
    }
};

export const updateLicense = license => async dispatch => {
    try {
        const response = await editLicense(license);
        dispatch(receiveLicense(response));
        dispatch(createLicenseSuccess('Success!'));
    } catch (errors) {
        dispatch(createLicenseError(ErrorResponseUtil(errors)));
    }
};

export const removeLicense = licenseId => async dispatch => {
    try {
        const response = await deleteLicense(licenseId);
        if (response?.message === 'success') {
            dispatch(receiveDeleteSuccess(licenseId));
        }
    } catch (errors) {
        dispatch(createLicenseError(ErrorResponseUtil(errors)));
    }
};

export const deactivateUsers = formData => async dispatch => {
    try {
        return (await deactivateLicenseUsers(formData));
    } catch (errors) {
        return ErrorResponseUtil(errors);
    }
};

export const migrateUsers = formData => async dispatch => {
    try {
        return (await migrateLicenseUsers(formData));
    } catch (errors) {
        return ErrorResponseUtil(errors);
    }
};
