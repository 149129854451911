import { getBillingInfo as getBilling } from '../../../../Util/ProfileApiUtil';

export const GET_BILLING_INFO_REQUEST = 'GET_BILLING_INFO_REQUEST';
export const GET_BILLING_INFO_SUCCESS = 'GET_BILLING_INFO_SUCCESS';
export const GET_BILLING_INFO_ERROR = 'GET_BILLING_INFO_ERROR';

export const getBillingInfo = data => async dispatch => {
    dispatch({
        type: 'GET_BILLING_INFO_REQUEST',
    });

    try {
        const res = await getBilling(data);
        const newResponse = {
            name: res.data.active_card.name,
            ccNumber: `•••••••••••• ${res.data.active_card.last4}`,
            expDate: `${res.data.active_card.exp_month}/${res.data.active_card.exp_year}`,
            cvv: '•••',
            zipCode: res.data.active_card.address_zip,
        };
        dispatch({
            type: 'GET_BILLING_INFO_SUCCESS',
            payload: newResponse,
        });
    } catch (error) {
        dispatch({
            type: 'GET_BILLING_INFO_ERROR',
            payload: error,
        });
    }
};
