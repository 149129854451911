import { connect } from 'react-redux';

import CourseProgressImport from './CourseProgressImport';

import { importProgress } from '../../../Actions/CourseProgressActions';

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace,
  courseSubscription: (state.access.subscriptions || {})[ownProps.course.id],
  courseProgress: (state.statistics.courseProgresses || {})[ownProps.course.id],
});

const mapDispatchToProps = dispatch => ({
  importProgress: importParams => dispatch(importProgress(importParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseProgressImport);
