import {
    CREATE_LICENSE_SUCCESS,
    CLEAR_LICENSE_FLASH,
} from '../../Actions/LicenseActions';

const defaultState = {
    message: '',
};

const license = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_LICENSE_SUCCESS:
            return { ...state, message: action.payload };
        case CLEAR_LICENSE_FLASH:
            return defaultState;
        default:
            return state;
    }
};

export default license;
