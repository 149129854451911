import React
  from 'react';

import './SentenceOrderingBoxMini.css';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import FunctionBox
  from '../../Composed/FunctionBox/FunctionBox';
import { COLORS }
  from '../../../Style/Style';

/**
* @class - SentenceOrderingBoxMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const SentenceOrderingBoxMini = (props) => {
  const { question, editQuestion, deleteQuestion, uploadAudio } = props;

  return (
    <div className='MultiButtonChoiceBoxBoxMini'>
      <div
        className="QuestionMini-QuestionBox">
        <div
          className="QuestionMini-QuestionBox-Position"
          style={{
            backgroundColor: COLORS.green
          }}>
          <TextBox
            text={question.position}
            style={{
              color: COLORS.Blue
            }} />
        </div>


        <div
          className="QuestionMini-QuestionBox-Question">
          <QuestionBox question={question} creation={props.creation}>
            <TextBox
              text={question.text}
              style={{
                color: COLORS.Blue
              }} />
          </QuestionBox>
        </div>

        {
          props.creation &&
          <FunctionBox
            submitMedia={uploadAudio}
            model="question"
            entity={question}
            editEntity={editQuestion}
            deleteEntity={deleteQuestion}
          />
        }
      </div>
    </div>
  );
};

export default SentenceOrderingBoxMini;
