export const proficiencyCodeToDesc = {
  'A1':  { 'canopyLevel': 'Basic Level 1',          'isQbsScore': false, 'points': null, 'isScoreable': false, 'listeningDescription': 'You can understand words and short phrases used in routine tasks such as gathering basic personal details. You can understand simple questions or requests and follow a short explanation that is slowly and carefully articulated.',                                                                                                                             'speakingDescription': 'You can ask and answer short, simple questions as part of a routine exchange such as gathering basic personal details. You can give very simple instructions and rely on a few isolated phrases, such as please and thank you, to show kindness and respect.', },
  'A2':  { 'canopyLevel': 'Basic Level 2',          'isQbsScore': false, 'points': 2,    'isScoreable': true,  'listeningDescription': 'You can understand basic medical terminology, phrases, and frequent expressions used in routine tasks, such as documenting common symptoms. You can follow the main point of a short explanation that is slowly and clearly articulated, with occasional need for repetition.',                                                                                   'speakingDescription': 'You can ask and answer questions in simple, routine situations, such as documenting common symptoms. You can use basic medical terminology, phrases, and expressions related to familiar medical topics. You can use simple phrases to express concern or empathy.', },
  'LB1': { 'canopyLevel': 'Emergent Level 1',       'isQbsScore': false, 'points': 4,    'isScoreable': true,  'listeningDescription': 'You can understand the main point and most details of an explanation about familiar medical matters, such as a patient\'s medical history. You can pick up on a speaker\'s tone and understand a variety of instructions, questions, and requests.',                                                                                                              'speakingDescription': 'You can give straightforward explanations related to a variety of familiar medical topics, such as describing a common diagnosis. You can appropriately express concern, ask and answer questions, and give advice or detailed instructions.', },
  'HB1': { 'canopyLevel': 'Emergent Level 2',       'isQbsScore': false, 'points': 6,    'isScoreable': true,  'listeningDescription': 'You can understand the main point and most details of an explanation about familiar medical matters, such as a patient\'s medical history. You can pick up on a speaker\'s tone and understand a variety of instructions, questions, and requests.',                                                                                                              'speakingDescription': 'You can give straightforward explanations related to a variety of familiar medical topics, such as describing a common diagnosis. You can appropriately express concern, ask and answer questions, and give advice or detailed instructions.',  },
  'LB2': { 'canopyLevel': 'Conversational Level 1', 'isQbsScore': false, 'points': 8,    'isScoreable': true,  'listeningDescription': 'You can understand the main ideas of discussions on a wide range of medical topics, such as an extended explanation of a patient\'s medical history. When the topic is familiar, you can pick up on a speaker\'s tone and understand complex lines of argument, and you can mostly follow along in unfamiliar situations too.',                                   'speakingDescription': 'You can give clear, systematic descriptions related to a wide range of medical topics, such as explaining a patient\'s diagnosis. You can give a clear argument with relevant supporting details and examples. You can handle a variety of contexts and are mostly comfortable discussing sensitive topics with patients.', },
  'HB2': { 'canopyLevel': 'Conversational Level 2', 'isQbsScore': true,  'points': 10,   'isScoreable': true,  'listeningDescription': 'You can understand the main ideas of discussions on a wide range of medical topics, such as an extended explanation of a patient\'s medical history. When the topic is familiar, you can pick up on a speaker\'s tone and understand complex lines of argument, and you can mostly follow along in unfamiliar situations too.',                                   'speakingDescription': 'You can give clear, systematic descriptions related to a wide range of medical topics, such as explaining a patient\'s diagnosis. You can give a clear argument with relevant supporting details and examples. You can handle a variety of contexts and are mostly comfortable discussing sensitive topics with patients.', },
  'C1':  { 'canopyLevel': 'Advanced Level 1',       'isQbsScore': true,  'points': 12,   'isScoreable': true,  'listeningDescription': 'You can follow the main idea and specific details of extended speech on complex medical topics, such as a technical discussion related to a patient\'s test results, even when the topic goes beyond your area of specialization. You can easily understand speech in a variety of contexts and interpret meaning even when it is implied rather than explicit.', 'speakingDescription': 'You can speak clearly for an extended period of time on complex medical topics, such giving a technical explanation of a patient\'s diagnosis. You can give elaborate and detailed descriptions and arguments, including about hypothetical situations. You can speak naturally in a variety of contexts and are comfortable discussing sensitive topics with patients.', },
  'C2':  { 'canopyLevel': 'Advanced Level 2',       'isQbsScore': true,  'points': null, 'isScoreable': false, 'listeningDescription': 'N/A',                                                                                                                                                                                                                                                                                                                                                             'speakingDescription': 'NA', },
}

export const applicableProficiencyCodes = Object.entries(proficiencyCodeToDesc).map(([k, v]) => v['isScoreable'] ? k : null).filter(k => k);
export const qbsThresholdCodes = Object.entries(proficiencyCodeToDesc).map(([k, v]) => v['isQbsScore'] ? k : null).filter(k => k);

export const testScoreRubric = {
  C1: ['C1'],
  HB2: ['HB2'],
  LB2: ['LB2'],
  HB1: ['HB1'],
  LB1: ['LB1'],
  A2: ['A2'],
  Unrateable: ['Unrateable', 'Unratable', 'Lower than A2'],
};

export const proficiencyCodeToRange = {
  "baseListening": {
    "A1":  { "max": 16,  "min": 0   },
    "A2":  { "max": 32,  "min": 17  },
    "LB1": { "max": 48,  "min": 33  },
    "HB1": { "max": 65,  "min": 49  },
    "LB2": { "max": 98,  "min": 66  },
    "HB2": { "max": 131, "min": 99  },
    "C1":  { "max": 176, "min": 132 },
  },
  "baseSpeaking": {
    "A1":  { "max": 13,  "min": 0   },
    "A2":  { "max": 26,  "min": 14  },
    "LB1": { "max": 39,  "min": 27  },
    "HB1": { "max": 53,  "min": 40  },
    "LB2": { "max": 80,  "min": 54  },
    "HB2": { "max": 107, "min": 81  },
    "C1":  { "max": 144, "min": 108 },
  },
  "targetListening": {
    "A1":  { "max": 14,  "min": 0   },
    "A2":  { "max": 29,  "min": 15  },
    "LB1": { "max": 44,  "min": 30  },
    "HB1": { "max": 59,  "min": 45  },
    "LB2": { "max": 89,  "min": 60  },
    "HB2": { "max": 119, "min": 90  },
    "C1":  { "max": 160, "min": 120 },
  },
  "targetSpeaking": {
    "A1":  { "max": 13,  "min": 0   },
    "A2":  { "max": 26,  "min": 14  },
    "LB1": { "max": 39,  "min": 27  },
    "HB1": { "max": 53,  "min": 40  },
    "LB2": { "max": 80,  "min": 54  },
    "HB2": { "max": 107, "min": 81  },
    "C1":  { "max": 144, "min": 108 },
  }
}

export const competanceCodeToDesc = {
  'sccc': {
    '1': { 'canopyLevel': 'Not Yet Competent',    'isQbsScore': false, 'isScoreable': true, 'description': 'You deliver only somewhat accurate information and do not always achieve your communication goals. You do not effectively demonstrate empathy, rapport, respect, or friendliness. You often struggle to use language that is accessible to patients or to smoothly transition between medical and informal language.' },
    '2': { 'canopyLevel': 'Somewhat Competent',   'isQbsScore': false, 'isScoreable': true, 'description': 'You deliver mostly accurate information and partly achieve your communication goals. You sometimes demonstrate empathy, rapport, respect, or friendliness. You may ocassionally struggle to use language that is accessible to patients or to smoothly transition between medical and informal language. Your speed of speech is usually easy to follow.' },
    '3': { 'canopyLevel': 'Reasonably Competent', 'isQbsScore': true,  'isScoreable': true, 'description': 'You nearly always deliver accurate information and generally achieve your communication goals. You usually demonstrate empathy, rapport, respect, and friendliness. You almost always use language that is accessible to patients and smoothly transition between medical and informal language. Your speed of speech is usually easy to follow.' },
    '4': { 'canopyLevel': 'Highly Competent',     'isQbsScore': true,  'isScoreable': true, 'description': 'You deliver completely accurate information and fully achieve your communication goals. You demonstrate empathy, rapport, respect, and friendliness. You use language that is accessible to patients, and you smoothly transition between medical and informal language. Your speed of speech is easy to follow.' },
  }
}

export const examStatusesList = {
  'Registered For Exam'      : { 'waiting_for_self_assessments': 'Waiting For Self Assessment', 'assigned': 'Waiting For Exam', 'active': 'Active' },
  'Completed Exam'           : { 'done': 'Waiting For Grades', 'rated': 'Received Grades' },
  'Qualified Bilingual Staff': { 'achieved_qbs': 'Achieved QBS' }
}

export const statusToPercentage = {
  'assigned':   '33',
  'done':       '66',
  'rated':      '100',
  'terminated': '0',
}
