import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../AutoComplete/AutoComplete';

import '../SelectCourseEntity.scss';

const SelectCourse = ({ courses, selectionCallBack }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleCourseSelection = (e) => {
    setSelectedCourse(e);
    selectionCallBack({ model: 'Course', id: e.value });
  }

  const renderCourseInput = () => {
    const selectableCourses = Object.values(courses || {});

    if (selectableCourses.length === 0) {
      return <div className="Empty-Options">No courses available for selection!</div>
    }

    return (
      <div className="Input-Container Course-Input-Container">
        <label className="Label">Course</label>
        <AutoComplete
          className="Select"
          options={selectableCourses.map((crs) => { return { value: crs.id, label: crs.name } })}
          value={selectedCourse}
          onChange={(e) => handleCourseSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  return (
    <div className="Select-Course-Entity-Container Select-Course-Container">
      {renderCourseInput()}
    </div>
  )
}

SelectCourse.propTypes = {
  courses: PropTypes.object,
  selectionCallBack: PropTypes.func.isRequired,
}

export default SelectCourse;
