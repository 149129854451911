import React from 'react';
import PropTypes from 'prop-types';

import WordScrambleBox from './WordScrambleBox';

const WordScrambleContainer = ({ section, newProps }) => (
    <div className="FillInTheBlankContainer">
        {
            section.questions.map(
                (_, index) => (
                    <WordScrambleBox
                        key={index}
                        currentQuestionIndex={index}
                        {...newProps}
                    />
                ),
            )
        }
    </div>
);

WordScrambleContainer.propTypes = {
    section: PropTypes.shape({
        questions: PropTypes.array,
    }).isRequired,
    newProps: PropTypes.shape({}).isRequired,
};

export default WordScrambleContainer;
