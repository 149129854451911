import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './CreationTopicsDashboard.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Modal from '../../Composed/Modal/Modal';
import CreateEditLesson from '../../Forms/CreateEditLesson/CreateEditLesson';
import CreateEditActivity from '../../Forms/CreateEditActivity/CreateEditActivity';
import CreateEditTopic from '../../Forms/CreateEditTopic/CreateEditTopic';
import CreateEditQuiz from '../../Forms/CreateEditQuiz/CreateEditQuiz';
import TopicBox from '../../Presentational/TopicBox/TopicBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import Confirm from '../../Composed/Confirm/Confirm';
import Loader from '../../Composed/Loader/Loader';
import SectionableCloneContainer from '../../Forms/Clone/SectionableClone/SectionableCloneContainer';

class CreationTopicsDashboard extends Component {
    state = { loading: true };

    componentDidMount() {
        let queryString = new URLSearchParams(this.props.location.search);

        Promise.all([
            this.props.clearTopicErrors(),
            this.props.fetchLessons(this.props.course.id),
            this.props.fetchTopics(this.props.currentLessonId),
        ]).then(() => {
            this.setState({ loading: false });

            if (queryString.has('nextToq')) {
                let pathHash = window.location.hash.split('?');
                window.location.hash = `${pathHash[0]}${queryString.get('nextToq')}?${pathHash[1]}`;
            }
        });
    }

    deleteActivityHandler(e, activityId) {
        e.preventDefault();
        this.props.deleteActivity(activityId);
    }

    deleteQuizHandler(e, quizId) {
        e.preventDefault();
        this.props.deleteQuiz(quizId);
    }

    restoreQuizHandler(e, quizId) {
        e.preventDefault();
        this.props.restoreQuiz(quizId);
    }

    handleTopicRender() {
        const { topics, lesson, course, deleteTopic } = this.props;
        const topicList = lesson.topics.map(
        topicId => {
            return (
            <div
                className="CreationDashboards-TopicList-Item"
                key={topicId}
            >
                <div className="PositionButtons-Container">
                    <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-chevron-up" />}
                        onClick={() => this.props.moveHigher(topicId)}
                    />
                    <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-chevron-down" />}
                        onClick={() => this.props.moveLower(topicId)}
                    />
                </div>
                
                <div className="CreationDashboards-TopicList-Item-TopicBox">
                    <TopicBox
                        topic={ topics[topicId] }
                        lessonId={ lesson.id }
                        courseId={ course.id }
                        creation
                    />
                </div>

                <div
                className="CreationDashboards-TopicList-Item-Buttons">
                <Link
                    to={`/creation/courses/${course.id}/lessons/${lesson.id}/topics/${topicId}`}>
                    <ButtonBox
                    className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                    text="Edit Topic" />
                </Link>

                <Modal
                    title="Target Lesson"
                    subTitle={`Select the target lesson where topic "${topics[topicId]?.name}" needs to be cloned`}
                    form={close => <SectionableCloneContainer model="Topic" entity={topics[topicId]} />}
                >
                    {
                        open =>
                        <ButtonBox
                          className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                          text='Clone Topic'
                          onClick={open}
                        />
                    }
                </Modal>

                <Confirm
                    text="Are you sure you want to delete this Topic?"
                    confirm={() => deleteTopic(topicId)}
                >
                    {
                        open => (
                            <ButtonBox
                                className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                text="Delete Topic"
                                onClick={open}
                            />
                        )
                    }
                </Confirm>
                </div>
            </div>
            );
        })

        return (
        <div
            className="CreationDashboards-TopicList">
            { topicList }
        </div>
        );
    }

    render() {
        const { course, lesson, activities, topics, quizzes, editLesson, topicErrors,
                topicFlash } = this.props;

        if (this.state.loading) {
            return <Loader />;
        }

        if(!lesson || !lesson.topics) {
        return <div className="CreationDashboards"></div>;
        }

        if (Object.keys(topics).length < lesson.topics.length) {
        return <div className="CreationDashboards"></div>;
        }

        return (
        <div
            className="CreationDashboards">
            <div
            className="CreationDashboards-Menu">
            <TextBox
                text={lesson.name}
                style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
                }}
                tag="h3"
            />

            <Modal
                title="Edit Lesson"
                form={
                close =>
                    <CreateEditLesson
                    lesson={lesson}
                    submitLesson={editLesson}
                    closeModal={close} />
                }>
                {
                open =>
                    <div
                    className="CreationDashboards-Menu-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Edit Lesson"
                        onClick={open} />
                    </div>
                }
            </Modal>
            </div>

            <div
            className="CreationDashboards-DescriptionBox">
            <TextBox
                text="Description: "
                style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
                }}
                tag="h5"
            />

            <TextBox
                text={lesson.description}
                style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
                }}
                tag="p"
            />
            </div>

            <div className="CreationDashboards-Menu">
                <TextBox
                    text="Activity"
                    style={{
                        color: `${COLORS.Blue}`,
                        fontWeight: "600",
                        textDecoration: "none",
                    }}
                    tag="h3"
                />

                {
                    !activities[lesson.activity_id]?.id &&
                    <Modal
                        title="Add An Activity"
                        form={
                            close =>
                            <CreateEditActivity
                                lesson={lesson}
                                submitActivity={this.props.createActivity}
                                closeModal={close}
                            />
                        }
                    >
                    {
                        open =>
                        <div
                            className="CreationDashboards-Menu-Button">
                            <ButtonBox
                                className="CreationDashboards-ButtonBox"
                                text="Add An Activity"
                                onClick={open}
                            />
                        </div>
                    }
                    </Modal>
                }
            </div>
            <div>
                {
                    activities[lesson.activity_id]?.id && (
                        activities[lesson.activity_id]?.status === 'active' ? (
                            <div className="CreationDashboards-TopicList">
                                <div className="CreationDashboards-TopicList-Item">
                                    <div className="CreationDashboards-TopicList-Item-TopicBox">
                                        <TopicBox
                                            topic={activities[lesson.activity_id]}
                                            lessonId={lesson.id}
                                            courseId={course.id}
                                            activityMode
                                            creation
                                        />
                                    </div>

                                    <div className="CreationDashboards-TopicList-Item-Buttons">
                                        <Link to={`/creation/courses/${course.id}/lessons/${lesson.id}/activity`}>
                                            <ButtonBox
                                                className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                                text="Edit Activity"
                                            />
                                        </Link>

                                        <Modal
                                            title="Target Lesson"
                                            subTitle={`Select the target lesson where activity "${activities[lesson.activity_id]?.name}" needs to be cloned`}
                                            form={close => <SectionableCloneContainer model="Activity" entity={activities[lesson.activity_id]} />}
                                        >
                                            {
                                                open =>
                                                <ButtonBox
                                                  className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                                  text='Clone Activity'
                                                  onClick={open}
                                                />
                                            }
                                        </Modal>

                                        <Confirm
                                            text="Are you sure you want to delete this activity?"
                                            confirm={(e) => this.deleteActivityHandler(e, lesson.activity_id)}
                                        >
                                            {
                                                open =>
                                                <ButtonBox
                                                    className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                                    text="Delete Activity"
                                                    onClick={open}
                                                />
                                            }
                                        </Confirm>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="Archive-Quiz">
                                The activity has been archived!
                            </div>
                        )
                    )
                }
            </div>

            <div
            className="CreationDashboards-Menu">
            <TextBox
                text="Topics"
                style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
                }}
                tag="h3"
            />

            {
                !lesson.is_test &&
                <Modal
                title="Add A Topic"
                form={
                    close =>
                    <CreateEditTopic
                        lesson={lesson}
                        submitTopic={this.props.createTopic}
                        closeModal={close} />
                }>
                {
                    open =>
                    <div
                        className="CreationDashboards-Menu-Button">
                        <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Add A Topic"
                        onClick={open} />
                    </div>
                }
                </Modal>
            }
            </div>

            <ErrorFlashBox
            errors={topicErrors}
            flash={topicFlash} />

            {
            lesson.is_test &&
            <div
                className="CreationDashboards-DescriptionBox">
                <TextBox
                text="This Lesson is a test:"
                style={{
                    color: `${COLORS.Blue}`,
                    fontWeight: "600",
                    textDecoration: "none",
                }}
                tag="h5"
                />

                <TextBox
                text="It does not have any topics"
                style={{
                    color: `${COLORS.Blue}`,
                    fontWeight: "600",
                    textDecoration: "none",
                }}
                tag="p"
                />
            </div>
            }

            { this.handleTopicRender() }

            <div
            className="CreationDashboards-Menu">
            <TextBox
                text="Quiz"
                style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
                }}
                tag="h3"
            />

            {
                !quizzes[lesson.quiz_id]?.id &&
                <Modal
                title="Add A Quiz"
                form={
                    close =>
                    <CreateEditQuiz
                        lesson={lesson}
                        submitQuiz={this.props.createQuiz}
                        closeModal={close} />
                }>
                {
                    open =>
                    <div
                        className="CreationDashboards-Menu-Button">
                        <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Add A Quiz"
                        onClick={open} />
                    </div>
                }
                </Modal>
            }
            </div>
            
            {
                quizzes[lesson.quiz_id]?.id && (
                    quizzes[lesson.quiz_id].status === 'active' ? (
                        <div
                            className="CreationDashboards-TopicList">
                            <div
                            className="CreationDashboards-TopicList-Item">
                            <div
                                className="CreationDashboards-TopicList-Item-TopicBox">
                                <TopicBox
                                topic={quizzes[lesson.quiz_id]}
                                lessonId={lesson.id}
                                courseId={course.id}
                                quizMode
                                creation />
                            </div>

                            <div
                                className="CreationDashboards-TopicList-Item-Buttons">
                                <Link
                                to={`/creation/courses/${course.id}/lessons/${lesson.id}/quiz`}>
                                <ButtonBox
                                    className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                    text="Edit Quiz" />
                                </Link>

                                <Modal
                                    title="Target Lesson"
                                    subTitle={`Select the target lesson where quiz "${quizzes[lesson.quiz_id]?.name}" needs to be cloned`}
                                    form={close => <SectionableCloneContainer model="Quiz" entity={quizzes[lesson.quiz_id]} />}
                                >
                                    {
                                        open =>
                                        <ButtonBox
                                          className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                          text='Clone Quiz'
                                          onClick={open}
                                        />
                                    }
                                </Modal>

                                <Confirm
                                    text="Are you sure you want to delete this quiz?"
                                    confirm={(e) => this.deleteQuizHandler(e, lesson.quiz_id)}
                                >
                                    {
                                        open =>
                                        <ButtonBox
                                            className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                            text="Delete Quiz"
                                            onClick={open}
                                        />
                                    }
                                </Confirm>
                            </div>
                            </div>
                        </div>
                    ) : (
                        <div className="Archive-Quiz">
                            The quiz has been archived!

                            <div className="Restore-Quiz">
                                <Confirm
                                    text="Are you sure you want to restore this quiz?"
                                    confirm={(e) => this.restoreQuizHandler(e, lesson.quiz_id)}
                                >
                                    {
                                        open =>
                                        <ButtonBox
                                            className="CreationDashboards-ButtonBox TopicBox-ButtonBox"
                                            text="Restore Quiz"
                                            onClick={open}
                                        />
                                    }
                                </Confirm>
                            </div>
                        </div>
                    )
                )
            }
        </div>
        );
    }
}

export default CreationTopicsDashboard;
