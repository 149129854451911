import { normalize } from 'normalizr';
import axios from './AxiosUtil';

import * as Schema from '../Schema/TopicSchema';

export const fetchTopics = (lessonId) => {
    return axios.get(`/api/lessons/${lessonId}/topics`).then(
        res => res.data,
    );
};

export const createTopic = (topic) => {
    return axios.post('/api/topics', { topic }).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const editTopic = (topic) => {
    return axios.patch(`/api/topics/${topic.id}`, { topic }).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const fetchTopic = (topicId) => {
    return axios.get(`/api/topics/${topicId}`).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const deleteTopic = topicId => {
    return axios.delete(`/api/topics/${topicId}`).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const moveHigher = topicId => {
    return axios.post(`/api/topics/${topicId}/higher`).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const moveLower = topicId => {
    return axios.post(`/api/topics/${topicId}/lower`).then(
        res => normalize(res.data, Schema.topic).entities,
    );
};

export const cloneTopic = (targetLessonId, topicId) => {
    return axios.post(`/api/topics/${topicId}/clone`, { target_lesson_id: targetLessonId }).then(
        res => res.data,
    );
};
