import * as APIUtil from '../Util/CoursePledgeApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_COURSE_PLEDGES = 'RECEIVE_COURSE_PLEDGES';
export const RECEIVE_COURSE_PLEDGE_ERRORS = 'RECEIVE_COURSE_PLEDGE_ERRORS';
export const CLEAR_COURSE_PLEDGES = 'CLEAR_COURSE_PLEDGES';

export const receiveCoursePledges = payload => ({
  type: RECEIVE_COURSE_PLEDGES,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_COURSE_PLEDGE_ERRORS,
  errors,
});

export const clearCoursePledges = () => ({
  type: CLEAR_COURSE_PLEDGES
});

export const createCoursePledges = (courseId) => dispatch => (
  APIUtil.createCoursePledges(courseId).then(
    payload => dispatch(receiveCoursePledges(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchCoursePledges = () => dispatch => (
  APIUtil.fetchCoursePledges().then(
    payload => dispatch(receiveCoursePledges(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const scaleUpCoursePledge = (course_pledge) => dispatch => (
  APIUtil.scaleUpCoursePledge(course_pledge).then(
    payload => dispatch(receiveCoursePledges(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const scaleDownCoursePledge = (course_pledge) => dispatch => (
  APIUtil.scaleDownCoursePledge(course_pledge).then(
    payload => dispatch(receiveCoursePledges(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
