export const streakTypesList = {
  'login': 'Login',
  'topic': 'Topic',
  'quiz': 'Quiz',
}

export const ranksList = {
  '1': { name: 'First Position',  imageSource: 'first-position.png' },
  '2': { name: 'Second Position', imageSource: 'second-position.png' },
  '3': { name: 'Third Position',  imageSource: 'third-position.png' }
}

export const streakTypeHeadLines = {
  'login': { title: 'Log-In Streak',       instruction: 'Login daily to increase this streak!' },
  'topic': { title: 'Topic Learn Streak',  instruction: 'Learn a topic daily to increase this streak!' },
  'quiz':  { title: 'Quiz Attempt Streak', instruction: 'Attempt a quiz daily to increase this streak' },
}
