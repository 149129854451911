import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/TableApiUtil';

export const RECEIVE_TABLE_ERRORS = 'RECEIVE_TABLE_ERRORS';
export const RECEIVE_TABLES = 'RECEIVE_TABLES';
export const CLEAR_TABLES = 'CLEAR_TABLES';
export const RECEIVE_TABLE = 'RECEIVE_TABLE';

export const receiveTables = payload => {
  return {
    type: RECEIVE_TABLES,
    payload,
  };
};

export const clearTables = () => {
  return {
    type: CLEAR_TABLES,
  };
};

export const receiveTable = payload => ({
  type: RECEIVE_TABLE,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_TABLE_ERRORS,
  errors
});

export const createTable = (table) => dispatch => (
  APIUtil.createTable(table).then(
    payload => dispatch(receiveTable(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editTable = (table) => dispatch => (
  APIUtil.editTable(table).then(
    payload => dispatch(receiveTable(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteTable = tableId => dispatch => (
  APIUtil.deleteTable(tableId).then(
    payload => dispatch(receiveTable(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);