import axios from './AxiosUtil';

export const createQuestion = (question) => {
  return axios.post(`/api/questions`, { question }).then(
    res => res.data
  );
};

export const editQuestion = (question) => {
  return axios.patch(`/api/questions/${question.id}`, { question }).then(
    res => res.data
  );
};

export const deleteQuestion = questionId => {
  return axios.delete(`/api/questions/${questionId}`).then(
    res => res.data
  );
}

export const uploadAudio = (formData) => {
  return axios({
    method: "PATCH",
    url: `/api/questions/${formData.get("question[id]")}`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  );
};
