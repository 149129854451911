import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import './Speak.scss';
import UserParticipation from './UserParticipation';
import Filters from '../Filters/Filters';
import UserPerformance from "./UserPerformance";
import Loader from '../../../Composed/Loader/Loader';

const mapStateToProps = (state) => ({
  flippers: state.access.flippers
});

const mapDispatchToProps = (dispatch) => ({});

const Speak = ({ flippers }) => {
  const [loading, setLoading] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  const formNames = ['OVERVIEW', 'USER ROSTER MANAGEMENT'];

  useLayoutEffect(() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 500;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const renderDashboard = () => {
    if (formIndex === 0) {
      return (
        <div className="OverView-Container">
          <Filters
            showFilters={['timesFilter']}
            forFilters={formNames[formIndex]}
            filtersLoaded={(flag) => setLoading(!flag)}
          />
          {
            loading ? <Loader /> :  (
              <>
                <UserParticipation contentWidth={contentWidth} />
                <UserPerformance contentWidth={contentWidth} />
              </>
            )
          }
        </div>
      );
    }

    return null;
  };

  if (!flippers?.actor?.speak_dashboard?.enabled) {
    return null;
  }

  return (
    <div className="B2B-Admin-Speak-Dashboard-Container">
      <div className="Speak-Dashboard-Tabs">
        {
          formNames.map((formName, idx) => (
            <div
              key={`speak-dashboard-tab-${idx}`}
              className={`Tab-Button ${formIndex === idx ? 'Active' : ''}`}
              onClick={() => { setLoading(true); setFormIndex(idx); }}
            >
              {formName}
            </div>
          ))
        }
      </div>
      {renderDashboard()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Speak);
