import * as APIUtil from '../../../Util/Stats/B2BAdmin/SpeakApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY = 'RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY';
export const RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY_ERRORS';
export const RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS = 'RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS';
export const RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS_ERRORS';
export const RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS = 'RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS';
export const RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS_ERRORS';
export const RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS = 'RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS';
export const RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS_ERRORS';
export const RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES = 'RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES';
export const RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES_ERRORS';
export const RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS = 'RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS';
export const RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS_ERRORS = 'RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS_ERRORS';
export const CLEAR_B2B_ADMIN_SPEAK_STATS = 'CLEAR_B2B_ADMIN_SPEAK_STATS';

export const receiveSpeakParticipationSummary = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY,
  payload,
});

export const receiveSpeakParticipationSummaryErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY_ERRORS,
  errors,
});

export const receiveSpeakUsageMetrics = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS,
  payload,
});

export const receiveSpeakUsageMetricsErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS_ERRORS,
  errors,
});

export const receiveSpeakPlaylistsMetrics = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS,
  payload,
});

export const receiveSpeakPlaylistsMetricsErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS_ERRORS,
  errors,
});

export const receiveSpeakPhrasesMetrics = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS,
  payload,
});

export const receiveSpeakPhrasesMetricsErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS_ERRORS,
  errors,
});

export const receiveSpeakWorkflowsScores = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES,
  payload,
});

export const receiveSpeakWorkflowsScoresErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES_ERRORS,
  errors,
});

export const receiveSpeakSkillMetrics = (payload) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS,
  payload,
});

export const receiveSpeakSkillMetricsErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS_ERRORS,
  errors,
});

export const clearSpeakStats = (key) => {
  return {
    type: CLEAR_B2B_ADMIN_SPEAK_STATS,
    key,
  };
};

export const fetchUserParticipation = (query) => dispatch => (
  Promise.all([
    APIUtil.fetchParticipationSummary(query).then(
      payload => dispatch(receiveSpeakParticipationSummary(payload)),
      errors => dispatch(receiveSpeakParticipationSummaryErrors(ErrorResponseUtil(errors)))
    ),
    APIUtil.fetchUsageMetrics(query).then(
      payload => dispatch(receiveSpeakUsageMetrics(payload)),
      errors => dispatch(receiveSpeakUsageMetricsErrors(ErrorResponseUtil(errors)))
    ),
    APIUtil.fetchPlaylistsMetrics(query).then(
      payload => dispatch(receiveSpeakPlaylistsMetrics(payload)),
      errors => dispatch(receiveSpeakPlaylistsMetricsErrors(ErrorResponseUtil(errors)))
    ),
    APIUtil.fetchPhrasesMetrics(query).then(
      payload => dispatch(receiveSpeakPhrasesMetrics(payload)),
      errors => dispatch(receiveSpeakPhrasesMetricsErrors(ErrorResponseUtil(errors)))
    )
  ])
)

export const fetchUserPerformance = (query) => dispatch => (
  Promise.all([
    APIUtil.fetchWorkflowsScores(query).then(
      payload => dispatch(receiveSpeakWorkflowsScores(payload)),
      errors => dispatch(receiveSpeakWorkflowsScoresErrors(ErrorResponseUtil(errors)))
    ),
    APIUtil.fetchSkillsMetrics(query).then(
      payload => dispatch(receiveSpeakSkillMetrics(payload)),
      errors => dispatch(receiveSpeakSkillMetricsErrors(ErrorResponseUtil(errors)))
    )
  ])
)
