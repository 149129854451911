import React, { useState } from 'react';

import InputBox from '../../../../Basic/InputBox/InputBox';
import TextBox from '../../../../Basic/TextBox/TextBox';
import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';

const EditExam = ({ exam, updateVersions, closeModal }) => {
  let versionHash = {};
  exam.supported_versions.map((v) => {
    if (exam.versions[v]) {
      versionHash[v] = exam.versions[v]
    } else {
      versionHash[v] = ''
    }
  });

  const [examAttrs, setExamAttrs] = useState({
    sapio_test_id: exam.sapio_test_id,
    versions: versionHash
  });
  const [examErrors, setExamErrors] = useState({});

  const handleInputChange = (e, field) => {
    setExamAttrs({ ...examAttrs, [field]: e.currentTarget.value });
  }

  const handleVersionInput = (e, field) => {
    setExamAttrs({
      ...examAttrs,
      versions: { ...(examAttrs.versions), [field]: e.currentTarget.value }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    if (examAttrs.sapio_test_id.length === 0) {
      errors['sapio_test_id'] = 'Default version should not be blank!';
    }

    setExamErrors(errors);

    if (Object.keys(errors).length === 0) {
      updateVersions({ ...examAttrs, id: exam.id }).then(res => closeModal())
    }
  }

  return (
    <div className="EditExam-Form">
      <form
        onSubmit={(e) => handleSubmit(e)}
      >
        {
          (Object.keys(examErrors).length !== 0) && (
            <div className="Errors">
              <ul className="list-disc pl-10">
                {
                  Object.values(examErrors).map((err, idx) => (
                    <li key={`err-${idx}`}>{err}</li>
                  ))
                }
              </ul>
            </div>
          )
        }

        <div className="sapioId">
          <lable>Default Version ID</lable>
          <InputBox
            className="InputBox-LogInBox"
            value={examAttrs.sapio_test_id}
            onChange = {(e) => handleInputChange(e, 'sapio_test_id')}
          />
        </div>
        <div>
          {
            Object.entries(examAttrs.versions).map(([k, v], idx) => (
              <div key={`input-${idx}`}>
                <lable>{k} Version ID</lable>
                <InputBox
                  className="InputBox-LogInBox"
                  value={v}
                  onChange={(e) => handleVersionInput(e, k)}
                />
              </div>
            ))
          }
        </div>
        <div
          className="Update-Submission-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox Submit-Button"
            text="Submit" />
        </div>
      </form>
    </div>
  )
}

export default EditExam;
