import * as APIUtil from '../Util/WorkPlaceApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

import { receiveCurrentUser, receiveErrors } from './SessionActions';

export const createWorkPlace = (workplace) => dispatch => (
  APIUtil.createWorkPlace(workplace).then(
    payload => dispatch(receiveCurrentUser(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const updateWorkPlace = (workplace) => dispatch => (
  APIUtil.updateWorkPlace(workplace).then(
    payload => dispatch(receiveCurrentUser(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const setCurrentWorkPlace = (workplaceId) => dispatch => (
  APIUtil.setCurrentWorkPlace(workplaceId).then(
    payload => payload,
    errors  => errors,
  )
);
