import { connect } from 'react-redux';

import LearningPage from './LearningPage';
import { recordQuizAnswer, recordQuizState, resetQuizAnswer, clearQuizProgresses, clearQuizStates } from '../../../Actions/QuizActions';
import { fetchTopic, clearTopics } from '../../../Actions/TopicActions';
import { clearSectionIndex, receiveSectionIndex } from '../../../Actions/NavigationActions';
import { saveCourseProgress } from '../../../Actions/CourseProgressActions';
import { createBookmark, deleteBookmark } from '../../../Actions/BookmarkActions';

const mapStateToProps = (state, { match }) => ({
    currentUser: state.session.currentUser,
    course: state.entities.courses[match.params.courseId],
    lesson: state.entities.lessons[match.params.lessonId],
    topic: state.entities.topics[match.params.topicId],
    courseId: match.params.courseId,
    lessonId: match.params.lessonId,
    topicId: match.params.topicId,
    quizProgress: state.statistics.quizProgresses[match.params.lessonId],
    navigationSectionIndex: state.navigation.currentSectionIndex,
    courseProgresses: state.statistics.courseProgresses,
    bookmarks: state.statistics.bookmarks
});

const mapDispatchToProps = (dispatch) => ({
    recordQuizAnswer: (answer) => dispatch(recordQuizAnswer(answer)),
    recordQuizState: (state) => dispatch(recordQuizState(state)),
    resetQuizAnswer: answer => dispatch(resetQuizAnswer(answer)),
    clearTopics: () => dispatch(clearTopics()),
    fetchTopic: topicId => dispatch(fetchTopic(topicId)),
    clearSectionIndex: () => dispatch(clearSectionIndex()),
    clearQuizProgresses: () => dispatch(clearQuizProgresses()),
    clearQuizStates: () => dispatch(clearQuizStates()),
    receiveSectionIndex: index => dispatch(receiveSectionIndex(index)),
    saveCourseProgress: (courseProgress, sectionProgress, lessonId, topicId, sectionId) => dispatch(saveCourseProgress(courseProgress, sectionProgress, lessonId, topicId, sectionId)),
    createBookmark: bookmark => dispatch(createBookmark(bookmark)),
    deleteBookmark: bookmark => dispatch(deleteBookmark(bookmark))
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningPage);
