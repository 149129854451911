import * as APIUtil from '../Util/InventoryApiUtil';
import * as CreateUserApiUtil from '../Util/CreateUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_INVENTORY = 'RECEIVE_INVENTORY';
export const CLEAR_INVENTORIES = 'CLEAR_INVENTORIES';
export const RECEIVE_INVENTORY_ERRORS = 'RECEIVE_INVENTORY_ERRORS';

export const receiveInventory = payload => ({
  type: RECEIVE_INVENTORY,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_INVENTORY_ERRORS,
  errors,
});

export const clearInventories = () => ({
  type: CLEAR_INVENTORIES,
});

export const fetchInventories = () => dispatch => (
  APIUtil.fetchInventories().then(
    payload => dispatch(receiveInventory(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchDetailedInventories = () => dispatch => (
  APIUtil.fetchDetailedInventories().then(
    payload => dispatch(receiveInventory(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors)))
  )
);

export const grantAccess = (userId, inventoryId) => dispatch => (
  APIUtil.grantAccess(userId, inventoryId).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);

export const checkExistingUser = (user) => dispatch => (
  CreateUserApiUtil.checkExistingUser(user).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);
