import { connect } from 'react-redux';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import CommentsPanel from './CommentsPanel';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './Comment.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  flippers: state.access.flippers
});

const mapDispatchToProps = (dispatch) => ({
});

const CommentsPanelHandler = ({ currentUser, flippers, commentableType, commentableId }) => {
  const [show, setShow] = useState(false);

  return flippers?.actor?.comments?.enabled && (
    <div className="Comments-Container">
      <div data-tip data-for="comments">
        <ButtonBox
          className="Comments-Button LessonAnswerButtons-ButtonBox"
          text={
            <div className="Box">
              <i className="fa fa-comment" />
              <p>Comments</p>
            </div>
          }
          onClick={() => {
            setShow(!show);
            !show && gaEvent('comments_open', { entity_id: commentableId });
          }}
        />
        <ReactTooltip place="bottom" type="dark" effect="solid" id="comments">
          Share your thoughts
        </ReactTooltip>
      </div>

      {
        show &&
        <CommentsPanel
          commentableType={commentableType}
          commentableId={commentableId}
          closePanel={() => setShow(false)}
        />
      }
    </div>
  )
}

CommentsPanelHandler.propTypes = {
  currentUser: PropTypes.object.isRequired,
  flippers: PropTypes.object.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPanelHandler);
