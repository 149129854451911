import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import { ProtectedRoute } from '../../../Util/RouteUtil';
import TrialPage from '../../Pages/TrialPage/TrialPageContainer';
import UserDashboardSummaryContainer from './UserDashboardSummary/UserDashboardSummaryContainer';
import Profile from './Profile/Profile';
import UserDashboardLearn from './UserDashboardLearn';
import UserDashboardCertificate from './UserDashboardCertificates';
import Certificate from './UserDashboardCertificates/Certificate';
import UserSideBar from '../../SideBars/UserSideBar/UserSideBar';
import Learn from './Learn/Learn';
import Compliance from './Compliance/Compliance';
import Credential from './Credential/Credential';
import NotificationPageContainer from '../../Pages/UserNotifications/NotificationPage/NotificationPageContainer';
import ResourceExhibitionContainer from '../../Pages/InstitutionResources/Exhibition/ExhibitionContainer';

import './CanopyUserDashboard.css';

const CanopyUserDashboard = props => {
    const [userDashboardReady, setUserDashboardReady] = useState(false);

    const {
        courseProgresses,
        currentUser,
        currentWorkplace,
        updateAccount,
        updatePhoto,
        updatePassword,
        hasSubscriptions,
        fetchCourses,
        fetchCourseProgresses,
        courses,
    } = props;

    const generateSecureRouteParamsFromArray = userID => {
        const titles = Object.values(courses).map(course => course.name);
        return titles.map(entry => ({
            route: CryptoJS.AES.encrypt(entry, userID.toString()).toString(),
        }));
    };

    const secureRoutes = generateSecureRouteParamsFromArray(currentUser.id);

    useEffect(() => {
        Promise.all([
            fetchCourses(),
            fetchCourseProgresses(),
        ]).then(() => setUserDashboardReady(true));
    }, [fetchCourses, fetchCourseProgresses]);

    return (
        <div className="User-Dashboard">
            <ProtectedRoute
                path="/account/:path"
                component={UserSideBar}
            />
            <div className="User-Dashboard-Main">
                {
                    secureRoutes.map(entry => (
                        <ProtectedRoute
                            exact
                            path={`/account/certificate/${sha256(currentUser.id).words[0]}/download/${entry.route}`}
                            component={
                                () => <Certificate currentUser={currentUser} />
                            }
                        />
                    ))
                }

                <ProtectedRoute
                    exact
                    path="/account/certificate"
                    component={
                        () => (
                            <UserDashboardCertificate
                                courses={courses}
                                courseProgresses={courseProgresses}
                                currentUser={currentUser}
                                secureRoutes={secureRoutes}
                            />
                        )
                    }
                />

                <ProtectedRoute
                    path="/account/analyze"
                    component={
                        () => <UserDashboardSummaryContainer currentUser={currentUser} />
                    }
                />

                <Route path="/account/profile">
                    <Profile
                        currentUser={currentUser}
                        updateAccount={updateAccount}
                        updatePhoto={updatePhoto}
                        updatePassword={updatePassword}
                    />
                </Route>

                <ProtectedRoute
                    path="/account/learn"
                    component={
                        () => <UserDashboardLearn currentUser={currentUser} currentWorkplace={currentWorkplace} hasSubscriptions={hasSubscriptions} userDashboardReady={userDashboardReady} />
                    }
                />

                <ProtectedRoute
                    path="/account/dashboard/credential"
                    component={Credential}
                />

                <ProtectedRoute
                    path="/account/dashboard/learn"
                    component={Learn}
                />

                {
                    currentUser.accessibleProducts.compliance[currentWorkplace.id]?.access &&
                    <ProtectedRoute
                        path="/account/dashboard/compliance"
                        component={Compliance}
                    />
                }

                <ProtectedRoute
                    path="/account/notifications"
                    component={NotificationPageContainer}
                />

                <ProtectedRoute
                    path="/account/resource_library"
                    component={ResourceExhibitionContainer}
                />

                <ProtectedRoute
                    path="/account/trial"
                    component={TrialPage}
                />
            </div>
        </div>
    );
};

CanopyUserDashboard.propTypes = {
    currentUser: PropTypes.instanceOf(Object).isRequired,
    updateAccount: PropTypes.func.isRequired,
    updatePhoto: PropTypes.func.isRequired,
    courseProgresses: PropTypes.instanceOf(Object).isRequired,
};

export default CanopyUserDashboard;
