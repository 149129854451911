import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import AlertPopup from '../AlertPopup/AlertPopup';
import MirrorLoader from '../Loader/MirrorLoader';

import './UserLevelSetting.scss';

const UserLevelSetting = ({
  profileCompletion, updateProfileCompletion,
  settingName, onText, offText,
}) => {
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState({});

  const handleConfigureSetting = e => {
    e.preventDefault();

    setProcessing(true);
    updateProfileCompletion({
      id: profileCompletion.id,
      settings: { [settingName]: !(profileCompletion.settings[settingName]) },
    }).then(res => {
      if (res.errors) {
        setResponse({ type: 'error', heading: 'Error!', description: 'Please try again later.' });
      } else {
        setResponse({ type: 'success', heading: 'Success!', description: 'Your changes have been saved!' });
      }

      setProcessing(false);
    });
  }

  return (
    <div className="User-Level-Setting">
      {processing && <MirrorLoader message="Saving your changes..." />}

      <AlertPopup
        close={() => setResponse({})}
        kind={response.type}
        title={response.heading}
        messages={[response.description]}
      />

      <ButtonBox
        className="Configure-Btn"
        text={profileCompletion.settings[settingName] ? offText : onText}
        onClick={e => handleConfigureSetting(e)}
      />
    </div>
  )
};

UserLevelSetting.propTypes = {
  profileCompletion: PropTypes.object.isRequired,
  updateProfileCompletion: PropTypes.func.isRequired,
  settingName: PropTypes.string.isRequired,
  onText: PropTypes.string.isRequired,
  offText: PropTypes.string.isRequired,
};

export default UserLevelSetting;
