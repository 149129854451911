import React from 'react';

import './DropDownBox.css';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import { COLORS } from '../../../Style/Style';

/**
* @class - DropDownBox - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const DropDownBox = (props) => {

  const { section, questions, quizProgress, lessonId, currentQuestionIndex } = props;
  const currentQuestion = questions[currentQuestionIndex];

  const options = {}
  currentQuestion.options.forEach(
    option => {
      if (options[option.index]) {
        options[option.index].push(option);
      } else {
        options[option.index] = [option];
      }
    }
  ) 

  const questionParts = currentQuestion.text.split("__drop__");

  const toggleChangeHandler = (idx) => {
    gaEvent('drop_down_option_select', { section_id: section.id });
    return (e) => {
      recordProgress(idx, e.currentTarget.value);
    };
  };

  const recordProgress = (idx, userValue) => {
    const answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            [idx]: {
              text:       userValue,
              is_correct: userValue === currentQuestion.answers[idx].text,
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  }

  const pinUnansweredRender = () => {
    return (
      props.pinUnanswered &&
      (Object.keys(quizProgress?.[section.id]?.[currentQuestion.id] || {}).length !== currentQuestion.answers.length) &&
      (
        <div className="Pin-Unanswered">
          <ImageBox class="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  const handleQuestionRender = () => {
    let question = [];
    questionParts.forEach((part, idx) => {
      part.split(" ").forEach(
        (word, idx2) => {
          question.push(
            <TextBox
              key={(idx + 1) * 1000 + idx2}
              text={word}
              tag='h5'
              style={{
                color: COLORS.Blue
              }} />
          );
        }
      )

      if (Object.keys(options)[idx]) {
        if (props.revealAnswers && !quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.is_correct) {
          recordProgress(idx, currentQuestion.answers[idx].text);
        }

        question.push(
          <div
            className="DropDownBox-Select"
            key={idx}>
            <span style={{ margin: "0 5px"}}>
              {
                props.checkAnswers && quizProgress?.[section.id]?.[currentQuestion.id]?.[idx] &&
                (
                  quizProgress[section.id][currentQuestion.id][idx].is_correct ?
                    <i class="fas fa-check icon-correct"></i>
                    :
                    <i class="fas fa-times icon-wrong"></i>
                )
              }
            </span>

            <select
              style={
                props.checkAnswers && quizProgress?.[section.id]?.[currentQuestion.id]?.[idx] ?
                (
                  quizProgress[section.id][currentQuestion.id][idx].is_correct ?
                      { borderColor: "#7de3bb", backgroundColor: "#f5fcf9" }
                  :
                      { borderColor: "#D47e7e", backgroundColor: "#fcf9f9" }
                )
                :
                {}
              }
              onChange={toggleChangeHandler(idx)}
              value={
                  quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]
                  ?
                  quizProgress[section.id][currentQuestion.id][idx].text
                  :
                  "none"
              }
              data-id={idx}>
              <option
                disabled
                value="none">select an option
              </option>
              {
                options[idx].map(
                  (option, index) => 
                    <option key={index} value={option.text}>
                      { option.text }
                    </option>
                )
              }
            </select>

          </div>
        );
      }
    });
    return question;
  };

  return (
    <div className='DropDownBox'>
      { pinUnansweredRender() }
      <div
        className="DropDownBox-QuestionBox">
        {
          !props.isComplianceCourse && (
            !props.small &&
            <div
              className="FillInTheBlankBox-QuestionBox-Position">
              <TextBox
                text={currentQuestion.position} />
            </div>
          )
        }
        
        <QuestionBox question={ currentQuestion }>
          { handleQuestionRender() }

          {
            props.questions[props.currentQuestionIndex].hint &&
            <div className="Questions-DropDownBox-Hint">
              <TextBox
                text={`(${props.questions[props.currentQuestionIndex].hint} )`}
                tag='h5'
                style={{
                  color: COLORS.lightBlue1
                }} />
            </div>
          }
        </QuestionBox>
      </div>
    </div>
  );
};

export default DropDownBox;
