import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import { receiveSectionIndex } from '../../../Actions/NavigationActions';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import CircularProgressBar from '../../Basic/CircularProgressBar/CircularProgressBar';

const mapStateToProps = (state) => {
  return {
    navigationSectionIndex:
      state.navigation.currentSectionIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveSectionIndex: index => dispatch(receiveSectionIndex(index)),
  };
};

const NavigationSection = ({
  course,
  courseProgress,
  lesson,
  topic,
  section,
  sectionIndex,
  setNavigationOpen,
  navigationSectionIndex,
  receiveSectionIndex,
  pathname
}) => {
  const sectionCompleted = () => {
    return (courseProgress?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses?.[section.id] !== undefined);
  }

  let source

  switch (section.section_type) {
    case "flashcard":
      source = "Flashcard.svg"
      break;
    case "video":
      source = "Telenovela.svg"
      break;
    case "dialog":
      source = "RolePlay.svg"
      break;
    case "text":
      source = "Lesson.svg"
      break;
    default:
      source= "Exercise.svg"
      break;
  }

  return (
    <Link
      to={`/learn/courses/${course.id}/lessons/${lesson.id}/topics/${topic.id}`}
      onClick={() => {
        receiveSectionIndex(sectionIndex);
        setNavigationOpen(false);
      }}>
        <div 
          className={
            pathname.split('/')[7] && +pathname.split('/')[7] === topic.id &&
              navigationSectionIndex === sectionIndex ?
              "NavigationSection NavigationSection-Selected"
              :
              "NavigationSection"
          }>
            <TextBox
              text={section.name}
            />

            {
              sectionCompleted() ?
              (
                <ImageBox className="ImageBox-Done" src="nav-item-done.svg" />
              )
              :
              (
                <div className="CoursesDashboard-Course-CircularProgressBar">
                  <CircularProgressBar
                    totalNumberOfSteps="100"
                    completedSteps="0"
                  />
                </div>
              )
            }
        </div>
    </Link>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationSection);
