import CryptoJS from 'crypto-js';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(
      CryptoJS.AES.decrypt(
        serializedState,
        (sessionStorage.jwtToken || 'this is a super secret key')
      ).toString(CryptoJS.enc.Utf8));
  } catch (errors) {
    return {};
  }
};

export const saveState = (state) => {
  try {
    const serializedState = CryptoJS.AES.encrypt(
      JSON.stringify(state),
      (sessionStorage.jwtToken || 'this is a super secret key')
    ).toString();
    sessionStorage.setItem('state', serializedState);
  } catch (errors) {
    // Ignore write errors.
  }
};