import React, { useEffect } from 'react';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import './CMECertificateModal.scss';

const CMECertificateModal = ({ course, setShowCMEOModal }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace(course.cmeo_certificate_link);
    }, 5000);

    return () => { clearTimeout(timer) };
  }, []);

  return (
    <div className="PledgeModal-Container">
      <div className="modal">
        <div className="Modal-Content">
          <ImageBox src="Down-Right-Arrow.svg" className="Modal-Icon" />
          <div className="Heading">You are being directed to the CME Outfitters website</div>
          <p className="Sub-Heading">
            You are being directed to the CME Outfitters website. You must fill out the evaluation at the CME Outfitters site to obtain your completion certificate and Continuing Education credits for the {course.name} course.
          </p>
          <div className="Cancel-Link" onClick={e => setShowCMEOModal(false)}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}

export default CMECertificateModal;
