import { combineReducers } from 'redux';

import currentSubscriptions from './CurrentSubscriptions';
import billingInfo from './BillingInfo';

const subscriptionsBilling = combineReducers({
    currentSubscriptions,
    billingInfo,
});

export default subscriptionsBilling;
