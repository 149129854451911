import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';
import InputBox from '../../Basic/InputBox/InputBox';
import TextAreaBox from '../../Basic/TextAreaBox/TextAreaBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import Confirm from '../../Composed/Confirm/Confirm';

import './CreateEditInstitutionResource.scss';

const CreateEditInstitutionResource = ({
  selectedInstitutionId, institutionResource, currentUser, platforms, mediaTypes,
  createInstitutionResource, updateInstitutionResource, closeForm,
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [institutionResourceAttrs, setInstitutionResourceAttrs] = useState(
    institutionResource ? {
      title: institutionResource.title,
      description: institutionResource.description,
      url: institutionResource.url,
      files: [],
      institution_resource_platforms_attributes: Object.assign([], Object.values(institutionResource.institutionResourcePlatforms || {})),
      institution_resource_media_types_attributes: Object.assign([], Object.values(institutionResource.institutionResourceMediaTypes || {})),
    } : {
      title: '', description: '', url: '', files: [], institution_resource_platforms_attributes: [], institution_resource_media_types_attributes: []
    }
  );

  const fileRef = useRef();
  const allowedFileTypes = ['audio/mpeg', 'video/mp4', 'image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const attachNewFiles = (e) => {
    let existingFileNames = Object.values(institutionResource?.files || {}).map(f => f.name);
    let newFileNames = institutionResourceAttrs.files.map(f => f.name);
    let currentFileNames = [...existingFileNames, ...newFileNames];

    let newFiles = Array.from(e.currentTarget.files).filter(f => !currentFileNames.includes(f.name));

    setInstitutionResourceAttrs({ ...institutionResourceAttrs, files: [...institutionResourceAttrs.files, ...newFiles] });

    fileRef.current.value = '';
  }

  const removeFile = (file) => {
    let currentFiles = Object.assign([], institutionResourceAttrs.files);

    let idx = currentFiles.indexOf(file);

    if (idx > -1) {
      currentFiles.splice(idx, 1);
    }

    setInstitutionResourceAttrs({ ...institutionResourceAttrs, files: currentFiles });
  }

  const handleCheckBoxChange = (attrKey, foreignKey, isChecked, value) => {
    let currentList = Object.assign([], institutionResourceAttrs[attrKey]);
    let entry = currentList.find(entry => entry[foreignKey] === value);
    let entryIndex = currentList.findIndex(entry => entry[foreignKey] === value);

    if (isChecked) {
      if (entry?.id && entryIndex > -1) {
        currentList[entryIndex]._destroy = false;
      } else if (!entry?.id && entryIndex === -1) {
        currentList.push({ [foreignKey]: value });
      }
    } else {
      if (entry?.id && entryIndex > -1) {
        currentList[entryIndex]._destroy = true;
      } else if (!entry?.id && entryIndex > -1) {
        currentList.splice(entryIndex, 1);
      }
    }

    setInstitutionResourceAttrs({ ...institutionResourceAttrs, [attrKey]: currentList });
  }

  const validateForm = () => {
    let errors = {};

    if (institutionResourceAttrs.title.length === 0) {
      errors['title'] = 'Title is required.';
    }

    if (institutionResourceAttrs.files.length > 0) {
      institutionResourceAttrs.files.map((f) => {
        if (!allowedFileTypes.includes(f.type)) {
          errors['file'] = 'File must be a valid audio, video, image, PDF, document, or spreadsheet file';
        }
      });
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm();

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      const formData = new FormData();

      if (institutionResource) {
        formData.append('institution_resource[id]', institutionResource.id);
        formData.append('institution_resource[index]', institutionResource.index);
      }

      formData.append('institution_resource[institution_id]', selectedInstitutionId);
      formData.append('institution_resource[title]', institutionResourceAttrs.title);
      formData.append('institution_resource[description]', institutionResourceAttrs.description);
      formData.append('institution_resource[url]', institutionResourceAttrs.url);

      institutionResourceAttrs.files.map(f => formData.append('institution_resource[files][]', f));

      institutionResourceAttrs.institution_resource_platforms_attributes.map((irpa, idx) => (
        Object.entries(irpa).map(([k, v]) => (
          formData.append(`institution_resource[institution_resource_platforms_attributes][${idx}][${k}]`, v)
        ))
      ));

      institutionResourceAttrs.institution_resource_media_types_attributes.map((irmta, idx) => (
        Object.entries(irmta).map(([k, v]) => (
          formData.append(`institution_resource[institution_resource_media_types_attributes][${idx}][${k}]`, v)
        ))
      ));

      if (institutionResource) {
        updateInstitutionResource(formData).then(res => handleResponse(res));
      } else {
        createInstitutionResource(formData).then(res => handleResponse(res));
      }
    }
  }

  const handleArchive = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append('institution_resource[id]', institutionResource.id);
    formData.append('institution_resource[institution_id]', selectedInstitutionId);
    formData.append('institution_resource[archived_by_id]', currentUser.id);
    formData.append('institution_resource[archived_at]', (new Date()));
    formData.append('institution_resource[index]', institutionResource.index);

    updateInstitutionResource(formData).then(res => handleResponse(res));
  }

  const handleFileRemove = (e, fileId) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append('institution_resource[id]', institutionResource.id);
    formData.append('institution_resource[institution_id]', selectedInstitutionId);
    formData.append('institution_resource[purge_attachment_id]', fileId);
    formData.append('institution_resource[index]', institutionResource.index);

    updateInstitutionResource(formData).then(res => handleResponse(res, false));
  }

  const handleResponse = (res, shouldClose = true) => {
    setSubmitting(false);

    if (res.errors) {
      setErrors({ error: res.errors.message });
    } else if (shouldClose) {
      closeForm();
    }
  }

  return (
    <div className="Institution-Resource-Form">
      <div className="modal">
        <div className="modal-content">
          {submitting && <MirrorLoader message='Submitting...' />}

          <div className="modal-header">
            <div className="Title">{institutionResource ? 'Edit' : 'Add'} RESOURCE</div>
            <ButtonBox
              className="Close-Button"
              src="close.svg"
              onClick={() => closeForm()}
            />
          </div>

          <div className="modal-body">
            {selectedInstitutionId === 1 && <div className="Flash-Message Info">This resource is for all institutions and all users!</div>}

            <ul className="Form-Errors list-disc pl-10">
              {
                Object.values(errors)?.map((error, idx) => (
                  <li key={idx} children={error} />
                ))
              }
            </ul>

            <div className="Field-Holder">
              <label className="Label">Title</label>
              <InputBox
                className="Input"
                value={institutionResourceAttrs.title}
                onChange={(e) => setInstitutionResourceAttrs({ ...institutionResourceAttrs, title: e.currentTarget.value })}
              />
            </div>

            <div className="Field-Holder">
              <label className="Label">Description</label>
              <TextAreaBox
                className="Text-Area"
                value={institutionResourceAttrs.description}
                onChange={(e) => setInstitutionResourceAttrs({ ...institutionResourceAttrs, description: e.currentTarget.value })}
              />
            </div>

            <div className="Field-Holder">
              <label className="Label Top">Attachments</label>
              <div className="Files">
                {
                  Object.values(institutionResource?.files || {}).map((file, idx) => (
                    <div key={`existing-file-list-${idx}`} className="File">
                      <span className="Name">{file.name}</span>
                      <Confirm
                        text="Are you sure you want to remove this attachment?"
                        confirm={(e) => handleFileRemove(e, file.id)}
                      >
                        {
                          (open) =>
                          <ButtonBox
                            text="Remove"
                            className="Remove"
                            onClick={() => open()}
                          />

                        }
                      </Confirm>
                    </div>
                  ))
                }
                {
                  institutionResourceAttrs.files.map((file, idx) => (
                    <div key={`new-file-list-${idx}`} className="File">
                      <span className="Name">{file.name}</span>
                      <ButtonBox
                        text="Remove"
                        className="Remove"
                        onClick={() => removeFile(file)}
                      />
                    </div>
                  ))
                }
                <label className="Upload">
                  <span className="Icon">+</span>
                  <span className="Text">Add Attachment</span>
                  <input
                    className="Input"
                    type="file"
                    ref={fileRef}
                    multiple={true}
                    accept={allowedFileTypes.join(', ')}
                    onChange={(e) => attachNewFiles(e)}
                  />
                </label>
              </div>
            </div>

            <div className="Field-Holder">
              <label className="Label">URL</label>
              <InputBox
                className='Input'
                value={institutionResourceAttrs.url}
                onChange={(e) => setInstitutionResourceAttrs({ ...institutionResourceAttrs, url: e.currentTarget.value })}
              />
            </div>

            <div className="Field-Holder">
              <label className="Label Top">Resource Type</label>
              <div className="CheckBoxes">
                {
                  Object.values(mediaTypes).map((mediaType, idx) => (
                    <label key={`media-type-checkbox-${idx}`}>
                      <CheckBox
                        className="CheckBox"
                        name={mediaType.name}
                        value={mediaType.id}
                        checked={institutionResourceAttrs.institution_resource_media_types_attributes.find(irmta => irmta.media_type_id === mediaType.id && !irmta._destroy)}
                        onChange={(e) => handleCheckBoxChange('institution_resource_media_types_attributes', 'media_type_id', e.currentTarget.checked, parseInt(e.currentTarget.value))}
                      />
                      <div className="Text">{mediaType.name}</div>
                    </label>
                  ))
                }
              </div>
            </div>

            <div className="Field-Holder">
              <label className="Label Top">Related Products</label>
              <div className="CheckBoxes">
                {
                  Object.values(platforms).map((platform, idx) => (
                    <label key={`platform-checkbox-${idx}`}>
                      <CheckBox
                        className="CheckBox"
                        name={platform.name}
                        value={platform.id}
                        checked={institutionResourceAttrs.institution_resource_platforms_attributes.find(irpa => irpa.platform_id === platform.id && !irpa._destroy)}
                        onChange={(e) => handleCheckBoxChange('institution_resource_platforms_attributes', 'platform_id', e.currentTarget.checked, parseInt(e.currentTarget.value))}
                      />
                      <div className="Text">{platform.name}</div>
                    </label>
                  ))
                }
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="Left">
              {
                institutionResource && !institutionResource.archived_at &&
                <Confirm
                  text="Are you sure you want to archive this resource?"
                  confirm={(e) => handleArchive(e)}
                >
                  {
                    (open) =>
                    <ButtonBox
                      className="Archive-Button"
                      text="Archive this resource"
                      icon="fa-archive"
                      onClick={() => open()}
                    />
                  }
                </Confirm>
              }
            </div>
            <div className="Right">
              <ButtonBox
                className="Cancel-Button Secondary-Btn"
                text={<span className="Secondary-Btn-Text">Cancel</span>}
                onClick={() => closeForm()}
              />
              <ButtonBox
                className="Save-Button Primary-Btn"
                text={<span className="Primary-Btn-Text">Save</span>}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

CreateEditInstitutionResource.propTypes = {
  selectedInstitutionId: PropTypes.number.isRequired,
  institutionResource: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  platforms: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  createInstitutionResource: PropTypes.func.isRequired,
  updateInstitutionResource: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default CreateEditInstitutionResource;
