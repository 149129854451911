import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearComplianceStats, fetchMyActivities } from '../../../../Actions/Stats/Users/ComplianceActions';
import { retake } from '../../../../Actions/CourseProgressActions';

import { humanizeDate } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  myActivities: state.stats.users.compliances?.myActivities || []
});

const mapDispatchToProps = (dispatch) => ({
  clearComplianceStats: (key) => dispatch(clearComplianceStats(key)),
  fetchMyActivities: () => dispatch(fetchMyActivities()),
  retake: (courseId) => dispatch(retake(courseId))
});

const MyActivities = ({ myActivities, clearComplianceStats, fetchMyActivities, retake, contentWidth }) => {
  const [loading, setLoading] = useState(true);
  const [retaking, setRetaking] = useState(false);

  useEffect(() => {
    clearComplianceStats('myActivities');
    fetchMyActivities().then(res => setLoading(false));
  }, [fetchMyActivities]);

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
       />
    </Link>
  );

  const canRetake = () => (
    myActivities[0].completed_at ? true : false
  )

  const renderMyActivities = () => (
    <div className="CardHolder">
      <div className="Title-Container">
        <div className="Sub-Title"></div>
        {
          <div className="Link">
            <ButtonBox
              className="RetakeButton"
              text="Retake Course"
              onClick={(e) => {
                setRetaking(true);
                retake(myActivities[0].course_id).then(
                  res => window.location.reload()
                )
              }}
              disabled={!canRetake() || retaking}
            />
          </div>
        }
      </div>

      <div
        className="Content-Container Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        <table className="ActivityTable">
          <thead>
            <tr>
              <th scope="col">Compliance Course Completion Date</th>
              <th scope="col">Score</th>
              <th scope="col">Certificate</th>
            </tr>
          </thead>
          <tbody>
            {myActivities.map((courseProgress, idx)=> (
              <tr key={idx}>
                <td>{courseProgress.completed_at ? humanizeDate(courseProgress.completed_at) : 'N/A'}</td>
                <td>{courseProgress.percentage ? `${courseProgress.percentage}%` : 'N/A'}</td>
                <td>{(courseProgress.percentage >= 80) && <a href={courseProgress.cmeo_certificate_link} target="_blank">View Certificate</a>}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    if (myActivities.length === 0) {
      return renderEmpty();
    }

    return renderMyActivities();
  }

  return (
    <div className="My-Activities-Container">
      <h2 className="Title">My Activities</h2>
      {renderContent()}
    </div>
  )
}

MyActivities.propTypes = {
  myActivities: PropTypes.array.isRequired,
  clearComplianceStats: PropTypes.func.isRequired,
  fetchMyActivities: PropTypes.func.isRequired,
  contentWidth: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyActivities);
