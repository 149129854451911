import * as APIUtil from '../../../Util/Stats/Users/ComplianceApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_COMPLIANCE_STATS = 'RECEIVE_COMPLIANCE_STATS';
export const RECEIVE_COMPLIANCE_STAT_ERRORS = 'RECEIVE_COMPLIANCE_STAT_ERRORS';
export const CLEAR_COMPLIANCE_STATS = 'CLEAR_COMPLIANCE_STATS';

export const receiveComplianceStats = (payload) => ({
  type: RECEIVE_COMPLIANCE_STATS,
  payload,
});

export const receiveComplianceStatErrors = (errors) => ({
  type: RECEIVE_COMPLIANCE_STAT_ERRORS,
  errors,
});

export const clearComplianceStats = (key) => {
  return {
    type: CLEAR_COMPLIANCE_STATS,
    key,
  };
};


export const fetchPledges = () => dispatch => (
  APIUtil.fetchPledges().then(
    payload => dispatch(receiveComplianceStats(payload)),
    errors  => dispatch(receiveComplianceStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyAchievements = () => dispatch => (
  APIUtil.fetchMyAchievements().then(
    payload => dispatch(receiveComplianceStats(payload)),
    errors  => dispatch(receiveComplianceStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyActivities = () => dispatch => (
  APIUtil.fetchMyActivities().then(
    payload => dispatch(receiveComplianceStats(payload)),
    errors  => dispatch(receiveComplianceStatErrors(ErrorResponseUtil(errors))),
  )
);
