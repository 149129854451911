import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Pledges from './Pledges';
import MyAchievements from './MyAchievements';
import MyActivities from './MyActivities';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './Compliance.scss';

const Compliance = () => {
  const [contentWidth, setContentWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      setContentWidth((window.outerWidth > 768) ? (pageWidth - 500) : (pageWidth - 150));
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <div className="Compliance-Dashboard">
      <div className="ButtonHolder">
        <Link to="/learn">
          <ButtonBox
            className="Static-Primary-Btn Compliance-Btn"
            text="Go to Canopy Compliance"
          />
        </Link>
      </div>

      <Pledges contentWidth={contentWidth} />

      <MyAchievements contentWidth={contentWidth} />
      
      <MyActivities contentWidth={contentWidth} />
    </div>
  )
}

export default Compliance;
