import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { QuillModules } from '../../../Data/Quill';
import 'react-quill/dist/quill.snow.css';

import './CreateEditTextContent.css';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

class CreateEditTextContent extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.textContent
            ? {
                text: this.props.textContent.text,
            }
            : {
                text: '',
            };
    }

    handleChange = value => this.setState({ text: value })

    handleSubmit = e => {
        e.preventDefault();
        const textContent = Object.assign({}, this.state);

        if (this.props.section) {
            textContent.section_id = this.props.section.id;
        }

        if (this.props.textContent) {
            textContent.id = this.props.textContent.id;
        }

        this.props.submitTextContent(textContent).then(
            this.props.closeModal(),
        );
    }

    render() {
        return (
            <form
                className="CreationForms-Form"
                onSubmit={this.handleSubmit}
            >
                <div className="CreationForms-ReactQuill">
                    <ReactQuill
                        modules={QuillModules}
                        value={this.state.text}
                        onChange={this.handleChange}
                    />
                </div>

                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Submit"
                    />
                </div>
            </form>
        );
    }
}

export default CreateEditTextContent;
