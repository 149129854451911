import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './WordScrambleBox.css';
import { COLORS } from '../../../Style/Style';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import { shuffle } from "../../../Util/Helper/GenericUtil";
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const mapStateToProps = state => ({
    quizStates: state.statistics.quizStates,
});

const WordScrambleBox = ({
    section,
    questions,
    quizStates,
    quizProgress,
    lessonId,
    currentQuestionIndex,
    recordQuizState,
    checkAnswers,
    revealAnswers,
    recordQuizAnswer,
    pinUnanswered,
    isComplianceCourse,
}) => {
    const [animationEnabled, setAnimationEnabled] = useState(false);
    const currentQuestion = questions[currentQuestionIndex];
    const punctuation = currentQuestion.text.split("__word__");

    useEffect(() => {
        const animation = requestAnimationFrame(() => setAnimationEnabled(true));
        if (!quizStates[section.id]) {
            let presentingAnswersOrder = shuffle(currentQuestion.answers.slice(0).map(ans => ans.id));
            Object.entries(quizProgress?.[section.id]?.[currentQuestion.id] || {}).map(([idx, ans]) => {
                let alreadyAnswered = currentQuestion.answers.find(a => a.text === ans.text)
                if (alreadyAnswered) {
                    let currentPresentingIndex = presentingAnswersOrder.indexOf(alreadyAnswered.id);
                    presentingAnswersOrder.splice(currentPresentingIndex, 1);
                    presentingAnswersOrder.splice(idx, 0, alreadyAnswered.id);
                }
            });

            recordQuizState({
                [section.id]: {
                    [currentQuestion.id]: presentingAnswersOrder,
                },
            });
        }

        return () => {
          cancelAnimationFrame(animation);
          setAnimationEnabled(false);
        };
    }, [recordQuizState, quizStates[section.id], currentQuestionIndex]);

    const onDragEnd = (result) => {
        gaEvent('word_scramble_option_organized', { section_id: section.id });
        const { source, destination } = result;

        if (!destination ||
            (source.droppableId === destination.droppableId
                && source.index === destination.index)) {
            return;
        }

        const newOrder = quizStates[section.id][currentQuestion.id].slice();
        const removed = newOrder.splice(source.index, 1)[0];
        newOrder.splice(destination.index, 0, removed);

        recordProgress(newOrder);
    };

    const recordProgress = (newOrder) => {
        recordQuizState({
            [section.id]: {
                [currentQuestion.id]: newOrder,
            },
        });

        const answer = {};
        for (let i = 0; i < newOrder.length; i++) {
            const element = newOrder[i];
            const answerData = currentQuestion.answers.find(a => a.id === element);
            answer[i] = {
                text:       answerData.text,
                is_correct: answerData.index === i,
            };
        }

        recordQuizAnswer({
            [lessonId]: {
                [section.id]: {
                    [currentQuestion.id]: answer,
                },
            },
        });
    }

    const pinUnansweredRender = () => {
        return (
            pinUnanswered &&
            !quizProgress?.[section.id]?.[currentQuestion.id] &&
            (
                <div className="Pin-Unanswered">
                    <ImageBox class="Pin-Unanswered-Img" src="pin-unanswered.svg" />
                </div>
            )
        )
    }

    const handleQuestionRender = () => {
        if (!quizStates[section.id]?.[currentQuestion.id]) {
            return <div />;
        }

        if (revealAnswers && (quizStates[section.id][currentQuestion.id].join(',') != currentQuestion.answers.slice(0).map(ans => ans.id).join(','))) {
            recordProgress(currentQuestion.answers.slice(0).map(ans => ans.id));
        }

        return (
            quizStates[section.id][currentQuestion.id].map(
                (answerId, index) => {
                    const answer = currentQuestion.answers.find(
                        answerData => answerData.id === answerId
                    );

                    const isCorrect = quizStates[section.id][currentQuestion.id].indexOf(answerId) === currentQuestion.answers.indexOf(answer)

                    return (
                        <Draggable
                            draggableId={`draggable=${answer.id}`}
                            index={index}
                            key={answer.id}
                        >
                            {
                                (provided) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <div className="SentenceOrderingBox-container">
                                            <div 
                                                className={
                                                    checkAnswers
                                                        ? (
                                                            isCorrect
                                                                ? "SentenceOrderingBox-QuestionBox correct"
                                                                : "SentenceOrderingBox-QuestionBox wrong"
                                                        )
                                                        : "SentenceOrderingBox-QuestionBox"
                                                }
                                            >
                                                <TextBox text={answer.text} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Draggable>
                    )
                }
            )
        )
    };

    return (
        <div className="WordScrambleBox">
            { pinUnansweredRender() }
            {
                !isComplianceCourse && (
                    <div className="FillInTheBlankBox-QuestionBox-Position">
                        <TextBox text={currentQuestion.position} />
                    </div>
                )
            }

            <DragDropContext onDragEnd={onDragEnd}>
                {
                    animationEnabled &&
                    <Droppable droppableId={`draggable=${currentQuestion.id}`} direction="horizontal">
                        {
                            (provided) => (
                                <div className="WordScrambleBox-List__container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}>

                                    { handleQuestionRender() }

                                    { provided.placeholder}
                                </div>
                            )
                        }
                    </Droppable>
                }
            </DragDropContext>

            <TextBox
                text={punctuation[punctuation.length - 1]}
                tag="h5"
                style={{
                    color: COLORS.Blue,
                }}
            />
        </div>
    );
};

WordScrambleBox.propTypes = {
    section: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    lessonId: PropTypes.number.isRequired,
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    quizStates: PropTypes.shape({}).isRequired,
    currentQuestionIndex: PropTypes.number.isRequired,
    recordQuizState: PropTypes.func.isRequired,
    checkAnswers: PropTypes.bool.isRequired,
    revealAnswers: PropTypes.bool.isRequired,
    recordQuizAnswer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(WordScrambleBox);
