import { connect } from 'react-redux';

import { getUserCourses, clearUserCourses, updateUser, addSubscription } from '../../../Actions/ManageUserAccessActions';
import { fetchCourses } from '../../../Actions/CourseActions';
import { getLicenses } from '../../../Actions/LicenseActions';
import ManageUserAccess from './ManageUserAccess';

const mapStateToProps = (state) => ({
    userCourses: state.admin.userCourses,
    flash: state.flash.manageAccess,
    errors: state.errors.manageAccess,
    courses: state.entities.courses,
    licenses: state.admin.licenses,
});

const mapDispatchToProps = (dispatch) => ({
    getUserCourses: user => dispatch(getUserCourses(user)),
    clearUserCourses: () => dispatch(clearUserCourses()),
    fetchCourses: () => dispatch(fetchCourses()),
    getLicenses: () => dispatch(getLicenses()),
    updateUser: user => dispatch(updateUser(user)),
    addSubscription: subscription => dispatch(addSubscription(subscription)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserAccess);
