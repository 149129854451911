import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import LineChart from '../../../HighCharts/LineChart/LineChart';
import BarChart from '../../../HighCharts/BarChart/BarChart';
import { fetchUserParticipation } from '../../../../Actions/Stats/B2BAdmin/SpeakActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  speaks: (state.stats.b2bAdmin.speaks || {}),
  filters: (state.filters || {})
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserParticipation: (queryString) => dispatch(fetchUserParticipation(queryString))
});

const UserParticipation = ({ speaks, filters, contentWidth, fetchUserParticipation }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [usageGraphGroupBy, setUsageGraphGroupBy] = useState('hour');

  useEffect(() => {
    fetchUserParticipation(hashToQueryString({...filters, usageGraphGroupBy})).then(() => setLoading(false));
  }, [filters, usageGraphGroupBy]);

  useEffect(() => {
    if (!loading) {
      setShowChart(true);
    }
  }, [loading]);


  const topPhrases = (speaks.phrasesMetrics || {}).phrases || [];
  const phrasesSummary = speaks.participationSummary || {};

  const getPhrasesUsageChartData = () => {
    const usage = (speaks.usageMetrics || {}).data || [];

    return {
      'title': '',
      'subtitle': '',
      'xAxis': usage.map(sample => sample.aggregator),
      'yAxisTitle': '',
      'series': [{
        name: 'Usage Volume',
        data: usage.map(sample => sample.amount)
      }]
    };
  };

  const getSpecialtyByPopularityChartData = () => {
    const specialties = (speaks.playlistsMetrics || {}).specialtiesByPopularity || [];

    return {
      'xAxis': specialties.map(specialty => specialty.groupName),
      'yAxisTitle': 'NO. OF PLAYLISTS',
      'legendTitle': 'SPECIALTY',
      'series': [{
        'name': 'Displayed',
        'data': specialties.map(specialty => specialty.displayed)
      }, {
        'name': 'Learned',
        'data': specialties.map(specialty => specialty.learned)
      }]
    };
  };

  const getLanguagesByPopularityChartData = () => {
    const languages = (speaks.playlistsMetrics || {}).languagesByPopularity || [];

    return {
      'xAxis': languages.map(lang => lang.groupName),
      'yAxisTitle': 'NO. OF PHRASES',
      'legendTitle': 'SPECIALTY',
      'series': [{
        'name': 'Clinical Mode',
        'data': languages.map(lang => lang.displayed)
      }, {
        'name': 'Learning Mode',
        'data': languages.map(lang => lang.learned)
      }]
    };
  };

  const renderContent = () => {
    if (loading) return <Loader/>;

    return (
      <div className="User-Participation-Container">
        <div className="Content Dynamic-Width">
          <div className="Phrases">
            <div className="Arrow-Holder">
              <h3 className="Top-Text">NEW USERS</h3>
              <div className="Arrow">
                <ImageBox src="arrow.svg" className="Arrow-Icon"/>
                <h4 className="Inside-Text"><span>{phrasesSummary.newUsers}</span> users</h4>
              </div>
            </div>
            <div className="Arrow-Holder">
              <h3 className="Top-Text">PHRASES PLAYLISTS CREATED</h3>
              <div className="Arrow">
                <ImageBox src="arrow-2.svg" className="Arrow-Icon"/>
                <h4 className="Inside-Text"><span>{phrasesSummary.playlistsCreated}</span> playlists
                </h4>
              </div>
            </div>
            <div className="Arrow-Holder">
              <h3 className="Top-Text">PLAYLISTS LEARNED</h3>
              <div className="Arrow">
                <ImageBox src="arrow-2.svg" className="Arrow-Icon"/>
                <h4 className="Inside-Text">
                  <span>{phrasesSummary.playlistsUtilized}</span> playlists</h4>
              </div>
            </div>
            <div className="Arrow-Holder">
              <h3 className="Top-Text">PHRASES DISPLAYED</h3>
              <div className="Arrow">
                <ImageBox src="arrow-2.svg" className="Arrow-Icon"/>
                <h4 className="Inside-Text"><span>{phrasesSummary.playlistsDisplayed}</span> phrases
                </h4>
              </div>
            </div>

            <div className="Badge-Holder">
              <h3 className="Top-Text">Phrases learned</h3>
              <div className="Badge">
                <ImageBox src="combo.svg" className="Badge-Icon"/>
                <h4 className="Inside-Text"><span>{phrasesSummary.phrasesLearned}</span> phrases
                </h4>
              </div>
              <p>out of <span>{phrasesSummary.totalPhrases}</span> phrases</p>
            </div>

          </div>

        </div>

        <div className="User-Participation-Usage-Volume">
          <div className="Title-Bar">
            <h3>Usage Volume by Phrases Displayed</h3>
          </div>
          <div className="Content Dynamic-Width">
            <div id="Phrases-Usage-Chart"></div>
            {
              showChart &&
              <LineChart
                id="Phrases-Usage-Chart"
                data={getPhrasesUsageChartData()}
              />
            }
            <div className="Floating-Buttons">
              <div className={'Toggle-Button ' + (usageGraphGroupBy === 'hour' ? 'Enabled' : '')} onClick={() => setUsageGraphGroupBy('hour')}>Day</div>
              <div className={'Toggle-Button ' + (usageGraphGroupBy === 'dow' ? 'Enabled' : '')} onClick={() => setUsageGraphGroupBy('dow')}>Week</div>
              <div className={'Toggle-Button ' + (usageGraphGroupBy === 'day' ? 'Enabled' : '')} onClick={() => setUsageGraphGroupBy('day')}>Month</div>
            </div>
          </div>
        </div>

        <div className="Dynamic-Width Charts-Container">
          <div className="Content Chart-Card">
            <h3 className="Sub-Title">PLAYLIST SPECIALTY BY POPULARITY</h3>
            <div id="Specialty-Popularity-Chart"></div>
            {
              showChart &&
              <BarChart
                id="Specialty-Popularity-Chart"
                data={getSpecialtyByPopularityChartData()}
              />
            }
          </div>

          <div className="Content Chart-Card">
            <h3 className="Sub-Title">PHRASES SPECIALTY BY POPULARITY</h3>
            <div id="Language-Populairty-Chart"></div>
            {
              showChart &&
              <BarChart
                id="Language-Populairty-Chart"
                data={getLanguagesByPopularityChartData()}
              />
            }
          </div>
        </div>


        <div className="User-Participation-Top-Phrases Content Dynamic-Width">
          <h3 className="Sub-Title">TOP PHRASES</h3>
          <div className="Table-Container">
            <table className="Table">
              <thead>
                <tr>
                  <th>Phrase</th>
                  <th>Display Frequency</th>
                  <th>Learn Frequency</th>
                </tr>
              </thead>
              <tbody>
              {
                topPhrases.map((topPhrase, index) => (
                  <tr key={index}>
                    <td>
                          <span>
                            {topPhrase.groupName}
                          </span>
                    </td>
                    <td>
                          <span>
                            {topPhrase.displayed}
                          </span>
                    </td>
                    <td>
                          <span>
                            {topPhrase.learned}
                          </span>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="User-Participation">
      <h2 className="Title">User Participation</h2>
      {renderContent()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserParticipation);
