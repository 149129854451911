import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import { retake } from '../../../../Actions/CourseProgressActions';

import './Fail.scss';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  retake: (courseId) => dispatch(retake(courseId)),
});

const Fail = ({ course, courseProgress, isComplianceCourse, retake }) => {
  const history = useHistory();

  const retakeCourse = (e) => {
    e.preventDefault();

    retake(course.id).then(res => {
      history.push(`/learn/courses/${course.id}/lessons`);
      window.location.reload();
    });
  }

  return (
    <div className="Fail-Container">
      <div className="Primary-Info-Block">
        <div className="Percentage">
          {courseProgress.percentage}%
        </div>
        <div className="Description">
          You must score {course.pass_threshold}% or above in each quiz to pass the {course.name} course.<br/>
          {isComplianceCourse && `Retake the entire course and score ${course.pass_threshold}% to receive credit.`}
        </div>
      </div>

      <div className="Secondary-Info-Block">
        {
          isComplianceCourse ? (
            <ButtonBox
              className="Restart-Button"
              text={<span className="Text">Restart the course</span>}
              onClick={e => retakeCourse(e)}
            />
          ) : (
            <Link to={`/learn/courses/${course.id}/lessons`} className="Link">
              <ButtonBox
                className="Revisit-Button"
                text={<span className="Text">Back to Course</span>}
              />
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Fail);
