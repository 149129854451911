import React from 'react';
import PropTypes from 'prop-types';

import './SortingBoxMini.css';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';
import { COLORS } from '../../../Style/Style';

const SortingBoxMini = ({
    question,
    editQuestion,
    deleteQuestion,
    uploadAudio,
    creation,
}) => {
    const words = question.answers.map(
        answerData => (
            <div
                className="SortingBoxMini-ButtonBox-div"
                key={answerData.id}
            >
                <ButtonBox
                    className="ButtonBox-MultiButtonChoiceBoxMini"
                    text={answerData.text}
                />
            </div>
        ),
    );

    return (
        <div className="MultiButtonChoiceBoxMini">
            <div className="QuestionMini-QuestionBox">
                <div
                    className="QuestionMini-QuestionBox-Position"
                    style={{
                        backgroundColor: COLORS.green,
                    }}
                >
                    <TextBox
                        text={question.position}
                        style={{
                            color: COLORS.Blue,
                        }}
                    />
                </div>


                <div className="QuestionMini-QuestionBox-Question">
                    <QuestionBox question={question} creation={creation}>
                        <TextBox
                            text={question.text}
                            style={{
                                color: COLORS.Blue,
                            }}
                        />
                    </QuestionBox>
                </div>

                {
                    creation
                        && (
                            <FunctionBox
                                submitMedia={uploadAudio}
                                model="question"
                                entity={question}
                                editEntity={editQuestion}
                                deleteEntity={deleteQuestion}
                            />
                        )
                }
            </div>

            <div className="SortingBoxMini-ButtonBox-container">
                {words}
            </div>
        </div>
    );
};

SortingBoxMini.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        position: PropTypes.number,
        answers: PropTypes.array,
    }).isRequired,
    editQuestion: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    uploadAudio: PropTypes.func.isRequired,
    creation: PropTypes.bool,
};

SortingBoxMini.defaultProps = {
    creation: false,
};

export default SortingBoxMini;
