import { connect } from 'react-redux';

import { fetchCoursePackages, createCoursePackage } from '../../../../Actions/CoursePackageActions';
import AdminDashboardCoursePackages from './AdminDashboardCoursePackages';

const mapStateToProps = (state) => ({
    coursePackages: state.entities.coursePackages,
    courses: state.entities.courses,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCoursePackages: () => dispatch(fetchCoursePackages()),
    createCoursePackage: coursePackage => dispatch(createCoursePackage(coursePackage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardCoursePackages);
