export const payablePrice = (coursePackage, couponData) => {
  if (couponData.coupon?.percent_off) {
    return (coursePackage.price * ((100 - couponData.coupon.percent_off) / 100)) / 100
  } else if (couponData.coupon?.amount_off) {
    return (coursePackage.price - couponData.coupon.amount_off) / 100
  } else {
    return ''
  }
};

export const discountedPrice = (coursePackage, couponData) => {
  if (couponData.coupon?.percent_off) {
    return (coursePackage.price * (couponData.coupon.percent_off / 100)) / 100
  } else if (couponData.coupon?.amount_off) {
    return couponData.coupon.amount_off / 100
  } else {
    return ''
  }
};

export const appliedDiscount = (couponData) => {
  if (couponData.coupon?.percent_off) {
    return `${couponData.coupon.percent_off}% discount`
  } else if (couponData.coupon?.amount_off) {
    return `$${couponData.coupon.amount_off / 100} discount`
  } else {
    return ''
  }
};

export const isValidZipCode = (zipCode) => (
  /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode)
);
