import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import './LearnNavBar.css';
import ImageBox  from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import UserDropdown from '../UserDropdown/UserDropdown';
import NavigationBox from '../NavigationBox/NavigationBox';
import NotificationListContainer from '../../Pages/UserNotifications/NotificationList/NotificationListContainer';

import { finalizePendingActionsAndLogout } from '../../../Util/Helper/UserWorkplaceUtil';
import { isBusinessLicense } from '../../../Util/Helper/LicenseUtil';
import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

const LearnNavBar = (props) => {
  const { logout } = useAuth0();
  const history = useHistory();
  const course = props.currentCourseId ? props.courses[props.currentCourseId] : null;
  const courseProgress = props.currentCourseId ? props.courseProgresses[props.currentCourseId] : null;
  const courseSettings = course?.course_type?.settings || {};
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [navigationOpen, setNavigationOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setNavigationOpen(false)
    const mobileMenu = document.querySelectorAll(".NavBar-Items_right");
    mobileMenu.forEach( (nav) => {
      nav.classList.toggle("NavBar-Items_visible_mobile");
    });
  };

  return(
    <div>
      <div
        className={isMobile && navigationOpen ? "NavBar NavBar_position_fixed" : "NavBar" }
        style={courseSettings.header_background ? { backgroundColor: courseSettings.header_background } : {}}
      >
        <div className="NavBar-Items_left">
          {
            course && isMobile &&
            <div className="NavBar-Button_visible_mobile">
              <ButtonBox
                className="ButtonBox-Accordion"
                onClick={() => setNavigationOpen(!navigationOpen)}
                text={<i className="far fa-file-alt"></i>}
              />
            </div>
          }

          <div className="NavBar-Logo-Container">
            <ImageBox
              className="NavBar-Logo"
              src="canopy.png"
            />
            {
              courseSettings.logo_text &&
              <span
                className={`NavBar-SubLogo ${courseSettings.logo_color_type}`}
                style={
                  courseSettings.logo_color_type === 'simple'
                  ? { color: courseSettings.logo_color }
                  : { backgroundImage: courseSettings.logo_color }
                }
              >
                {courseSettings.logo_text}
              </span>
            }
          </div>

          <div className="NavBar-Button_visible_mobile">
            <ButtonBox
              className="ButtonBox-Accordion"
              src="accordion.png"
              onClick={ handleToggleMobileMenu }
            />
          </div>
        </div>

        <div className="NavBar-Items_right">
          {!isTEAdmin(props.currentUser) && <NotificationListContainer  />}
          <a href="https://info.withcanopy.com/en/knowledge/canopylearn" target="_blank" rel="noopener noreferrer">
            <ButtonBox
              className="NavBar-Button"
              text="FAQ"
              tag="h5"
            />
          </a>

          {
            props.currentUser.user_type !== "TE.Admin" &&
            <a
              href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', marginRight: 5, marginLeft: 5 }}
            >
              <ButtonBox
                className="NavBar-Button"
                text="Support"
                tag="h5"
              />
            </a>
          }

          {
            isMobile ?
            <Fragment>
              {
                (!['TE.Admin', 'B2B.Admin'].includes(props.currentUser.user_type)) && (!isBusinessLicense(props.currentWorkplace?.license_type)) &&
                <Link to="/account/learn/course_packages">
                  <ButtonBox
                    className="NavBar-Button"
                    text="Enroll"
                  />
                </Link>
              }

              {
                (['B2C.User', 'B2B.User'].includes(props.currentUser.user_type)) &&
                <>
                  <Link to="/account/profile">
                    <ButtonBox
                      className="NavBar-Button"
                      text="My Profile"
                    />
                  </Link>

                  <Link to="/account/analyze">
                    <ButtonBox
                      className="NavBar-Button"
                      text="My Progress"
                    />
                  </Link>

                  <Link to="/account/certificate">
                    <ButtonBox
                      className="NavBar-Button"
                      text="My Certificates"
                    />
                  </Link>
                </>
              }

              {
                (props.currentUser.user_type === 'B2B.Admin') &&
                <>
                  <Link to="/account/search">
                    <ButtonBox
                      className="NavBar-Button"
                      text="Add/Search Users"
                    />
                  </Link>

                  <Link to="/account/progress">
                    <ButtonBox
                      className="NavBar-Button"
                      text="Generate Reports"
                    />
                  </Link>

                  <Link to="/account/groups">
                    <ButtonBox
                      className="NavBar-Button"
                      text="Manage Groups"
                    />
                  </Link>

                  <Link to="/account/profile">
                    <ButtonBox
                      className="NavBar-Button"
                      text="My Account"
                    />
                  </Link>
                </>
              }

              {
                props.currentUser.user_type === "TE.Admin" &&
                <>
                  <Link to="/creation">
                    <ButtonBox
                      className="NavBar-Button"
                      text="Create"
                    />
                  </Link>

                  <Link to="/learn">
                    <ButtonBox
                      className="NavBar-Button"
                      text="Preview"
                    />
                  </Link>
                </>
              }

              <ButtonBox
                className="NavBar-Button"
                text="Log Out"
                onClick={() => finalizePendingActionsAndLogout(history, logout)}
              />
            </Fragment>
            :
            <UserDropdown />
          }
        </div>
      </div>

      <div className="SideBar">
        <div className="SideBar-Open">
          {
            navigationOpen &&
            <NavigationBox
              course={course}
              courseProgress={courseProgress}
              lessons={props.lessons}
              setNavigationOpen={setNavigationOpen}
              pathname={props.location.pathname}
            />
          }
        </div>

        <div
          className="SideBar-Close"
          onClick={() => setNavigationOpen(true) }
          data-tip={"Click to open the \"Navigation Panel\""}
        >
          {
            course && !isMobile &&
            <button
              className="NavBar-Button SideBar-Opener"
            >
              <ImageBox className="ImageBox-Accordion" src="sidebar-open.svg" />
              <div className="SideBar-Tooltip">
                <ReactTooltip place="right" type="info" className="Tooltip" />
              </div>
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default LearnNavBar;
