import { CLEAR_MEDIA_TYPES, RECEIVE_MEDIA_TYPES } from '../../Actions/MediaTypeActions';

const MediaTypesReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_MEDIA_TYPES:
      return Object.assign({}, state, action.payload.mediaTypes);
    case CLEAR_MEDIA_TYPES:
      return {}
    default:
      return state;
  }
};

export default MediaTypesReducer;
