import {
    CREATE_LICENSE_ERROR,
    CREATE_LICENSE_SUCCESS,
} from '../../Actions/LicenseActions';

const defaultState = {
    license_type: null,
    access_code: null,
    num_seats: null,
    expiration_date: null,
    subscription_date: null,
    institution_id: null,
};

const license = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_LICENSE_ERROR:
            return { ...state, ...action.errors };
        case CREATE_LICENSE_SUCCESS:
            return defaultState;
        default:
            return state;
    }
};

export default license;
