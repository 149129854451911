import React, { useState } from 'react';
 
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

const CreateEditMultipleAnswer = ({ section, question, submitQuestion, closeModal }) => {
  const [questionAttrs, setQuestionAttrs] = useState(
    question
    ? { text: question.text, hint: question.hint, options_attributes: question.options }
    : { text: '', hint: '', options_attributes: [{ text: '', audio_url: '', index: 0 }] }
  );

  const handleInputChange = (e, field) => {
    setQuestionAttrs({ ...questionAttrs, [field]: e.currentTarget.value })
  };

  const handleOptionInput = (e, idx) => {
    questionAttrs.options_attributes[idx].text = e.currentTarget.value;
    setQuestionAttrs({ ...questionAttrs });
  };

  const handleAddOption = (e) => {
    e.preventDefault();
    questionAttrs.options_attributes.push({ text: '', audio_url: '', index: 0 }) ;
    setQuestionAttrs({ ...questionAttrs });
  };

  const handleDeleteOption = (e, idx) => {
    e.preventDefault();
    questionAttrs.options_attributes[idx]._destroy = true;
    setQuestionAttrs({ ...questionAttrs });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let questionObject = Object.assign({}, questionAttrs);

    if (section) {
      questionObject.section_id = section.id;
      questionObject.question_type = section.section_type;
    }

    if (question) {
      questionObject.id = question.id;
    }

    submitQuestion(questionObject).then(
      closeModal()
    )
  };

  const handleOptionRender = () => {
    return questionAttrs.options_attributes.map((option, idx) => {
      if (option._destroy) {
        return null;
      }

      return (
        <div
          className="CreationForms-InputBox"
          key={idx}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <InputBox
            className="InputBox-LogInBox"
            value={option.text}
            placeholder={`Option ${idx + 1}`}
            onChange={(e) => handleOptionInput(e, idx)}
          />

          <ButtonBox
            className="ButtonBox-EditQuestions"
            text={<i className="far fa-trash-alt"></i>}
            onClick={(e) => handleDeleteOption(e, idx)}
          />
        </div>
      )}
    )
  };

  return (
    <form
      className="CreationForms-Form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="CreationForms-InputBox">
        <textarea
          className="TextAreaBox-CreationForms"
          value={questionAttrs.text}
          placeholder="Text Here"
          onChange={(e) => handleInputChange(e, 'text')}
        />
      </div>

      <div className="CreationForms-OptionsBox">
        {handleOptionRender()}

        <div className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Add an Option"
            onClick={(e) => handleAddOption(e)}
          />
        </div>
      </div>

      <div className="CreationForms-InputBox">
        <InputBox
          className="InputBox-LogInBox"
          value={questionAttrs.hint}
          placeholder="Hint (Optional)"
          onChange={(e) => handleInputChange(e, 'hint')}
        />
      </div>

      <div className="CreationForms-Button">
        <ButtonBox
          className="CreationDashboards-ButtonBox"
          text="Submit"
        />
      </div>
    </form>
  )
}

export default CreateEditMultipleAnswer;
