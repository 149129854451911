import { courseSeriesList } from '../../Constants/CourseConstants';

// start lesson link
export const initiateLesson = (course, lesson) => {
  let link = `/learn/courses/${course.id}/lessons/${lesson.id}`;

  if (lesson.activity_id)
    return `${link}/activity`;

  if (lesson.topics.length > 0)
    return `${link}/topics/${lesson.topics[0]}`;

  if (lesson.quiz_id)
    return `${link}/quiz`;

  return link;
}

// continue lesson link
export const continueLessonLink = (course, lesson, activityId, topicId, quizId, lessons) => {
  let link = `/learn/courses/${course.id}/lessons`;

  if (activityId) {
    if (lesson.topics.length > 0) {
      return `${link}/${lesson.id}/topics/${lesson.topics[0]}`;
    } else if (lesson.quiz_id) {
      return `${link}/${lesson.id}/quiz`;
    }
  } else if (topicId) {
    let nextTopicId = lesson.topics[lesson.topics.indexOf(parseInt(topicId, 10)) + 1];

    if (nextTopicId) {
      return `${link}/${lesson.id}/topics/${nextTopicId}`;
    } else if (lesson.quiz_id) {
      return `${link}/${lesson.id}/quiz`;
    }
  }

  let lessonIds    = course.lessons;
  let nextLessonId = lessonIds[lessonIds.indexOf(parseInt(lesson.id, 10)) + 1];
  let nextLesson   = lessons[nextLessonId];

  if (nextLesson) {
    return initiateLesson(course, nextLesson);
  }

  return `/learn/courses/${course.id}/completion`;
}

// sort courses by series
export const sortCourseIdsBySeries = courses => {
  const orderedSeries = Object.keys(courseSeriesList);

  return Object.values(courses).sort((courseA, courseB) => {
    const courseAIndex = orderedSeries.indexOf(courseA.series);
    const courseBIndex = orderedSeries.indexOf(courseB.series);

    if (courseAIndex === courseBIndex) {
      return courseA.level - courseB.level;
    }

    return courseAIndex - courseBIndex;
  }).map(course => course.id);
}
