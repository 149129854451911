import React from 'react';

import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';

import { COLORS} from '../../../Style/Style';
  
const MultiButtonAnswerBoxMini = (props) => {  
  const {
    course, question, editQuestion, deleteQuestion, createAnswer, editAnswer, deleteAnswer, uploadAudio, creation, quizProgress, section
  } = props;
  const answerTexts = question.answers.map(a => a.text);

  const handleChoiceSelect = (e) => {
    if (creation) {
      let selectedOption = question.options[e.currentTarget.dataset.id];

      if (answerTexts.includes(selectedOption.text)) {
        deleteAnswer(question.answers.find(a => a.text === selectedOption.text).id);
      } else {
        createAnswer({
          question_id: question.id,
          text: selectedOption.text
        })
      }
    }
  }

  const buttons = question.options.map((option, idx) => {
    return (
      <div className="MultiButtonChoiceBoxMini-ButtonBox-div" key={option.id}>
        {option.audio_url && <AudioPlayButton src={option.audio_url} downloadable={creation} />}
        <ButtonBox
          className={
            creation ?
            (
              answerTexts.includes(option.text) ?
              "ButtonBox-MultiButtonChoiceBoxMini-correct"
              :
              "ButtonBox-MultiButtonChoiceBoxMini"
            )
            :
            (
              quizProgress[section.id]?.[question.id]?.[0]?.id === option.id ?
              (
                quizProgress[section.id][question.id][0].is_correct ?
                "ButtonBox-MultiButtonChoiceBoxMini-correct"
                :
                "ButtonBox-MultiButtonChoiceBoxMini-wrong" 
              )
              :
              "ButtonBox-MultiButtonChoiceBoxMini"
            )
          }
          text={option.text}
          id={idx}
          onClick={handleChoiceSelect}/>
      </div>
    )
  });

  return (
    <div className="MultiButtonChoiceBoxMini">
      <div className="QuestionMini-QuestionBox">
        <div className="QuestionMini-QuestionBox-Position"
          style={
            creation
            ? (answerTexts.length > 0 ? { backgroundColor: COLORS.green } : { backgroundColor: COLORS.redPink })
            : { backgroundColor: COLORS.lightBlue2 }
          }
        >
          <TextBox
            text={question.position}
            style={{color: COLORS.Blue}}
          />
        </div>

        <div className="QuestionMini-QuestionBox-Question">
          <QuestionBox question={question} creation={creation}>
            <TextBox
              text={question.text}
              style={{ color: COLORS.Blue }}
            />
          </QuestionBox>
        </div>

        {
          props.creation &&
          <FunctionBox
            submitMedia={uploadAudio}
            model="question"
            entity={question}
            editEntity={editQuestion}
            deleteEntity={deleteQuestion}
            course={course}
          />
        }
      </div>

      <div className="MultiButtonChoiceBoxMini-ButtonBox-container">
        {buttons}
      </div>
    </div>
  );
};

export default MultiButtonAnswerBoxMini;
