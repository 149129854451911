import React, { useState, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Filters from '../Filters/Filters';
import Pagination from '../../../Composed/Pagination/Pagination';
import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../../Basic/InputBox/InputBox';
import CheckBox from '../../../Basic/CheckBox/CheckBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import CredentialScoreCardContainer from '../../../Pages/CredentialScoreCard/CredentialScoreCardContainer';

import { clearRosterManagement, fetchRosterManagement } from '../../../../Actions/RosterManagementActions';
import { hashToQueryString, humanizeDate } from '../../../../Util/Helper/GenericUtil';
import { examStatusesList, qbsThresholdCodes } from '../../../../Constants/CredentialConstants';

import './UserRosterManagement.scss';

const mapStateToProps = (state) => ({
  rosterManagements: state.admin.rosterManagement || {},
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearRosterManagement: () => dispatch(clearRosterManagement()),
  fetchRosterManagement: (queryString) => dispatch(fetchRosterManagement(queryString))
});

const CredentialRosterManagement = ({ rosterManagements, filters, clearRosterManagement, fetchRosterManagement }) => {
  const productType = 'Credential';

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [contentWidth, setContentWidth] = useState(0);
  const [bulkActionDrop, setBulkActionDrop] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [triggerSearch, setTriggerSearch] = useState('');
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(10);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useLayoutEffect (() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 500;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    setLoading(true);
    clearRosterManagement();
    fetchRosterManagement(buildQueryString()).then(
      res => {
        setLoading(false);
        setSelectedUsers([]);
      }
    );
  }, [fetchRosterManagement, filters, triggerSearch, page, per]);

  const buildQueryString = () => {
    let queryString = '';

    queryString += hashToQueryString(filters);
    queryString += `&page=${page}&per=${per}`;
    queryString += `&search_filter=${searchFilter}`;
    queryString += `&product_type=${productType}`;

    return queryString;
  }

  const handleUserSelection = (user) => {
    let newSelectedUsers = JSON.parse(JSON.stringify(selectedUsers));
    let idx = newSelectedUsers.indexOf(user.id);

    (idx > -1) ? newSelectedUsers.splice(idx, 1) : newSelectedUsers.push(user.id);

    setSelectedUsers(newSelectedUsers);
  }

  const goToUserProfile = (userId) => {
    history.push(`/account/search/${userId}`)
  }

  const qbsAchieved = (listening, speaking) => (
    qbsThresholdCodes.includes(listening) && qbsThresholdCodes.includes(speaking)
  )

  const renderCredentialTestStep = user => {
    if (!user.credentialTests) {
      return <li>N/A</li>
    }

    return Object.values(user.credentialTests || {}).filter(credentialTest => !credentialTest.is_waived).map(credentialTest => {
      let credentialTestStep;

      if (!credentialTest.preAsssessmentScore) {
        credentialTestStep = 'Waiting For Self Assessment';
      } else if (qbsAchieved(credentialTest.sapioTest?.listening, credentialTest.sapioTest?.speaking)) {
        credentialTestStep = 'Achieved QBS';
      } else {
        credentialTestStep = Object.entries(examStatusesList).find(([k, v]) => Object.entries(v).find(([kk, vv]) => kk == credentialTest.sapioTest?.status))?.[1]?.[credentialTest.sapioTest?.status];
      }

      return (
        <li>
          {credentialTestStep}
          {
            credentialTest.sapioTest?.status === 'rated' &&
            <CredentialScoreCardContainer ofUser={user} ofCredentialTestId={credentialTest.id} />
          }
        </li>
      )
    });
  }

  const renderData = () => {
    let emptyResult = rosterManagements.totalUsers == 0

    return(
      <>
        <div className="Actions-Container">
          <div className="Actions">
            <div className="Bulk-Action-And-Per-Page">
              <div className="Per-Page-Drop-Down">
                <select
                    className="Records-Per-Page"
                    value={per}
                    onChange={(e) => setPer(e.currentTarget.value)}
                  >
                    <option value="10">Show 10 users</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
              </div>
            </div>
            <div className="SearchBar">
              <InputBox
                className="Input"
                value={searchFilter}
                placeholder="First Name, Last Name, or Email"
                onChange={e => setSearchFilter(e.target.value)}
                onBlur={e => setTriggerSearch(searchFilter)}
                onKeyPress={e => (e.key === 'Enter') && e.currentTarget.blur()}
              />
            </div>
          </div>
          <div className="Invite-Users">
            <Link to="/account/invite_users/new">
              <ButtonBox
                className="Static-Primary-Btn"
                text="Invite new users"
              />
            </Link>
          </div>
        </div>

        <div
          className="Content Scrollable-Table"
          style={{
            width: `${contentWidth}px`
          }}
        >
          {
            emptyResult ? renderEmpty() : (
              <table className="Table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Last Name ▾</th>
                    <th>First Name ▾</th>
                    <th>Email ▾</th>
                    <th>Test Date ▾</th>
                    <th>Step in Process ▾</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.values(rosterManagements.users || {}).map((user, uIdx) => (
                      <tr key={`user-${uIdx}`}>
                        <td>
                          <div className="CheckBox-Container">
                            <CheckBox
                              checked={selectedUsers.includes(user.id)}
                              onClick={(e) => handleUserSelection(user)}
                            />
                          </div>
                        </td>
                        <td><span className="Name">{user.last_name}</span></td>
                        <td><span className="Name">{user.first_name}</span></td>
                        <td>{user.email}</td>
                        <td>
                          <ul className="list-disc pl-10">
                            {
                              !user.credentialTests ? <li>N/A</li> : (
                                !Object.values(user.credentialTests || {}).find(ct => ct.sapioTest?.start_time) ? <li>N/A</li> : (
                                  Object.values(user.credentialTests || {}).map((credentialTest) => (
                                    credentialTest.sapioTest?.start_time && <li>{humanizeDate(new Date(credentialTest.sapioTest?.start_time))}</li>
                                  ))
                                )
                              )
                            }
                          </ul>
                        </td>
                        <td>
                          <ul className="list-disc pl-10">
                            {renderCredentialTestStep(user)}
                          </ul>
                        </td>
                        <td className="DropDown-Td" >
                          <select
                            className="Select-Action"
                            onChange={e => {
                              if (e.currentTarget.value == 'profile') {
                                goToUserProfile(user.id)
                              }
                            }}
                          >
                            <option>Action</option>
                            <option value="profile">View Profile</option>
                          </select>
                          <i className="fa fa-caret-down Drop-Down-Arrow-Icon"></i>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            )
          }
        </div>
        {
          !emptyResult &&
          <Pagination
            totalRecords={rosterManagements.totalUsers}
            page={page}
            setPage={(p) => setPage(p)}
            per={per}
          />
        }
      </>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      No users!
    </div>
  )

  const renderContent = () => {
    if (loading)
      return <Loader />

    return renderData();
  }

  return (
    <div className="B2B-Admin-Roster-Management-Container">
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialRosterManagement);
