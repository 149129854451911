import axios from './AxiosUtil';

export const createTable = (table) => {
  return axios.post(`/api/tables`, { table }).then(
    res => res.data
  );
};

export const editTable = (table) => {
  return axios.patch(`/api/tables/${table.id}`, { table }).then(
    res => res.data
  );
};

export const deleteTable = tableId => {
  return axios.delete(`/api/tables/${tableId}`).then(
    res => res.data
  );
}