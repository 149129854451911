import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import DonutChart from '../../../HighCharts/DonutChart/DonutChart';

import { clearCredentialStats, fetchUserPerformance } from '../../../../Actions/Stats/B2BAdmin/CredentialActions';
import { hashToQueryString, numberInRange, percentage } from '../../../../Util/Helper/GenericUtil';
import { proficiencyCodeToRange, applicableProficiencyCodes } from '../../../../Constants/CredentialConstants';

const mapStateToProps = (state) => ({
  userPerformance: state.stats.b2bAdmin.credentials.userPerformance,
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearCredentialStats: (key) => dispatch(clearCredentialStats(key)),
  fetchUserPerformance: (queryString) => dispatch(fetchUserPerformance(queryString))
});

const UserPerformance = ({ userPerformance, filters, contentWidth, clearCredentialStats, fetchUserPerformance }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShowChart(false);
    clearCredentialStats('userPerformance');
    fetchUserPerformance(hashToQueryString(filters)).then(res => setLoading(false));
  }, [fetchUserPerformance, filters]);

  useEffect(() => {
    if (!loading)
      setShowChart(true);
  }, [loading]);

  const renderRatedTable = () => {
    let totalCredentialTests = Object.keys(userPerformance.credentialTests || {}).length;
    let tableData = {
      'proficiency': { 'Spanish Listening': {}, 'Spanish Speaking': {}, 'English Listening': {}, 'English Speaking': {} },
      'competence':  { 'Grade 1': 0, 'Grade 2': 0, 'Grade 3': 0, 'Grade 4': 0 }
    }
    Object.keys(tableData.proficiency).map(mode => (
      applicableProficiencyCodes.map(i => tableData['proficiency'][mode][i] = 0)
    ));

    Object.values(userPerformance.credentialTests || {}).map(credentialTest => {
      if (Object.keys(tableData['competence']).includes(credentialTest.sapioTest.sccc)) {
        tableData['competence'][credentialTest.sapioTest.sccc] += 1;
      }

      if (credentialTest.exam.language.name == 'Spanish') {
        tableData['proficiency']['Spanish Listening'][credentialTest.sapioTest.listening] += 1;
        tableData['proficiency']['Spanish Speaking'][credentialTest.sapioTest.speaking] += 1;
      } else if (credentialTest.exam.language.name == 'English') {
        tableData['proficiency']['English Listening'][credentialTest.sapioTest.listening] += 1;
        tableData['proficiency']['English Speaking'][credentialTest.sapioTest.speaking] += 1;
      }
    });

    return (
      <div className="Score-Container">
        <h4 className="Heading">Proficiency Level Scoring</h4>

        <div className="Table-Container">
          <table className="Table">
            <thead>
              <tr>
                <th></th>
                <th className="Yellow-Line-Out-Arrow">Basic Level 2</th>
                <th className="Yellow-In-Out-Arrow">Emergent Level 1</th>
                <th className="Yellow-In-Out-Arrow">Emergent Level 2</th>
                <th className="Yellow-In-Out-Arrow">Conversational Level 1</th>
                <th className="Green-In-Out-Arrow">
                  <div className="ToolTip-Holder">
                    <div className="ToolTip">QBS Threshold</div>
                    <ImageBox className="ToolTip-Img" src="tooltip-head.png" />
                  </div>
                  Conversational Level 2
                </th>
                <th className="Green-In-Line-Arrow">Advanced Level 1</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.entries(tableData.proficiency).map(([mode, stats], idx) => (
                  <tr key={`${mode}-${idx}`} >
                    <td>{mode}</td>
                    {
                      applicableProficiencyCodes.map(code => (
                        <td>
                          <div className="Percentage">{`${percentage(stats[code], totalCredentialTests)} %`}</div>
                          <div className="Total">{`${stats[code]} / ${totalCredentialTests}`}</div>
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <div className="Table-Info">
          * Basic Level 1 and Advanced Level 2 are not covered by the current version of the assessment
        </div>

        <h4 className="Heading">Competency Level Scoring</h4>

        <div className="Table-Container">
          <table className="Table">
            <thead>
              <tr>
                <th></th>
                <th className="Yellow-Line-Out-Arrow">Not Yet Competent</th>
                <th className="Yellow-In-Out-Arrow">Somewhat Competent</th>
                <th className="Green-In-Out-Arrow">Reasonably Competent</th>
                <th className="Green-In-Line-Arrow">Highly Competent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Impartiality / Effectiveness</td>
                {
                  Object.values(tableData.competence).map(scccValue => (
                    <td>
                      <div className="Percentage">{`${percentage(scccValue, totalCredentialTests)} %`}</div>
                      <div className="Total">{`${scccValue} / ${totalCredentialTests}`}</div>
                    </td>
                  ))
                }
              </tr>
            </tbody>
          </table>
        </div>

        <div className="Credential-Scores-Explanation">
          <ImageBox className="Image" src="credential-scores-explanation.webp" />
        </div>
      </div>
    )
  }

  const renderFalseFluencyChart = () => {
    const donutChartSeriesData = {
      'underestimated':     { listening: 0, speaking: 0 },
      'correctlyEstimated': { listening: 0, speaking: 0 },
      'overestimated':      { listening: 0, speaking: 0 }
    };

    Object.values(userPerformance.credentialTests || {}).map(credentialTest => {
      if (!credentialTest.preAsssessmentScore || !credentialTest.sapioTest)
        return null;

      let scoringScheme = credentialTest.exam.language.is_qbs_target ? 'target' : 'base';

      ['Listening', 'Speaking'].map(mode => {
       let range = proficiencyCodeToRange[`${scoringScheme}${mode}`][credentialTest['sapioTest'][mode.toLowerCase()]]

        if (range?.max && range?.min) {
          switch (numberInRange(credentialTest['preAsssessmentScore'][mode.toLowerCase()], range.min, range.max)) {
            case 0:
              return donutChartSeriesData['correctlyEstimated'][mode.toLowerCase()] += 1;
            case 1:
              return donutChartSeriesData['overestimated'][mode.toLowerCase()] += 1;
            case -1:
              return donutChartSeriesData['underestimated'][mode.toLowerCase()] += 1;
          }
        }
      })
    });

    const listeningChartData = {
      title: '',
      yAxisTitle: '',
      tooltip:  `<p class="heading">{{POINT_KEY}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_PERCENTAGE}}%</span>` +
                `</div>` ,
      series: [
        { name: 'Correctly Estimated', color: '#00a6ed', y: donutChartSeriesData.correctlyEstimated.listening},
        { name: 'Overestimated', color: '#f6511d', y: donutChartSeriesData.overestimated.listening},
        { name: 'Underestimated', color: '#546e7a', y: donutChartSeriesData.underestimated.listening}
      ]
    }

    const speakingChartData = {
      title: '',
      yAxisTitle: '',
      tooltip: `<p class="heading">{{POINT_KEY}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_PERCENTAGE}}%</span>` +
                `</div>` ,
      series: [
        { name: 'Correctly Estimated', color: '#00a6ed', y: donutChartSeriesData.correctlyEstimated.speaking},
        { name: 'Overestimated', color: '#f6511d', y: donutChartSeriesData.overestimated.speaking},
        { name: 'Underestimated', color: '#546e7a', y: donutChartSeriesData.underestimated.speaking}
      ]
    }

    return (
      <div className="Fluency-Container User-Performance-Chart-Container">
        <h4 className="Heading">False Fluency</h4>
        <h5 className="SubHeading">Listening</h5>
        <div
          className={`User-Performance-Listening-Chart DonutChart`}
          id={`User-Performance-Listening-Donut-Chart`}
        >
          {
            showChart &&
            <DonutChart
              id={`User-Performance-Listening-Donut-Chart`}
              data={listeningChartData}
            />
          }
        </div>

        <div className="Graph-Data-Holder">
          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Underestimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Underestimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.underestimated.listening} users`}</div>
            </div>
          </div>

          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Correctly-Estimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Correctly Estimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.correctlyEstimated.listening} users`}</div>
            </div>
          </div>

          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Overestimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Overestimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.overestimated.listening} users`}</div>
            </div>
          </div>
        </div>

        <h5 className="SubHeading">Speaking</h5>
        <div
          className={`User-Performance-Speaking-Chart DonutChart`}
          id={`User-Performance-Speaking-Donut-Chart`}
        >
          {
            showChart &&
            <DonutChart
              id={`User-Performance-Speaking-Donut-Chart`}
              data={speakingChartData}
            />
          }
        </div>

        <div className="Graph-Data-Holder">
          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Underestimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Underestimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.underestimated.speaking} users`}</div>
            </div>
          </div>

          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Correctly-Estimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Correctly Estimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.correctlyEstimated.speaking} users`}</div>
            </div>
          </div>

          <div className="Graph-Data">
            <div className="Legend-Holder">
              <div className="Overestimated-Legend"></div>
            </div>
            <div className="Text-Holder">
              <div className="Text">Overestimated on Self-Assessment</div>
              <div className="User-Count">{`${donutChartSeriesData.overestimated.speaking} users`}</div>
            </div>
          </div>
        </div>

        <div className="Bottom-Text">
          *False Fluency is defined as the delta by which a test-taker's self-assessment on the pre-test questionnaire exceeds his/her actual performance on the proficiency test.
        </div>
      </div>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      The users have not made any progress yet!
    </div>
  );

  const renderContent = () => {
    if (loading)
      return <Loader />

    if (Object.keys(userPerformance.credentialTests || {}).length == 0)
      return renderEmpty();

    return (
      <div className="Table-And-Chart-Container"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {renderRatedTable()}
        {renderFalseFluencyChart()}
      </div>
    )
  }

  return (
    <div className="Credential-User-Performance">
      <h2 className="Title">User Performance</h2>

      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPerformance);
