import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DropTarget } from 'react-dnd';

const dropTarget = {
  drop(props, monitor, component) {
    const answerData = monitor.getItem();
    answerData.destination = props.destination;
    return answerData;
  }
};

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    answerData: monitor.getItem(),
  };
};

class DropArea extends Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    const style = isOver ? {
      backgroundColor: '#a1d4e1',
      border: '1px solid #a1d4e1'
    } : {};
    return connectDropTarget(
      <div
        className={ this.props.className }
        style={ style }>
        {this.props.children}
      </div>
    );
  }
}

DropArea.propTypes = {
  dropID: PropTypes.number,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired
};

export default DropTarget('answer', dropTarget, collect)(DropArea);
