import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import './TrialPage.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

const TrialPage = ({
    currentUser,
    subscribedCourses,
    updateAccount,
}) => {
    const [trial, setTrial] = useState(false);

    useEffect(() => {
        updateAccount({
            id: currentUser.id,
            trial: true,
            trial_activation: new Date(),
        }).then(() => {
            window.fbq('track', 'StartTrial');
            setTrial(true);
        });
    }, [updateAccount]);

    if (Object.keys(subscribedCourses).length) {
        return <Redirect to="/account/learn/course_packages" />;
    }

    if (trial || currentUser.trial) {
        return (
            <div className="UserDashboardPurchasePackage__success">
                <div className="UserDashboardPurchasePackage__container">
                    <i className="fas fa-check icon-purchase-success" />

                    <TextBox
                        text="Free Trial Active!"
                        tag="h1"
                        style={{
                            color: '#6bd1be',
                            margin: '20px 0',
                        }}
                    />

                    <TextBox
                        text="You are all set. You now have access to Canopy Learn."
                        tag="h5"
                        style={{
                            color: COLORS.Blue,
                        }}
                    />

                    <TextBox
                        text="Lessons 1, 2 and 3 are available. Upgrade to the full version at any time."
                        tag="h5"
                        style={{
                            color: COLORS.Blue,
                        }}
                    />

                    <TextBox
                        text="Please click below to start your course."
                        tag="h5"
                        style={{
                            color: COLORS.Blue,
                        }}
                    />

                    <Link to="/learn">
                        <div style={{ width: '434px', marginTop: '30px' }}>
                            <ButtonBox
                                className="UserDashboardPurchasePackage-Button__submit"
                                text="Go to Courses"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div />
    );
}

TrialPage.propTypes = {
    currentUser: PropTypes.shape({
        id: PropTypes.number,
        trial: PropTypes.bool,
    }).isRequired,
    updateAccount: PropTypes.func.isRequired,
    subscribedCourses: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default TrialPage;
