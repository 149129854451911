import { connect } from 'react-redux';

import AdminDashboardInstitutions from './AdminDashboardInstitutions';
import { fetchInstitutions } from '../../../../Actions/InstitutionActions';


const mapStateToProps = (state) => ({
    institutions: state.admin.institutions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInstitutions: () => dispatch(fetchInstitutions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardInstitutions);
