import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import RadioBox from '../../Basic/RadioBox/RadioBox';
import TextAreaBox from '../../Basic/TextAreaBox/TextAreaBox';
import Pill from '../../Basic/Pill/Pill';
import DashboardFormBoxNoEdit from '../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import TutorialContainer from '../../Presentational/Tutorial/TutorialContainer';

import { validFileTypesList } from '../../../Constants/InviteUserConstants';
import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './InviteUsers.scss';

const InviteUsers = ({
  currentUser,
  institutions,
  errors, flash,
  clearInstitutions, fetchInstitutions, fetchInstitution,
  clearInviteUsersErrors, createInviteUsers, inviteUsersFormValidatonErrors, receiveInviteUsersConfirmation
}) => {
  const [tab, setTab] = useState('Excel');
  const [file, setFile] = useState(null);
  const [emailsBuffer, setEmailsBuffer] = useState('');
  const [emails, setEmails] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [code, setCode] = useState('');
  const [inviting, setInviting] = useState(false);

  const fileRef = useRef(null);

  useEffect(() => {
    clearInviteUsersErrors();
    clearInstitutions();

    if (isTEAdmin(currentUser)) {
      fetchInstitutions();
    } else {
      fetchInstitution(currentUser.institution_id).then(
        res => setSelectedInstitution(res?.payload)
      )
    }
  }, [clearInstitutions, fetchInstitution, fetchInstitutions]);

  useEffect(() => {
    if (selectedInstitution) {
      setLicenses(selectedInstitution?.licenses);
      setCode('');
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (licenses && licenses.length === 1) {
      setCode(licenses[0].access_code);
    }
  }, [licenses]);

  const resetTabState = () => {
    if (tab === 'Excel') {
      setEmailsBuffer('');
      setEmails([]);
    } else if (tab === 'Emails') {
      setFile(null);
    }
  };

  const resetState = () => {
    setEmailsBuffer('');
    setEmails([]);
    setFile(null);

    if (fileRef.current) {
      fileRef.current.value = '';
    }

    if (isTEAdmin(currentUser)) {
      setSelectedInstitution(null);
      setLicenses([]);
    }

    setCode('');
  };

  const containerClass = () => (
    isTEAdmin(currentUser)
      ? 'SharedAdminDashboard-GroupList__container'
      : 'B2BAdminDashboard-GroupList__container'
  );

  const renderTabs = () => (
    <div className="Invite-Tabs-Holder Tour-Step-Invite-Users-Invitation-Tabs">
      {
        ['Excel', 'Emails'].map((option) => (
          <div
            key={`Tab-${option}`}
            className={`Invite-Tab ${option == tab && 'Selected-Tab'}`}
            onClick={() => {
              setTab(option);
              resetTabState();
            }}
          >
            {option}
          </div>
        ))
      }
    </div>
  );

  const renderExcelTab = () => (
    <div className="Excel-Tab-Content">
      <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
        <DashboardFormBoxNoEdit
          title="INVITE BY UPLOADING AN EXCEL FILE:"
        >
          <ul className="Instructions list-disc">
            <li>Try to use this option if you need to invite a large number of users.</li>
            <li>The file should be a valid Excel file containing a column header with name "Email".</li>
          </ul>
        </DashboardFormBoxNoEdit>
      </div>

      <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
        <DashboardFormBoxNoEdit
          title="UPLOAD FILE"
        >
          <div className="Excel-Field">
            <input
              type="file"
              className="Input"
              accept={validFileTypesList.join(', ')}
              ref={fileRef}
              onChange={e => handleFile()}
            />
          </div>
        </DashboardFormBoxNoEdit>
      </div>
    </div>
  );

  const handleFile = () => {
    clearInviteUsersErrors();
    if (fileRef.current.files.length > 0) {
      setFile(fileRef.current.files[0]);
    }
  }

  const renderEmailsTab = () => (
    <div className="Emails-Tab-Content">
      <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
        <DashboardFormBoxNoEdit
          title="INVITE BY PROVIDING EMAILS:"
        >
          <ul className="Instructions list-disc">
            <li>Try to use this option if you need to invite a small number of users.</li>
            <li>Type in one or more emails separated by commas and click the "Update" button to verify the list.</li>
            <li>If you are pasting in a list from your clipboard, make sure your emails are separated by commas.</li>
          </ul>
        </DashboardFormBoxNoEdit>
      </div>

      <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
        <DashboardFormBoxNoEdit
          title="EMAILS"
        >
          <div className="Heading-Holder">
            <div className="DashboardFormBox-Label">
              Email List
            </div>
            <button
              className="InviteUsers-Update-Button"
              onClick={(e) => handleAddEmails(e)}
              type="button"
            >
              Update
            </button>
          </div>

          <div className="Text-Area-Box">
            <TextAreaBox
              className="TextAreaBox-InviteUsers"
              value={emailsBuffer}
              onChange={(e) => handleEmailsInputChange(e)}
            />
          </div>

          {buildEmailPills()}
        </DashboardFormBoxNoEdit>
      </div>
    </div>
  );

  const handleEmailsInputChange = (e) => {
    clearInviteUsersErrors();
    setEmailsBuffer(e.target.value);
  };

  const handleAddEmails = (e) => {
    e.preventDefault();
    clearInviteUsersErrors();

    setEmails(emailsBuffer.split(',').map(e => e.trim()).filter(e => e));
  };

  const buildEmailPills = () => (
    <div className="Pill-Holder">
      {
        emails?.map((recipient, idx) => (
          <Pill className="Pill-Add" key={`${recipient}-${idx}`} text={recipient} />
        ))
      }
    </div>
  );

  const buildInstitutionDropdown = () => (
    <select
      className="createLicenseContainer__select"
      onChange={handleInstitutionChange}
      value={selectedInstitution ? selectedInstitution.id : 'unselected'}
    >
      <option value="unselected" disabled>Select an institution</option>
      {
        institutions?.sort((a, b) => a.name > b.name ? 1 : -1)?.map((institution) => (
          <option value={institution.id} key={`institution-${institution.id}`}>
            {institution.name}
          </option>
        ))
      }
    </select>
  );

  const handleInstitutionChange = (e) => {
    clearInviteUsersErrors();
    const matchingInstitution = institutions.find(institution => institution.id.toString() === e.currentTarget.value);

    if (matchingInstitution) {
      setSelectedInstitution(matchingInstitution);
    }
  };

  const buildLicenses = () => (
    <div className="CreationForms-RadioBox">
      {
        licenses?.map((license, index) => (
          <RadioBox
            key={`license-${license.access_code}-${index}`}
            className="RadioBox-EditUser"
            name={`license-choice-${index}`}
            text={license.access_code}
            value={license.access_code}
            checked={(code === license.access_code)}
            onChange={handleCodeChange}
          />
        ))
      }
    </div>
  );

  const handleCodeChange = (e) => {
    clearInviteUsersErrors();
    setCode(e.currentTarget.value);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    clearInviteUsersErrors();
    resetState();
  };

  const handleSubmit = (e) => {
    clearInviteUsersErrors();
    e.preventDefault();

    let errors = {};

    if (tab == 'Excel') {
      if (!file) {
        errors['File:'] = 'No file selected!';
      }

      if (!validFileTypesList.includes(file?.type)) {
        errors['File Type:'] = 'Please choose an Excel file of type .xlsx or .xls';
      }
    } else if (tab == 'Emails') {
      if (emails.length === 0) {
        errors['Emails'] = 'No emails provided. Kindly input emails and click update button.';
      }
    }

    if (!selectedInstitution) {
      errors['Institution:'] = 'No institution selected!';
    }

    if (!code) {
      errors['License:'] = 'No license selected!';
    }

    inviteUsersFormValidatonErrors(errors);

    if (Object.keys(errors).length === 0) {
      setInviting(true);

      const formData = new FormData();
      formData.append('invitation_batch[institution_id]', selectedInstitution.id);
      formData.append('invitation_batch[access_code]', code);

      if (tab == 'Excel') {
        formData.append('invitation_batch[file]', file);
      } else if (tab == 'Emails') {
        formData.append('invitation_batch[emails]', JSON.stringify(emails));
      }

      createInviteUsers(formData).then(() => {
        gaEvent(`invite_user_through_${tab.toLowerCase()}`, { institution_id: selectedInstitution.id, access_code: code });
        setInviting(false);
        resetState();
      });
    }
  };

  return (
    <div className={`${containerClass()} InviteUsers`}>
      {inviting && <MirrorLoader message="Inviting Users!" />}

      <ErrorFlashBox
        errors={errors}
        flash={flash}
      />

      <TutorialContainer />

      <form className="DashboardFormBox-Content" onSubmit={handleSubmit}>
        <DashboardFormBoxNoEdit
          title="USERS TO INVITE"
        >
          {renderTabs()}
        </DashboardFormBoxNoEdit>

        {tab === 'Excel' ? renderExcelTab() : renderEmailsTab()}

        {
          isTEAdmin(currentUser) && (
            <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
              <DashboardFormBoxNoEdit
                title="ADD TO INSTITUTION"
              >
                <div className="DashboardFormBox-Line fdc">
                  <div className="Institution-Container">
                    <div className="Institution-Drop-Down">
                      {buildInstitutionDropdown()}
                    </div>
                  </div>
                </div>
              </DashboardFormBoxNoEdit>
            </div>
          )
        }

        <div className="DashboardFormBoxNoEdit-Container Adjust-Space">
          <DashboardFormBoxNoEdit
            title="ADD TO LICENSE"
          >
            <div className="DashboardFormBox-Line fdc Tour-Step-Invite-Users-License-Options">
              <div className="License-Container">
                <div className="License-Drop-Down">
                  {buildLicenses()}
                </div>
              </div>
            </div>
            <div className="Buttons-Container" >
              <ButtonBox
                className={`DashboardFormBox-SaveBtn DashboardFormBox-Button_save ${code ? '' : ' disabled_save_button'}`}
                text="Save"
              />
              <ButtonBox
                className="DashboardFormBox-CancelBtn DashboardFormBox-Button_cancel"
                text="Clear"
                onClick={handleCancel}
              />
            </div>
          </DashboardFormBoxNoEdit>
        </div>
      </form>
    </div>
  );
};

InviteUsers.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    user_type: PropTypes.string,
    institution_id: PropTypes.number,
  }).isRequired,
  institutions: PropTypes.array,
  errors: PropTypes.shape({
    message: PropTypes.string,
  }),
  flash: PropTypes.shape({
    message: PropTypes.string,
  }),
  clearInstitutions: PropTypes.func.isRequired,
  fetchInstitutions: PropTypes.func.isRequired,
  fetchInstitution: PropTypes.func.isRequired,
  clearInviteUsersErrors: PropTypes.func.isRequired,
  createInviteUsers: PropTypes.func.isRequired,
  inviteUsersFormValidatonErrors: PropTypes.func.isRequired,
  receiveInviteUsersConfirmation: PropTypes.func.isRequired,
};

InviteUsers.defaultProps = {
  errors: undefined,
  flash: undefined,
};

export default InviteUsers;
