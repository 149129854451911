import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectCourse from '../../../Composed/SelectCourseEntity/SelectCourse/SelectCourse';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import '../Clone.scss';

const LessonClone = ({ courses, lesson, fetchCoursesTree, cloneLesson }) => {
  const [loading, setLoading] = useState(true);
  const [cloning, setCloning] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [targetCourseId, setTargetCourseId] = useState(null);

  useEffect(() => {
    fetchCoursesTree().then((res) => {
      setLoading(false);
    });
  }, [fetchCoursesTree]);

  const resetErrorAndFlash = () => {
    setErrors({});
    setFlash({});
  }

  const handleCourseSelection = (e) => {
    resetErrorAndFlash();
    setTargetCourseId(e.id);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrorAndFlash();

    if (!targetCourseId) {
      setErrors({ '': 'Please select target course first!' });
      return null;
    }

    setCloning(true);
    cloneLesson(targetCourseId, lesson.id).then((res) => {
      res.message ? setFlash({ message: res.message }) : setErrors({ '': res.errors?.error });
      setCloning(false);
    });
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Clone-Modal-Container Lesson-Clone-Container">
      {cloning && <MirrorLoader message="Cloning Lesson!" />}

      <ErrorFlashBox errors={errors} flash={flash} />

      <SelectCourse
        courses={courses}
        selectionCallBack={e => handleCourseSelection(e)}
      />

      <div className="Buttons-Container">
        <ButtonBox
          className="CreationDashboards-ButtonBox Clone-Button"
          text='Clone Lesson'
          onClick={e => handleSubmit(e)}
        />
      </div>
    </div>
  )
}

LessonClone.propTypes = {
  courses: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  fetchCoursesTree: PropTypes.func.isRequired,
  cloneLesson: PropTypes.func.isRequired,
};

export default LessonClone;
