import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

import './DonutChart.scss';

Exporting(Highcharts);

const DonutChart = ({ id, data }) => {
  const buildToolTip = (that) => (
    data.tooltip
      .replaceAll('{{POINT_KEY}}', that.key)
      .replaceAll('{{POINT_COLOR}}', that.color)
      .replaceAll('{{POINT_PERCENTAGE}}', Math.round(that.percentage || 0))
  );

  Highcharts.chart(id, {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    title: {
      text: data.title,
      align: 'center',
      verticalAlign: 'middle',
      y: 60
    },
    tooltip: {
      shared: false,
      useHTML: true,
      formatter: function() {
        return buildToolTip(this)
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          distance: 0,
          style: {
            fontWeight: 'bold',
            color: 'white'
          }
        },
        startAngle: 0,
        endAngle: 360,
        center: ['50%', '50%'],
        size: '70%',
        color: 'red'
      }
    },
    series: [{
        type: 'pie',
        innerSize: '70%',
        colorByPoint:false,
        data: data.series,
    }]
  });

  return null;
}

export default DonutChart;
