import axios from './AxiosUtil';

export const createSection = (section) => {
    return axios.post("/api/sections", { section }).then(
        res => res.data
    );
};

export const editSection = (section) => {
    return axios.patch(`/api/sections/${section.id}`, { section }).then(
        res => res.data
    );
};

export const deleteSection = sectionId => {
    return axios.delete(`/api/sections/${sectionId}`).then(
        res => res.data
    );
 }

export const moveHigher = sectionId => {
    return axios.post(`/api/sections/${sectionId}/higher`).then(
        res => res.data
    );
};

export const moveLower = sectionId => {
    return axios.post(`/api/sections/${sectionId}/lower`).then(
        res => res.data
    );
};

export const cloneSection = (targetSectionableType, targetSectionableId, sectionId) => {
    return axios.post(`/api/sections/${sectionId}/clone`, { target_sectionable_type: targetSectionableType, target_sectionable_id: targetSectionableId }).then(
        res => res.data
    );
};

export const uploadSectionMedia = (formData) => {
    return axios({
        method: "PATCH",
        url: `/api/sections/${formData.get("section[id]")}`,
        processData: false,
        contentType: false,
        dataType: 'json',
        data: formData,
    }).then(
        res => res.data
    );
};
