import React, { Component } from 'react';

import './WordScrambleBoxMini.css';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';
import { COLORS } from '../../../Style/Style';

class WordScrambleBoxMini extends Component {

    state = {
        answers: this.props.question.answers,
    }

    handleBlur = idx => e => {
        e.currentTarget.id = "";
        e.currentTarget.value = this.props.question.answers[idx].text;
    };

    handleFocus = e => {
        e.currentTarget.id = "active";
    };

    handleInputChange = idx => {
        return e => {
            if (!this.props.creation) {
                return;
            }
            const value = e.currentTarget.value;
            this.setState(prevState => {
                const newAnswers = [...prevState.answers];
                newAnswers[idx].text = value;
                return {
                    answers: newAnswers,
                }
            });
        }
    }

    handleSubmit = idx => e => {
        e.preventDefault();
        if (this.props.creation) {
            this.props.editAnswer(this.state.answers[idx]);
        }
    }

    handleQuestionRender = () => {
        const { question } = this.props;
        const questionParts = question.text.split("__word__");

        let questionList = [];
        questionParts.forEach((part, idx) => {
            part.split(" ").forEach(
                (word, idx2) => {
                    questionList.push(
                        <div
                            className="DragAndDropBoxMini-QuestionParts"
                            key={(idx + 1) * 1000 + idx2}>
                            <TextBox
                                text={word}
                                style={{
                                    color: COLORS.Blue
                                }} />
                        </div>
                    );
                }
            )

            if (question.answers[idx]) {
                questionList.push(
                    <form
                        key={idx + 1}
                        onSubmit={this.handleSubmit(idx)}>
                        <input
                            style={{
                                width: `${(question.answers[idx].text.length + 2) * 12}px`,
                            }}
                            value={this.state.answers[idx].text}
                            onChange={this.handleInputChange(idx)}
                            onBlur={this.handleBlur(idx)}
                            onFocus={this.handleFocus}
                            type='text' />
                    </form>
                );
            }
        });
        return questionList;
    };

    render() {
        const { question, editQuestion, deleteQuestion, uploadAudio } = this.props;

        return (
            <div
                className="DragAndDropBoxMini">
                <div
                    className="QuestionMini-QuestionBox">
                    <div
                        className="QuestionMini-QuestionBox-Position"
                        style={
                            question.answers.length >= question.number_of_answers ?
                                {
                                    backgroundColor: COLORS.green
                                }
                                :
                                {
                                    backgroundColor: COLORS.redPink
                                }
                        }>
                        <TextBox
                            text={question.position}
                            style={{
                                color: COLORS.Blue
                            }} />
                    </div>

                    <div
                        className="QuestionMini-QuestionBox-Question">
                        <QuestionBox question={question} creation={this.props.creation}>
                            {this.handleQuestionRender()}

                            {
                                question.hint &&
                                <TextBox
                                    text={`(${question.hint} )`}
                                    style={{
                                        color: COLORS.lightBlue1
                                    }} />
                            }
                        </QuestionBox>
                    </div>
                    {
                        this.props.creation &&
                        <FunctionBox
                            submitMedia={uploadAudio}
                            model="question"
                            entity={question}
                            editEntity={editQuestion}
                            deleteEntity={deleteQuestion}
                        />
                    }
                </div>
            </div>
        );
    };
}

export default WordScrambleBoxMini;
