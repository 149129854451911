import {
  CLEAR_BREADCRUMBS,
  ADD_BREADCRUMB,
  UPDATE_BREADCRUMB,
  REMOVE_BREADCRUMB,
} from '../../Actions/BreadcrumbActions';

const BreadcrumbsReducer = (state = [], action) => {
  Object.freeze(state);

  switch (action.type) {
    case CLEAR_BREADCRUMBS:
      return [];
    case ADD_BREADCRUMB:
      return [...state, action.payload];
    case UPDATE_BREADCRUMB:
      return state.map(crumb => (crumb.id === action.payload.id ? action.payload : crumb));
    case REMOVE_BREADCRUMB:
      return state.filter(crumb => (crumb.id !== action.payload.id));
    default:
      return state;
  }
}

export default BreadcrumbsReducer;
