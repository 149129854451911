import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Tabs from '../../../Basic/Tabs/Tabs';
import Loader from '../../../Composed/Loader/Loader';
import { Progress, ProgressMobile } from './Progress';
import Activity from './Activity';
import Select from './Select';
import './UserDashboardSummary.scss';

const propTypes = {
    currentUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    fetchTopicsCount: PropTypes.func.isRequired,
    topicsCount: PropTypes.instanceOf(Object).isRequired,
    courseProgresses: PropTypes.array.isRequired,
    courses: PropTypes.instanceOf(Object).isRequired,
};

export const getNumberOfCompletedTopics = (progress, idx) => {
    try {
        return progress.progress_data.lessonProgresses[idx].completedTopics.length;
    } catch (error) {
        return 0;
    }
};

export const calculateLessonProgressPercentage = (progress, courseIdx, lesson, topics) => {
    const perOneHundred = 100;
    const numDigitsToRound = 1;
    const numCompletedTopics = getNumberOfCompletedTopics(progress[courseIdx], lesson.id);
    const numTotalTopics = topics[lesson.id];
    return numTotalTopics === 0
        ? '0'
        : ((numCompletedTopics / numTotalTopics) * perOneHundred).toFixed(numDigitsToRound);
};

/**
 * Synthesizes object needed to hydrate course progress grid
 * @param {object} courses subscribed courses
 * @param {object} progress current course/lesson progresses
 * @param {object} topics key/value representation of total topics per lesson
 */
export const synthesizeCourseProgresses = (courses, progress, topics) => {
    if (Object.keys(courses).length > 0
        && Object.keys(progress).length > 0 && Object.keys(topics).length > 0) {
        const demo = Object.values(courses).map((course, courseIdx) => ({
            course_id: course.id,
            courseName: course.name,
            lessons: course.lessons
                .filter(lesson => lesson.is_test === false).map((lesson) => ({
                    lessonName: lesson.name,
                    lessonProgress: calculateLessonProgressPercentage(progress, courseIdx, lesson, topics),
                    completedDate: 0,
                })),
        }));
        return demo;
    }
    return [];
};

const UserDashboardSummary = props => {
    const [loadingTopics, setLoadingTopics] = useState(true);
    const [course, setCourse] = useState(0);
    const [tab, setTab] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const tabs = ['PROGRESS'];
    const {
        activity,
        topicsCount,
        courseProgresses,
        courses,
        currentUser: { id },
        fetchTopicsCount,
    } = props;

    useEffect(() => {
        window.addEventListener('resize', debounce(() => setWidth(window.innerWidth), 500));
    });

    useEffect(() => {
        fetchTopicsCount().then(() => setLoadingTopics(false));
    }, [fetchTopicsCount]);

    const displayTab = () => {
        switch (tab) {
            case 0:
                return width <= 1300 ? <ProgressMobile course={synthesizeCourseProgresses(props.courses, props.courseProgresses, props.topicsCount)[course]} /> : <Progress course={synthesizeCourseProgresses(props.courses, props.courseProgresses, props.topicsCount)[course]} />;
            case 2:
                return <Activity activity={activity} />;
            default:
                return <div />;
        }
    };

    if (loadingTopics) {
        return <Loader message="Loading, Hang on!" />
    }

    return (
        <div className="userDashboardSummary">
            <div className="userDashboardSummary__inner">
                {
                    Object.keys(courses).length > 0
                        ? width <= 768
                            ? (
                                <>
                                    <Tabs setTab={setTab} currentTab={tab} tabs={tabs} />
                                    <Select
                                        iterator={synthesizeCourseProgresses(props.courses, props.courseProgresses, props.topicsCount)}
                                        callback={setCourse}
                                        property="courseName"
                                    />
                                    {displayTab()}
                                </>
                            )
                            : (
                                <>
                                    <Select
                                        iterator={synthesizeCourseProgresses(props.courses, props.courseProgresses, props.topicsCount)}
                                        callback={setCourse}
                                        property="courseName"
                                    />
                                    <Tabs setTab={setTab} currentTab={tab} tabs={tabs} />
                                    {displayTab()}
                                </>
                            )
                        : (
                            <h2 style={{
                                margin: 20,
                            }}
                            >
                                Please
                                {' '}
                                <a style={{ textDecoration: 'underline' }} href="/#/account/learn/course_packages">sign up</a>
                                {' '}
                                for a course!
                            </h2>
                        )
                }
            </div>
        </div>
    );
};

UserDashboardSummary.propTypes = propTypes;
export default UserDashboardSummary;
