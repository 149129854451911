import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Pill from '../../../Basic/Pill/Pill';

import { invitationStatusesList } from '../../../../Constants/InviteUserConstants';
import { humanizeDate } from '../../../../Util/Helper/GenericUtil';

const Invitations = ({ invitationBatch }) => {
  const [showInvitations, setShowInvitations] = useState(false);
  const [tab, setTab] = useState(Object.keys(invitationStatusesList)[0]);

  const renderEmpty = () => (
    <div className="Empty-Pills">No invitations!</div>
  );

  const renderPills = () => (
    <div className="Pills">
      {
        invitationBatch[tab].map(invitation => (
          <Pill className="Pill-Add" text={invitation.email} key={invitation.email} />
        ))
      }
    </div>
  );

  return (
    <div className="Invitation-Batch-Container">
      <div className="Batch-Info" onClick={e => setShowInvitations(!showInvitations)}>
        <div className="Info-Holder">
          <div><strong>Access Code</strong>: {invitationBatch.access_code}</div>
          <div><strong>Total Invitations</strong>: {invitationBatch.totalInvitations}</div>
          <div><strong>Sent on</strong>: {humanizeDate(invitationBatch.created_at)}</div>
          {
            invitationBatch.fileName &&
            <div><strong>File Name</strong>: {invitationBatch.fileName}</div>
          }
        </div>
        <div className="Drop-Down-Icon">
          <i className={`fas fa-chevron-${showInvitations ? 'up' : 'down'}`} />
        </div>
      </div>

      <div className="Invitations-Info">
        {
          showInvitations &&
          <div className="Info-Holder">
            <div className="Tabs">
              {
                Object.entries(invitationStatusesList).map(([k, v], idx) => (
                  <div
                    key={`tab-${k}-${idx}`}
                    children={`${v} (${invitationBatch[k]?.length})`}
                    className={`Tab ${tab === k && 'Active'}`}
                    onClick={() => setTab(k)}
                  />
                ))
              }
            </div>

            {invitationBatch[tab].length === 0 ? renderEmpty() : renderPills()}
          </div>
        }
      </div>
    </div>
  )
}

Invitations.propTypes = {
  invitationBatch: PropTypes.object.isRequired
}

export default Invitations;
