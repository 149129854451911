import { connect } from 'react-redux';
import { fetchUsers } from '../../../../../Actions/UserActions';
import SearchUsers from './SearchUsers';

const mapStateToProps = state => ({
    users: state.admin.users.users,
});

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => dispatch(fetchUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsers);
