import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import DashboardFormBoxNoEdit from '../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

import { isB2C, isB2BBusiness, isB2BIndividual } from '../../../Util/Helper/UserWorkplaceUtil';

const ManageUserAccess = ({
  clearUserCourses, getUserCourses, userCourses,
  fetchCourses, courses,
  getLicenses, licenses,
  updateUser, addSubscription,
  errors, flash
}) => {
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState('');
  const [workplaceId, setWorkplaceId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [length, setLength] = useState('12');
  const [formError, setFormError] = useState('');
  const [subscribing, setSubscribing] = useState(false);

  useEffect(() => {
    clearUserCourses();
    fetchCourses();
    getLicenses();
  }, [clearUserCourses, fetchCourses, getLicenses]);

  const handleCancel = e => {
    e.preventDefault();
    clearUserCourses();
    setEmail('');
    resetStateValues();
  };

  const resetStateValues = () => {
    setWorkplaceId(null);
    setCourseId(null);
    setLength('12');
    setFormError('');
    setSubscribing(false);
  }

  const handleChangeSubscriprions = (e) => {
    e.preventDefault();

    if (!workplaceId || !courseId || !length) {
      setFormError('Please select all fields');
      return null;
    }

    const subscription = {
      user_id:      userCourses.id,
      workplace_id: workplaceId,
      course_id:    courseId,
      course_package_id: 1,
      length,
    };

    setSubscribing(true);
    setProcessing(true);

    addSubscription(subscription).then(res => {
      resetStateValues();
      setProcessing(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  const handleToggleTrial = e => {
    e.preventDefault();

    const user = {
      id:    userCourses.id,
      trial: !userCourses.trial,
    };

    setProcessing(true);
    updateUser(user).then(() => {
      setProcessing(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  return (
    <div className="createUserContainer CreateUser">
      {processing && <MirrorLoader message='Processing...' />}

      <ErrorFlashBox
        errors={errors}
        flash={flash}
      />

      <DashboardFormBoxNoEdit
        title="MANAGE USER ACCESS"
      >
        <div className="DashboardFormBox-Line Manage-User-Access fdc">
          <div className="Verify-Head">
            <div className="formWidth">
              <div className="DashboardFormBox-Label left">
                User Email
              </div>
              <button
                className="CreateUser-Update-Button"
                onClick={(e) => {
                  e.preventDefault();
                  setProcessing(true);
                  getUserCourses({ email }).then(() => setProcessing(false));
                }}
                type="button"
              >
                Verify
              </button>
            </div>
          </div>
          <div className="Verify-Body">
            <input
              className={`${userCourses.id ? 'ValidEmail' : ''} formWidth`}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              type="text"
            />
            <i className={`fas fa-check ${userCourses.id ? 'ValidEmail' : ''}`} />
          </div>
        </div>
      </DashboardFormBoxNoEdit>

      <DashboardFormBoxNoEdit
        title="B2B Business License Access"
      >
        {
          userCourses.id && (
            userCourses.workplaces.find(w => isB2BBusiness(userCourses, w)) ? (
              <div>
                <ol className="list-decimal">
                  {
                    userCourses.workplaces.filter(w => isB2BBusiness(userCourses, w)).map(w => (
                      <li>
                        <div className="Big-Text">
                          <b>Institution Name:</b> {w.original_institution_name}
                        </div>
                        <div className="Small-Text">
                          <b>User Defined Institution Name:</b> {w.institution_name || ''}
                        </div>
                        <div className="Small-Text">
                          <b>License Type:</b> {w.license_type}
                        </div>
                        <div className="Small-Text">
                          <b>License Code:</b> {w.license_access_code}
                        </div>
                      </li>
                    ))
                  }
                </ol>
              </div>
            ) : (
              <div>No B2B Business License Workplace</div>
            )
          )
        }
      </DashboardFormBoxNoEdit>

      <DashboardFormBoxNoEdit
        title="B2B Individual License Access"
      >
        {
          userCourses.id && (
            userCourses.workplaces.find(w => isB2BIndividual(userCourses, w)) ? (
              <div>
                <ol className="list-decimal">
                  {
                    userCourses.workplaces.filter(w => isB2BIndividual(userCourses, w)).map(w => (
                      <li>
                        <div className="Big-Text">
                          <b>Institution Name:</b> {w.original_institution_name}
                        </div>
                        <div className="Small-Text">
                          <b>User Defined Institution Name:</b> {w.institution_name || ''}
                        </div>
                        <div className="Small-Text">
                          <b>License Type:</b> {w.license_type}
                        </div>
                        <div className="Small-Text">
                          <b>License Code:</b> {w.license_access_code}
                        </div>
                      </li>
                    ))
                  }
                </ol>
              </div>
            ) : (
              <div>No B2B Individual License Workplace</div>
            )
          )
        }
      </DashboardFormBoxNoEdit>

      <DashboardFormBoxNoEdit
        title="B2C Access"
      >
        {
          userCourses.id && (
            userCourses.workplaces.find(w => isB2C(userCourses, w)) ? (
              <div>
                <ol className="list-decimal">
                  {
                    userCourses.workplaces.filter(w => isB2C(userCourses, w)).map(w => (
                      <li>
                        <div className="Big-Text">
                          <b>Institution Name:</b> {w.original_institution_name}
                        </div>
                        <div className="Small-Text">
                          <b>User Defined Institution Name:</b> {w.institution_name || ''}
                        </div>
                        <div className="Small-Text">
                          <b>License Type:</b> {w.license_type || 'N/A'}
                        </div>
                        <div className="Small-Text">
                          <b>License Code:</b> {w.license_access_code || 'N/A'}
                        </div>
                      </li>
                    ))
                  }
                </ol>
              </div>
            ) : (
              <div>No B2C Workplace</div>
            )
          )
        }
      </DashboardFormBoxNoEdit>

      <DashboardFormBoxNoEdit
        title="Add Subscription"
      >
        {
          userCourses.id && (
            userCourses.workplaces.find(w => isB2C(userCourses, w) || isB2BIndividual(userCourses, w)) ? (
              <form className="DashboardFormBox-Content" onSubmit={handleChangeSubscriprions}>
                <div className="DashboardFormBox-Content">
                  <div className="Flex-Space-Between">
                    <div className="formWidth Flex-Space-Between w-100">
                      <div className="DashboardFormBox-Label left mr-10">
                        User Subscriptions
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="Flex-Center">
                      <div className="formWidth Flex-Space-Between">
                        <select
                          className="createLicenseContainer__select"
                          value={courseId || 'unselected'}
                          onChange={(e) => setCourseId(e.currentTarget.value)}
                        >
                          <option value="unselected" disabled>Please select course</option>
                          {
                            Object.values(courses).map((course) => (
                              <option value={course.id} key={course.id}>
                                {course.name}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="DashboardFormBox-Content">
                  <div className="Flex-Space-Between">
                    <div className="formWidth Flex-Space-Between w-100">
                      <div className="DashboardFormBox-Label left mr-10">
                        User Workplaces
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="Flex-Center">
                      <div className="formWidth Flex-Space-Between">
                        <select
                          className="createLicenseContainer__select"
                          value={workplaceId || 'unselected'}
                          onChange={(e) => setWorkplaceId(e.currentTarget.value)}
                        >
                          <option value="unselected" disabled>Please Select Workplace (Institution Name -- User Defined Name -- Access Code)</option>
                          {
                            userCourses.workplaces.filter(w => (isB2C(userCourses, w) || isB2BIndividual(userCourses, w))).map((workplace) => (
                              <option value={workplace.id} key={workplace.id}>
                                {`${workplace.original_institution_name} -- ${workplace.institution_name || 'N/A'} -- ${workplace.license_access_code || 'N/A'}`}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="DashboardFormBox-Content mb-12">
                  <div className="">
                    <div className="Flex-Space-Between">
                      <div className="formWidth Flex-Space-Between">
                        <div className="DashboardFormBox-Label left mr-10">
                          Subscription Length
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="Flex-Center">
                      <input
                        className="formWidth"
                        value={length}
                        onChange={(e) => setLength(e.currentTarget.value)}
                        style={{ marginTop: 0, marginBottom: 0 }}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                {
                  formError &&
                  <div className="Danger-Message">{formError}</div>
                }

                <div className="Flex-Space-Between w-100">
                  <ButtonBox
                    className={`DashboardFormBox-Button_save full-width ${userCourses.id ? '' : ' disabled_save_button'}`}
                    disabled={subscribing}
                    text="Add Subscription"
                  />
                  <ButtonBox
                    className="DashboardFormBox-Button_cancel"
                    text="Clear"
                    onClick={handleCancel}
                  />
                </div>
              </form>
            ) : (
              <div>No B2C or B2B Individual License Workplace</div>
            )
          )
        }
      </DashboardFormBoxNoEdit>

      <DashboardFormBoxNoEdit
        title="TRIAL STATUS"
      >
        {
          userCourses.id &&
          <form className="DashboardFormBox-Content" onSubmit={handleToggleTrial}>
            <div className="DashboardFormBox-Line">
              <div className="Flex-Space-Between w-100">
                <div className="formWidth Flex-Space-Between w-100">
                  <div className="DashboardFormBox-Label left">
                    {
                      userCourses.trial
                        ? 'This user is a trial user'
                        : 'This user is not a trial user'
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="Flex-Space-Between w-100">
              <ButtonBox
                className={`DashboardFormBox-Button_save full-width ${userCourses.id ? '' : ' disabled_save_button'}`}
                text={userCourses.trial ? 'Remove Trial' : 'Make Trial User'}
              />
              <ButtonBox
                className="DashboardFormBox-Button_cancel"
                text="Clear"
                onClick={handleCancel}
              />
            </div>
          </form>
        }
      </DashboardFormBoxNoEdit>
    </div>
  );
};

export default ManageUserAccess;
