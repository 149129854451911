import React from 'react';
import PropTypes from 'prop-types';

import './LessonsSummaryBox.css';
import LessonBox from '../../Presentational/LessonBox/LessonBox';

const propTypes = {
    lessons: PropTypes.objectOf(PropTypes.object).isRequired,
    lessonsOrdering: PropTypes.arrayOf(PropTypes.number).isRequired,
    course: PropTypes.shape().isRequired,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({
        trial: PropTypes.bool,
    }).isRequired,
    quizResults: PropTypes.objectOf(PropTypes.array).isRequired,
};

const LessonsSummaryBox = ({
    lessons,
    lessonsOrdering,
    course,
    courseProgresses,
    currentUser,
    quizResults,
}) => {
    const handleRender = () => {
        const items = lessonsOrdering.map((lessonId, index) => (
            <div
                className="LessonSummaryBox-Item"
                key={lessonId}
            >
                <LessonBox
                    key={lessonId}
                    lesson={lessons[lessonId]}
                    lessons={lessons}
                    course={course}
                    courseProgress={courseProgresses[lessonId]}
                    trialLocked={currentUser.trial && (index > 3 || index < 1)}
                    quizResults={quizResults}
                />
            </div>
        ));
        return items;
    };

    return (
        <div className="LessonsSummaryBox">
            { handleRender() }
        </div>
    );
};

LessonsSummaryBox.propTypes = propTypes;

export default LessonsSummaryBox;
