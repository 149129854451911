import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CommunityActivity from './CommunityActivity';
import MyBookmarks from './MyBookmarks';
import MyComments from './MyComments';
import MyProgresses from './MyProgresses';
import MyActivity from './MyActivity';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './Learn.scss';

const Learn = () => {
  const [contentWidth, setContentWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      setContentWidth((window.outerWidth > 768) ? (pageWidth - 500) : (pageWidth - 150));
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <div className="Learn-Dashboard">
      <div className="ButtonHolder">
        <Link to="/account/profile/?tab=my-progress&sec=subscriptions">
          <ButtonBox
            className="ManageButton"
            text="Manage Subscriptions"
          />
        </Link>

        <Link to="/learn">
          <ButtonBox
            className="Static-Primary-Btn Learn-Button"
            text="Go to Canopy Learn"
          />
        </Link>
      </div>

      <MyBookmarks contentWidth={contentWidth} />

      <MyComments contentWidth={contentWidth} />

      <MyProgresses contentWidth={contentWidth} />

      <MyActivity contentWidth={contentWidth} />
    </div>
  )
}

export default Learn;
