import React, { Component } from 'react';
import ReactNumberFormat from 'react-number-format';
import ReactQuill from 'react-quill';

import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';
import AutoComplete
  from '../../Composed/AutoComplete/AutoComplete';
import { COLORS }
  from '../../../Style/Style';

import { QuillModules } from '../../../Data/Quill';

import './CreateEditCoursePackage.scss';
import 'react-quill/dist/quill.snow.css';

class CreateEditCoursePackage extends Component {

  state = this.props.coursePackage ?
    {
      name: this.props.coursePackage.name,
      price: this.props.coursePackage.price / 100,
      renewal_price: this.props.coursePackage.renewal_price / 100,
      stripe_initial_plan_id: this.props.coursePackage.stripe_initial_plan_id,
      stripe_recurring_plan_id: this.props.coursePackage.stripe_recurring_plan_id,
      length: this.props.coursePackage.length,
      tag: this.props.coursePackage.tag,
      description: this.props.coursePackage.description,
      data: JSON.parse(JSON.stringify(this.props.coursePackage.data || {})),
    }
    :
    {
      name: "",
      price: "",
      renewal_price: "",
      stripe_initial_plan_id: "",
      stripe_recurring_plan_id: "",
      length: "",
      tag: "",
      description: "",
      data: {},
    }

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleQuillChange = field => value => this.setState({ [field]: value })

  handleSubmit = (e) => {
    e.preventDefault();

    const coursePackage = Object.assign({}, this.state);

    coursePackage.price = coursePackage.price * 100;
    coursePackage.renewal_price = coursePackage.renewal_price * 100;

    if (this.props.coursePackage) {
      coursePackage.id = this.props.coursePackage.id;
    }

    this.props.submitCoursePackage(coursePackage).then(
      this.props.closeModal()
    );
  }

  hasActiveSubscriptions = () => (
    this.props.coursePackage && this.props.coursePackage.active_recurring_subscriptions > 0
  );

  buildSelectableCourses = () => (
    Object.values(this.props.courses).map(course => {
      return { value: course.id, label: course.name }
    })
  );

  buildSelectedCourses = () => (
    Object.values(this.props.courses).map(course => {
      if ((this.state.data.renew_reward_courses_ids || []).includes(course.id)) {
        return { value: course.id, label: course.name }
      }
    })
  );

  selectCourses = (selected_options) => {
    let newData = this.state.data;

    newData['renew_reward_courses_ids'] = selected_options.map(option => option.value);

    this.setState({ data: newData });
  }

  render() {
    return (
      <div className="modal">
        <div className="modal-content">
          <div
            className="CreationForms">
            <div
              className="CreationForms-Title">
              <TextBox
                text={this.props.coursePackage ? "Edit Course Package" : "Add A Course Package"}
                style={{
                  color: `${COLORS.Blue}`,
                  fontWeight: "600",
                  textDecoration: "none",
                }}
                tag="h3"
              />

              <div
                className="CreationForms-Button">
                <ButtonBox
                  className="CreationDashboards-ButtonBox"
                  text="Exit"
                  onClick={this.props.closeModal} />
              </div>
            </div>

            <form
              className="CreationForms-Form CoursePackage-CreateEdit-Form"
              onSubmit={this.handleSubmit}
            >
              <div className="Course-Package-Instructions">
                <h1>Instructions</h1>
                <ul className="list-disc pl-10">
                  <li>B2C users are subscribed to recurring subscriptions. They are auto-renewed at the end of each billing cycle.</li>
                  <li>B2B2C users are subscribed to recurring subscriptions. Their subscriptions are set to cancel at period end to avoid auto-renew at the end of billing cycle.</li>
                  <li>Make sure different attributes of this Course Package match with the relevant Stripe Plans.</li>
                  <li>If you want to discontinue this Course Package, just remove it from the sale but don't modify it if there are active subscriptions in it.</li>
                </ul>
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Package Name</label>
                <InputBox
                  className="InputBox-LogInBox"
                  value={this.state.name}
                  placeholder="Name"
                  onChange={this.handleInputChange('name')} />
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Price</label>
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>The total price of the Course Package.</li>
                  <li>It is displayed to users when they want to purchase.</li>
                  <li>For B2B2C users, there is a coupon code in their license that they should use to purchase on licensed price.</li>
                </ul>
                <ReactNumberFormat
                  className="InputBox-LogInBox"
                  value={this.state.price}
                  placeholder="Price"
                  onValueChange={values => this.setState({ price: values.floatValue })} 
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  thousandSeparator={true}/>
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Renewal Price</label>
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>For B2C users, this price will be used in the auto-renew reminder email and to display renewal cost on subscription page.</li>
                </ul>
                <ReactNumberFormat
                  className="InputBox-LogInBox"
                  value={this.state.renewal_price}
                  placeholder="Renewal Price"
                  onValueChange={values => this.setState({ renewal_price: values.floatValue })}
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  thousandSeparator={true}/>
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Stripe Initial Plan ID:</label>
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>For B2C and B2B2C users, this price is used to charge the difference of the original price and renewal price for the first time while subscribing a user in auto-renew.</li>
                  <li>It should point to the relevant Stripe One-Time Price ID.</li>
                </ul>
                <InputBox
                  className="InputBox-LogInBox"
                  value={this.state.stripe_initial_plan_id}
                  placeholder="Stripe Initial Plan ID"
                  onChange={this.handleInputChange('stripe_initial_plan_id')}
                  disabled={this.hasActiveSubscriptions()}
                />
              </div>

              <div
                className="CreationForms-InputBox">
                {
                  this.props.coursePackage
                    ? <label>{`Stripe Recurring Plan ID: (Active Subscriptions: ${this.props.coursePackage.active_recurring_subscriptions})`}</label>
                    : <label>Stripe Recurring Plan ID</label>
                }
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>For B2C and B2B2C users, this price is used to subscribe a user in auto-renew price but for B2B2C users, auto-renew is set to cancel at period end.</li>
                  <li>It should point to the relevant Stripe Recurring Price ID.</li>
                </ul>
                <InputBox
                  className="InputBox-LogInBox"
                  value={this.state.stripe_recurring_plan_id}
                  placeholder="Stripe Recurring Plan ID"
                  onChange={this.handleInputChange('stripe_recurring_plan_id')}
                  disabled={this.hasActiveSubscriptions()}
                />
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Subscription length in months</label>
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>Should match with the billing period of relevant Stripe Recurring Plan.</li>
                  <li>For example for yearly Stripe Recurring Plan this value should be 12.</li>
                </ul>
                <InputBox
                  className="InputBox-LogInBox"
                  value={this.state.length}
                  placeholder="Length of Subscription in Months"
                  onChange={this.handleInputChange('length')}
                  type="number"
                  disabled={this.hasActiveSubscriptions()}
                />
              </div>

              <div
                className="CreationForms-InputBox">
                <label>Renew Reward Courses</label>
                <ul className="Course-Package-Field-Instructions list-disc pl-10">
                  <li>Courses that should be given to a user as a reward on renewing this Course Package.</li>
                  <li>For example on renewing Level 1 give Level 2 and Level 3 as a reward.</li>
                </ul>
                <AutoComplete
                  className="Renew-Reward-Field"
                  options={this.buildSelectableCourses()}
                  defaultValue={this.buildSelectedCourses()}
                  onChange={(selected_options) => this.selectCourses(selected_options)}
                  isMulti={true}
                />
              </div>

              <div className="CreationForms-InputBox">
                <label>Tag</label>
                <InputBox
                  className="InputBox-LogInBox"
                  value={this.state.tag}
                  placeholder="Tag"
                  onChange={this.handleInputChange('tag')}
                />
              </div>

              <div className="CreationForms-InputBox">
                <label>Description</label>
                <div className="CreationForms-ReactQuill">
                  <ReactQuill
                    modules={QuillModules}
                    value={this.state.description}
                    onChange={this.handleQuillChange('description')}
                  />
                </div>
              </div>

              <div
                className="CreationForms-Button">
                <ButtonBox
                  className="CreationDashboards-ButtonBox"
                  text="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditCoursePackage;
