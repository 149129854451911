import axios from './AxiosUtil';

export const createTextContent = (textContent) => {
  return axios.post(`/api/text_contents`, { text_content: textContent }).then(
    res => res.data
  );
};

export const editTextContent = (textContent) => {
  return axios.patch(`/api/text_contents/${textContent.id}`, { text_content: textContent }).then(
    res => res.data
  );
};

export const deleteTextContent = textContentId => {
  return axios.delete(`/api/text_contents/${textContentId}`).then(
    res => res.data
  );
}

export const uploadAudio = (formData) => {
  return axios({
    method: "PATCH",
    url: `/api/text_contents/${formData.get("text_content[id]")}`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  );
};