import { connect } from 'react-redux';
import CanopyB2BAdminDashboard from './CanopyB2BAdminDashboard';
import { updateAccount, updatePhoto, updatePassword } from '../../../Actions/SessionActions';

const mapStateToProps = state => ({
    currentUser: state.session.currentUser,
});

const mapDispatchToProps = dispatch => ({
    updateAccount: user => dispatch(updateAccount(user)),
    updatePhoto: formData => dispatch(updatePhoto(formData)),
    updatePassword: user => dispatch(updatePassword(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanopyB2BAdminDashboard);
