import { connect } from 'react-redux';

import Tutorial from './Tutorial';

import { updateProfileCompletion } from '../../../Actions/ProfileCompletionActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  profileCompletion: state.userEntities.profileCompletion,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfileCompletion: (profileCompletion) => dispatch(updateProfileCompletion(profileCompletion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
