import {
  RECEIVE_B2B_ADMIN_CREDENTIAL_STATS,
  RECEIVE_B2B_ADMIN_CREDENTIAL_STAT_ERRORS,
  CLEAR_B2B_ADMIN_CREDENTIAL_STATS
  } from '../../../Actions/Stats/B2BAdmin/CredentialActions';

const CredentialsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_B2B_ADMIN_CREDENTIAL_STATS:
      return Object.assign({}, state, action.payload);
    case CLEAR_B2B_ADMIN_CREDENTIAL_STATS:
      return Object.assign({}, state, { [action.key]: {} });
    default:
      return state;
  }
}

export default CredentialsReducer;
