import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import EditUser from '../../../Forms/EditUser/EditUser';
import UserPreference from '../UserPreference/UserPreference';
import WorkPlace from '../WorkPlace/WorkPlace';
import Lingo from '../Lingo/Lingo';
import Pledges from '../Pledge/Pledges';
import Subscriptions from '../Subscriptions/Subscriptions';
import ProfileWingsCompletion from '../../../Composed/ProfileCompletion/ProfileWingsCompletion';
import UserStreaksContainer from '../Engage/UserStreaks/UserStreaksContainer';
import TutorialContainer from '../../../Presentational/Tutorial/TutorialContainer';

import { isStudent } from '../../../../Util/Helper/UserWorkplaceUtil';

import './Profile.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  profileCompletion: state.userEntities.profileCompletion,
});

const mapDispatchToProps = (dispatch) => ({});

const Profile = ({ currentUser, profileCompletion, updateAccount, updatePhoto, updatePassword }) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [showProfileWingsModal, setShowProfileWingsModal] = useState(false);

  const isNormalUser = isStudent(currentUser);
  const tabsData = [
    { position: 1, name: 'GENERAL INFORMATION', paramName: 'general-information', tourClass: 'Tour-Step-Profile-General-Info-Tab', completed: (profileCompletion?.basic_info && profileCompletion?.workplace_info) },
    { position: 2, name: 'LANGUAGE BACKGROUND', paramName: 'my-languages', tourClass: 'Tour-Step-Profile-My-Languages-Tab', completed: profileCompletion?.lingo_info },
    { position: 3, name: 'MY PROGRESS', paramName: 'my-progress', tourClass: 'Tour-Step-Profile-My-Progress-Tab', completed: profileCompletion?.pledge_info },
  ];
  const queryString = new URLSearchParams(useLocation().search);
  const history = useHistory();

  useEffect(() => {
    if (queryString.has('tab')) {
      const foundTab = tabsData.find((tabData) => tabData.paramName === queryString.get('tab'));
      setTabIndex(foundTab?.position || 1);
    }
  }, [queryString, tabsData]);

  const renderTabContent = () => {
    switch (tabIndex) {
      case 1:
        return (
          <div className="General-Information-Container">
            {isNormalUser && currentUser.streakConfig && <UserStreaksContainer />}

            <div className="Sides">
              <div className="Left-Side">
                <EditUser
                  currentUser={currentUser}
                  updateAccount={updateAccount}
                  updatePhoto={updatePhoto}
                  updatePassword={updatePassword}
                  displayProfileWingsModal={() => setShowProfileWingsModal(true)}
                />

                <UserPreference currentUser={currentUser} profileCompletion={profileCompletion} />
              </div>

              {
                isNormalUser &&
                <div className="Right-Side">
                  <WorkPlace
                    currentUser={currentUser}
                    displayProfileWingsModal={() => setShowProfileWingsModal(true)}
                  />
                </div>
              }
            </div>
          </div>
        );
      case 2:
        return (
          <div className="Language-Background-Container">
            <Lingo
              currentUser={currentUser}
              displayProfileWingsModal={() => setShowProfileWingsModal(true)}
            />
          </div>
        );
      case 3:
        return (
          <div className="My-Progress-Container">
            <div className="Pledges-Container">
              <Pledges
                currentUser={currentUser}
                displayProfileWingsModal={() => setShowProfileWingsModal(true)}
              />
            </div>

            <div className="Subscriptions-Container">
              <Subscriptions />
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }

  return (
    <div className="Profile-Container">
      <TutorialContainer />
      {
        isNormalUser &&
        <div className="Tabs">
          {
            tabsData.map((tabData) => (
              <div
                key={tabData.position}
                className={`Tab ${tabIndex === tabData.position ? 'active' : ''} ${tabData.tourClass}`}
                onClick={() => {
                  setTabIndex(tabData.position);
                  history.replace({
                    search: (new URLSearchParams({ tab: tabData.paramName })).toString()
                  });
                }}
              >
                <span className="Name">{tabData.name}</span>
                {
                  !tabData.completed && <span className="Required-Asterisk">*</span>
                }
              </div>
            ))
          }
        </div>
      }


      <div className="Tab-Content">
        {renderTabContent()}
      </div>

      <div className="Profile-Wings-Modal">
        {
          showProfileWingsModal && <ProfileWingsCompletion hideProfileWingsModal={() => setShowProfileWingsModal(false)} />
        }
      </div>
    </div>
  )
}

Profile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
  updatePhoto: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
