import { connect } from 'react-redux';

import AdminDashboardLicenses from './AdminDashboardLicenses';
import { getLicenses, removeLicense } from '../../../../Actions/LicenseActions';


const mapStateToProps = (state) => ({
    licenses: state.admin.licenses,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLicenses: () => dispatch(getLicenses()),
    deleteLicense: licenseId => dispatch(removeLicense(licenseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardLicenses);
