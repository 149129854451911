import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LicenseBox from '../../../Presentational/LicenseBox/LicenseBox';
import Loader from '../../../Composed/Loader/Loader';

const AdminDashboardLicenses = ({ licenses, fetchLicenses, deleteLicense }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLicenses().then(() => setLoading(false));
    }, [fetchLicenses]);

    const handleLicenseRender = () => {
        const licenseList = Object.values(licenses).sort((a, b) => (a.access_code > b.access_code) ? 1 : ((b.access_code > a.access_code) ? -1 : 0)).map(
            license => <LicenseBox key={license.id} license={license} deleteLicense={deleteLicense} />,
        );

        return licenseList;
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="AdminDashboard-Pages">
            <div className="License-List">
                {handleLicenseRender()}
            </div>
        </div>
    );
};

AdminDashboardLicenses.propTypes = {
    licenses: PropTypes.objectOf(PropTypes.object).isRequired,
    fetchLicenses: PropTypes.func.isRequired,
    deleteLicense: PropTypes.func.isRequired,
};

export default AdminDashboardLicenses;
