import { isBusinessLicense, isIndividualLicense } from './LicenseUtil';

export const isTEAdmin = (user) => (
  user.user_type === 'TE.Admin'
);

export const isB2BAdmin = (user) => (
  user.user_type === 'B2B.Admin'
);

export const isStudent = (user) => (
  ['B2B.User', 'B2C.User'].includes(user.user_type)
);

export const isB2C = (user, workplace) => (
  isStudent(user) && (workplace.institution_id === 1) && !workplace.license_id
);

export const isB2B = (user, workplace) => (
  isStudent(user) && (workplace.institution_id > 1) && workplace.license_id
);

export const isB2BBusiness = (user, workplace) => (
  isB2B(user, workplace) && (isBusinessLicense(workplace.license_type))
);

export const isB2BIndividual = (user, workplace) => (
  isB2B(user, workplace) && (isIndividualLicense(workplace.license_type))
);

export const isProfileCompleted = (profileCompletion) => (
  profileCompletion?.basic_info &&
  profileCompletion?.workplace_info &&
  profileCompletion?.lingo_info &&
  profileCompletion?.pledge_info
);

export const isUserPreferenceEnabled = (user, name) => (
  user.userPreferences.find(userPref => userPref.preference.name === name)?.enabled
);

export const finalizePendingActionsAndLogout = (history, logout) => {
  // redirect to home page to sync seat-times and finalize any other pending actions.
  history.push('/');

  // logout the user.
  setTimeout(() => { logout({ returnTo: window.location.origin }) }, 1.5 * 1000);
}

export const isValidBirthdate = (birthdate) => {
  const inputDate = new Date(birthdate);
  inputDate.setHours(0, 0, 0, 0);

  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  minDate.setFullYear(minDate.getFullYear() - 112);

  const maxDate = new Date();
  maxDate.setHours(0, 0, 0, 0);
  maxDate.setFullYear(maxDate.getFullYear() - 12);

  return inputDate >= minDate && inputDate <= maxDate;
}
