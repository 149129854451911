import React, { useState } from 'react';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

const DialogHintWidget = ({ children }) => {
    const [hidden, setHidden] = useState(true);
    return (
        <>
            <ButtonBox
                className="ButtonBox-AudioPlayButton Hint-Control"
                onClick={() => setHidden(!hidden)}
                text={hidden ? 'Reveal hint' : 'Hide hint'}
                tag="h3"
            />
            {!hidden && children}
        </>
    );
};

export default DialogHintWidget;
