import React, { Component } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

class CreateEditFillInTheBlank extends Component {
  state = {
    submitting: false,
    text: this.props.question ? this.props.question.text : '',
    hint: this.props.question ? this.props.question.hint : '',
    answers_attributes: this.props.question ? JSON.parse(JSON.stringify(this.props.question)).answers : [],
  }

  textArea = React.createRef();

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleAnswerInputChange = (idx) => {
    return (e) => {
      const answers_attributes = [...this.state.answers_attributes];
      answers_attributes[idx].text = e.currentTarget.value;
      this.setState({
        answers_attributes,
      })
    }
  }

  handleAddAnswer = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const textAreaNode = this.textArea.current;

      let nextText = `${
        prevState.text.slice(0, textAreaNode.selectionStart)
      } __blank__ ${
        prevState.text.slice(textAreaNode.selectionEnd, prevState.text.length)
      }`.replace(/\s+/g, ' ').trim();

      let indexToAssign = prevState.text.slice(0, textAreaNode.selectionStart).match(/__blank__|__removed__/g)?.length || 0;
      let nextAnswerAttributes = [...prevState.answers_attributes];
      nextAnswerAttributes.splice(indexToAssign, 0, { text: '', index: indexToAssign });
      nextAnswerAttributes.map((answer, idx) => answer.index = idx);

      return { text: nextText, answers_attributes: nextAnswerAttributes }
    });
  }

  handleRemoveAnswer = idx => (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      let nextText = '';
      let nextAnswerAttributes = [...prevState.answers_attributes];
      let occurrence = -1;

      if (nextAnswerAttributes[idx].id) {
        nextText = prevState.text.replace(/__blank__|__removed__/g, (match) => {
          occurrence++;
          return occurrence === idx ? '__removed__' : match
        }).replace(/\s+/g, ' ').trim();
        nextAnswerAttributes[idx]._destroy = true;
      } else {
        nextText = prevState.text.replace(/__blank__|__removed__/g, (match) => {
          occurrence++;
          return occurrence === idx ? '' : match
        }).replace(/\s+/g, ' ').trim();
        nextAnswerAttributes.splice(idx, 1);
      }

      nextAnswerAttributes.map((answer, idx) => answer.index = idx);

      return { text: nextText, answers_attributes: nextAnswerAttributes }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const question = {};

    question.hint = this.state.hint;
    question.text = this.state.text.replace(/__removed__/g, '').replace(/\s+/g, ' ').trim();

    let finalIndex = -1;
    let finalAnswerAttributes = JSON.parse(JSON.stringify(this.state.answers_attributes));
    finalAnswerAttributes.map((answer, idx) => {
      if (answer._destroy) {
        answer.index = -1;
      } else {
        finalIndex++;
        answer.index = finalIndex;
      }
    });
    question.answers_attributes = finalAnswerAttributes;

    question.number_of_answers = this.state.answers_attributes.filter(answer => !answer._destroy).length;

    if(this.props.section) {
      question.section_id = this.props.section.id;
      question.question_type = this.props.section.section_type;
    }

    if (this.props.question) {
      question.id = this.props.question.id;
    }

    this.setState({ submitting: true });
    this.props.submitQuestion(question).then(() => {
      this.setState({ submitting: false });
      this.props.closeModal();
    });
  }

  handleAnswersRender() {
    return this.state.answers_attributes.map(
      (answer, idx) => (
        <div
          className={`CreationForms-InputBox ${answer._destroy ? 'Removed' : ''}`}
          key={idx}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.answers_attributes[idx].text}
            placeholder={`Answer ${idx + 1}`}
            onChange={this.handleAnswerInputChange(idx)}
            disabled={answer._destroy}
          />

          <ButtonBox
            className="ButtonBox-EditQuestions Remove-Answer"
            text={<i className="far fa-trash-alt" />}
            onClick={this.handleRemoveAnswer(idx)}
            disabled={answer._destroy}
          />
        </div>
      )
    );
  }

  render() {
    return (
      <form className="CreationForms-Form" onSubmit={this.handleSubmit}>
        {this.state.submitting && <MirrorLoader message="Submitting..." />}

        <div className="CreationForms-InputBox">
          <p className="Note">
            <strong>Note:</strong> Don't manullay add/remove __blank__ and __removed__ placeholders, as they are managed automatically.
          </p>
          <textarea
            ref={this.textArea}
            className="TextAreaBox-CreationForms"
            value={this.state.text}
            placeholder="Text Here"
            onChange={this.handleInputChange('text')}
          />
        </div>

        <div className="CreationForms-AnswersBox">
          {this.handleAnswersRender()}

          <div className="CreationForms-Button">
            <ButtonBox
              className="CreationDashboards-ButtonBox Add-Answer"
              text="Insert A Blank"
              onClick={this.handleAddAnswer}
            />
          </div>
        </div>

        <div className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.hint}
            placeholder="Hint Optional"
            onChange={this.handleInputChange('hint')}
          />
        </div>

        <div className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit"
          />
        </div>
      </form>
    );
  }
}

export default CreateEditFillInTheBlank;
