import axios from './AxiosUtil';

export const createCharge = (token, coursePackageId, promoCodeId) => (
    axios.post('/api/charges', {
        source: token.id,
        card: token.card,
        course_package_id: coursePackageId,
        promo_code_id: promoCodeId,
    }).then(
        res => res.data,
    )
);

export const verifyCode = (code, coursePackageId) => (
    axios.post('/api/promo_code', {
        promo_code: code,
        course_package_id: coursePackageId,
    }).then(
        res => res.data,
    )
);
