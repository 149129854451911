import { connect } from 'react-redux';
import ActivityPage from './ActivityPage';

import { recordQuizAnswer, clearQuizProgresses, clearQuizStates } from '../../../Actions/QuizActions';
import { fetchActivity, submitActivityResponse } from '../../../Actions/ActivityActions';
import { clearSectionIndex, receiveSectionIndex } from '../../../Actions/NavigationActions';

const mapStateToProps = (state, { match }) => ({
  currentUser: state.session.currentUser,
  courseId: match.params.courseId,
  course: state.entities.courses[match.params.courseId],
  lessonId: match.params.lessonId,
  lesson: state.entities.lessons[match.params.lessonId],
  lessons: state.entities.lessons,
  quizProgress: state.statistics.quizProgresses[match.params.lessonId],
  activities: state.entities.activities
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivity: (lessonId) => dispatch(fetchActivity(lessonId)),
  recordQuizAnswer: (answer) => dispatch(recordQuizAnswer(answer)),
  clearQuizProgresses: () => dispatch(clearQuizProgresses()),
  clearQuizStates: () => dispatch(clearQuizStates()),
  clearSectionIndex: () => dispatch(clearSectionIndex()),
  receiveSectionIndex: (index) => dispatch(receiveSectionIndex(index)),
  submitActivityResponse: (activityResponseData) => dispatch(submitActivityResponse(activityResponseData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
