import React, { Component } from 'react';

import './CreateEditQuiz.css';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';


class CreateEditQuiz extends Component {
  state = this.props.quiz ?
  {
    name: this.props.quiz.name,
  }
  :
  {
    name: "",
  }

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const quiz = Object.assign({}, this.state);

    if (this.props.lesson) {
      quiz.lesson_id = this.props.lesson.id;
    }

    if (this.props.quiz) {
      quiz.id = this.props.quiz.id;
    }

    this.props.submitQuiz(quiz).then(
      this.props.closeModal()
    );
  }

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.name}
            placeholder="Name"
            onChange={this.handleInputChange('name')} />
        </div>

        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditQuiz;