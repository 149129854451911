import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MoonLoader } from 'react-spinners';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { fetchUser, createUser } from '../../../Actions/Onboarding/UserActions';
import { createCharge, receiveOnboardingCharge } from '../../../Actions/Onboarding/ChargeActions';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './Process.scss';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (email) => dispatch(fetchUser(email)),
  createUser: (user) => dispatch(createUser(user)),
  createCharge: (charge) => dispatch(createCharge(charge)),
  receiveOnboardingCharge: (payload) => dispatch(receiveOnboardingCharge(payload))
});

const Process = ({
  user, fetchUser, createUser,
  password, passwordChange,
  card, coupon, stripe, cardNumberElement, createCharge, receiveOnboardingCharge,
  coursePackage,
  abort
}) => {
  const [loader, setLoader] = useState({ show: false, message: '' });
  const [askPassword, setAskPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLoader({ show: true, message: 'Processing!' });
    fetchUser(user.email.value).then(res => {
      if (res.payload) {
        if (res.payload.exist === true) {
          initiatePayment(res.payload);
        } else if (res.payload.exist === false) {
          setAskPassword(true);
          setLoader({ show: false, message: '' });
        }
      }
    });
  }, [fetchUser]);

  const initiateSignup = () => {
    setAskPassword(false);
    setLoader({ show: true, message: 'Creating Account!' });

    let userParams = {};
    Object.entries(user).map(([k, v], i) => userParams[k] = v.value);
    Object.entries(password).map(([k, v], i) => userParams[k] = v.value);

    createUser(userParams).then(res => {
      if (res.payload) {
        if (res.payload.exist === true) {
          initiatePayment(res.payload);
        } else if (res.payload.exist === false) {
          abort();
        }
      }
    });
  }

  const initiatePayment = async (iUser) => {
    setLoader({ show: true, message: 'Processing Payment!' });

    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: card.name.value,
      address_zip: card.zip.value,
      address_country: card.country.value
    });

    if (error) {
      receiveOnboardingCharge({ error: error.message });
      abort();
    } else {
      createCharge({
        user_id: iUser.id,
        course_package_id: coursePackage.id,
        source: token.id,
        card: token.card,
        coupon_code_id: coupon.data?.id
      }).then(res => {
        if (res.payload) {
          if (res.payload.success === true) {
            setSuccess(true);
            setLoader({ show: false, message: '' });
            window.fbq('track', 'Purchase', { currency: 'USD', value: coursePackage.price / 100 });
            gaEvent('onboarding_package_purchased', { currency: 'USD', course_package_name: coursePackage.name, value: (coursePackage.price / 100), affiliation: 'google' });
            window.uet_report_conversion('event', 'PRODUCT_PURCHASE', { ecomm_prodid: coursePackage.id, ecomm_pagetype: 'PURCHASE', revenue_value: (coursePackage.price / 100), currency: 'USD' });
          } else if (res.payload.success === false) {
            abort();
          }
        }
      });
    }
  }

  return (
    <div className="Process">
      {
        loader.show &&
        <div className="Loader">
          <div className="Message">{loader.message}</div>
          <MoonLoader color="#fff" />
        </div>
      }

      {
        askPassword &&
        <div className="Password-Modal modal">
          <div className="modal-content">
            <div className="Form">
              <div className="Head">
                <img className="Image" src="favicon.ico" />
                <h2 className="Title">Almost there!</h2>
                <p className="Desc">Create a password to continue checkout and log into your new account</p>
              </div>

              <div className="Info">
                <div className="Label">Email</div>
                <div className="Value">{user.email.value}</div>
              </div>

              <div className="Group">
                <label>Password</label>
                <input
                  type="password"
                  className={password.password.complete && password.password.error ? 'Error' : ''}
                  value={password.password.value}
                  onChange={(e) => passwordChange('password', e)}
                />
              </div>

              <div className="Group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className={password.confirm_password.complete && password.confirm_password.error ? 'Error' : ''}
                  value={password.confirm_password.value}
                  onChange={(e) => passwordChange('confirm_password', e)}
                />
              </div>

              {
                password.password.error &&
                <div className="Note">
                  Password should consist of 8 or more characters. Also, it should have a lower case letter, a upper case letter, a digit, and a special character (e.g. !@#$%^&*?~).
                </div>
              }

              <div className="Submit">
                <ButtonBox
                  className="Btn"
                  text="Continue"
                  disabled={!(password.password.complete && !password.password.error && password.confirm_password.complete && !password.confirm_password.error)}
                  onClick={() => initiateSignup()}
                />
              </div>
            </div>
          </div>
        </div>
      }

      {
        success &&
        <div className="Success-Modal modal">
          <div className="modal-content">
            <div className="Success">
              <div className="Logo">
                <i className="fa fa-check" />
              </div>
              <div className="Heading">
                <p>Payment Success!</p>
              </div>
              <div className="Desc">
                <p>
                  <a href={window.location.origin} target="_blank">Click here</a>&nbsp;
                  to get started! Your subscription to CanopyLearn will renew every {coursePackage.length} months. Please visit&nbsp;
                  <a href={`${window.location.origin}/#/account/profile?tab=my-progress&sec=subscriptions`} target="_blank">Canopy Profile</a>&nbsp;
                  to manage your settings.
                </p>
              </div>
              <div className="Close">
                <ButtonBox
                  className="Btn"
                  text="Close"
                  onClick={() => window.location = window.location.origin}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Process);
