import * as APIUtil
  from '../Util/CourseApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_COURSE_ERRORS = 'RECEIVE_COURSE_ERRORS';
export const RECEIVE_COURSES = 'RECEIVE_COURSES';
export const RECEIVE_COURSES_TREE = 'RECEIVE_COURSES_TREE';
export const RECEIVE_COURSE = 'RECEIVE_COURSE';
export const CLEAR_COURSE_ERRORS = 'CLEAR_COURSE_ERRORS';
export const CLEAR_COURSES = 'CLEAR_COURSES';

export const receiveCourses = payload => {
  return {
    type: RECEIVE_COURSES,
    payload,
  };
};

export const receieveCoursesTree = payload => {
  return {
    type: RECEIVE_COURSES_TREE,
    payload,
  };
};

export const receiveCourse = (payload, message) => ({
  type: RECEIVE_COURSE,
  payload,
  message
});

export const receiveErrors = errors => ({
  type: RECEIVE_COURSE_ERRORS,
  errors
});

export const clearCourseErrors = () => ({
  type: CLEAR_COURSE_ERRORS,
});

export const clearCourses = () => ({
  type: CLEAR_COURSES,
});

export const fetchCourses = () => dispatch => (
  APIUtil.fetchCourses().then(
    payload => dispatch(receiveCourses(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchCoursesTree = () => dispatch => (
  APIUtil.fetchCoursesTree().then(
    payload => dispatch(receieveCoursesTree(payload)),
    errors => ErrorResponseUtil(errors),
  )
);

export const createCourse = (course) => dispatch => (
  APIUtil.createCourse(course).then(
    payload => dispatch(receiveCourse(payload, "Course Created")),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editCourse = (course) => dispatch => (
  APIUtil.editCourse(course).then(
    payload => dispatch(receiveCourse(payload, "Course Updated")),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
