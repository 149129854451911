import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { fetchRenewProducts, clearCoursePackages } from '../../../Actions/CoursePackageActions';
import { clearStripeFlashAndErrors } from '../../../Actions/StripeActions';
import { fetchSubscriptions } from '../../../Actions/SubscriptionActions';
import CoursePackageBox from '../../Presentational/CoursePackageBox/CoursePackageBox';
import TextBox from '../../Basic/TextBox/TextBox';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

const mapStateToProps = (state) => ({
    coursePackages: state.entities.coursePackages,
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
    subscribedCourses: state.dashboard.courses,
    subscriptions: state.access.subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRenewProducts: () => dispatch(fetchRenewProducts()),
    clearCoursePackages: () => dispatch(clearCoursePackages()),
    clearStripeFlashAndErrors: () => dispatch(clearStripeFlashAndErrors()),
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
});

const UserDashboardRenew = ({
    fetchRenewProducts,
    coursePackages,
    courses,
    currentUser,
    clearCoursePackages,
    subscribedCourses,
    clearStripeFlashAndErrors,
    fetchSubscriptions,
    subscriptions,
}) => {
    useEffect(() => {
        clearCoursePackages();
        fetchRenewProducts();
        clearStripeFlashAndErrors();
        fetchSubscriptions();
    }, [fetchRenewProducts, clearCoursePackages, clearStripeFlashAndErrors, fetchSubscriptions]);

    const handleCoursePackageRender = () => (
        Object.values(coursePackages).map(
            coursePackage => (
                <CoursePackageBox
                    key={coursePackage.id}
                    courses={courses}
                    coursePackage={coursePackage}
                    currentUser={currentUser}
                />
            ),
        )
    );

    const verifyRenewElegibility = () => {
        const current = Object.values(subscriptions);

        for (let i = 0; i < current.length; i++) {
            const element = current[i];
            if (Date.parse(element.expires_at) < Date.now()) {
                return true;
            }
        }

        return false;
    };

    if (!Object.keys(coursePackages).length) {
        return <div />;
    }

    return (
        <div className="UserDashboardCoursePackages">
            {
                verifyRenewElegibility()
                    ? (
                        <>
                            <TextBox
                                text="Renew Your Access to Canopy Learn"
                                tag="h1"
                                style={{
                                    color: COLORS.Blue,
                                    marginTop: '50px',
                                }}
                            />

                            <div className="UserDashboardCoursePackages-Packages__container">
                                {
                                    handleCoursePackageRender()
                                }
                            </div>
                        </>
                    )
                    : (
                        <TextBox
                            text="You Are Not Elegible For Renewals"
                            tag="h1"
                            style={{
                                color: COLORS.Blue,
                                marginTop: '50px',
                            }}
                        />
                    )

            }
        </div>
    );
};

UserDashboardRenew.propTypes = {
    fetchRenewProducts: PropTypes.func.isRequired,
    coursePackages: PropTypes.objectOf(PropTypes.object).isRequired,
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({
        trial: PropTypes.bool,
    }).isRequired,
    clearCoursePackages: PropTypes.func.isRequired,
    subscribedCourses: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardRenew);
