import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import Loader from '../../../Composed/Loader/Loader'
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearLearnStats, fetchMyComments } from '../../../../Actions/Stats/Users/LearnActions';

const mapStateToProps = (state) => ({
  comments: Object.entries(state.stats.users.learns?.myComments || {})
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchMyComments: () => dispatch(fetchMyComments())
});

const MyComments = ({ comments, clearLearnStats, fetchMyComments, contentWidth }) => {
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({ attribute: 'addedAt', direction: 1 });
  const [tab, setTab] = useState(0);

  useEffect(() => {
    clearLearnStats('myComments');
    fetchMyComments().then(
      res => setLoading(false)
    );
  }, [fetchMyComments]);

  const applySort = (attribute) => {
    let newSort = JSON.parse(JSON.stringify(sort));

    if (newSort.attribute === attribute) {
      newSort.direction *= -1;
    } else {
      newSort.attribute = attribute;
      newSort.direction = 1;
    }

    setSort(newSort);
  }

  const sortAngle = (attribute) => (
    sort.attribute === attribute && sort.direction === 1 ? 'up' : 'down'
  )

  const sortedStats = () => {
    let commentStats = JSON.parse(JSON.stringify(comments[tab][1]));

    if (sort.attribute === 'addedAt') {
      commentStats.sort((a, b) => (new Date(a[sort.attribute])).getTime() > (new Date(b[sort.attribute])).getTime() ? (sort.direction * -1) : sort.direction);
    } else if (sort.attribute !== '') {
      commentStats.sort((a, b) => a[sort.attribute]['name'] > b[sort.attribute]['name'] ? (sort.direction * -1) : sort.direction);
    }

    return commentStats;
  }

  const buildSlideLink = (comment) => {
    let nextToq = comment.toq.type === 'Topic' ? `topics/${comment.toq.id}` : 'quiz';
    let nextSec = comment.slide.position - 1;
    let copn    = comment.toq.type === 'Topic' ? '&copn=true' : ''

    let link = `/learn/courses/${comment.course.id}/lessons/${comment.lesson.id}/?nextToq=${nextToq}&nextSec=${nextSec}${copn}`;

    return (
      <Link to={link}>View</Link>
    )
  }

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
      />
    </Link>
  );

  const renderMycomments = () => (
    <div
      className="Content Dynamic-Width Scrollable-Table"
      style={{
        width: `${contentWidth}px`
      }}
    >
      <table className="Table">
        <thead>
          <tr>
            <th onClick={() => applySort('lesson')}>
              Lesson > Topic > Slide <i className={`fa fa-angle-${sortAngle('lesson')}`} />
            </th>
            <th>Comment</th>
            <th onClick={() => applySort('addedAt')}>
              Date Added <i className={`fa fa-angle-${sortAngle('addedAt')}`} />
            </th>
            <th onClick={() => applySort('replies')}>
              Replies <i className={`fa fa-angle-${sortAngle('replies')}`} />
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            sortedStats().map((comment, idx) => (
              <tr key={`my-comment-${idx}`}>
                <td>{comment.lesson.name}/{comment.toq.name}/{comment.slide.name}</td>
                <td>{(comment.text.length > 60) ? `${comment.text.slice(0, 60)}...` : comment.text}</td>
                <td>{comment.addedAt ? dateFormat(comment.addedAt, 'mm/dd/yyyy') : 'N/A'}</td>
                <td>{comment.replies} replies</td>
                <td>
                  <span className="Link">{buildSlideLink(comment)}</span>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )

  const renderTabs = () => {
    if (comments.length === 0)
      return null;

    return (
      <div className="Level-Btn-Holder">
        {
          comments.map(([k, v], idx) => (
            <div
              key={`tab-${idx}`}
              children={`Level ${k}`}
              className={`Btn ${tab === idx ? 'Active' : ''}`}
              onClick={() => setTab(idx)}
            />
          ))
        }
      </div>
    )
  }

  const renderContent = () => {
    if (loading)
      return <Loader />;

    if (hasComments())
      return renderMycomments();

    return renderEmpty();
  }

  const hasComments = () => (
    comments.find(([k, v]) => v.length > 0) ? true : false
  )

  return (
    <div className="My-Comments-Container">
      <div className="Head">
        <p className="Title">Comments</p>
        {hasComments() && renderTabs()}
      </div>
      {renderContent()}
    </div>
  )
}

MyComments.propTypes = {
  comments: PropTypes.object.isRequired,
  contentWidth: PropTypes.number.isRequired,
  clearLearnStats: PropTypes.func.isRequired,
  fetchMyComments: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComments);
