import React from 'react';

import MultiButtonChoiceBox from './MultiButtonChoiceBox';

const MultiButtonChoiceContainer = ({ section, newProps }) => {
  return (
    <div className="MultiButtonChoiceContainer">
      {
        section.questions.map(
          (_, index) =>
            <MultiButtonChoiceBox
              key={index}
              currentQuestionIndex={index}
              {...newProps}
            />
        )
      }
    </div>
  );
}

export default MultiButtonChoiceContainer;
