const singleAnswerById      = ['multiple_choice'];
const singleAnswerByText    = ['matching', 'true_or_false']
const multipleAnswersByText = ['fill_in_the_blank', 'drop_down', 'drag_and_drop', 'word_scramble'];
const multipleAnswersById   = ['multiple_answer'];


export const hasAnsweredAll = (currentSection, currentQuestion, isFullListingSection, quizProgress) => {
  let notAnsweredQuestion = null;

  if (singleAnswerById.includes(currentSection?.section_type)) {
    notAnsweredQuestion = checkSingleAnswerById(currentSection, currentQuestion, isFullListingSection, quizProgress)
  } else if (singleAnswerByText.includes(currentSection?.section_type)) {
    notAnsweredQuestion = checkSingleAnswerByText(currentSection, currentQuestion, isFullListingSection, quizProgress)
  } else if (multipleAnswersByText.includes(currentSection?.section_type)) {
    notAnsweredQuestion = checkMultipleAnswersByText(currentSection, currentQuestion, isFullListingSection, quizProgress)
  } else if (multipleAnswersById.includes(currentSection?.section_type)) {
    notAnsweredQuestion = checkMultipleAnswersById(currentSection, currentQuestion, isFullListingSection, quizProgress)
  }

  return notAnsweredQuestion ? false : true
}

const checkSingleAnswerById = (currentSection, currentQuestion, isFullListingSection, quizProgress) => (
  isFullListingSection
  ? currentSection.questions.find(q => !Object.values(quizProgress?.[currentSection.id]?.[q.id] || {})?.[0]?.id)
  : !Object.values(quizProgress?.[currentSection.id]?.[currentQuestion.id] || {})?.[0]?.id
)

const checkSingleAnswerByText = (currentSection, currentQuestion, isFullListingSection, quizProgress) => (
  isFullListingSection
  ? currentSection.questions.find(q => !Object.values(quizProgress?.[currentSection.id]?.[q.id] || {})?.[0]?.text)
  : !Object.values(quizProgress?.[currentSection.id]?.[currentQuestion.id] || {})?.[0]?.text
)

const checkMultipleAnswersByText = (currentSection, currentQuestion, isFullListingSection, quizProgress) => {
  if (isFullListingSection) {
    return currentSection.questions.find(q => {
        let qData = quizProgress?.[currentSection.id]?.[q.id] || {}

        return (Object.keys(qData).length !== q.answers.length) || (Object.values(qData)?.find(a => !a.text))
      }
    )
  } else {
    let qData = quizProgress?.[currentSection.id]?.[currentQuestion.id] || {}

    return (Object.keys(qData).length !== currentQuestion.answers.length) || (Object.values(qData)?.find(a => !a.text))
  }
}

const checkMultipleAnswersById = (currentSection, currentQuestion, isFullListingSection, quizProgress) => (
  isFullListingSection
  ? currentSection.questions.find(q => !Object.values(quizProgress?.[currentSection.id]?.[q.id] || {})?.find(a => a.id))
  : !Object.values(quizProgress?.[currentSection.id]?.[currentQuestion.id] || {})?.find(a => a.id)
)
