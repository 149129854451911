import React, { Fragment }
  from 'react';

import './QuestionBox.css';
import AudioPlayButton
  from '../AudioPlayButton/AudioPlayButton';

const QuestionBox = ({ question, children, creation = false }) => {

  return (
    <div className="QuestionBox-Question-Bar">
      {
        question.audio_url &&
        <AudioPlayButton src={ question.audio_url } downloadable={creation} />
      }
      { children }
    </div>
  );
};

export default QuestionBox;
