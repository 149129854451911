import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import './SortingBox.scss';
import { shuffle } from '../../../Util/Helper/GenericUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import DragElement from '../DragAndDropBox/DragElement';
import DragPreview from '../DragAndDropBox/DragPreview';
import DropArea from '../DragAndDropBox/DropArea';

const mapStateToProps = state => ({
    quizStates: state.statistics.quizStates,
});

const SortingBox = ({
    quizStates,
    recordQuizState,
    section,
    questions,
    lessonId,
    recordQuizAnswer,
    checkAnswers,
    revealAnswers,
    quizProgress,
}) => {
    const [isLengthyDrag, setIsLengthyDrag] = useState(false);

    useEffect(() => {
        // This creates a list of all the options to be used as a reference and will be shuffled to
        // create the drag pool. The options are taken from all the answers from all the questions.
        if (!quizStates[section.id]) {
            const optionsList = [];
            questions.forEach(
                (question) => {
                    question.answers.forEach(
                        (answer) => {
                            optionsList.push({
                                value: answer.text,
                                questionId: question.id,
                                index: answer.index,
                                answerId: answer.id,
                            });
                        },
                    );
                },
            );

            // Shuffling the options.
            let alreadyGivenAnswers = [];
            Object.values(quizProgress?.[section.id] || {}).map(givenAnswers => (
                Object.values(givenAnswers || {}).map(givenAnswer => alreadyGivenAnswers.push(givenAnswer.text))
            ));
            let optionsArray = [];
            shuffle(optionsList.slice(0)).map(
                (option, idx) => {
                    if (alreadyGivenAnswers.includes(option.value)) {
                        optionsArray.push(null);
                        return
                    }
                    optionsArray.push({
                        answer: option.value,
                        origin: {
                            section: 'options',
                            index: idx,
                        }
                    })
                }
            )

            // This creates the questions part of the state which will be mapped to the drop zones.
            const questionsArrays = {};
            questions.forEach(question => questionsArrays[question.id] = []);
            optionsList.map(
                (option, idx) => {
                    if (!quizProgress?.[section.id]?.[option.questionId]?.[option.answerId]?.text) {
                        return
                    }
                    questionsArrays[option.questionId].push({
                        answer: option.value,
                        origin: {
                            section: 'questions',
                            id: option.questionId
                        }
                    })
                }
            )

            // Finally saving the whole thing to the redux store.
            recordQuizState({
                [section.id]: {
                    optionsList,
                    options: optionsArray,
                    questions: questionsArrays,
                },
            });
        }
    }, [recordQuizState, quizStates[section.id]]);

    useEffect(() => {
        function handleScroll() {
            const elementProperties = document.getElementsByClassName('SortingBox')?.[0]?.getBoundingClientRect();

            if (elementProperties) {
                setIsLengthyDrag((elementProperties.top - 65) <= 0 && (elementProperties.bottom - 200 >= 0));
            }
        }

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    const fillAnswers = () => {
        Object.keys(quizStates[section.id].questions).map((qId) => {
            quizStates[section.id].questions[qId].map((opt) => {
                if (opt.origin.id != quizStates[section.id].optionsList.find(ol => ol.value === opt.answer).questionId)
                    fillAnswer(opt);
            });
        });

        quizStates[section.id].options.map((opt, i) => {
            if (opt)
                fillAnswer(opt);
        });
    }

    const fillAnswer = (opt) => {
        opt['destination'] = {
            section: 'questions',
            id:      quizStates[section.id].optionsList.find(ol => ol.value === opt.answer).questionId
        }

        moveOption(opt);
    }

    const moveOption = answerData => {
        gaEvent('sorting_box_option_move', { section_id: section?.id });

        if (
            (answerData.origin.section      === 'questions') &&
            (answerData.destination.section === 'questions') &&
            (answerData.origin.id === answerData.destination.id)
        ) {
            return;
        }

        // There are 4 cases for draging the options.
        // Dragging from the 'options' pool to the 'answers' zones.
        // Dragging the 'options' to other slots on the 'options' pool.
        // Dragging the options from the 'questions' zones back to the 'options' pool.
        // Dagging the options from one 'answer' zone to another 'answer' zone.
        if (answerData.destination.section === 'questions' && answerData.origin.section === 'options') {
            // checking if answer is correct and saving it to quizProgress.
            const movedToQuestion = questions.find(questionData => questionData.id === answerData.destination.id);
            const movedOption = quizStates[section.id].optionsList.find(o => o.value === answerData.answer);
            const answer = {
                [lessonId]: {
                    [section.id]: {
                        [movedToQuestion.id]: {
                            [movedOption.answerId]: {
                                text: answerData.answer,
                                is_correct: movedToQuestion.answers.map(a => a.text).includes(answerData.answer),
                            },
                        },
                    },
                },
            };

            recordQuizAnswer(answer);

            const { options, questions: questionsArrays } = quizStates[section.id];
            options[answerData.origin.index] = null;
            answerData.origin = answerData.destination;
            delete answerData.destination;
            questionsArrays[answerData.origin.id].push(answerData);

            recordQuizState({
                [section.id]: {
                    options,
                    questions: questionsArrays,
                },
            });
        } else if (answerData.destination.section === 'questions' && answerData.origin.section === 'questions') {
            // checking if answer is correct and saving it to quizProgress.
            const movedToQuestion = questions.find(questionData => questionData.id === answerData.destination.id);
            const movedOption = quizStates[section.id].optionsList.find(o => o.value === answerData.answer);
            const answer = {
                [lessonId]: {
                    [section.id]: {
                        [movedToQuestion.id]: {
                            [movedOption.answerId]: {
                                text: answerData.answer,
                                is_correct: movedToQuestion.answers.map(a => a.text).includes(answerData.answer),
                            }
                        },
                        [answerData.origin.id]: {
                            [movedOption.answerId]: {
                                text: '',
                                is_correct: false,
                            },
                        },
                    },
                },
            };

            recordQuizAnswer(answer);

            // performing the move ans saving to to the redux store
            const { options, questions: questionsArrays } = quizStates[section.id];
            const spliceIndex = questionsArrays[answerData.origin.id].map(answerObj => answerObj.answer).indexOf(answerData.answer);
            questionsArrays[answerData.origin.id].splice(spliceIndex, 1);
            answerData.origin = answerData.destination;
            delete answerData.destination;
            questionsArrays[answerData.origin.id].push(answerData);

            recordQuizState({
                [section.id]: {
                    options,
                    questions: questionsArrays,
                },
            });
        } else if (answerData.destination.section === 'options' && answerData.origin.section === 'options') {
            // performing the move ans saving to to the redux store
            const { options, questions: questionsArrays } = quizStates[section.id];
            options[answerData.origin.index] = null;
            answerData.origin = answerData.destination;
            delete answerData.destination;
            options[answerData.origin.index] = answerData;

            recordQuizState({
                [section.id]: {
                    options,
                    questions: questionsArrays,
                },
            });
        } else if (answerData.destination.section === 'options' && answerData.origin.section === 'questions') {
            // checking if answer is correct and saving it to quizProgress.
            const movedOption = quizStates[section.id].optionsList.find(o => o.value === answerData.answer);
            const answer = {
                [lessonId]: {
                    [section.id]: {
                        [answerData.origin.id]: {
                            [movedOption.answerId]: {
                                text: '',
                                is_correct: false,
                            },
                        },
                    },
                },
            };

            recordQuizAnswer(answer);

            // performing the move ans saving to to the redux store
            const { options, questions: questionsArrays } = quizStates[section.id];
            const spliceIndex = questionsArrays[answerData.origin.id].map(answerObj => answerObj.answer).indexOf(answerData.answer);
            questionsArrays[answerData.origin.id].splice(spliceIndex, 1);
            answerData.origin = answerData.destination;
            delete answerData.destination;
            options[answerData.origin.index] = answerData;

            recordQuizState({
                [section.id]: {
                    options,
                    questions: questionsArrays,
                },
            });
        }
    };

    const renderOptions = () => {
        const optionsList = quizStates?.[section.id]?.options.map((option, idx) => {
            if (!option) {
                return (
                    <DropArea
                        className="DropArea-Options"
                        destination={{
                            section: 'options',
                            index: idx,
                        }}
                        key={idx}
                    />
                );
            }

            return (
                <DragElement
                    answerData={
                        option
                    }
                    handleDrop={(answerData) => moveOption(answerData)}
                    key={idx}
                />
            );
        });

        return optionsList;
    };

    const renderQuestions = () => {
        return questions.map(
            (question, idx) => (
                <div className="SortingBox-Questions__container">
                    <TextBox
                        text={question.text}
                        tag="h5"
                        style={{
                            color: COLORS.Blue,
                            marginBottom: '10px',
                        }}
                    />

                    <DropArea
                        className="SortingBox-DropArea"
                        destination={{
                            section: 'questions',
                            id: question.id,
                        }}
                        dropId={idx}
                    >
                        {
                            quizStates?.[section.id]?.questions?.[question.id].map(
                                (option, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                        >
                                            <DragElement
                                                answerData={
                                                    option
                                                }
                                                className={
                                                    checkAnswers
                                                        ? (
                                                            question.answers.map(a => a.text).includes(option.answer) ?
                                                                "DragElement DragAndDropBox-correct"
                                                                :
                                                                "DragElement DragAndDropBox-wrong"
                                                        )
                                                        : "DragElement"
                                                }
                                                handleDrop={(answerData) => moveOption(answerData)}
                                                key={idx}
                                            />
                                        </div>
                                    );
                                },
                            )
                        }
                    </DropArea>
                </div>
            ),
        );
    };

    if (!quizStates[section.id]?.optionsList) {
        return <div />;
    }

    if (revealAnswers) {
        fillAnswers();
    }

    return (
        <div className={`SortingBox ${isLengthyDrag ? 'Lengthy-Drag' : ''}`}>
            <div className="DragAndDropBox-OptionsArea">
                {renderOptions()}
            </div>

            <div className="SortingBox-Questions DragAndDropBox-Questions">
                {renderQuestions()}
            </div>

            <DragPreview />
        </div>
    );
};

let isTouchDevice;
let DNDBackend;

// Checks if current device has touch capability
try {
    document.createEvent('TouchEvent');
    isTouchDevice = true;
} catch (errors) {
    isTouchDevice = false;
}

if (isTouchDevice) {
    DNDBackend = TouchBackend({ enableMouseEvents: true });
} else {
    DNDBackend = HTML5Backend;
}

export default connect(mapStateToProps, null)(DragDropContext(DNDBackend)(SortingBox));
