import * as Sentry from '@sentry/browser';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import createSentryMiddleware from 'redux-sentry-middleware';
import { thunk } from 'redux-thunk';

import rootReducer from '../Reducers/RootReducer';
import { adaptStore, adaptUser } from './StoreUtil';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadedState = {}) => {
    if (process.env.NODE_ENV === 'production') {
        return (
            createStore(
                rootReducer,
                preloadedState,
                applyMiddleware(
                    thunk,
                    createSentryMiddleware(Sentry, {
                        getUserContext: (state) => adaptUser(state),
                        stateTransformer: (state) => adaptStore(state),
                    }),
                ),
            )
        );
    }

    return (
        createStore(
            rootReducer,
            preloadedState,
            composeEnhancer(applyMiddleware(
                thunk,
                logger,
                createSentryMiddleware(Sentry, {
                    getUserContext: (state) => adaptUser(state),
                    stateTransformer: (state) => adaptStore(state),
                }),
            )),
        )
    );
};

export default configureStore;
