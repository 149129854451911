import {
  RECEIVE_CREDENTIAL_STATS,
  RECEIVE_CREDENTIAL_STAT_ERRORS,
  CLEAR_CREDENTIAL_STATS
} from '../../../Actions/Stats/Users/CredentialActions';

const CredentialsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CREDENTIAL_STATS:
      return Object.assign({}, state, action.payload);
    case CLEAR_CREDENTIAL_STATS:
      return Object.assign({}, state, { [action.key]: {} });
    default:
      return state;
  }
}

export default CredentialsReducer;

