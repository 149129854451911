import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/TopicApiUtil';

export const RECEIVE_TOPIC_ERRORS = 'RECEIVE_TOPIC_ERRORS';
export const RECEIVE_TOPICS = 'RECEIVE_TOPICS';
export const RECEIVE_ONLY_TOPICS = 'RECEIVE_ONLY_TOPICS';
export const CLEAR_TOPICS = 'CLEAR_TOPICS';
export const RECEIVE_TOPIC = 'RECEIVE_TOPIC';
export const CLEAR_TOPIC_ERRORS = 'CLEAR_TOPIC_ERRORS';
export const RECEIVE_TOPIC_DELETE_SUCCESS = 'RECEIVE_TOPIC_DELETE_SUCCESS';
export const RECEIVE_TOPIC_POSITIONS = 'RECEIVE_TOPIC_POSITIONS';

export const receiveTopics = payload => ({
    type: RECEIVE_TOPICS,
    payload,
});

export const receiveOnlyTopics = payload => ({
    type: RECEIVE_ONLY_TOPICS,
    payload,
});

export const clearTopics = () => ({
    type: CLEAR_TOPICS,
});

export const receiveTopic = (payload, message) => ({
    type: RECEIVE_TOPIC,
    payload,
    message,
});

export const receiveErrors = errors => ({
    type: RECEIVE_TOPIC_ERRORS,
    errors,
});

export const clearTopicErrors = () => ({
    type: CLEAR_TOPIC_ERRORS,
});

export const receiveTopicDeleteSuccess = (payload, message) => ({
    type: RECEIVE_TOPIC_DELETE_SUCCESS,
    payload,
    message,
});

export const receiveTopicPositions = (payload, message) => ({
    type: RECEIVE_TOPIC_POSITIONS,
    payload,
    message,
});

export const fetchTopics = (lessonId) => dispatch => (
    APIUtil.fetchTopics(lessonId).then(
        payload => dispatch(receiveTopics(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchOnlyTopics = lessonId => dispatch => (
    APIUtil.fetchTopics(lessonId).then(
        payload => dispatch(receiveOnlyTopics(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchTopic = (topicId) => dispatch => (
    APIUtil.fetchTopic(topicId).then(
        payload => dispatch(receiveTopic(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createTopic = (topic) => dispatch => (
    APIUtil.createTopic(topic).then(
        payload => dispatch(receiveTopic(payload, 'Topic Created')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editTopic = (topic) => dispatch => (
    APIUtil.editTopic(topic).then(
        payload => dispatch(receiveTopic(payload, 'Topic Updated')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteTopic = topicId => dispatch => (
    APIUtil.deleteTopic(topicId).then(
        payload => dispatch(receiveTopicDeleteSuccess(payload, 'Topic Deleted')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveHigher = topicId => dispatch => (
    APIUtil.moveHigher(topicId).then(
        payload => dispatch(receiveTopicPositions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveLower = topicId => dispatch => (
    APIUtil.moveLower(topicId).then(
        payload => dispatch(receiveTopicPositions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const cloneTopic = (targetLessonId, topicId) => dispatch => (
    APIUtil.cloneTopic(targetLessonId, topicId).then(
        payload => payload,
        errors => ErrorResponseUtil(errors),
    )
);
