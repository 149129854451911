import { connect } from 'react-redux';

import ModifyUser from './ModifyUser';

import { clearInstitutions, fetchInstitutions } from '../../../Actions/InstitutionActions';
import { checkExistingUser, updateUserType, confirmUser, archiveUser, overidePassword } from '../../../Actions/ModifyUserActions';

const mapStateToProps = (state) => ({
  institutions: state.admin.institutions,
});

const mapDispatchToProps = (dispatch) => ({
  clearInstitutions: () => dispatch(clearInstitutions()),
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  checkExistingUser: (user) => dispatch(checkExistingUser(user)),
  updateUserType: (user) => dispatch(updateUserType(user)),
  confirmUser: (user) => dispatch(confirmUser(user)),
  archiveUser: (userId) => dispatch(archiveUser(userId)),
  overidePassword: (user) => dispatch(overidePassword(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUser);
