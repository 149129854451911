import * as APIUtil from '../Util/MediaTypeApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_MEDIA_TYPES = 'CLEAR_MEDIA_TYPES';
export const RECEIVE_MEDIA_TYPES = 'RECEIVE_MEDIA_TYPES';
export const RECEIVE_MEDIA_TYPE_ERRORS = 'RECEIVE_MEDIA_TYPE_ERRORS';

export const clearMediaTypes = () => ({
  type: CLEAR_MEDIA_TYPES,
});

export const receiveMediaTypes = payload => ({
  type: RECEIVE_MEDIA_TYPES,
  payload,
});

export const receiveMediaTypeErrors = errors => ({
  type: RECEIVE_MEDIA_TYPE_ERRORS,
  errors,
});

export const fetchMediaTypes = () => dispatch => (
  APIUtil.fetchMediaTypes().then(
    payload => dispatch(receiveMediaTypes(payload)),
    errors => dispatch(receiveMediaTypeErrors(ErrorResponseUtil(errors))),
  )
);
