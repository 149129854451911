import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from  '../../../../Basic/ButtonBox/ButtonBox';
import Loader from '../../../../Composed/Loader/Loader';
import MirrorLoader from '../../../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import DashboardFormBoxNoEdit from '../../../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';

import './GrantAccess.scss';

const GrantAccess = ({ inventories, clearInventories, fetchInventories, grantAccess, checkExistingUser }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [email, setEmail] = useState('');
  const [exists, setExists] = useState(false);
  const [user, setUser] = useState(null);
  const [inventoryId, setInventoryId] = useState('');

  useEffect(() => {
    clearInventories();
    fetchInventories().then(() => setLoading(false));
  }, [clearInventories, fetchInventories]);

  const handleCheckExistingUser = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (email.trim().length === 0) {
      setErrors({ '': 'Please enter email address to check user\'s existence.' });
      return null;
    }

    setProcessing(true);
    checkExistingUser({ email }).then(res => {
      setExists(res.exists);
      setUser(res.user);
      setProcessing(false);

      if (res.exists) {
        setFlash({ message: 'A user with this email exists in the system.' });
      } else {
        setErrors({ '': 'A user with this email does not exists in the system.' });
      }
    });
  }

  const handleGrantAccess = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (!user.id) {
      setErrors({ '': 'Unable to get ID of the identified user.' });
      return null;
    }

    if (!inventoryId) {
      setErrors({ '': 'Please select inventory whose access needs to be given to the user.' })
      return null;
    }

    setProcessing(true);
    grantAccess(user.id, inventoryId).then(res => {
      setFlash({ message: res.message });
      setProcessing(false);
    });
  }

  const handleClear = (e) => {
    e.preventDefault();

    setErrors({});
    setFlash({});
    setEmail('');
    setExists(false);
    setUser(null);
    setInventoryId('');
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Grant-Access-Container createUserContainer CreateUser">
      <ErrorFlashBox errors={errors} flash={flash} />

      {processing && <MirrorLoader message="Processing..." />}

      <DashboardFormBoxNoEdit title="Grant Access to Canopy Credential">
        <div className="DashboardFormBox-Line Manage-User-Access fdc">
          <div className="Verify-Head">
            <div className="Full-Width">
              <div className="DashboardFormBox-Label left">
                User Email
              </div>
              <button
                type="button"
                className="CreateUser-Update-Button Verify-Btn"
                onClick={(e) => handleCheckExistingUser(e)}
              >
                Verify
              </button>
            </div>
          </div>
          <div className="Verify-Body">
            <input
              type="text"
              value={email}
              disabled={exists}
              className={`${exists && 'ValidEmail'} Full-Width`}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {exists && <i className="fas fa-check Check-Icon" />}
          </div>
        </div>
      </DashboardFormBoxNoEdit>

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          <div className="Select-Holder">
            <select
              value={inventoryId}
              className="createLicenseContainer__select Inventory-Drop-Down"
              onChange={(e) => setInventoryId(e.currentTarget.value)}
            >
              <option key="inventory-placeholder" value="" children="Please select inventory" />
              {
                Object.values(inventories).map((inventory, idx) => (
                  <option
                    key={`inventory-${inventory.id}-${idx}`}
                    value={inventory.id}
                    children={`${inventory.name} - ${inventory.variant}`}
                  />
                ))
              }
            </select>
          </div>
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          <div className="Button-Container">
            <ButtonBox
              className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
              text="Grant Access"
              onClick={(e) => handleGrantAccess(e)}
            />
            <ButtonBox
              className="DashboardFormBox-Button_cancel DashboardFormBox-CancelBtn"
              text="Clear"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }
    </div>
  )
}

GrantAccess.propTypes = {
  inventories: PropTypes.object,
  clearInventories: PropTypes.func.isRequired,
  fetchInventories: PropTypes.func.isRequired,
  grantAccess: PropTypes.func.isRequired,
  checkExistingUser: PropTypes.func.isRequired,
}

export default GrantAccess;
