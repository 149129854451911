import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProgressBar from '../../Basic/ProgressBar/ProgressBar';
import ReactBreadcrumbs from '../ReactBreadcrumbs/ReactBreadcrumbs';
import ReactBreadcrumb from '../ReactBreadcrumbs/ReactBreadcrumb';

import './LearnBreadcrumbs.css';

const propTypes = {
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    lessons: PropTypes.objectOf(PropTypes.object).isRequired,
    activities: PropTypes.objectOf(PropTypes.object).isRequired,
    topics: PropTypes.objectOf(PropTypes.object).isRequired,
    quizzes: PropTypes.objectOf(PropTypes.object).isRequired,
};

const LearnBreadcrumbs = ({
    currentCourseId,
    courses,
    lessons,
    activities,
    topics,
    quizzes,
    navigationSectionIndex,
    navigationQuestionIndex
}) => {
    const [progress, setProgress] = useState(0);
    const currentCourse = currentCourseId ? courses[currentCourseId] : null;
    const breadcrumbItemDisplay = currentCourse?.course_type?.name === 'Compliance';

    const calculateQuizProgress = (quiz) => {
        let perSectionPercentage = 100 / quiz.sections.length;
        let percentage = perSectionPercentage * navigationSectionIndex;

        if (navigationQuestionIndex) {
            percentage += (perSectionPercentage / quiz.sections[navigationSectionIndex].questions.length) * navigationQuestionIndex;
        }

        setProgress(Math.floor(percentage));
    }

    const buildItem = (title, value) => ({
        title, value
    });

    const buildCourse = (courseId) => {
        const course = courses[courseId];
        return course ? buildItem('Level', `Level ${course.level}`) : {};
    }

    const buildLesson = (lessonId) => {
        const lesson = lessons[lessonId];
        return lesson ? buildItem('Lesson', lesson.name) : {};
    }

    const buildActivity = (activityId) => {
        const activity = activities[activityId];
        return activity ? buildItem('Activity', activity.name) : {};
    }

    const buildTopic = (topicId) => {
        const topic = topics[topicId];
        return topic ? buildItem('Module', topic.name) : {};
    }

    const buildQuiz = (quizId) => {
        const quiz = quizzes[quizId];
        return quiz ? buildItem('Quiz', quiz.name) : {};
    }

    const buildActivitySection = (activityId) => {
        if (navigationSectionIndex !== null && activityId) {
            const activity = activities[activityId];
            if (activity) {
                setProgress(Math.floor(navigationSectionIndex / activity.sections.length * 100));
            }
        }

        return '';
    }

    const buildTopicSection = (topicId) => {
        if (navigationSectionIndex !== null && topicId) {
            const topic = topics[topicId];
            if (topic) {
                setProgress(Math.floor(navigationSectionIndex / topic.sections.length * 100));
                return buildItem('Slide', (topic.sections[navigationSectionIndex]?.name || 'Result'));
            }
        }

        return {};
    }

    const buildQuizSection = (quizId) => {
        if (navigationSectionIndex !== null && quizId) {
            const quiz = quizzes[quizId];
            if (quiz) {
                calculateQuizProgress(quiz);
            }
        }

        return '';
    }

    return (
    <div className="Breadcrumbs">
        <ReactBreadcrumbs separator={<i className="fas fa-caret-right" />} />

        <Route
            exact
            path="/learn/courses/:courseId/lessons"
            render={routeProps => (
                <span>
                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildItem('All', 'Courses'),
                            pathname: '/learn'
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildCourse(routeProps.match.params.courseId),
                            pathname: routeProps.match.url
                        }}
                    />
                </span>
            )}
        />

        <Route
            path="/creation/courses/:courseId/lessons"
            render={routeProps => (
                <span>
                    <ReactBreadcrumb
                        data={{
                            title: buildItem('All', 'Courses'),
                            pathname: '/creation'
                        }}
                    />

                    <ReactBreadcrumb
                        data={{
                            title: buildCourse(routeProps.match.params.courseId),
                            pathname: routeProps.match.url
                        }}
                    />
                </span>
            )}
        />

        <Route
            exact
            path="/learn/courses/:courseId/lessons/:lessonId"
            render={routeProps => (
                <span>
                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildItem('All', 'Courses'),
                            pathname: '/learn'
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildCourse(routeProps.match.params.courseId),
                            pathname: `/learn/courses/${routeProps.match.params.courseId}/lessons`
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildLesson(routeProps.match.params.lessonId),
                            pathname: routeProps.match.url
                        }}
                    />
                </span>
            )}
        />

        <Route
            path="/creation/courses/:courseId/lessons/:lessonId"
            render={routeProps => (
                <ReactBreadcrumb
                    data={{
                        title: buildLesson(routeProps.match.params.lessonId),
                        pathname: routeProps.match.url
                    }}
                />
            )}
        />

        <Route
            path="/learn/courses/:courseId/lessons/:lessonId/activity"
            render={routeProps => (
              <div className="Breadcrumbs-Heading">
                { buildActivitySection(lessons[routeProps.match.params.lessonId]?.activity_id) }
                <div className="Breadcrumbs-HeadingMain">{`${lessons[routeProps.match.params.lessonId]?.name}`}</div>
              </div>
            )}
        />

        <Route
            path="/creation/courses/:courseId/lessons/:lessonId/activity"
            render={routeProps => (
                <ReactBreadcrumb
                    data={{
                        title: buildActivity(lessons[routeProps.match.params.lessonId].activity_id),
                        pathname: routeProps.match.url
                    }}
                />
            )}
        />

        <Route
            exact
            path="/learn/courses/:courseId/lessons/:lessonId/topics/:topicId"
            render={routeProps => (
                <span>
                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildItem('All', 'Courses'),
                            pathname: '/learn'
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildCourse(routeProps.match.params.courseId),
                            pathname: `/learn/courses/${routeProps.match.params.courseId}/lessons`
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildLesson(routeProps.match.params.lessonId),
                            pathname: routeProps.match.url
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildTopic(routeProps.match.params.topicId),
                            pathname: routeProps.match.url
                        }}
                    />

                    <ReactBreadcrumb
                        hidden={breadcrumbItemDisplay}
                        data={{
                            title: buildTopicSection(routeProps.match.params.topicId),
                            pathname: routeProps.match.url
                        }}
                    />
                </span>
            )}
        />

        <Route
            path="/creation/courses/:courseId/lessons/:lessonId/topics/:topicId"
            render={routeProps => (
                <ReactBreadcrumb
                    data={{
                        title: buildTopic(routeProps.match.params.topicId),
                        pathname: routeProps.match.url
                    }}
                />
            )}
        />

        <Route
            path="/learn/courses/:courseId/lessons/:lessonId/quiz"
            render={routeProps => (
              <div className="Breadcrumbs-Heading">
                { buildQuizSection(lessons[routeProps.match.params.lessonId]?.quiz_id) }
                <div className="Breadcrumbs-HeadingMain">{`${lessons[routeProps.match.params.lessonId]?.name} Quiz`}</div>
              </div>
            )}
        />

        <Route
            path="/creation/courses/:courseId/lessons/:lessonId/quiz"
            render={routeProps => (
                <ReactBreadcrumb
                    data={{
                        title: buildQuiz(lessons[routeProps.match.params.lessonId].quiz_id),
                        pathname: routeProps.match.url
                    }}
                />
            )}
        />

        {
            (navigationSectionIndex !== null) &&
            (
                <div className="Breadcrumb-Progress">
                    <ProgressBar percent={progress} moveable={true} />
                </div>
            )
        }
    </div>
    );
}

LearnBreadcrumbs.propTypes = propTypes;

export default LearnBreadcrumbs;
