import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUserPerformance, fetchUserPerformanceCleanup, fetchUser } from '../../../../../Actions/UserActions';
import UserDetail from './UserDetail';

const mapStateToProps = state => ({
    user: state.admin.users.user,
    userDetail: state.admin.users.userDetail,
});

const mapDispatchToProps = dispatch => ({
    fetchUser: (userId) => dispatch(fetchUser(userId)),
    fetchUserPerformance: (userId) => dispatch(fetchUserPerformance(userId)),
    fetchUserPerformanceCleanup: () => dispatch(fetchUserPerformanceCleanup()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetail));
