import { connect } from 'react-redux';

import Management from './Management';

import { clearInstitutions, fetchInstitutions } from '../../../../Actions/InstitutionActions';
import { clearInstitutionResources, fetchInstitutionResources } from '../../../../Actions/InstitutionResourceActions';
import { clearPlatforms, fetchPlatforms } from '../../../../Actions/PlatformActions';
import { clearMediaTypes, fetchMediaTypes } from '../../../../Actions/MediaTypeActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  institutions: (state.admin.institutions || {}),
  institutionResources: (state.institutionEntities.institutionResources || {}),
  platforms: (state.entities.platforms || {}),
  mediaTypes: (state.entities.mediaTypes || {}),
});

const mapDispatchToProps = (dispatch) => ({
  clearInstitutions: () => dispatch(clearInstitutions()),
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  clearInstitutionResources: () => dispatch(clearInstitutionResources()),
  fetchInstitutionResources: (institutionId, queryString) => dispatch(fetchInstitutionResources(institutionId, queryString)),
  clearPlatforms: () => dispatch(clearPlatforms()),
  fetchPlatforms: () => dispatch(fetchPlatforms()),
  clearMediaTypes: () => dispatch(clearMediaTypes()),
  fetchMediaTypes: () => dispatch(fetchMediaTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);
