import axios from './AxiosUtil';

export const fetchActivity = (lessonId) => {
    return axios.get(`/api/lessons/${lessonId}/activity`).then(
        res => res.data,
    );
};

export const createActivity = (activity) => {
    return axios.post('/api/activities', { activity }).then(
        res => res.data,
    );
};

export const editActivity = (activity) => {
    return axios.patch(`/api/activities/${activity.id}`, { activity }).then(
        res => res.data,
    );
};

export const deleteActivity = (activityId) => {
    return axios.delete(`/api/activities/${activityId}`).then(
        res => res.data,
    );
};

export const cloneActivity = (targetLessonId, activityId) => {
    return axios.post(`/api/activities/${activityId}/clone`, { target_lesson_id: targetLessonId }).then(
        res => res.data,
    );
};

export const submitActivityResponse = (activityResponseData) => {
    return axios.post('/api/activity_responses', { activity_response: activityResponseData }).then(
        res => res.data,
    );
};
