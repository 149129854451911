import React from 'react';

export const institutionalAdminTutorialModules = {
  home: [
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Users-Menu',
      content: (
        <div className="Step">
          <div className="Heading">Add Users/Students</div>
          <div className="Content">
            <p className="Text">Invite students to join Canopy! You can send invitations to start their learning journey with just a few clicks.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Progress-Menu',
      content: (
        <div className="Step">
          <div className="Heading">View Progress and Performance</div>
          <div className="Content">
            <p className="Text">Access comprehensive reports to track student progress and performance effortlessly.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Groups-Menu',
      content: (
        <div className="Step">
          <div className="Heading">Manage Groups</div>
          <div className="Content">
            <p className="Text">Organize students by utilizing the groups feature. Group students together to run specialized reports that cater to specific learning segments or criteria.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Sidebar-Dashboards-Links',
      content: (
        <div className="Step">
          <div className="Heading">Dashboards</div>
          <div className="Content">
            <p className="Text">Access student participation and performance details by clicking on each of these products.</p>
          </div>
        </div>
      )
    }
  ],
  invitation: [
    {
      locationHash: '#/account/invite_users/new',
      selector: '.Tour-Step-Invite-Users-Invitation-Tabs',
      content: (
        <div className="Step">
          <div className="Heading">Invite your students</div>
          <div className="Content">
            <p className="Text">Choose how you want to invite your students.</p>
            <p className="Text">The Excel option is most suitable when you need to add a substantial number of users at once.</p>
            <p className="Text">The Emails option is most suitable when you to need invite less than 10 users.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/invite_users/new',
      selector: '.Tour-Step-Invite-Users-License-Options',
      content: (
        <div className="Step">
          <div className="Heading">Select the license</div>
          <div className="Content">
            <p className="Text">Typically, only one license should be visible here. If multiple licenses are listed, choose the one in which you want your students to be enrolled.</p>
          </div>
        </div>
      )
    },
  ],
  courseProgress: [
    {
      locationHash: '#/account/progress',
      selector: '.Tour-Step-Course-Progress-Filter-Form',
      content: (
        <div className="Step">
          <div className="Heading">Running Reports</div>
          <div className="Content">
            <p className="Text">Choose the course and student group you wish to track progress for. Once selected, click 'Generate Report' to view their progress.</p>
          </div>
        </div>
      )
    }
  ],
}

export const studentTutorialModules = {
  home: [
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Welcome-Banner',
      content: (
        <div className="Step">
          <div className="Heading">Welcome to your personalized dashboard!</div>
          <div className="Content">
            <p className="Text">This is your hub for managing all things related to your Canopy account.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Learn-Button',
      content: (
        <div className="Step">
          <div className="Heading">The CanopyLearn course!</div>
          <div className="Content">
            <p className="Text">Start your journey now by clicking 'Begin Course'! Dive into the content and take your first step towards mastering medical Spanish.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Home-Credential-Button',
      content: (
        <div className="Step">
          <div className="Heading">The CanopyCredential exam!</div>
          <div className="Content">
            <p className="Text">When you feel prepared, you can begin the Credential exam from this button.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/learn',
      selector: '.Tour-Step-Sidebar-Profile-Link',
      content: (
        <div className="Step">
          <div className="Heading">Profile, Preferences, and more!</div>
          <div className="Content">
            <p className="Text">Complete your profile to tailor your experience. Share a bit about yourself and choose preferences to customize your account to your liking.</p>
          </div>
        </div>
      )
    }
  ],
  profile: [
    {
      locationHash: '#/account/profile?tab=general-information',
      selector: '.Tour-Step-Profile-General-Info-Tab',
      content: (
        <div className="Step">
          <div className="Heading">General Information</div>
          <div className="Content">
            <p className="Text">In this section of your Profile, you can customize your account and workplace information. You'll also be able to set your communication preferences.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=general-information',
      selector: '.Tour-Step-Profile-Basic-Info-Title',
      content: (
        <div className="Step">
          <div className="Heading">Basic Information</div>
          <div className="Content">
            <p className="Text">Tell us about yourself! All information will be used to personalize your experience and ensure we cater to your preferences.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=general-information',
      selector: '.Tour-Step-Profile-Workplace-Info-Title',
      content: (
        <div className="Step">
          <div className="Heading">Workplace Information</div>
          <div className="Content">
            <p className="Text">Accurate workplace details strengthen your connection to your institution, if relevant. Ensure precision in providing your workplace information for seamless integration and relevant updates.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=general-information',
      selector: '.Tour-Step-Profile-Security-Title',
      content: (
        <div className="Step">
          <div className="Heading">Guard your account</div>
          <div className="Content">
            <p className="Text">If there's ever a need to update your password, you can make the change right here.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=general-information',
      selector: '.Tour-Step-Profile-Preference-Title',
      content: (
        <div className="Step">
          <div className="Heading">Set your preferences!</div>
          <div className="Content">
            <p className="Text">Control your notification reminders from this hub. Customize your preferences by switching notifications on or off according to your liking.</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=my-languages',
      selector: '.Tour-Step-Profile-My-Languages-Tab',
      content: (
        <div className="Step">
          <div className="Heading">What's your language background?</div>
          <div className="Content">
            <p className="Text">Add the languages you're learning and proficient in here (including English).</p>
          </div>
        </div>
      )
    },
    {
      locationHash: '#/account/profile?tab=my-progress',
      selector: '.Tour-Step-Profile-My-Progress-Tab',
      content: (
        <div className="Step">
          <div className="Heading">Your Progress</div>
          <div className="Content">
            <p className="Text">Craft your language learning strategy by generating pledges. Just one pledge is needed to finalize your profile.</p>
            <p className="Text">Additionally, manage your subscriptions and view your achievements in this section.</p>
          </div>
        </div>
      ),
    },
  ],
}
