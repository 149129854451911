import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './TopicBox.css';
import { COLORS } from '../../../Style/Style';

import TextBox from '../../Basic/TextBox/TextBox';
import ProgressBar from '../../Basic/ProgressBar/ProgressBar';
import SectionListBox from '../../Composed/SectionListBox/SectionListBox';

const propTypes = {
    currentUser: PropTypes.shape({
        user_type: PropTypes.string,
    }).isRequired,
    topic: PropTypes.shape().isRequired,
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.number.isRequired,
    quizMode: PropTypes.bool,
    creation: PropTypes.bool,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    bookmarks: PropTypes.objectOf(Array).isRequired
};

const defaultProps = {
    quizMode: false,
    creation: false,
}

const mapStateToProps = (state) => ({
    courseProgresses: state.statistics.courseProgresses,
    bookmarks: state.statistics.bookmarks,
    currentUser: state.session.currentUser,
});

const TopicBox = ({
    topic,
    lessonId,
    courseId,
    quizMode,
    creation,
    courseProgresses,
    bookmarks,
    currentUser,
    activityMode
}) => {
    const [hidden, setHidden] = useState(true);

    const handleProgressPercent = () => {
        let percentage = 0;

        if (courseProgresses?.[courseId]?.progress_data?.lessonProgresses?.[lessonId]?.topicProgresses?.[topic.id]) {
            percentage = Math.floor(
                (
                    Object.keys(courseProgresses[courseId].progress_data.lessonProgresses[lessonId].topicProgresses[topic.id].sectionProgresses).length
                    /
                    topic.sections.length
                ) * 100
            )
        }

        return (
            percentage > 100 ? 100 : percentage
        );
    };

    const handleLinkLocation = () => {
        if (activityMode) {
            if (creation) {
                return `/creation/courses/${courseId}/lessons/${lessonId}/activity`;
            }

            return `/learn/courses/${courseId}/lessons/${lessonId}/activity`;
        }

        if (quizMode) {
            if (creation) {
                return `/creation/courses/${courseId}/lessons/${lessonId}/quiz`;
            }

            return `/learn/courses/${courseId}/lessons/${lessonId}/quiz`;
        }

        if (creation) {
            return `/creation/courses/${courseId}/lessons/${lessonId}/topics/${topic.id}`;
        }

        return `/learn/courses/${courseId}/lessons/${lessonId}/topics/${topic.id}`;
    };

    return (
        <div className="TopicBox">
            <div 
                className="TopicBox-Visible"
                onClick={() => !creation && setHidden(!hidden)}
            >
                <Link to={handleLinkLocation()}>
                    <div className="TopicBox-DataBox-title">
                        <TextBox
                            text={topic.name}
                            style={{
                                color: `${COLORS.Blue}`,
                            }}
                        />
                    </div>
                </Link>
                <div className="TopicBox-DataBox">
                    {
                        currentUser.user_type !== 'TE.Admin'
                        && (
                            <div className="TopicBox-DataBox-ProgressBar">
                                <ProgressBar percent={handleProgressPercent()} />
                            </div>
                        )
                    }

                    {
                        !creation
                        && (
                            <button
                                className="LessonBox-Button-Icon__dropdown"
                                type="button"
                            >
                                {
                                    hidden
                                        ? <i className="fas fa-chevron-down" />
                                        : <i className="fas fa-chevron-up" />
                                }
                            </button>
                        )
                    }
                </div>
            </div>

            {
                !hidden
                && (
                    <SectionListBox
                        sections={topic.sections}
                        topic={topic}
                        lessonId={lessonId}
                        courseId={courseId}
                        courseProgresses={courseProgresses}
                        bookmarks={bookmarks}
                    />
                )
            }
        </div>
    );
};

TopicBox.propTypes = propTypes;
TopicBox.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(TopicBox);
