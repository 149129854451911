import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';

import ImageBox from '../../../Basic/ImageBox/ImageBox';

import './ScoreCardHeader.scss';

const ScoreCardHeader = ({ title, user, testEndTime }) => {
  const dateContent = (heading, date, format) => (
    <div className="Info">
      <div className="Heading">{heading}</div>
      <div className="Text">{dateFormat(date, format)}</div>
    </div>
  )

  return (
    <div className="Score-Card-Header">
      <div className="Header-Logo">
        <ImageBox className="Image" src="logo_credential.png" alt="credential-logo" />
      </div>
      <div className="Header-Title">
        <h4>{title}</h4>
      </div>
      <div className="Header-Info">
        <div className="Info">
          <div className="Heading">Name: </div>
          <div className="Text">{`${user.first_name} ${user.last_name}`}</div>
        </div>
        {testEndTime && dateContent('Date: ', testEndTime, 'mmmm dd, yyyy')}
        {user.birthdate && dateContent('Birthdate (MM/DD): ', user.birthdate, 'm/d')}
      </div>
    </div>
  )
}

ScoreCardHeader.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birthdate: PropTypes.string,
  }).isRequired,
  testEndTime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ScoreCardHeader;
