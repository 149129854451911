import { updateProfileLanguage } from "../../../Util/ProfileApiUtil";

export const UPDATE_LANGUAGE_BACKGROUND = 'UPDATE_LANGUAGE_BACKGROUND';
export const APPEND_EMPTY_LANGUAGE_BACKGROUND = 'APPEND_EMPTY_LANGUAGE_BACKGROUND';
export const REMOVE_EMPTY_LANGUAGE_BACKGROUND = 'REMOVE_EMPTY_LANGUAGE_BACKGROUND';

export const updateLanguageBackground = (payload) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_LANGUAGE_BACKGROUND,
        payload,
    });
    const updatedState = getState();
    try {
        const res = updateProfileLanguage(updatedState.session.currentUser.id, updatedState.profile.languageBackground);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const appendEmptyLanguageBackground = () => ({
    type: APPEND_EMPTY_LANGUAGE_BACKGROUND,
});

export const removeEmptyLanguageBackground = () => ({
    type: REMOVE_EMPTY_LANGUAGE_BACKGROUND,
});
