import {
  RECEIVE_B2B_ADMIN_COMPLIANCE_STATS,
  RECEIVE_B2B_ADMIN_COMPLIANCE_STAT_ERRORS,
  CLEAR_B2B_ADMIN_COMPLIANCE_STATS
} from '../../../Actions/Stats/B2BAdmin/ComplianceActions';

const CompliancesReducer = (state = {}, action) => {
  Object.freeze(state)

  switch (action.type) {
    case RECEIVE_B2B_ADMIN_COMPLIANCE_STATS:
      return Object.assign({}, state, action.payload);
    case CLEAR_B2B_ADMIN_COMPLIANCE_STATS:
      return Object.assign({}, state, { [action.key]: {} });
    default:
      return state;
  }
}

export default CompliancesReducer;
