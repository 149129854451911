import {
    CLEAR_MODIFY_USER_ERRORS,
    RECEIVE_MODIFY_USER_CONFIRMATION,
    RECEIVE_MODIFY_USER_ERRORS,
    RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION,
} from '../../Actions/ModifyUserActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case CLEAR_MODIFY_USER_ERRORS:
            return {};
        case RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION:
            if (action?.exists === false) {
                return { message: 'User Not Found.' };
            }
            return {};
        case RECEIVE_MODIFY_USER_CONFIRMATION:
            return {};
        case RECEIVE_MODIFY_USER_ERRORS:
            return action.errors;
        default:
            return state;
    }
};
