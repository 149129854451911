import React from 'react';
import { COUNTRIES } from '../../../Data/CountryCodes';

const CountryDropdown = (props) => {
  return (
    <select { ...props } defaultValue="US">
      <option value="">Please select</option>
      {
        COUNTRIES.map((country, idx) =>
          <option value={country.Code} key={ idx }>
          {country.Code + " - " + country.Name}
          </option>
        )
      }
    </select>
  );
}

export default CountryDropdown;
