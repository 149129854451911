import React from 'react';
import PropTypes from 'prop-types';

/**
* @function - TextBox - functional
* @{props} - {Object}
 * className - string
 * text - string or JSX object
 * tag - string
 * style - object
* @innerHTML = { JSX Object }
*/

const TextBox = ({ className, text, tag, style }) => {

  TextBox.propTypes = {
    className: PropTypes.string,
    tag: PropTypes.string,
    style: PropTypes.object
  }

  let innerHTML;

  switch (tag) {
    case ('h1'):
      innerHTML = (
        <h1
          className={className}
          style={ style }
          >
          { text }
        </h1>
      );
      break;
    case ('h2'):
      innerHTML = (
        <h2
          className={className}
          style={   style   }
          >
          { text }
        </h2>
      );
      break;
    case ('h3'):
      innerHTML = (
        <h3
          className={className}
          style={   style   }
          >
          { text }
        </h3>
      );
      break;
    case ('h4'):
      innerHTML = (
        <h4
          className={className}
          style={  style  }
          >
          { text }
        </h4>
      );
      break;
    case ('h5'):
      innerHTML = (
        <h5
          className={className}
          style={ style }
          >
          { text }
        </h5>
      );
      break;
    case ('h6'):
      innerHTML = (
        <h6
          className={className}
          style={ style }
          >
          { text }
        </h6>
      );
      break;
    case ('p'):
      innerHTML = (
        <p
          className={className}
          style={ style }
          >
          { text }
        </p>
      );
      break;
    default:
      innerHTML = (
        <p
          className={className}
          style={ style }
          >
          { text }
        </p>
      );
      break;
  }

  return innerHTML;
};

export default TextBox;
