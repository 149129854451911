import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputBox from '../../Basic/InputBox/InputBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const CreateEditGroup = ({ closeModal, createGroup, currentUser }) => {
    const [name, setName] = useState('');

    const handleInputChange = e => setName(e.currentTarget.value);

    const handleSubmit = () => {
        const group = {
            name,
            institution_id: currentUser.institution_id,
        };

        gaEvent('group_create', { institution_id: currentUser.institution_id });
        createGroup(group).then(
            () => closeModal(),
        );
    };

    return (
        <form
            className="CreationForms-Form"
            onSubmit={handleSubmit}
        >
            <InputBox
                className="InputBox-LogInBox"
                value={name}
                placeholder="Name"
                onChange={handleInputChange}
            />

            <div className="CreationForms-Button">
                <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Submit"
                />
            </div>
        </form>
    );
};

CreateEditGroup.propTypes = {
    closeModal: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
        institution_id: PropTypes.number,
    }).isRequired,
};

export default CreateEditGroup;
