import React, { Component } from 'react';

import './AdminDashboardCoursePackages.css';
import TextBox from '../../../Basic/TextBox/TextBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import CreateEditCoursePackage from '../../../Forms/CreateEditCoursePackage/CreateEditCoursePackage';
import CoursePackageBox from '../../../Presentational/CoursePackageBox/CoursePackageBox';
import { COLORS } from '../../../../Style/Style';

class AdminDashboardCoursePackages extends Component {

  state = {
    modalOpen: false
  }

  openModal = () => this.setState({ modalOpen: true })

  closeModal = () => this.setState({ modalOpen: false })

  componentDidMount() {
    this.props.fetchCoursePackages();
  }

  handleCoursePackageRender(forSale, renewOnly) {
    const { coursePackages, courses } = this.props;

    let coursePacakgeList = [];
    Object.values(coursePackages).forEach(
      (coursePackage, index) => {
        if (coursePackage.for_sale === forSale && coursePackage.renew_only === renewOnly) {
          coursePacakgeList.push(
            <CoursePackageBox 
              key={index}
              courses={courses}
              coursePackage={coursePackage}
              creation />
          )
        }  
      }
    );

    return coursePacakgeList;
  }

  render() {
    if(!Object.keys(this.props.courses).length || !Object.keys(this.props.coursePackages).length) {
      return <div></div>;
    }

    return (
      <div
        className="AdminDashboard-Pages">
        <div className="AdminDashboard-Manage">
          <div className="AdminDashboard-Title">
            <TextBox
              text="Manage Course Packages"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h2"
            />

            <ButtonBox
              className="ButtonBox-EditQuestions"
              text={<i className="fas fa-plus"></i>}
              onClick={this.openModal} />
          </div>

          <div className="AdminDashboard-Title-mini">
            <TextBox
              text="For Sale"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h5"
            />
          </div>
          
          <div className="AdminDashboard-CoursePackage-list">
            {this.handleCoursePackageRender(true, false)}
          </div>
          {
            this.state.modalOpen &&
            <CreateEditCoursePackage
              closeModal={this.closeModal}
              submitCoursePackage={ this.props.createCoursePackage }
              courses={this.props.courses}
            />
          }

          <div className="AdminDashboard-Title-mini">
            <TextBox
              text="Other Packages"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h5"
            />
          </div>
          
          <div className="AdminDashboard-CoursePackage-list">
            {this.handleCoursePackageRender(false, false)}
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboardCoursePackages;
