import {
  CLEAR_USER_RESOURCES,
  RECEIVE_USER_RESOURCES,
} from '../../Actions/UserResourceActions'

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case CLEAR_USER_RESOURCES:
      return {};
    case RECEIVE_USER_RESOURCES:
      return Object.assign({}, state, action.payload.userResources);
    default:
      return state;
  }
}
