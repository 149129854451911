import axios from './AxiosUtil';

export const institutionResourceUserInteraction = (institutionResourceUserParams) => (
  axios.post(
    `/api/institutions/${institutionResourceUserParams['institutionId']}/institution_resources/${institutionResourceUserParams['institutionResourceId']}/institution_resource_users/interact`,
    { institution_resource_user: institutionResourceUserParams }
  ).then(
    res => res.data
  )
);
