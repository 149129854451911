import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

import './LineChart.scss';

Exporting(Highcharts);

const LineChart = ({ id, data }) => {
  Highcharts.chart(id, {
    chart: {
      type: 'line'
    },
    title: {
      text: data.title
    },
    subtitle: {
      text: data.subtitle
    },
    xAxis: {
      categories: data.xAxis,
      labels: {
        style: {
          fontFamily: 'LATO',
          fontSize: '14px',
          color: '#183c5b'
        }
      }
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: data.yAxisTitle
      },
      lineLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: (
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray'
        }
      }
    },
    legend: {
      align: 'right',
      x: -50,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    plotOptions: {
      line: {
        lineWidth: 1,
        enableMouseTracking: false,
        marker: {
          symbol: 'cricle',
          radius: 5,
        },
        dataLabels: {
          enabled: false
        }
      }
    },
    series: data.series
  });

  return null;
}

export default LineChart;
