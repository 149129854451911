import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { isStudent, isProfileCompleted } from '../../../Util/Helper/UserWorkplaceUtil';

import './ProfileCompletion.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  profileCompletion: state.userEntities.profileCompletion,
});

const mapDispatchToProps = (dispatch) => ({});

const ProfileCompletion = ({ currentUser, profileCompletion }) => {
  if (!isStudent(currentUser) || isProfileCompleted(profileCompletion)) {
    return '';
  }

  return (
    <div className="ProfileCompletion modal">
      <div className="modal-content">
        <div className="ImageBox">
          <ImageBox
            className="Image"
            src="user-with-question-mark.png"
          />
        </div>

        <div className="ContentBox">
          <div className="Content">
            <div className="Title">
              Hi there,
            </div>
            <div className="Heading">
              It seems that your Canopy Profile is still incomplete.
            </div>
            <div className="Benefits">
              <div className="Description">Users who have completed their Profile get to enjoy many benefits, including:</div>

              <ul className="List list-disc pl-10">
                <li>Access additional Canopy products that may be available at the institutions where you work/study.</li>
                <li>Get tailored support for learning goals you would like to achieve.</li>
                <li>Connect with other Canopy users who may share similar interests.</li>
              </ul>
            </div>

            <div className="Ask">
              It takes less than 10 minutes to complete your profile. Shall we get that taken care of today?
            </div>

            <div className="ActionBox">
              <div className="Actions">
                <Link to="/account/profile">
                  <ButtonBox
                    className="Primary-Btn"
                    text={<span className="Primary-Btn-Text">Yes, take me to my Profile</span>}
                  />
                </Link>

                <Link to="/account/learn">
                  <ButtonBox
                    className="Primary-Btn"
                    text={<span className="Primary-Btn-Text">I’ll do it later*</span>}
                  />
                </Link>
              </div>

              <div className="Note">*You won’t be able to proceed to CanopyLearn lessons and other products.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileCompletion.propTypes = {
  currentUser: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompletion);
