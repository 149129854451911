import axios from './AxiosUtil';

export const fetchCourseProgresses = () => {
    return axios.get('/api/course_progresses').then(
        res => res.data
    )
};

export const saveCourseProgress = (courseProgress, sectionProgress, lessonId, topicId, sectionId) => {
    return axios.post('api/course_progresses', { course_progress: courseProgress, section_progress: sectionProgress, lessonId, topicId, sectionId }).then(
        res => res.data,
    );
};

export const retake = (courseId) => {
  return axios.post('/api/course_progresses/retake', { course_id: courseId }).then(
    res => res.data
  );
};

export const importProgress = (importParams) => {
    return axios.post('/api/course_progresses/import_progress', importParams).then(
        res => res.data
    );
}
