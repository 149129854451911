import {
  RECEIVE_RECURRING_SUBSCRIPTIONS,
  RECEIVE_RECURRING_SUBSCRIPTIONS_ERRORS,
  CLEAR_RECURRING_SUBSCRIPTIONS
} from '../../Actions/RecurringSubscriptionActions';

const RecurringSubscriptionReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_RECURRING_SUBSCRIPTIONS:
      return Object.assign({}, state, action.payload);
    case CLEAR_RECURRING_SUBSCRIPTIONS:
      return {};
    default:
      return state;
  }
}

export default RecurringSubscriptionReducer;
