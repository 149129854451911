import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MoonLoader } from 'react-spinners';

import Modal from '../../../../Composed/Modal/Modal';
import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';
import EditExam  from './EditExam';

import { clearInventories, fetchDetailedInventories } from '../../../../../Actions/InventoryActions';
import { updateVersions } from '../../../../../Actions/ExamActions';

import './ManageExams.scss';

const mapStateToProps = (state) => ({
  inventories: state.entities.inventories || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearInventories: () => dispatch(clearInventories()),
  fetchDetailedInventories: () => dispatch(fetchDetailedInventories()),
  updateVersions: (v) => dispatch(updateVersions(v))
});

const ManageExams = ({ inventories, clearInventories, fetchDetailedInventories, updateVersions }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearInventories();
    fetchDetailedInventories().then(res => setLoading(false));
  }, [clearInventories, fetchDetailedInventories]);

  const renderLoader = () => (
    <div className="Credential-Loader">
      <MoonLoader color="#000" />
    </div>
  )

  const renderInventories = () => (
    Object.values(inventories).map((inventory, iIdx) => (
      inventory.products.map((product, pIdx) => (
        <div key={`${iIdx}-${pIdx}`}>
          <h2>{inventory.name} - {inventory.variant} - {product.product_type}</h2>

          <div className="Exams-Container">
            <table>
              <thead>
                <tr>
                  <th>Exam</th>
                  <th>Test Language</th>
                  <th>Sapio Test ID</th>
                  <th>Instruction Versions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  product.exams.map((exam, eIdx) => (
                    <tr key={`${iIdx}-${pIdx}-${eIdx}`}>
                      <td>{exam.name}</td>
                      <td>{exam.language}</td>
                      <td>{exam.sapio_test_id}</td>
                      <td>
                        {
                          Object.entries(exam.versions).map(([k, v], vIdx) => (
                            <div key={`${iIdx}-${pIdx}-${eIdx}-${vIdx}`}>
                              {`${k}: ${v}`}
                            </div>
                          ))
                        }
                      </td>
                      <td>
                        <Modal
                          title="Edit Exam"
                          form={
                            close => (
                              <EditExam
                                exam={exam}
                                updateVersions={(v) => updateVersions(v)}
                                closeModal={close}
                              />
                            )
                          }
                        >
                          {
                            open => (
                              <ButtonBox
                                text="Edit Exam versions"
                                className="B2BAdminDashboard-Button"
                                onClick={open}
                              />
                            )
                          }
                        </Modal>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      ))
    ))
  )

  return (
    <div className="Admin-Dashboard-Main">
      <div className="AdminDashboard-Pages">
        <div className="AdminDashboard-Manage">
          <div className="AdminDashboard-Credential">
            {loading ? renderLoader() : renderInventories()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageExams);
