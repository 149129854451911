import axios from './AxiosUtil';

export const fetchReports = () => {
    return axios.get('/api/reports').then(
        res => res.data,
    );
};

export const createReport = report => {
    return axios.post('/api/reports', { report }).then(
        res => res.data,
    );
};