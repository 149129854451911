import { merge } from 'lodash';
import {
    RECEIVE_GROUPS,
    RECEIVE_GROUP,
    CLEAR_GROUPS,
} from '../../Actions/GroupActions';

const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_GROUPS:
            return { ...action.payload };
        case RECEIVE_GROUP:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_GROUPS:
            return {};
        default:
            return state;
    }
};
