import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { DragSource } from 'react-dnd';

const dragSource = {
  beginDrag: (props) => {
    return props.answerData;
  },

  endDrag: (props, monitor, component) => {
    if(!monitor.didDrop()) {
      return;
    }
    return props.handleDrop(monitor.getDropResult());
  }
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

class DragElement extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render () {
    const { isDragging, connectDragSource, answerData } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      <div
        className={this.props.className || "DragElement"}
        style={{
          opacity,
        }}>
        { answerData.answer }
      </div>
    );
  }
}

DragElement.propTypes = {
    answerData: PropTypes.object.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
};

export default DragSource('answer', dragSource, collect)(DragElement);
