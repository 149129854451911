import { connect } from 'react-redux';

import { fetchCoursePackage, editCoursePackage } from '../../../../Actions/CoursePackageActions';
import { fetchCourses } from '../../../../Actions/CourseActions';
import { fetchInventories } from '../../../../Actions/InventoryActions';
import CoursePackageDashboard from './CoursePackageDashboard';

const mapStateToProps = (state, { match }) => ({
    coursePackageId: match.params.coursePackageId,
    coursePackage: state.entities.coursePackages[match.params.coursePackageId],
    courses: state.entities.courses,
    inventories: state.entities.inventories
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourses: () => dispatch(fetchCourses()),
    fetchCoursePackage: coursePackageId => dispatch(fetchCoursePackage(coursePackageId)),
    editCoursePackage: coursePackage => dispatch(editCoursePackage(coursePackage)),
    fetchInventories: () => dispatch(fetchInventories())
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursePackageDashboard);
