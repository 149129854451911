import { GET_CURRENT_SUBSCRIPTIONS_SUCCESS } from '../../../../Actions/Profile/SubscriptionsBilling/CurrentSubscriptions';

const initialState = [];

const currentSubscription = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_SUBSCRIPTIONS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

export default currentSubscription;
