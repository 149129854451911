import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './SectionBoxMini.css';
import { COLORS } from '../../../Style/Style';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import TextBox from '../../Basic/TextBox/TextBox';
import { receiveSectionIndex } from '../../../Actions/NavigationActions';

const mapDispatchToProps = dispatch => ({
    receiveSectionIndex: index => dispatch(receiveSectionIndex(index)),
});

const SectionBoxMini = ({
    section,
    topic,
    lessonId,
    courseId,
    courseProgresses,
    bookmarks,
    receiveSectionIndex,
    sectionIndex,
}) => {
    let source;

    switch (section.section_type) {
        case 'flashcard':
            source = 'Flashcard.svg';
            break;
        case 'video':
            source = 'Telenovela.svg';
            break;
        case 'dialog':
            source = 'RolePlay.svg';
            break;
        case 'text':
            source = 'Lesson.svg';
            break;
        default:
            source = 'Exercise.svg';
            break;
    }

    const isSectionBookmarked = () => {
        return bookmarks?.ids?.sections?.includes(section.id);
    };

    const checkProgress = () => {
        if (courseProgresses?.[courseId]?.progress_data?.lessonProgresses?.[lessonId]
                ?.topicProgresses?.[topic.id]?.sectionProgresses?.[section.id]) {
            return true;
        }
        return false;
    }

    return (
        <div className="SectionBoxMini">
            <Link
                to={`/learn/courses/${courseId}/lessons/${lessonId}/topics/${topic.id}`}
                onClick={() => {
                    receiveSectionIndex(sectionIndex);
                }}
            >
                <div className="SectionBoxMini-Left__container">
                    <div className="NavigationSection-ImageBox-padding">
                        <ImageBox
                            className="NavigationSection-ImageBox"
                            src={source}
                        />
                    </div>

                    <TextBox
                        text={section.name}
                        style={{
                            color: COLORS.Blue
                        }}
                    />
                </div>
            </Link>

            <div>
                {
                    isSectionBookmarked()
                    && <i className="fas fa-bookmark icon-save" />
                }

                {
                    checkProgress()
                    && <i className="fas fa-check icon-correct" />
                }
            </div>
        </div>
    );
};

SectionBoxMini.propTypes = {
    section: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        section_type: PropTypes.string,
    }).isRequired,
    topic: PropTypes.shape().isRequired,
    lessonId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    bookmarks: PropTypes.objectOf(Array).isRequired,
    receiveSectionIndex: PropTypes.func.isRequired,
    sectionIndex: PropTypes.number.isRequired,
};

export default connect(null, mapDispatchToProps)(SectionBoxMini);
