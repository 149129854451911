import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import './ProfileNavBar.css';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import UserDropdown from '../UserDropdown/UserDropdown';
import NotificationListContainer from '../../Pages/UserNotifications/NotificationList/NotificationListContainer';

import { finalizePendingActionsAndLogout } from '../../../Util/Helper/UserWorkplaceUtil';
import { isBusinessLicense } from '../../../Util/Helper/LicenseUtil';
import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

const ProfileNavBar = ({ currentUser, currentWorkplace, location, logoOnly }) => {
    const { logout } = useAuth0();
    const history = useHistory();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const handleToggleMobileMenu = () => {
        const mobileMenu = document.querySelectorAll('.NavBar-Items_right');
        mobileMenu.forEach((div) => {
            div.classList.toggle('NavBar-Items_visible_mobile');
        });
    };

    const renderNavBarRightSide = () => {
        if (currentUser) {
            return (
                <div className="NavBar-Items_right">
                    {(!isTEAdmin(currentUser)) && <NotificationListContainer />}
                    {
                        (!isTEAdmin(currentUser))
                        && (
                            <a
                                href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'white', fontSize: 18, marginRight: 5 }}
                            >
                                <ButtonBox
                                    className="NavBar-Button"
                                    text="Support"
                                    tag="h5"
                                />
                            </a>
                        )
                    }

                    {
                        isMobile
                            ? (
                                <>
                                    {
                                        (!['TE.Admin', 'B2B.Admin'].includes(currentUser.user_type)) && (!isBusinessLicense(currentWorkplace?.license_type)) &&
                                        <Link to="/account/learn/course_packages">
                                            <ButtonBox
                                                className="NavBar-Button"
                                                text="Enroll"
                                            />
                                        </Link>
                                    }

                                    {
                                        (['B2C.User', 'B2B.User'].includes(currentUser.user_type)) &&
                                        <>
                                            <Link to="/learn">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Learn"
                                                />
                                            </Link>

                                            <Link to="/account/profile">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="My Profile"
                                                />
                                            </Link>

                                            <Link to="/account/analyze">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="My Progress"
                                                />
                                            </Link>

                                            <Link to="/account/certificate">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="My Certificates"
                                                />
                                            </Link>
                                        </>
                                    }

                                    {
                                        (currentUser.user_type === 'B2B.Admin') &&
                                        <>
                                            <Link to="/account/search">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Add/Search Users"
                                                />
                                            </Link>

                                            <Link to="/account/progress">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Generate Reports"
                                                />
                                            </Link>

                                            <Link to="/account/groups">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Manage Groups"
                                                />
                                            </Link>

                                            <Link to="/account/profile">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="My Account"
                                                />
                                            </Link>
                                        </>
                                    }

                                    {
                                        (currentUser.user_type === 'TE.Admin') &&
                                        <>
                                            <Link to="/creation">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Create"
                                                />
                                            </Link>

                                            <Link to="/learn">
                                                <ButtonBox
                                                    className="NavBar-Button"
                                                    text="Preview"
                                                />
                                            </Link>
                                        </>
                                    }

                                    <ButtonBox
                                        className="NavBar-Button"
                                        text="Log Out"
                                        onClick={() => finalizePendingActionsAndLogout(history, logout)}
                                    />
                                </>
                            )
                            : <UserDropdown />
                    }
                </div>
            );
        }

        return (
            <div className="NavBar-Items_right">
                <a href="https://withcanopy.com">
                    <ButtonBox
                        className="NavBar-Button"
                        text="Home"
                    />
                </a>
            </div>
        );
    };

    return (
        <div className="NavBar_profile">
            <div className="NavBar-Items_left">
                <a href="/#/account/learn">
                    <ImageBox
                        className="NavBar-Logo"
                        src="canopy.png"
                    />
                </a>

                {
                    !logoOnly &&
                    <div className="NavBar-Button_visible_mobile">
                        <ButtonBox
                            className="ButtonBox-Accordion"
                            src="accordion.png"
                            onClick={handleToggleMobileMenu}
                        />
                    </div>
                }
            </div>

            {!logoOnly && renderNavBarRightSide()}
        </div>
    );
};

ProfileNavBar.propTypes = {
    currentUser: PropTypes.shape({
        user_type: PropTypes.string,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default ProfileNavBar;
