import React, { useState } from 'react';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import NavigationSection from './NavigationSection';
import CircularProgressBar from '../../Basic/CircularProgressBar/CircularProgressBar';

const NavigationTopic = ({
  course,
  courseProgress,
  lesson,
  topic,
  setNavigationOpen,
  pathname
}) => {
  const [collapsed, setCollapsed] = useState(
    pathname.split('/')[7] && +pathname.split('/')[7] === topic.id ?
    false
    :
    true
  );

  const completionPercentage = () => {
    if (topic.sections.length === 0) {
      return 0;
    }

    return Math.floor(
      (
        (Object.keys(courseProgress?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses || {}).length)
        /
        (topic.sections.length)
      ) * 100
    );
  }


  const handleSectionRender = () => {
    return topic.sections.map(
      (section, index) =>
        <NavigationSection
          key={section.id}
          course={course}
          courseProgress={courseProgress}
          lesson={lesson}
          topic={topic}
          section={section}
          setNavigationOpen={setNavigationOpen}
          sectionIndex={index}
          pathname={pathname} />
    );
  }

  return (
    <div className="NavigationTopic">
      <div className="NavigationTopic-fixed">
        <div className="NavigationTopic-Title">
          <div
            onClick={() => setCollapsed(!collapsed)}
          >
            <TextBox
              text={topic.name}
            />
          </div>
          <div className="CoursesDashboard-Course-CircularProgressBar">
            <CircularProgressBar
              totalNumberOfSteps="100"
              completedSteps={completionPercentage()}
            />
          </div>
        </div>

        {
          collapsed ?
            <button
              className="NavigationTopic-Button"
              onClick={() => setCollapsed(false)}>
              <i className="fas fa-chevron-down"></i>
            </button>
            :
            <button
              className="NavigationTopic-Button"
              onClick={() => setCollapsed(true)}>
              <i className="fas fa-chevron-up"></i>
            </button>
        }
      </div>

      {
        !collapsed &&
        handleSectionRender()
      }
    </div>
  );
}

export default NavigationTopic;
