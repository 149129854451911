import * as Sentry from '@sentry/browser';

import * as ErrorResponses from '../Constants/ErrorResponses';

// Assume that the errors argument could be an object with multiple errors from Rails,
// or a single JavaScript exception, and that we want to handle both scenarios.
export default (errors) => {
    // Assume that we don't want to flood Sentry with local development errors when testing things
    const isDev = process.env.NODE_ENV === "development" ? true : false;
    if (!isDev) { Sentry.captureException(errors) };

    if (typeof errors === 'object') {
        // Assume that valid API responses with Rails model errors will come back with
        // a response property and hopefully a data field containing the model errors.
        if (errors?.response && typeof errors?.response === 'object') {
            // Assume that a valid API response with data had an error status code 400-500
            if (errors?.response?.data) {
                return errors.response.data;
            }

            // Assume something is wrong with API responses that have no data or json properties
            return ErrorResponses?.invalidApiData;
        }

        // Assume that we only care about displaying the message from JavaScript exceptions,
        // since the whole thing will be sent to Sentry.io for analysis.
        if (errors?.name && errors?.name?.toLowerCase()?.includes('error') && errors?.message) {
            return { message: `${errors.name} - ${errors.message}` };
        }

        // Assume that if this isn't a response object or a JS error but it has a message,
        // that we want to go ahead and display the message, whatever it is.
        if (errors?.message) {
            return { message: errors.message };
        }

        // Assume that objects with an error property contain info worth showing
        if (errors?.error) {
            return { message: errors.error };
        }

        // Assume that if the errors argument isn't in a structure we recognize,
        return ErrorResponses?.unrecognizedError;
    }

    // Assume that if the errors argument isn't an object,
    // we want to update the state with nothing rather than the wrong type.
    return {};
};