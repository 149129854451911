import { connect } from 'react-redux';

import CreateUserResource from './CreateUserResource';

import { clearInventories, fetchDetailedInventories } from '../../../../Actions/InventoryActions';
import { checkExistingUser, createUserResource } from '../../../../Actions/UserResourceActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  inventories: state.entities.inventories,
});

const mapDispatchToProps = (dispatch) => ({
  clearInventories: () => dispatch(clearInventories()),
  fetchDetailedInventories: () => dispatch(fetchDetailedInventories()),
  checkExistingUser: (user) => dispatch(checkExistingUser(user)),
  createUserResource: (userResource) => dispatch(createUserResource(userResource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserResource);
