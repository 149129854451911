import React, { Component } from 'react';

import './ActivationPage.css';
import { COLORS } from '../../../Style/Style';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBarContainer';
import TextBox from '../../Basic/TextBox/TextBox';

export class ActivationPage extends Component {
    componentDidMount() {
        const { confirmEmail, token } = this.props;
        confirmEmail(token);
    }

    render() {
        const { errors } = this.props;

        return (
            <div className="ActivationPage">
                <ProfileNavBar />
                <div className="ActivationPage-TextBox">
                    {
                        Object.keys(errors).length > 0
                            ? (
                                Object.keys(errors).map(
                                    (field, idx) => (
                                        <TextBox
                                            text={`${field.charAt(0).toUpperCase() + field.slice(1)} ${errors[field]}`}
                                            style={{
                                                color: COLORS.Blue,
                                            }}
                                            key={idx}
                                        />
                                    ),
                                )
                            )
                            : (
                                <TextBox
                                    text=" Activating your account! You will be redirected"
                                    style={{
                                        color: COLORS.Blue,
                                    }}
                                />
                            )
                    }
                </div>
            </div>
        );
    }
}

export default ActivationPage;
