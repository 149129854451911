import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { createCharge, receiveErrors } from '../../../Actions/StripeActions';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import CoursePackageBox from '../../Presentational/CoursePackageBox/CoursePackageBox';
import { verifyCode } from '../../../Util/StripeApiUtil';
import { payablePrice, discountedPrice, appliedDiscount, isValidZipCode } from '../../../Util/Helper/StripeUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { isB2C } from '../../../Util/Helper/UserWorkplaceUtil';

const mapStateToProps = (state) => ({
    coursePackages: state.entities.coursePackages,
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace,
    errors: state.errors.stripe,
    flash: state.flash.stripe,
});

const mapDispatchToProps = (dispatch) => ({
    createCharge: (token, coursePackageId, promoCodeId) => dispatch(createCharge(token, coursePackageId, promoCodeId)),
    receiveErrors: (errors) => dispatch(receiveErrors(errors)),
});

class UserDashboardPurchasePackage extends Component {
    _initialState = {
        fullName: '',
        firstNmae: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        promoCode: '',
        loading: false,
        promoError: {},
        promoData: null,
    }

    state = this._initialState;

    componentDidUpdate(prevProps) {
        if (this.props.errors && this.props.errors !== prevProps.errors) {
            this.setState({ loading: false });
        }
    }

    handleInputChange = field => e => {
        this.setState({
            [field]: e.currentTarget.value,
        });
    }

    handleSubmit = async () => {
        const {
            fullName,
            firstNmae,
            lastName,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
            country,
            promoData,
        } = this.state;

        const { stripe, elements, receiveErrors, createCharge, match } = this.props;

        if (!isValidZipCode(zipCode)) {
            receiveErrors({ message: 'Invalid zip code.' });
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        const { token, error } = await stripe.createToken(cardNumberElement, {
            name: fullName,
            address_line1: addressLine1,
            address_line2: addressLine2,
            address_city: city,
            address_state: state,
            address_zip: zipCode,
            address_country: country,
        });

        this.setState({ loading: true });

        if (error) {
            receiveErrors({ message: error.message });
        } else {
            createCharge(token, match.params.coursePackageId, promoData?.id);
        }
    }

    handlePromoCode = async (e) => {
        e.preventDefault();
        // verifyCode is not a thunk action creator because this api response is not going into redux
        this.setState({ loading: true });
        const { promoCode } = this.state;
        const { match } = this.props;
        try {
            const response = await verifyCode(promoCode, match.params.coursePackageId);
            this.setState({ promoData: response, loading: false, promoError: {} })
            gaEvent('promo_code_applied', { promo_code: response?.coupon?.name });
        } catch (error) {
            this.setState({ promoError: error.response.data, loading: false })
            gaEvent('promo_code_error');
        }
    }

    render() {
        const { courses, coursePackages, match, errors, flash, currentUser, currentWorkplace } = this.props;
        const {
            fullName,
            firstNmae,
            lastName,
            addressLine1,
            addressLine2,
            city,
            state,
            promoCode,
            zipCode,
            loading,
            promoData,
        } = this.state;
        const coursePackage = coursePackages[match.params.coursePackageId];
        const licenseCoupon = currentWorkplace.license?.course_packages_licenses?.filter(cpl => cpl.course_package_id === coursePackage.id)?.[0]?.coupon;

        if (flash.message === 'success') {
            window.fbq('track', 'Purchase', { currency: 'USD', value: coursePackage.price / 100 });
            gaEvent('purchase', { currency: 'USD', value: coursePackage.price / 100, affiliation: "google" })
            return (
                <div className="UserDashboardPurchasePackage__success">
                    <div className="UserDashboardPurchasePackage__container">
                        <i className="fas fa-check icon-purchase-success" />

                        <TextBox
                            text="Payment Successful!"
                            tag="h1"
                            style={{
                                color: '#6bd1be',
                                margin: '20px 0',
                                letterSpacing: '3px',
                                fontSize: '42px'
                            }}
                        />

                        <TextBox
                            text="To receive your Certificate(s) and optimize your usage experience,"
                            tag="h5"
                            style={{
                                color: COLORS.Blue,
                                letterSpacing: '1px',
                                fontSize: '24px'

                            }}
                        />

                        <TextBox
                            text="complete your full Canopy Profile"
                            tag="h5"
                            style={{
                                color: COLORS.Blue,
                                letterSpacing: '1px',
                                fontSize: '24px'
                            }}
                        />

                        {
                            isB2C(currentUser, currentWorkplace) &&
                            <p className="Purchase-Desc">
                              Your subscription to CanopyLearn will renew every {coursePackage.length} months. Please visit&nbsp;
                              <Link to="/account/profile?tab=my-progress&sec=subscriptions">Canopy Profile</Link>&nbsp;
                              to manage your settings.
                            </p>
                        }

                        <Link to="/learn">
                            <div style={{ width: '434px', marginTop: '30px', fontSize: '20px' }}>
                                <ButtonBox
                                    className="UserDashboardPurchasePackage-Button__submit"
                                    text="COMPLETE YOUR PROFILE"
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }

        return (
            <div className="UserDashboardPurchasePackage">
                <div className="heading">
                    Enroll to Canopy Learn
                </div>
                {
                    licenseCoupon &&
                    <div className="sub-heading Coupon-Banner">
                        {`Use coupon code ${licenseCoupon} provided by your institution to receive a discount!`}
                    </div>
                }
                <div>
                    <CoursePackageBox
                        coursePackage={coursePackage}
                        courses={courses}
                        currentUser={currentUser}
                        large
                        promoData={promoData}
                        premiumPackage={Object.values(coursePackages).find((coursePackage) => coursePackage.courses?.length > 1)}
                    />

                    <div className="OrderSummaryCard">
                      <div className="CardHeader">
                        Order Summary
                      </div>
                      <div className="CardBody">
                        <div className="InfoHolder">
                          <div className="cardTitle">{coursePackage.name}<br />{coursePackage.length} Month Individual Subscription</div>
                          <div className="cardText">${coursePackage.price / 100}</div>
                        </div>
                        {
                            promoData?.coupon &&
                            <div className="InfoHolder">
                                <div className="cardTitle">Coupon</div>
                                <div className="cardText">-${discountedPrice(coursePackage, promoData)}</div>
                            </div>
                        }
                        <div className="Divider"></div>
                        <div className="InfoHolder">
                          <div className="cardTitle">Total</div>
                          <div className="cardText">
                            ${promoData?.coupon ? (payablePrice(coursePackage, promoData)) : (coursePackage.price / 100)}
                        </div>
                        </div>
                      </div>

                    </div>

                    <div className="AuthPage-DataBox-InputBox">
                        <InputBox
                            className="UserDashboardPurchasePackage-InputBox"
                            value={promoCode}
                            placeholder="Promotion Code"
                            onChange={this.handleInputChange('promoCode')}
                        />
                    </div>

                    <ErrorFlashBox
                        errors={this.state.promoError}
                        style={{ marginBottom: '20px' }}
                    />

                    {
                        promoData
                            && (
                                <div className="PromoCodeBox__container">
                                    <div className="PromoCodeBox__inner">
                                        <TextBox
                                            text="COUPON APPLIED"
                                            style={{
                                                color: COLORS.Blue,
                                                margin: '10px',
                                            }}
                                        />

                                        <TextBox
                                            text={promoData.coupon?.name}
                                            style={{
                                                color: COLORS.Blue,
                                                margin: '10px',
                                            }}
                                        />

                                        <TextBox
                                            text={appliedDiscount(promoData)}
                                            style={{
                                                color: COLORS.Blue,
                                                margin: '10px',
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                    }

                    <ButtonBox
                        className="UserDashboardPurchasePackage-Button__submit mb-20"
                        text={loading ? <BeatLoader color='white' /> : 'APPLY CODE'}
                        onClick={this.handlePromoCode}
                        disabled={loading}
                    />
                </div>

                <div className="UserDashboardPurchasePackage-Form">
                    <div className="UserDashboardPurchasePackage-title">
                        <TextBox
                            text="Billing Information"
                            tag="h5"
                            style={{
                                color: COLORS.Blue,
                                fontSize: '28px',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        />
                    </div>

                    <div className="AuthPage-DataBox-InputBox">
                        <h5>NAME ON CARD</h5>
                        <InputBox
                            className="UserDashboardPurchasePackage-InputBox"
                            value={fullName}
                            onChange={this.handleInputChange('fullName')}
                        />
                    </div>

                    <h5>CARD NUMBER</h5>
                    <div className="CardElement">
                        <CardNumberElement />
                    </div>
                    <div className="customeField">
                      <h5 className="headingLeft">EXPIRATION DATE</h5>
                      <div className="CardElement">
                          <CardExpiryElement/>
                      </div>
                    </div>
                    <div className="customeField">
                      <h5>CVV/CVC</h5>
                      <div className="CardElement">
                          <CardCvcElement/>
                      </div>
                    </div>
                    <div className="customeField">
                      <div className="AuthPage-DataBox-InputBox">
                          <h5>ZIP CODE</h5>
                          <InputBox
                              className="UserDashboardPurchasePackage-InputBox"
                              value={zipCode}
                              onChange={e => this.setState({ zipCode: e.currentTarget.value })}
                          />
                      </div>
                    </div>

                    <ErrorFlashBox
                        errors={this.props.errors} />

                    <div style={{ marginTop: '30px', textAlign: 'center' }}>
                        <ButtonBox
                            className="UserDashboardPurchasePackage-Button__submit"
                            text={loading ? <BeatLoader color='white' /> : 'PAY & ENROLL'}
                            onClick={this.handleSubmit}
                            disabled={loading}
                        />
                    </div>

                    <p style={{fontSize: '14px', color: COLORS.greyBlue, margin: '40px 0px 10px 0px', textAlign: 'center'}}>
                        By enrolling to Canopy, you agree to our &nbsp;
                        <a href="terms_of_service.pdf" target="_blank"><u>Terms of Use</u></a>
                        &nbsp; and &nbsp;
                        <a href="privacy_policy.pdf" target="_blank"><u>Privacy Policy</u></a>
                    </p>
                </div>
            </div>
        );
    }
}

UserDashboardPurchasePackage.propTypes = {
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    coursePackages: PropTypes.objectOf(PropTypes.object).isRequired,
    match: PropTypes.shape({
        params: PropTypes.object,
    }).isRequired,
    stripe: PropTypes.shape({
        createToken: PropTypes.func,
    }).isRequired,
    receiveErrors: PropTypes.func.isRequired,
    createCharge: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    flash: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardPurchasePackage)
