import {
    FETCH_USER_PERFORMANCE_REQUEST,
    FETCH_USER_PERFORMANCE_SUCCESS,
    FETCH_USER_PERFORMANCE_ERROR,
    FETCH_USER_PERFORMANCE_CLEANUP,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_ERROR,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
} from '../../Actions/UserActions';

const defaultState = {
    users: {
        isError: false,
        isLoading: true,
        data: [],
        admins: [],
    },
    userDetail: {
        isError: false,
        isLoading: true,
        data: [],
    },
    user: {
        isError: false,
        isLoading: true,
        data: [],
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_USER_REQUEST:
            return {
                ...state,
                user: {
                    isError: false,
                    isLoading: true,
                    data: [],
                },
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: {
                    isError: false,
                    isLoading: false,
                    data: action.payload.data,
                },
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                user: {
                    isError: false,
                    isLoading: true,
                    data: action.error.data,
                },
            };
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                users: {
                    isError: false,
                    isLoading: true,
                    data: [],
                    admins: [],
                },
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: {
                    isError: false,
                    isLoading: false,
                    data: action.payload.data,
                    admins: action.payload.admins,
                },
            };
        case FETCH_USERS_ERROR:
            return {
                ...state,
                users: {
                    isError: true,
                    isLoading: false,
                    data: action.error.data,
                    admins: [],
                },
            };
        case FETCH_USER_PERFORMANCE_REQUEST:
            return {
                ...state,
                userDetail: {
                    isError: false,
                    isLoading: true,
                    data: [],
                },
            };
        case FETCH_USER_PERFORMANCE_SUCCESS:
            return {
                ...state,
                userDetail: {
                    isError: false,
                    isLoading: false,
                    data: action.payload.data,
                },
            };
        case FETCH_USER_PERFORMANCE_ERROR:
            return {
                ...state,
                userDetail: {
                    isError: true,
                    isLoading: false,
                    data: action.error.data,
                },
            };
        case FETCH_USER_PERFORMANCE_CLEANUP:
            return {
                ...state,
                userDetail: defaultState.userDetail,
            };
        default:
            return state;
    }
};
