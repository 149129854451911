export const complianceCoursePledgeTemplates = {
  'Report Concerns about Access': 'I would like to be more proactive in reporting concerns about language access.',
  'Improve Documentation': 'I would like to help improve my unit\'s documentation practices related to language access.',
  'Help Mitigate Risks': 'I would like to be more proactive about mitigating risk related to language barriers.',
  'Contribute to Policies & Programs': 'I would like to contribute to enhancing the policies and programs at our institution for serving our LEP communities.',
  'Download Medical Phrasebook': 'I would like to download a medical phrasebook by __target__.',
  'Implement Research Project': 'I would like to plan and implement a research project related to language access by __target__.',
}

export const pledgeTemplates = {
  'Complete Online Course': 'I would like to complete an online course in medical __language__ by __target__.',
  'Improve My Proficiency': 'I would like to improve my __language__ proficiency by __level__ levels on the __canopy_scale__ for Bilingual Medical proficiency by __target__.',
  'Take Proficiency Exam': 'I would like to take a medical language proficiency exam so that I can care for patients in __language__ by __target__.',
  'Practice Weekly': 'I would like to find others in my organization who I can connect with and practice __language__ with on a weekly basis.',
  'Teach Others': 'I would like to train or teach others medical __language__ as a tutor or instructor.',
}

export const complianceCoursePledgeResponse = {
  'Report Concerns about Access':      { head: 'Great!',         body: 'Please communicate concerns related to patient language barriers to your institution\'s Language Access Coordinator (LAC) or similar role. The LAC will record the details and the substance of the complaint, conduct a review, investigate, and, if applicable, report complaints to the vendor providing the service.' },
  'Improve Documentation':             { head: 'Excellent!',     body: 'Review Part 5 of the CanopyCompliance course so that you are up-to-date on what to document. You may also want to bring up the issue of documenting language services at your next unit meeting.' },
  'Help Mitigate Risks':               { head: 'Fantastic!',     body: 'It\'s great to include a plan for communicating with each LEP patient in case management meetings and reiterate it frequently during daily rounds. Speak up if there are any concerns about how a situation is being handled with an LEP patient with regard to language access or care delivery.' },
  'Contribute to Policies & Programs': { head: 'Wonderful!',     body: 'Get in touch with your institution’s Language Access Coordinator (LAC) to see what the latest opportunities are.' },
  'Download Medical Phrasebook':       { head: 'Fantastic!',     body: 'We will send you more information about medical phrasebooks available to you.' },
  'Complete Online Course':            { head: 'Super!',         body: 'We will send you more information about medical language training resources available to you.' },
  'Improve My Proficiency':            { head: 'Wonderful!',     body: 'We will send you more information about the Canopy Scale and the language training and assessment resources that are available to you.' },
  'Take Proficiency Exam':             { head: 'Awesome!',       body: 'We will send you more information about medical language proficiency exams available to you.' },
  'Practice Weekly':                   { head: 'That\'s great!', body: 'We will keep you posted as we work on developing a medical language conversational exchange program.' },
  'Teach Others':                      { head: 'Fantastic!',     body: 'We will send you more information about opportunities to be a medical language instructor.' },
  'Implement Research Project':        { head: 'Great!',         body: 'Get in touch with your institution\'s Language Access Coordinator (LAC) to see what the latest opportunities are.' },
}
