import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/SectionApiUtil';

export const RECEIVE_SECTION = 'RECEIVE_SECTION';
export const RECEIVE_SECTION_ERRORS = 'RECEIVE_SECTION_ERRORS';

export const receiveSection = (payload) => ({
    type: RECEIVE_SECTION,
    payload,
});

export const receiveErrors = (errors) => ({
    type: RECEIVE_SECTION_ERRORS,
    errors,
});

export const createSection = (section) => dispatch => (
    APIUtil.createSection(section).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editSection = (section) => dispatch => (
    APIUtil.editSection(section).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteSection = sectionId => dispatch => (
    APIUtil.deleteSection(sectionId).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const uploadSectionMedia = formData => dispatch => (
    APIUtil.uploadSectionMedia(formData).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveHigher = sectionId => dispatch => (
    APIUtil.moveHigher(sectionId).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveLower = sectionId => dispatch => (
    APIUtil.moveLower(sectionId).then(
        payload => dispatch(receiveSection(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const cloneSection = (targetSectionableType, targetSectionableId, sectionId) => dispatch => (
    APIUtil.cloneSection(targetSectionableType, targetSectionableId, sectionId).then(
        payload => payload,
        errors => ErrorResponseUtil(errors),
    )
);
