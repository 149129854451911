export const raceList = {
  '': 'Please select',
  'white': 'White',
  'black_or_african_american': 'Black or African American',
  'american_indian_or_alaska_native': 'American Indian or Alaska Native',
  'asian': 'Asian',
  'native_hawaiian_and_other_pacific_islander': 'Native Hawaiian and Other Pacific Islander',
  'two_or_more_races': 'Two or More Races',
  'other': 'Other',
}

export const ethnicityList = {
  '': 'Please select',
  'hispanic_or_latino': 'Hispanic or Latino',
  'not_hispanic_or_latino': 'Not Hispanic or Latino',
}

export const AdminUserTypesList = {
  'TE.Admin': 'TE Admin',
  'B2B.Admin': 'B2B Admin',
}
