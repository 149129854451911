import { connect } from 'react-redux';

import NotificationPage from './NotificationPage';

import { clearUserNotifications, fetchUserNotifications } from '../../../../Actions/UserNotificationActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  userNotifications: state.notifications.userNotifications,
});

const mapDispatchToProps = (dispatch) => ({
  clearUserNotifications: () => dispatch(clearUserNotifications()),
  fetchUserNotifications: (queryString) => dispatch(fetchUserNotifications(queryString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
