import axios from './AxiosUtil';

export const createAnswer = (answer) => {
  return axios.post("/api/answers", { answer }).then(
    res => res.data
  );
};

export const editAnswer = (answer) => {
  return axios.patch(`/api/answers/${answer.id}`, { answer }).then(
    res => res.data
  );
};

export const deleteAnswer = (answerId) => {
  return axios.delete(`/api/answers/${answerId}`).then(
    res => res.data
  );
};
