export const splitPledge = (pledge) => (
  pledge.split(/ |\.|,/)
);

export const isValidPledgeTarget = (target) => {
  const inputDate = new Date(target);
  inputDate.setHours(0, 0, 0, 0);

  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date();
  maxDate.setHours(0, 0, 0, 0);
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  return inputDate >= minDate && inputDate <= maxDate;
}
