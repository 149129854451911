import { connect } from 'react-redux';

import ProfileNavBar from './ProfileNavBar';

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNavBar);
