import { RECEIVE_REPORT, RECEIVE_REPORT_ERRORS } from '../../Actions/ReportActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_REPORT:
            if (action.message) {
                return { message: action.message };
            }
            return state;
        case RECEIVE_REPORT_ERRORS:
            return {};
        default:
            return state;
    }
};
