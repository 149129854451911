import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';

import CreateEditComment from './CreateEditComment';
import CommentDetail from './CommentDetail';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Modal from '../../Composed/Modal/Modal';

import { clearSpecificComments, fetchSpecificComments, destroyComment, unflagComment } from '../../../Actions/CommentActions';

const mapStateToProps = (state) => ({
  comments: state.entities.comments.specific
});

const mapDispatchToProps = (dispatch) => ({
  clearSpecificComments: () => dispatch(clearSpecificComments()),
  fetchSpecificComments: (criteria) => dispatch(fetchSpecificComments(criteria)),
  destroyComment: (criteria, comment) => dispatch(destroyComment(criteria, comment)),
  unflagComment: (criteria, comment) => dispatch(unflagComment(criteria, comment))
});

const CommentsPanel = ({ comments, clearSpecificComments, fetchSpecificComments, destroyComment, unflagComment, commentableType, commentableId, closePanel }) => {
  const [loading, setLoading] = useState(true);
  const criteria = { action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId }

  useEffect(() => {
    clearSpecificComments();
    fetchSpecificComments(criteria).then(() => setLoading(false));
  }, [clearSpecificComments, fetchSpecificComments]);

  const destroyCommentHandler = (e, commentId) => {
    e.preventDefault();
    destroyComment(criteria, { id: commentId });
  };

  const unflagCommentHandler = (e, commentId) => {
    e.preventDefault();
    unflagComment(criteria, { id: commentId });
  }

  const renderTitle = () => {
    return (
      <div className="Title-Container">
        <div className="Title-And-Btns">
          <h4 className="Title">Comments</h4>
          <div className="Btns">
            <Modal
              title="Add Comment"
              form={
                close =>
                <CreateEditComment
                  commentableType={commentableType}
                  commentableId={commentableId}
                  closeModal={close}
                />
              }
            >
              {
                open =>
                <ButtonBox
                  className="Btn-Small"
                  text={<i className="fa fa-plus" />}
                  onClick={open}
                />
              }
            </Modal>
            <ButtonBox
              className="Close"
              text={<i className="fa fa-close"></i>}
              onClick={() => closePanel()}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSticky = () => {
    if ((comments?.sticky || []).length === 0) {
      return (
        <div className="Comments-Empty">
          No Sticky Comments!!!
        </div>
      )
    }

    return (
      comments.sticky.map((commentData, idx) => (
        <CommentDetail
          key={`sticky-${idx}`}
          commentableType={commentableType}
          commentableId={commentableId}
          commentData={commentData}
          showEditBtn={true}
          showDestroyBtn={true}
          destroyCommentHandler={(e, commentId) => destroyCommentHandler(e, commentId)}
        />
      ))
    )
  }

  const renderFlag = () => {
    if (!(comments?.non_sticky || []).find(c => c.flags?.length > 0)) {
      return (
        <div className="Comments-Empty">
          No Flagged Comments!!!
        </div>
      )
    }

    return (
      comments.non_sticky.map((commentData, idx) => {
        if (commentData.flags.length === 0)
          return '';

        return (
          <CommentDetail
            key={`flag-${idx}`}
            commentableType={commentableType}
            commentableId={commentableId}
            commentData={commentData}
            showUnflagBtn={true}
            showDestroyBtn={true}
            showFlags={true}
            unflagCommentHandler={(e, commentId) => unflagCommentHandler(e, commentId)}
            destroyCommentHandler={(e, commentId) => destroyCommentHandler(e, commentId)}
          />
        )
      })
    )
  }

  const renderNonSticky = () => {
    if (!(comments?.non_sticky || []).find(c => c.flags.length === 0)) {
      return (
        <div className="Comments-Empty">
          No Comments!!!
        </div>
      )
    }

    return (
      comments.non_sticky.map((commentData, idx) => {
        if (commentData.flags.length !== 0)
          return '';

        return (
          <CommentDetail
            key={`non-sticky-${idx}`}
            commentableType={commentableType}
            commentableId={commentableId}
            commentData={commentData}
            showReplyBtn={true}
            showDestroyBtn={true}
            showReplies={true}
            destroyCommentHandler={(e, commentId) => destroyCommentHandler(e, commentId)}
          />
        )
      })
    )
  }

  if (loading) {
    return (
      <div className="Comments-Load">
        <ScaleLoader color="#fff" />
      </div>
    )
  }

  return (
    <div className ="Comments-View">
      <div className="modal">
        <div className="modal-content">
          <div className="Comments-View-Title">
            {renderTitle()}
          </div>

          <div className="Comments-View-Sticky">
            {renderSticky()}
          </div>

          <div className="Comments-View-Flagged">
            {renderFlag()}
          </div>

          <div className="Comments-View-NonSticky">
            {renderNonSticky()}
          </div>
        </div>
      </div>
    </div>
  )
};

CommentsPanel.propTypes = {
  comments: PropTypes.object.isRequired,
  clearSpecificComments: PropTypes.func.isRequired,
  fetchSpecificComments: PropTypes.func.isRequired,
  destroyComment: PropTypes.func.isRequired,
  unflagComment: PropTypes.func.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  closePanel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPanel);
