import {
  CLEAR_SESSION_ERRORS,
  RECEIVE_CURRENT_USER,
  RECEIVE_SESSION_ERRORS,
  RECEIVE_SESSION_FLASH,
  RECEIVE_UNLOCK_FLASH,
} from '../../Actions/SessionActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_SESSION_FLASH:
      if (action.message) {
        return { message: action.message }
      }
      return state;
    case RECEIVE_UNLOCK_FLASH:
      if (action.payload?.email) {
        return { message: `Successfully unlocked ${action.payload.email}!` }
      }
      return state;
    case RECEIVE_CURRENT_USER:
      return {};
    case RECEIVE_SESSION_ERRORS:
      return {};
    case CLEAR_SESSION_ERRORS:
      return {};
    default:
      return state;
  }
};
