import { connect } from 'react-redux';

import {
  fetchTopic,
  editTopic,
  clearTopics,
  clearTopicErrors,
} from '../../../Actions/TopicActions';
import {
  createSection
} from '../../../Actions/SectionActions';
import CreationLearningDashboard
  from './CreationLearningDashboard';

const mapStateToProps = (state, { match }) => {
  return {
    course:
      state.entities.courses[match.params.courseId],
    lessons:
      state.entities.lessons,
    currentTopicId:
      match.params.topicId,
    topic:
      state.entities.topics[match.params.topicId],
    topicErrors:
      state.errors.topics,
    topicFlash:
      state.flash.topics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopic:
      lessonId => dispatch(fetchTopic(lessonId)),
    editTopic:
      quiz => dispatch(editTopic(quiz)),
    clearTopics:
      () => dispatch(clearTopics()),
    clearTopicErrors:
      () => dispatch(clearTopicErrors()),
    createSection:
      section => dispatch(createSection(section)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreationLearningDashboard);