import axios from './AxiosUtil';

export const fetchAllComments = (pageQs) => {
  return axios.get(`/api/comments/fetch_all?${pageQs}`).then(
    res => res.data
  )
};

export const bulkUnflag = (commentIds, pageQs) => {
  return axios.post(`/api/comments/bulk_unflag?${pageQs}`, { commentIds }).then(
    res => res.data
  )
};

export const bulkDelete = (commentIds, pageQs) => {
  return axios.post(`/api/comments/bulk_delete?${pageQs}`, { commentIds }).then(
    res => res.data
  )
};

export const fetchSpecificComments = (criteria) => {
  return axios.post('/api/comments/fetch', { criteria }).then(
    res => res.data
  )
};

export const createComment = (criteria, comment) => {
  return axios.post('/api/comments', { criteria, comment }).then(
    res => res.data
  )
};

export const updateComment = (criteria, comment) => {
  return axios.patch(`/api/comments/${comment.id}`, { criteria, comment }).then(
    res => res.data
  )
};

export const destroyComment = (criteria, comment) => {
  return axios.post(`/api/comments/${comment.id}/delete`, { criteria, comment }).then(
    res => res.data
  )
};

export const unflagComment = (criteria, comment) => {
  return axios.post(`/api/comments/${comment.id}/unflag`, { criteria, comment }).then(
    res => res.data
  )
};

export const likeComment = (criteria, reaction) => {
  return axios.post('/api/comments/like', { criteria, reaction }).then(
    res => res.data
  )
};

export const flagComment = (criteria, reaction) => {
  return axios.post('/api/comments/flag', { criteria, reaction }).then(
    res => res.data
  )
};
