import React
  from 'react';

import './TrueOrFalseBox.css';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import ImageBox
  from '../../Basic/ImageBox/ImageBox';
import { COLORS }
  from '../../../Style/Style';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

/**
* @class - TrueOrFalseBox - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const TrueOrFalseBox = (props) => {
  const { section, lessonId, questions, currentQuestionIndex, quizProgress } = props;
  const currentQuestion = questions[currentQuestionIndex];

  const toggleClickHandler = () => {
    gaEvent('true_false_select', { section_id: section.id });
    return (e) => {
      recordProgress(e.currentTarget.dataset.id);
    };
  };

  const recordProgress = (userValue) => {
    const answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            0: {
              text:       userValue,
              is_correct: userValue === currentQuestion.answers[0].text,
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  }

  const pinUnansweredRender = () => {
    return (
      props.pinUnanswered &&
      !quizProgress?.[section.id]?.[currentQuestion.id]?.[0] &&
      (
        <div className="Pin-Unanswered">
          <ImageBox className="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  if (props.revealAnswers && !quizProgress?.[section.id]?.[currentQuestion.id]?.[0]?.is_correct) {
    recordProgress(currentQuestion.answers[0].text);
  }

  const buttons = ["True", "False"].map((option, idx) => {
    let className;

    if (quizProgress?.[section.id]?.[currentQuestion.id]?.[0]) {
      if (quizProgress[section.id][currentQuestion.id][0].text === option) {
        className = 'TrueOrFalseBox-Button__selected';
      } else {
        className = 'TrueOrFalseBox-Button';
      }
    } else {
      className = 'TrueOrFalseBox-Button';
    }

    return (
      <div
        className="TrueOrFalseBox-ButtonBox-div"
        key={ idx }>
        <ButtonBox
          className={ className }
          onClick={ toggleClickHandler() }
          text={ option }
          id={ option }
        />
      </div>
    );
  });

  return (
    <div className='TrueOrFalseBox'>
      { pinUnansweredRender() }
      <div
        className="TrueOrFalseBox-QuestionBox">
        {
          !props.isComplianceCourse && (
            !props.small &&
            <div
              className="FillInTheBlankBox-QuestionBox-Position">
              <TextBox
                text={currentQuestion.position} />
            </div>
          )
        }

        <QuestionBox question={ currentQuestion }>
          <TextBox
            text={ currentQuestion.text }
            tag='p'
            style={{
              color: COLORS.Blue
            }}/>
        </QuestionBox>
      </div>

      <div className='TrueOrFalseBox-ButtonBox-container'>
        {
          props.checkAnswers && quizProgress?.[section.id]?.[currentQuestion.id]?.[0] &&
          (
            quizProgress[section.id][currentQuestion.id][0].is_correct ?
              <i class="fas fa-check icon-correct"></i>
              :
              <i class="fas fa-times icon-wrong"></i>
          )
        }
        { buttons }
      </div>
    </div>
  );
};

export default TrueOrFalseBox;
