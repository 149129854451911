import React from 'react';
import PropTypes from 'prop-types';

import './Pill.css';

const Pill = ({ className, text }) => {
    return (<span className="Pill-Container"><span className={className}>{text}</span></span>);
};

Pill.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default Pill;
