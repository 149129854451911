import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageBox from '../../../Basic/ImageBox/ImageBox';
import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import StackedChart from '../../../HighCharts/VerticalStackedChart/StackedChart';

import { clearComplianceStats, fetchPledges } from '../../../../Actions/Stats/Users/ComplianceActions';
import { pledgeTemplates } from '../../../../Constants/PledgesConstants';

const mapStateToProps = (state) => ({
  myPledges:        (state.stats.users.compliances?.Pledges?.myPledges || {}),
  communityPledges: (state.stats.users.compliances?.Pledges?.communityPledges || {})
});

const mapDispatchToProps = (dispatch) => ({
  clearComplianceStats: (key) => dispatch(clearComplianceStats(key)),
  fetchPledges: () => dispatch(fetchPledges())
});

const Pledges = ({ myPledges, communityPledges, clearComplianceStats, fetchPledges, contentWidth }) => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    clearComplianceStats('Pledges');
    fetchPledges().then(res => setLoading(false));
  }, [clearComplianceStats, fetchPledges]);

  useEffect(() => {
    if (!loading)
      setShowChart(true)
  }, [loading]);

  const injectPlaceholderValues = (template, language, level, target) => (
    template.replaceAll('__language__', language)
            .replaceAll('__level__', level)
            .replaceAll('__target__', target)
            .replaceAll('__canopy_scale__', 'Canopy Scale')
  )

  const renderEmpty = () => (
    <Link to="/account/profile/?tab=my-progress" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Add Pledges!"
       />
    </Link>
  );

  const renderTabs = () => {
    return (
      <div
        className="Tab-Container"
        style={{
          width: `${contentWidth}px`
        }}
      >
        <div className="Tabs">
          {
            Object.keys(myPledges).map((lingo, idx) => (
              <div
                key={`tab-${idx}`}
                children={lingo}
                className={`Tab ${tab === idx ? 'Active' : ''}`}
                onClick={() => setTab(idx)}
              />
            ))
          }
        </div>

        <div className="Link">
          <Link
            to="/account/profile/?tab=my-progress&sec=pledges"
            className="ManagePledgeLink"
          >
            Manage Pledges
          </Link>
        </div>
      </div>
    )
  }

  const renderMyPledgesChart = () => {
    const [activeLanguage, activeLanguageMyPledges] = Object.entries(myPledges)[tab];
    const myPledgesSeriesData = { completed: [], in_progress: [] };

    activeLanguageMyPledges.map((pledge) => {
      myPledgesSeriesData.completed.push({
        y: (pledge.status === 'completed' ? 100 : 0),
        users: (pledge.status === 'completed' ? 1 : 0),
        template: injectPlaceholderValues(pledgeTemplates[pledge.short_description], activeLanguage, pledge.level, pledge.target)
      });
      myPledgesSeriesData.in_progress.push({
        y: (pledge.status === 'in_progress' ? 100 : 0),
        users: (pledge.status === 'in_progress' ? 1 : 0),
        template: injectPlaceholderValues(pledgeTemplates[pledge.short_description], activeLanguage, pledge.level, pledge.target)
      });
    });

    const myPledgesChartData = {
      title: 'My Pledges',
      xAxis: activeLanguageMyPledges.map(pledge => pledge.short_description),
      yAxisTitle: 'Progress (%)',
      tooltip:  `<p class="heading">{{POINT_0_X}}</p>` +
                `<p class="sub-heading">{{POINT_0_POINT_TEMPLATE}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_0_PERCENTAGE}}% Completed -- {{POINT_0_POINT_USERS}} users</span>` +
                `</div>` +
                `<div class="in-progress">` +
                  `<span class="box" style="backgroundColor: {{POINT_1_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_1_PERCENTAGE}}% In Progress -- {{POINT_1_POINT_USERS}} users</span>` +
                `</div>`,
      series: [
        { name: 'Completed',  color: '#80cbc4', data: myPledgesSeriesData.completed },
        { name: 'Inprogress', color: '#7986cb', data: myPledgesSeriesData.in_progress }
      ]
    };

    return (
      <div className="My-Pledge-Chart-Containter">
        <div
          className="myPledgesChart VerticalStackedChart"
          id="myPledgesStackChart"
        >
          My Pledges
          {
            showChart &&
            <StackedChart
              id="myPledgesStackChart"
              data={myPledgesChartData}
            />
          }
        </div>
        <div className="Badge-Holder">
          {
            activeLanguageMyPledges?.find((pledge) => pledge.status === 'completed') && (
              <div className="Completed-Badge">
                You've completed these pledges
                <ImageBox src="icon_pledge_complete.svg" className="Badge Icon-Pledge-Complete" />
              </div>
            )
          }
          {
            activeLanguageMyPledges?.find((pledge) => pledge.status === 'in_progress') && (
              <div className="Badge Progress-Badge">
                These pledges are in progress
                <i className="fa fa-spinner Badge Badge-Spinner-Icon"></i>
              </div>
            )
          }
        </div>
      </div >
    );
  }

  const renderCommunityPledgesChart = () => {
    const [activeLanguage, activeLanguageMyPledges] = Object.entries(myPledges)[tab];
    const activeLanguageCommunityPledges = Object.entries(communityPledges[activeLanguage] || {});
    const communityPledgesSeriesData = { completed: [], in_progress: [] }

    activeLanguageCommunityPledges.map(([description, descData]) => {
      communityPledgesSeriesData.completed.push({
        y: Math.round(((descData.completed)  / (descData.total)) * 100),
        users: descData.completed,
        template: injectPlaceholderValues(pledgeTemplates[description], activeLanguage, `${descData.levels[0] || ''}..${descData.levels.at(-1) || ''}`, `${descData.targets[0] || ''}..${descData.targets.at(-1) || ''}`)
      });
      communityPledgesSeriesData.in_progress.push({
        y: Math.round(((descData.inProgress) / (descData.total)) * 100),
        users: descData.inProgress,
        template: injectPlaceholderValues(pledgeTemplates[description], activeLanguage, `${descData.levels[0] || ''}..${descData.levels.at(-1) || ''}`, `${descData.targets[0] || ''}..${descData.targets.at(-1) || ''}`)
      });
    });

    const communityPledgesChartData = {
      title: 'Organization Pledges',
      subtitle: (activeLanguageCommunityPledges.length === 0 ? '<div class="Sub-Title">Users in your organization have not made enough pledges</div>' : ''),
      legendTitle: 'Users in my Organization:',
      xAxis: activeLanguageCommunityPledges.map(([k, v]) => k),
      yAxisTitle: 'Progress (%)',
      tooltip:  `<p class="heading">{{POINT_0_X}}</p>` +
                `<p class="sub-heading">{{POINT_0_POINT_TEMPLATE}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_0_PERCENTAGE}}% Completed -- {{POINT_0_POINT_USERS}} users</span>` +
                `</div>` +
                `<div class="in-progress">` +
                  `<span class="box" style="backgroundColor: {{POINT_1_COLOR}}"></span>` +
                  `<span class="percentage">{{POINT_1_PERCENTAGE}}% In Progress -- {{POINT_1_POINT_USERS}} users</span>` +
                `</div>`,
      series: [
        { name: 'Completed',  color: '#80cbc4', data: communityPledgesSeriesData.completed },
        { name: 'Inprogress', color: '#7986cb', data: communityPledgesSeriesData.in_progress }
      ]
    };

    return (
      <div
        className="communityPledgesChart VerticalStackedChart"
        id="communityPledgesStackChart"
      >
        Community Pledges
        {
          showChart &&
          <StackedChart
            id="communityPledgesStackChart"
            data={communityPledgesChartData}
          />
        }
      </div>
    )
  }

  const renderPledges = () => (
    <div className="CardHolder">
      {renderTabs()}
      <div
        className="AllCharts"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {renderMyPledgesChart()}
        {renderCommunityPledgesChart()}
      </div>
    </div>
  );
  
  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    if (Object.entries(myPledges).length === 0) {
      return renderEmpty();
    }

    return renderPledges();
  }

  return (
    <div className="Pledges-Container">
      <h2 className="Title">Pledges</h2>
      {renderContent()}
    </div>
  )
}

Pledges.propTypes = {
  myPledges: PropTypes.object.isRequired,
  communityPledges: PropTypes.object.isRequired,
  clearComplianceStats: PropTypes.func.isRequired,
  fetchPledges: PropTypes.func.isRequired,
  contentWidth: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Pledges);
