import { merge } from 'lodash';
import {
    RECEIVE_QUIZ_STATE,
    RESET_QUIZ_ANSWER,
    CLEAR_QUIZ_STATES,
} from '../../Actions/QuizActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_QUIZ_STATE:
            let newState = Object.assign({}, state);
            newState = merge(newState, action.state);
            return newState;
        case RESET_QUIZ_ANSWER:
            let newState2 = Object.assign({}, state);
            if (newState2[action.answer.sectionId]) {
                delete newState2[action.answer.sectionId];
            }
            return newState2;
        case CLEAR_QUIZ_STATES:
            return {};
        default:
            return state;
    }
};
