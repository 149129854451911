import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CreateEditLingo from '../../../Forms/CreateEditLingo/CreateEditLingo';
import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../Basic/ImageBox/ImageBox';

import { clearLingos, fetchLingos, createLingo, updateLingo } from '../../../../Actions/LingoActions';
import { languagesList } from '../../../../Constants/LanguageConstants';

import './Lingo.scss';

const mapStateToProps = (state) => ({
  lingos: state.entities.lingos
});

const mapDispatchToProps = (dispatch) => ({
  clearLingos: () => (dispatch(clearLingos())),
  fetchLingos: () => (dispatch(fetchLingos())),
  createLingo: lingo => (dispatch(createLingo(lingo))),
  updateLingo: lingo => (dispatch(updateLingo(lingo))),
});

const Lingo = ({ currentUser, lingos, clearLingos, fetchLingos, createLingo, updateLingo, displayProfileWingsModal }) => {
  const [loading, setLoading] = useState(true);
  const [newLingo, setNewLingo] = useState(false);

  useEffect(() => {
    clearLingos();
    fetchLingos().then(res => setLoading(false));
  }, [fetchLingos]);

  if (loading) {
    return <Loader />
  }

  const totalLingos = Object.values(lingos).length;

  return (
    <div className="Lingo-Container">
      <div className="Info-Text">
        Adding info about your language background will help us support you in your medical language journey. You can add as many languages as applicable.
      </div>

      {
        (Object.keys(lingos).length === 0) &&
        <div className="Add-Language-Info">
          <p>Let's get started by adding a language</p>
          <ImageBox className="Add-Language-Target-Icon" src="down.svg" />
        </div>
      }

      <div className="My-Lingos">
        {
           Object.values(lingos).map((lingo, idx) => (
            <div key={idx} className="My-Lingo">
              <CreateEditLingo
                currentUser={currentUser}
                lingo={lingo}
                updateLingo={updateLingo}
                cardTitle={languagesList[lingo.language]}
                displayProfileWingsModal={displayProfileWingsModal}
              />
            </div>
          ))
        }
      </div>

      <div className="New-Lingo-ButtonBox">
      {
        !newLingo &&
          <ButtonBox
            className="New-Lingo-Button"
            text={
              <span className="Btn">
                <ImageBox src="add.svg" className="Icon" />
                Add Language
              </span>
            }
            onClick={() => setNewLingo(true)}
          />
      }
      </div>

      {
        newLingo &&
        <div className="New-Lingos">
          <div className="New-Lingo">
            <CreateEditLingo
              currentUser={currentUser}
              createLingo={createLingo}
              cancelNewLingo={() => setNewLingo(false)}
              cardTitle="New Language"
              displayProfileWingsModal={displayProfileWingsModal}
            />
          </div>
        </div>
      }
    </div>
  )
}

Lingo.propTypes = {
  currentUser: PropTypes.object.isRequired,
  createLingo: PropTypes.func.isRequired,
  updateLingo: PropTypes.func.isRequired,
  displayProfileWingsModal: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Lingo);
