import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './LicenseBox.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Confirm from '../../Composed/Confirm/Confirm';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

const LicenseBox = ({ license, deleteLicense }) => {
    const [processing, setProcessing] = useState(false);

    const handleDeleteLicense = licenseId => e => {
        e.preventDefault();
        setProcessing(true);
        deleteLicense(licenseId).then(() => setProcessing(false));
    };

    return (
        <div className="LicenseBox">
            {processing && <MirrorLoader message='Processing...' />}
            <div className="LicenseBox__divider">
                <i className="far fa-file" />

                <TextBox
                    text={license?.access_code}
                    style={{
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div>
                <Link to={`/account/licenses/${license.id}`}>
                    <ButtonBox
                        className="LicenseBox__button"
                        text="Edit"
                    />
                </Link>

                <Confirm
                    text="Are you sure you want to delete this license?"
                    confirm={handleDeleteLicense(license.id)}
                >
                    {
                        open => (
                            <ButtonBox
                                className="LicenseBox__button red"
                                text="Delete"
                                onClick={open}
                            />
                        )
                    }
                </Confirm>
            </div>
        </div>
    );
};

LicenseBox.propTypes = {
    license: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    deleteLicense: PropTypes.func.isRequired,
};

export default LicenseBox;
