import {
  CLEAR_NET_PROMOTER_SCORES,
  RECEIVE_NET_PROMOTER_SCORES,
} from '../../Actions/NetPromoterScoreActions';

const NetPromoterScoresReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_NET_PROMOTER_SCORES:
      return Object.assign({}, state, action.payload.netPromoterScores );
    case CLEAR_NET_PROMOTER_SCORES:
      return {};
    default:
      return state;
  }
}

export default NetPromoterScoresReducer;
