import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/GroupApiUtil';

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const RECEIVE_GROUP_ERRORS = 'RECEIVE_GROUP_ERRORS';
export const RECEIVE_GROUP = 'RECEIVE_GROUP';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';

export const receiveGroups = payload => {
    return {
        type: RECEIVE_GROUPS,
        payload,
    };
};

export const clearGroups = () => {
    return {
        type: CLEAR_GROUPS,
    };
};

export const receiveGroup = payload => ({
    type: RECEIVE_GROUP,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_GROUP_ERRORS,
    errors,
});

export const fetchGroups = () => dispatch => (
    APIUtil.fetchGroups().then(
        payload => dispatch(receiveGroups(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchGroup = groupId => dispatch => (
    APIUtil.fetchGroup(groupId).then(
        payload => dispatch(receiveGroup(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createGroup = (group) => dispatch => (
    APIUtil.createGroup(group).then(
        payload => dispatch(receiveGroup(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editGroup = (group) => dispatch => (
    APIUtil.editGroup(group).then(
        payload => dispatch(receiveGroup(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteGroup = groupId => dispatch => (
    APIUtil.deleteGroup(groupId).then(
        () => dispatch(clearGroups()),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
