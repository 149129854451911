import {
  RECEIVE_ROSTER_MANAGEMENT,
  RECEIVE_ROSTER_MANAGEMENT_ERRORS,
  RECEIVE_USER_ROSTER_UPLOAD_INITIAL_STATE,
  RECEIVE_USER_ROSTER_UPLOAD_CHUNK,
  CLEAR_ROSTER_MANAGEMENT
} from '../../Actions/RosterManagementActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ROSTER_MANAGEMENT:
      return Object.assign({}, state, action.payload.rosterManagement);
    case RECEIVE_USER_ROSTER_UPLOAD_INITIAL_STATE:
      return action.payload;
    case RECEIVE_USER_ROSTER_UPLOAD_CHUNK:
      let newState = { ...state };

      if (action.chunk.event === 'error') {
        newState.event = action.chunk.event;
        newState.message = action.chunk.message;
        newState.users_new.emails = action.chunk.emails || [];
      }

      if (action.chunk.event === 'processed') {
        newState.event = action.chunk.event;
        newState.message = action.chunk.message;
        newState[action.chunk.action].emails.push(action.chunk.email);
      }

      if (action.chunk.event === 'success') {
        newState.event = action.chunk.event;
        newState.message = action.chunk.message;
      }

      if (['error', 'success', 'closed', 'crashed'].includes(action.chunk.event)) {
        newState.finished = true;
      }

      return newState;
    case CLEAR_ROSTER_MANAGEMENT:
      return {};
    default:
      return state;
  }
}
