import { RECEIVE_COURSES_TREE } from '../../Actions/CourseActions';

const coursesTreeReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_COURSES_TREE:
      return Object.assign({}, state, action.payload.coursesTree);
    default:
      return state;
  }
}

export default coursesTreeReducer;
