import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './LessonsDashboard.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import LessonsSummaryBox from '../../Composed/LessonsSummaryBox/LessonsSummaryBox';
import { syncSeatTime } from '../../../Actions/SeatTimeActions';
import Loader from '../../Composed/Loader/Loader';
import CertificateLocator from '../../Composed/CertificateLocator/CertificateLocator';

const propTypes = {
    clearTopics: PropTypes.func.isRequired,
    fetchLessons: PropTypes.func.isRequired,
    currentCourseId: PropTypes.string.isRequired,
    receiveCourseId: PropTypes.func.isRequired,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    saveCourseProgress: PropTypes.func.isRequired,
    course: PropTypes.shape({
        name: PropTypes.string,
        lessons: PropTypes.array,
        num_lessons: PropTypes.number,
    }).isRequired,
    lessons: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    quizResults: PropTypes.objectOf(PropTypes.array).isRequired,
};

class LessonsDashboard extends Component {
    state = { loading: true }

    componentDidMount() {
        syncSeatTime();
        const {
            clearTopics,
            fetchLessons,
            currentCourseId,
            receiveCourseId,
            courseProgresses,
            saveCourseProgress,
        } = this.props;

        clearTopics();
        fetchLessons(currentCourseId).then(() => this.setState({ loading: false }));
        receiveCourseId(+currentCourseId);
        if (!courseProgresses[currentCourseId]) {
            saveCourseProgress({
                course_id: currentCourseId,
            });
        }

        let queryString = new URLSearchParams(this.props.location.search);
        if (queryString.has('nextToq')) {
            setTimeout(() => {
                let pathHash = window.location.hash.split('?');
                let nextToq  = queryString.get('nextToq');

                if (!(pathHash[0].includes('topics') || pathHash[0].includes('quiz')))
                    window.location.hash = `${pathHash[0]}${nextToq}?${pathHash[1]}`
            }, 2000);
        }
    }

    render() {
        const {
            lessons,
            course,
            courseProgresses,
            currentUser,
            quizResults,
        } = this.props;

        if (this.state.loading) {
            return <Loader message="Loading, Hang on!" />;
        }

        if (Object.keys(lessons).length === 0
            || Object.keys(lessons).length < course.lessons.length) {
            return <div />;
        }

        return (
            <div className="LessonsDashboard">
                <div className="LessonsDashboard-Title__container">
                    <TextBox
                        text={course.name}
                        tag="h1"
                        style={{
                            color: COLORS.Blue,
                        }}
                    />

                    <div className="LessonsDashboard-Buttons">
                        <CertificateLocator course={course} lessons={lessons} quizResults={quizResults} />
                        <Link to="/learn/bookmarks">
                            <ButtonBox
                                className="LessonsDashboard-ButtonBox Secondary-Btn"
                                text={(<span className="Secondary-Btn-Text"><i className="fas fa-bookmark" /> All Bookmarks</span>)}
                            />
                        </Link>
                    </div>
                </div>
                <div className="LessonsDashboard-Databox-LessonsSummaryBox">
                    <LessonsSummaryBox
                        lessons={lessons}
                        lessonsOrdering={course.lessons}
                        course={course}
                        courseProgresses={courseProgresses}
                        currentUser={currentUser}
                        quizResults={quizResults}
                    />
                </div>
            </div>
        );
    }
}

LessonsDashboard.propTypes = propTypes;

export default LessonsDashboard;
