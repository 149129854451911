import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { finalizePendingActionsAndLogout } from '../../../Util/Helper/UserWorkplaceUtil';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import './Indolence.scss';

const Indolence = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { logout } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    let inactivityTimer = null;
    let logoutTimer = null;

    const resetTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(logoutTimer);

      inactivityTimer = setTimeout(() => {
        setShowDialog(true);
        logoutTimer = setTimeout(() => {
          finalizePendingActionsAndLogout(history, logout)
        }, 5 * 60 * 1000);
      }, 25 * 60 * 1000);
    }

    window.addEventListener('click', resetTimers);
    window.addEventListener('keypress', resetTimers);

    resetTimers();

    return () => {
      clearTimeout(inactivityTimer);
      clearTimeout(logoutTimer);
      window.removeEventListener('click', resetTimers);
      window.removeEventListener('keypress', resetTimers);
    }
  }, [history, logout]);

  if (!showDialog) {
    return '';
  }

  return (
    <div className="Indolence modal">
      <div className="modal-content">
        <div className="Title">
          <div
            className="Text"
            children="Are you still there?"
          />
        </div>

        <div className="Content">
          <div
            className="Text"
            children={"You will be logged out automatically due to inactivity. Please choose \"Stay logged in\" to avoid automatic logout."}
          />
        </div>

        <div className="Actions">
          <ButtonBox
            className="Stay Primary-Btn"
            text={<span className="Primary-Btn-Text">Stay logged in</span>}
            onClick={(e) => {
              e.preventDefault();
              setShowDialog(false);
            }}
          />

          <ButtonBox
            className="Leave Alert-Btn"
            text={<span className="Alert-Btn-Text">Logout</span>}
            onClick={(e) => {
              e.preventDefault();
              finalizePendingActionsAndLogout(history, logout);
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Indolence;
