import { connect } from 'react-redux';

import SectionClone from './SectionClone';

import { fetchCoursesTree } from '../../../../Actions/CourseActions';
import { cloneSection } from '../../../../Actions/SectionActions';

const mapStateToProps = (state) => ({
  courses: state.entities.coursesTree,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoursesTree: () => dispatch(fetchCoursesTree()),
  cloneSection: (targetSectionableType, targetSectionableId, sectionId) => dispatch(cloneSection(targetSectionableType, targetSectionableId, sectionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionClone);
