import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/FlashcardApiUtil';

export const RECEIVE_FLASHCARD_ERRORS = 'RECEIVE_FLASHCARD_ERRORS';
export const RECEIVE_FLASHCARDS = 'RECEIVE_FLASHCARDS';
export const CLEAR_FLASHCARDS = 'CLEAR_FLASHCARDS';
export const RECEIVE_FLASHCARD = 'RECEIVE_FLASHCARD';

export const receiveFlashcards = payload => {
  return {
    type: RECEIVE_FLASHCARDS,
    payload,
  };
};

export const clearFlashcards = () => {
  return {
    type: CLEAR_FLASHCARDS,
  };
};

export const receiveFlashcard = payload => ({
  type: RECEIVE_FLASHCARD,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_FLASHCARD_ERRORS,
  errors,
});

export const createFlashcard = (flashcard) => dispatch => (
  APIUtil.createFlashcard(flashcard).then(
    payload => dispatch(receiveFlashcard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editFlashcard = (flashcard) => dispatch => (
  APIUtil.editFlashcard(flashcard).then(
    payload => dispatch(receiveFlashcard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteFlashcard = flashcardId => dispatch => (
  APIUtil.deleteFlashcard(flashcardId).then(
    payload => dispatch(receiveFlashcard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const uploadFlashcardAudio = formData => dispatch => (
  APIUtil.uploadAudio(formData).then(
    payload => dispatch(receiveFlashcard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);