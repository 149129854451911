import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateLicense, clearLicenseFlash } from '../../../Actions/LicenseActions';
import { fetchCourses } from '../../../Actions/CourseActions';
import { fetchCoursePackages } from '../../../Actions/CoursePackageActions';
import EditLicense from './EditLicense';

const mapStateToProps = (state, ownProps) => ({
    errors: state.errors.license,
    flash: state.flash.license,
    history: ownProps.history,
    courses: state.entities.courses,
    coursePackages: state.entities.coursePackages,
});

const mapDispatchToProps = (dispatch) => ({
    editLicense: license => dispatch(updateLicense(license)),
    clearLicenseFlash: () => dispatch(clearLicenseFlash()),
    fetchCourses: () => dispatch(fetchCourses()),
    fetchCoursePackages: () => dispatch(fetchCoursePackages()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLicense));