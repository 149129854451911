import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loader from '../../../Composed/Loader/Loader';
import LineChart from '../../../HighCharts/LineChart/LineChart';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearLearnStats, fetchMyProgresses } from '../../../../Actions/Stats/Users/LearnActions';

const mapStateToProps = (state) => ({
  progresses: Object.entries(state.stats.users.learns?.myProgresses || {})
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchMyProgresses: () => dispatch(fetchMyProgresses())
});

const MyProgresses = ({ contentWidth, progresses, clearLearnStats, fetchMyProgresses }) => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [showChart, setShowChart] = useState (false);

  useEffect(() => {
    clearLearnStats('myProgresses');
    fetchMyProgresses().then(res => setLoading(false));
  }, [fetchMyProgresses]);

  useEffect(() => {
    if (!loading)
      setShowChart(true)
  }, [loading]);

  const myProgressChartData = () => ({
    title: '',
    xAxis: progresses[tab][1]?.lessons?.map(l => l.name),
    yAxisTitle: 'Progress (%)',
    series: [
      { name: 'Your Highest Score',      color: '#183c5b', data: progresses[tab][1]?.lessons?.map(l => l.quizScore) },
      { name: 'Average Community Score', color: '#ff9800', data: progresses[tab][1]?.lessons?.map(l => l.communityScore) },
    ]
  });

  const hasProgress = () => (
    progresses.find(
      ([k, v]) => v.lessons.find(
        l => l.quizScore > 0
      )
    ) ? true : false
  )

  const hasCertificate = () => (
    progresses.find(
      ([k, v]) => v.award
    ) ? true : false
  )

  const renderMyProgress = () => (
    <div className="CardHolder">
      <div className="Title-Container">
        <div className="Text">
          <p>Your Quiz Grades vs Community</p>
        </div>

        {
          hasCertificate() &&
          <div className="Button">
            <Link to={ `/account/certificate/` }>
              <ButtonBox
                className="Static-Primary-Btn btn"
                text="View Certificates"
              />
            </Link>
          </div>
        }
      </div>

      <div
        className="Tab-Container"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {
          progresses.map(([k, v], idx) => (
            <div
              key={`tab-${idx}`}
              children={k}
              className={`Tab ${tab === idx ? 'Active' : ''}`}
              onClick={() => setTab(idx)}
            />
          ))
        }
      </div>

      <div
        id="My-Progress-Chart"
        className="Content-Container My-Progress-Chart LineChart Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {
          showChart &&
          <LineChart
            id="My-Progress-Chart"
            data={myProgressChartData()}
          />
        }
      </div>
    </div>
  )

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
      />
    </Link>
  );

  const renderContent = () => {
    if (loading)
      return <Loader />;

    if (hasProgress())
      return renderMyProgress();

    return renderEmpty();
  }

  return (
    <div className="My-Progresses-Container">
      <h2 className="Title">My Progress</h2>
      {renderContent()}
    </div>
  )
};

MyProgresses.propTypes = {
  progresses: PropTypes.object.isRequired,
  contentWidth: PropTypes.number.isRequired,
  clearLearnStats: PropTypes.func.isRequired,
  fetchMyProgresses: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProgresses);
