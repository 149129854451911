import * as APIUtil from '../../Util/Conversions/CourseToScormApiUtil';
import { PackageScormCourse } from '../../Util/Helper/PackageScormCourseUtil';

export const fetchCourseToExport = course => dispatch => {
  const packageScormCourse = PackageScormCourse(course);
  packageScormCourse.setupBasicElements();

  const supplyChunk = (chunk) => {
    if (chunk.event === 'streaming') {
      packageScormCourse.processLesson(chunk);
    }

    if (chunk.event === 'closed') {
      packageScormCourse.finalizeImsManifest();
      packageScormCourse.download();
    }
  }

  return APIUtil.fetchCourseToExport(
    course.id,
    chunk => supplyChunk(chunk),
  )
}
