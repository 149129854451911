import React, { useState } from 'react';
 
import TextBox from '../../Basic/TextBox/TextBox';
import NavigationTopics from './NavigationTopics';
import CircularProgressBar from '../../Basic/CircularProgressBar/CircularProgressBar';

const NavigationLesson = ({ course, courseProgress, lesson, setNavigationOpen, pathname }) => {
  const [hidden, setHidden] = useState(
    pathname.split('/')[5] && +pathname.split('/')[5] === lesson.id ? false : true
  );

  const completionPercentage = () => {
    if (lesson.topics.length === 0) {
      return 0;
    }

    return Math.floor(
      (
        (courseProgress?.progress_data?.lessonProgresses?.[lesson.id]?.completedTopics?.length || 0)
        /
        (lesson.topics.length || 0)
      ) * 100
    );
  }

  if (lesson.is_test)
    return '';

  return (
    <div className="NavigationLesson">
      <div className="NavigationLesson-fixed">
        <div className="NavigationLesson-Title">
          <div
            onClick={() => setHidden(!hidden)}
          >
            <TextBox
              text={lesson.name}
            />
          </div>
          <div className="CoursesDashboard-Course-CircularProgressBar">
            <CircularProgressBar
              totalNumberOfSteps="100"
              completedSteps={completionPercentage()}
            />
          </div>
        </div>

        {
          hidden ?
            <button
              className="NavigationTopic-Button"
              onClick={() => setHidden(false)}>
              <i className="fas fa-chevron-down"></i>
            </button>
            :
            <button
              className="NavigationTopic-Button"
              onClick={() => setHidden(true)}>
              <i className="fas fa-chevron-up"></i>
            </button>
        }
      </div>

      {
        !hidden &&
        <NavigationTopics
          course={course}
          courseProgress={courseProgress}
          lesson={lesson}
          setNavigationOpen={setNavigationOpen}
          pathname={pathname} />
      }
    </div>
  );
}

export default NavigationLesson;
