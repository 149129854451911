import {
    RECEIVE_CHARGE_CONFIRMATION,
    CLEAR_STRIPE_FLASH_AND_ERRORS,
} from '../../Actions/StripeActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_CHARGE_CONFIRMATION:
            return action.payload;
        case CLEAR_STRIPE_FLASH_AND_ERRORS:
            return {};
        default:
            return state;
    }
};
