import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchInstitutions } from '../../../../Actions/InstitutionActions';
import CreateEditInstitution from '../../../Forms/CreateEditInstitution/CreateEditInstitutionContainer';

const mapStateToProps = (state, ownProps) => ({
    institutions: state.admin.institutions,
    institutionId: ownProps.match.params.institutionId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInstitutions: () => dispatch(fetchInstitutions()),
});

const InstitutionDetail = ({ institutions, institutionId, fetchInstitutions }) => {
    useEffect(() => {
        if (!Object.keys(institutions).length) {
            fetchInstitutions();
        }
    }, [fetchInstitutions]);

    if (!Object.keys(institutions).length) {
        return <div />;
    }

    const institution = institutions[institutionId];

    return (
        <div>
            <CreateEditInstitution
                institution={institution}
            />
        </div>
    );
};

InstitutionDetail.propTypes = {
    institutions: PropTypes.objectOf(PropTypes.object).isRequired,
    institutionId: PropTypes.string.isRequired,
    fetchInstitutions: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionDetail);
