import React, { useState, useEffect } from 'react';
import { ScaleLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import TextBox from '../../../../Basic/TextBox/TextBox';
import InputBox from '../../../../Basic/InputBox/InputBox';

import './SearchUsers.scss';

const SearchUsers = ({ users, fetchUsers }) => {
    const tabs = {
        learners: { name: 'Learners', pool: 'data' },
        admins: { name: 'Admins', pool: 'admins' },
    };

    const [filter, setFilter] = useState('');
    const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const renderLoader = () => (
        <div className="Loader">
            <ScaleLoader
                color="#bfbfbf"
                height={50}
                width={50}
            />
        </div>
    );

    const renderErrorMessage = () => (
        <div className="Error Flash-Message Danger">
            Something went wrong. Please refresh and try again.
        </div>
    );

    const getFilteredUsers = () => (
        users?.[tabs[activeTab].pool]?.filter(user => (
            user.email.toLowerCase().includes(filter.toLowerCase()) ||
            user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
            user.last_name.toLowerCase().includes(filter.toLowerCase())
        ))
    );

    const renderSearchUsers = () => (
        <>
            <div className="B2BAdminDashboard-GroupList__container">
                <TextBox
                    text="Search for users by entering relevant information below"
                    className="Title"
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <div className="Filters-Box">
                    <div className="Search">
                        <div className="Label">Search Filter:</div>
                        <InputBox
                            className="Input"
                            value={filter}
                            placeholder="First Name, Last Name, or Email"
                            onChange={e => setFilter(e.target.value)}
                        />
                    </div>

                    <div className="Tabs">
                        <div className="Label">User Type Filter:</div>
                        {
                            Object.entries(tabs).map(([k, v], idx) => (
                                <div
                                    key={`tab-${idx}`}
                                    className={`Tab ${activeTab === k ? 'Active' : ''}`}
                                    children={v.name}
                                    onClick={() => {
                                        setFilter('');
                                        setActiveTab(k);
                                    }}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <div className="Content-Container">
                    <table>
                        <thead>
                            <tr>
                                <td>First Name</td>
                                <td>Last Name</td>
                                <td>Email</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getFilteredUsers().map((user, idx) => (
                                    <tr key={`user-${idx}`}>
                                        <td>{user.first_name}</td>
                                        <td>{user.last_name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            {
                                                activeTab === 'learners'
                                                && (
                                                    <Link to={`/account/search/${user.id}`}>
                                                        Details
                                                    </Link>
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

    const decideWhichComponentsToRender = () => {
        if (users.isLoading) {
            return renderLoader();
        }
        if (users.isError) {
            return renderErrorMessage();
        }
        return renderSearchUsers();
    };

    return (
        <div className="SearchUsers-Container">
            {decideWhichComponentsToRender()}
        </div>
    );
};

export default SearchUsers;
