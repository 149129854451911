import { schema } from 'normalizr';

export const topic = new schema.Entity('topics');

export const sibling = new schema.Entity('siblings');

export const course = new schema.Entity('courses', {
  lessons: [sibling]
})

export const lesson = new schema.Entity('lessons', {
  topics: [topic],
  course: course
});


export const lessons = new schema.Values(lesson);