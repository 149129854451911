import { connect } from 'react-redux';

import Institutions from './Institutions';

import { clearTEAdminInstitutionsStats, fetchTEAdminInstitutionsStats } from '../../../../../Actions/Stats/TEAdmin/InstitutionsActions';
import { fetchInstitutions } from '../../../../../Actions/InstitutionActions';
import { fetchCourses } from '../../../../../Actions/CourseActions';

const mapStateToProps = (state) => ({
  institutions: state.admin.institutions,
  courses: state.entities.courses,
  total_licenses: state.stats.teAdmin.institutions?.total_licenses || 0,
  processed_licenses: state.stats.teAdmin.institutions?.processed_licenses || 0,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  fetchCourses: () => dispatch(fetchCourses()),
  clearTEAdminInstitutionsStats: () => dispatch(clearTEAdminInstitutionsStats()),
  fetchTEAdminInstitutionsStats: (institutionsFilter, normalFilters, comparisonFilters) => dispatch(fetchTEAdminInstitutionsStats(institutionsFilter, normalFilters, comparisonFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Institutions);
