import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CommentsPanel from './CommentsPanel';

const CommentsPanelHandler = ({ commentableType, commentableId }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="Comments-Container">
      <ButtonBox
        className="ButtonBox-EditQuestions Comment-Btn"
        text={<i className="fa fa-comment" />}
        onClick={() => setShow(!show)}
      />

      {
        show &&
        <CommentsPanel
          commentableType={commentableType}
          commentableId={commentableId}
          closePanel={() => setShow(false)}
        />
      }
    </div>
  )
}

CommentsPanelHandler.propTypes = {
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
}

export default CommentsPanelHandler;
