import React, { Component } from 'react';
import ReactToolTip from 'react-tooltip';

import './CreateEditTable.css';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';
import CheckBox
  from '../../Basic/CheckBox/CheckBox';
import { COLORS }
  from '../../../Style/Style';

class CreateEditTable extends Component {
  state = this.props.table ?
  {
    num_rows: this.props.table.num_rows,
    num_cols: this.props.table.num_cols,
    has_header: this.props.table.has_header,
  }
  :
  {
    num_rows: "",
    num_cols: "",
    has_header: false,
  }

  handleInputChange = field => e => {
    this.setState({
      [field]: e.currentTarget.value,
    })
  }

  handleCheckboxChange = (e) => {
    this.setState({ has_header: e.target.checked })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const table = Object.assign({}, this.state);

    if (this.props.textContent) {
      table.text_content_id = this.props.textContent.id;
    }

    if (this.props.table) {
      table.id = this.props.table.id;
    }

    this.props.submitTable(table).then(
      this.props.closeModal()
    );
  }

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <InputBox
            blurTo="number"
            className="InputBox-LogInBox"
            value={this.state.num_rows}
            placeholder="Number of Rows"
            onChange={this.handleInputChange('num_rows')} />
        </div>

        <div
          className="CreationForms-InputBox">
          <InputBox
            blurTo="number"
            className="InputBox-LogInBox"
            value={this.state.num_cols}
            placeholder="Number of Columns"
            onChange={this.handleInputChange('num_cols')} />
        </div>

        <div
          className="CreateEditLesson-Checkbox-container">
          <div
            className="CreateEditLesson-Checkbox">
            <CheckBox
              checked={this.state.has_header}
              onChange={this.handleCheckboxChange} />
          </div>

          <div>
            <div
              data-tip
              data-for="lesson-tooltip">
              <TextBox
                text="Include Header"
                style={{
                  color: `${COLORS.Blue}`,
                  textDecoration: "none",
                }}
              />
            </div>
            <ReactToolTip id="lesson-tooltip" type="info">
              <span>Will automatically make the first row a header.</span>
            </ReactToolTip>
          </div>
        </div>

        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditTable;
