import { RECEIVE_ACTIVITY_RESPONSE, CLEAR_ACTIVITY_RESPONSES } from '../../Actions/ActivityActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ACTIVITY_RESPONSE:
      return Object.assign({}, state, action.payload);
    case CLEAR_ACTIVITY_RESPONSES:
      return {};
    default:
      return state;
  }
};
