import React
  from 'react';

import './FlashcardMini.css';
import TextBox
  from '../../Basic/TextBox/TextBox';
import FunctionBox
  from '../../Composed/FunctionBox/FunctionBox';
import QuestionBox
  from '../../Quiz/QuestionBox/QuestionBox';
import { COLORS }
  from '../../../Style/Style';

/**
* @class - FlashcardMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const FlashcardMini = ({ flashcard, editFlashcard, deleteFlashcard,  creation, 
                         submitMedia }) => {
  return (
    <div
      className="QuestionMini-QuestionBox">
      <div
        className="QuestionMini-QuestionBox-Position"
        style={{
          backgroundColor: COLORS.green
        }}>
        <TextBox
          text={flashcard.position}
          style={{
            color: COLORS.Blue
          }} />
      </div>

      <div
        className="QuestionMini-QuestionBox-Question-Split">
        <div style={{display: "flex", alignItems: "center"}}>
          <QuestionBox question={flashcard} creation={creation}>
            <TextBox
              text={flashcard.text}
              style={{
                color: COLORS.Blue
              }} />
          </QuestionBox>
        </div>

        <TextBox
          text={flashcard.back_text}
          style={{
            color: COLORS.Blue
          }} />
      </div>

      {
        creation &&
        <FunctionBox
          submitMedia={submitMedia}
          model="flashcard"
          entity={flashcard}
          editEntity={editFlashcard}
          deleteEntity={deleteFlashcard}
        />
      }
    </div>
  );
};

export default FlashcardMini;
