import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from  '../../../Basic/ButtonBox/ButtonBox';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import DashboardFormBoxNoEdit from '../../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';

import './CreateUserResource.scss';

const CreateUserResource = ({
  currentUser, inventories,
  clearInventories, fetchDetailedInventories,
  checkExistingUser,
  createUserResource,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [email, setEmail] = useState('');
  const [exists, setExists] = useState(false);
  const [user, setUser] = useState(null);
  const [userResourceAttrs, setUserResourceAttrs] = useState({ resourceable_type: '', resourceable_id: '', name: '', description: '', file: null });

  useEffect(() => {
    clearInventories();
    fetchDetailedInventories().then(() => setLoading(false));
  }, [clearInventories, fetchDetailedInventories]);

  const handleCheckExistingUser = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (email.trim().length === 0) {
      setErrors({ '': 'Please enter email address to check user\'s existence.' });
      return null;
    }

    setProcessing(true);
    checkExistingUser({ email }).then(res => {
      setExists(res.exists);
      setUser(res.user);
      setProcessing(false);

      if (res.exists) {
        setFlash({ message: 'A user with this email exists in the system.' });
      } else {
        setErrors({ '': 'A user with this email does not exists in the system.' });
      }
    });
  }

  const validateForm = () => {
    let errors = {};

    if (!userResourceAttrs.resourceable_type || !userResourceAttrs.resourceable_id) {
      errors['Exam:'] = 'Please select exam!';
    }

    if (userResourceAttrs.name.trim().length === 0) {
      errors['Name:'] = 'Please enter name of the user resource!';
    }

    if (!userResourceAttrs.file) {
      errors['File:'] = 'Please attach a file!';
    }

    return errors;
  }

  const handleCreateUserResource = (e) => {
    e.preventDefault();

    let errors = validateForm();

    setErrors(errors);
    setFlash({});

    if (Object.keys(errors).length === 0) {
      const formData = new FormData();

      formData.append('user_resource[created_by_id]', currentUser.id);
      formData.append('user_resource[owned_by_id]', user.id);
      formData.append('user_resource[resourceable_type]', userResourceAttrs.resourceable_type);
      formData.append('user_resource[resourceable_id]', userResourceAttrs.resourceable_id);
      formData.append('user_resource[name]', userResourceAttrs.name);
      formData.append('user_resource[description]', userResourceAttrs.description);
      formData.append('user_resource[file]', userResourceAttrs.file);

      setProcessing(true);
      createUserResource(formData).then((res) => {
        if (res.success) {
          setFlash({ message: 'User resource uploaded successfully.' });
        } else {
          setErrors({ '': 'Failed to upload user resource. Please try again.' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setProcessing(false);
      });
    }
  }

  const handleClear = (e) => {
    e.preventDefault();

    setErrors({});
    setFlash({});
    setEmail('');
    setExists(false);
    setUser(null);
    setUserResourceAttrs({ resourceable_type: '', resourceable_id: '', name: '', description: '', file: null });
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Create-User-Resource-Container createUserContainer CreateUser">
      <ErrorFlashBox errors={errors} flash={flash} />

      {processing && <MirrorLoader message="Processing..." />}

      <DashboardFormBoxNoEdit title="UPLOAD USER RESOURCES">
        <div className="DashboardFormBox-Line Verify-User-Existence fdc">
          <div className="Verify-Head">
            <div className="Full-Width">
              <div className="DashboardFormBox-Label left">
                User Email
              </div>
              <button
                type="button"
                className="CreateUser-Update-Button Verify-Btn"
                onClick={(e) => handleCheckExistingUser(e)}
              >
                Verify
              </button>
            </div>
          </div>
          <div className="Verify-Body">
            <input
              type="text"
              value={email}
              disabled={exists}
              className={`${exists && 'ValidEmail'} Full-Width`}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {exists && <i className="fas fa-check Check-Icon" />}
          </div>
        </div>
      </DashboardFormBoxNoEdit>

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          <div className="User-Resource-Form">
            <div className="Input-Container">
              <div className="DashboardFormBox-Label">Exam</div>
              <select
                value={userResourceAttrs.resourceable_id}
                className="createLicenseContainer__select"
                onChange={(e) => setUserResourceAttrs({ ...userResourceAttrs, resourceable_type: 'Exam', resourceable_id: e.currentTarget.value })}
              >
                <option key="option" value="" children="Please select" />
                {
                  Object.values(inventories).map((inventory, iIdx) => (
                    inventory.products.map((product, pIdx) => (
                      product.exams.map((exam, eIdx) => (
                        <option key={`option-${iIdx}-${pIdx}-${eIdx}`} value={exam.id} children={exam.name} />
                      ))
                    ))
                  ))
                }
              </select>
            </div>

            <div className="Input-Container">
              <div className="DashboardFormBox-Label">Name</div>
              <input
                type="text"
                className="Input"
                value={userResourceAttrs.name}
                onChange={(e) => setUserResourceAttrs({ ...userResourceAttrs, name: e.currentTarget.value })}
              />
            </div>

            <div className="Input-Container">
              <div className="DashboardFormBox-Label">File (PDF, PNG, JPEG, JPG)</div>
              <input
                type="file"
                className="Input"
                accept="application/pdf, image/x-png, image/jpeg"
                onChange={(e) => setUserResourceAttrs({ ...userResourceAttrs, file: e.currentTarget.files[0] })}
              />
            </div>

            <div className="Input-Container">
              <div className="DashboardFormBox-Label">Description (optional)</div>
              <input
                type="text"
                className="Input"
                value={userResourceAttrs.description}
                onChange={(e) => setUserResourceAttrs({ ...userResourceAttrs, description: e.currentTarget.value })}
              />
            </div>
          </div>
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          <div className="Button-Container">
            <ButtonBox
              className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save"
              text="Upload User Resource"
              onClick={(e) => handleCreateUserResource(e)}
            />
            <ButtonBox
              className="DashboardFormBox-Button_cancel DashboardFormBox-CancelBtn"
              text="Clear"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }
    </div>
  )
}

CreateUserResource.propTypes = {
  currentUser: PropTypes.object.isRequired,
  inventories: PropTypes.object.isRequired,
  clearInventories: PropTypes.func.isRequired,
  fetchDetailedInventories: PropTypes.func.isRequired,
  checkExistingUser: PropTypes.func.isRequired,
  createUserResource: PropTypes.func.isRequired,
}

export default CreateUserResource;
