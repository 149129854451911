import React, { useState } from 'react';

import CreateEditQuestion
  from '../../Forms/CreateEditQuestion/CreateEditQuestionContainer';
import CreateEditFlashcard
  from '../../Forms/CreateEditFlashcard/CreateEditFlashcard';
import CreateEditTextContent 
  from '../../Forms/CreateEditTextContent/CreateEditTextContent';
import CreateEditTable 
  from '../../Forms/CreateEditTable/CreateEditTable';
import UploadMedia
  from '../../Forms/UploadMedia/UploadMedia';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import Confirm
  from '../../Composed/Confirm/Confirm';
import Modal
  from '../../Composed/Modal/Modal';

const FunctionBox = ({ course, model, entity, editEntity, deleteEntity, submitMedia, createTable, createComment, updateComment }) => {
  const uploadables = ["question", "flashcard", "text_content"];

  const handleDeleteQuestion = entityId => e => {
    e.preventDefault();
    deleteEntity(entityId);
  }

  const selectForm = close => {
    let form;
    switch (model) {
      case "question":
        form = <CreateEditQuestion
                  question={entity}
                  submitQuestion={editEntity}
                  closeModal={close} />
        break;
      case "flashcard":
        form = <CreateEditFlashcard
                  flashcard={entity}
                  submitFlashcard={editEntity}
                  closeModal={close} />;
        break;
      case "text_content":
        form = <CreateEditTextContent
                  textContent={entity}
                  submitTextContent={editEntity}
                  closeModal={close} />;
        break;
      case "table":
        form = <CreateEditTable
                  table={entity}
                  submitTable={editEntity}
                  closeModal={close} />;
        break;
      default:
        break;
    }
    return form
  }

  return (
    <div
      className="MultiButtonChoiceBox-QuestionBox-EditButtons">
      {
        uploadables.includes(model) &&
        <Modal
          small
          title="Add Audio"
          form={
            close =>
              <UploadMedia
                submitMedia={submitMedia}
                media_type="audio"
                entity={entity}
                model={model}
                attachment="audio"
                closeModal={close} />
          }>
          {
            open =>
              <ButtonBox
                className="ButtonBox-EditQuestions"
                text={<i className="fas fa-headphones"></i>}
                onClick={open} />
          }
        </Modal>
      }

      {
        uploadables.includes(model) &&
        entity.audio_url &&
        <Confirm
          text={`Are you sure you want to delete audio for this ${model.split('_').join(' ')}?`}
          confirm={(e) => editEntity({ id: entity.id, purge_audio: true })}
        >
          {
            open =>
            <ButtonBox
              className="ButtonBox-EditQuestions"
              text={<i className="fa fa-volume-off"></i>}
              onClick={open}
            />
          }
        </Confirm>
      }

      {
        (model === "text_content" && !entity.table) &&
        <Modal
          title="Add A Table"
          form={
            close =>
              <CreateEditTable
                textContent={entity}
                submitTable={createTable}
                closeModal={close} />
          }>
          {
            open =>
              <ButtonBox
                className="ButtonBox-EditQuestions"
                text={<i className="fas fa-table"></i>}
                onClick={open} />
          }
        </Modal>
      }

      <Modal
        title={`Edit ${model.split("_").map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}`}
        form={
          close => selectForm(close)
        }
        spanishKeyboard={course?.language === 'SP'}
      >
        {
          open =>
            <ButtonBox
              className="ButtonBox-EditQuestions"
              text={<i className="far fa-edit"></i>}
              onClick={open} />
        }
      </Modal>

      <Confirm
        text={`Are you sure you want to delete this ${model.split("_").map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}?` }
        confirm={handleDeleteQuestion(entity.id)}>
        {
          open =>
            <ButtonBox
              className="ButtonBox-EditQuestions"
              text={<i className="far fa-trash-alt"></i>}
              onClick={open} />
        }
      </Confirm>
    </div>
  );
}

export default FunctionBox;
