import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import './DialogBox.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import AudioRecordButton from '../../Quiz/AudioRecordButton/AudioRecordButton';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';
import UploadMedia from '../../Forms/UploadMedia/UploadMedia';
import Modal from '../../Composed/Modal/Modal';
import DialogHintWidget from './DialogHintWidget';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const DialogBox = (props) => {
    const [reloadDialog, setReloadDialog] = useState(false);
    const {
        section,
        questions,
        editQuestion,
        deleteQuestion,
        uploadSectionMedia, 
        uploadAudio,
        creation,
        quizProgress,
        lessonId,
        recordQuizAnswer,
        revealAnswers,
        checkAnswers,
    } = props;

    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    let rolePlayIndex = null;

    const toggleChangeHandler = (currentQuestion, idx) => {
        return (e) => {
            recordProgress(currentQuestion, idx, e.currentTarget.value);
        };
    };

    const recordProgress = (currentQuestion, idx, userValue) => {
        const answer = {
            [lessonId]: {
                [section.id]: {
                    [currentQuestion.id]: {
                        [idx]: {
                            text: userValue,
                            is_correct: userValue.trim().toLowerCase() === currentQuestion.answers[idx].text.trim().toLowerCase(),
                        }
                    }
                }
            }
        };
        recordQuizAnswer(answer);
    }

    const handleBlur = () => e => {
        e.currentTarget.id = '';
    };

    const handleFocus = () => e => {
        e.currentTarget.id = 'active';
    };

    const rolePlayStart = () => {
        gaEvent('role_play_start');
        rolePlayIndex = 0;
        let playBtn = document.querySelectorAll('.DialogBox-Audio .ButtonBox-AudioPlayButton')[rolePlayIndex];
        return playBtn ? playBtn.click() : rolePlayFinish();
    }

    const rolePlayNext = () => {
        if (rolePlayIndex === null)
            return null;

        rolePlayIndex += 1;
        let playBtn = document.querySelectorAll('.DialogBox-Audio .ButtonBox-AudioPlayButton')[rolePlayIndex];
        return playBtn ? playBtn.click() : rolePlayFinish();
    }

    const rolePlayFinish = () => {
        rolePlayIndex = null;
    }

    const handleRecordAudio = (index, question, isHint) =>{
        if (!section.record_audio || isIE) {
            return question.audio_url && !isHint ? <AudioPlayButton src={question.audio_url} autoplay nextPlay={() => rolePlayNext()} downloadable={creation} /> : null
        }

        if (isHint) {
            return question.audio_url ? <AudioPlayButton src={question.audio_url} autoplay downloadable={creation} /> : null
        } 

        if (((section.record_on === "odd_lines" || section.record_on === "all_lines") &&
        index % 2 === 0) || ((section.record_on === "even_lines" || section.record_on === "all_lines") &&
        index % 2 === 1)) {
            return <AudioRecordButton index={index} nextPlay={() => rolePlayNext()} setReloadDialog={() => setReloadDialog(!reloadDialog)} key={`recorder-${question.id}-${index}`} />
        } else if (question.audio_url) {
            return <AudioPlayButton src={question.audio_url} autoplay nextPlay={() => rolePlayNext()} downloadable={creation}/>
        }

        return null;
    }

    const handleQuestionRender = (currentQuestion) => {
        const questionParts = currentQuestion.text.split("__blank__");
        let question = [];
        questionParts.forEach((part, idx) => {
        part.split(" ").forEach(
            (word, idx2) => {
            question.push(
                <TextBox
                key={(idx + 1) * 100 + idx2}
                text={word}
                tag='p'
                style={{
                    color: COLORS.Blue
                }} />
            );
            }
        )

        if (currentQuestion.answers[idx]) {
            if (revealAnswers && !quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.is_correct) {
                recordProgress(currentQuestion, idx, currentQuestion.answers[idx].text);
            }

            question.push(
                <div key={idx + 1}>
                    {
                        checkAnswers &&
                        quizProgress?.[section.id]?.[currentQuestion.id]?.[idx] &&
                        (
                            quizProgress[section.id][currentQuestion.id][idx].is_correct ?
                                <i class="fas fa-check icon-correct"></i>
                                :
                                <i class="fas fa-times icon-wrong"></i>
                        )
                    }

                    <input
                        key={idx + 1}
                        style={{
                            width: `${(currentQuestion.answers[idx].text.length + 2) * 10}px`,
                        }}
                        value={
                            quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]
                            ?
                            quizProgress[section.id][currentQuestion.id][idx].text
                            :
                            (
                            creation ? currentQuestion.answers[idx].text : ""
                            )
                        }
                        onChange={toggleChangeHandler(currentQuestion, idx)}
                        onBlur={handleBlur()}
                        onFocus={handleFocus()}
                        type='text'
                    />
                </div>
            );
        }
        });
        return question;
    }

    const handleContentRender = () => {
        let content = questions.map((question, idx) => (
            <div key={`question-${idx}`} className="DialogBox-Box">
                <div className="DialogBox-Picture">
                    {
                        creation ?
                        <Modal
                            small
                            title="Add An Image"
                            form={
                                close =>
                                <UploadMedia
                                    submitMedia={uploadSectionMedia}
                                    media_type="image"
                                    entity={section}
                                    model="section"
                                    attachment={
                                        idx % 2 === 0 ?
                                        "first_speaker"
                                        :
                                        "second_speaker"
                                    }
                                    closeModal={close}
                                />
                            }
                        >
                            {
                                open =>
                                <ImageBox
                                    className="DialogBox-ImageBox"
                                    src={
                                        idx % 2 === 0 ?
                                        (section.first_speaker_url ? section.first_speaker_url : 'default_user.png')
                                        :
                                        (section.second_speaker_url ? section.second_speaker_url : 'default_user.png')
                                    }
                                    onClick={open}
                                    style={{ cursor: "pointer" }}
                                />
                            }
                        </Modal>
                        :
                        <ImageBox
                            className="DialogBox-ImageBox"
                            src={
                                idx % 2 === 0 ?
                                (section.first_speaker_url ? section.first_speaker_url : 'default_user.png')
                                :
                                (section.second_speaker_url ? section.second_speaker_url : 'default_user.png')
                            }
                        />
                    }
                </div>

                <div className="DialogBox-Audio">
                    {
                        handleRecordAudio(idx, question, false)
                    }
                </div>

                <div className="DialogBox-Content">
                    <div className="DialogBox-Content-Body">
                        {
                            handleQuestionRender(question)
                        }
                    </div>

                    {
                        question.hint &&
                        <div className="DialogBox-Content-Hint">
                            <DialogHintWidget>
                                <div className="Hint-Reveal">
                                    {
                                        handleRecordAudio(idx, question, true)
                                    }
                                    <TextBox
                                        className="Hint-Text"
                                        text={question.hint}
                                    />
                                </div>
                            </DialogHintWidget>
                        </div>
                    }
                </div>

                {
                    creation &&
                    <FunctionBox
                        submitMedia={uploadAudio}
                        model="question"
                        entity={question}
                        editEntity={editQuestion}
                        deleteEntity={deleteQuestion}
                    />
                }
            </div>
        ));
        return content;
    };

    const noRecordingExist = () => {
        return document.getElementsByClassName('No-Recording').length > 0
    }

    return (
        <div className="DialogBox">
            {
                isIE
                    && (
                        <TextBox
                            text="Your browser does not support audio recording. Try a different browser for the full experience."
                            style={{
                                color: COLORS.redPink,
                                padding: "30px",
                            }}
                    />
                    )
            }

            {handleContentRender()}

            {
                noRecordingExist() ? (
                    <div data-tip data-for="No-Recording-RolePlay" className="DialogBox-RolePlay No-Recording-RolePlay">
                        <ButtonBox
                            className="Secondary-Btn"
                            text={<span className="Secondary-Btn-Text"><i className="fa fa-play" /> Listen to Role Play</span>}
                        />
                        <ReactTooltip place="bottom" type="dark" effect="solid" id="No-Recording-RolePlay">
                            Please record your portions of the conversation in order to replay the audio.
                        </ReactTooltip>
                    </div>
                ) : (
                    <div className="DialogBox-RolePlay">
                        <ButtonBox
                            className="Secondary-Btn"
                            text={<span className="Secondary-Btn-Text"><i className="fa fa-play" /> Listen to Role Play</span>}
                            onClick={() => rolePlayStart()}
                        />
                    </div>
                )
            }
        </div>
    );
    };

export default DialogBox;
