import * as APIUtil from '../Util/RecurringSubscriptionApiUtil';

import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_RECURRING_SUBSCRIPTIONS = 'RECEIVE_RECURRING_SUBSCRIPTIONS';
export const RECEIVE_RECURRING_SUBSCRIPTIONS_ERRORS = 'RECEIVE_RECURRING_SUBSCRIPTIONS_ERRORS';
export const CLEAR_RECURRING_SUBSCRIPTIONS = 'CLEAR_RECURRING_SUBSCRIPTIONS';

export const receiveRecurringSubscriptions = payload => {
  return {
    type: RECEIVE_RECURRING_SUBSCRIPTIONS,
    payload,
  };
};

export const receiveErrors = errors => {
  return {
    type: RECEIVE_RECURRING_SUBSCRIPTIONS_ERRORS,
    errors
  };
};

export const clearRecurringSubscriptions = () => {
  return {
    type: CLEAR_RECURRING_SUBSCRIPTIONS,
  };
};

export const fetchRecurringSubscriptions = () => dispatch => (
  APIUtil.fetchRecurringSubscriptions().then(
    payload => dispatch(receiveRecurringSubscriptions(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const cancelRecurringSubscription = (recurringSubscriptionId) => dispatch => (
  APIUtil.cancelRecurringSubscription(recurringSubscriptionId).then(
    payload => dispatch(receiveRecurringSubscriptions(payload)),
    errors  => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
