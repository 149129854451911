import * as APIUtil from '../../Util/Engage/StreakApiUtil';
import ErrorResponseUtil from '../../Util/ErrorResponseUtil';

export const RECEIVE_USER_STREAKS = 'RECEIVE_USER_STREAKS';
export const CLEAR_USER_STREAKS = 'CLEAR_USER_STREAKS';
export const RECEIVE_LEADERBOARD = 'RECEIVE_LEADERBOARD';
export const CLEAR_LEADERBOARD = 'CLEAR_LEADERBOARD';
export const RECEIVE_STREAK_ERRORS = 'RECEIVE_STREAK_ERRORS';

export const receiveUserStreaks = (payload) => ({
  type: RECEIVE_USER_STREAKS,
  payload,
});

export const clearUserStreaks = () => ({
  type: CLEAR_USER_STREAKS,
});

export const receiveLeaderboard = (payload) => ({
  type: RECEIVE_LEADERBOARD,
  payload,
});

export const clearLeaderboard = () => ({
  type: CLEAR_LEADERBOARD,
});

export const receiveStreakErrors = (errors) => ({
  type: RECEIVE_STREAK_ERRORS,
  errors,
});

export const fetchUserStreaks = () => dispatch => (
  APIUtil.fetchUserStreaks().then(
    payload => dispatch(receiveUserStreaks(payload)),
    errors => dispatch(receiveStreakErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchLeaderboard = (streakType) => dispatch => (
  APIUtil.fetchLeaderboard(streakType).then(
    payload => dispatch(receiveLeaderboard(payload)),
    errors => dispatch(receiveStreakErrors(ErrorResponseUtil(errors))),
  )
);
