import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import './AdminHomeDashboard.scss';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import BarStackedChart from '../../../HighCharts/BarStackedChart/BarStackedChart';

const mapStateToProps = (state) => ({
  flippers: state.access.flippers
});

const mapDispatchToProps = (dispatch) => ({});

const AdminHomeDashboard = ({ flippers }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 500;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      setShowChart(true);
    }
  }, [loading]);

  const professionalDevelopmentStats = {
    canopyCompliance: {
      completedTrainings: 4,
      totalTrainings: 30
    },
    canopyCredential: {
      users: 152
    },
    canopyLearn: {
      level: 2,
      usersCompleted: 69
    },
    canopySpeak: {
      learnedPhrases: 830
    }
  };

  const pledgeStatusData = [
    {
      pledge: 'Improve Proficiency Level',
      completed: 50,
      inProgress: 357,
      notAchieved: 217
    },
    {
      pledge: 'Take Proficiency Exam',
      completed: 273,
      inProgress: 204,
      notAchieved: 58
    },
    {
      pledge: 'Join Language Class',
      completed: 222,
      inProgress: 87,
      notAchieved: 265
    },
    {
      pledge: 'Take e-Learning Course',
      completed: 118,
      inProgress: 94,
      notAchieved: 276
    },
    {
      pledge: 'Volunteer as Interpreter',
      completed: 73,
      inProgress: 272,
      notAchieved: 298
    },
    {
      pledge: 'Volunteer as Translator',
      completed: 375,
      inProgress: 318,
      notAchieved: 195
    },
    {
      pledge: 'Find Practice Buddy',
      completed: 316,
      inProgress: 239,
      notAchieved: 38
    },
    {
      pledge: 'Other Pledges',
      completed: 157,
      inProgress: 211,
      notAchieved: 348
    },
  ];

  const pledgeStatusData1 = pledgeStatusData.slice(0, Math.ceil(pledgeStatusData.length / 2));
  const pledgeStatusData2 = pledgeStatusData.slice(Math.ceil(pledgeStatusData.length / 2));

  const getPledgeStatusChartBaseData = () => {
    return {
      tooltip: '',
      title: '',
      yAxisTitle: '',
      legendTitle: '',
      stacking: 'percent',
      series: [{
        name: 'Completed',
        color: '#9DC7C4'
      }, {
        name: 'In Progress',
        color: '#7D87C1'
      }, {
        name: 'Not Achieved',
        color: '#BEC4CC'
      }]
    };
  };

  const getPledgeStatusChart1Data = () => {
    const baseData = getPledgeStatusChartBaseData();
    return {
      ...baseData,
      legend: {
        enabled: false
      },
      xAxis: pledgeStatusData1.map(pledgeStatus => pledgeStatus.pledge),
      series: [{
        ...baseData.series[0],
        data: pledgeStatusData1.map(pledgeStatus => pledgeStatus.completed),
      }, {
        ...baseData.series[1],
        data: pledgeStatusData1.map(pledgeStatus => pledgeStatus.inProgress),
      }, {
        ...baseData.series[2],
        data: pledgeStatusData1.map(pledgeStatus => pledgeStatus.notAchieved),
      }]
    };
  };

  const getChartPledgeStatusChart2Data = () => {
    const baseData = getPledgeStatusChartBaseData();
    return {
      ...baseData,
      xAxis: pledgeStatusData2.map(pledgeStatus => pledgeStatus.pledge),
      series: [{
        ...baseData.series[0],
        data: pledgeStatusData2.map(pledgeStatus => pledgeStatus.completed),
      }, {
        ...baseData.series[1],
        data: pledgeStatusData2.map(pledgeStatus => pledgeStatus.inProgress),
      }, {
        ...baseData.series[2],
        data: pledgeStatusData2.map(pledgeStatus => pledgeStatus.notAchieved),
      }]
    };
  };

  const renderDashboard = () => {
    return (
      <div className="B2B-Admin-Home-Dashboard-Container Main-Page-Content">
        <div className="Admin-Dashboard-Professional-Development-Stats">
          <div className="Title-Bar">
            <h3>Professional Development Stats</h3>
          </div>
          <div className="Content">
            <div className="Card">
              <ImageBox src="canopy_compliance.svg" className="Canopy-Image"/>
              <div className="Combo-Badge-Holder">
                <div className="Text-Heading">
                  Completed Training
                </div>
                <div className="Shape">
                  <ImageBox src="combo.svg" className="Badge-Icon"/>
                  <div className="Inner-Text">
                    <div
                      className="Digit">{Math.round((professionalDevelopmentStats.canopyCompliance.completedTrainings / professionalDevelopmentStats.canopyCompliance.totalTrainings) * 100)}%
                    </div>
                    <div className="Text">workforce</div>
                  </div>
                </div>
                <div className="Total-Progress">
                  {professionalDevelopmentStats.canopyCompliance.completedTrainings} completed
                  / {professionalDevelopmentStats.canopyCompliance.totalTrainings} total
                </div>
              </div>
            </div>
            <div className="Card">
              <ImageBox src="canopy_credential.svg" className="Canopy-Image"/>
              <div className="Combo-Badge-Holder">
                <div className="Text-Heading">
                  Archived QBS Status
                </div>
                <div className="Shape">
                  <ImageBox src="combo.svg" className="Badge-Icon"/>
                  <div className="Inner-Text">
                    <div
                      className="Digit">{professionalDevelopmentStats.canopyCredential.users}</div>
                    <div className="Text">users</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Card">
              <ImageBox src="canopy_learn.svg" className="Canopy-Image"/>
              <div className="Combo-Badge-Holder">
                <div className="Text-Heading">
                  Completed level {professionalDevelopmentStats.canopyLearn.level}
                </div>
                <div className="Shape">
                  <ImageBox src="combo.svg" className="Badge-Icon"/>
                  <div className="Inner-Text">
                    <div
                      className="Digit">{professionalDevelopmentStats.canopyLearn.usersCompleted}</div>
                    <div className="Text">users</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Card">
              <ImageBox src="canopy_speak.svg" className="Canopy-Image"/>
              <div className="Combo-Badge-Holder">
                <div className="Text-Heading">
                  Phrases Learned
                </div>
                <div className="Shape">
                  <ImageBox src="combo.svg" className="Badge-Icon"/>
                  <div className="Inner-Text">
                    <div
                      className="Digit">{professionalDevelopmentStats.canopySpeak.learnedPhrases}</div>
                    <div className="Text">phrases</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Admin-Dashboard-Pledge-Status">
          <div className="Title-Bar">
            <h3>Pledge Status</h3>
          </div>
          <div className="Content Dynamic-Width" style={{ width: `${contentWidth}px` }}>
            <div className="Chart" id="Pledge-Status-Chart-1"></div>
            {
              showChart &&
              <BarStackedChart
                id="Pledge-Status-Chart-1"
                data={getPledgeStatusChart1Data()}
              />
            }
            <div className="Chart" id="Pledge-Status-Chart-2"></div>
            {
              showChart &&
              <BarStackedChart
                id="Pledge-Status-Chart-2"
                data={getChartPledgeStatusChart2Data()}
              />
            }
            <div className="Floating-Buttons">
              <div className="Toggle-Button Enabled">Staff Pledges</div>
              <div className="Toggle-Button">Inst. Pledges</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!flippers?.actor?.admin_home_dashboard?.enabled) {
    return null;
  }

  return renderDashboard();
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomeDashboard);
