import React from 'react';
import './select.css';

const Select = ({ iterator, callback, property }) => (
    <select className="select-css" onChange={(e) => callback(e.target.value)}>
        {
            iterator.map((entry, idx) => (
                <option key={idx} value={idx}>
                    {entry[property]}
                </option>
            ))
        }
    </select>
)

export default Select;
