import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToolTip from 'react-tooltip';
import ReactQuill from 'react-quill';
import { QuillModules } from '../../../Data/Quill';
import 'react-quill/dist/quill.snow.css';

import './CreateEditSection.css';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';
import RadioBox from '../../Basic/RadioBox/RadioBox';

class CreateEditSection extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.section 
            ? ({
                name: this.props.section.name,
                description: this.props.section.description,
                instructions: this.props.section.instructions,
                record_audio: this.props.section.record_audio,
                record_on: this.props.section.record_on,
                study_mode: this.props.section.study_mode,
                section_type: this.props.section.section_type,
            })
            : ({
                name: '',
                instructions: '',
                section_type: '',
                record_audio: false,
                record_on: null,
                study_mode: true
            });
    }

    handleInputChange = (field) => {
        return (e) => this.setState({
            [field]: e.currentTarget.value,
        });
    }

    handleQuillChange = field => value => this.setState({ [field]: value })

    handleSelectChange = (e) => {
        this.setState({
            section_type: e.currentTarget.value,
        });
    }

    handleCheckboxChange = e => {
        this.setState({ record_audio: e.target.checked });
    }

    handleStudyModeChange = e => {
        this.setState({ study_mode: e.target.checked });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { activity, topic, quiz, submitSection } = this.props;
        const section = Object.assign({}, this.state);

        if (activity) {
            section.sectionable_id = activity.id;
            section.sectionable_type = 'Activity';
        }

        if (quiz) {
            section.sectionable_id = quiz.id;
            section.sectionable_type = 'Quiz';
        } 

        if (topic) {
            section.sectionable_id = topic.id;
            section.sectionable_type = 'Topic';
        }

        if (this.props.section) {
            section.id = this.props.section.id;
        }

        submitSection(section).then(
            this.props.closeModal(),
        );
    }

    render() {
        return (
            <form
                className="CreationForms-Form"
                onSubmit={this.handleSubmit}
            >
                <div className="CreationForms-InputBox">
                    <InputBox
                        className="InputBox-LogInBox"
                        value={this.state.name}
                        placeholder="Name"
                        onChange={this.handleInputChange('name')}
                    />
                </div>

                <div className="CreationForms-ReactQuill">
                    <ReactQuill
                        modules={QuillModules}
                        value={this.state.instructions}
                        onChange={this.handleQuillChange('instructions')}
                    />
                </div>

                {
                    !this.props.section
                        && (
                            <div className="CreationForms-SelectBox">
                                <select
                                    onChange={this.handleSelectChange}
                                    defaultValue=""
                                >
                                    <option
                                        disabled
                                        value=""
                                    >
                                        Section Type
                                    </option>

                                    {
                                        (this.props.learning || this.props.isActivity) &&
                                        <option value="video">Video</option>
                                    }

                                    <option value="text">Text</option>

                                    {
                                        this.props.learning &&
                                        <option value="dialog">Dialog</option>
                                    }

                                    {
                                        this.props.learning &&
                                        <option value="flashcard">Flashcard</option>
                                    }

                                    {
                                        this.props.learning &&
                                        <option value="table_fill_in">Table Fill In</option>
                                    }

                                    {
                                        this.props.learning &&
                                        <option value="sorting">Sorting</option>
                                    }
                                    
                                    <option value="multiple_choice">Multiple Choice</option>

                                    {
                                        !this.props.isActivity &&
                                        <>
                                            <option value="word_scramble">Word Scramble</option>

                                            <option value="multiple_answer">Multiple Answer</option>

                                            <option value="fill_in_the_blank">Fill In The Blank</option>

                                            <option value="true_or_false">True Or False</option>

                                            <option value="matching">Matching</option>

                                            <option value="drag_and_drop">Drag And Drop</option>

                                            <option value="drop_down">Drop Down</option>

                                            <option value="sentence_ordering">Sentence Ordering</option>
                                        </>
                                    }
                                </select>
                            </div>
                        )
                }

                {
                    this.state.section_type === 'dialog'
                        && (
                            <div className="CreateEditLesson-Checkbox-container">
                                <div
                                    className="CreateEditLesson-Checkbox">
                                    <CheckBox
                                        checked={this.state.record_audio}
                                        onChange={this.handleCheckboxChange} />
                                </div>

                                <div>
                                    <div
                                        data-tip
                                        data-for="lesson-tooltip">
                                        <TextBox
                                            text="Record Audio"
                                            style={{
                                                color: `${COLORS.Blue}`,
                                                textDecoration: "none",
                                            }}
                                        />
                                    </div>
                                    <ReactToolTip id="lesson-tooltip" type="info">
                                        <span>Allows users to record audio.</span>
                                    </ReactToolTip>
                                </div>
                            </div>
                        )
                }

                {
                    this.state.record_audio
                        && (
                        <div className="CreationForms-RadioBox">
                            <RadioBox
                                className="RadioBox-EditUser"
                                name="record"
                                value="odd_lines"
                                text="Odd lines"
                                onChange={this.handleInputChange('record_on')}
                            />
                            <RadioBox
                                className="RadioBox-EditUser"
                                name="record"
                                value="even_lines"
                                text="Even lines"
                                onChange={this.handleInputChange('record_on')}
                            />
                            <RadioBox
                                className="RadioBox-EditUser"
                                name="record"
                                value="all_lines"
                                text="All lines"
                                onChange={this.handleInputChange('record_on')}
                            />
                        </div>
                        )
                }

                {
                    this.state.section_type === 'flashcard' && (
                        <div className="CreateEditLesson-Checkbox-container">
                            <div className="CreateEditLesson-Checkbox">
                                <CheckBox
                                    checked={this.state.study_mode}
                                    onChange={this.handleStudyModeChange}
                                />
                            </div>

                            <div>
                                <TextBox text="Study Mode" />
                            </div>
                        </div>
                    )
                }

                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Submit"
                    />
                </div>
            </form>
        );
    }
}

export default CreateEditSection;
