import axios from './AxiosUtil';
import { normalize } from 'normalizr';

import * as Schema from '../Schema/LessonSchema';

export const fetchLessons = (courseId) => {
    return axios.get(`/api/courses/${courseId}/lessons`).then(
        res => normalize(res.data, Schema.lessons).entities
    );
};

export const createLesson = (lesson) => {
    return axios.post("/api/lessons", { lesson }).then(
        res => normalize(res.data, Schema.lesson).entities
    );
};

export const editLesson = (lesson) => {
    return axios.patch(`/api/lessons/${lesson.id}`, { lesson }).then(
        res => normalize(res.data, Schema.lesson).entities
    );
};

export const deleteLesson = lessonId => {
    return axios.delete(`/api/lessons/${lessonId}`).then(
        res => normalize(res.data, Schema.lesson).entities
    );
};

export const cloneLesson = (targetCourseId, lessonId) => {
    return axios.post(`/api/lessons/${lessonId}/clone`, { target_course_id: targetCourseId }).then(
        res => res.data
    );
};

export const moveHigher = lessonId => {
    return axios.post(`/api/lessons/${lessonId}/higher`).then(
        res => normalize(res.data, Schema.lesson).entities
    );
};

export const moveLower = lessonId => {
    return axios.post(`/api/lessons/${lessonId}/lower`).then(
        res => normalize(res.data, Schema.lesson).entities
    );
};

export const uploadImage = (formData) => {
    return axios({
        method: "PATCH",
        url: `/api/lessons/${formData.get("lesson[id]")}`,
        processData: false,
        contentType: false,
        dataType: 'json',
        data: formData,
    }).then(
        res => normalize(res.data, Schema.lessons).entities
    );
};
