import { connect } from 'react-redux';

import SectionSummaryBox from './SectionSummaryBox';
import {
    editSection,
    deleteSection,
    uploadSectionMedia,
    moveHigher,
    moveLower,
} from '../../../Actions/SectionActions';
import {
    createQuestion,
    editQuestion,
    deleteQuestion,
    uploadAudio,
} from '../../../Actions/QuestionActions';
import {
    createAnswer,
    editAnswer,
    deleteAnswer
} from '../../../Actions/AnswerActions';
import {
    createTextContent,
    editTextContent,
    deleteTextContent,
    uploadTextContentAudio,
} from '../../../Actions/TextContentActions';
import {
    createFlashcard,
    editFlashcard,
    deleteFlashcard,
    uploadFlashcardAudio,
} from '../../../Actions/FlashcardActions';
import {
    createTable,
    editTable,
    deleteTable,
} from '../../../Actions/TableActions';
import {
    createTableEntry,
    editTableEntry,
} from '../../../Actions/TableEntryActions';

const mapStateToProps = (state, ownProps) => ({
    activity: ownProps.activityId && state.entities.activities[ownProps.activityId],
    topic: ownProps.topicId && state.entities.topics[ownProps.topicId],
    quiz: ownProps.quizId && state.entities.quizzes[ownProps.quizId]
});

const mapDispatchToProps = (dispatch) => ({
    editSection: section => dispatch(editSection(section)),
    deleteSection: sectionId => dispatch(deleteSection(sectionId)),
    uploadSectionMedia: formData => dispatch(uploadSectionMedia(formData)),
    createQuestion: question => dispatch(createQuestion(question)),
    editQuestion: question => dispatch(editQuestion(question)),
    uploadAudio: formData => dispatch(uploadAudio(formData)),
    deleteQuestion: questionId => dispatch(deleteQuestion(questionId)),
    createTextContent: textContent => dispatch(createTextContent(textContent)),
    editTextContent: textContent => dispatch(editTextContent(textContent)),
    deleteTextContent: textContentId => dispatch(deleteTextContent(textContentId)),
    uploadTextContentAudio: formData => dispatch(uploadTextContentAudio(formData)),
    createFlashcard: flashcard => dispatch(createFlashcard(flashcard)),
    editFlashcard: flashcard => dispatch(editFlashcard(flashcard)),
    deleteFlashcard: flashcardId => dispatch(deleteFlashcard(flashcardId)),
    uploadFlashcardAudio: formData => dispatch(uploadFlashcardAudio(formData)),
    createAnswer: answer => dispatch(createAnswer(answer)),
    editAnswer: answer => dispatch(editAnswer(answer)),
    deleteAnswer: answerId => dispatch(deleteAnswer(answerId)),
    createTable: table => dispatch(createTable(table)),
    editTable: table => dispatch(editTable(table)),
    deleteTable: tableId => dispatch(deleteTable(tableId)),
    createTableEntry: formData => dispatch(createTableEntry(formData)),
    editTableEntry: formData => dispatch(editTableEntry(formData)),
    moveHigher: sectionId => dispatch(moveHigher(sectionId)),
    moveLower: sectionId => dispatch(moveLower(sectionId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SectionSummaryBox);
