import {
  RECEIVE_FILTER,
  CLEAR_FILTERS
} from '../Actions/FilterActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_FILTER:
      return Object.assign({}, state, { [action.filter]: action.value });
    case CLEAR_FILTERS:
      return {};
    default:
      return state;
  }
}
