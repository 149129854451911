import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import Pledge from '../../../Dashboards/CanopyUserDashboard/Pledge/Pledge';
import Pass from '../Pass/Pass';

import { clearCoursePledges, createCoursePledges, scaleUpCoursePledge, scaleDownCoursePledge } from '../../../../Actions/CoursePledgeActions';
import { courseLanguageList } from '../../../../Constants/CourseConstants';

import './Pledges.scss';

const mapStateToProps = (state) => ({
  coursePledges: Object.values(state.entities.coursePledges || {}).filter(cp => cp.id)
});

const mapDispatchToProps = (dispatch) => ({
  clearCoursePledges: () => dispatch(clearCoursePledges()),
  createCoursePledges: (courseId) => dispatch(createCoursePledges(courseId)),
  scaleUpCoursePledge: (pledge) => dispatch(scaleUpCoursePledge(pledge)),
  scaleDownCoursePledge: (pledge) => dispatch(scaleDownCoursePledge(pledge))
});

const Pledges = ({
  course, courseProgress, isComplianceCourse,
  coursePledges, clearCoursePledges, createCoursePledges, scaleUpCoursePledge, scaleDownCoursePledge
}) => {
  const [showPassPage, setShowPassPage] = useState(false);
  const [loading, setLoading] = useState(true);

  const currentActiveCourse = coursePledges.find(cp => cp.id === course.id);

  useEffect(() => {
    clearCoursePledges();
    createCoursePledges(course.id).then(res => setLoading(false));
  }, [createCoursePledges]);

  const renderPledge = () => {
    const pledgeProps = {
      language: courseLanguageList[currentActiveCourse.language]?.toLowerCase(),
      scaleUpPledge: scaleUpCoursePledge,
      scaleDownPledge: scaleDownCoursePledge
    };

    return (
      <div className="My-Pledges">
        <div className="My-Courses-Pledges">
          <div className="My-Course-Pledges">
            <div className="Course-Title">
              {currentActiveCourse.name}
            </div>
            <div className="Pledge-Cards">
              {
                currentActiveCourse.in_progress_pledges.map((pledge, idx) => (
                  <Pledge key={pledge.id} pledge={pledge} currentIdx={idx} {...pledgeProps} />
                ))
              }
              {
                currentActiveCourse.outstanding_pledges.map((pledge, idx) => (
                  <Pledge key={pledge.id} pledge={pledge} currentIdx={idx} {...pledgeProps} />
                ))
              }
              {
                currentActiveCourse.completed_pledges.map((pledge, idx) => (
                  <Pledge key={pledge.id} pledge={pledge} currentIdx={idx} {...pledgeProps} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (showPassPage)
    return <Pass course={course} courseProgress={courseProgress} isComplianceCourse={isComplianceCourse} />

  if (loading || !currentActiveCourse)
    return <MirrorLoader message="Please wait!" />

  return (
    <div className="Course-Pledge-Containter">
      <h2 className="Title">Canopy Pledges</h2>
      <div className="Content">
        <p className="Paragraph">
          We want to support you in your journey to becoming a linguistically and culturally skilled healthcare provider.
          By setting your goals here, you are taking an important step forward in that journey, and giving us the opportunity
          to know your priorities and support you along the way. There is no penalty or risk in not being able to fully meet your stated goals.
          We know that even the best laid plans don't always come to full fruition, or within the timeframe that is anticipated.
          But there's a lot to celebrate in just setting a goal and giving it your best.
        </p>

        {renderPledge()}
      </div>

      <div className="Finalize-Pledge-Button">
        <ButtonBox
          className="Btn"
          text={<span className="Text">Finalize Pledges</span>}
          icon="fa-angle-right"
          onClick={e => setShowPassPage(true)}
          disabled={(currentActiveCourse.completed_pledges.length === 0) && (currentActiveCourse.in_progress_pledges.length === 0)}
        />
      </div>
    </div>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Pledges);
