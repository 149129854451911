import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

import { authSignup } from '../../../Util/SessionApiUtil';

import InputBox from '../../Basic/InputBox/InputBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';

import './SignUpPage.scss';

const SignUpPage = props => {
  let isValid = false;

  const queryString = new URLSearchParams(useLocation().search);
  const fillAccessCode = queryString.get('access_code');

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    accessCode: fillAccessCode || '',
    termsOfUse: false,
    privacyPolicy: false
  });
  const [userError, setUserError] = useState({});
  const [loader, setLoader] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const handleInputChange = (e, field) => {
    if (field === 'accessCode') {
      setUser({ ...user, [field]: e.currentTarget.value.replace(/\s+/g, '') });
      return
    }

    setUser({ ...user, [field]: e.currentTarget.value });
  };

  const handleCheckBoxChange =(e, field) => {
    setUser({ ...user, [field]: e.currentTarget.checked });
  }

  const handleValidations = async () => {
    let errors = {};

    if (user.firstName.length === 0) {
      errors['firstName'] = 'Required';
    }

    if (user.lastName.length === 0) {
      errors['lastName'] = 'Required';
    }

    if (!(/^\S+@\S+\.\S+$/.test(user.email))) {
      errors['email'] = 'Email is not valid.'
    }

    if (user.email !== user.confirmEmail) {
      errors['confirmEmail'] = 'Email and confirm email are not the same.'
    }

    if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*?~]{8,}$/.test(user.password))) {
      errors['password'] = 'Password should consist of 8 or more characters. Also, it should have a lower case letter, a upper case letter, a digit, and a special character (e.g. !@#$%^&*?~).'
    }

    if (user.password !== user.confirmPassword) {
      errors['confirmPassword'] = 'Password and confirm password are not the same.'
    }

    if (!user.termsOfUse) {
      errors['termsOfUse'] = 'Required';
    }

    if (!user.privacyPolicy) {
      errors['privacyPolicy'] = 'Required';
    }

    setUserError(errors);
    isValid = Object.keys(errors).length === 0;

    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await handleValidations();

    if (isValid) {
      authSignup({
        email: user.email,
        password: user.password,
        first_name: user.firstName,
        last_name: user.lastName,
        access_code: user.accessCode,
        terms_accepted: user.termsOfUse.toString(),
        privacy_accepted: user.privacyPolicy.toString(),
      }).then(res => {
        setLoader(false);
        setSignedUp(true);
        const location = queryString.get('redirect_uri') || window.location.origin;
        setTimeout(() => { window.location = location }, 3000);
      }).catch(err => {
        setLoader(false);
        setUserError(err.response.data);
      });
    } else {
      setLoader(false);
      return false;
    }
  };

  const redirectToLoginPage = (e) => {
    e.preventDefault();
    const location = queryString.get('redirect_uri') || window.location.origin;
    window.location = location;
  }

  return (
    <div className="AuthPage bg-grey">
      <div className="Signup-Container container">
        <div className="Heading">
          <img src="https://prod-canopy-railsapi-media-bucket.s3.amazonaws.com/canopy-c.png" />
          <p>Create Your Canopy Profile</p>
        </div>
        <p className="Subheading">To access Canopy products and customize your user experience, please create your Canopy Profile.</p>

        <form className="Form" autoComplete="off">
          {
            loader && (
              <div className="From-Loader">
                <ScaleLoader color="#ffffff" />
              </div>
            )
          }

          {
            signedUp && (
              <div className="From-Loader">
                <div className="SignedUp">Signed up successfully. Redirecting you to login page.</div>
              </div>
            )
          }

          {
            userError.error &&
            <div className="Form-Error">{userError.error}</div>
          }

          <div className="Field NameField">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">First Name</label>
                <div className="Error">{userError.firstName}</div>
              </div>
              <InputBox
                className={`Input First-Name ${userError.firstName ? 'Error' : ''}`}
                value={user.firstName}
                onChange={(e) => handleInputChange(e, 'firstName')}
              />
            </React.Fragment>
          </div>

          <div className="Field NameField mr-0">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Last Name</label>
                <div className="Error">{userError.lastName}</div>
              </div>
              <InputBox
                className={`Input Last-Name ${userError.lastName ? 'Error' : ''}`}
                value={user.lastName}
                onChange={(e) => handleInputChange(e, 'lastName')}
              />
            </React.Fragment>
          </div>

          <div className="Field">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Email</label>
                <div className="Error">{userError.email}</div>
              </div>
              <InputBox
                className={`Input Email ${userError.email ? 'Error' : ''}`}
                value={user.email}
                onChange={(e) => handleInputChange(e, 'email')}
              />
            </React.Fragment>
          </div>

          <div className="Field">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Confirm Email</label>
                <div className="Error">{userError.confirmEmail}</div>
              </div>
              <InputBox
                className={`Input Confirm-Email ${userError.confirmEmail ? 'Error' : ''}`}
                value={user.confirmEmail}
                onChange={(e) => handleInputChange(e, 'confirmEmail')}
              />
            </React.Fragment>
          </div>

          <div className="Field">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Password</label>
                <div className="Error">{userError.password}</div>
              </div>
              <InputBox
                className={`Input Password ${userError.password ? 'Error' : ''}`}
                value={user.password}
                type="password"
                onChange={(e) => handleInputChange(e, 'password')}
              />
            </React.Fragment>
          </div>

          <div className="Field">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Confirm Password</label>
                <div className="Error">{userError.confirmPassword}</div>
              </div>
              <InputBox
                className={`Input Confirm-Password ${userError.confirmPassword ? 'Error' : ''}`}
                value={user.confirmPassword}
                type="password"
                onChange={(e) => handleInputChange(e, 'confirmPassword')}
              />
            </React.Fragment>
          </div>

          <div className="Field">
            <React.Fragment>
              <div className="Field-Title">
                <label className="Label">Access Code</label>
                <div className="Error">{userError.accessCode}</div>
              </div>
              <InputBox
                className={`Input Access-Code ${userError.accessCode ? 'Error' : ''}`}
                value={user.accessCode}
                onChange={(e) => handleInputChange(e, 'accessCode')}
                disabled={fillAccessCode}
              />
            </React.Fragment>
          </div>

          <div className="Access-Code-Notice">
            <p>Only enter an Access Code if provided by your school, employer, etc.</p>
          </div>

          <div className="Field CheckboxHolder">
            <React.Fragment>
              <div className="Field-Title">
                <label className={`Label ${userError.termsOfUse ? 'CheckBoxError' : ''}`}>
                  <CheckBox
                    className="Checkbox Terms-Of-Use"
                    text="Terms of Use"
                    value={user.termsOfUse}
                    onClick={(e) => handleCheckBoxChange(e, 'termsOfUse')}
                  />
                </label>
                <div className="Term-Of-Use Text">
                  I agree to Canopy's <a href="terms_of_service.pdf" target="_blank"><u>Terms of Service</u></a>
                </div>
                <div className="Error">{userError.termsOfUse}</div>
              </div>
            </React.Fragment>
          </div>

          <div className="Field CheckboxHolder">
            <React.Fragment>
              <div className="Field-Title">
                <label className={`Label ${userError.privacyPolicy ? 'CheckBoxError' : ''}`}>
                  <CheckBox
                    className="Checkbox Privacy-Policy"
                    text="Privacy Policy"
                    value={user.privacyPolicy}
                    onClick={(e) => handleCheckBoxChange(e, 'privacyPolicy')}
                  />
                </label>
                <div className="Privacy-Policy Text">
                  I accept the terms of Canopy's &nbsp;
                  <a href="privacy_policy.pdf" target="_blank"><u>Privacy Policy</u></a>
                </div>
                <div className="Error">{userError.privacyPolicy}</div>
              </div>
            </React.Fragment>
          </div>

          <div className="Button">
            <button
              type="button"
              className="Submit"
              disabled={false}
              onClick={(e) => handleSubmit(e)}
            >
              CREATE PROFILE
            </button>
          </div>
        </form>

        <div className="Profile">
          <div className="Text">
            <p>Already have a Profile?</p>
          </div>
          <div className="Login-Button">
            <button
             onClick={(e) => redirectToLoginPage(e)}
            >
              log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
