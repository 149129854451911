import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getLicenses } from '../../../../Actions/LicenseActions';
import EditLicenseContainer from '../../../Forms/EditLicense/EditLicenseContainer';

const mapStateToProps = (state, ownProps) => ({
    licenses: state.admin.licenses,
    licenseId: ownProps.match.params.licenseId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLicenses: () => dispatch(getLicenses()),
});

const LicenseDetail = ({ licenses, licenseId, fetchLicenses }) => {
    useEffect(() => {
        if (!Object.keys(licenses).length) {
            fetchLicenses();
        }
    }, [fetchLicenses]);

    if (!Object.keys(licenses).length) {
        return <div />;
    }

    const license = licenses[licenseId];

    return (
        <div>
            <EditLicenseContainer
                license={license}
            />
        </div>
    );
};

LicenseDetail.propTypes = {
    licenses: PropTypes.objectOf(PropTypes.object).isRequired,
    licenseId: PropTypes.string.isRequired,
    fetchLicenses: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetail);
