import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/InviteUsersApiUtil';

export const RECEIVE_INVITE_USERS = 'RECEIVE_INVITE_USERS';
export const RECEIVE_INVITE_USER_ERRORS = 'RECEIVE_INVITE_USER_ERRORS';
export const CLEAR_INVITE_USERS = 'CLEAR_INVITE_USERS';
export const RECEIVE_INVITE_USERS_CONFIRMATION = 'RECEIVE_INVITE_USERS_CONFIRMATION';
export const RECEIVE_INVITE_USERS_ERRORS = 'RECEIVE_INVITE_USERS_ERRORS';
export const CLEAR_INVITE_USERS_ERRORS = 'CLEAR_INVITE_USERS_ERRORS';
export const INVITE_USERS_VALIDATION_ERRORS = 'INVITE_USERS_VALIDATION_ERRORS';

export const receiveInviteUsers = (payload) => ({
  type: RECEIVE_INVITE_USERS,
  payload,
});

export const receiveInviteUserErrors = (errors) => ({
  type: RECEIVE_INVITE_USER_ERRORS,
  errors,
});

export const clearInviteUsers = () => ({
  type: CLEAR_INVITE_USERS,
});

export const receiveInviteUsersConfirmation = payload => ({
  type: RECEIVE_INVITE_USERS_CONFIRMATION,
  message: payload.message,
});

export const recieveInviteUsersErrors = errors => ({
  type: RECEIVE_INVITE_USERS_ERRORS,
  errors,
});

export const clearInviteUsersErrors = () => ({
  type: CLEAR_INVITE_USERS_ERRORS,
});

export const inviteUsersFormValidatonErrors = errors => ({
  type: INVITE_USERS_VALIDATION_ERRORS,
  errors,
});

export const fetchInviteUsers = () => dispatch => (
  APIUtil.fetchInviteUsers().then(
    payload => dispatch(receiveInviteUsers(payload)),
    errors => dispatch(receiveInviteUserErrors(ErrorResponseUtil(errors)))
  )
);

export const createInviteUsers = formData => dispatch => (
  APIUtil.createInviteUsers(formData).then(
    payload => dispatch(receiveInviteUsersConfirmation(payload)),
    errors => dispatch(recieveInviteUsersErrors(ErrorResponseUtil(errors))),
  )
);
