import React from 'react';
import PropTypes from 'prop-types';

import './RadioBox.css';

/**
* @function - RadioBox - functional
* @{props} - {Object}
 * className
 * name - names for grouped radio items must be the same.
 * value
 * text
 * style
* @return { JSX Object }
*/

const RadioBox = ({ className, name, value, text, style, onChange, checked }) => {

  // propTypes for the component
  RadioBox.propTypes = {
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
  }
  
  return(
    <div
      className={ className } >
      <input
        type="radio"
        name={ name }
        value={ value }
        style={ style }
        onChange={ onChange }
        checked={ checked } />
        { text }
    </div>
  );
};

export default RadioBox;
