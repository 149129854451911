import {
    RECEIVE_USER_COURSES,
    RECEIVE_USER_COURSES_ERRORS,
    CLEAR_USER_COURSES,
    RECEIVE_SUBSCRIPTION_CONFIRMATION,
} from '../../Actions/ManageUserAccessActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_USER_COURSES:
            return {};
        case RECEIVE_USER_COURSES_ERRORS:
            return action.errors;
        case CLEAR_USER_COURSES:
            return {};
        case RECEIVE_SUBSCRIPTION_CONFIRMATION:
            return {};
        default:
            return state;
    }
};
