import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './AddUserToGroup.css';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const AddUserToGroup = ({ setFormOpen, users, group, groupUsers, editGroup }) => {
    const [filter, setFilter] = useState('');

    const getFilteredUsers = () => (
        users.filter(user => (
            user.email.toLowerCase().includes(filter.toLowerCase()) ||
            user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
            user.last_name.toLowerCase().includes(filter.toLowerCase())
        ))
    );

    const renderUsers = () => {
        const userList = getFilteredUsers().map(user => (
            <div
                className="B2BAdminDashboard-GroupList__line"
                key={user.id}
            >
                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={user.first_name}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={user.last_name}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={user.email}
                />

                {
                    !group.users.includes(user.id)
                        ? (
                            <ButtonBox
                                className="B2BAdminDashboard-Button green"
                                text="Add"
                                onClick={() => {
                                    gaEvent('group_member_add', { institution_id: group.institution_id, group_id: group.id });
                                    editGroup({ ...group, add_id: user.id });
                                }}
                            />
                        )
                        : (
                            <ButtonBox
                                className="B2BAdminDashboard-Button red"
                                text="Remove"
                                onClick={() => {
                                    gaEvent('group_member_remove', { institution_id: group.institution_id, group_id: group.id });
                                    editGroup({ ...group, remove_id: user.id });
                                }}
                            />
                        )
                }
            </div>
        ));
        return (
            <div className="AddUserToGroup-UserList">
                <div className="B2BAdminDashboard-GroupList__line">
                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="First Name"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Last Name"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Email"
                        tag="h5"
                    />
                </div>
                {userList}
            </div>
        );
    };

    return (
        <div className="modal">
            <div className="modal-content-groups">
                <div className="AddUserToGroup">
                    <div className="AddUserToGroup-Title">
                        <TextBox
                            text="Add Students to Group"
                            style={{
                                color: COLORS.Blue,
                            }}
                        />

                        <ButtonBox
                            className="ButtonBox-Exit-X"
                            onClick={() => setFormOpen(false)}
                            text={<i className="fas fa-times" />}
                        />
                    </div>

                    <div className="AddUserToGroup-SearchBox">
                        <div className="SearchBox__label">
                            <span>SEARCH FILTERS</span>
                            <InputBox
                                value={filter}
                                placeholder="First Name, Last Name, or Email"
                                onChange={e => setFilter(e.target.value)}
                            />
                        </div>
                    </div>

                    {renderUsers()}
                </div>
            </div>
        </div>
    );
};

AddUserToGroup.propTypes = {
    setFormOpen: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    group: PropTypes.shape({
        users: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    editGroup: PropTypes.func.isRequired,
};

export default AddUserToGroup;
