import React from 'react';
import CreatableSelect from 'react-select/creatable';

const AutoCompleteCreatable = ({ className, placeholder, value, isClearable, isMulti, onChange, styles = {} }) => {
  return (
    <CreatableSelect
      className={className}
      placeholder={placeholder}
      value={value}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={(val) => onChange(val)}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: '#7ec2d4',
          backgroundColor: '#7ec2d41a',
        }),
        input: (provided, state) => ({
          ...provided,
          height: '30px',
        }),
      }}
    />
  )
}

export default AutoCompleteCreatable;