export const STYLE = {

    background: {
      fill: 'white'
    },
    text: {
      fill: "#183D5B",
      fontSize: "10px"
    },
    path: {
      stroke: "#7de3ce",
      strokeWidth: "14px"
    },
    trail: {
      stroke: '#e8eff2',
      strokeWidth: "14px" 
    }
};
