import React
  from 'react';

import './TrueOrFalseBoxMini';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import FunctionBox
  from '../../Composed/FunctionBox/FunctionBox';
import { COLORS }
  from '../../../Style/Style';

/**
* @class - TrueOrFalseBoxMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const TrueOrFalseBoxMini = ({ question, createAnswer, editAnswer, editQuestion, 
  deleteQuestion, uploadAudio, creation }) => {

  const handleChoiceSelect = (e) => {
    if (createAnswer) {
      if (question.answers[0]) {
        editAnswer({
          id: question.answers[0].id,
          question_id: question.id,
          text: e.currentTarget.dataset.id
        })
      } else {
        createAnswer({
          question_id: question.id,
          text: e.currentTarget.dataset.id
        })
      }
    }
  }

  const buttons = ["True", "False"].map((option, idx) => {
    return (
      <div
        className="MultiButtonChoiceBoxMini-ButtonBox-div"
        key={idx}>
        <ButtonBox
          className={
            question.answers[0] && question.answers[0].text === option ?
              "ButtonBox-MultiButtonChoiceBoxMini-correct"
              :
              "ButtonBox-MultiButtonChoiceBoxMini"
          }
          text={option}
          id={option}
          onClick={handleChoiceSelect} />
      </div>
    );
  });

  return (
    <div className='TrueOrFalseBoxMini'>
      <div
        className="QuestionMini-QuestionBox">
        <div
          className="QuestionMini-QuestionBox-Position"
          style={
            question.answers[0] ?
              {
                backgroundColor: COLORS.green
              }
              :
              {
                backgroundColor: COLORS.redPink
              }
          }>
          <TextBox
            text={question.position}
            style={{
              color: COLORS.Blue
            }} />
        </div>


        <div
          className="QuestionMini-QuestionBox-Question">
          <QuestionBox question={question} creation={creation}>
            <TextBox
              text={question.text}
              style={{
                color: COLORS.Blue
              }} />
          </QuestionBox>
        </div>

        {
          creation &&
          <FunctionBox
            submitMedia={uploadAudio}
            model="question"
            entity={question}
            editEntity={editQuestion}
            deleteEntity={deleteQuestion}
          />
        }
      </div>

      <div className='MultiButtonChoiceBoxMini-ButtonBox-container'>
        {buttons}
      </div>
    </div>
  );
};

export default TrueOrFalseBoxMini;
