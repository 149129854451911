export const emptyMessage = 'ADD INFORMATION ABOUT YOUR LANGUAGE BACKGROUND';

export const languageOptions = {
  '': 'Select Language',
  'english': 'English',
  'french': 'French',
  'german': 'German',
  'italian': 'Italian',
  'japanese': 'Japanese',
  'korean': 'Korean',
  'spanish': 'Spanish',
  'other': 'Other'
};

export const proficiencyOptions = {
  '': 'Select One',
  'native_or_nativelike': 'Native or nativelike',
  'advanced': 'Advanced',
  'intermediate': 'Intermediate',
  'beginner': 'Beginner'
};

export const verifiedByOptions = {
  'canopy_credential': 'Yes, CanopyCredential',
  'self_rated': 'No, self-rated only',
  'other': 'Yes, other test'
};

export const credentialOptions = {
  '': 'Select One',
  'advance_level_2': 'Advance Level 2',
  'advance_level_1': 'Advance Level 1',
  'conversational_level_2': 'Conversational Level 2',
  'conversational_level_1': 'Conversational Level 1',
  'emergent_level_2': 'Emergent Level 2',
  'emergent_level_1': 'Emergent Level 1',
  'basic_level_2': 'Basic Level 2',
  'basic_level_1': 'Basic Level 1'
};

export const acquiredThroughOptions = {
  'grew_up': 'I grew up speaking this language',
  'school_child': 'At school as a child',
  'instructor_led_classroom': 'Instructor-led classroom based courses as an adult',
  'instructor_led_online': 'Instructor-led online learning',
  'self_paced_elearning': 'Self-paced eLearning courses or mobile apps',
  'study_abroad': 'Study abroad',
  'professional_working_language': 'Living or working in a place where this language is spoken',
  'language_club': 'Language club or peer-to-peer language exchange',
  'books_flashcards': 'Books, flashcards, or other offline study aids',
  'other': 'Other'
};

export const miscOptions = [
  'I want to volunteer as an interpreter for this language',
  'I would like to meet peers who are similiar proficiency levels as me'
];
