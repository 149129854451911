import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import { humanizeDate } from '../../../../Util/Helper/GenericUtil';
import { proficiencyCodeToDesc, competanceCodeToDesc } from '../../../../Constants/CredentialConstants';

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const MyProgress = ({ allAssessmentsLoader, allAssessments, hasLicense, contentWidth }) => {
  const scoreCardLink = (assessment) => (
    <a href={`${REACT_APP_ONE_DOMAIN}/test-score/${assessment.id}`} target="_blank">View Scorecard</a>
  );

  const getProficiencyLevel = (level) => (
    proficiencyCodeToDesc[level]?.canopyLevel || 'Unrateable'
  );

  const getOverallProficiencyLevel = (listening, speaking) => {
    let listeningDesc = proficiencyCodeToDesc[listening];
    let speakingDesc  = proficiencyCodeToDesc[speaking];

    if (!listeningDesc?.points || !speakingDesc?.points) {
      return '';
    }

    let averagePoint = (listeningDesc.points + speakingDesc.points) / 2;
    if (averagePoint % 2 !== 0) {
      speakingDesc.points > averagePoint ? ++averagePoint : --averagePoint;
    }

    return Object.values(proficiencyCodeToDesc).find(desc => desc.points === averagePoint)?.canopyLevel || '-';
  }

  const getCompetenceLevel = (level) => {
    level = (level || '').replace('Grade ', '');
    return competanceCodeToDesc.sccc[level]?.canopyLevel;
  }

  const renderAssessmentRow = (assessment, idx) => {
    let currentState = assessment.is_waived ? 'Waived' : (
      assessment.sapioTest?.status !== 'rated' ? 'Pending' : null
    );

    return(
      <tr key={`my-assessment-${idx}`} className={currentState}>
        <td>{`Medical ${assessment.exam.language.name}`}</td>
        <td>{currentState || humanizeDate(assessment.sapioTest?.end_time)}</td>
        <td>{currentState || getProficiencyLevel(assessment.sapioTest?.listening)}</td>
        <td>{currentState || getProficiencyLevel(assessment.sapioTest?.speaking)}</td>
        <td>{currentState || getOverallProficiencyLevel(assessment.sapioTest?.listening, assessment.sapioTest?.speaking)}</td>
        <td>{currentState || getCompetenceLevel(assessment.sapioTest?.sccc)}</td>
        <td>{assessment.sapioTest?.status === 'rated' ? scoreCardLink(assessment) : ''}</td>
      </tr>
    )
  }

  const renderMyProgress = () => (
    <div className="CardHolder">
      <div
        className="Content-Container Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        <table className="AssessmentTable">
          <thead>
            <tr>
              <th>Exam</th>
              <th>Completion Date</th>
              <th>Listening</th>
              <th>Speaking</th>
              <th>Overall Score</th>
              <th>Sociocultural</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              allAssessments.filter(a => !a.sapioTest?.end_time)
                            .sort((a, b) => a['created_at'] > b['created_at'] ? -1 : 1)
                            .map((assessment, idx) => renderAssessmentRow(assessment, idx))
            }
            {
              allAssessments.filter(a => a.sapioTest?.end_time)
                            .sort((a, b) => a['sapioTest']['end_time'] > b['sapioTest']['end_time'] ? -1 : 1)
                            .map((assessment, idx) => renderAssessmentRow(assessment, idx))
            }
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderEmpty = () => (
    <a href={`${REACT_APP_ONE_DOMAIN}${hasLicense ? '/' : '/products'}`} target="_blank" className="Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text={`${hasLicense ? 'Go to Canopy Credential' : 'Enroll'}`}
      />
    </a>
  )

  const renderContent = () => {
    if (allAssessmentsLoader)
      return <Loader />;

    if (allAssessments.length === 0)
      return renderEmpty();

    return renderMyProgress();
  }

  return (
    <div className="My-Progress-Container">
      <h2 className="Title">My Progress</h2>
      {renderContent()}
    </div>
  )
}

MyProgress.propTypes = {
  allAssessmentsLoader: PropTypes.bool.isRequired,
  allAssessments: PropTypes.array.isRequired,
  hasLicense: PropTypes.bool.isRequired,
  contentWidth: PropTypes.number.isRequired
}

export default MyProgress;
