import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import StackedChart from '../../../HighCharts/VerticalStackedChart/StackedChart';

import { clearCredentialStats, fetchUserParticipation } from '../../../../Actions/Stats/B2BAdmin/CredentialActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';
import { shortMonthsList, fullMonthsList } from '../../../../Constants/TimeConstants';
import { qbsThresholdCodes } from '../../../../Constants/CredentialConstants';

const mapStateToProps = (state) => ({
  userParticipation: state.stats.b2bAdmin.credentials.userParticipation,
  filters: state.filters || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearCredentialStats: (key) => dispatch(clearCredentialStats(key)),
  fetchUserParticipation: (queryString) => dispatch(fetchUserParticipation(queryString))
});

const UserParticipation = ({ userParticipation, filters, clearCredentialStats, fetchUserParticipation }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShowChart(false);
    clearCredentialStats('userParticipation');
    fetchUserParticipation(hashToQueryString(filters)).then(res => setLoading(false));
  }, [fetchUserParticipation, filters]);

  useEffect(() => {
    if (!loading)
      setShowChart(true);
  }, [loading]);

  const qbsAchieved = (listening, speaking) => (
    qbsThresholdCodes.includes(listening) && qbsThresholdCodes.includes(speaking)
  )

  const countUniqueUsers = (tests = []) => (
    tests.map(t => t.user_id).filter((value, index, self) => self.indexOf(value) === index).length
  )

  const renderStats = () => {
    let credentialTests         = Object.values(userParticipation.credentialTests || {}).filter(ct => !ct.is_waived);
    let preAssessmentScoreCount = countUniqueUsers(credentialTests.filter(ct => ct.preAsssessmentScore));
    let waitingForAssessment    = countUniqueUsers(credentialTests.filter(ct => ct.sapioTest?.status === 'assigned'));
    let waitingForScore         = countUniqueUsers(credentialTests.filter(ct => ct.sapioTest?.status === 'done'));
    let receivedScore           = countUniqueUsers(credentialTests.filter(ct => ct.sapioTest?.status === 'rated'));
    let achievedQbsStatus       = countUniqueUsers(credentialTests.filter(ct => ct.sapioTest?.status === 'rated' && qbsAchieved(ct.sapioTest?.listening, ct.sapioTest?.speaking)));

    return (
      <div className="Badge-Container">
        <div className="Completed-Pre-Assessment-Holder Badge">
          <div className="Text-Heading">
            Completed Pre-Assessment
          </div>

          <div className="Shape">
            <ImageBox src="arrow.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{preAssessmentScoreCount}</div>
              <div className="Text">users</div>
            </div>
          </div>
        </div>

        <div className="Waiting-For-Assessment-Holder Badge">
          <div className="Text-Heading">
            Waiting for Assessment
          </div>
          <div className="Shape">
            <ImageBox src="arrow-2.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{waitingForAssessment}</div>
              <div className="Text">users</div>
            </div>
          </div>
        </div>

        <div className="Waiting-For-Scores-Holder Badge">
          <div className="Text-Heading">
            Waiting for Scores
          </div>
          <div className="Shape">
            <ImageBox src="arrow-2.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{waitingForScore}</div>
              <div className="Text">users</div>
            </div>
          </div>
        </div>

        <div className="Received-Scores-Holder Badge">
          <div className="Text-Heading">
            Received Scores
          </div>
          <div className="Shape">
            <ImageBox src="arrow-2.svg" className="Arrow-Icon" />
            <div className="Box-Inner-Text">
              <div className="Digit">{receivedScore}</div>
              <div className="Text">Users</div>
            </div>
          </div>
        </div>

        <div className="Achieved-QBS-Status-Holder">
          <div className="Text-Heading">
            Achieved QBS Status
          </div>
          <div className="Shape">
            <ImageBox src="combo.svg" className="Image" />
            <div className="Inner-Text">
              <div className="Digit">{achievedQbsStatus}</div>
              <div className="Text">Users</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderChart = () => {
    const userParticipationSeriesData = {};
    const monthlyStats = (!filters.timesFilter || filters.timesFilter.endsWith('year'));

    Object.values(userParticipation.credentialTests || {}).filter(ct => !ct.is_waived).map(credentialTest => {
      let dateObj = new Date(credentialTest.created_at);
      let date = dateObj.getUTCDate();
      let shortMonth = shortMonthsList[dateObj.getUTCMonth()];
      let longMonth = fullMonthsList[shortMonth];
      let year = dateObj.getUTCFullYear();

      let key = monthlyStats ? shortMonth : `${date} ${shortMonth}`;

      userParticipationSeriesData[key] = userParticipationSeriesData[key] || { registeredForAssessments: { y: 0, fullDate: `${longMonth} ${monthlyStats ? '' : date}, ${year}` }, completedAssessment: { y: 0 }, qbsAchieved: { y: 0 }};

      userParticipationSeriesData[key]['registeredForAssessments']['y'] += 1;

      if (qbsAchieved(credentialTest.sapioTest?.listening, credentialTest.sapioTest?.speaking))
        userParticipationSeriesData[key]['qbsAchieved']['y'] += 1;
    });

    Object.values(userParticipation.completedSapioTest || {}).map(sapioTest => {
      let dateObj = new Date(sapioTest.end_time);
      let date = dateObj.getUTCDate();
      let shortMonth = shortMonthsList[dateObj.getUTCMonth()];
      let longMonth = fullMonthsList[shortMonth];
      let year = dateObj.getUTCFullYear();

      let key = monthlyStats ? shortMonth : `${date} ${shortMonth}`;

      userParticipationSeriesData[key] = userParticipationSeriesData[key] || { registeredForAssessments: { y: 0, fullDate: `${longMonth} ${monthlyStats ? '' : date}, ${year}` }, completedAssessment: { y: 0 }, qbsAchieved: { y: 0 }};

      userParticipationSeriesData[key]['completedAssessment']['y'] += 1;
    });

    const userParticipationChartData = {
      title: '',
      xAxis: Object.keys(userParticipationSeriesData),
      yAxisTitle: '',
      tooltip:  `<p class="heading">{{POINT_0_POINT_FULL_DATE}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="Users">{{POINT_0_POINT_TOTAL}} Registered for Assessments</span>` +
                `</div>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_1_COLOR}}"></span>` +
                  `<span class="Users">{{POINT_1_POINT_TOTAL}} Completed Assessments</span>` +
                `</div>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_2_COLOR}}"></span>` +
                  `<span class="Users">{{POINT_2_POINT_TOTAL}} Qualified Bilingual Staff</span>` +
                `</div>` ,
      series: [
        { name: 'Registered for Assessments',  color: '#193c5a4d', data: Object.values(userParticipationSeriesData).map(d => d.registeredForAssessments)},
        { name: 'Completed Assessments',  color: '#7986cb', data: Object.values(userParticipationSeriesData).map(d => d.completedAssessment)},
        { name: 'Qualified Bilingual Staff',  color: '#80cbc4', data: Object.values(userParticipationSeriesData).map(d => d.qbsAchieved)}
      ]
    };

    return (
      <div className="User-Participation-Chart-Container">
        <div
          className="User-Participation-Chart VerticalStackedChart"
          id="User-Participation-Stacked-Chart"
        >
          {
            showChart &&
            <StackedChart
              id="User-Participation-Stacked-Chart"
              data={userParticipationChartData}
            />
          }
        </div>
      </div>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      The users have not made any progress yet!
    </div>
  );

  const renderContent = () => {
    if (loading)
      return <Loader />

    if (Object.keys(userParticipation).length == 0)
      return renderEmpty();

    return (
      <>
        {renderStats()}
        {renderChart()}
      </>
    )
  }

  return (
    <div className="Credential-User-Participation">
      <h2 className="Title">User Participation</h2>

      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserParticipation);
