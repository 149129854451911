import { connect } from 'react-redux';

import { fetchQuiz, editQuiz } from '../../../Actions/QuizActions';
import { createSection } from '../../../Actions/SectionActions';
import CreationQuizDashboard from './CreationQuizDashboard';

const mapStateToProps = (state, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    lessons: state.entities.lessons,
    currentLessonId: match.params.lessonId,
    quizzes: state.entities.quizzes,
});


const mapDispatchToProps = (dispatch) => ({
    fetchQuiz: lessonId => dispatch(fetchQuiz(lessonId)),
    editQuiz: quiz => dispatch(editQuiz(quiz)),
    createSection: section => dispatch(createSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationQuizDashboard);
