import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../../Composed/Loader/Loader';
import Pagination from '../../../../Composed/Pagination/Pagination';

import './Details.scss';

const Details = ({ netPromoterScores, clearNetPromoterScores, fetchNPSDetails }) => {
  const [loading, setLoading] = useState(true);
  const [learnerTab, setLearnerTab] = useState({ ratedAs: 'b2b_user', timePeriod: 'three_months' });
  const [page, setPage] = useState({ b2b_user_three_months: 1, b2c_user_three_months: 1, b2c_user_nine_months: 1 });

  const per = 10;

  useEffect(() => {
    setLoading(true);
    clearNetPromoterScores();
    fetchNPSDetails(`page=${JSON.stringify(page)}&per=${per}`).then(() => {
      setLoading(false)
    });
  }, [clearNetPromoterScores, fetchNPSDetails, page, per]);

  const renderContent = () => (
    <div className="Table-Holder">
      <table className="Table">
        <thead>
          <th>Email</th>
          <th>Score</th>
          <th>Feedback</th>
        </thead>
        <tbody>
          {
            Object.values(netPromoterScores[learnerTab.ratedAs][learnerTab.timePeriod].records).map(record => (
              <tr>
                <td>{record.user.email}</td>
                <td>{record.score}</td>
                <td>{record.feedback}</td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Pagination
        totalRecords={netPromoterScores[learnerTab.ratedAs][learnerTab.timePeriod].total || 0}
        page={page[`${learnerTab.ratedAs}_${learnerTab.timePeriod}`]}
        setPage={(p) => setPage({ ...page, [`${learnerTab.ratedAs}_${learnerTab.timePeriod}`]: p })}
        per={10}
      />
    </div>
  )

  const renderDetails = () => {
    if (loading) {
      return <Loader message="Getting things ready..." />
    }

    if (Object.keys(netPromoterScores?.[learnerTab.ratedAs]?.[learnerTab.timePeriod]?.records || {}).length === 0) {
      return <div className="Empty-Component">No user provided rating yet!</div>
    }

    return renderContent();
  }

  return (
    <div className="Details-Container">
      <div className="Details-Content">
        <div className="Learner-Tabs">
          <div
            className={`Learner-Tab ${(learnerTab.ratedAs === 'b2b_user' && learnerTab.timePeriod === 'three_months') ? 'Active' : ''}`}
            onClick={() => setLearnerTab({ ratedAs: 'b2b_user', timePeriod: 'three_months' })}
          >
            B2B User 3 Months
          </div>

          <div
            className={`Learner-Tab ${(learnerTab.ratedAs === 'b2c_user' && learnerTab.timePeriod === 'three_months') ? 'Active' : ''}`}
            onClick={() => setLearnerTab({ ratedAs: 'b2c_user', timePeriod: 'three_months' })}
          >
            B2C User 3 Months
          </div>

          <div
            className={`Learner-Tab ${(learnerTab.ratedAs === 'b2c_user' && learnerTab.timePeriod === 'nine_months') ? 'Active' : ''}`}
            onClick={() => setLearnerTab({ ratedAs: 'b2c_user', timePeriod: 'nine_months' })}
          >
            B2C User 9 Months
          </div>
        </div>

        {renderDetails()}
      </div>
    </div>
  )
};

Details.propTypes = {
  netPromoterScores: PropTypes.shape({}).isRequired,
  clearNetPromoterScores: PropTypes.func.isRequired,
  fetchNPSDetails: PropTypes.func.isRequired,
};

export default Details;
