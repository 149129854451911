import {
  RECEIVE_INVITE_USERS,
  CLEAR_INVITE_USERS,
} from '../../Actions/InviteUserActions';

const InviteUsersReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_INVITE_USERS:
      return Object.assign({}, state, action.payload);
    case CLEAR_INVITE_USERS:
      return {};
    default:
      return state;
  }
}

export default InviteUsersReducer;
