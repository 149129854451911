import React from 'react';

const Progress = ({ course }) => (
    <table style={{ tableLayout: 'fixed', width: '90%', backgroundColor: 'white' }}>
        <thead>
            <tr>
                <td style={{ backgroundColor: '#7DE3BB', width: 120 }} />
                {
                    course && course.lessons && course.lessons.map((progress, idx) => (
                        <td
                            style={{
                                padding: 10,
                                backgroundColor: '#7DE3BB',
                                textAlign: 'center',
                                color: '#ffffff',
                                overflow: 'hidden'
                            }}
                            key={idx}
                        >
                            {progress.lessonName}
                        </td>
                    ))
                }
            </tr>
        </thead>

        <tbody>
            <tr>
                <td style={{
                    padding: 10,
                    textAlign: 'center',
                }}
                >
                    MY PROGRESS
                </td>
                {
                    course && course.lessons && course.lessons.map((progress, idx) => (
                        <td
                            style={{
                                padding: '40px 10px',
                                textAlign: 'center',
                            }}
                            key={idx}
                        >
                            {`${isNaN(progress.lessonProgress) ? 0 : progress.lessonProgress}%`}
                        </td>
                    ))
                }
            </tr>
        </tbody>
    </table>
);

export default Progress;
