import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../Style/Style';
import TextBox from '../../../Basic/TextBox/TextBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import SearchUsersContainer from './SearchUsers/SearchUsersContainer';

const AddSearchUsers = () => (
    <div className="B2BAdminDashboard">
        <div className="B2BAdminDashboard-Title">
            <TextBox
                text="Invite/Search Users"
                tag="h4"
                style={{
                    color: COLORS.Blue,
                }}
            />
            <Link to="/account/invite_users/new">
                <ButtonBox
                    text="Invite Users"
                    className="B2BAdminDashboard-Button"
                />
            </Link>
        </div>

        <div>
            <SearchUsersContainer />
        </div>
    </div>
);

export default AddSearchUsers;
