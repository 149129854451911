import { connect } from 'react-redux';

import UserLevelSetting from './UserLevelSetting';

import { updateProfileCompletion } from '../../../Actions/ProfileCompletionActions';

const mapStateToProps = state => ({
  profileCompletion: state.userEntities.profileCompletion,
});

const mapDispatchToProps = dispatch => ({
  updateProfileCompletion: profileCompletion => dispatch(updateProfileCompletion(profileCompletion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelSetting);
