import { combineReducers } from 'redux';

import courses from './Flash/CoursesFlashReducer';
import createUser from './Flash/CreateUserFlashReducer';
import institutions from './Flash/InstitutionsFlashReducer';
import inviteUsers from './Flash/InviteUsersFlashReducer';
import lessons from './Flash/LessonsFlashReducer';
import license from './Flash/LicenseFlashReducer';
import manageAccess from './Flash/ManageAccessFlashReducer';
import modifyUser from './Flash/ModifyUserFlashReducer';
import reports from './Flash/ReportFlashReducer';
import session from './Flash/SessionFlashReducer';
import stripe from './Flash/StripeFlashReducer';
import topics from './Flash/TopicsFlashReducer';

export default combineReducers({
    courses,
    createUser,
    institutions,
    inviteUsers,
    lessons,
    license,
    manageAccess,
    modifyUser,
    reports,
    session,
    stripe,
    topics,
});
