import { connect } from 'react-redux';

import NetPromoterScore from './NetPromoterScore';

import { createUserNetPromoterScore } from '../../../Actions/NetPromoterScoreActions'

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace,
});

const mapDispatchToProps = dispatch => ({
  createUserNetPromoterScore: netPromoterScore => dispatch(createUserNetPromoterScore(netPromoterScore)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetPromoterScore);
