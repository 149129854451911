import { combineReducers } from 'redux';

import activityResponses from './Statistics/ActivityResponseReducer';
import courseProgresses from './Statistics/CourseProgressesReducer';
import bookmarks from './Statistics/BookmarksReducer';
import quizProgresses from './Statistics/QuizProgressesReducer';
import quizResults from './Statistics/QuizResultsReducer';
import quizStates from './Statistics/QuizStatesReducer';

export default combineReducers({
    activityResponses,
    courseProgresses,
    bookmarks,
    quizProgresses,
    quizResults,
    quizStates,
});
