import React from 'react';
import './mobile.scss';

const ProgressMobile = ({ course }) => {
    return (
        <table className="dashboardProgressMobile">
            <thead>
                <tr>
                    <td className="dashboardProgressMobile__cell-green">
                        <p className="dashboardProgressMobile__text">LESSON NAME</p>
                    </td>
                    <td className="dashboardProgressMobile__cell-white">
                        <p className="dashboardProgressMobile__text">MY PROGRESS</p>
                    </td>
                </tr>
            </thead>

            <tbody>
                {
                    course && course.lessons && Object.values(course.lessons).map((entry, idx) => (
                        <tr key={idx}>
                            <td className="dashboardProgressMobile__cell-green">
                                <p className="dashboardProgressMobile__text">{entry.lessonName}</p>
                            </td>
                            <td className="dashboardProgressMobile__cell-white">
                                <p className="dashboardProgressMobile__text">{isNaN(entry.lessonProgress) ? '0' : entry.lessonProgress}</p>
                            </td>
                        </tr>
                    ))
                }
            </tbody>

        </table>
    );
};

export default ProgressMobile;
