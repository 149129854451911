import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './LessonBoxMini.css';
import { COLORS } from '../../../Style/Style';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Confirm from '../../Composed/Confirm/Confirm';
import Modal from '../../Composed/Modal/Modal';
import LessonCloneContainer from '../../Forms/Clone/LessonClone/LessonCloneContainer';

const LessonBoxMini = ({
    lesson,
    course,
    deleteLesson,
}) => (
    <div className="LessonBoxMini">
        <div className="LessonBoxMini-Left">
            <div className="LessonBoxMini-ImageBox">
                <ImageBox
                    className="ImageBox-LessonBoxMini"
                    src={
                        lesson.image_url
                            ? lesson.image_url
                            : 'lesson.png'
                    }
                />
            </div>
            <div className="LessonBoxMini-DataBox">
                <div className="LessonBoxMini-DataBox-title">
                    <TextBox
                        text={`Lesson ${lesson.position}-${lesson.name}`}
                        style={{
                            color: COLORS.black,
                            fontWeight: '600',
                        }}
                        tag="p"
                    />
                </div>
                <div className="LessonBoxMini-DataBox-description">
                    <TextBox
                        text={lesson.description}
                        style={{
                            color: COLORS.greyBlue,
                        }}
                        tag="h5"
                    />
                </div>
            </div>
        </div>
        <div className="LessonBoxMini-Right">
            <div className="LessonBoxMini-DataBox-ButtonBoxes">
                <div className="LessonBoxMini-DataBox-ButtonBox-left">
                    <Link to={`/creation/courses/${course.id}/lessons/${lesson.id}`}>
                        <ButtonBox
                            className="ButtonBox-LessonBoxMini-left"
                            text="Edit Lesson"
                        />
                    </Link>
                </div>

                <div className="LessonBoxMini-DataBox-ButtonBox-left">
                    <Modal
                        title="Target Course"
                        subTitle={`Select the target course where lesson "${lesson.name}" needs to be cloned`}
                        form={close => <LessonCloneContainer lesson={lesson} />}
                    >
                        {
                            open =>
                            <ButtonBox
                              className="ButtonBox-LessonBoxMini-left"
                              text='Clone Lesson'
                              onClick={open}
                            />
                        }
                    </Modal>
                </div>

                <div className="LessonBoxMini-DataBox-ButtonBox-right">
                    <Confirm
                        text="Are you sure you want to delete this Lesson?"
                        confirm={() => deleteLesson(lesson.id)}
                    >
                        {
                            open => (
                                <ButtonBox
                                    className="ButtonBox-LessonBoxMini-right"
                                    text="Delete Lesson"
                                    onClick={open}
                                />
                            )
                        }
                    </Confirm>
                </div>
            </div>
        </div>
    </div>
);

LessonBoxMini.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number,
        position: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        image_url: PropTypes.string,
        is_test: PropTypes.bool,
        topics: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    course: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    deleteLesson: PropTypes.func.isRequired,
};

export default LessonBoxMini;
