import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './QuizSummaryBox.scss';
import TextBox from '../../Basic/TextBox/TextBox';
import { COLORS } from '../../../Style/Style';
import QuizAnswerButtons from '../QuizAnswerButtons/QuizAnswerButtons';
import QuizReviewBox from '../../Quiz/QuizReviewBox/QuizReviewBox';
import { receiveSectionIndex } from '../../../Actions/NavigationActions';
import { syncSeatTime } from '../../../Actions/SeatTimeActions';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

const mapStateToProps = (state) => ({
    quizProgresses: state.statistics.quizProgresses,
    courses:        state.entities.courses,
    lessons:        state.entities.lessons,
    quizzes:        state.entities.quizzes
});

const mapDispatchToProps = (dispatch) => ({
    receiveSectionIndex: index => dispatch(receiveSectionIndex(index))
});

const QuizSummaryBox = ({ courses, courseId, lessons, lessonId, activity, topic, quiz, quizProgresses, handleQuizReset, receiveSectionIndex, quizzes }) => {
    const [revealAnswers, setRevealAnswers] = useState(false);
    const entity = activity || topic || quiz;
    const recommendedSection = ['multiple_choice', 'true_or_false', 'fill_in_the_blank', 'drop_down', 'drag_and_drop', 'word_scramble', 'matching'];
    const isComplianceCourse = courses[courseId]?.course_type?.name === 'Compliance';

    useEffect(() => {
        syncSeatTime();
    }, [])

    const buildQuizResult = () => {
        const quizProgress = quizProgresses[lessonId];

        let numQuestions = 0;
        let numCorrect = 0;

        entity.sections.forEach(section => {
            section.questions.forEach(question => {
                numQuestions += question.answers.length;
            });
        });

        if (quizProgress) {
            Object.values(quizProgress).forEach(sectionProgress => {
                Object.values(sectionProgress).forEach(questionProgress => {
                    Object.values(questionProgress).forEach(answerProgress => {
                        if (answerProgress.is_correct) {
                            numCorrect++;
                        }
                    });
                });
            });
        }

        return {
            num_questions: numQuestions,
            num_correct:   numCorrect,
            percent:       Math.floor(numCorrect / numQuestions * 100),
            quiz_data:     (quizProgress || {})
        }
    }

    const buildSectionResult = (section) => {
        const sectionProgress = quizProgresses[lessonId]?.[section.id] || {};

        let numQuestions = 0;
        let numCorrect   = 0;
        let idIncorrect  = [];

        section.questions.forEach(q => numQuestions += q.answers.length);
        Object.keys(sectionProgress).forEach(questionId => {
            Object.values(sectionProgress[questionId]).forEach(answerProgress => {
                if (answerProgress.is_correct) {
                    numCorrect++;
                } else {
                    if (idIncorrect.indexOf(questionId) === -1)
                        idIncorrect.push(parseInt(questionId))
                }
            })
        })
        

        return {
            num_questions: numQuestions,
            num_correct: numCorrect,
            percent: Math.floor(numCorrect / numQuestions * 100),
            id_incorrect: idIncorrect
        }
    }

    const handleQuizReview = () => {
        return entity.sections.map(section => (
            section.section_type !== 'text' &&
            <QuizReviewBox
                key={section.id}
                index={section.id}
                course={courses[courseId]}
                section={section}
                quizResult={quizResult}
                revealAnswers={revealAnswers}
            />
        ));
    };

    const handleRevealAnswers = () => {
        gaEvent('reveal_quiz_answers');
        setRevealAnswers(!revealAnswers);
    };

    const renderTitle = () => {
        return (
            <div className="QuizSummaryBox-Content__container">
                <TextBox
                    text={entity.name}
                    tag="h4"
                    style={{
                        color: COLORS.Blue,
                        marginBottom: '20px',
                    }}
                />
            </div>
        )
    }

    const renderButtons = () => {
        return (
            <QuizAnswerButtons
                courseId={courseId}
                course={courses[courseId]}
                lessonId={lessonId}
                lessons={lessons}
                activity={activity}
                topic={topic}
                quiz={quiz}
                handleQuizReset={handleQuizReset}
                handleRevealAnswers={handleRevealAnswers}
                revealAnswers={revealAnswers}
                receiveSectionIndex={receiveSectionIndex}
                quizzes={quizzes}
            />
        )
    }

    const quizResult = buildQuizResult();

    if (activity || (isComplianceCourse && topic)) {
        return (
            <div className="QuizSummaryBox">
                {renderTitle()}
                {renderButtons()}
            </div>
        )
    }

    return (
        <div className="QuizSummaryBox">
            {renderTitle()}
            {renderButtons()}
            {
                (!isComplianceCourse && quizResult.num_questions !== 0) && (
                    <div className="QuizSummaryBox-Backdrop__review">
                        <div className="QuizSummaryBox-Score__container">
                            <div
                                className="QuizSummaryBox-Score__box"
                                style={
                                    quizResult && (quizResult.percent < 70) ? { backgroundColor: '#D47e7e' } : {}
                                }
                            >
                                {
                                    quizResult && (
                                        <>
                                            <TextBox text={`${quizResult.percent} %`} tag="h1" />
                                            <TextBox text="correct answers" tag="h5" />
                                            <TextBox text={`(${quizResult.num_correct}/${quizResult.num_questions})`} tag="h5" />
                                        </>
                                    )
                                }
                            </div>

                            <div className="QuizSummaryBox-Analysis__box">
                                {
                                    quizResult
                                    && (
                                        <>
                                            {
                                                quizResult.percent >= 70 ? <p className="QuizSummaryPage-Banner">Well done!</p> : <p className="QuizSummaryPage-Banner">Keep practicing, there's more to learn!</p>
                                            }
                                            {
                                                quizResult.percent < 70 &&
                                                <p className="QuizSummaryPage-Desc">Review your score breakdown below and click on the recommended links for more practice.</p>
                                            }
                                            {
                                                quizResult.percent < 70 && quiz &&
                                                <p className="QuizSummaryPage-Desc">You will not be able to get certificate of completion with this low of a score. You can retake quiz as well.</p>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            {
                (!isComplianceCourse && quizResult.num_questions !== 0) && (
                    <div className="QuizSummaryBox-Recommended">
                        <div>
                            <h2>Recommended Review by Section</h2>
                            <div className="QuizSummaryBox-Recommended__container">
                                {
                                    entity.sections?.map((section, idx) => {
                                        if (!recommendedSection.includes(section.section_type))
                                            return

                                        const sectionResult = buildSectionResult(section);
                                        return (
                                            sectionResult.percent !== 100 &&
                                            <div className="QuizSummaryBox-Recommended__box">
                                                <div className="QuizSummaryBox-Recommended__text">
                                                    <TextBox text={section.name} tag="h2" />
                                                    <TextBox text={`${sectionResult.percent}%`} />
                                                </div>

                                                <div className="QuizSummaryBox-Recommended__SubHeading">
                                                    {section.name}
                                                </div>

                                                <div className="QuizSummaryBox-Recommended__Ul">
                                                    <ul className="list-disc pl-10">
                                                        {
                                                            sectionResult.percent !== 0
                                                            ? (
                                                                section.questions?.map(question =>
                                                                    sectionResult.id_incorrect.includes(question.id) &&
                                                                    <li>{question.text.replace(/__blank__|__drop__|__drag__|__word__/g, '_____')}</li>
                                                                )
                                                            ) : (
                                                                <li>Practice all questions</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            {
                quizResult.num_questions !== 0 && (
                    <div className="QuizReviewBox__container">
                        { handleQuizReview() }
                    </div>
                )
            }
        </div>
    );
};

QuizSummaryBox.propTypes = {
    courseId: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
    activity: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    topic: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    quiz: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    handleQuizReset: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizSummaryBox);
