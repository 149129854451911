import {
  RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY,
  RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS,
  RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS, RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS,
  RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS, RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES
} from '../../../Actions/Stats/B2BAdmin/SpeakActions';

const SpeaksReducer = (state = {}, action) => {
  Object.freeze(state)

  switch (action.type) {
    case RECEIVE_B2B_ADMIN_SPEAK_PARTICIPATION_SUMMARY:
      return {...state, participationSummary: action.payload };
    case RECEIVE_B2B_ADMIN_SPEAK_USAGE_METRICS:
      return {...state, usageMetrics: action.payload };
    case RECEIVE_B2B_ADMIN_SPEAK_PLAYLISTS_METRICS:
      return {...state, playlistsMetrics: action.payload };
    case RECEIVE_B2B_ADMIN_SPEAK_PHRASES_METRICS:
      return {...state, phrasesMetrics: action.payload };
    case RECEIVE_B2B_ADMIN_SPEAK_WORKFLOWS_SCORES:
      return {...state, workflowsMetrics: action.payload };
    case RECEIVE_B2B_ADMIN_SPEAK_SKILL_METRICS:
      return {...state, skillMetrics: action.payload };
    default:
      return state;
  }
}

export default SpeaksReducer;
