import React, { Component } from 'react';

import './SectionBox.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import VideoBox from '../../Basic/VideoBox/VideoBox';
import YouTubeVideo from '../../Basic/YouTubeVideoBox/YouTubeVideo';
import Confirm from '../../Composed/Confirm/Confirm';
import Modal from '../../Composed/Modal/Modal';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import MultiButtonChoiceBoxMini from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceBoxMini';
import MultiButtonAnswerBoxMini from '../../Quiz/MultiButtonAnswerBox/MultiButtonAnswerBoxMini'
import FillInTheBlankBoxMini from '../../Quiz/FillInTheBlankBox/FillInTheBlankBoxMini';
import TrueOrFalseBoxMini  from '../../Quiz/TrueOrFalseBox/TrueOrFalseBoxMini';
import MatchingBoxMini from '../../Quiz/MatchingBox/MatchingBoxMini';
import DragAndDropBoxMini from '../../Quiz/DragAndDropBox/DragAndDropBoxMini';
import DropDownBoxMini from '../../Quiz/DropDownBox/DropDownBoxMini';
import SentenceOderingBoxMini from '../../Quiz/SentenceOrderingBox/SentenceOrderingBoxMini';
import FlashcardMini  from '../../Learning/Flashcard/FlashcardMini';
import WordScrambleBoxMini from '../../Quiz/WordScrambleBox/WordScrambleBoxMini';
import SortingBoxMini from '../../Quiz/SortingBox/SortingBoxMini';
import DialogBox from '../../Learning/DialogBox/DialogBox';
import CreateEditQuestion from '../../Forms/CreateEditQuestion/CreateEditQuestionContainer';
import SpanishKeyboard from '../../Composed/SpanishKeyboard/SpanishKeyboard';
import CreateEditSection  from '../../Forms/CreateEditSection/CreateEditSection';
import CreateEditTextContent from '../../Forms/CreateEditTextContent/CreateEditTextContent';
import CreateEditFlashcard from '../../Forms/CreateEditFlashcard/CreateEditFlashcard';
import UploadMedia from '../../Forms/UploadMedia/UploadMedia';
import CommentsPanelHandler from '../../Forms/CreateEditComment/CommentsPanelHandler';
import SectionCloneContainer from '../../Forms/Clone/SectionClone/SectionCloneContainer';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';

class SectionBox extends Component {
  audioSections = ["multiple_choice", "multiple_answer", "true_or_false", "drop_down"];
  textSections = ["multiple_choice", "multiple_answer", "text", "table_fill_in", "true_or_false"];
  videoSections = ["video", "dialog", "sentence_ordering", "true_or_false"]
  questionSections = [
    "multiple_choice",
    "multiple_answer",
    "fill_in_the_blank",
    "true_or_false",
    "matching",
    "drag_and_drop",
    "drop_down",
    "dialog",
    "sentence_ordering",
    "table_fill_in",
    "word_scramble",
    "sorting",
  ];  
  singleFormats = ["dialog"];

  handleDeleteSection = sectionId => e => {
    e.preventDefault();
    this.props.deleteSection(sectionId);
  }

  handleQuestionRender = () => {
    const { section, editQuestion, deleteQuestion, createAnswer, editAnswer, deleteAnswer,
            uploadSectionMedia, uploadAudio, creation, quizProgress,
            recordQuizAnswer, quiz, course } = this.props;
    const newProps = {
      course,
      section,
      editQuestion,
      deleteQuestion,
      createAnswer,
      editAnswer,
      deleteAnswer,
      uploadSectionMedia,
      uploadAudio,
      creation,
      quizProgress,
      recordQuizAnswer,
      quiz
    }

    if (this.singleFormats.includes(section.section_type)) {
      switch (section.section_type) {
        case "dialog":
          return ( 
            <div className="SectionBox-Single">
              <DialogBox
              questions={section.questions} 
              {...newProps} />
            </div>
          );
      default:
        break;
    }
  }

  return section.questions.map(
      question => {
        switch (question.question_type) {
          case "multiple_choice":
            return (
              <MultiButtonChoiceBoxMini
                key={question.id}
                question={question}
                { ...newProps } />
            );
          case "multiple_answer":
            return (
              <MultiButtonAnswerBoxMini 
                key={question.id}
                question={question}
                { ...newProps }
              />
              );
          case "fill_in_the_blank":
            return (
              <FillInTheBlankBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "true_or_false":
            return (
              <TrueOrFalseBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "matching":
            return (
              <MatchingBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "drag_and_drop":
            return (
              <DragAndDropBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "drop_down":
            return (
              <DropDownBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "sentence_ordering":
            return (
              <SentenceOderingBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "word_scramble":
            return (
              <WordScrambleBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          case "sorting":
            return (
              <SortingBoxMini
                key={question.id}
                question={question}
                {...newProps} />
            );
          default:
            return <div key={question.id} />;
        }
      }
    );
  };

  render() {
    const { course, section, createQuestion, createTextContent, 
            createFlashcard, editSection, editFlashcard, deleteFlashcard,
            uploadFlashcardAudio, editTextContent, deleteTextContent, 
            uploadTextContentAudio, createTable, editTable, deleteTable,
            creation, createTableEntry, editTableEntry,
            uploadSectionMedia, editAnswer, editQuestion, deleteQuestion, uploadAudio } = this.props;

    return (
      <div
        className="SectionBox">
        <div
          className="SectionBox-Menu">
          <div
            className="SectionBox-Position">
            <TextBox
              text={section.position}
              style={{
                color: `${COLORS.Blue}`,
              }}
              tag="h1" />
          </div>

          <div
            className="SectionBox-DataBox">
            <div
              className="SectionBox-DataBox-Title">
              <TextBox
                text={section.name}
                style={{
                  color: `${COLORS.Blue}`,
                }}
                tag="h4" />
            </div>
            
            {
              this.props.creation &&
              <div className="SectionBox-DataBox-Buttons">

                <ButtonBox
                    className="ButtonBox-EditQuestions"
                    text={<i className="fas fa-chevron-up" />}
                    onClick={() => this.props.moveHigher(section.id)}
                />

                <ButtonBox
                    className="ButtonBox-EditQuestions"
                    text={<i className="fas fa-chevron-down" />}
                    onClick={() => this.props.moveLower(section.id)}
                />

                {
                  this.questionSections.includes(section.section_type) &&
                  <Modal
                    title="Add A Question"
                    form={
                      close =>
                        <CreateEditQuestion
                          section={section}
                          closeModal={close}
                          submitQuestion={createQuestion} />
                    }
                    spanishKeyboard={course?.language === 'SP'}>
                    {
                      open =>
                        <ButtonBox
                          className="ButtonBox-EditQuestions"
                          text={<i className="fas fa-question"></i>}
                          onClick={open} />
                    }
                  </Modal>
                }
                {
                  this.audioSections.includes(section.section_type) &&
                  <Modal
                    title="Upload An Audio"
                    small
                    form={
                      close =>
                        <UploadMedia
                          submitMedia={uploadSectionMedia}
                          media_type="audio"
                          entity={section}
                          model="section"
                          attachment="audio"
                          closeModal={close} />
                    }>
                    {
                      open =>
                        <ButtonBox
                          className="ButtonBox-EditQuestions"
                          text={<i className="fas fa-headphones"></i>}
                          onClick={open} />
                    }
                  </Modal>
                }
                {
                  this.audioSections.includes(section.section_type) &&
                  section.audio_url &&
                  <Confirm
                    text="Are you sure you want to delete audio for this section?"
                    confirm={(e) => editSection({ id: section.id, purge_audio: true })}
                  >
                    {
                      open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fa fa-volume-off"></i>}
                        onClick={open}
                      />
                    }
                  </Confirm>
                }
                {
                  this.textSections.includes(section.section_type) &&
                  <Modal
                    title="Add A Text Section"
                    form={
                      close =>
                        <CreateEditTextContent
                          section={section}
                          submitTextContent={createTextContent}
                          closeModal={close} />
                    }>
                    {
                      open =>
                        <ButtonBox
                          className="ButtonBox-EditQuestions"
                          text={<i className="far fa-file-alt"></i>}
                          onClick={open} />
                    }
                  </Modal>
                }
                {
                  section.section_type === "flashcard" &&
                  <Modal
                    title="Add A Flashcard"
                    form={
                      close =>
                        <CreateEditFlashcard
                          course={course}
                          section={section}
                          submitFlashcard={createFlashcard}
                          closeModal={close} />
                    }>
                    {
                      open =>
                        <ButtonBox
                          className="ButtonBox-EditQuestions"
                          text={<i className="far fa-file-alt"></i>}
                          onClick={open} />
                    }
                  </Modal>
                }
                {
                  this.videoSections.includes(section.section_type) &&
                  <Modal
                    title="Upload A Video"
                    small
                    form={
                      close =>
                        <UploadMedia
                          submitMedia={uploadSectionMedia}
                          media_type="video"
                          entity={section}
                          model="section"
                          attachment="video"
                          closeModal={close} />
                    }>
                    {
                      open =>
                        <ButtonBox
                          className="ButtonBox-EditQuestions"
                          text={<i className="fas fa-video"></i>}
                          onClick={open} />
                    }
                  </Modal>
                }

                <Modal
                  title="Upload Instruction Audio"
                  small
                  form={
                    close =>
                      <UploadMedia
                        submitMedia={uploadSectionMedia}
                        media_type="audio"
                        entity={section}
                        model="section"
                        attachment="instruction_audio"
                        closeModal={close}
                      />
                  }
                >
                  {
                    open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fa fa-info"></i>}
                        onClick={open}
                      />
                  }
                </Modal>

                <CommentsPanelHandler
                  commentableType="Section"
                  commentableId={section.id}
                />

                <Modal
                  title="Target Activity/Topic/Quiz"
                  subTitle={`Select the target activity, topic, or quiz where section "${section.name}" needs to be cloned`}
                  form={close => <SectionCloneContainer section={section} />}
                >
                  {
                    open =>
                    <ButtonBox
                      className="ButtonBox-EditQuestions"
                      text={<i className="fas fa-clone" />}
                      onClick={open}
                    />
                  }
                </Modal>

                <Modal
                  title="Edit Section"
                  form={
                    close =>
                      <CreateEditSection
                        section={section}
                        submitSection={editSection}
                        closeModal={close} />
                  }>
                  {
                    open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="far fa-edit"></i>}
                        onClick={open} />
                  }
                </Modal>

                <Confirm
                  text="Are you sure you want to delete this section?"
                  confirm={this.handleDeleteSection(section.id)}>
                  {
                    open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="far fa-trash-alt"></i>}
                        onClick={open} />
                  }
                </Confirm>
              </div>
            }
          </div>
        </div>

        <div
          className="SectionBox-InfoBox">
          <div
          className="SectionBox-InfoBox-Label">
            <TextBox
              text="Instructions: "
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "bold",
              }} />
          </div>

            <div
                key={section.id}
                style={{ color: COLORS.Blue }}
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(section.instructions) }}
            />
        </div>

        <div
          className="SectionBox-InfoBox">
          <div
            className="SectionBox-InfoBox-Label">
            <TextBox
              text="Type: "
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "bold",
              }} />
          </div>

          <TextBox
            text={section.section_type}
            style={{
              color: `${COLORS.Blue}`,
            }} />
        </div>

        {
          Boolean(section.text_contents.length) &&
          <div className="SectionBox-TextContent">
            <TextContentBox 
              section={section}
              editTextContent={editTextContent}
              deleteTextContent={deleteTextContent}
              uploadTextContentAudio={uploadTextContentAudio}
              createTable={createTable}
              editTable={editTable}
              deleteTable={deleteTable}
              createTableEntry={createTableEntry}
              editTableEntry={editTableEntry}
              editAnswer={editAnswer}
              editQuestion={editQuestion}
              deleteQuestion={deleteQuestion}
              uploadAudio={uploadAudio}
              creation
            />
          </div>
        }

        {
          section.audio_url &&
          <div
            className="SectionBox-MediaContent">
            <AudioPlayButton
              src={section.audio_url}
              downloadable={creation}
            />
          </div>
        }

        {
          section.media_url ? (
            <div className="SectionBox-MediaContent">
              <YouTubeVideo shortUrl={section.media_url} />
            </div>
          ) : (
            section.video_url &&
            <div className="SectionBox-MediaContent">
              <VideoBox src={section.video_url} />
            </div>
          )
        }

        {
          Boolean(section.flashcards.length) &&
          section.flashcards.map(
            (flashcard, idx) => 
              <FlashcardMini 
                key={idx} 
                flashcard={flashcard}
                creation={creation}
                editFlashcard={editFlashcard}
                deleteFlashcard={deleteFlashcard}
                submitMedia={uploadFlashcardAudio}
              />
          )
        }

        { 
          section.section_type === "fill_in_the_blank"
          && course?.language === 'SP'
          && <div className="SectionBoc-SpanishKeyboard"><SpanishKeyboard /></div>
        }

        { this.handleQuestionRender() }
      </div>
    );
  }
} 

export default SectionBox;
