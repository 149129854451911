import { normalize } from 'normalizr';

import * as Schema from '../Schema/CoursePackageSchema';
import axios from './AxiosUtil';

export const fetchCoursePackages = () => (
    axios.get('/api/course_packages').then(
        res => normalize(res.data, Schema.coursePackages).entities,
    )
);

export const fetchProducts = () => (
    axios.get('/api/course_packages/products/all').then(
        res => normalize(res.data, Schema.coursePackages).entities,
    )
);

export const fetchRenewProducts = () => (
    axios.get('/api/course_packages/renew/all').then(
        res => normalize(res.data, Schema.coursePackages).entities,
    )
);

export const fetchCoursePackage = (coursePackageId) => (
    axios.get(`/api/course_packages/${coursePackageId}`).then(
        res => normalize(res.data, Schema.coursePackage).entities,
    )
);

export const createCoursePackage = (coursePackage) => (
    axios.post('/api/course_packages',
        { course_package: coursePackage }).then(
        res => normalize(res.data, Schema.coursePackage).entities,
    )
);

export const editCoursePackage = (coursePackage) => (
    axios.patch(`/api/course_packages/${coursePackage.id}`,
        { course_package: coursePackage }).then(
        res => normalize(res.data, Schema.coursePackage).entities,
    )
);

export const deleteCoursePackage = (coursePackageId) => (
    axios.delete(`/api/course_packages/${coursePackageId}`).then(
        res => normalize(res.data, Schema.coursePackage).entities,
    )
);
