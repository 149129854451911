import React, { Component } from 'react';
import { Prompt } from "react-router-dom";
import PropTypes from 'prop-types';

import './QuizPage.css';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import LessonAnswerButtons from '../../Composed/LessonAnswerButtons/LessonAnswerButtons';
import QuizSummaryBox from '../../Composed/QuizSummaryBox/QuizSummaryBox';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import MultiButtonChoiceContainer from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceContainer';
import MultiButtonAnswerContainer from '../../Quiz/MultiButtonAnswerBox/MultiButtonAnswerContainer';
import FillInTheBlankContainer from '../../Quiz/FillInTheBlankBox/FillInTheBlankContainer';
import TrueOrFalseContainer from '../../Quiz/TrueOrFalseBox/TrueOrFalseContainer';
import DropDownContainer from '../../Quiz/DropDownBox/DropDownContainer';
import DragAndDropBox from '../../Quiz/DragAndDropBox/DragAndDropBox';
import MatchingBox from '../../Quiz/MatchingBox/MatchingBox';
import SentenceOrderingBox from '../../Quiz/SentenceOrderingBox/SentenceOrderingBox';
import WordScrambleContainer from '../../Quiz/WordScrambleBox/WordScrambleContainer';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import AudioProgressBox from '../../Basic/AudioProgressBox/AudioProgressBox';
import SpanishKeyboard from '../../Composed/SpanishKeyboard/SpanishKeyboard';
import CourseLeftover from '../../Composed/CourseLeftover/CourseLeftover';

import { instructionsContent, secondsToMiliseconds } from '../../../Util/Helper/GenericUtil';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';
import { continueLessonLink } from '../../../Util/Helper/CourseUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { isStudent } from '../../../Util/Helper/UserWorkplaceUtil';
import { trackSeatTime, syncSeatTime, removeLs } from '../../../Actions/SeatTimeActions';

const propTypes = {
    course: PropTypes.shape({
        language: PropTypes.string,
    }).isRequired,
    courseProgresses: PropTypes.object,
    lesson: PropTypes.shape({
        quiz_id: PropTypes.number,
    }).isRequired,
    quizzes: PropTypes.objectOf(PropTypes.object).isRequired,
    quizResults: PropTypes.object,
    courseId: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
    fetchQuiz: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    quizProgress: PropTypes.objectOf(PropTypes.object),
    recordQuizAnswer: PropTypes.func.isRequired,
    recordQuizState: PropTypes.func.isRequired,
    quizStates: PropTypes.objectOf(PropTypes.object).isRequired,
    submitQuiz: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    clearQuizProgresses: PropTypes.func.isRequired,
    clearQuizStates: PropTypes.func.isRequired,
    clearSectionIndex: PropTypes.func.isRequired,
    receiveSectionIndex: PropTypes.func.isRequired,
    bookmarks: PropTypes.objectOf(Array).isRequired,
    createBookmark: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired
};

class QuizPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSectionIndex: 0,
            slide: true,
            submit: false,
            submitThresholdMet: false,
            summary: false,
            displayBookmarkSection: true,
            loading: true,
            submitting: false,
            error: '',
        };
        this.blockNavigation = true;
        this.seatTimeTracker = {};
    }

    componentDidMount() {
        let queryString = new URLSearchParams(this.props.location.search);
        const {
            fetchQuiz, clearQuizProgresses, clearQuizStates,
            receiveSectionIndex, navigationSectionIndex,
            courseId, course, lessonId, lesson, lessons, quizzes,
        } = this.props;

        syncSeatTime();
        clearQuizProgresses();
        clearQuizStates();
        fetchQuiz(lessonId).then(res => {
            if (res.payload?.status === 'archive') {
                this.props.history.push(continueLessonLink(course, lesson, null, null, lesson.quiz_id, lessons));
            } else {
                this.setState({ loading: false }, () => {
                    this.handleSeatTimeStart(quizzes[lesson?.quiz_id], navigationSectionIndex);
                });
            }
            if (queryString.has('copn'))
                document.getElementsByClassName('Comments-Button')[0].click();
        });
        receiveSectionIndex(queryString.has('nextSec') ? parseInt(queryString.get('nextSec')) : 0);
        this.setState({ currentSectionIndex: queryString.has('nextSec') ? parseInt(queryString.get('nextSec')) : 0 });
        window.addEventListener('beforeunload', this.onBeforeUnload);
        window.addEventListener('unload', this.onUnload);
        document.addEventListener('visibilitychange', this.onVisibilityChange);
    }

    componentDidUpdate(prevProps) {
        const {
            fetchQuiz, clearQuizProgresses, clearQuizStates,
            receiveSectionIndex, navigationSectionIndex,
            courseId, course, lessonId, lesson, lessons, quizzes,
        } = this.props;

        if (prevProps.lessonId !== lessonId) {
            this.setState({ loading: true });
            this.handleSeatTimeEnd(quizzes[prevProps.lesson?.quiz_id], prevProps.navigationSectionIndex);
            removeLs(`seat-time-of-quiz-${prevProps.lesson?.quiz_id}`);
            clearQuizProgresses();
            clearQuizStates();
            fetchQuiz(lessonId).then(res => {
                if (res.payload?.status === 'archive') {
                    this.props.history.push(continueLessonLink(course, lesson, null, null, lesson.quiz_id, lessons));
                } else {
                    this.setState({ loading: false }, () => {
                        this.handleSeatTimeStart(quizzes[lesson?.quiz_id], navigationSectionIndex);
                    });
                }
            });
            receiveSectionIndex(0);
            this.setState({ slide: true, submit: false, summary: false, currentSectionIndex: 0 });
            this.blockNavigation = true;
            window.addEventListener('beforeunload', this.onBeforeUnload);
            window.addEventListener('unload', this.onUnload);
            document.addEventListener('visibilitychange', this.onVisibilityChange);
        } else if (prevProps.navigationSectionIndex !== navigationSectionIndex) {
            this.handleSeatTimeEnd(quizzes[lesson?.quiz_id], prevProps.navigationSectionIndex);
            this.handleSeatTimeStart(quizzes[lesson?.quiz_id], navigationSectionIndex);
        }
    }

    componentWillUnmount() {
        const { navigationSectionIndex, lesson, quizzes } = this.props;

        this.handleSeatTimeEnd(quizzes[lesson?.quiz_id], navigationSectionIndex);
        removeLs(`seat-time-of-quiz-${lesson?.quiz_id}`);

        this.props.clearSectionIndex();
        window.removeEventListener('beforeunload', this.onBeforeUnload);
        window.removeEventListener('unload', this.onUnload);
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
    }

    onBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = true;
    }

    onUnload = () => {
        const { navigationSectionIndex, lesson, quizzes } = this.props;

        this.handleSeatTimeEnd(quizzes[lesson?.quiz_id], navigationSectionIndex);
        removeLs(`seat-time-of-quiz-${lesson?.quiz_id}`);
    }

    onVisibilityChange = () => {
        const { navigationSectionIndex, lesson, quizzes } = this.props;

        switch (document.visibilityState) {
            case 'hidden':  return this.handleSeatTimeEnd(quizzes[lesson?.quiz_id], navigationSectionIndex);
            case 'visible': return this.handleSeatTimeStart(quizzes[lesson?.quiz_id], navigationSectionIndex);
            default:        return null;
        }
    }

    handleSeatTimeStart = (ofQuiz, ofSectionIndex) => {
        if (!isStudent(this.props.currentUser) || !ofQuiz?.sections?.[ofSectionIndex] || this.seatTimeTracker.start) {
            return null;
        }

        clearInterval(this.seatTimeTracker.intervalId);

        this.seatTimeTracker = {
            start: Date.now(),
            lastActive: Date.now(),
            intervalId: setInterval(() => {
                if ((this.seatTimeTracker.lastActive + secondsToMiliseconds(15)) > Date.now()) {
                    this.seatTimeTracker['lastActive'] = Date.now();
                } else {
                    clearInterval(this.seatTimeTracker.intervalId);
                }
            }, secondsToMiliseconds(10)),
        };
    }

    handleSeatTimeEnd = (ofQuiz, ofSectionIndex) => {
        if (!isStudent(this.props.currentUser) || !ofQuiz?.sections?.[ofSectionIndex] || !this.seatTimeTracker.start) {
            return null;
        }

        clearInterval(this.seatTimeTracker.intervalId);

        let diffInLastActiveAndNow  = Date.now() - this.seatTimeTracker.lastActive;
        this.seatTimeTracker['end'] = (diffInLastActiveAndNow >= secondsToMiliseconds(0) && diffInLastActiveAndNow <= secondsToMiliseconds(10)) ? Date.now() : this.seatTimeTracker.lastActive;

        if ((this.seatTimeTracker.end - this.seatTimeTracker.start) < secondsToMiliseconds(5)) {
            this.seatTimeTracker = {};
            return null;
        }

        trackSeatTime(`seat-time-of-quiz-${ofQuiz.id}`, {
            seat_timeable_type: 'Section',
            seat_timeable_id: ofQuiz.sections[ofSectionIndex].id,
            start: (new Date(this.seatTimeTracker.start)).toUTCString(),
            end: (new Date(this.seatTimeTracker.end)).toUTCString(),
        });

        this.seatTimeTracker = {};
    }

    toggleNavHandler = (direction) => {
        const {
            quizzes,
            lesson,
            history,
            courseId,
            receiveSectionIndex
        } = this.props;
        const { currentSectionIndex } = this.state;

        const quiz = quizzes[lesson.quiz_id];
        const currentSection = quiz.sections[currentSectionIndex];

        return (e) => {
            e.preventDefault();
            if (direction === 'left' && currentSectionIndex === 0) {
                history.push(`/learn/courses/${courseId}/lessons`);
                return;
            }

            if (direction === 'right'
                && currentSectionIndex === quiz.sections.length) {
                return;
            }

            if (direction === 'right') {
                if (false && currentSection && currentSection.instruction_audio_url && !this.getSectionInstructionAudio(currentSection)) {
                    document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = 'Please listen to the full audio before moving on.'
                    return null;
                }

                if ((currentSectionIndex + 1) === quiz.sections.length) {
                    this.setState({ slide: false, submit: true, displayBookmarkSection: false });
                }
                gaEvent('next_slide');
                receiveSectionIndex(currentSectionIndex + 1);
                this.setState({
                    currentSectionIndex: currentSectionIndex + 1,
                });
            } else if (direction === 'left') {
                gaEvent('previous_slide');
                receiveSectionIndex(currentSectionIndex - 1);
                this.setState({
                    currentSectionIndex: currentSectionIndex - 1,
                    slide: true,
                    submit: false,
                    displayBookmarkSection: true
                });
            }
        };
    }

    handleSubmit = () => {
        const {
            quizzes,
            lesson,
            quizProgress,
            submitQuiz,
            currentUser,
        } = this.props;
        const quiz = quizzes[lesson.quiz_id];

        let numQuestions = 0;
        let numCorrect = 0;

        quiz.sections.forEach(section => {
            section.questions.forEach(question => {
                numQuestions += question.answers.length;
            });
        });


        if (quizProgress) {
            Object.values(quizProgress).forEach(sectionProgress => {
                Object.values(sectionProgress).forEach(questionProgress => {
                    Object.values(questionProgress).forEach(answerProgress => {
                        if (answerProgress.is_correct) {
                            numCorrect++;
                        }
                    });
                });
            });
        }

        const quizData = {
            user_id: currentUser.id,
            quiz_id: quiz.id,
            num_correct: numCorrect,
            num_questions: numQuestions,
            quiz_data: quizProgress,
        }

        this.setState({ submitting: true });
        submitQuiz(quizData).then((res) => {
            if (res?.errors) {
                return this.setState({ submitting: false, error: 'Oops! something went wrong. Please try submitting the quiz again.' });
            }
            gaEvent('quiz_attempted', { quiz_id: quiz.id });
            this.setState({ submitting: false, error: '', submit: false, summary: true });
            this.blockNavigation = false;
            window.removeEventListener('beforeunload', this.onBeforeUnload);
            window.removeEventListener('unload', this.onUnload);
            document.removeEventListener('visibilitychange', this.onVisibilityChange);
        });
    }

    handleQuizReset = () => {
        const { clearQuizProgresses, clearQuizStates, receiveSectionIndex } = this.props;
        clearQuizProgresses();
        clearQuizStates();
        receiveSectionIndex(0);
        gaEvent('retake_quiz');
        this.setState({
            currentSectionIndex: 0,
            slide: true,
            submit: false,
            summary: false,
        });
        this.blockNavigation = true;
    }

    handleInstructions = (currentSection) => {
        const { course } = this.props;

        let finalInstructions = '';

        if (course?.course_type?.name === 'Compliance') {
            finalInstructions = currentSection.instructions;
        } else {
            finalInstructions = currentSection.instructions ? (`<p>${currentSection.position}. ${currentSection.name}</p><br>${currentSection.instructions}`) : (`${currentSection.position}. ${currentSection.name}`);
        }

        return (
            finalInstructions && instructionsContent(finalInstructions) &&
            <div className="LearningPage-Instructions">
                <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(finalInstructions) }}
                />
            </div>
        )
    }

    isBookmarkedSection = section => {
        const { bookmarks } = this.props;

        if (!section) {
            return false;
        }

        return bookmarks?.ids?.sections?.includes(section.id);
    }

    bookmarkSection = section => () => {
        const {
            createBookmark,
            deleteBookmark
        } = this.props;

        const bookmark = { bookmarkable_type: 'Section', bookmarkable_id: section.id };
        if (this.isBookmarkedSection(section)) {
            gaEvent('unbookmark_slide', { section_id: section.id });
            deleteBookmark(bookmark);
        } else {
            gaEvent('bookmark_slide', { section_id: section.id });
            createBookmark(bookmark);
        }
    }

    setSectionInstructionAudio = section => () => {
        if (!section)
            return null;

        document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = '';

        let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];

        if (listened.includes(section.id))
            return null;

        listened.push(section.id)
        window.localStorage.setItem('sectionInstructionAudios', JSON.stringify(listened));
    }

    getSectionInstructionAudio = section => {
        let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];
        return listened.includes(section.id);
    }

    handleSubmitRender() {
        const { course, lesson, lessonId, quizzes, quizProgress, quizStates, recordQuizAnswer, recordQuizState } = this.props;

        const quiz = quizzes[lesson.quiz_id];

        let numQuestions = 0;
        let numAttempted = 0;

        quiz.sections.forEach(section => {
            section.questions.forEach(question => numQuestions++);
        });

        Object.values(quizProgress || {}).forEach(sectionProgress => {
            Object.values(sectionProgress).forEach(questionProgress => numAttempted++);
        });

        let submitThresholdMet = ((numAttempted / numQuestions) * 100) >= 66;

        if (submitThresholdMet !== this.state.submitThresholdMet) {
            this.setState({ submitThresholdMet });
        }

        return (
            <div className="QuizPage-Submit-Content">
                {
                    !this.state.submitThresholdMet &&
                    <div id="Answered-All-Error">You need to attempt more questions before you see your results and feedback page.</div>
                }
                <div className="LearningPage-Instructions">
                    <p>Review your answers before submitting the quiz.</p>
                    <p>Make sure to answer any questions that you may have missed.</p>
                </div>

                {
                    quiz.sections.map((section, idx) => {
                        let component;

                        let newProps = {
                            lessonId, section, questions: section.questions, quizProgress, quizStates, recordQuizAnswer, recordQuizState,
                            pinUnanswered: true,
                            isComplianceCourse: (course?.course_type?.name === 'Compliance')
                        };

                        if (section.questions.length) {
                            switch (section.section_type) {
                                case 'multiple_choice':
                                    component = <MultiButtonChoiceContainer newProps={newProps} section={section} />
                                    break;
                                case 'multiple_answer':
                                    component = <MultiButtonAnswerContainer newProps={newProps} section={section} />
                                    break;
                                case 'fill_in_the_blank':
                                    component = <FillInTheBlankContainer newProps={newProps} section={section} />
                                    break;
                                case 'true_or_false':
                                    component = <TrueOrFalseContainer newProps={newProps} section={section} />
                                    break;
                                case 'drop_down':
                                    component = <DropDownContainer newProps={newProps} section={section} />
                                    break;
                                case 'word_scramble':
                                    component = <WordScrambleContainer newProps={newProps} section={section} />
                                    break;
                                case 'drag_and_drop':
                                    component = <DragAndDropBox { ...newProps } />
                                    break;
                                case 'matching':
                                    component = <MatchingBox { ...newProps } />
                                    break;
                                case 'sentence_ordering':
                                    component = <SentenceOrderingBox { ...newProps } />
                                    break;
                                default:
                                    component = null;
                            }
                        } else {
                            component = section.section_type === 'text' ? '' : <div>This section is incomplete. It contains no questions.</div>;
                        }

                        return (
                            <div className="QuizPage-Submit-Container">
                                <div className="QuizPage-Submit-Title">
                                    <span>{ idx + 1 }.</span>
                                    <span>{ section.name }</span>
                                </div>
                                {
                                    this.handleInstructions(section)
                                }
                                <div className="QuizPage-Content">
                                    {
                                        section.text_contents &&
                                        <TextContentBox section={section} />
                                    }

                                    {
                                        section.section_type === 'fill_in_the_blank' &&
                                        course.language === 'SP' &&
                                        <SpanishKeyboard />
                                    }
                                    { component }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    handleRender() {
        const {
            course,
            quizzes,
            lessonId,
            lesson,
            quizProgress,
            recordQuizAnswer,
            recordQuizState,
            quizStates,
        } = this.props;
        const { currentSectionIndex } = this.state;

        const quiz = quizzes[lesson.quiz_id];

        const currentSection = quiz.sections[currentSectionIndex];

        const newProps = {
            lessonId,
            section: currentSection,
            questions: currentSection.questions,
            recordQuizAnswer,
            recordQuizState,
            quizProgress,
            quizStates,
            isComplianceCourse: (course?.course_type?.name === 'Compliance')
        };

        let component;

        if (currentSection.questions.length) {
            switch (currentSection.section_type) {
                case 'multiple_choice':
                    component = <MultiButtonChoiceContainer newProps={newProps} section={currentSection} />
                    break;
                case 'multiple_answer':
                    component = <MultiButtonAnswerContainer newProps={newProps} section={currentSection} />
                    break;
                case 'fill_in_the_blank':
                    component = <FillInTheBlankContainer newProps={newProps} section={currentSection} />
                    break;
                case 'true_or_false':
                    component = <TrueOrFalseContainer newProps={newProps} section={currentSection} />
                    break;
                case 'drop_down':
                    component = <DropDownContainer newProps={newProps} section={currentSection} />
                    break;
                case 'word_scramble':
                    component = <WordScrambleContainer newProps={newProps} section={currentSection} />
                    break;
                case 'drag_and_drop':
                    component = <DragAndDropBox { ...newProps } />;
                    break;
                case 'matching':
                    component = <MatchingBox { ...newProps } />;
                    break;
                case 'sentence_ordering':
                    component = <SentenceOrderingBox { ...newProps } />;
                    break;
                default:
                    return null;
            }
        } else {
            component = currentSection.section_type === 'text' ? '' : <div>This section is incomplete. It contains no questions.</div>;
        }

        if (currentSection.text_contents.length) {
            return (
                <div className={`QuizPage-Content ${currentSection.section_type}`}>
                    <div className="QuizPage-TextBox">
                        <TextContentBox { ...newProps } />
                    </div>

                    { component }
                </div>
            );
        }

        if (currentSection.audio_url) {
            return (
                <div className={`QuizPage-Content ${currentSection.section_type}`}>
                    <div className="QuizPage-AudioPlayButton">
                        <AudioPlayButton
                            className="AudioPlayButton-QuizPage"
                            src={currentSection.audio_url}
                        />
                    </div>

                    { component }
                </div>
            );
        }

        return (
            component &&
            <div className={`QuizPage-Content ${currentSection.section_type}`}>
                {
                    currentSection.section_type === 'fill_in_the_blank'
                    && course.language === 'SP'
                    && <SpanishKeyboard shouldFix={true} />
                }
                { component }
            </div>
        );
    }

    render() {
        const { courseId, course, courseProgresses, lessonId, lesson, quizzes, quizResults } = this.props;
        const { slide, loading, submit, submitThresholdMet, summary, currentSectionIndex, submitting, error } = this.state;

        if (loading) {
          return <MirrorLoader message="Loading, Hang on!" />
        }

        const quiz = quizzes[lesson?.quiz_id];

        if (!quiz?.sections || !quiz.sections.length) {
            return <div className="QuizPage" />;
        }

        const currentSection = quiz.sections[currentSectionIndex];

        return (
            <div className="QuizPage-Backdrop">
                <Prompt
                    when={this.blockNavigation}
                    message="Are you sure you want to leave this quiz? All progress will be lost."
                />

                {submitting && <MirrorLoader message={'Submitting Quiz!'} />}
                {error && <div id="Answered-All-Error">{error}</div>}
                {
                    lessonId === course.lessons[course.lessons.length - 1].toString() &&
                    <CourseLeftover
                        course={course}
                        courseProgress={courseProgresses[courseId]}
                        quizResults={quizResults}
                    />
                }

                <div className="QuizPage">
                    <div className="LearningPage-LessonAnswerButtons_container">
                        {
                            (slide || submit) && (
                                <LessonAnswerButtons
                                    currentUser={this.props.currentUser}
                                    quiz={quiz}
                                    currentSection={currentSection}
                                    displayBookmarkSection={this.state.displayBookmarkSection}
                                    isBookmarkedSection={this.isBookmarkedSection(currentSection)}
                                    bookmarkSection={this.bookmarkSection(currentSection)}
                                    displayLeftClick={true}
                                    leftClickHandler={this.toggleNavHandler('left')}
                                    displayRightClick={!submit}
                                    rightClickHandler={this.toggleNavHandler('right')}
                                    displaySubmitQuiz={submit && submitThresholdMet}
                                    submitQuizHandler={() => this.handleSubmit()}
                                />
                            )
                        }

                        { slide && this.handleInstructions(currentSection) }

                        {
                            slide && currentSection?.instruction_audio_url &&
                            <AudioProgressBox
                                src={currentSection.instruction_audio_url}
                                onEndHandler={this.setSectionInstructionAudio(currentSection)}
                            />
                        }
                    </div>

                    {
                        summary
                        ? (
                            <QuizSummaryBox
                                courseId={courseId}
                                lessonId={lessonId}
                                quiz={quiz}
                                handleQuizReset={this.handleQuizReset}
                            />
                        )
                        : (
                            submit ? this.handleSubmitRender() : this.handleRender()
                        )
                    }
                </div>
            </div>
        );
    }
}

QuizPage.propTypes = propTypes;

export default QuizPage;
