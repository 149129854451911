import { combineReducers } from 'redux';

import learns from './Users/LearnsReducer';
import compliances from './Users/CompliancesReducer'
import credentials from './Users/CredentialReducer';

export default combineReducers({
  learns,
  compliances,
  credentials
});
