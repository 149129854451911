import { connect } from 'react-redux';
import CreateLicense from './index';
import { createLicenseRequest } from '../../../Actions/LicenseActions';
import { fetchCoursePackages } from '../../../Actions/CoursePackageActions';

const mapStateToProps = state => ({
    errors: state.errors.license,
    flash: state.flash.license,
    coursePackages: state.entities.coursePackages,
});

const mapDispatchToProps = dispatch => ({
    createLicense: license => dispatch(createLicenseRequest(license)),
    fetchCoursePackages: () => dispatch(fetchCoursePackages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLicense);
