import {
  RECEIVE_ONBOARDING_CHARGE,
  RECEIVE_ONBOARDING_CHARGE_ERRORS
} from '../../Actions/Onboarding/ChargeActions';

const ChargesReducer = (state = {}, action) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_ONBOARDING_CHARGE:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}

export default ChargesReducer;
