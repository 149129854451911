import React, { Component } from 'react';

import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';

class CreateEditDragAndDrop extends Component {
  state = this.props.question ?
  {
    text: this.props.question.text,
    hint: this.props.question.hint,
    answers_attributes: this.props.question.answers,
  }
  :
  {
    text: "",
    hint: "",
    answers_attributes: []
  }

  textArea = React.createRef();

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleAnswerInput = (idx) => {
    return (e) => {
      const answers_attributes = [...this.state.answers_attributes];
      answers_attributes[idx].text = e.currentTarget.value;
      this.setState({
        answers_attributes,
      })
    }
  }

  handleAddAnswer = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const textAreaNode = this.textArea.current;
      const newText =
        prevState.text.slice(0, textAreaNode.selectionStart) + "__drag__" +
        prevState.text.slice(textAreaNode.selectionEnd, prevState.text.length);
      return {
        answers_attributes: [...prevState.answers_attributes, { text: "", index: prevState.answers_attributes.length }],
        text: newText,
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const question = Object.assign({}, this.state);

    if(this.props.section) {
      question.section_id = this.props.section.id;
      question.number_of_answers = this.state.answers_attributes.length;
      question.question_type = this.props.section.section_type;
    }

    if(this.props.question) {
      question.id = this.props.question.id;
    }

    this.props.submitQuestion(question).then(
      this.props.closeModal()
    );
  }

  handleAnswersRender() {
    return this.state.answers_attributes.map(
      (answer, idx) => (
        <div
          className="CreationForms-InputBox"
          key={idx}>
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.answers_attributes[idx].text}
            placeholder={`Answer ${idx + 1}`}
            onChange={this.handleAnswerInput(idx)}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>
      )
    );
  }

  handleBlur = () => e => {
    e.currentTarget.id = "";
  };

  handleFocus = () => e => {
    e.currentTarget.id = "active";
  };

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <textarea
            ref={this.textArea}
            className="TextAreaBox-CreationForms"
            value={this.state.text}
            placeholder="Text Here"
            onChange={this.handleInputChange('text')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-AnswersBox">

          {
            this.handleAnswersRender()
          }

          <div
            className="CreationForms-Button">
            <ButtonBox
              className="CreationDashboards-ButtonBox"
              text="Insert A Draggable"
              onClick={this.handleAddAnswer} />
          </div>
        </div>

        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.hint}
            placeholder="Hint Optional"
            onChange={this.handleInputChange('hint')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditDragAndDrop;