import { RECEIVE_CURRENT_USER } from '../../Actions/SessionActions';
import {
  CLEAR_USER_NOTIFICATIONS,
  RECEIVE_USER_NOTIFICATIONS,
  RECEIVE_USER_NOTIFICATION,
} from '../../Actions/UserNotificationActions';

const UserNotifiactionReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return Object.assign({}, state, { ...state, notDisplayedUserNotificationsCount: action.payload.notDisplayedUserNotificationsCount } );
    case RECEIVE_USER_NOTIFICATIONS:
      return Object.assign({}, state, { data: action.payload.userNotifications, total: action.payload.totalUserNotifications, notDisplayedUserNotificationsCount: action.payload.notDisplayedUserNotificationsCount } );
    case RECEIVE_USER_NOTIFICATION:
      return Object.assign({}, state, { ...state, data: { ...state.data, [action.payload.userNotification.id]: action.payload.userNotification }, notDisplayedUserNotificationsCount: action.payload.notDisplayedUserNotificationsCount });
    case CLEAR_USER_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
}

export default UserNotifiactionReducer;
