import { merge } from 'lodash';
import {
    RECEIVE_INSTITUTIONS,
    RECEIVE_INSTITUTION,
    CLEAR_INSTITUTIONS,
} from '../../Actions/InstitutionActions';

const InstitutionsReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_INSTITUTIONS:
            return Object.assign({}, action.payload);
        case RECEIVE_INSTITUTION:
            return merge({}, state, {
                [action.payload.id]: action.payload,
            });
        case CLEAR_INSTITUTIONS:
            return {};
        default:
            return state;
    }
};

export default InstitutionsReducer;
