import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './QuizAnswerButtons.css';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { continueLessonLink } from '../../../Util/Helper/CourseUtil';

const QuizAnswerButtons = ({
    courseId,
    course,
    lessonId,
    lessons,
    activity,
    topic,
    quiz,
    handleQuizReset,
    handleRevealAnswers,
    revealAnswers,
    receiveSectionIndex,
    quizzes
}) => {
    return (
        <div className="QuizAnswerButtons">
            <Link to={`/learn/courses/${courseId}/lessons`}>
                <ButtonBox
                    className="QuizAnswerButtons-ButtonBox Green-Btn"
                    text={(
                        <div className="Green-Btn-Text">
                            <i className="fas fa-chevron-left" />
                            Course Home
                        </div>
                    )}
                />
            </Link>

            {
                quiz &&
                <ButtonBox
                    className={
                        revealAnswers
                            ? 'QuizAnswerButtons-ButtonBox Green-Btn active'
                            : 'QuizAnswerButtons-ButtonBox Green-Btn'
                    }
                    text={(
                        <div className="Green-Btn-Text">
                            <i className="fas fa-key" />
                            Show Answers
                        </div>
                    )}
                    onClick={handleRevealAnswers}
                />
            }

            {
                handleQuizReset &&
                <ButtonBox
                    className="QuizAnswerButtons-ButtonBox Green-Btn"
                    text={(
                        <div className="Green-Btn-Text">
                            <i className="fas fa-redo" />
                            Retake Quiz
                        </div>
                    )}
                    onClick={handleQuizReset}
                />
            }

            <Link to={continueLessonLink(course, lessons[lessonId], activity?.id, topic?.id, quiz?.id, lessons)} onClick={() => {receiveSectionIndex(0);}}>
                <ButtonBox
                    className="QuizAnswerButtons-ButtonBox Green-Btn"
                    text={(
                        <div className="Green-Btn-Text">
                            Continue
                            <i className="fas fa-chevron-right" />
                        </div>
                    )}
                />
            </Link>
        </div>
    );
};

QuizAnswerButtons.propTypes = {
    courseId: PropTypes.string.isRequired,
    course: PropTypes.object.isRequired,
    lessonId: PropTypes.string.isRequired,
    lessons: PropTypes.objectOf(PropTypes.object).isRequired,
    activity: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    topic: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    quiz: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.object)
    }),
    handleQuizReset: PropTypes.func,
    handleRevealAnswers: PropTypes.func.isRequired,
    revealAnswers: PropTypes.bool.isRequired,
    receiveSectionIndex: PropTypes.func.isRequired
};

export default QuizAnswerButtons;
