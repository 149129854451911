import * as APIUtil from '../../Util/Engage/StreakConfigApiUtil';
import ErrorResponseUtil from '../../Util/ErrorResponseUtil';

export const RECEIVE_STREAK_CONFIG = 'RECEIVE_STREAK_CONFIG';
export const RECEIVE_STREAK_CONFIG_ERRORS = 'RECEIVE_STREAK_CONFIG_ERRORS';

export const receiveStreakConfig = (payload) => ({
  type: RECEIVE_STREAK_CONFIG,
  payload,
});

export const receiveStreakConfigErrors = (errors) => ({
  type: RECEIVE_STREAK_CONFIG_ERRORS,
  errors,
});

export const createStreakConfig = (streakConfig) => dispatch => (
  APIUtil.createStreakConfig(streakConfig).then(
    payload => dispatch(receiveStreakConfig(payload)),
    errors => dispatch(receiveStreakConfigErrors(ErrorResponseUtil(errors))),
  )
);
