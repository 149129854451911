import { GET_PROFILE_SUCCESS } from '../../../../Actions/Profile';
import { UPDATE_WORKPLACE_SCHOOL } from '../../../../Actions/Profile/Occupation/WorkplaceSchool';


const initialState = {
    organization_name: '',
    state: '',
    website: '',
    hours: [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ],
};

const workplaceSchool = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE_SUCCESS:
            return action.payload.data.occupation.workplaceSchool;

        case UPDATE_WORKPLACE_SCHOOL:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

export default workplaceSchool;
