import React from 'react';
import Select from 'react-select';

const AutoComplete = ({ className, options, defaultValue, value, onChange, isMulti, styles = {} }) => {
  return(
    <Select
      className={className}
      options={options}
      defaultValue={defaultValue}
      value={value}
      onChange={(val) => onChange(val)}
      isMulti={isMulti}
      styles={{
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none'
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display: 'none'
        }),
        control: (provided, state) => ({
          ...provided,
          minHeight: '36px',
          border: '1px solid #6bd1be',
          fontFamily: 'LATO',
          fontSize: styles.fontSize ? styles.fontSize : '14px',
          color: '#193c5a'
        }),
        input: (provided, state) => ({
          ...provided,
          height: '25px',
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: '200px',
        }),
      }}
    />
  );
};

export default AutoComplete;
