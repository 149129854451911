import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    createInstitution,
    editInstitution,
    deleteInstitution,
    clearInstitutionErrors,
} from '../../../Actions/InstitutionActions';
import CreateEditInstitution from './CreateEditInstitution';

const mapStateToProps = (state, ownProps) => ({
    errors: state.errors.institutions,
    flash: state.flash.institutions,
    history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
    createInstitution: institution => dispatch(createInstitution(institution)),
    editInstitution: institution => dispatch(editInstitution(institution)),
    deleteInstitution: institutionId => dispatch(deleteInstitution(institutionId)),
    clearInstitutionErrors: () => dispatch(clearInstitutionErrors()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEditInstitution));
