import React from 'react';
import { Link } from 'react-router-dom';

export const titles = {
  welcome_when_institution_admin_signs_up: <div className="Title">You haven't added any users!</div>,
  notify_institution_admin_when_few_license_seats_left: <div className="Title">Canopy License Update - Limited Seats Remaining</div>,
  notify_institution_admin_when_license_seats_are_full: <div className="Title">Canopy License Update - No Seats Remaining</div>,
  welcome_when_user_signs_up: <div className="Title">Welcome to Canopy!</div>,
  send_confirmation_follow_up_after_user_purchased_product: <div className="Title">More Information About Your Canopy Subscription</div>,
  remind_to_complete_profile_if_not_after_user_purchases: <div className="Title">Action Required: Complete Your Canopy Profile</div>,
  congratulate_when_completes_learn_level_1: <div className="Title">Congratulations!</div>,
  congratulate_when_completes_learn_level_2: <div className="Title">Congratulations!</div>,
  congratulate_when_completes_learn_level_3: <div className="Title">Congratulations!</div>,
  congratulate_when_completes_compliance_level: <div className="Title">Congratulations!</div>,
  congratulate_when_completes_credential_exam: <div className="Title">Congratulations!</div>,
  level_2_overview_if_accessible_when_user_completes_level_1: <div className="Title">Level 2: What to Expect</div>,
  level_3_overview_if_accessible_when_user_completes_level_2: <div className="Title">Level 3: What to Expect</div>,
  remind_to_adjust_settings_before_one_month_of_auto_renewal: <div className="Title">Your Subscription Will Auto-Renew in 30 Days</div>,
  remind_to_adjust_settings_before_three_days_of_auto_renewal: <div className="Title">Your Subscription Will Auto-Renew in 3 Days</div>,
};

export const welcome_when_institution_admin_signs_up = (
  <div className="Body">
    <p className="Text">
      Click&nbsp;
      <Link className="Link" to="/account/invite_users/new">here</Link>&nbsp;
      to Add Users now.
    </p>
    <p className="Text">
      Need assistance? Click&nbsp;
      <a className="Link" href="https://uploads-ssl.webflow.com/63cbcd54f604a371d478ce14/64b851751a0c8382ea37ab94_Updated%20-%20Canopy%20Admin%20Dashboard%20Guide.pdf" target="_blank">here</a>&nbsp;
      to access our "<strong>Canopy Admin Set Up Guide</strong>".
    </p>
  </div>
);

export const notify_institution_admin_when_few_license_seats_left = (
  <div className="Body">
    <p className="Text">Your Canopy institutional license has a few seats remaining. Please review this against your expected user count and contact your Canopy sales representative or <a href="mailto:info@canopyapps.com">info@canopyapps.com</a> if you'd like to purchase additional seats. Thank you!</p>
  </div>
);

export const notify_institution_admin_when_license_seats_are_full = (
  <div className="Body">
    <p className="Text">All current Canopy seats have now been allocated, no new users will be able to join in your existing contract term. Please consider adding more seats to your Canopy license if you foresee additional learners coming onboard.</p>
    <p className="Text">If you have any questions or would like to purchase more seats, please contact your Canopy sales representative or <a href="mailto:info@canopyapps.com">info@canopyapps.com</a>. Thank you!</p>
  </div>
);

export const welcome_when_user_signs_up = (
  <div className="Body">
    <p className="Text">Get started by creating your profile and setting goals.</p>
    <p className="Text">In addition to your pledged goals in your Canopy Profile, Canopy Streaks are designed to keep you motivated and accountable to your goals. They encourage consistency and celebrate your successes along the way (or remind you of when you're off track). Please check your email for more details—we've laid it all out there for you.</p>
    <Link className="Link" to="/account/learn">Click here to set your Canopy Streaks settings.</Link>
  </div>
);

export const send_confirmation_follow_up_after_user_purchased_product = (
  <div className="Body">
    <p>In addition to your pledged goals in your Canopy Profile, Canopy Streaks are designed to keep you motivated and accountable to your goals. They encourage consistency and celebrate your successes along the way (or remind you of when you're off track). Please check your email for more details—we've laid it all out there for you.</p>
  </div>
);

export const remind_to_complete_profile_if_not_after_user_purchases = (
  <div className="Body">
    <p className="Text">Your Canopy profile is more than just a collection of information; it's your personal roadmap to language proficiency and success. By sharing some basic details and setting your learning goals, you're taking a significant step toward ensuring that your Canopy experience aligns perfectly with your aspirations.</p>
    <Link className="Link" to="/account/profile">Click here to complete your profile now before you start learning.</Link>
  </div>
);

export const level_2_overview_if_accessible_when_user_completes_level_1 = (
  <div className="Body">
    <p className="Text">Level 2 builds upon your momentum from Level 1 and tackles topics, such as:</p>
    <p className="Text"><strong>Allergies and Pneumonia:</strong> Delve into vocabulary and communication related to respiratory ailments and allergies, ensuring effective patient care and safety.</p>
    <p className="Text"><strong>Pharmacy and Prescriptions:</strong> Learn essential terminology for discussing medications, prescriptions, and pharmacy interactions with confidence.</p>
    <p className="Text"><strong>Substance Abuse and Addiction:</strong> Gain insight into the language surrounding substance abuse and addiction, fostering empathy and understanding in challenging situations.</p>
    <p className="Text">Don't let your momentum slow.</p>
    <Link className="Link" to="/learn">Click here to jump in and start learning now.</Link>
  </div>
);

export const level_3_overview_if_accessible_when_user_completes_level_2 = (
  <div className="Body">
    <p className="Text">Level 3 is the culmination of your CanopyLearn adventure, and it's designed to equip you with the advanced language skills required in a healthcare setting. Here's a glimpse of the topics you'll explore in this level:</p>
    <p className="Text"><strong>Infant Care:</strong> Learn how to effectively communicate about infant health and care, an essential skill for healthcare professionals and caregivers.</p>
    <p className="Text"><strong>Trauma:</strong> Dive into the vocabulary and communication related to trauma, enhancing your ability to provide support and care in emergency situations.</p>
    <p className="Text"><strong>Cancers:</strong> Gain a deep understanding of the language used in discussing different types of cancers and their treatments.</p>
    <p className="Text"><strong>Depression and Suicide:</strong> Explore the sensitive and critical topics of depression and suicide, empowering you with the language to approach these subjects with empathy and care.</p>
    <p className="Text">Consider this your "capstone" level. Finish strong so you don't lose your progress.</p>
    <Link className="Link" to="/learn">Click here to jump in and start learning now.</Link>
  </div>
);

export const congratulate_when_completes_learn_level_1 = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/certificate">Click here to get it now.</Link>
  </div>
);

export const congratulate_when_completes_learn_level_2 = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/certificate">Click here to get it now.</Link>
  </div>
);

export const congratulate_when_completes_learn_level_3 = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/certificate">Click here to get it now.</Link>
  </div>
);

export const congratulate_when_completes_compliance_level = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/certificate">Click here to get it now.</Link>
  </div>
);

export const congratulate_when_completes_credential_exam = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/dashboard/credential">Click here to get it now.</Link>
  </div>
);

export const remind_to_adjust_settings_before_one_month_of_auto_renewal = (
  <div className="Body">
    <p className="Text">And good news: You're eligible for our special, returning learners' discounted rate!</p>
    <p className="Text">If you'd like to continue your learning journey, no action is needed. Otherwise, please click "Manage Subscription" in the top bar of "Home", then scroll down and find the option to "Turn Auto Renew Off".</p>
  </div>
)

export const remind_to_adjust_settings_before_three_days_of_auto_renewal = (
  <div className="Body">
    <p className="Text">And you're still eligible for our special, returning learners' discounted rate!</p>
    <p className="Text">If you'd like to continue your learning journey, no action is needed. Otherwise, please click "Manage Subscription" in the top bar of "Home", then scroll down and find the option to "Turn Auto Renew Off".</p>
  </div>
)
