import './CanopyLearnPage.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import { ProtectedRoute, TrialLockedRoute } from '../../../Util/RouteUtil';
import Footer from '../../Basic/Footer/Footer';
import LearnBreadcrumbs from '../../Composed/LearnBreadcrumbs/LearnBreadcrumbsContainer';
import CoursesDashboard from '../../Dashboards/CoursesDashboard/CoursesDashboardContainer';
import LessonsDashboard from '../../Dashboards/LessonsDashboard/LessonsDashboardContainer';
import LearnNavBar from '../../NavBars/LearnNavBar/LearnNavBarContainer';
import LearningPage from '../LearningPage/LearningPageContainer';
import ActivityPage from '../ActivityPage/ActivityPageContainer';
import QuizPage from '../QuizPage/QuizPageContainer';
import QuizPageV2 from '../QuizPageV2/QuizPageV2Container';
import CourseCompletion from '../CourseCompletion/CourseCompletion';
import Bookmarks from '../Bookmarks/Bookmarks';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import ProfileCompletion from '../../Composed/ProfileCompletion/ProfileCompletion';

import { fetchCourseProgresses } from '../../../Actions/CourseProgressActions';
import { isB2BBusiness } from '../../../Util/Helper/UserWorkplaceUtil';

const propTypes = {
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.number,
        user_type: PropTypes.string,
        trial: PropTypes.bool,
    }).isRequired,
    clearCourses: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    fetchCourseProgresses: PropTypes.func.isRequired,
    clearCourseProgresses: PropTypes.func.isRequired,
    fetchBookmarkIds: PropTypes.func.isRequired,
    clearBookmarks: PropTypes.func.isRequired,
    syncCurrentUser: PropTypes.func.isRequired,
    fetchQuizResults: PropTypes.func.isRequired,
    clearQuizResults: PropTypes.func.isRequired
};

class CanopyLearnPage extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        const {
            clearCourses,
            fetchCourses,
            currentUser,
            fetchCourseProgresses,
            clearCourseProgresses,
            fetchBookmarkIds,
            clearBookmarks,
            syncCurrentUser,
            fetchQuizResults,
            clearQuizResults,
            fetchSubscriptions,
            clearSubscriptions,
            fetchLessons,
            receiveCourseId,
            clearCourseId,
        } = this.props;

        const promises = [
            clearCourses(),
            fetchCourses(),
            syncCurrentUser(),
            clearSubscriptions(),
            fetchSubscriptions(),
        ];

        if (currentUser.user_type !== 'TE.Admin') {
            promises.push(clearCourseProgresses());
            promises.push(fetchCourseProgresses());
            promises.push(clearBookmarks());
            promises.push(fetchBookmarkIds());
            promises.push(clearQuizResults());
            promises.push(fetchQuizResults());
        }

        let capturedCourseId = window.location.hash.match(/#\/learn\/courses\/(\d+)\/lessons/i)?.[1]
        if (capturedCourseId) {
            promises.push(fetchLessons(capturedCourseId));
            promises.push(receiveCourseId(+capturedCourseId));
        } else {
            promises.push(clearCourseId());
        }

        Promise.all(promises).then(() => this.setState({ loading: false }));
    }

    componentDidUpdate() {
        fetchCourseProgresses();
    }

    render() {
        const { courses, currentUser, currentWorkplace, subscriptions, currentCourseId } = this.props;
        const { loading } = this.state;

        if (loading)
            return <MirrorLoader message="Loading, Hang on!" />

        if (Object.keys(courses).length === 0) {
            return (
                <div className="CanopyLearnPage Warning-Message">
                    <div className="Message-Container">
                        <h2>
                            {
                                isB2BBusiness(currentUser, currentWorkplace)
                                ? <>No courses found. Please contact your institution Admin.</>
                                : <>
                                    Please&nbsp;
                                    <Link className="tdu" to="/account/learn/course_packages">sign up</Link>
                                    &nbsp;for a course.
                                </>
                            }
                        </h2>

                        <h4 className="mt-10">
                            <Link className="tdu" to="/account/profile">My Profile</Link>
                        </h4>
                    </div>
                </div>
            );
        }

        if (currentWorkplace.status === 'archive') {
            return (
                <div className="CanopyLearnPage Warning-Message">
                    <div className="Message-Container">
                        <h2>Access Denied!</h2>
                        <h4 className="mt-10 mb-12">Your institutional license is no longer active. Please contact your program administrator or <a href="mailto:info@canopyapps.com" className="tdu">info@canopyapps.com</a> for more information.</h4>
                        <Link className="tdu" to="/account/learn">Back to Home</Link>
                    </div>
                </div>
            )
        }

        if (currentCourseId && !Object.keys(courses).includes(currentCourseId.toString())) {
            return (
                <div className="CanopyLearnPage Warning-Message">
                    <div className="Message-Container">
                        <h2>Access Denied!</h2>
                        <h4 className="mt-10 mb-12">This course is not available</h4>
                        <Link className="tdu" to="/account/learn">Back to Home</Link>
                    </div>
                </div>
            )
        }

        if (isB2BBusiness(currentUser, currentWorkplace) && currentWorkplace?.license?.expiration_date && Date.parse(currentWorkplace?.license?.expiration_date) < Date.now()) {
            return (
                <div className="CanopyLearnPage Warning-Message">
                    <div className="Message-Container">
                        <h2>Subscription Expired!</h2>
                        <h4 className="mt-10 mb-12">The Canopy license you are attempting to access has expired. Please contact your program administrator or <a href="mailto:info@canopyapps.com" className="tdu">info@canopyapps.com</a> for more information.</h4>
                        <Link className="tdu" to="/account/learn">Back to Home</Link>
                    </div>
                </div>
            )
        }

        if (currentCourseId && subscriptions?.[currentCourseId]?.expires_at && Date.parse(subscriptions[currentCourseId].expires_at) < Date.now()) {
            return (
                <div className="CanopyLearnPage Warning-Message">
                    <div className="Message-Container">
                        <h2>Subscription Expired!</h2>
                        <h4 className="mt-10 mb-12">Your subscription of this course has expired</h4>
                        <Link className="tdu" to="/account/learn">Back to Home</Link>
                    </div>
                </div>
            )
        }

        return (
            <div className={`CanopyLearnPage`}>
                <div>
                    <Route path="/" component={LearnNavBar} />

                    <LearnBreadcrumbs />

                    {
                        currentUser.trial
                            && (
                                <div className="CanopyLearnPage-Banner__container">
                                    <p className="CanopyLearnPage-Banner">Trial Mode</p>
                                </div>
                            )

                    }

                    <ProfileCompletion />

                    <Switch>
                        <TrialLockedRoute
                            path="/learn/courses/:courseId/lessons/:lessonId/activity"
                            component={ActivityPage}
                        />

                        <TrialLockedRoute
                            path="/learn/courses/:courseId/lessons/:lessonId/quiz"
                            component={this.props.courses[this.props.currentCourseId]?.course_type?.name === 'Compliance' ? QuizPageV2 : QuizPage}
                        />

                        <TrialLockedRoute
                            path="/learn/courses/:courseId/lessons/:lessonId/topics/:topicId"
                            component={LearningPage}
                        />

                        <ProtectedRoute
                            path="/learn/courses/:courseId/lessons"
                            component={LessonsDashboard}
                        />

                        <ProtectedRoute
                            path="/learn/courses/:courseId/completion"
                            component={CourseCompletion}
                        />

                        <ProtectedRoute
                            path="/learn/bookmarks"
                            component={Bookmarks}
                        />

                        <ProtectedRoute
                            path="/learn"
                            component={CoursesDashboard}
                        />
                    </Switch>
                </div>

                <Footer />
            </div>
        );
    }
}

CanopyLearnPage.propTypes = propTypes;

export default (CanopyLearnPage);
