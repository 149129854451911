import { getProfile } from '../../Util/ProfileApiUtil';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const getProfileSuccess = payload => ({
    type: GET_PROFILE_SUCCESS,
    payload,
});

export const getProfileFailure = payload => ({
    type: GET_PROFILE_FAILURE,
    payload,
});

export const getProfileRequest = data => async dispatch => {
    try {
        const response = await getProfile(data);
        dispatch(getProfileSuccess(response));
    } catch (error) {
        dispatch(getProfileFailure(error));
    }
};
