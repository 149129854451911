export const timeIntervalsList = {
  '': 'Please select',
  'past_week': 'Past Week',
  'past_month': 'Past Month',
  'past_year': 'Past Year'
}

export const shortMonthsList = {
  '0': 'Jan',
  '1': 'Feb',
  '2': 'Mar',
  '3': 'Apr',
  '4': 'May',
  '5': 'Jun',
  '6': 'Jul',
  '7': 'Aug',
  '8': 'Sep',
  '9': 'Oct',
  '10': 'Nov',
  '11': 'Dec',
}

export const fullMonthsList = {
  'Jan': 'January',
  'Feb': 'February',
  'Mar': 'March',
  'Apr': 'April',
  'May': 'May',
  'Jun': 'June',
  'Jul': 'July',
  'Aug': 'August',
  'Sep': 'September',
  'Oct': 'October',
  'Nov': 'November',
  'Dec': 'December',
}

export const daysCategoriesList = {
  'include_weekend': { text: 'Weekdays and Weekends', dayNumbers: [0, 1, 2, 3, 4, 5, 6] },
  'exclude_weekend': { text: 'Weekdays Only',         dayNumbers: [1, 2, 3, 4, 5] },
  'custom_days':     { text: 'Choose Days',           dayNumbers: [] },
}

export const weekDaysList = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
}

export const timeZonesList = {
  'Pacific/Midway': 'Midway Island, Samoa (GMT-11:00)',
  'Pacific/Honolulu': 'Hawaii (GMT-10:00)',
  'America/Juneau': 'Alaska (GMT-8:00)',
  'America/Dawson': 'Dawson, Yukon (GMT-7:00)',
  'America/Phoenix': 'Arizona (GMT-7:00)',
  'America/Tijuana': 'Tijuana (GMT-7:00)',
  'America/Los_Angeles': 'Pacific Time (GMT-7:00)',
  'America/Boise': 'Mountain Time (GMT-6:00)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan (GMT-6:00)',
  'America/Regina': 'Saskatchewan (GMT-6:00)',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey (GMT-6:00)',
  'America/Belize': 'Central America (GMT-6:00)',
  'America/Chicago': 'Central Time (GMT-5:00)',
  'America/Bogota': 'Bogota, Lima, Quito (GMT-5:00)',
  'America/Detroit': 'Eastern Time (GMT-4:00)',
  'America/Caracas': 'Caracas, La Paz (GMT-4:00)',
  'America/Santiago': 'Santiago (GMT-4:00)',
  'America/Sao_Paulo': 'Brasilia (GMT-3:00)',
  'America/Montevideo': 'Montevideo (GMT-3:00)',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown (GMT-3:00)',
  'America/St_Johns': 'Newfoundland and Labrador (GMT-2:30)',
  'America/Godthab': 'Greenland (GMT-2:00)',
  'Atlantic/Cape_Verde': 'Cape Verde Islands (GMT-1:00)',
  'Atlantic/Azores': 'Azores (GMT+0:00)',
  'Etc/GMT': 'UTC (GMT+0:00)',
  'Africa/Casablanca': 'Casablanca, Monrovia (GMT+0:00)',
  'Europe/London': 'Edinburgh, London (GMT+1:00)',
  'Europe/Dublin': 'Dublin (GMT+1:00)',
  'Europe/Lisbon': 'Lisbon (GMT+1:00)',
  'Atlantic/Canary': 'Canary Islands (GMT+1:00)',
  'Africa/Algiers': 'West Central Africa (GMT+1:00)',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (GMT+2:00)',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb (GMT+2:00)',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris (GMT+2:00)',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (GMT+2:00)',
  'Africa/Cairo': 'Cairo (GMT+2:00)',
  'Africa/Harare': 'Harare, Pretoria (GMT+2:00)',
  'Europe/Bucharest': 'Bucharest (GMT+3:00)',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (GMT+3:00)',
  'Europe/Athens': 'Athens (GMT+3:00)',
  'Asia/Jerusalem': 'Jerusalem (GMT+3:00)',
  'Europe/Moscow': 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd (GMT+3:00)',
  'Asia/Kuwait': 'Kuwait, Riyadh (GMT+3:00)',
  'Africa/Nairobi': 'Nairobi (GMT+3:00)',
  'Asia/Baghdad': 'Baghdad (GMT+3:00)',
  'Asia/Tehran': 'Tehran (GMT+3:30)',
  'Asia/Dubai': 'Abu Dhabi, Muscat (GMT+4:00)',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan (GMT+4:00)',
  'Asia/Kabul': 'Kabul (GMT+4:30)',
  'Asia/Yekaterinburg': 'Ekaterinburg (GMT+5:00)',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent (GMT+5:00)',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi (GMT+5:30)',
  'Asia/Colombo': 'Sri Jayawardenepura (GMT+5:30)',
  'Asia/Kathmandu': 'Kathmandu (GMT+5:45)',
  'Asia/Dhaka': 'Astana, Dhaka (GMT+6:00)',
  'Asia/Almaty': 'Almaty, Novosibirsk (GMT+6:00)',
  'Asia/Rangoon': 'Yangon Rangoon (GMT+6:30)',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta (GMT+7:00)',
  'Asia/Krasnoyarsk': 'Krasnoyarsk (GMT+7:00)',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi (GMT+8:00)',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore (GMT+8:00)',
  'Asia/Taipei': 'Taipei (GMT+8:00)',
  'Australia/Perth': 'Perth (GMT+8:00)',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar (GMT+8:00)',
  'Asia/Seoul': 'Seoul (GMT+9:00)',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo (GMT+9:00)',
  'Asia/Yakutsk': 'Yakutsk (GMT+9:00)',
  'Australia/Darwin': 'Darwin (GMT+9:30)',
  'Australia/Adelaide': 'Adelaide (GMT+9:30)',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney (GMT+10:00)',
  'Australia/Brisbane': 'Brisbane (GMT+10:00)',
  'Australia/Hobart': 'Hobart (GMT+10:00)',
  'Asia/Vladivostok': 'Vladivostok (GMT+10:00)',
  'Pacific/Guam': 'Guam, Port Moresby (GMT+10:00)',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia (GMT+11:00)',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands (GMT+12:00)',
  'Pacific/Fiji': 'Fiji Islands (GMT+12:00)',
  'Pacific/Auckland': 'Auckland, Wellington (GMT+12:00)',
  'Pacific/Tongatapu': 'Nuku\'alofa (GMT+13:00)',
}
