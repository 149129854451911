import {
  CLEAR_LESSON_ERRORS,
  RECEIVE_LESSON,
  RECEIVE_LESSONS,
  RECEIVE_LESSON_ERRORS,
} from '../../Actions/LessonActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_LESSON_ERRORS:
      return action.errors;
    case RECEIVE_LESSONS:
      return {};
    case RECEIVE_LESSON:
      return {};
    case CLEAR_LESSON_ERRORS:
      return {};
    default:
      return state;
  }
};