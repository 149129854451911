import { merge } from 'lodash';
import {
    RECEIVE_COURSES,
    RECEIVE_COURSE,
    CLEAR_COURSES,
} from '../../Actions/CourseActions';
import {
    RECEIVE_LESSON,
    RECEIVE_LESSON_DELETE_SUCCESS,
    RECEIVE_LESSON_POSITIONS,
    RECEIVE_CLONE_LESSON
} from '../../Actions/LessonActions';
import {
    RECEIVE_COURSE_PACKAGES,
    RECEIVE_COURSE_PACKAGE,
} from '../../Actions/CoursePackageActions';

const CoursesReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_COURSES:
            return Object.assign({}, action.payload.courses);
        case RECEIVE_COURSE:
            let newState = Object.assign({}, state);
            newState = merge(newState, action.payload.courses);
            return newState;
        case RECEIVE_LESSON:
            return Object.assign({}, action.payload.courses);
        case RECEIVE_CLONE_LESSON:
            let cloneState = Object.assign({}, state);
            cloneState[action.payload.course_id].lessons.push(action.payload.id);
            return cloneState;
        case RECEIVE_LESSON_DELETE_SUCCESS:
            return Object.assign({}, action.payload.courses);
        case RECEIVE_LESSON_POSITIONS:
            return Object.assign({}, action.payload.courses);
        case RECEIVE_COURSE_PACKAGES:
            return Object.assign({}, state, action.payload.courses);
        case RECEIVE_COURSE_PACKAGE:
            return Object.assign({}, state, action.payload.courses);
        case CLEAR_COURSES:
            return {};
        default:
            return state;
    }
};

export default CoursesReducer;
