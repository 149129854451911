import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import LatestAssessment from './LatestAssessment' 
import MyProgress from './MyProgress';

import { clearCredentialStats, fetchLatestAssessment, fetchAllAssessments } from '../../../../Actions/Stats/Users/CredentialActions';
import { CanopyCredentialLink } from '../../../../Util/Helper/CredentialUtil';

import './Credential.scss';

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const mapStateToProps = (state) => ({
  latestAssessment: state.stats.users.credentials?.latestAssessment || {},
  allAssessments:   state.stats.users.credentials?.allAssessments   || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearCredentialStats:  (key) => dispatch(clearCredentialStats(key)),
  fetchLatestAssessment: () => dispatch(fetchLatestAssessment()),
  fetchAllAssessments:   () => dispatch(fetchAllAssessments())
});

const Credential = ({ latestAssessment, allAssessments, clearCredentialStats, fetchLatestAssessment, fetchAllAssessments }) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [latestAssessmentLoader, setLatestAssessmentLoader] = useState(true);
  const [allAssessmentsLoader, setAllAssessmentsLoader]     = useState(true);
  const hasLicense = (allAssessments?.canopyLicenses || 0) > 0;

  useLayoutEffect(() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      setContentWidth((window.outerWidth > 768) ? (pageWidth - 500) : (pageWidth - 150));
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    clearCredentialStats('latestAssessment');
    fetchLatestAssessment().then(res => setLatestAssessmentLoader(false));
  }, [fetchLatestAssessment]);

  useEffect(() => {
    clearCredentialStats('allAssessments');
    fetchAllAssessments().then(res => setAllAssessmentsLoader(false));
  }, [fetchAllAssessments]);

  const renderTakeCredentialBtn = () => {
    if (allAssessmentsLoader)
      return null;

    return (
      <a href={CanopyCredentialLink(REACT_APP_ONE_DOMAIN, hasLicense, allAssessments?.credentialTests)} target="_blank" className="Link">
        <ButtonBox
          className="Static-Primary-Btn Credential-Btn"
          text="Go to canopy credential"
        />
      </a>
    )
  }

  return (
    <div className="Credential-Dashboard">
      <div className="ButtonHolder">
        {renderTakeCredentialBtn()}
      </div>

      <LatestAssessment
        latestAssessmentLoader={latestAssessmentLoader}
        latestAssessment={latestAssessment}
        hasLicense={hasLicense}
        contentWidth={contentWidth}
      />

      <MyProgress
        allAssessmentsLoader={allAssessmentsLoader}
        allAssessments={allAssessments.credentialTests}
        hasLicense={hasLicense}
        contentWidth={contentWidth}
      />
    </div>
  )
}

Credential.propTypes = {
  latestAssessment: PropTypes.object.isRequired,
  allAssessments: PropTypes.object.isRequired,
  clearCredentialStats: PropTypes.func.isRequired,
  fetchLatestAssessment: PropTypes.func.isRequired,
  fetchAllAssessments: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Credential);
