import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

const Launch = ({ profileCompletion, updateProfileCompletion, tutorialModules }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    if (!currentModule) {
      const moduleToStart = Object.keys(tutorialModules).find((tutorialModule) => (
        !profileCompletion.tutorial[tutorialModule] || (profileCompletion.tutorial[tutorialModule]['status'] === 'in-progress')
      ));

      if (moduleToStart) {
        const stepIndexToStart = Number.isInteger(profileCompletion.tutorial[moduleToStart]?.steps) ? (profileCompletion.tutorial[moduleToStart].steps + 1) : 0;

        setCurrentModule(moduleToStart);
        setCurrentStepIndex(stepIndexToStart);
      }
    }

    if (currentModule) {
      window.location.hash = tutorialModules[currentModule][currentStepIndex].locationHash;
      setIsOpen(true);
    }
  }, [currentModule]);

  const handleTourStart = (e) => {
    disableBodyScroll(e);
  }

  const handleTourFinish = (e) => {
    enableBodyScroll(e);
  }

  const handleStepNext = (e) => {
    e.currentTarget.blur();
    handleUpdateProgress('in-progress');
    prepareNavigatedStep(+1);
  }

  const handleStepPrevious = (e) => {
    e.currentTarget.blur();
    prepareNavigatedStep(-1);
  }

  const handleLastStepNext = (e) => {
    e.currentTarget.blur();
    handleUpdateProgress('completed');
    setIsOpen(false);
    setCurrentModule(null);
  }

  const prepareNavigatedStep = async (stepDelta = 0) => {
    const newStepIndex = currentStepIndex + stepDelta;

    window.location.hash = tutorialModules[currentModule][newStepIndex].locationHash;
    await new Promise(r => setTimeout(r, 300));

    setCurrentStepIndex(newStepIndex);
  }

  const handleUpdateProgress = (status) => {
    let currentTutorial = profileCompletion.tutorial;

    if ((currentTutorial[currentModule]?.steps || -1) >= currentStepIndex) {
      return null;
    }

    currentTutorial[currentModule] = { status, steps: currentStepIndex, completed_at: (status === 'completed' ? (new Date()) : '') };
    updateProfileCompletion({ id: profileCompletion.id, tutorial: currentTutorial });
  }

  if (!isOpen) {
    return '';
  }

  return (
    <div className='Tour-Container'>
      <Tour
        className="Tour"
        isOpen={isOpen}
        steps={tutorialModules[currentModule]}
        startAt={currentStepIndex}
        onAfterOpen={(e) => handleTourStart(e)}
        onRequestClose={(e) => handleTourFinish(e)}
        nextButton={<ButtonBox className="Next-Button Primary-Btn" text={<span className="Primary-Btn-Text">Next</span>} onClick={(e) => handleStepNext(e)} />}
        prevButton={<ButtonBox className="Previous-Button Primary-Btn" text={<span className="Primary-Btn-Text">Previous</span>} onClick={(e) => handleStepPrevious(e)}/> }
        lastStepNextButton={<ButtonBox className="Finish-Button Secondary-Btn" text={<span className="Secondary-Btn-Text">Finish!</span>} onClick={(e) => handleLastStepNext(e)}/>}
        showCloseButton={false}
        closeWithMask={false}
        disableKeyboardNavigation={['esc', 'right', 'left']}
        disableDotsNavigation={true}
      />
    </div>
  )
};

Launch.propTypes = {
  profileCompletion: PropTypes.object.isRequired,
  updateProfileCompletion: PropTypes.func.isRequired,
  tutorialModules: PropTypes.object.isRequired,
}

export default Launch;
