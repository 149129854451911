import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { enablePledge } from '../../../../Actions/LingoActions';
import { clearPledges, fetchPledges, scaleUpPledge, scaleDownPledge } from '../../../../Actions/PledgeActions';
import { clearCoursePledges, fetchCoursePledges, scaleUpCoursePledge, scaleDownCoursePledge } from '../../../../Actions/CoursePledgeActions';
import { languagesList } from '../../../../Constants/LanguageConstants';
import { courseLanguageList } from '../../../../Constants/CourseConstants';

import Loader from '../../../Composed/Loader/Loader';
import Pledge from './Pledge';
import AddLanguage from './AddLanguage';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './Pledge.scss';

const mapStateToProps = (state) => ({
  allLingoPledges: (state.entities.pledges || {}),
  lingoPledges:    Object.values(state.entities.pledges || {}).filter(lp => lp.pledge_enabled),
  coursePledges:   Object.values(state.entities.coursePledges || {}).filter(cp => cp.id),
});

const mapDispatchToProps = (dispatch) => ({
  enablePledge: (lingo) => (dispatch(enablePledge(lingo))),
  clearPledges: () => (dispatch(clearPledges())),
  fetchPledges: () => (dispatch(fetchPledges())),
  scaleUpPledge: (pledge) => (dispatch(scaleUpPledge(pledge))),
  scaleDownPledge: (pledge) => (dispatch(scaleDownPledge(pledge))),
  clearCoursePledges: () => (dispatch(clearCoursePledges())),
  fetchCoursePledges: () => (dispatch(fetchCoursePledges())),
  scaleUpCoursePledge: (course_pledge) => (dispatch(scaleUpCoursePledge(course_pledge))),
  scaleDownCoursePledge: (course_pledge) => (dispatch(scaleDownCoursePledge(course_pledge))),
});

const Pledges = ({
  currentUser,
  allLingoPledges, lingoPledges, coursePledges,
  enablePledge, clearPledges, fetchPledges, scaleUpPledge, scaleDownPledge,
  clearCoursePledges, fetchCoursePledges, scaleUpCoursePledge, scaleDownCoursePledge,
  displayProfileWingsModal
}) => {
  const [loading, setLoading] = useState(true);
  const [showEnablePledgeForm, setShowEnablePledgeForm] = useState(false);

  useEffect(() => {
    Promise.all([
      clearPledges(),
      fetchPledges(),
      clearCoursePledges(),
      fetchCoursePledges(),
    ]).then(() => setLoading(false));
  }, [clearPledges, fetchPledges, clearCoursePledges, fetchCoursePledges]);

  const emptyPledges = () => {
    return (
      <div className="AddLanguage">
        <div className="Tool-Tip" data-tip data-for="Add-Language-ToolTip">
          <ButtonBox
            className="Enable-Pledge-Btn"
            text={<><ImageBox className="AddIcon" src="add.svg" />Select the Language you wish to set goals for</>}
            onClick={() => setShowEnablePledgeForm(true)}
            disabled= {Object.keys(allLingoPledges).length === 0}
          />
          {
            Object.keys(allLingoPledges).length === 0 && (
              <ReactTooltip type="dark" effect="solid" id="Add-Language-ToolTip">
                <p>Please Complete</p>
                <p>'LANGUAGE BACKGROUND'</p>
              </ReactTooltip>
            )
          }
        </div>
      </div>
    )
  };

  const myPledges = () => {
    const lingoPledgeProps = {
      scaleUpPledge: scaleUpPledge,
      scaleDownPledge: scaleDownPledge,
      displayProfileWingsModal: displayProfileWingsModal,
    }

    const coursePledgeProps = {
      scaleUpPledge: scaleUpCoursePledge,
      scaleDownPledge: scaleDownCoursePledge,
      displayProfileWingsModal: null,
    }

    return (
      <div className="My-Pledges">
        <div className="My-Courses-Pledges">
          {
            coursePledges.map((coursePledge, idx) => (
              <div key={`course-pledges-${coursePledge.id}-${idx}`} className="My-Course-Pledges">
                <div className="Course-Title">
                  {coursePledge.name}
                </div>
                <div className="Pledge-Cards">
                  {
                    coursePledge.completed_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={courseLanguageList[coursePledge.language]?.toLowerCase()} currentIdx={idx} {...coursePledgeProps} />
                    ))
                  }
                  {
                    coursePledge.in_progress_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={courseLanguageList[coursePledge.language]?.toLowerCase()} currentIdx={idx} {...coursePledgeProps} />
                    ))
                  }
                  {
                    coursePledge.outstanding_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={courseLanguageList[coursePledge.language]?.toLowerCase()} currentIdx={idx} {...coursePledgeProps} />
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className="My-Lingos-Pledges">
          {
            lingoPledges.map((lingoPledge, idx) => (
              <div key={`lingo-pledges-${lingoPledge.id}`} className="My-Lingo-Pledges">
                <div className="Lingo-Title">
                  {languagesList[lingoPledge.language]}
                </div>
                <div className="Pledge-Cards">
                  {
                    lingoPledge.completed_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={lingoPledge.language} currentIdx={idx} {...lingoPledgeProps} />
                    ))
                  }
                  {
                    lingoPledge.in_progress_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={lingoPledge.language} currentIdx={idx} {...lingoPledgeProps} />
                    ))
                  }
                  {
                    lingoPledge.outstanding_pledges.map((pledge, idx) => (
                      <Pledge key={`pledge-${pledge.id}`} pledge={pledge} language={lingoPledge.language} currentIdx={idx} {...lingoPledgeProps} />
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className="My-Add-Language">
          <ButtonBox
            className="Btn"
            text={<><ImageBox className="Add-Icon" src="add.svg" /> Add Language</>}
            onClick={() => setShowEnablePledgeForm(true)}
          />
        </div>
      </div>
    )
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="My-Progress">
      {
        showEnablePledgeForm &&
        <AddLanguage
          allLingoPledges={allLingoPledges}
          enablePledge={enablePledge}
          close={() => setShowEnablePledgeForm(false)}
        />
      }
      <div className="ProgressContainer">
        <div className="TitleContainer">
          <div className="ProgressTitle">
            MY ACTION PLAN
          </div>
        </div>
        <div className="ProgressContent">
          <div className="ProgressContent-Holder">
            <div className="Content-Text">
              <p>
                We want to support you in your journey to becoming a linguistically and culturally skilled healthcare provider.
                By setting your goals here, you are taking an important step forward in that journey,
                and giving us the opportunity to know your priorities and support you along the way.
                There is no penalty or risk in not being able to fully meet your stated goals.
                We know that even the best laid plans don't always come to full fruition, or within the timeframe that is anticipated.
                But there's a lot to celebrate in just setting a goal and giving it your best.
              </p>
            </div>
          </div>

          { (coursePledges.length === 0 && lingoPledges.length === 0) ? emptyPledges() : myPledges() }
        </div>
      </div>
    </div>
  )
}

Pledges.propTypes = {
  currentUser: PropTypes.object.isRequired,
  allLingoPledges: PropTypes.object.isRequired,
  lingoPledges: PropTypes.object.isRequired,
  enablePledge: PropTypes.func.isRequired,
  clearPledges: PropTypes.func.isRequired,
  fetchPledges: PropTypes.func.isRequired,
  scaleUpPledge: PropTypes.func.isRequired,
  scaleDownPledge: PropTypes.func.isRequired,
  clearCoursePledges: PropTypes.func.isRequired,
  fetchCoursePledges: PropTypes.func.isRequired,
  scaleUpCoursePledge: PropTypes.func.isRequired,
  scaleDownCoursePledge: PropTypes.func.isRequired,
  displayProfileWingsModal: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pledges);
