import React from 'react';
import PropTypes from 'prop-types';

import './DropDownBoxMini.css';
import QuestionBox from '../QuestionBox/QuestionBox';
import TextBox from '../../Basic/TextBox/TextBox';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';
import { COLORS } from '../../../Style/Style';

/**
* @class - DropDownBoxMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const DropDownBoxMini = ({
    question,
    createAnswer,
    editAnswer,
    deleteQuestion,
    editQuestion,
    uploadAudio,
    creation,
}) => {

    const questionParts = question.text.split('__drop__');

    const options = {};
    question.options.forEach(
        option => {
            if (options[option.index]) {
                options[option.index].push(option);
            } else {
                options[option.index] = [option];
            }
        },
    );

    const answers = {};
    question.answers.forEach(
        answer => {
            answers[answer.index] = answer;
        },
    );

    const toggleChangeHandler = idx => e => {
        if (!creation) {
            return;
        }
        if (createAnswer) {
            if (answers[idx]) {
                editAnswer({
                    id: question.answers[idx].id,
                    question_id: question.id,
                    text: e.currentTarget.value,
                });
            } else {
                createAnswer({
                    question_id: question.id,
                    text: e.currentTarget.value,
                    index: idx,
                });
            }
        }
    };

    const handleQuestionRender = () => {
        const questionList = [];
        questionParts.forEach((part, idx) => {
            part.split(' ').forEach(
                (word, idx2) => {
                    questionList.push(
                        <div
                            className="FillInTheBlankBoxMini-QuestionParts"
                            key={(idx + 1) * 1000 + idx2}
                        >
                            <TextBox
                                text={word}
                                style={{
                                    color: COLORS.Blue,
                                }}
                            />
                        </div>
                    );
                },
            );
            if (Object.keys(options)[idx]) {
                questionList.push(
                    <div
                        className="DropDownBoxMini-Select"
                        key={idx}
                    >
                        <select
                            onChange={toggleChangeHandler(idx)}
                            value={
                                answers[idx]
                                    ? answers[idx].text
                                    : 'none'
                            }
                            data-id={idx}
                        >
                            <option
                                disabled
                                value="none">
                                    select an option
                            </option>
                            {
                                options[idx].map(
                                    (option, index) => (
                                        <option key={index} value={option.text}>
                                            {option.text}
                                        </option>
                                    ),
                                )
                            }
                        </select>
                    </div>
                );
            }
        });
        return questionList;
    };

    return (
        <div className='DropDownBoxMini'>
            <div className="QuestionMini-QuestionBox">
                <div
                    className="QuestionMini-QuestionBox-Position"
                    style={{
                        backgroundColor: question.answers.length >= question.number_of_answers ? COLORS.green : COLORS.redPink,
                    }}
                >
                    <TextBox
                        text={question.position}
                        style={{
                            color: COLORS.Blue,
                        }}
                    />
                </div>

                <div className="QuestionMini-QuestionBox-Question">
                    <QuestionBox question={question} creation={creation}>
                        {handleQuestionRender()}

                        {
                            question.hint
                            && (
                                <TextBox
                                    text={`(${question.hint} )`}
                                    tag="h5"
                                    style={{
                                        color: COLORS.lightBlue1,
                                    }}
                                />
                            )
                        }
                    </QuestionBox>
                </div>

                {
                    creation
                        && (
                            <FunctionBox
                                submitMedia={uploadAudio}
                                model="question"
                                entity={question}
                                editEntity={editQuestion}
                                deleteEntity={deleteQuestion}
                            />
                        )
                }
            </div>
        </div>
    );
};

DropDownBoxMini.propTypes = {
    question: PropTypes.shape({
        text: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        answers: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    createAnswer: PropTypes.func.isRequired,
    editAnswer: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    editQuestion: PropTypes.func.isRequired,
    uploadAudio: PropTypes.func.isRequired,
    creation: PropTypes.bool,
};

export default DropDownBoxMini;
