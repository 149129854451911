import axios from './AxiosUtil';
import { normalize } from 'normalizr';

import * as Schema from '../Schema/CourseSchema';

export const fetchCourses = () => {
  return axios.get("/api/courses").then(
    res => normalize(res.data, Schema.courses).entities
  );
};

export const fetchCoursesTree = () => {
  return axios.get("/api/courses/courses_tree").then(
    res => res.data
  );
};


export const createCourse = (course) => {
  return axios.post("/api/courses", { course }).then(
    res => normalize(res.data, Schema.course).entities
  );
};

export const editCourse = (course) => {
  return axios.patch(`/api/courses/${course.id}`, { course }).then(
    res => normalize(res.data, Schema.course).entities
  );
};
