import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { isStudent, isProfileCompleted } from '../../../Util/Helper/UserWorkplaceUtil';

import './ProfileCompletion.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  profileCompletion: state.userEntities.profileCompletion
});

const mapDispatchToProps = (dispatch) => ({});

const ProfileWingsCompletion = ({ currentUser, profileCompletion, hideProfileWingsModal }) => {
  if (!isStudent(currentUser) || isProfileCompleted(profileCompletion)) {
    return '';
  }

  const checkOrSpinner = (wing) => (
    <div className={`Icon ${profileCompletion?.[wing] ? 'Check' : 'Spinner'}`}>
      <i className={`fa ${profileCompletion?.[wing] ? 'fa-check' : 'fa-spinner'}`} />
    </div>
  );

  return (
    <div className="ProfileCompletion modal">
      <div className="modal-content">
        <div className="ImageBox">
          <ImageBox
            className="Image"
            src="user-with-question-mark.png"
          />
        </div>

        <div className="ContentBox">
          <div className="Content">
            <div className="Heading">
              It seems that your Canopy Profile is still incomplete.
            </div>
          </div>

          <div className="Wings">
            <div className="Wing">
              <div className="Name">General Information</div>
              <div className="Item">
                {checkOrSpinner('basic_info')}
                <div className="Text">
                  Complete your basic information by providing full name, date of birth, and zip code.
                </div>
              </div>
              <div className="Item">
                {checkOrSpinner('workplace_info')}
                <div className="Text">
                  Complete your workplace information by providing institution name, institution country, institution type, institution website, email, and job title of at least one workplace.
                </div>
              </div>
            </div>

            <div className="Wing">
              <div className="Name">Language Background</div>
              <div className="Item">
                {checkOrSpinner('lingo_info')}
                <div className="Text">
                  Add at least one language and fill out all fields.
                </div>
              </div>
            </div>

            <div className="Wing">
              <div className="Name">My Progress</div>
              <div className="Item">
                {checkOrSpinner('pledge_info')}
                <div className="Text">
                  Add at least one language to have pledges and activate at least one language pledge.
                </div>
              </div>
            </div>
          </div>

          <div className="ActionBox">
            <div className="Actions">
              <ButtonBox
                className="Primary-Btn"
                text={<span className="Primary-Btn-Text">Got it!</span>}
                onClick={() => hideProfileWingsModal()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileWingsCompletion.propTypes = {
  currentUser: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object.isRequired,
  hideProfileWingsModal: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWingsCompletion);
