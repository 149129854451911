import * as APIUtil from '../../Util/Onboarding/ChargeApiUtil';
import ErrorResponseUtil from '../../Util/ErrorResponseUtil';

export const RECEIVE_ONBOARDING_CHARGE = 'RECEIVE_ONBOARDING_CHARGE';
export const RECEIVE_ONBOARDING_CHARGE_ERRORS = 'RECEIVE_ONBOARDING_CHARGE_ERRORS';

export const receiveOnboardingCharge = (payload) => ({
  type: RECEIVE_ONBOARDING_CHARGE,
  payload,
});

export const receiveErrors = (errors) => ({
  type: RECEIVE_ONBOARDING_CHARGE_ERRORS,
  errors,
});

export const createCharge = (charge) => dispatch => (
  APIUtil.createCharge(charge).then(
    payload => dispatch(receiveOnboardingCharge(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
