import { connect } from 'react-redux';

import StreakConfig from './StreakConfig';

import { createStreakConfig } from '../../../../../Actions/Engage/StreakConfigActions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createStreakConfig: (streakConfig) => dispatch(createStreakConfig(streakConfig)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StreakConfig);
