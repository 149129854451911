import { connect } from 'react-redux';

import LessonsDashboard from './LessonsDashboard';
import { fetchLessons } from '../../../Actions/LessonActions';
import { clearTopics } from '../../../Actions/TopicActions';
import { receiveCourseId } from '../../../Actions/NavigationActions';
import { saveCourseProgress } from '../../../Actions/CourseProgressActions';

const mapStateToProps = (state = {}, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    currentCourseId: match.params.courseId,
    lessons: state.entities.lessons,
    courseProgresses: state.statistics.courseProgresses,
    currentUser: state.session.currentUser,
    quizResults: state.statistics.quizResults,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLessons: (courseId) => dispatch(fetchLessons(courseId)),
    clearTopics: () => dispatch(clearTopics()),
    receiveCourseId: index => dispatch(receiveCourseId(index)),
    saveCourseProgress: courseProgress => dispatch(saveCourseProgress(courseProgress)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonsDashboard);
