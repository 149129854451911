import axios from './AxiosUtil';

export const createWorkPlace = (workplace) => {
  return axios.post('/api/workplaces', { workplace }).then(
    res => res.data,
  );
};

export const updateWorkPlace = (workplace) => {
  return axios.patch(`/api/workplaces/${workplace.id}`, { workplace }).then(
    res => res.data,
  );
};

export const setCurrentWorkPlace = (workplaceId) => {
  return axios.patch(`/api/workplaces/${workplaceId}/current`, {}).then(
    res => res.data,
  )
};
