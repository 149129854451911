import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

import { clearCourseProgresses, fetchCourseProgresses } from '../../../Actions/CourseProgressActions';
import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

import InProgress from './InProgress/InProgress';
import Fail from './Fail/Fail';
import Pledges from './Pledges/Pledges';
import Pass from './Pass/Pass';

import './CourseCompletion.scss'

const mapStateToProps = (state, { match }) => ({
  currentUser: state.session.currentUser,
  course: state.entities.courses[match.params.courseId],
  courseProgress: state.statistics.courseProgresses[match.params.courseId],
});

const mapDispatchToProps = (dispatch) => ({
  clearCourseProgresses: () => dispatch(clearCourseProgresses()),
  fetchCourseProgresses: () => dispatch(fetchCourseProgresses())
});

const CourseCompletion = ({ currentUser, course, courseProgress, clearCourseProgresses, fetchCourseProgresses }) => {
  const [loading, setLoading] = useState(true);

  const isComplianceCourse = course.course_type?.name === 'Compliance';

  useEffect(() => {
    if (isTEAdmin(currentUser)) {
      setLoading(false);
    } else {
      clearCourseProgresses();
      fetchCourseProgresses().then(() => setLoading(false));
    }
  }, [currentUser, clearCourseProgresses, fetchCourseProgresses]);

  const renderPreviewModeNote = () => {
    return (
      <div className="Preview-Mode-Container">
        <div className="Preview-Mode-Text">
          Result not available, because you are in Preview Mode as a TE Admin.
        </div>
        <Link to={`/creation/courses`} className="Link">
          <ButtonBox
            className="Back-To-Course-Button Primary-Btn"
            text={<span className="Primary-Btn-Text">Back to Manage Courses</span>}
          />
        </Link>
      </div>
    )
  };

  const renderContent = () => {
    if (loading)
      return <MirrorLoader message="Preparing Course Result!" />

    if (isTEAdmin(currentUser))
      return renderPreviewModeNote();

    if (!courseProgress.completed_at)
      return <InProgress course={course} />

    if (courseProgress.result === 'fail')
      return <Fail course={course} courseProgress={courseProgress} isComplianceCourse={isComplianceCourse} />

    if (isComplianceCourse)
      return <Pledges course={course} courseProgress={courseProgress} isComplianceCourse={isComplianceCourse} />

    return <Pass course={course} courseProgress={courseProgress} isComplianceCourse={isComplianceCourse} />
  }

  return (
    <div className="Course-Completion-Container">
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCompletion);
