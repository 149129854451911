import { connect }
  from 'react-redux';

import LogInPage
  from './SignInPage';
import { login, clearSessionErrors }
  from '../../../Actions/SessionActions';

const mapStateToProps = (state) => {
  return {
    errors: state.errors.session
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: user => dispatch(login(user)),
    clearSessionErrors: () => dispatch(clearSessionErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInPage);
