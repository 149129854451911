import React, { useEffect, useState } from 'react';

import Loader from '../../../Composed/Loader/Loader';
import Pagination from '../../../Composed/Pagination/Pagination';
import NotificationPopupContainer from '../NotificationPopup/NotificationPopupContainer';

import './NotificationPage.scss';

const NotificationPage = ({ currentUser, userNotifications, clearUserNotifications, fetchUserNotifications }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(20);

  useEffect(() => {
    setLoading(true);
    clearUserNotifications();
    fetchUserNotifications(`page=${page}&per=${per}`).then(() => setLoading(false));
  }, [per, page, clearUserNotifications, fetchUserNotifications]);

  const renderNotifications = () => {
    if (loading) {
      return <Loader />
    }

    if (Object.keys(userNotifications?.data || {}).length === 0) {
      return <div className="Empty-Notifications">No Notifications!</div>
    }

    return (
      <div className="Notifications">
        {
          Object.values(userNotifications.data).reverse().map((userNotification, idx) => (
            <NotificationPopupContainer
              key={`user-notification-page-${idx}`}
              userNotification={userNotification}
            />
          ))
        }

        <Pagination
          totalRecords={userNotifications.total}
          page={page}
          setPage={(p) => setPage(p)}
          per={per}
        />
      </div>
    )
  }

  return (
    <div className="Notification-Page-Container">
      <div className="Page-Title">Notifications</div>
      {renderNotifications()}
    </div>
  )
};

export default NotificationPage;
