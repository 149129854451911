import axios from './AxiosUtil';

export const createFlashcard = (flashcard) => {
  return axios.post(`/api/flashcards`, { flashcard: flashcard }).then(
    res => res.data
  );
};

export const editFlashcard = (flashcard) => {
  return axios.patch(`/api/flashcards/${flashcard.id}`, { flashcard }).then(
    res => res.data
  );
};

export const deleteFlashcard = flashcardId => {
  return axios.delete(`/api/flashcards/${flashcardId}`).then(
    res => res.data
  );
}

export const uploadAudio = (formData) => {
  return axios({
    method: "PATCH",
    url: `/api/flashcards/${formData.get("flashcard[id]")}`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  );
};