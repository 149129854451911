import axios from '../Util/AxiosUtil';

export const RECEIVE_WORKPLACE = "RECEIVE_WORKPLACE";
export const RECEIVE_COURSE_ID = "RECEIVE_COURSE_ID";
export const RECEIVE_SECTION_INDEX = "RECEIVE_SECTION_INDEX";
export const RECEIVE_QUESTION_INDEX = "RECEIVE_QUESTION_INDEX";
export const CLEAR_WORKPLACE = "CLEAR_WORKPLACE";
export const CLEAR_COURSE_ID = "CLEAR_COURSE_ID";
export const CLEAR_SECTION_INDEX = "CLEAR_SECTION_INDEX";
export const CLEAR_QUESTION_INDEX = "CLEAR_QUESTION_INDEX";

export const receiveWorkplace = workplace => {
  axios.defaults.headers.common['Current_Workplace_Id'] = workplace.id;

  return {
    type: RECEIVE_WORKPLACE,
    workplace
  }
};

export const receiveCourseId = id => ({
  type: RECEIVE_COURSE_ID,
  id
});

export const receiveSectionIndex = index => ({
  type: RECEIVE_SECTION_INDEX,
  index
});

export const receiveQuestionIndex = index => ({
  type: RECEIVE_QUESTION_INDEX,
  index
});

export const clearWorkplace = () => {
  delete axios.defaults.headers.common['Current_Workplace_Id'];

  return {
    type: CLEAR_WORKPLACE
  }
};

export const clearCourseId = () => ({
  type: CLEAR_COURSE_ID
});

export const clearSectionIndex = () => ({
  type: CLEAR_SECTION_INDEX
});

export const clearQuestionIndex = () => ({
  type: CLEAR_QUESTION_INDEX
});
