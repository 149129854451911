import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import InputBox from '../../Basic/InputBox/InputBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { departmentsList, institutionTypeOptions } from '../../../Constants/DepartmentConstants';
import { countriesList } from '../../../Constants/CountriesConstants';
import { usaStatesList } from '../../../Constants/StatesConstants';

import './CreateEditWorkPlace.scss';

const CreateEditWorkPlace = ({ currentUser, workPlace, createWorkPlace, updateWorkPlace, cancelNewWorkPlace, workPlaceNumber, displayProfileWingsModal }) => {
  const [editMode, setEditMode] = useState(workPlace ? false : true);
  const [submitting, isSubmitting] = useState(false);
  const [workPlaceErrors, setWorkPlaceErrors] = useState({});
  const [workPlaceAttrs, setWorkPlaceAttrs] = useState(
    workPlace ? {
      institution_name: workPlace.institution_name,
      institution_country: workPlace.institution_country,
      institution_state: workPlace.institution_state,
      institution_type: workPlace.institution_type,
      institution_website: workPlace.institution_website,
      email: workPlace.email,
      job_title: workPlace.job_title,
      degree_designation: (workPlace.degree_designation?.length ? JSON.parse(JSON.stringify(workPlace.degree_designation)) : ['']),
      degree_in_progress: (workPlace.degree_in_progress?.length ? JSON.parse(JSON.stringify(workPlace.degree_in_progress)) : ['']),
      department_specialty: workPlace.department_specialty,
      medical_license_number: workPlace.medical_license_number,
      npi_number: workPlace.npi_number,
    } : {
      access_code: '',
      institution_name: '',
      institution_country: '',
      institution_state: '',
      institution_type: '',
      institution_website: '',
      email: '',
      job_title: '',
      degree_designation: [''],
      degree_in_progress: [''],
      department_specialty: '',
      medical_license_number: '',
      npi_number: ''
    }
  );

  const isUnitedStates = () => (
    workPlaceAttrs.institution_country === 'united_states'
  );

  const handleFieldChange = (field, value) => {
    setWorkPlaceAttrs({ ...workPlaceAttrs, [field]: value });
  };

  const handleDegreeDesignationChange = (idx, value) => {
    workPlaceAttrs.degree_designation[idx] = value;
    setWorkPlaceAttrs({ ...workPlaceAttrs });
  }

  const handleDegreeDesignationAdd = (e) => {
    e.preventDefault();
    workPlaceAttrs.degree_designation.push('');
    setWorkPlaceAttrs({ ...workPlaceAttrs });
  }

  const handleDegreeInProgressChange = (idx, value) => {
    workPlaceAttrs.degree_in_progress[idx] = value;
    setWorkPlaceAttrs({ ...workPlaceAttrs });
  }

  const handleDegreeInProgressAdd = (e) => {
    e.preventDefault();
    workPlaceAttrs.degree_in_progress.push('');
    setWorkPlaceAttrs({ ...workPlaceAttrs });
  }

  const handleCancel = (e) => {
    e.preventDefault();

    if (workPlace) {
      resetState();
      setEditMode(false);
    } else {
      cancelNewWorkPlace();
    }
  }

  const resetState = () => {
    setWorkPlaceErrors({});
    setWorkPlaceAttrs(
      workPlace ? {
        institution_name: workPlace.institution_name,
        institution_country: workPlace.institution_country,
        institution_state: workPlace.institution_state,
        institution_type: workPlace.institution_type,
        institution_website: workPlace.institution_website,
        email: workPlace.email,
        job_title: workPlace.job_title,
        degree_designation: (workPlace.degree_designation?.length ? JSON.parse(JSON.stringify(workPlace.degree_designation)) : ['']),
        degree_in_progress: (workPlace.degree_in_progress?.length ? JSON.parse(JSON.stringify(workPlace.degree_in_progress)) : ['']),
        department_specialty: workPlace.department_specialty,
        medical_license_number: workPlace.medical_license_number,
        npi_number: workPlace.npi_number,
      } : {
        access_code: '',
        institution_name: '',
        institution_country: '',
        institution_state: '',
        institution_type: '',
        institution_website: '',
        email: '',
        job_title: '',
        degree_designation: [''],
        degree_in_progress: [''],
        department_specialty: '',
        medical_license_number: '',
        npi_number: ''
      }
    );
  }

  const clearBlankFields = () => {
    if (!isUnitedStates()) {
      workPlaceAttrs['institution_state'] = '';
    }

    workPlaceAttrs['degree_designation'] = workPlaceAttrs.degree_designation.filter(dd => dd);
    if (workPlaceAttrs['degree_designation'].length === 0) {
      workPlaceAttrs['degree_designation'].push('');
    }

    workPlaceAttrs['degree_in_progress'] = workPlaceAttrs.degree_in_progress.filter(dip => dip);
    if (workPlaceAttrs['degree_in_progress'].length === 0) {
      workPlaceAttrs['degree_in_progress'].push('');
    }

    setWorkPlaceAttrs({ ...workPlaceAttrs });
  }

  const validateForm = () => {
    let errors = {};

    if (currentUser.isTJUStudent && workPlaceAttrs.institution_name.length === 0) {
      errors['institution_name'] = 'Institution name is required.'
    }

    if ((workPlaceAttrs.institution_name || '').length > 150) {
      errors['institution_name'] = 'Institution name is too long (maximum is 150 characters).'
    }

    if (workPlaceAttrs.institution_country.length === 0) {
      errors['institution_country'] = 'Institution country is required.'
    }

    if (isUnitedStates() && workPlaceAttrs.institution_state.length === 0) {
      errors['institution_state'] = 'Institution state is required.'
    }

    if (currentUser.isTJUStudent && workPlaceAttrs.institution_type.length === 0) {
      errors['institution_type'] = 'Institution type is required.'
    }

    if (currentUser.isTJUStudent && workPlaceAttrs.institution_website.length === 0) {
      errors['institution_website'] = 'Institution website is required.'
    }

    if ((workPlaceAttrs.institution_website || '').length > 100) {
      errors['institution_website'] = 'Institution website is too long (maximum is 100 characters).'
    }

    if (!(/^\S+@\S+\.\S+$/.test(workPlaceAttrs.email))) {
      errors['email'] = 'Email is invalid.'
    }

    if ((workPlaceAttrs.email || '').length > 100) {
      errors['email'] = 'Email is too long (maximum is 100 characters).';
    }

    if (currentUser.isTJUStudent && workPlaceAttrs.job_title.length === 0) {
      errors['job_title'] = 'Job title is required.'
    }

    if ((workPlaceAttrs.job_title || '').length > 100) {
      errors['job_title'] = 'Job title is too long (maximum is 100 characters).'
    }

    workPlaceAttrs.degree_designation.find(degree => {
      if ((degree || '').length > 100) {
        errors['degree_designation'] = 'Degree is too long (maximum is 100 characters).'
      }
    })

    workPlaceAttrs.degree_in_progress.find(degree => {
      if ((degree || '').length > 100) {
        errors['degree_in_progress'] = 'Degree in-progress is too long (maximum is 100 characters).'
      }
    })

    if ((workPlaceAttrs.medical_license_number || '').length > 100) {
      errors['medical_license_number'] = 'Medical license number is too long (maximum is 100 characters).'
    }

    if ((workPlaceAttrs.npi_number || '').length > 100) {
      errors['npi_number'] = 'Npi number is too long (maximum is 100 characters).'
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    clearBlankFields();

    let errors = validateForm();

    setWorkPlaceErrors(errors);

    if (Object.keys(errors).length === 0) {
      isSubmitting(true);

      if (workPlace) {
        updateWorkPlace({ id: workPlace.id, ...workPlaceAttrs }).then(res => {
          isSubmitting(false);
          if (res.errors) {
            setWorkPlaceErrors({ response: res.errors.workplace })
          } else {
            gaEvent('workplace_update', { access_code: workPlace.license_access_code });
            displayProfileWingsModal();
            setEditMode(false);
          }
        });
      } else {
        createWorkPlace(workPlaceAttrs).then(res => {
          isSubmitting(false);
          if (res.errors) {
            setWorkPlaceErrors({ response: res.errors.workplace })
          } else {
            gaEvent('workplace_create', { access_code: workPlaceAttrs.access_code });
            cancelNewWorkPlace();
            window.location.reload(false);
          }
        });
      }
    }
  }

  const renderViewMode = () => {
    return (
      <div className="DashboardFormBox-Content ViewMode">
        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Institution Name{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
            <div data-tip data-for="institution-name-field" className="Tooltip">
              <i className="fa fa-question" />
              <ReactTooltip id="institution-name-field" type="info" effect="solid" children="Please type your institution name correctly so we can correctly match you to your institution." />
            </div>
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.institution_name}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Institution Country<span className="Required-Asterisk">*</span>
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.institution_country ? countriesList[workPlace.institution_country] : ''}
          </div>
        </div>

        {
          isUnitedStates() &&
          <div className="DashboardFormBox-Line">
            <div className="DashboardFormBox-Label">
              Institution State<span className="Required-Asterisk">*</span>
            </div>
            <div className="DashboardFormBox-Label blue">
              {workPlace.institution_state ? usaStatesList[workPlace.institution_state] : ''}
            </div>
          </div>
        }

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Institution Type{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.institution_type ? institutionTypeOptions[workPlace.institution_type] : ''}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Institution Website{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Label blue ttn">
            {workPlace.institution_website}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Institution issued email<span className="Required-Asterisk">*</span>
          </div>
          <div className="DashboardFormBox-Label blue ttn">
            {workPlace.email}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Job Title{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.job_title}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Degree
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.degree_designation?.join(', ')}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Degree In-progress
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.degree_in_progress?.join(', ')}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Department Specialty
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.department_specialty ? departmentsList[workPlace.department_specialty] : ''}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Medical License Number
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.medical_license_number}
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label">
            Npi Number
          </div>
          <div className="DashboardFormBox-Label blue">
            {workPlace.npi_number}
          </div>
        </div>
      </div>
    )
  };

  const renderEditMode = () => {
    return (
      <form className="DashboardFormBox-Content LanguageCreateEditLingo EditMode">
        {
          (Object.keys(workPlaceErrors).length > 0) &&
          <ul className="WorkPlace-Errors list-disc pl-10">
            {
              Object.entries(workPlaceErrors).map(([k, v], idx) => (
                <li key={idx} children={v} />
              ))
            }
          </ul>
        }

        {
          !workPlace && (
            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                Access Code (if applicable)
              </div>
              <div className="DashboardFormBox-Label">
                <InputBox
                  className="DashboardFormBox-Input"
                  value={workPlaceAttrs.access_code}
                  onChange={(e) => handleFieldChange('access_code', e.currentTarget.value)}
                />
              </div>
            </div>
          )
        }

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Institution Name{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
            <div data-tip data-for="institution-name-field" className="Tooltip">
              <i className="fa fa-question" />
              <ReactTooltip id="institution-name-field" type="info" effect="solid" children="Please type your institution name correctly so we can correctly match you to your institution." />
            </div>
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.institution_name}
              onChange={(e) => handleFieldChange('institution_name', e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Institution Country<span className="Required-Asterisk">*</span>
          </div>
          <div className="DashboardFormBox-Input_container Half">
            <select
              className="DashboardFormBox-Select"
              value={workPlaceAttrs.institution_country}
              onChange={(e) => handleFieldChange('institution_country', e.currentTarget.value)}
            >
              {
                Object.entries(countriesList).map(([k, v]) => (
                  <option key={k} value={k} children={v} />
                ))
              }
            </select>
          </div>
        </div>

        {
          isUnitedStates() &&
          <div className="DashboardFormBox-Line">
            <div className="DashboardFormBox-Label left">
              Institution State<span className="Required-Asterisk">*</span>
            </div>
            <div className="DashboardFormBox-Input_container Half">
              <select
                className="DashboardFormBox-Select"
                value={workPlaceAttrs.institution_state}
                onChange={(e) => handleFieldChange('institution_state', e.currentTarget.value)}
              >
                {
                  Object.entries(usaStatesList).map(([k, v]) => (
                    <option key={k} value={k} children={v} />
                  ))
                }
              </select>
            </div>
          </div>
        }

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Institution Type{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Input_container Half">
            <select
              className="DashboardFormBox-Select"
              value={workPlaceAttrs.institution_type}
              onChange={(e) => handleFieldChange('institution_type', e.currentTarget.value)}
            >
              {
                Object.entries(institutionTypeOptions).map(([k, v]) => (
                  <option key={k} value={k} children={v} />
                ))
              }
            </select>
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Institution Website{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.institution_website}
              onChange={(e) => handleFieldChange('institution_website', e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Institution issued email<span className="Required-Asterisk">*</span>
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.email}
              onChange={(e) => handleFieldChange('email', e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Job Title{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.job_title}
              onChange={(e) => handleFieldChange('job_title', e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Degree
          </div>
          <div className="DashboardFormBox-Input_container">
            {
              workPlaceAttrs.degree_designation?.map((dd, idx) => (
                <div className="Designation" key={`dd-${idx}`}>
                  <InputBox
                    className="DashboardFormBox-Input Quater"
                    value={dd}
                    onChange={(e) => handleDegreeDesignationChange(idx, e.currentTarget.value)}
                  />
                  {
                    (workPlaceAttrs.degree_designation.length === (idx + 1)) &&
                    <ButtonBox
                      className="Add-Degree-Designation"
                      text={
                        <span className="Btn">
                          <ImageBox src="add.svg" className="Icon" />
                          Add Degree
                        </span>
                      }
                      onClick={(e) => handleDegreeDesignationAdd(e)}
                    />
                  }
                </div>
              ))
            }
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Degree In-progress
          </div>
          <div className="DashboardFormBox-Input_container">
            {
              workPlaceAttrs.degree_in_progress?.map((dip, idx) => (
                <div className="Degree-In-Progress" key={`dip-${idx}`}>
                  <InputBox
                    className="DashboardFormBox-Input Quater"
                    value={dip}
                    onChange={(e) => handleDegreeInProgressChange(idx, e.currentTarget.value)}
                  />
                  {
                    (workPlaceAttrs.degree_in_progress.length === (idx + 1)) &&
                    <ButtonBox
                      className="Add-Degree-In-Progress"
                      text={
                        <span className="Btn">
                          <ImageBox src="add.svg" className="Icon" />
                          Add Degree In-progress
                        </span>
                      }
                      onClick={(e) => handleDegreeInProgressAdd(e)}
                    />
                  }
                </div>
              ))
            }
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Department Specialty
          </div>
          <div className="DashboardFormBox-Input_container Half">
            <select
              className="DashboardFormBox-Select"
              value={workPlaceAttrs.department_specialty}
              onChange={(e) => handleFieldChange('department_specialty', e.currentTarget.value)}
            >
              {
                Object.entries(departmentsList).map(([k, v]) => (
                  <option key={k} value={k} children={v} />
                ))
              }
            </select>
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Medical License Number
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.medical_license_number}
              onChange={(e) => handleFieldChange('medical_license_number', e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="DashboardFormBox-Line">
          <div className="DashboardFormBox-Label left">
            Npi Number
          </div>
          <div className="DashboardFormBox-Input_container Full">
            <InputBox
              className="DashboardFormBox-Input"
              value={workPlaceAttrs.npi_number}
              onChange={(e) => handleFieldChange('npi_number', e.currentTarget.value)}
            />
          </div>
        </div>
      </form>
    )
  };

  return (
    <div className="DashboardFormBox Create-Edit-Workplace-Container">
      {submitting && <MirrorLoader message={workPlace ? 'Updating workplace!' : 'Creating and switching to new workplace!'} />}
      <div className="DashboardFormBox-Title_container">
        <div className="DashboardFormBox-Title">
          <TextBox
            className="Title"
            text={workPlace ? `WorkPlace ${workPlaceNumber}` : 'New Workplace'}
          />
        </div>

        {
          editMode ? (
            <div className="DashboardFormBox-EditModeBtns">
              <ButtonBox
                className="DashboardFormBox-Button_cancel"
                text="Cancel"
                onClick={(e) => handleCancel(e)}
              />

              <ButtonBox
                className="DashboardFormBox-Button_save"
                text="Save"
                disabled={submitting}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          ) : (
            <div className="DashboardFormBox-ViewModeBtns">
              <ButtonBox
                className="DashboardFormBox-Button_edit"
                text="Edit"
                onClick={(e) => setEditMode(true)}
              />
            </div>
          )
        }
      </div>

      <div className="DashboardFormBox-Content_container">
        {editMode ? renderEditMode() : renderViewMode()}
      </div>

      <div className="DashboardFormBox-Footer_container">
        {
          editMode &&
          <div className="DashboardFormBox-EditModeFooterBtns">
            <ButtonBox
              className="DashboardFormBox-Button_cancel"
              text="Cancel"
              onClick={(e) => handleCancel(e)}
            />

            <ButtonBox
              className="DashboardFormBox-Button_save"
              text="Save"
              disabled={submitting}
              onClick={(e) => handleSubmit(e)}
            />
          </div>
        }
      </div>
    </div>
  );
}

CreateEditWorkPlace.propTypes = {
  currentUser: PropTypes.object.isRequired,
  workPlace: PropTypes.object,
  createWorkPlace: PropTypes.func,
  updateWorkPlace: PropTypes.func,
  cancelNewWorkPlace: PropTypes.func,
  workPlaceNumber: PropTypes.number,
  displayProfileWingsModal: PropTypes.func.isRequired,
};

export default CreateEditWorkPlace;
