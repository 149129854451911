import { connect } from 'react-redux';

import { clearSessionErrors, unlockAccount } from '../../../Actions/SessionActions';
import UnlockPage from './UnlockPage';

const mapStateToProps = (state, ownProps) => ({
    errors: state.errors.session,
    flash: state.flash.session,
    token: ownProps.match.params.token,
});

const mapDispatchToProps = (dispatch) => ({
    unlockAccount: user => dispatch(unlockAccount(user)),
    clearSessionErrors: () => dispatch(clearSessionErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlockPage);
