import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './InstitutionBox.css';
import { COLORS } from '../../../Style/Style';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import TextBox from '../../Basic/TextBox/TextBox';

const InstitutionBox = ({ institution }) => {
    return (
        <div className="InstitutionBox">
            {
                institution.image_url
                    ? (
                        <img
                            className="ImageBox-InstitutionBox"
                            src={institution.image_url}
                            alt=""
                        />
                    )
                    : (
                        <ImageBox
                            className="ImageBox-InstitutionBox"
                            src="building.png"
                        />
                    )
            }

            <Link to={`/account/institutions/${institution.id}/edit`}>
                <TextBox
                    text={institution.name}
                    style={{
                        color: COLORS.Blue,
                    }}
                />
            </Link>
        </div>
    );
};

InstitutionBox.propTypes = {
    institution: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        image_url: PropTypes.string,
    }).isRequired,
};

export default InstitutionBox;
