import axios from './AxiosUtil';

export const fetchUserResources = () => {
  return axios.get('/api/user_resources').then(res => res.data);
}

export const createUserResource = (userResource) => {
  return axios({
    method: 'POST',
    url: '/api/user_resources',
    processData: false,
    contentType: false,
    dataType: 'json',
    data: userResource,
  }).then(
    res => res.data
  );
};
