import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/UserApiUtil';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USER_PERFORMANCE_REQUEST = 'FETCH_USER_PERFORMANCE_REQUEST';
export const FETCH_USER_PERFORMANCE_SUCCESS = 'FETCH_USER_PERFORMANCE_SUCCESS';
export const FETCH_USER_PERFORMANCE_ERROR = 'FETCH_USER_PERFORMANCE_ERROR';
export const FETCH_USER_PERFORMANCE_CLEANUP = 'FETCH_USER_PERFORMANCE_CLEANUP';

export const fetchUserRequest = () => ({
    type: FETCH_USER_REQUEST,
});

export const fetchUserSuccess = payload => ({
    type: FETCH_USER_SUCCESS,
    payload,
});

export const fetchUserError = error => ({
    type: FETCH_USER_ERROR,
    error,
});

export const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = payload => ({
    type: FETCH_USERS_SUCCESS,
    payload,
});

export const fetchUsersError = error => ({
    type: FETCH_USERS_ERROR,
    error,
});

export const fetchUserPerformanceRequest = () => ({
    type: FETCH_USER_PERFORMANCE_REQUEST,
});

export const fetchUserPerformanceSuccess = payload => ({
    type: FETCH_USER_PERFORMANCE_SUCCESS,
    payload,
});

export const fetchUserPerformanceError = error => ({
    type: FETCH_USER_PERFORMANCE_ERROR,
    error,
});

export const cleanup = () => ({
    type: FETCH_USER_PERFORMANCE_CLEANUP,
});


export const fetchUser = userId => async dispatch => {
    dispatch(fetchUserRequest());
    try {
        const res = await APIUtil.fetchUser(userId);
        dispatch(fetchUserSuccess(res));
    } catch (errors) {
        dispatch(fetchUserError(ErrorResponseUtil(errors)));
    }
};

export const fetchUsers = () => async dispatch => {
    dispatch(fetchUsersRequest());
    try {
        const res = await APIUtil.fetchUsers();
        dispatch(fetchUsersSuccess(res));
    } catch (errors) {
        dispatch(fetchUsersError(ErrorResponseUtil(errors)));
    }
};

export const fetchUserPerformance = userId => async dispatch => {
    dispatch(fetchUserPerformanceRequest());
    try {
        const res = await APIUtil.fetchUserPerformance(userId);
        dispatch(fetchUserPerformanceSuccess(res));
    } catch (errors) {
        dispatch(fetchUserPerformanceError(ErrorResponseUtil(errors)));
    }
};

export const fetchUserPerformanceCleanup = () => dispatch => dispatch(cleanup());
