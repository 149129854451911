import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import EnvironmentUtil from '../../../../Util/EnvironmentUtil';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import Loader from '../../../Composed/Loader/Loader';

import './UserResources.scss';

const UserResources = ({ userResources, clearUserResources, fetchUserResources }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearUserResources();
    fetchUserResources().then((res) => setLoading(false));
  }, [clearUserResources, fetchUserResources]);

  const downloadUserResource = (userResource) => {
    const fileUrl = EnvironmentUtil().buildURL(userResource.file.url);

    fetch(fileUrl).then((res) => {
      return res.blob();
    }).then((blob) => {
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const downloadLink = document.createElement('a');

      downloadLink.href = blobUrl;
      downloadLink.download = `${userResource.name}.${userResource.file.extension}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="UserResources-Container">
      <div className="ProgressTable Responsive-To-Screen">
        <table>
          <thead>
            <tr>
              <th scope="col">Product</th>
              <th scope="col">
                <ImageBox className="RelateIcon" src="related.svg" />
                {' '}
                Related Resources
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Object.values(userResources).map((userResource, idx) => (
                userResource.file.url &&
                userResource.file.extension &&
                <tr key={`user-resource-${idx}`}>
                  <td>{userResource.resourceable?.name}</td>
                  <td className="Download-Resource">
                    <div onClick={() => downloadUserResource(userResource)}>
                      {userResource.name}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

UserResources.propTypes = {
  userResources: PropTypes.object.isRequired,
  clearUserResources: PropTypes.func.isRequired,
  fetchUserResources: PropTypes.func.isRequired,
}

export default UserResources;
