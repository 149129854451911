import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './LessonBox.css';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ProgressBar from '../../Basic/ProgressBar/ProgressBar';
import TopicSummaryBox from '../../Composed/TopicSummaryBox/TopicSummaryBox';
import { fetchOnlyTopics } from '../../../Actions/TopicActions';
import { initiateLesson } from '../../../Util/Helper/CourseUtil';
import CertificateLocator from '../../Composed/CertificateLocator/CertificateLocator';

const propTypes = {
    course: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    lesson: PropTypes.shape({
        id: PropTypes.number,
        image_url: PropTypes.string,
        position: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        is_test: PropTypes.bool,
        topics: PropTypes.array,
        quiz_id: PropTypes.number,
    }).isRequired,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    trialLocked: PropTypes.bool,
    quizResults: PropTypes.objectOf(PropTypes.array).isRequired,
};

const mapStateToProps = (state) => ({
    courseProgresses: state.statistics.courseProgresses,
    activities: state.entities.activities,
    quizzes: state.entities.quizzes
});

const mapDispatchToProps = (dispatch) => ({
    fetchTopics: lessonId => dispatch(fetchOnlyTopics(lessonId)),
});

const LessonBox = ({
    lesson,
    lessons,
    course,
    courseProgresses,
    trialLocked,
    quizResults,
    fetchTopics,
    activities,
    quizzes
}) => {
    const [hidden, setHidden] = useState(true);

    const handleProgressPercent = () => {
        if (courseProgresses?.[course.id]?.progress_data?.lessonProgresses?.[lesson.id]
                ?.completedTopics) {
            return (
                Math.floor(
                    courseProgresses[course.id].progress_data.lessonProgresses[lesson.id].completedTopics.length / lesson.topics.length * 100
                )
            );
        }

        return 0;
    };

    const handleQuizScore = () => {
        if (quizResults?.[lesson.quiz_id]) {
            const result = quizResults[lesson.quiz_id][0];
            return Math.floor(result.num_correct / result.num_questions * 100);
        }

        return '-';
    };

    const handleQuizColor = () => {
        const score = handleQuizScore();
        if (score === '-') {
            return COLORS.Blue;
        }

        if (score >= 70) {
            return COLORS.green;
        }

        return COLORS.redPink;
    };

    const handleLessonBoxClick = () => {
        if (course.course_type?.name !== 'Compliance') {
            setHidden(!hidden);
        } else {
            fetchTopics(lesson.id).then(res =>
                window.location.hash = `#${initiateLesson(course, lesson)}`
            );
        }
    }

    return (
        <div className={trialLocked ? 'LessonBox trial' : 'LessonBox'}>
            {
                (course.lessons[course.lessons.length - 1] === lesson.id) &&
                <CertificateLocator className="Lesson-Dashboard-Tile" course={course} lessons={lessons} quizResults={quizResults} />
            }
            <div 
                className="LessonBox-Visible__container"
                onClick={
                    !trialLocked
                        ? () => handleLessonBoxClick()
                        : undefined
                }
            >
                <div className="LessonBox-Databox__container">
                    {
                        lesson.number
                        && (
                            <div className="LessonBox-Position">
                                {lesson.number}
                            </div>
                        )
                    }

                    <div className="LessonBox-DataBox">
                        <div className="LessonBox-DataBox-title">
                            <TextBox
                                text={lesson.name}
                                style={{
                                    color: `${COLORS.Blue}`,
                                    fontWeight: '600',
                                }}
                                tag="h5"
                            />
                        </div>
                        <div className="LessonBox-DataBox-description">
                            <TextBox
                                text={lesson.description}
                                style={{
                                    color: COLORS.Blue,
                                }}
                                tag="h5"
                            />
                        </div>
                        {
                            !lesson.is_test
                            && (
                                <div className="LessonBox-DataBox-ProgressBar">
                                    <ProgressBar
                                        percent={handleProgressPercent()}
                                        fractionTop={
                                            courseProgresses?.[course.id]?.progress_data
                                                ?.lessonProgresses?.[lesson.id]?.completedTopics
                                                ? String(courseProgresses[course.id].progress_data
                                                    .lessonProgresses[lesson.id].completedTopics
                                                    .length)
                                                : '0'
                                        }
                                        fractionBottom={String(lesson.topics.length)}
                                        textColor={`${COLORS.greyBlue}`}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>

                {
                    quizResults?.[lesson.quiz_id]?.[0]?.quiz_status === 'active' &&
                    <div className="LessonBox-QuizResult__constainer">
                        <TextBox
                            text={lesson.is_test ? 'Exam Grade' : 'Lesson Quiz Grade'}
                            style={{
                                color: COLORS.Blue,
                            }}
                        />

                        <TextBox
                            text={handleQuizScore() === '-' ? handleQuizScore() : `${handleQuizScore()}%`}
                            style={{
                                color: handleQuizColor(),
                            }}
                            tag="h2"
                        />
                    </div>
                }

                {
                    course.course_type?.name !== 'Compliance' &&
                    <button
                        className="LessonBox-Button-Icon__dropdown"
                        type="button"
                    >
                        {
                            hidden
                                ? <i className="fas fa-chevron-down" />
                                : <i className="fas fa-chevron-up" />
                        }
                    </button>
                }
            </div>

            {
                !hidden
                && (
                    <TopicSummaryBox
                        lesson={lesson}
                        courseId={course.id}
                        lessonId={lesson.id}
                    />
                )
            }
        </div>
    );
};

LessonBox.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LessonBox);
