import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextAreaBox from '../../Basic/TextAreaBox/TextAreaBox';

import { isStudent, isB2B } from '../../../Util/Helper/UserWorkplaceUtil';

import './NetPromoterScore.scss';

const NetPromoterScore = ({ currentUser, currentWorkplace, createUserNetPromoterScore }) => {
  const [displayState, setDisplayState] = useState({ isClosed: false, shouldShow: false });
  const [processingState, setProcessingState] = useState({ submitting: false, error: '', success: false });
  const [npsAttrs, setNpsAttrs] = useState({});

  useEffect(() => {
    if (!isStudent(currentUser)) {
      setDisplayState({ isClosed: true, shouldShow: false });
    } else if (!displayState.shouldShow) {
      const display3Months = shouldDisplayFor(3, 'threeMonthsNpsRated')
      const display9Months = shouldDisplayFor(9, 'nineMonthsNpsRated')

      if (display3Months || display9Months) {
        setDisplayState({ isClosed: false, shouldShow: true });
        setNpsAttrs({
          rated_as: (isB2B(currentUser, currentWorkplace) ? 'B2B.User' : 'B2C.User'),
          period: (display3Months ? 'three_months' : 'nine_months'),
          score: 5,
          feedback: '',
        });
      } else {
        setDisplayState({ isClosed: true, shouldShow: false });
      }
    }
  }, [currentUser]);

  const shouldDisplayFor = (month, givenForPeriod) => {
    if (isB2B(currentUser, currentWorkplace) && month === 9) {
      return false;
    }

    if (currentUser[givenForPeriod]) {
      return false;
    }

    return todayIsInRange(month);
  }

  const todayIsInRange = month => {
    const registrationDate = new Date(currentUser.created_at);

    const rangeStartDate = new Date(registrationDate);
    rangeStartDate.setMonth(registrationDate.getMonth() + month);

    const rangeEndDate = new Date(rangeStartDate);
    rangeEndDate.setDate(rangeStartDate.getDate() + 14);

    const today = new Date();
    return (rangeStartDate <= today && today <= rangeEndDate);
  }

  const handleSubmit = () => {
    setProcessingState({ submitting: true, error: '', success: false });
    createUserNetPromoterScore(npsAttrs).then(res => {
      res.error
        ? setProcessingState({ submitting: false, error: res.error })
        : setProcessingState({ submitting: false, success: true })
    });
  }

  const renderContent = () => (
    <div className="NetPromoterScore-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="Heading">How did we do?</div>
            <ButtonBox
              className="Close-Btn"
              src="close.svg"
              onClick={() => setDisplayState({ isClosed: true, shouldShow: false })}
            />
          </div>
          <div className="modal-body">
            {
              processingState.success
              ? (
                <div className="Success">
                  <i className="fa fa-5x fa-check-circle-o Icon" />
                  <h3 className="Response">Thank you for your feedback!</h3>
                </div>
              ) : (
                <div className="Form">
                  <div className="Error">
                    {processingState.error}
                  </div>
                  <div className="Description">
                    On a scale of 1-10, how likely are you to recommend Canopy to a friend or colleague?
                  </div>
                  <div className="Slider-Section">
                    <span className="Label">1</span>
                    <Range
                      step={1}
                      min={1}
                      max={10}
                      values={[npsAttrs.score]}
                      onChange={values => setNpsAttrs({ ...npsAttrs, score: values[0] })}
                      renderTrack={({ props, children }) => (
                        <div {...props} className="Legend-Bar" style={{ ...props.style }}>
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div {...props} key={props.key} className="Pointer" style={{ ...props.style }}>
                          <div className="ToolTip">
                            {npsAttrs.score}
                          </div>
                        </div>
                      )}
                    />
                    <span className="Label">10</span>
                  </div>
                  <div className="Feedback-Section">
                    <TextAreaBox
                      className="Feedback"
                      placeholder="Feedback and Comments (max 500 characters)"
                      value={npsAttrs.feedback}
                      maxLength="500"
                      onChange={e => setNpsAttrs({ ...npsAttrs, feedback: e.currentTarget.value })}
                    />
                  </div>
                  {
                    npsAttrs.feedback.length === 500
                    && (
                      <div className="Error">
                        Maximum feedback limit is 500 characters.
                      </div>
                    )
                  }
                  <div className="Actions">
                    <ButtonBox
                      className="Primary-Btn Submit-Button"
                      text="Submit"
                      disabled={processingState.submitting}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )

  if (displayState.isClosed) {
    return null;
  }

  if (displayState.shouldShow) {
    return renderContent();
  }

  return null;
};

NetPromoterScore.propTypes = {
  currentUser: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  currentWorkplace: PropTypes.shape({}).isRequired,
  createUserNetPromoterScore: PropTypes.func.isRequired,
};

export default NetPromoterScore;
