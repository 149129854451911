import axios from './AxiosUtil';

export const fetchInviteUsers = () => {
  return axios.get('/api/invite_users').then(
    res => res.data
  )
};

export const createInviteUsers = (formData) => {
  return axios({
    method: 'POST',
    url: '/api/invite_users',
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data,
  )
};
