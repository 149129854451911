import { SSE } from 'sse.js';
import axios from './AxiosUtil';
import EnvironmentUtil from './EnvironmentUtil';

export const receiveStreamAndSupplyEachChunk = (src, supplyChunk, payload = null) => {
  return new Promise((resolve, reject) => {
    const eventSource = new SSE(
      EnvironmentUtil().buildURL(src),
      {
        headers: {
          Authorization: axios.defaults.headers.common['Authorization'],
        },
        payload,
      }
    );

    eventSource.onmessage = (event) => {
      const chunk = JSON.parse(event.data);

      supplyChunk(chunk);

      if (chunk.event === 'crashed') {
        console.log(`chunk error: ${chunk.message}`)
      }

      if (chunk.event === 'closed') {
        eventSource.close();
        resolve();
      }
    }

    eventSource.onerror = (event) => {
      eventSource.close();
      reject(new Error(event.message));
    }
  });
}
