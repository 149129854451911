import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StackedChart from '../../../../HighCharts/VerticalStackedChart/StackedChart';
import Loader from '../../../../Composed/Loader/Loader';

import './Overview.scss';

const Overview = ({ netPromoterScores, clearNetPromoterScores, fetchNPSOverview }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);

  const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    clearNetPromoterScores();
    fetchNPSOverview().then(() => setLoading(false));
  }, [clearNetPromoterScores, fetchNPSOverview]);

  useEffect(() => {
    if (!loading) {
      setShowChart(true);
    }
  }, [loading]);

  const renderChart = (ratedAs, timePeriod, graphTitle) => {
    const data = [];

    ratings.map(rating => {
      const occurrence = netPromoterScores[ratedAs][timePeriod].scores.filter(score => score === rating).length;

      data.push({
        y: occurrence,
        users: occurrence,
      });
    });

    const chartPayload = {
      title: graphTitle,
      subtitle: (netPromoterScores[ratedAs][timePeriod].scores.length === 0 ? '<div class="Sub-Title">No ratings have been submitted by users.</div>' : ''),
      xAxis: ratings,
      yAxisTitle: 'Users',
      legendTop: 25,
      tooltip: '<p class="heading">{{POINT_0_POINT_USERS}} users</p>',
      series: [
        { name: 'Rating', color: '#80cbc4', data },
      ],
    };

    return (
      <div id={`${ratedAs}-${timePeriod}-graph`}>
        {
          showChart
          && (
            <StackedChart
              id={`${ratedAs}-${timePeriod}-graph`}
              data={chartPayload}
            />
          )
        }
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return <Loader message="Getting things ready..." />
    }

    return (
      <div className="Chart-Container">
        <div className="Chart">
          {renderChart('b2b_user', 'three_months', 'B2B.User ratings after three months of registration')}
        </div>
        <div className="Chart">
          {renderChart('b2c_user', 'three_months', 'B2C.User ratings after three months of registration')}
        </div>
        <div className="Chart">
          {renderChart('b2c_user', 'nine_months', 'B2C.User ratings after nine months of registration')}
        </div>
      </div>
    )
  }

  return (
    <div className="Overview-Container">
      {renderContent()}
    </div>
  )
};

Overview.propTypes = {
  netPromoterScores: PropTypes.shape({}).isRequired,
  clearNetPromoterScores: PropTypes.func.isRequired,
  fetchNPSOverview: PropTypes.func.isRequired,
};

export default Overview;
