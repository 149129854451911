import axios from './AxiosUtil';

export const fetchCourseTypes = () => {
    return axios.get('/api/course_types').then(
        res => res.data,
    );
};

export const updateCourseType = course_type => {
    return axios.patch(`/api/course_types/${course_type.id}`, { course_type }).then(
        res => res.data,
    );
};
