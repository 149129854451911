import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import EnvironmentUtil from './EnvironmentUtil';

const sessionId = uuidv4();

const instance = axios.create(EnvironmentUtil());

instance.defaults.headers.common['X-Session-ID'] = sessionId;

instance.interceptors.request.use((config) => {
    config.headers['X-Transaction-ID'] = uuidv4();
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;
