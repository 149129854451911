import React from 'react';
import './tabs.scss';

const Tabs = ({ setTab, currentTab, tabs }) => {
    return (
        <div className="tabs">
            {
                tabs.map((tab, idx) => (
                    <div
                        key={idx}
                        onClick={() => setTab(idx)}
                        className={currentTab === idx ? "tabs__element-active" : "tabs__element"}
                    >
                        {tab}
                    </div>
                ))
            }
        </div>
    );
};

export default Tabs;
