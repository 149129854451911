import React from 'react';
import PropTypes from 'prop-types';

import './SectionListBox.css';
import SectionBoxMini from '../../Presentational/SectionBox/SectionBoxMini';

const SectionListBox = ({
    sections,
    topic,
    lessonId,
    courseId,
    courseProgresses,
    bookmarks
}) => {
    const handleRender = () => (
        sections.map((section, index) => (
            <SectionBoxMini
                key={section.id}
                section={section}
                topic={topic}
                lessonId={lessonId}
                courseId={courseId}
                courseProgresses={courseProgresses}
                bookmarks={bookmarks}
                sectionIndex={index}
            />
        ))
    );

    return (
        <div className="SectionListBox">
            {handleRender()}
        </div>
    );
};

SectionListBox.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    topic: PropTypes.shape().isRequired,
    lessonId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    courseProgresses: PropTypes.objectOf(PropTypes.object).isRequired,
    bookmarks: PropTypes.objectOf(Array).isRequired,
};

export default SectionListBox;
