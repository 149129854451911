import { CLEAR_PLATFORMS, RECEIVE_PLATFORMS } from '../../Actions/PlatformActions';

const PlatformsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PLATFORMS:
      return Object.assign({}, state, action.payload.platforms);
    case CLEAR_PLATFORMS:
      return {}
    default:
      return state;
  }
};

export default PlatformsReducer;
