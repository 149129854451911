import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './TopicSummaryBox.css';
import { COLORS } from '../../../Style/Style';
import { fetchOnlyTopics } from '../../../Actions/TopicActions';
import TopicBox from '../../Presentational/TopicBox/TopicBox';
import TextBox from '../../Basic/TextBox/TextBox';
import Loader from '../Loader/Loader';

/**
* @function - AccodionBoxContent - functional
* @{props} - { Object }
 * menuItems - Object - lesson Objects to be rendered as a menu.
* @return { JSX Object }
*/

const propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number,
        topics: PropTypes.array,
        quiz_id: PropTypes.number,
    }).isRequired,
    lessonId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    topics: PropTypes.objectOf(PropTypes.object).isRequired,
    quizzes: PropTypes.objectOf(PropTypes.object).isRequired,
    fetchTopics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    activities: state.entities.activities,
    topics: state.entities.topics,
    quizzes: state.entities.quizzes,
});

const mapDispatchToProps = dispatch => ({
    fetchTopics: lessonId => dispatch(fetchOnlyTopics(lessonId)),
});

const TopicSummaryBox = ({
    lesson,
    lessonId,
    courseId,
    topics,
    fetchTopics,
    activities,
    quizzes,
}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTopics(lesson.id).then(() => setLoading(false));
    }, [fetchTopics, lesson.id]);

    const renderLessonActivity = () => {
        if (lesson.activity_id && activities[lesson.activity_id]?.status === 'active') {
            return (
                <div
                    className="TopicBox"
                    key={lesson.activityId}
                >
                    <Link to={`/learn/courses/${courseId}/lessons/${lessonId}/activity`}>
                        <div className="TopicBox-Visible">
                            <div className="TopicBox-DataBox-title">
                                <TextBox
                                    text={activities[lesson.activity_id].name}
                                    style={{
                                        color: `${COLORS.Blue}`,
                                    }}
                                />
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }

        return '';
    }

    const renderLessonTopics = () => {
        return lesson.topics.map((topicId) => {
            if (!topics[topicId]) {
                return <div key={topicId} />;
            }

            return (
                <TopicBox
                    key={topicId}
                    topic={topics[topicId]}
                    lessonId={lessonId}
                    courseId={courseId}
                />
            );
        });
    }

    const renderLessonQuiz = () => {
        if (lesson.quiz_id && quizzes[lesson.quiz_id]?.status === 'active') {
            return (
                <div
                    className="TopicBox"
                    key={lesson.quiz_id + 100000}
                >
                    <Link to={`/learn/courses/${courseId}/lessons/${lessonId}/quiz`}>
                        <div className="TopicBox-Visible">
                            <div className="TopicBox-DataBox-title">
                                <TextBox
                                    text={quizzes[lesson.quiz_id].name}
                                    style={{
                                        color: `${COLORS.Blue}`,
                                    }}
                                />
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }

        return '';
    }

    const handleRender = () => (
        <>
            {renderLessonActivity()}
            {renderLessonTopics()}
            {renderLessonQuiz()}
        </>
    );

    return (
        <div className="TopicSummaryBox">
            {
                loading
                    ? (
                        <div className="TopicSummaryBox-Placeholder">
                            <Loader />
                        </div>
                    )
                    : handleRender()
            }
        </div>
    );
};

TopicSummaryBox.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TopicSummaryBox);
