import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { clearComplianceStats, fetchMyAchievements } from '../../../../Actions/Stats/Users/ComplianceActions';

const mapStateToProps = (state) => ({
  myAchievements: state.stats.users.compliances?.myAchievements || {}
});

const mapDispatchToProps = (dispatch) => ({
  clearComplianceStats: (key) => dispatch(clearComplianceStats(key)),
  fetchMyAchievements: () => dispatch(fetchMyAchievements())
});

const MyAchievements = ({ myAchievements, clearComplianceStats, fetchMyAchievements, contentWidth }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearComplianceStats('myAchievements');
    fetchMyAchievements().then(res => setLoading(false));
  }, [fetchMyAchievements]);

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
       />
    </Link>
  );

  const goToCourseLink = (question) => (
    question.topicId ? (
      <span><Link to={`/learn/courses/${question.courseId}/lessons/${question.lessonId}/?nextToq=topics/${question.topicId}`}>Go to course</Link></span>
    ) : (
      <span><Link to={`/learn/courses/${question.courseId}/lessons/${question.lessonId}/?nextToq=quiz`}>Go to course</Link></span>
    )
  )

  const renderQuestion = (question, isCorrect, questionNumber) => {
    return (
      <div className="QuestionHolder">
        <div className="Question-Number">
          <h5>Question {questionNumber}</h5>
        </div>
        <div className="Question-Detail">
          <p className="Answer">{question.text.replace(/__blank__|__drop__|__drag__|__word__/g, '_____')}</p>
          {
            isCorrect ?
            <div className="Correct">
              You answered this correctly
            </div>
            :
            <div className="Incorrect">
              <div className="Statement">
                You answered this incorrectly
              </div>
              <div className="Link">
                {goToCourseLink(question)}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  const renderMyAchievements = () => {
    let questionNumber = 0

    return (
      <div className="CardHolder">
        <div className="Title-Container">
          <div className="Sub-Title">Compliance Review</div>

          <Link to="/account/resource_library" className="Link">
            <ButtonBox
              className="LibraryButton"
              text="Resource Library"
            />
          </Link>
        </div>

        <div
          className="Content-Container Dynamic-Width"
          style={{
            width: `${contentWidth}px`
          }}
        >
          {
            myAchievements.quizResults.map((quizResult, qrIdx) => {
              let sectionsData = Object.entries(quizResult.quiz_data || {});

              return sectionsData.map(([sectionId, sectionData], sIdx) => {
                let questionsData =  Object.entries(sectionData)

                return questionsData.map(([questionId, questionData], qIdx) => {
                  let isCorrect = Object.entries(questionData).find(([answerNumber, answerData], aIdx) => !answerData.is_correct) ? false : true

                  let question = myAchievements.questions.find(q => q.id === parseInt(questionId));

                  if (!question)
                    return null;

                  questionNumber += 1
                  return renderQuestion(question, isCorrect, questionNumber);
                })
              })
            })
          }
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    if ((myAchievements?.quizResults || []).length === 0) {
      return renderEmpty();
    }

    return renderMyAchievements();
  }

  return (
    <div className="My-Achievements-Container">
      <h2 className="Title">My Achievements</h2>
      {renderContent()}
    </div>
  )
}

MyAchievements.propTypes = {
  myAchievements: PropTypes.object.isRequired,
  clearComplianceStats: PropTypes.func.isRequired,
  fetchMyAchievements: PropTypes.func.isRequired,
  contentWidth: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAchievements);
