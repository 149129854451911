import { RECEIVE_CURRENT_USER } from '../../Actions/SessionActions';
import { RECEIVE_LINGOS } from '../../Actions/LingoActions';
import { RECEIVE_PLEDGES } from '../../Actions/PledgeActions';
import { RECEIVE_PROFILE_COMPLETION } from '../../Actions/ProfileCompletionActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CURRENT_USER: case RECEIVE_LINGOS: case RECEIVE_PLEDGES: case RECEIVE_PROFILE_COMPLETION:
      return Object.assign({}, state, action.payload.profileCompletion);
    default:
      return state;
  }
};
