import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import './LessonAnswerButtons.scss';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CommentsPanelHandler from '../../Pages/Comment/CommentsPanelHandler';

const LessonAnswerButtons = ({
  currentUser,
  activity, topic, quiz, currentSection,
  checkAnswers, checkAnswerHandler, revealAnswers, revealAnswerHandler, resetAnswerHandler,
  displayBookmarkSection, isBookmarkedSection, bookmarkSection,
  displayLeftClick, leftClickHandler, displayRightClick, rightClickHandler, displaySubmitQuiz, submitQuizHandler, displayCommentButton,
  isSavingProgress,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const questionSections = [
    'multiple_choice',
    'multiple_answer',
    'fill_in_the_blank',
    'true_or_false',
    'matching',
    'drag_and_drop',
    'drop_down',
    'sentence_ordering',
    'table_fill_in',
    'word_scramble',
    'sorting',
    'dialog',
  ];

  const renderCheckAnswerBtn = () => (
    topic && questionSections.includes(currentSection.section_type) && (
      <div data-tip data-for="check-answers">
        <ButtonBox
          className={`LessonAnswerButtons-ButtonBox ${checkAnswers ? 'active' : 'teal'}`}
          text={
            <div className="Box">
              <i className="fas fa-check" />
              <p>Check Answers</p>
            </div>
          }
          onClick={checkAnswerHandler}
        />
        <ReactTooltip place="bottom" type="dark" effect="solid" id="check-answers">
          Answer a question then click this to check your answer.
        </ReactTooltip>
      </div>
    )
  )

  const renderRevealAnswerBtn = () => (
    topic && questionSections.includes(currentSection.section_type) && (
      <div data-tip data-for="reveal-answers">
        <ButtonBox
          className={`LessonAnswerButtons-ButtonBox ${revealAnswers ? 'active' : ''}`}
          text={
            <div className="Box">
              <i className="fas fa-key" />
              <p>Reveal Answers</p>
            </div>
          }
          onClick={revealAnswerHandler}
        />
        <ReactTooltip place="bottom" type="dark" effect="solid" id="reveal-answers">
          Click this to reveal correct answers.
        </ReactTooltip>
      </div>
    )
  )

  const renderResetAnswerBtn = () => (
    topic && questionSections.includes(currentSection.section_type) && (
      <div data-tip data-for="reset-answers">
        <ButtonBox
          className="LessonAnswerButtons-ButtonBox"
          text={
            <div className="Box">
              <i className="fas fa-redo" />
              <p>Reset Answers</p>
            </div>
          }
          onClick={resetAnswerHandler}
        />
        <ReactTooltip place="bottom" type="dark" effect="solid" id="reset-answers">
          Reset Answers
        </ReactTooltip>
      </div>
    )
  )

  const renderBookmarkSectionBtn = () => (
    displayBookmarkSection && (
      <div data-tip data-for="save-section">
        <ButtonBox
          className="LessonAnswerButtons-ButtonBox"
          text={
            isBookmarkedSection ? (
              <div className="Box">
                <i className="fas fa-bookmark" />
                <p>Bookmarked</p>
              </div>
            ) : (
              <div className="Box">
                <i className="far fa-bookmark" />
                <p>Bookmark</p>
              </div>
            )
          }
          onClick={bookmarkSection}
        />
        <ReactTooltip place="bottom" type="dark" effect="solid" id="save-section">
          Save Section
        </ReactTooltip>
      </div>
    )
  )

  const renderCommentBtn = () => (
    ((currentUser.user_type === 'TE.Admin' && currentSection) || (displayCommentButton && currentSection)) && (
      <CommentsPanelHandler
        commentableType="Section"
        commentableId={currentSection.id}
      />
    )
  )

  const renderPreviousSectionBtn = () => (
    displayLeftClick &&
    <div data-tip data-for="previous-section">
      <ButtonBox
        className="LessonAnswerButtons-ButtonBox"
        text={
          <div className="Box">
            <i className="fas fa-chevron-left" />
            <p>Previous</p>
          </div>
        }
        onClick={leftClickHandler}
      />
      <ReactTooltip place="bottom" type="dark" effect="solid" id="previous-section">
        Previous Section
      </ReactTooltip>
    </div>
  )

  const renderNextSectionBtn = () => (
    displayRightClick &&
    <div data-tip data-for="next-section">
      <ButtonBox
        className="LessonAnswerButtons-ButtonBox"
        text={
          <div className="Box">
            <i className="fas fa-chevron-right" />
            <p>Next</p>
          </div>
        }
        onClick={rightClickHandler}
      />
      <ReactTooltip place="bottom" type="dark" effect="solid" id="next-section">
        Next Section
      </ReactTooltip>
    </div>
  )

  const renderSubmitQuizBtn = () => (
    displaySubmitQuiz &&
    <div data-tip data-for="submit-quiz">
      <ButtonBox
        className="LessonAnswerButtons-ButtonBox LessonAnswerButtons-SubmitButton"
        text={<span className="SubmitButton"><span>Submit Quiz</span><i className="fas fa-chevron-right" /></span>}
        onClick={submitQuizHandler}
      />
      <ReactTooltip place="bottom" type="dark" effect="solid" id="submit-quiz">
        Submit Quiz
      </ReactTooltip>
    </div>
  )

  const renderSavingProgressIndicator = () => (
    <div data-for="saving-progress">
      <ButtonBox
        className="LessonAnswerButtons-ButtonBox LessonAnswerButtons-SavingProgressIndicator"
        text={
          <div className="Box">
            <i className="fa fa-circle-o-notch fa-spin" />
            <p>Saving Progress!</p>
          </div>
        }
      />
    </div>
  )

  const renderHelperButtons = () => (
    <>
      {renderCheckAnswerBtn()}
      {renderRevealAnswerBtn()}
      {renderResetAnswerBtn()}
    </>
  )

  const renderToolButtons = () => (
    <>
      {renderBookmarkSectionBtn()}
      {renderCommentBtn()}
    </>
  )

  const renderControlButtons = () => {
    if (isSavingProgress) {
      return renderSavingProgressIndicator();
    }

    return (
      <>
        {renderPreviousSectionBtn()}
        {renderNextSectionBtn()}
        {renderSubmitQuizBtn()}
      </>
    )
  }

  return (
    isMobile ? (
      <>
        <div className="LessonAnswerButtons-Mobile-Bottom">
          <div className="LessonAnswerButtons-ButtonBox_container Helpers">
            {renderHelperButtons()}
          </div>

          <div className="LessonAnswerButtons-ButtonBox_container Tools">
            {renderToolButtons()}
          </div>
        </div>

        <div className="LessonAnswerButtons-Mobile-Title">
          <div className="LessonAnswerButtons-ButtonBox_container Controls">
            {renderControlButtons()}
          </div>
        </div>
      </>
    ) : (
      <div className="LessonAnswerButtons-Desktop">
        <div className="LessonAnswerButtons-ButtonBox_container Helpers">
          {renderHelperButtons()}
        </div>

        <div className="LessonAnswerButtons-ButtonBox_container Tools">
          {renderToolButtons()}
        </div>

        <div className="LessonAnswerButtons-ButtonBox_container Controls">
          {renderControlButtons()}
        </div>
      </div>
    )
  );
};

LessonAnswerButtons.propTypes = {
    currentUser: PropTypes.object.isRequired,
    activity: PropTypes.object,
    topic: PropTypes.object,
    quiz: PropTypes.object,
    currentSection: PropTypes.shape({
        section_type: PropTypes.string,
    }),
    checkAnswers: PropTypes.bool,
    checkAnswerHandler: PropTypes.func,
    revealAnswers: PropTypes.bool,
    revealAnswerHandler: PropTypes.func,
    resetAnswerHandler: PropTypes.func,
    displayBookmarkSection: PropTypes.bool,
    isBookmarkedSection: PropTypes.bool,
    bookmarkSection: PropTypes.func,
    displayLeftClick: PropTypes.bool,
    leftClickHandler: PropTypes.func,
    displayRightClick: PropTypes.bool,
    rightClickHandler: PropTypes.func.isRequired,
    displaySubmitQuiz: PropTypes.bool,
    submitQuizHandler: PropTypes.func,
    displayCommentButton: PropTypes.bool,
    isSavingProgress: PropTypes.bool,
};

LessonAnswerButtons.defaultProps = {
    revealAnswers: false,
    checkAnswers: false,
};

export default LessonAnswerButtons;
