import * as APIUtil from '../../../Util/Stats/Users/LearnApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_LEARN_STATS = 'RECEIVE_LEARN_STATS';
export const RECEIVE_LEARN_STAT_ERRORS = 'RECEIVE_LEARN_STAT_ERRORS';
export const CLEAR_LEARN_STATS = 'CLEAR_LEARN_STATS';

export const receiveLearnStats = (payload) => ({
  type: RECEIVE_LEARN_STATS,
  payload,
});

export const receiveLearnStatErrors = (errors) => ({
  type: RECEIVE_LEARN_STAT_ERRORS,
  errors,
});

export const clearLearnStats = (key) => {
  return {
    type: CLEAR_LEARN_STATS,
    key,
  };
};

export const fetchCommunityActivity = () => dispatch => (
  APIUtil.fetchCommunityActivity().then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyBookmarks = () => dispatch => (
  APIUtil.fetchMyBookmarks().then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyComments = () => dispatch => (
  APIUtil.fetchMyComments().then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyProgresses = () => dispatch => (
  APIUtil.fetchMyProgresses().then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchMyActivity = () => dispatch => (
  APIUtil.fetchMyActivity().then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors))),
  )
);
