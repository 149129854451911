import { merge } from 'lodash';

import {
    RECEIVE_COURSE_PROGRESSES,
    RECEIVE_COURSE_PROGRESS,
    RECEIVE_COURSE_PROGRESS_AND_UPDATE,
    CLEAR_COURSE_PROGRESSES
} from '../../Actions/CourseProgressActions';

const CourseProgressesReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_COURSE_PROGRESSES:
            return Object.assign({}, state, action.payload);
        case RECEIVE_COURSE_PROGRESS:
            return Object.assign({}, state, { [action.payload.course_id]: action.payload });
        case RECEIVE_COURSE_PROGRESS_AND_UPDATE:
            merge(state, { [action.payload.course_id]: action.payload });
            return { ...merge(state, { [action.payload.course_id]: action.payload }) };
        case CLEAR_COURSE_PROGRESSES:
            return {};
        default:
            return state;
    }
};

export default CourseProgressesReducer;
