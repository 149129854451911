import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBarContainer';
import CanopyAdminDashboard from '../../Dashboards/CanopyAdminDashboard/CanopyAdminDashboardContainer';
import CanopyUserDashboard from '../../Dashboards/CanopyUserDashboard/CanopyUserDashboardContainer';
import CanopyB2BAdminDashboard from '../../Dashboards/CanopyB2BAdminDashboard/CanopyB2BAdminDashboardContainer';
import './LearnerInterfacePage.css';

class Main extends Component {
    handleRender() {
        const { currentUser } = this.props;
        switch (currentUser.user_type) {
            case ('TE.Admin'):
                return <CanopyAdminDashboard />;
            case ('B2B.Admin'):
                return <CanopyB2BAdminDashboard />;
            case ('B2C.User'):
                return (
                    <CanopyUserDashboard />
                );
            case ('B2B.User'):
                return (
                    <CanopyUserDashboard />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="Profile-Main">
                <Route path="/" component={ProfileNavBar} />

                {this.handleRender()}
            </div>
        );
    }
}

export default (Main);
