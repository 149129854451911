import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from  '../../../../Basic/ButtonBox/ButtonBox';
import Loader from  '../../../../Composed/Loader/Loader';
import MirrorLoader from  '../../../../Composed/Loader/MirrorLoader';
import Confirm from '../../../../Composed/Confirm/Confirm';
import DashboardFormBoxNoEdit from '../../../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import ErrorFlashBox from '../../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import InformationModal from '../../../../Composed/InformationModal/InformationModal';

import './ManageTests.scss';

const ManageTests = ({ credentialTests, clearCredentialTests, fetchUserCredentialTests, resetCredentialTest, waiveCredentialTest, checkExistingUser }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [email, setEmail] = useState('');
  const [exists, setExists] = useState(false);
  const [user, setUser] = useState(null);
  const [showSapioResetInstructions, setShowSapioResetInstructions] = useState(false);

  useEffect(() => {
    clearCredentialTests();
    setLoading(false);
  }, [clearCredentialTests]);

  useEffect(() => {
    if (user) {
      setProcessing(true);
      clearCredentialTests();
      fetchUserCredentialTests(user.id).then((res) => setProcessing(false));
    }
  }, [user]);

  const handleCheckExistingUser = (e) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    if (email.trim().length === 0) {
      setErrors({ '': 'Please enter email address to check user\'s existence.' });
      return null;
    }

    setProcessing(true);
    checkExistingUser({ email }).then(res => {
      setExists(res.exists);
      setUser(res.user);
      setProcessing(false);

      if (res.exists) {
        setFlash({ message: 'A user with this email exists in the system.' });
      } else {
        setErrors({ '': 'A user with this email does not exists in the system.' });
      }
    });
  }

  const handleResetCredentialTest = (e, credentialTest) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    setProcessing(true);
    resetCredentialTest(credentialTest.id).then(res => {
      setProcessing(false);

      if (res?.errors?.message) {
        setErrors({ '': res.errors.message });
      } else {
        setShowSapioResetInstructions(true);
      }
    });
  }

  const handleWaiveCredentialTest = (e, credentialTest) => {
    e.preventDefault();
    setErrors({});
    setFlash({});

    setProcessing(true);
    waiveCredentialTest(credentialTest.id).then(res => {
      setProcessing(false);

      if (res?.errors?.message) {
        setErrors({ '': res.errors.message });
      } else {
        setFlash({ message: 'Test waived successfully.' });
      }
    });
  }

  const handleClear = (e) => {
    e.preventDefault();

    setErrors();
    setFlash({});
    setEmail('');
    setExists(false);
    setUser(null);
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Manage-Tests-Container createUserContainer CreateUser">
      <ErrorFlashBox errors={errors} flash={flash} />

      {processing && <MirrorLoader message="Processing..." />}

      {
        showSapioResetInstructions &&
        <InformationModal
          title="Reset Sapio Test"
          descriptions={['The credential test has been reset successfully for this user. However, you have to reset it on the SapioMinds as well. Below are the steps to do this:']}
          instructions={[
            'Visit SapioMinds site and login.',
            'In the side navigation panel, click "Users & Accessess" then click "Users List".',
            'Use the email address to find the user.',
            'Click on the three dots at the end of the user record to open a menu, then click on "Test Takes".',
            'To reset a particular test, click the three dots at the end of that test to open a menu, then click on "Reset".',
            'Be careful to reset only the relevant test on the SapioMinds site.',
          ]}
          actionCallBack={() => setShowSapioResetInstructions(false)}
        />
      }

      <DashboardFormBoxNoEdit title="MANAGE TESTS">
        <div className="DashboardFormBox-Line Manage-User-Access fdc">
          <div className="Verify-Head">
            <div className="Full-Width">
              <div className="DashboardFormBox-Label left">
                User Email
              </div>
              <button
                type="button"
                className="CreateUser-Update-Button Verify-Btn"
                onClick={(e) => handleCheckExistingUser(e)}
              >
                Verify
              </button>
            </div>
          </div>
          <div className="Verify-Body">
            <input
              type="text"
              value={email}
              disabled={exists}
              className={`${exists && 'ValidEmail'} Full-Width`}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {exists && <i className="fas fa-check Check-Icon" />}
          </div>
        </div>
      </DashboardFormBoxNoEdit>

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          {
            Object.keys(credentialTests).length === 0 ? (
              <div className="No-Tests">
                This user has not enrolled in any tests.
              </div>
            ) : (
              <div className="Credential-Tests">
                <ol className="list-decimal">
                  {
                    Object.values(credentialTests).map((credentialTest, idx) => (
                      <li key={`credential-test-${credentialTest.id}-${idx}`}>
                        <div className="Credential-Test">
                          <div className="Details">
                            <div className="Big-Text">
                              {credentialTest.exam?.name || ''}
                            </div>
                            <div className="Small-Text">
                              <b>Language:</b> {credentialTest.exam?.language?.name || ''}
                            </div>
                            <div className="Small-Text">
                              <b>Waived:</b> {credentialTest.is_waived ? 'Yes' : 'No'}
                            </div>
                            <div className="Small-Text">
                              <b>Test Status:</b> {credentialTest.sapioTest?.status || ''}
                            </div>
                          </div>
                          <Confirm
                            text="Are you sure you want to reset this test?"
                            confirm={(e) => handleResetCredentialTest(e, credentialTest)}
                          >
                            {
                              open =>
                              <ButtonBox
                                className="Reset-Button"
                                text="Reset"
                                onClick={() => open()}
                              />
                            }
                          </Confirm>
                          {
                            !credentialTest.is_waived &&
                            <Confirm
                              text="Are you sure you want to waive this test?"
                              confirm={(e) => handleWaiveCredentialTest(e, credentialTest)}
                            >
                              {
                                open =>
                                <ButtonBox
                                  className="Waive-Button"
                                  text="Waive"
                                  onClick={() => open()}
                                />
                              }
                            </Confirm>
                          }
                        </div>
                      </li>
                    ))
                  }
                </ol>
              </div>
            )
          }
        </DashboardFormBoxNoEdit>
      }

      {
        exists &&
        <DashboardFormBoxNoEdit hideTitle>
          <div className="Button-Container">
            <ButtonBox
              className="DashboardFormBox-Button_cancel DashboardFormBox-CancelBtn"
              text="Clear"
              onClick={(e) => handleClear(e)}
            />
          </div>
        </DashboardFormBoxNoEdit>
      }
    </div>
  )
};

ManageTests.propTypes = {
  credentialTests: PropTypes.object,
  clearCredentialTests: PropTypes.func.isRequired,
  fetchUserCredentialTests: PropTypes.func.isRequired,
  resetCredentialTest: PropTypes.func.isRequired,
  waiveCredentialTest: PropTypes.func.isRequired,
  checkExistingUser: PropTypes.func.isRequired,
};

export default ManageTests;
