import React from 'react';
import PropTypes from 'prop-types';

import CreateEditComment from './CreateEditComment';
import Modal from '../../Composed/Modal/Modal';
import Confirm from '../../Composed/Confirm/Confirm';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';

import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

const CommentDetail = ({
  commentableType, commentableId,
  parentCommentData, commentData,
  showEditBtn, showUnflagBtn, showReplyBtn, showDestroyBtn,
  showFlags, showReplies,
  unflagCommentHandler, destroyCommentHandler
}) => {
  const userImage = () => (
    isTEAdmin(commentData.user)
      ? 'favicon.ico'
      : commentData.user.image ? commentData.user.image : 'default_user.png'
  );

  const userName = () => (
    isTEAdmin(commentData.user)
      ? 'Canopy Admin'
      : `${commentData.user.first_name} ${commentData.user.last_name}`
  );

  const commentTime = (timestamp) => {
    let time = new Date(timestamp);

    return `${time.toLocaleString('default', { month: 'short' })} ${time.getDate()}, ${time.getFullYear()}`
  }

  const handleShowMoreLess = (e) => {
    let textElement = e.currentTarget.parentElement.previousElementSibling;

    if (textElement.classList.contains('expand')) {
      textElement.classList.remove('expand');
      textElement.style = 'max-height: 60px;';
      e.currentTarget.innerText = 'Full Comment';
    } else {
      textElement.classList.add('expand');
      textElement.style = 'max-height: ' + textElement.scrollHeight  + 'px;';
      e.currentTarget.innerText = 'Collapse';
    }
  }

  return (
    <div className="Comment-Container">
      <div className="Image">
        <ImageBox src={userImage()} />
      </div>

      <div className="Comment">
        <div className="Info">
          <div className="User">
            {userName()}
          </div>

          <div className="Time">
            {commentTime(commentData.comment.created_at)}
          </div>

          {
            showUnflagBtn &&
            <ButtonBox
              className="Btn-Small"
              text={<i className="fa fa-check"></i>}
              onClick={(e) => unflagCommentHandler(e, commentData.comment.id)}
            />
          }

          {
            showEditBtn &&
            <Modal
              title="Edit Comment"
              form={
                close =>
                <CreateEditComment
                  commentableType={commentableType}
                  commentableId={commentableId}
                  parentComment={parentCommentData?.comment}
                  comment={commentData.comment}
                  closeModal={close}
                />
              }
            >
              {
                open =>
                <ButtonBox
                  className="Btn-Small"
                  text={<i className="fa fa-pencil" />}
                  onClick={open}
                />
              }
            </Modal>
          }

          {
            showReplyBtn &&
            <Modal
              title="Reply Comment"
              form={
                close =>
                <CreateEditComment
                  commentableType={commentableType}
                  commentableId={commentableId}
                  parentComment={commentData.comment}
                  closeModal={close}
                />
              }
            >
              {
                open =>
                <ButtonBox
                  className="Btn-Small"
                  text={<i className="fa fa-reply" />}
                  onClick={open}
                />
              }
            </Modal>
          }

          {
            showDestroyBtn &&
            <Confirm
              text="Are you sure you want to archive this comment?"
              confirm={(e) => destroyCommentHandler(e, commentData.comment.id)}
            >
              {
                open =>
                <ButtonBox
                  className="Btn-Small"
                  text={<i className="fa fa-trash"></i>}
                  onClick={open}
                />
              }
            </Confirm>
          }
        </div>

        <div className="Text">
          {commentData.comment.text}
        </div>
        {
          commentData.comment.text.length >= 170 &&
          <div className="Show-More-Less-Btn">
            <button
              className="More-Less"
              onClick={(e) => handleShowMoreLess(e)}
            >
              Full Comment
            </button>
          </div>
        }

        {
          showFlags &&
          <div className="Flagger-Container">
            {
              commentData.flags.map((flag, idx) => (
                <table className="Flagger" key={`flagger-${idx}`}>
                  <tbody>
                    <tr className="User">
                      <td><strong>Flagged By:</strong></td>
                      <td>{`${flag.first_name} ${flag.last_name}`}</td>
                    </tr>
                    <tr className="Reason-Type">
                      <td><strong>Reason Type:</strong></td>
                      <td>{flag.reason_type}</td>
                    </tr>
                    <tr className="Reason">
                      <td><strong>Reason:</strong></td>
                      <td>{flag.reason}</td>
                    </tr>
                  </tbody>
                </table>
              ))
            }
          </div>
        }

        {
          showReplies &&
          ((commentData.replies || []).length !== 0) &&
          <div className="Replies">
            {
              commentData.replies.map((replyData, idx) => (
                <CommentDetail
                  key={`reply-${idx}`}
                  commentableType={commentableType}
                  commentableId={commentableId}
                  parentCommentData={commentData}
                  commentData={replyData}
                  showEditBtn={true}
                  showDestroyBtn={true}
                  destroyCommentHandler={(e, commentId) => destroyCommentHandler(e, commentId)}
                />
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}

CommentDetail.propTypes = {
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  parentCommentData: PropTypes.object,
  commentData: PropTypes.object.isRequired,
  showEditBtn: PropTypes.bool,
  showReplyBtn: PropTypes.bool,
  showUnflagBtn: PropTypes.bool,
  showDestroyBtn: PropTypes.bool,
  showFlags: PropTypes.bool,
  showReplies: PropTypes.bool,
  unflagCommentHandler: PropTypes.func,
  destroyCommentHandler: PropTypes.func
}

export default CommentDetail;
