import { connect } from 'react-redux';

import BatchProfileUpload from './BatchProfileUpload';

import { createBatchProfileUpload } from '../../../../Actions/BatchProfileUploadActions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createBatchProfileUpload: (batchProfileUpload) => dispatch(createBatchProfileUpload(batchProfileUpload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchProfileUpload);
