import { connect } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createComment, updateComment } from '../../../Actions/CommentActions';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';
import TextBox from '../../Basic/TextBox/TextBox';
import TextAreaBox from '../../Basic/TextAreaBox/TextAreaBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createComment: (criteria, comment) => dispatch(createComment(criteria, comment)),
  updateComment: (criteria, comment) => dispatch(updateComment(criteria, comment))
});

const CreateEditComment = ({ createComment, updateComment, commentableType, commentableId, parentComment, comment, closeModal }) => {
  const [commentAttrs, setCommentAttrs] = useState(comment ? { text: comment.text, is_sticky: comment.is_sticky } : { text: '', is_sticky: true });
  const [submitting, isSubmitting] = useState(false);

  const handleInputChange = (e, field) => {
    setCommentAttrs({ ...commentAttrs, [field]: e.target.value });
  }

  const handleCheckedBoxChange = (e, field) => {
    setCommentAttrs({ ...commentAttrs, [field]: e.target.checked });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let paramsCriteria = { action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId };
    let paramsComment  = { id: comment?.id, commentable_type: commentableType, commentable_id: commentableId, ...commentAttrs, parent_id: parentComment?.id };

    isSubmitting(true);
    comment
    ? updateComment(paramsCriteria, paramsComment).then(() => handleResponse())
    : createComment(paramsCriteria, paramsComment).then(() => handleResponse());
  };

  const handleResponse = () => {
    isSubmitting(false);
    closeModal();
  }

  return (
    <form className="CreationForms-Form" onSubmit={(e) => handleSubmit(e)}>
      {submitting && <MirrorLoader message="Submitting!" />}
      <div className="CreateEditLesson-Checkbox-container">
        <div className="CreateEditLesson-Checkbox">
          <CheckBox
            checked={commentAttrs.is_sticky}
            onChange={(e) => handleCheckedBoxChange(e, 'is_sticky')}
          />
        </div>

        <div className="Text">
          <TextBox text="Sticky" />
        </div>
      </div>

      <div className="CreationForms-InputBox">
        <TextAreaBox
          className="TextAreaBox-CreationForms"
          placeholder="Comment"
          value={commentAttrs.text}
          onChange={(e) => handleInputChange(e, 'text')}
        />
      </div>

      <div className="CreationForms-Button">
        <ButtonBox
          className="CreationDashboards-ButtonBox"
          text="Submit"
        />
      </div>
    </form>
  );
};

CreateEditComment.propTypes = {
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  parentComment: PropTypes.object,
  comment: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditComment);
