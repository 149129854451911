import {
  RECEIVE_B2B_ADMIN_LEARN_STATS,
  RECEIVE_B2B_ADMIN_LEARN_STAT_ERRORS,
  CLEAR_B2B_ADMIN_LEARN_STATS
} from '../../../Actions/Stats/B2BAdmin/LearnActions';

const LearnsReducer = (state = {}, action) => {
  Object.freeze(state)

  switch (action.type) {
    case RECEIVE_B2B_ADMIN_LEARN_STATS:
      return Object.assign({}, state, action.payload);
    case CLEAR_B2B_ADMIN_LEARN_STATS:
      return Object.assign({}, state, { [action.key]: {}, [`${action.key}Error`]: false });
    case RECEIVE_B2B_ADMIN_LEARN_STAT_ERRORS:
      return Object.assign({}, state, { [`${action.key}Error`]: true });
    default:
      return state;
  }
}

export default LearnsReducer;
