import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import EnvironmentUtil from '../../../Util/EnvironmentUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import ButtonBox from '../ButtonBox/ButtonBox';

import './AudioProgressBox.css';

const AudioProgressBox = ({ src, onEndHandler }) => {
  const buttonRef   = useRef(null);
  const progressRef = useRef(null);
  const audioRef    = useRef(null);
  const errorRef    = useRef(null);
  const filler      = useRef(null);
  const [srcPath, setSrcPath] = useState('');

  useEffect(() => {
    if (src && (typeof src === 'string')) {
      setSrcPath(EnvironmentUtil().buildURL(src));
    }

    return () => resetAudio();
  }, [src]);

  useEffect(() => {
    if ((typeof srcPath === 'string') && (srcPath.length > 0)) {
      fetch(srcPath).then(resp =>
        resp.blob()
      ).then(blob => {
        if (audioRef?.current) {
          audioRef.current.src = URL.createObjectURL(blob);
          audioRef.current.load();
        }
      });
    }
  }, [srcPath]);

  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (buttonRef.current) {
      buttonRef.current.setAttribute('class', 'fa fa-play');
    }
    if (progressRef.current) {
      progressRef.current.style.width = '0%';
    }

    clearInterval(filler.current);
  }

  const handleAudio = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (audioRef.current.paused) {
      audioRef.current.play();
      buttonRef.current.setAttribute('class', 'fa fa-pause');
      filler.current = setInterval(() => fillIt(), 100);
    } else {
      audioRef.current.pause();
      buttonRef.current.setAttribute('class', 'fa fa-play');
      clearInterval(filler.current);
    }
  }

  const fillIt = () => {
    if (!audioRef.current) {
      return null;
    }

    let percentage = Math.floor((audioRef.current.currentTime / audioRef.current.duration) * 100);
    progressRef.current.style['width'] = `${percentage}%`;

    if (percentage === 100) {
      clearInterval(filler.current);
      buttonRef.current.setAttribute('class', 'fa fa-redo');
    }
  }

  return (
    <div className="AudioProgressBox-Container">
      <ButtonBox
        className="AudioProgressBox-Button"
        text={<i className="fa fa-play" ref={buttonRef} />}
        onClick={(e) => handleAudio(e)}
      />

      <div className="AudioProgressBox-Progress">
        <div className="AudioProgressBox-ProgressFiller" ref={progressRef}></div>
      </div>

      <audio
        className="AudioProgressBox-Audio"
        ref={audioRef}
        onEnded={onEndHandler ? () => onEndHandler() : null}
        onPlay={() => gaEvent('instruction_audio_play', { src })}
      />

      <div className="AudioProgressBox-Error" ref={errorRef}></div>
    </div>
  );
};

AudioProgressBox.propTypes = {
  src: PropTypes.string.isRequired,
  onEndHandler: PropTypes.func
};

export default AudioProgressBox;
