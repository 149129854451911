import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

import { isStudent, isB2BBusiness } from '../../../Util/Helper/UserWorkplaceUtil';

import './CourseProgressImport.scss';

const CourseProgressImport = ({
  currentUser, currentWorkplace,
  course, courseSubscription, courseProgress,
  importProgress,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const targetWorkplace = currentUser.workplaces.filter(wkp => (
    wkp.institution_id === currentWorkplace.institution_id
    && wkp.id !== currentWorkplace.id
    && Object.keys(wkp.courses || {}).includes(course.id.toString())
  )).sort((a, b) => (
    Date.parse(a.license?.issuance_date) > Date.parse(b.license?.issuance_date) ? -1 : 1
  ))[0];

  let currentWorkplaceLicenseActive;
  if (isB2BBusiness(currentUser, currentWorkplace)) {
    currentWorkplaceLicenseActive = Date.parse(currentWorkplace.license?.expiration_date) > Date.now();
  } else {
    currentWorkplaceLicenseActive = (
      (Date.parse(currentWorkplace.license?.expiration_date) > Date.now())
      && (Date.parse(courseSubscription?.expires_at) > Date.now())
    )
  }

  const allConditionsSatisfiedToImport = () => (
    isStudent(currentUser)
    && targetWorkplace
    && currentWorkplaceLicenseActive
    && !courseProgress.completed_at
    && !['imported', 'declined'].includes(courseProgress.details.import_status)
  )

  const handleImportDeclineClick = (e, operation) => {
    e.preventDefault();

    setProcessing(operation);
    setSuccess(false);
    setError('');
    importProgress({ operation, course_id: course.id, target_workplace_id: targetWorkplace.id }).then(res => {
      if (res.error) {
        setError(res.error);
      } else {
        setTimeout(() => window.location.reload(), 3000);
        setSuccess(true);
      }
      setProcessing('');
    });
  }

  if (allConditionsSatisfiedToImport()) {
    return (
      <div className="CourseProgressImport-Container">
        <ButtonBox
          className="Import-Button Secondary-Btn"
          text={<span className="Secondary-Btn-Text">Import Progress</span>}
          onClick={() => setShowModal(true)}
        />

        {
          showModal &&
          <div className="modal">
            <div className="modal-content">
              <div className="Head">
                <h2 className="Text">Import Progress</h2>
                <ButtonBox
                  className="Close-Btn"
                  text={<ImageBox src="close.svg" alt="close" />}
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="Body">
                {processing && <MirrorLoader message={processing === 'import' ? 'Importing Progress!' : 'Processing!'} />}
                {error && <div className="Flash-Message Danger">{error}</div>}
                {
                  success ? (
                    <div className="Success">
                      <i className="fa fa-check-circle-o fa-5x Icon" />
                      <h3 className="Heading">Operation completed successfully!</h3>
                      <p className="Text">Refreshing the page...</p>
                    </div>
                  ) : (
                    <div className="Form">
                      <div className="Information">
                        <i className="fa fa-info-circle Icon" />
                        <span className="Text">
                          You have completed {targetWorkplace.courses[course.id].completed_lessons} lessons and attempted {targetWorkplace.courses[course.id].attempted_quizzes} quizzes in your previous institutional license.
                        </span>
                      </div>
                      <div className="Warning">
                        <i className="fa fa-exclamation-triangle Icon" />
                        <span className="Text">This action is not reversible, and your current progress will be overwritten.</span>
                      </div>
                      <hr className="Horizontal-Rule" />
                      <div className="Import-Action">
                        <span className="Text">I understand the consequences and want to import my progress!</span>
                        <ButtonBox
                          className="Btn Primary-Btn"
                          text={<span className="Primary-Btn-Text">Import Progress</span>}
                          onClick={e => handleImportDeclineClick(e, 'import')}
                        />
                      </div>
                      <hr className="Horizontal-Rule" />
                      <div className="Decline-Action">
                        <span className="Text">I don't want to import my progress and continue as is!</span>
                        <ButtonBox
                          className="Btn Secondary-Btn"
                          text={<span className="Secondary-Btn-Text">Decline Import</span>}
                          onClick={e => handleImportDeclineClick(e, 'decline')}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  return null;
}

CourseProgressImport.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentWorkplace: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  courseSubscription: PropTypes.object,
  courseProgress: PropTypes.object.isRequired,
  importProgress: PropTypes.func.isRequired,
}

export default CourseProgressImport;
