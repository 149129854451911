import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './EditLicense.scss';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

class CreateEditInstitution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.license.id,
            access_code: this.props.license.access_code,
            num_seats: String(this.props.license.num_seats),
            subscription_date: this.props.license.subscription_date.split('T')[0],
            expiration_date: this.props.license.expiration_date.split('T')[0],
            course_licenses: this.props.license.courses,
            course_packages_licenses: this.props.license.course_packages_licenses,
            license_type: this.props.license.license_type,
            license_domains_attributes: this.props.license.license_domains,
        };
    }

    componentDidMount() {
        const { clearLicenseFlash, fetchCourses, fetchCoursePackages } = this.props;
        clearLicenseFlash();
        fetchCourses();
        fetchCoursePackages();
    }

    handleInputChange = field => e => {
        if (field === 'access_code') {
            this.setState({ [field]: e.currentTarget.value.replace(/\s+/g, '') });
            return
        }

        this.setState({ [field]: e.currentTarget.value });
    }

    handleCoursePackageLicenseInput = (coursePackageId, field) => e => {
        const newState = [...this.state.course_packages_licenses];
        const mappedArray = this.state.course_packages_licenses.map(obj => obj.course_package_id);
        const index = mappedArray.indexOf(coursePackageId);
        newState[index][field] = e.currentTarget.value;
        this.setState({ course_packages_licenses: newState });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const license = {
            ...this.state,
        };

        this.props.editLicense(license);
    }

    handleCourseInclusions = () => {
        const { courses } = this.props;
        return Object.values(courses).map(
            course => (
                <label 
                    className="createLicenseContainer__checkbox"
                    key={course.id}
                >
                    {course.name}
                    <input
                        type="checkbox"
                        checked={this.state.course_licenses.includes(course.id)}
                        onClick={this.handleCheckCourses(course.id)}
                    />
                    <span className="checkmark" />
                </label>
            ),
        );
    };

    handleCoursePackageInclusions = () => {
        const { coursePackages } = this.props;
        return Object.values(coursePackages).map(
            coursePackage => {
                const index = this.state.course_packages_licenses.map(
                    obj => obj.course_package_id,
                ).indexOf(coursePackage.id);

                const isIncluded = index >= 0;

                return (
                    <div
                        key={coursePackage.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <label
                            className="createLicenseContainer__checkbox"
                        >
                            {coursePackage.name}
                            <input
                                type="checkbox"
                                checked={isIncluded}
                                onClick={this.handleCheckCoursePackages(coursePackage.id)}
                            />
                            <span className="checkmark" />
                        </label>

                        {
                            isIncluded
                                && (
                                    <>
                                        <InputBox
                                            className="createLicenseContainer__textinput__large"
                                            placeholder="Coupon"
                                            value={String(this.state.course_packages_licenses[index].coupon || '')}
                                            onChange={this.handleCoursePackageLicenseInput(coursePackage.id, 'coupon')}
                                            style={{ width: '150px' }}
                                        />
                                    </>
                                )
                        }
                    </div>
                );
            },
        );
    }

    handleCheckCourses = courseId => e => {
        const newState = [...this.state.course_licenses];
        if (this.state.course_licenses.includes(courseId)) {
            const index = newState.indexOf(courseId);
            newState.splice(index, 1);
            this.setState({ course_licenses: newState });
        } else {
            newState.push(courseId);
            this.setState({ course_licenses: newState });
        }
    };

    handleCheckCoursePackages = coursePackageId => e => {
        const newState = [...this.state.course_packages_licenses];
        const mappedArray = this.state.course_packages_licenses.map(obj => obj.course_package_id);
        if (mappedArray.includes(coursePackageId)) {
            const index = mappedArray.indexOf(coursePackageId);
            newState.splice(index, 1);
            this.setState({ course_packages_licenses: newState });
        } else {
            newState.push({
                course_package_id: coursePackageId,
                license_id: this.props.license.id,
                coupon: ''
            });
            this.setState({ course_packages_licenses: newState });
        }
    };

    handleLicenseDomainsInclusion = () => (
        <div className="License-Domains-Container mt-5">
            <p className="createLicenseContainer__header">Email Domains</p>
            <p className="createLicenseContainer__subheader">Enter email domains, to grant licensed resources to users with email addresses from these domains.</p>

            <button
                type="button"
                className="DashboardFormBox-SaveBtn DashboardFormBox-Button_save Add-Btn"
                onClick={(e) => this.handleAddLicenseDomain(e)}
            >
                Add Domain
            </button>

            {
                this.props.errors['license_domains.domain'] &&
                this.props.errors['license_domains.domain'].map((err, idx) => (
                    <div key={`domain-error-${idx}`} className="Error-Container">{`The domain ${err}`}</div>
                ))
            }

            {
                this.state.license_domains_attributes.map((license_domain, idx) => (
                    <div key={`license-domain-${idx}`} className={`License-Domain ${license_domain._destroy ? 'remove' : ''}`}>
                        <input
                            type="text"
                            className="createLicenseContainer__textinput__large Input"
                            value={license_domain.domain || ''}
                            placeholder="@example.com"
                            onChange={(e) => this.handleEditLicenseDomain(e, idx)}
                        />

                        <button
                            type="button"
                            className="ButtonBox-EditQuestions Remove-Btn"
                            onClick={(e) => this.removeLicenseDomain(e, idx)}
                        >
                            <i className="fa fa-trash" />
                        </button>
                    </div>
                ))
            }
        </div>
    )

    handleAddLicenseDomain = (e) => {
        e.preventDefault();

        const addLicenseDomains = this.state.license_domains_attributes;
        addLicenseDomains.push({});

        this.setState({ license_domains_attributes: addLicenseDomains });
    }

    handleEditLicenseDomain = (e, idx) => {
        const editLicenseDomains = this.state.license_domains_attributes;
        editLicenseDomains[idx]['domain'] = e.currentTarget.value;

        this.setState({ license_domains_attributes: editLicenseDomains });
    }

    removeLicenseDomain = (e, idx) => {
        e.preventDefault();

        const removeLicenseDomains = this.state.license_domains_attributes;
        removeLicenseDomains[idx]['_destroy'] = true;

        this.setState({ license_domains_attributes: removeLicenseDomains });
    }

    render() {
        const { flash, license } = this.props;

        return (
            <div className="AdminForm">
                <div className="createLicenseContainer">
                    <div className="createLicenseContainer__header">
                        EDIT LICENSE
                    </div>

                    <div className="createLicenseContainer__subheader">Enter the details of an license below</div>

                    {
                        flash.message
                        && (
                            <div className="createLicenseContainer__flash">
                                {flash.message}
                            </div>
                        )
                    }

                    <form
                        className="AdminForm-DataBox-form"
                        onSubmit={this.handleSubmit}
                    >
                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">INSTITUTION</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={license.institution.name}
                                disabled
                                style={{
                                    opacity: "70%",
                                }}
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">ACCESS CODE</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.access_code}
                                onChange={this.handleInputChange('access_code')}
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">NO. OF SEATS</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.num_seats}
                                onChange={this.handleInputChange('num_seats')}
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">LICENSE START DATE</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.subscription_date}
                                onChange={this.handleInputChange('subscription_date')}
                                type="date"
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">LICENSE END DATE</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.expiration_date}
                                onChange={this.handleInputChange('expiration_date')}
                                type="date"
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">LICENSE TYPE</p>
                            </div>

                            <select
                                className="createLicenseContainer__textinput__large"
                                value={this.state.license_type}
                                onChange={e => this.setState({ license_type: e.currentTarget.value })}
                            >
                                <option value="business">business</option>
                                <option value="individual">individual</option>
                            </select>
                        </div>

                        {this.handleLicenseDomainsInclusion()}

                        {
                            this.props.license.license_type === 'business'
                                && (
                                    <div className="Business-Pay-Container">
                                        <p className="createLicenseContainer__header" style={{ margin: '20px 0' }}>B2B LICENSE COURSES</p>
                                        { this.handleCourseInclusions() }
                                    </div>
                                )
                        }

                        {
                            this.props.license.license_type === 'individual'
                            && (
                                <div className="Individual-Pay-Container">
                                    <p className="createLicenseContainer__header" style={{ margin: '20px 0' }}>B2B LICENSE COURSE PACKAGES (Enter Coupons)</p>
                                    {this.handleCoursePackageInclusions()}
                                </div>
                            )
                        }

                        <ButtonBox
                            className="createLicenseContainer__button"
                            text="Update"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

CreateEditInstitution.propTypes = {
    courses: PropTypes.objectOf(PropTypes.obj).isRequired,
    coursePackages: PropTypes.objectOf(PropTypes.obj).isRequired,
    editLicense: PropTypes.func.isRequired,
    clearLicenseFlash: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    fetchCoursePackages: PropTypes.func.isRequired,
};

export default CreateEditInstitution;
