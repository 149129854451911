import { connect } from 'react-redux';
import QuizPageV2 from './QuizPageV2';
import {
    fetchQuiz,
    recordQuizAnswer,
    recordQuizState,
    submitQuizV2,
    clearQuizProgresses,
    clearQuizStates,
    clearQuizResults,
    fetchQuizResults
} from '../../../Actions/QuizActions';
import { clearSectionIndex, receiveSectionIndex, clearQuestionIndex, receiveQuestionIndex } from '../../../Actions/NavigationActions';
import { createBookmark, deleteBookmark } from '../../../Actions/BookmarkActions';

const mapStateToProps = (state, { match }) => ({
  currentUser: state.session.currentUser,
  courseId: match.params.courseId,
  course: state.entities.courses[match.params.courseId],
  courseProgresses: state.statistics.courseProgresses,
  lessonId: match.params.lessonId,
  lesson: state.entities.lessons[match.params.lessonId],
  lessons: state.entities.lessons,
  quizzes: state.entities.quizzes,
  quizStates: state.statistics.quizStates,
  quizProgress: state.statistics.quizProgresses[match.params.lessonId],
  bookmarks: state.statistics.bookmarks,
  quizResults: state.statistics.quizResults,
  navigationSectionIndex: state.navigation.currentSectionIndex,
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuiz: (lessonId) => dispatch(fetchQuiz(lessonId)),
  recordQuizState: (state) => dispatch(recordQuizState(state)),
  recordQuizAnswer: (answer) => dispatch(recordQuizAnswer(answer)),
  submitQuizV2: (data) => dispatch(submitQuizV2(data)),
  clearQuizStates: () => dispatch(clearQuizStates()),
  clearQuizProgresses: () => dispatch(clearQuizProgresses()),
  clearSectionIndex: () => dispatch(clearSectionIndex()),
  receiveSectionIndex: (index) => dispatch(receiveSectionIndex(index)),
  clearQuestionIndex: () => dispatch(clearQuestionIndex()),
  receiveQuestionIndex: (index) => dispatch(receiveQuestionIndex(index)),
  createBookmark: (bookmark) => dispatch(createBookmark(bookmark)),
  deleteBookmark: (bookmark) => dispatch(deleteBookmark(bookmark)),
  clearQuizResults: () => dispatch(clearQuizResults()),
  fetchQuizResults: () => dispatch(fetchQuizResults())
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizPageV2);
