import './VideoBox.css';

import PropTypes from 'prop-types';
import React from 'react';

import EnvironmentUtil from '../../../Util/EnvironmentUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

/**
* @function - VideoBox - functional
* @{props} - {Object}
  * className
  * src
* @return { JSX Object }
*/
const VideoBox = ({ className, src, ...otherProps }) => {
    // propTypes for the component
    VideoBox.propTypes = {
        className: PropTypes.string,
        src: PropTypes.string.isRequired,
    };

    return (
        <video
            className={className}
            controls
            onContextMenu={(e) => e.preventDefault()}
            controlsList="nodownload"
            onPlay={() => gaEvent('video_start', { video_url: src })}
            { ...otherProps }
        >
            <source
                src={EnvironmentUtil().buildURL(src)}
                type="video/mp4"
            >
            </source>
        </video>
    );
};

export default VideoBox;
