import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/SubscriptionApiUtil';

export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION';
export const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTION_ERRORS = 'RECEIVE_SUBSCRIPTION_ERRORS';

export const receiveSubscriptions = payload => ({
    type: RECEIVE_SUBSCRIPTIONS,
    payload,
});

export const receiveSubscription = payload => ({
    type: RECEIVE_SUBSCRIPTION,
    payload,
});

export const clearSubscriptions = () => ({
    type: CLEAR_SUBSCRIPTIONS,
});

export const receiveErrors = errors => ({
    type: RECEIVE_SUBSCRIPTION_ERRORS,
    errors,
});

export const fetchSubscriptions = () => dispatch => (
    APIUtil.fetchSubscriptions().then(
        payload => dispatch(receiveSubscriptions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
