import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { authResetPassword } from '../../../Util/SessionApiUtil';

import './passwordPage.scss';

const PasswordPage = () => {
  const [email, setEmail] = useState('');
  const [submitting, isSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess('');
    setError('');

    if (email.length === 0) {
      setError('Please provide an email address.')
      return null;
    }

    isSubmitting(true);
    authResetPassword(email).then(res => {
      setSuccess(res.message);
    }).catch(err => {
      isSubmitting(false);
      setError(err.response.data);
    });
  };

  const renderSuccess = () => (
    <div className="Success">
      <i className="fa fa-check" />
      <p>{success}</p>
    </div>
  )

  const renderForm = () => (
    <>
      <p className="Subheading">
        Enter your email address for intructions on resetting your password.
      </p>

      <form className="Form">
        {
          error &&
          <div className="Danger">{error}</div>
        }

        <input
          className="Input"
          type="text"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
        />

        <button
          className="Submit"
          onClick={(e) => handleSubmit(e)}
          disabled={submitting}
        >
          Reset Password
        </button>
      </form>
    </>
  )

  return (
    <div className="AuthPage bg-grey Password">
      <div className="Password-Container container">
        <div className="Heading">
          <img src="https://prod-canopy-railsapi-media-bucket.s3.amazonaws.com/canopy-c.png" />
          <p>Reset Password</p>
        </div>

        {
          success ? renderSuccess() : renderForm()
        }
      </div>
    </div>
  );
};

PasswordPage.propTypes = {};

export default PasswordPage;
