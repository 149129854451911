import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import CryptoJS from 'crypto-js';

Font.register({ family: 'Montserrat', src: "http://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf" });

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
    },
    section: {
    },
    boldGreen: {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 30,
        color: '#7ecfaf',
    },
    boldBlack: {
        marginTop: 10,
        fontSize: 30,
        color: 'black',
    },
    descriptionText: {
        fontSize: 10,
        width: 230,
    },
    dateText: {
        fontSize: 10,
        width: 230,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 'auto',
        borderStyle: 'solid',
        borderColor: '#7ecfaf',
        borderWidth: 3,
        backgroundColor: '#ffffff',
        height: '98%',
        width: '98%',
    },
    InnerContainer: {
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    image: {
        width: 140,
        height: 20,
    },
    canopyLogo: {
        width: 240,
        height: 100,
    },
});

// Create Document Component
const Certificate = ({ currentUser }) => {
    const bytes = CryptoJS.AES.decrypt(window.location.toString().split("download/")[window.location.toString().split("download/").length - 1], currentUser.id.toString());
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    let date = new Date();
    date = date.toLocaleDateString();

    return (
        <PDFViewer width="100%" height={window.innerHeight - 10}>
            <Document>
                <Page size="a5" orientation="landscape" scale={100} style={styles.page}>
                    <View style={styles.container}>
                        <View>
                            <Text>This Certificate of Achievement Recognizes</Text>
                            <Text style={styles.boldGreen}>{`${currentUser.first_name} ${currentUser.last_name}`}</Text>
                            <Text>For Completing the Program</Text>
                            <Text style={styles.boldBlack}>{originalText}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Certificate;
