import axios from './AxiosUtil';

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};

export const signup = user => {
    return axios.post("/api/users", { user }).then(
        res => res.data
    );
};

export const authSignup = user => {
    return axios.post("/api/users/auth_signup", { user }).then(
        res => res.data
    )
};

export const login = user => {
    return axios.post("/api/users/sign_in", { user }).then(
        res => {
            const token = res.headers.authorization;
            sessionStorage.setItem('jwtToken', token);
            setAuthToken(token);
            return res.data;
        },
    );
};

export const confirmEmail = query => {
    return axios.get(`/api/users/confirmation?confirmation_token=${query}`).then(
        res => {
            const token = res.headers.authorization;
            sessionStorage.setItem('jwtToken', token);
            setAuthToken(token);
            return res.data;
        },
    );
};

export const logout = async () => {
    try {
        await axios.post('/api/user_sessions', {
            "user_session": {
                "user_id": 2,
                "session_time": Math.floor((Math.abs(Date.now() - JSON.parse(window.localStorage.getItem('startTime'))) * 0.001) / 60),
                "session_date": window.localStorage.getItem('startDate'),
            },
        });
        await axios.delete('/api/users/sign_out');
        sessionStorage.removeItem('jwtToken');
        setAuthToken();
    } catch (errors) {
        await axios.delete('/api/users/sign_out');
        sessionStorage.removeItem('jwtToken');
        setAuthToken();
    }
};

export const updatePhoto = (formData) => {
    return axios({
        method: "PATCH",
        url: `/api/users/${formData.get("user[id]")}`,
        processData: false,
        contentType: false,
        dataType: 'json',
        data: formData,
    }).then(
        res => res.data,
    );
};

export const updateAccount = (user) => {
    return axios.patch(`/api/users/${user.id}`, { user }).then(
        res => res.data,
    );
};

// update password from within the site
export const updatePassword = user => {
    return axios.patch('/api/users/update_password', { user }).then(
        res => res.data,
    );
};

export const syncCurrentUser = () => (
    axios.get(`/api/users/current/user`).then(
        res => res.data,
    )
);

export const resendConfirmationEmail = () => (
    axios.get(`/api/uses/resend_confirmation_email`).then(
        res => res.data,
    )
);

// request a change to password when forgotten
export const resetPassword = user => (
    axios.post('/api/users/password', { user }).then(
        res => res.data,
    )
);

export const authResetPassword = email => (
    axios.post('/api/users/auth_reset_password', { email }).then(
        res => res.data
    )
);

// reset a password following a link
export const setNewPassword = user => (
    axios.put('/api/users/password', { user }).then(
        res => {
            const token = res.headers.authorization;
            sessionStorage.setItem('jwtToken', token);
            setAuthToken(token);
            return res.data;
        },
    )
);

// request to unlock account using token from email link
export const unlockAccount = token => (
    axios.get(`/api/users/unlock?unlock_token=${token}`).then(
        res => res.data,
    )
);
