import axios from './AxiosUtil';

export const fetchRecurringSubscriptions = () => {
  return axios.get(`/api/recurring_subscriptions`).then(
    res => res.data,
  );
};

export const cancelRecurringSubscription = (recurringSubscriptionId) => {
  return axios.patch(`/api/recurring_subscriptions/${recurringSubscriptionId}/cancel`).then(
    res => res.data,
  );
};
