import {
  RECEIVE_COURSE_TYPES,
  CLEAR_COURSE_TYPES,
} from '../../Actions/CourseTypeActions';

const CourseTypesReducer = (state = {}, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_COURSE_TYPES:
      return Object.assign({}, state, action.payload.course_types);
    case CLEAR_COURSE_TYPES:
      return {};
    default:
      return state;
  }
}

export default CourseTypesReducer;
