import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../Composed/Loader/Loader';
import { fetchUserPerformance } from '../../../../Actions/Stats/B2BAdmin/SpeakActions';
import { hashToQueryString } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  speaks: (state.stats.b2bAdmin.speaks || {}),
  filters: (state.filters || {})
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserPerformance: (queryString) => dispatch(fetchUserPerformance(queryString))
});

const UserPerformance = ({ speaks, filters, fetchUserPerformance }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserPerformance(hashToQueryString(filters)).then(() => setLoading(false));
  }, [filters]);

  const speakingSkills = (speaks.skillMetrics || {}).scores || [];
  const performanceSummary = speaks.workflowsMetrics || {};

  const variationCard = (score, prevScore) => {
    const variation = Math.round(score) - Math.round(prevScore);

    if (variation === 0) return null;

    return (
      <div className="User-Performance-Card-Variation">
        <div className={'Variation ' + (variation < 0 ? 'Variation-Negative' : 'Variation-Positive')}>{variation > 0 ? '+' : ''}{variation}%</div>
        <div className="Card-Variation-Label">From yesterday</div>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) return <Loader/>;

    return (
      <div className="User-Performance-Container">
        <div className="Content Dynamic-Width User-Performance-Summary">
          <div className="User-Performance-Card">
            <h3 className="User-Performance-Card-Title">Initial Assessment</h3>
            {variationCard(performanceSummary.initialAssessmentScore, performanceSummary.prevInitialAssessmentScore)}
            <div className="User-Performance-Content">
              <div className="User-Performance-Value">
                <p className="Value">{Math.round(performanceSummary.initialAssessmentScore)}%</p>
                <p className="Label">Correct</p>
              </div>
              <div className="User-Performance-Value">
                <p className="Value">{100 - Math.round(performanceSummary.initialAssessmentScore)}%</p>
                <p className="Label">Incorrect</p>
              </div>
            </div>
          </div>
          <div className="User-Performance-Card">
            <h3 className="User-Performance-Card-Title">Flashcard</h3>
            {variationCard(performanceSummary.flashCardsScore, performanceSummary.prevFlashCardsScore)}
            <div className="User-Performance-Content">
              <div className="User-Performance-Value">
                <p className="Value">{Math.round(performanceSummary.flashCardsScore)}%</p>
                <p className="Label">I Got This</p>
              </div>
              <div className="User-Performance-Value">
                <p className="Value">{100 - Math.round(performanceSummary.flashCardsScore)}%</p>
                <p className="Label">Keep Reviewing</p>
              </div>
            </div>
          </div>
          <div className="User-Performance-Card">
            <h3 className="User-Performance-Card-Title">Multiple Choice</h3>
            {variationCard(performanceSummary.multipleChoicesScore, performanceSummary.prevMultipleChoicesScore)}
            <div className="User-Performance-Content">
              <div className="User-Performance-Value">
                <p className="Value">{Math.round(performanceSummary.multipleChoicesScore)}%</p>
                <p className="Label">Correct</p>
              </div>
              <div className="User-Performance-Value">
                <p className="Value">{100 - Math.round(performanceSummary.multipleChoicesScore)}%</p>
                <p className="Label">Incorrect</p>
              </div>
            </div>
          </div>
          <div className="User-Performance-Card">
            <h3 className="User-Performance-Card-Title">Countdown Challenge</h3>
            {variationCard(performanceSummary.countdownScore, performanceSummary.prevCountdownScore)}
            <div className="User-Performance-Content">
              <div className="User-Performance-Value">
                <p className="Value">{Math.round(performanceSummary.countdownScore)}%</p>
                <p className="Label">Correct</p>
              </div>
              <div className="User-Performance-Value">
                <p className="Value">{100 - Math.round(performanceSummary.countdownScore)}%</p>
                <p className="Label">Incorrect</p>
              </div>
            </div>
          </div>
          <div className="User-Performance-Card">
            <h3 className="User-Performance-Card-Title">Speaking Practice</h3>
            {variationCard(performanceSummary.speakingScore, performanceSummary.prevSpeakingScore)}
            <div className="User-Performance-Content">
              <div className="User-Performance-Value">
                <p className="Value">{Math.round(performanceSummary.speakingScore)}%</p>
                <p className="Label">Speaking Score</p>
              </div>
            </div>
          </div>
        </div>

        <div className="Content Dynamic-Width">
          <div className="User-Performance-Speaking-Skills Table-Container">
            <table className="Table">
              <thead>
                <tr>
                  <th>Speaking Skills</th>
                  <th>Playlists Displayed</th>
                  <th>Workflows Completed</th>
                  <th>Skill Performance Score</th>
                </tr>
              </thead>
              <tbody>
              {
                speakingSkills.map((skill, index) => (
                  <tr key={index}>
                    <td>
                        <span>
                          {skill.skill}
                        </span>
                    </td>
                    <td>
                        <span>
                          {skill.displayed}
                        </span>
                    </td>
                    <td>
                        <span>
                          {skill.learned}
                        </span>
                    </td>
                    <td>
                        <span className={`${skill.score < 70 ? 'Fail' : 'Pass'} `}>
                          {skill.score}%
                        </span>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="User-Performance">
      <h2 className="Title">User Performance</h2>
      {renderContent()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPerformance);
