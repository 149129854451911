import {
    RECEIVE_SUBSCRIPTIONS,
    RECEIVE_SUBSCRIPTION,
    CLEAR_SUBSCRIPTIONS,
} from '../../Actions/SubscriptionActions';

export default (state = {}, action) => {
    Object.freeze(state);

    switch (action.type) {
        case RECEIVE_SUBSCRIPTIONS:
            return { ...state, ...action.payload };
        case RECEIVE_SUBSCRIPTION:
            return { ...state, ...action.payload };
        case CLEAR_SUBSCRIPTIONS:
            return {};
        default:
            return state;
    }
};
