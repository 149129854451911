import {
  RECEIVE_COURSE,
  CLEAR_COURSE_ERRORS,
  RECEIVE_COURSE_ERRORS,
} from '../../Actions/CourseActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_COURSE:
      if (action.message) {
        return { message: action.message }
      }
      return state;
    case RECEIVE_COURSE_ERRORS:
      return {};
    case CLEAR_COURSE_ERRORS:
      return {};
    default:
      return state;
  }
};