import { connect } from 'react-redux';

import LicenseManagement from './LicenseManagement';

import { clearInstitutions, fetchInstitutions } from '../../../Actions/InstitutionActions';
import { deactivateUsers, migrateUsers } from '../../../Actions/LicenseActions';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  institutions: state.admin.institutions || {},
});

const mapDispatchToProps = dispatch => ({
  clearInstitutions: () => dispatch(clearInstitutions()),
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  deactivateUsers: formData => dispatch(deactivateUsers(formData)),
  migrateUsers: formData => dispatch(migrateUsers(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseManagement);
