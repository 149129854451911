import React from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import './InformationModal.scss';

const InformationModal = ({
  title,
  descriptions = [],
  instructions = [],
  actionText = 'Close',
  actionDisable = false,
  actionCallBack,
}) => {
  return(
    <div className="Information-Modal">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="Title">
              {title}
            </div>
          </div>

          <div className="modal-body">
            {
              descriptions.length > 0 &&
              <div className="Descriptions-Container">
                {
                  descriptions.map((description, idx) => (
                    <div key={`description-${idx}`} className="Description" children={description} />
                  ))
                }
              </div>
            }

            {
              instructions.length > 0 &&
              <div className="Instructions-Container">
                <ol className="Instructions list-decimal pl-10">
                  {
                    instructions.map((instruction, idx) => (
                      <li key={`instruction-${idx}`} className="Instruction" children={instruction} />
                    ))
                  }
                </ol>
              </div>
            }
          </div>

          <div className="modal-footer">
            <ButtonBox
              className="Secondary-Btn Action-Button"
              text={<span className="Secondary-Btn-Text">{actionText}</span>}
              disabled={actionDisable}
              onClick={() => actionCallBack()}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

InformationModal.propTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.array,
  instructions: PropTypes.array,
  actionText: PropTypes.string,
  actionDisable: PropTypes.bool,
  actionCallBack: PropTypes.func.isRequired,
}

export default InformationModal;
