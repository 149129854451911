import {
  RECEIVE_COURSE_ERRORS,
  RECEIVE_COURSES,
  RECEIVE_COURSE,
  CLEAR_COURSE_ERRORS,
} from '../../Actions/CourseActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_COURSE_ERRORS:
      return action.errors;
    case RECEIVE_COURSES:
      return {};
    case RECEIVE_COURSE:
      return {};
    case CLEAR_COURSE_ERRORS:
      return {};
    default:
      return state;
  }
};