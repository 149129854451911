import { merge } from 'lodash';

import {
    RECEIVE_TOPICS,
    RECEIVE_TOPIC,
    CLEAR_TOPICS,
    RECEIVE_ONLY_TOPICS,
    RECEIVE_TOPIC_DELETE_SUCCESS,
    RECEIVE_TOPIC_POSITIONS,
} from '../../Actions/TopicActions';
import { RECEIVE_SECTION } from '../../Actions/SectionActions';
import { RECEIVE_QUESTION } from '../../Actions/QuestionActions';
import { RECEIVE_ANSWER } from '../../Actions/AnswerActions';
import { RECEIVE_TEXT_CONTENT } from '../../Actions/TextContentActions';
import { RECEIVE_FLASHCARD } from '../../Actions/FlashcardActions';
import { RECEIVE_TABLE } from '../../Actions/TableActions';
import { RECEIVE_TABLE_ENTRY } from '../../Actions/TableEntryActions';

const SectionReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_TOPICS:
            return Object.assign({}, action.payload.topics);
        case RECEIVE_ONLY_TOPICS:
            return merge({}, state, action.payload.topics);
        case CLEAR_TOPICS:
            return {};
        case RECEIVE_TOPIC:
            return Object.assign({}, state, action.payload.topics);
        case RECEIVE_TOPIC_DELETE_SUCCESS:
            return Object.assign({}, action.payload.siblings);
        case RECEIVE_TOPIC_POSITIONS:
            return Object.assign({}, action.payload.topics, action.payload.siblings);
        case RECEIVE_SECTION:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                    [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_QUESTION:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_ANSWER:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_TEXT_CONTENT:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_FLASHCARD:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_TABLE:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        case RECEIVE_TABLE_ENTRY:
            if (action.payload.sectionable_type === 'Topic') {
                return Object.assign({}, {
                [action.payload.topic.id]: action.payload.topic
                });
            }
            return state;
        default:
            return state;
    }
};

export default SectionReducer;
