import axios from './AxiosUtil';

export const submitQuiz = (data) => {
    return axios.post('/api/quiz_results', { quiz_result: data }).then(
        res => res.data,
    );
};

export const submitQuizV2 = (data) => {
    return axios.post('/api/quiz_results/create_v2', { quiz_result: data }).then(
        res => res.data,
    );
};

export const createQuiz = (quiz) => {
    return axios.post('/api/quizzes', { quiz }).then(
        res => res.data,
    );
};

export const editQuiz = (quiz) => {
    return axios.patch(`/api/quizzes/${quiz.id}`, { quiz }).then(
        res => res.data,
    );
};

export const deleteQuiz = (quizId) => {
    return axios.delete(`/api/quizzes/${quizId}`).then(
        res => res.data,
    );
};

export const restoreQuiz = (quizId) => {
    return axios.put(`/api/quizzes/${quizId}/restore`).then(
        res => res.data,
    )
}

export const fetchQuiz = (lessonId) => {
    return axios.get(`/api/lessons/${lessonId}/quiz`).then(
        res => res.data,
    );
};

export const fetchQuizResults = () => {
    return axios.get('/api/quiz_results').then(
        res => res.data,
    );
};

export const cloneQuiz = (targetLessonId, quizId) => {
    return axios.post(`/api/quizzes/${quizId}/clone`, { target_lesson_id: targetLessonId }).then(
        res => res.data,
    );
};
