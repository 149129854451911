import axios from './AxiosUtil';

export const createBatchProfileUpload = (batchProfileUpload) => {
  return axios({
    method: 'POST',
    url: '/api/batch_profile_uploads',
    processData: false,
    contentType: false,
    dataType: 'json',
    data: batchProfileUpload,
  }).then(
    (res) => res.data
  );
}
