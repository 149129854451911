import * as APIUtil from '../Util/ProfileCompletionApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_PROFILE_COMPLETION = 'RECEIVE_PROFILE_COMPLETION';
export const RECEIVE_PROFILE_COMPLETION_ERRORS = 'RECEIVE_PROFILE_COMPLETION_ERRORS';

export const receiveProfileCompletion = payload => ({
  type: RECEIVE_PROFILE_COMPLETION,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_PROFILE_COMPLETION_ERRORS,
  errors
});

export const updateProfileCompletion = (profileCompletion) => dispatch => (
  APIUtil.updateProfileCompletion(profileCompletion).then(
    payload => dispatch(receiveProfileCompletion(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
