import { connect } from 'react-redux';

import GrantAccess from './GrantAccess';

import { clearInventories, fetchInventories, grantAccess, checkExistingUser } from '../../../../../Actions/InventoryActions';

const mapStateToProps = (state) => ({
  inventories: state.entities.inventories || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearInventories: () => dispatch(clearInventories()),
  fetchInventories: () => dispatch(fetchInventories()),
  grantAccess: (userId, inventoryId) => dispatch(grantAccess(userId, inventoryId)),
  checkExistingUser: (user) => dispatch(checkExistingUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrantAccess);
