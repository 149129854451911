import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import './CreateEditInstitution.css';
import './ChipsOveride.scss';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';
import TextAreaBox from '../../Basic/TextAreaBox/TextAreaBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import Confirm from '../../Composed/Confirm/Confirm';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import AutoCompleteCreatable from '../../Composed/AutoCompleteCreatable/AutoCompleteCreatable';

const IP_PATTERN = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const DOMAIN_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;

class CreateEditInstitution extends Component {
    _nullState = {
        name: '',
        app_description: '',
        terms_of_service: '',
        platform_support_email: '',
        platform_support_phone: '',
        content_support_email: '',
        content_support_phone: '',
        whitelist_ip: [],
        whitelist_domains: [],
        processing: false,
    }

    _loadedState = {
        id: this.props.institution?.id,
        name: this.props.institution?.name,
        app_description: this.props.institution?.app_description,
        terms_of_service: this.props.institution?.terms_of_service,
        platform_support_email: this.props.institution?.platform_support_email,
        platform_support_phone: this.props.institution?.platform_support_phone,
        content_support_email: this.props.institution?.content_support_email,
        content_support_phone: this.props.institution?.content_support_phone,
        processing: false,
        whitelist_ip: this.props.institution?.whitelist_ip
            ? this.props.institution.whitelist_ip.map((ip) => ({ value: ip, label: ip }))
            : [],
        whitelist_domains: this.props.institution?.whitelist_domains 
            ? this.props.institution.whitelist_domains.map((domain) => ({ value: domain, label: domain }))
            : [],
    }

    state = this.props.institution ? this._loadedState : this._nullState;

    componentDidMount() {
        const { clearInstitutionErrors } = this.props;
        clearInstitutionErrors();
    }

    handleInputChange = field => e => this.setState({
        [field]: e.currentTarget.value,
    });

    handleSubmit = (e) => {
        e.preventDefault();
        const institution = {
            ...this.state,
        };
        institution.name = institution.name.trim();
        institution.whitelist_ip = institution.whitelist_ip.map(chip => chip.value);
        institution.whitelist_domains = institution.whitelist_domains.map(chip => chip.value);

        this.setState({ processing: true });
        if (this.props.institution) {
            this.props.editInstitution(institution).then(
                () => this.setState(this._loadedState),
            );
        } else {
            this.props.createInstitution(institution).then(
                () => this.setState(this._nullState),
            );
        }
    }

    handleDeleteInstitution = institutionId => e => {
        e.preventDefault();
        this.setState({ processing: true });
        this.props.deleteInstitution(institutionId).then(
            () => {
                this.setState({ processing: false });
                this.props.history.push("/account/institutions");
            },
        );
    }

    render() {
        const { errors, flash, institution } = this.props;

        return (
            <div className="AdminForm">
                {this.state.processing && <MirrorLoader message="Processing..." />}
                <div className="createLicenseContainer">
                    <div className="createLicenseContainer__header">
                        {
                            institution
                                ? 'EDIT INSTITUTION'
                                : 'CREATE INSTITUTION'
                        }
                    </div>
                    <div className="createLicenseContainer__subheader">Enter the details of an institution below</div>

                    <ErrorFlashBox
                        errors={errors}
                        flash={flash}
                    />

                    <form
                        className="AdminForm-DataBox-form"
                        onSubmit={this.handleSubmit}
                    >
                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">NAME</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.name}
                                onChange={this.handleInputChange('name')}
                            />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">APP DESCRIPTION</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.app_description}
                                onChange={this.handleInputChange('app_description')} />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">PLATFORM SUPPORT EMAIL</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.platform_support_email}
                                onChange={this.handleInputChange('platform_support_email')} />
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">CONTENT SUPPORT EMAIL</p>
                            </div>

                            <InputBox
                                className="createLicenseContainer__textinput__large"
                                value={this.state.content_support_email}
                                onChange={this.handleInputChange('content_support_email')} />
                        </div>

                        <div className="AdminForm-DataBox-splitdiv">
                            <div className="AdminForm-DataBox-InputBox">
                                <div className="createLicenseContainer__label-row">
                                    <p className="createLicenseContainer__label">PLATFORM SUPPORT PHONE</p>
                                </div>

                                <div style={{ margin: '5px 0' }}>
                                    <PhoneInput
                                        country="US"
                                        value={this.state.platform_support_phone}
                                        onChange={
                                            platform_support_phone => this.setState({ platform_support_phone })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="AdminForm-DataBox-InputBox">
                                <div className="createLicenseContainer__label-row">
                                    <p className="createLicenseContainer__label">CONTENT SUPPORT PHONE</p>
                                </div>

                                <div style={{ margin: '5px 0 20px 0' }}>
                                    <PhoneInput
                                        country="US"
                                        value={this.state.content_support_phone}
                                        onChange={
                                            content_support_phone => this.setState({ content_support_phone })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="AdminForm-DataBox-InputBox">
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">TERMS OF SERVICE</p>
                            </div>

                            <TextAreaBox
                                className="TextAreaBox-SignUp"
                                value={this.state.terms_of_service}
                                onChange={this.handleInputChange('terms_of_service')}
                            />
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">WHITELIST IPS</p>
                            </div>
                            <AutoCompleteCreatable
                                value={this.state.whitelist_ip}
                                placeholder="Add an IP address..."
                                onChange={data => this.setState({ whitelist_ip: data })}
                                isClearable
                                isMulti
                            />
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <div className="createLicenseContainer__label-row">
                                <p className="createLicenseContainer__label">WHITELIST DOMAINS</p>
                            </div>
                            <AutoCompleteCreatable
                                value={this.state.whitelist_domains}
                                placeholder="Add a domain..."
                                onChange={data => this.setState({ whitelist_domains: data })}
                                isClearable
                                isMulti
                            />
                        </div>

                        <ButtonBox
                            className="createLicenseContainer__button"
                            text={this.props.institution ? "Update" : "Create"}
                        />
                    </form>

                    {
                        this.props.institution 
                            && (
                                <Confirm
                                    text="Are you sure you want to delete this institution?"
                                    confirm={this.handleDeleteInstitution(this.props.institution.id)}
                                >
                                    {
                                        open =>
                                            <ButtonBox
                                                className="createLicenseContainer__button red"
                                                text="Delete"
                                                onClick={open}
                                            />
                                    }
                                </Confirm>
                            )
                    }
                </div>
            </div>
        );
    }
}

export default CreateEditInstitution;
