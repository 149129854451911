import React from 'react';
import { Switch } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import { ProtectedRoute } from '../../../Util/RouteUtil';
import UserDashboardCoursePackages from './UserDashboardCoursePackages';
import UserDashboardPurchasePackage from './UserDashboardPurchasePackage';
import UserDashboardRenew from './UserDashboardRenew';
import Home from './Home/Home';

const UserDashboardLearn = ({ currentUser, currentWorkplace, hasSubscriptions, userDashboardReady }) => {
    return (
        <div className="UserDashboard">
            <Switch>
                <ProtectedRoute
                    path="/account/learn/course_packages/:coursePackageId"
                    component={props =>
                        <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => <UserDashboardPurchasePackage stripe={stripe} elements={elements} {...props} />}
                            </ElementsConsumer>
                        </Elements>
                    }
                />

                <ProtectedRoute
                    path="/account/learn/course_packages"
                    component={UserDashboardCoursePackages}
                />

                <ProtectedRoute
                    path="/account/learn/renew"
                    component={UserDashboardRenew}
                />

                <ProtectedRoute
                    path="/account/learn"
                    component={() => <Home userDashboardReady={userDashboardReady} />}
                />
            </Switch>
        </div>
    );
};

export default UserDashboardLearn;
