import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './EditCourseType.css';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import RadioBox from '../../Basic/RadioBox/RadioBox';

const EditCourseType = ({ courseType, updateCourseType, closeModal }) => {
  const [settings, setSettings] = useState(courseType.settings);

  const handleInputChange = (e, field) => {
    setSettings({ ...settings, [field]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    updateCourseType({
      id: courseType.id,
      settings: settings
    }).then(
      res => closeModal()
    );
  }

  return (
    <form
      className="CreationForms-Form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="CreationForms-InputBox">
        <InputBox
          className="InputBox-LogInBox"
          value={settings.logo_text}
          placeholder="Logo Text"
          onChange={(e) => handleInputChange(e, 'logo_text')}
        />
      </div>

      <div className="CreationForms-InputBox">
        <InputBox
          className="InputBox-LogInBox"
          value={settings.logo_color}
          placeholder="Logo Color"
          onChange={(e) => handleInputChange(e, 'logo_color')}
        />
      </div>

      <div className="CreationForms-RadioBox">
        <div>Logo Color Type</div>
        <RadioBox
          name="logo_color_type"
          text="Simple"
          value="simple"
          checked={settings.logo_color_type === 'simple'}
          onChange={(e) => handleInputChange(e, 'logo_color_type')}
        />
        <RadioBox
          name="logo_color_type"
          text="Gradient"
          value="gradient"
          checked={settings.logo_color_type === 'gradient'}
          onChange={(e) => handleInputChange(e, 'logo_color_type')}
        />
      </div>

      <div className="CreationForms-InputBox">
        <InputBox
          className="InputBox-LogInBox"
          value={settings.header_background}
          placeholder="Header Background"
          onChange={(e) => handleInputChange(e, 'header_background')}
        />
      </div>

      <div className="CreationForms-Button">
        <ButtonBox
          className="CreationDashboards-ButtonBox"
          text="Submit"
        />
      </div>
    </form>
  );
};

EditCourseType.propTypes = {
  courseType: PropTypes.object.isRequired,
  updateCourseType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default EditCourseType;
