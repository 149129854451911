import React from 'react';
import PropTypes from 'prop-types';

import Invitations from './Invitations';

const InvitationBatches = ({ invitationBatches }) => {
  const renderEmpty = () => (
    <div className="Empty-Invitations">
      No invitations have been sent!
    </div>
  );

  const renderInvitations = () => (
    Object.values(invitationBatches)
          .sort((a, b) => a.id < b.id ? 1 : -1)
          .map((invitationBatch, idx) => (
      <Invitations key={idx} invitationBatch={invitationBatch} />
    ))
  );

  if (Object.keys(invitationBatches).length === 0) {
    return renderEmpty();
  }

  return renderInvitations();
}

InvitationBatches.propTypes = {
  invitationBatches: PropTypes.object.isRequired
}

export default InvitationBatches;
