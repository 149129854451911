import React from 'react';
import { MoonLoader } from 'react-spinners';

import './Loader.scss';

const MirrorLoader = ({ message }) => (
  <div className="Mirror-Loader">
    <div className="Loader">
      <div className="Message">{message}</div>
      <MoonLoader color="#fff" />
    </div>
  </div>
)

export default MirrorLoader;
