import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import './SessionExpired.scss'

const SessionExpired = () => {
  const totalTime = 3
  const [timer, setTimer] = useState(totalTime)

  useEffect(() => {
    setTimeout(() => {
      timer > 0 ? setTimer(timer - 1) : (window.location = window.location.origin)
    }, 1000)
  }, [timer])

  return (
    <div className="Session-Expired-Container bg-grey">
      <div className="Session-Expired-Card">
        <div className="Heading">
          <img src="https://prod-canopy-railsapi-media-bucket.s3.amazonaws.com/canopy-c.png" alt="canopy icon" />
          <p>Session Expired</p>
        </div>
        <div className="Subheading">
          Oops! looks like your session has expired. Redirecting back to login page...
        </div>

        <div className="Timer-Holder">
          <div className="Timer">
            <CircularProgressbar
              className="CircularProgressbar"
              value={(timer / totalTime) * 100 || 0}
              text={timer.toString()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionExpired
