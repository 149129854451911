import { connect } from 'react-redux';

import LearnCreationPage from './LearnCreationPage';
import { fetchCourses, clearCourses } from '../../../Actions/CourseActions';
import { fetchCourseTypes, clearCourseTypes } from '../../../Actions/CourseTypeActions';

const mapStateToProps = (state) => ({
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourses: () => dispatch(fetchCourses()),
    clearCourses: () => dispatch(clearCourses()),
    fetchCourseTypes: () => dispatch(fetchCourseTypes()),
    clearCourseTypes: () => dispatch(clearCourseTypes())
});

export default connect(mapStateToProps, mapDispatchToProps)(LearnCreationPage);
