import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './CoursesDashboard.scss';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import CircularProgressBar from '../../Basic/CircularProgressBar/CircularProgressBar';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CourseProgressImportContainer from '../../Forms/CourseProgressImport/CourseProgressImportContainer';

import { syncSeatTime } from '../../../Actions/SeatTimeActions';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { sortCourseIdsBySeries } from '../../../Util/Helper/CourseUtil';

const propTypes = {
    clearLessons: PropTypes.func.isRequired,
    clearCourseId: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    fetchCourseProgresses: PropTypes.func.isRequired,
    courses: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    courseProgresses: PropTypes.shape({
        id: PropTypes.munber,
    }).isRequired,
};

class CourseDashboard extends Component {
    componentDidMount() {
        syncSeatTime();
        const { clearLessons, clearCourseId, fetchCourses, fetchCourseProgresses } = this.props;
        clearLessons();
        clearCourseId();
        fetchCourses();
        // fetchCourseProgresses();
    }

    checkExpired = (course) => {
        const { subscriptions } = this.props;
        let expiration = subscriptions?.[course.id]?.expires_at
        if (expiration && Date.parse(expiration) < Date.now()) {
            return (
                <div className="Expired-Overlay">
                    <i className="fas fa-lock"></i>

                    <TextBox
                        text={"Your subscription has expired."}
                        style={{
                            color: `${COLORS.Blue}`,
                            fontWeight: '600',
                            textDecoration: 'none',
                            margin: '10px 0',
                        }}
                    />

                    {
                        (subscriptions?.[course.id]?.recurring_subscription_id && subscriptions?.[course.id]?.recurring_subscription?.auto_renew) ? (
                            <TextBox
                                text={"Your subscription will be renewed shortly."}
                                style={{
                                    color: `${COLORS.Blue}`,
                                    fontWeight: '600',
                                    textDecoration: 'none',
                                    marginBottom: '50px',
                                    marginLeft: '42px',
                                }}
                            />
                        ) : (
                            <>
                                <TextBox
                                    text={"Click below to subscribe."}
                                    style={{
                                        color: `${COLORS.Blue}`,
                                        fontWeight: '600',
                                        textDecoration: 'none',
                                        marginBottom: '50px'
                                    }}
                                />
                                <div className="CoursesDashboard-Course-Button">
                                    <Link to="/account/learn/course_packages">
                                        <ButtonBox
                                            className="CoursesDashboard-Button__start Primary-Btn"
                                            text={(<span className="Primary-Btn-Text">Subscribe</span>)}
                                        />
                                    </Link>
                                </div>
                            </>
                        )
                    }
                </div>
            );
        }
        return null;
    }

    handleCourseRender = () => {
        const { courses, courseProgresses, subscriptions, flippers } = this.props;
        if (Object.keys(courses).length > 0) {
            const coursesList = sortCourseIdsBySeries(courses).map((courseId) => {
                let expiration = subscriptions?.[courseId]?.expires_at
                const course = courses[courseId]
                return (
                    <div className="CoursesDashboard-Course-Box" key={courseId}>
                        <div className="CoursesDashboard-Course-Title">
                            <TextBox
                                text={course.name}
                                style={{
                                    color: `${COLORS.Blue}`,
                                    fontWeight: '600',
                                    textDecoration: 'none',
                                }}
                                tag="h5"
                            />
                        </div>
                        <div className="CoursesDashboard-Course-CircularProgressBar">
                            <CircularProgressBar
                                totalNumberOfSteps={course?.non_test_lessons || 0}
                                completedSteps={courseProgresses?.[courseId]?.progress_data?.completedLessons?.length || 0}
                                showText={true}
                            />
                        </div>
                        <div className="CoursesDashboard-Course-Button">
                            {
                                !(expiration && Date.parse(expiration) < Date.now()) &&
                                <Link to={`/learn/courses/${course.id}/lessons`}>
                                    <ButtonBox
                                        className="CoursesDashboard-Button__start Primary-Btn"
                                        text={(<span className="Primary-Btn-Text">Enter Course</span>)}
                                        onClick={() => gaEvent('course_open', { course_id: course.id })}
                                    />
                                </Link>
                            }
                        </div>

                        {
                            flippers?.actor?.course_progress_import?.enabled &&
                            <CourseProgressImportContainer course={course} />
                        }

                        {
                            this.checkExpired(course)
                        }
                    </div>
                );
            });
            return coursesList;
        }
        return null;
    }

    render() {
        return (
            <div className="CoursesDashboard">
                {this.handleCourseRender()}
            </div>
        );
    }
}

CourseDashboard.propTypes = propTypes;

export default CourseDashboard;
