import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBar';

const UnlockPage = ({
    errors,
    flash,
    unlockAccount,
    clearSessionErrors,
    token,
}) => {
    useEffect(() => {
        clearSessionErrors();
    }, [clearSessionErrors]);

    useEffect(() => {
        unlockAccount(token);
    }, [token, unlockAccount]);

    return (
        <div className="AuthPage">
            <ProfileNavBar />
            <div className="container">
                <p className="container__header">UNLOCK YOUR ACCOUNT</p>
                <p className="container__subheader">
                    We made an attempt to unlock your account using the email link you arrived here from. The link may only be used once, then becomes invalid.
                </p>
                <ErrorFlashBox
                    errors={errors}
                    flash={flash}
                    style={{ marginBottom: '20px' }}
                />
                { flash.message && (
                    <Link to="/signin" className="container__link">
                        <button
                            className="container__button"
                            type="submit"
                        >
                            SIGN IN
                        </button>
                    </Link>
                )}
            </div>
        </div>
    );
};

UnlockPage.propTypes = {
    errors: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    flash: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    unlockAccount: PropTypes.func.isRequired,
    clearSessionErrors: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
};

export default UnlockPage;
