import * as APIUtil from '../Util/UserResourceApiUtil';
import * as CreateUserApiUtil from '../Util/CreateUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_USER_RESOURCES = 'CLEAR_USER_RESOURCES';
export const RECEIVE_USER_RESOURCES = 'RECEIVE_USER_RESOURCES';
export const RECEIVE_USER_RESOURCE_ERRORS = 'RECEIVE_USER_RESOURCE_ERRORS';

export const clearUserResources = () => ({
  type: CLEAR_USER_RESOURCES,
});

export const receiveUserResources = (payload) => ({
  type: RECEIVE_USER_RESOURCES,
  payload,
});

export const receiveUserResourceErrors = (errors) => ({
  type: RECEIVE_USER_RESOURCE_ERRORS,
  errors,
});

export const fetchUserResources = () => dispatch => (
  APIUtil.fetchUserResources().then(
    payload => dispatch(receiveUserResources(payload)),
    errors => dispatch(receiveUserResourceErrors(ErrorResponseUtil(errors))),
  )
);

export const createUserResource = (userResource) => dispatch => (
  APIUtil.createUserResource(userResource).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);

export const checkExistingUser = (user) => dispatch => (
  CreateUserApiUtil.checkExistingUser(user).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);
