import { RECEIVE_CURRENT_USER } from '../Actions/SessionActions';
import { RECEIVE_USER_PREFERENCES } from '../Actions/UserPreferenceActions';
import { RECEIVE_STREAK_CONFIG } from '../Actions/Engage/StreakConfigActions';

const nullUser = Object.freeze({
    currentUser: null,
    currentSubscriptions: {},
});

const sessionReducer = (state = nullUser, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_CURRENT_USER:
            return { currentUser: action.payload };
        case RECEIVE_USER_PREFERENCES:
            return Object.assign({}, state, { currentUser: { ...(state.currentUser), 'userPreferences': action.payload.userPreferences } });
        case RECEIVE_STREAK_CONFIG:
            return Object.assign({}, state, { currentUser: { ...(state.currentUser), 'streakConfig': action.payload.streakConfig } });
        default:
            return state;
    }
};

export default sessionReducer;
