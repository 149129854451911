import { postSeatTime } from '../Util/SeatTimeApiUtil';
import CryptoJS from 'crypto-js';

// key: seat-time-of-{MODULE_NAME}-{MODULE_ID}

export const trackSeatTime = (key, seatTime) => {
  var seatTimes = getLs(key);

  if (seatTimes) {
    seatTimes.push(seatTime);
  } else {
    seatTimes = [seatTime];
  }

  setLs(key, seatTimes);
}

export const syncSeatTime = () => {
  Object.keys(window.localStorage).forEach(function(e) {
    if (e.startsWith('seat-time-of-')) {
      saveSeatTime(e);
    }
  });
}

export const saveSeatTime = (key) => {
  postSeatTime(getLs(key));
  removeLs(key);
}

export const setLs = (key, args) => {
  let stringifiedObject = JSON.stringify(args);
  let cipheredText = CryptoJS.AES.encrypt(stringifiedObject, key).toString();
  window.localStorage.setItem(key, cipheredText);
}

export const getLs = (key) => {
  let cipheredText = window.localStorage.getItem(key);

  if (!cipheredText) {
    return null;
  }

  let stringifiedObject = CryptoJS.AES.decrypt(cipheredText, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(stringifiedObject);
}

export const removeLs = (key) => {
  window.localStorage.removeItem(key);
}
