import axios from './AxiosUtil';

export const fetchUsers = () => {
    return axios.get('/api/users').then(
        res => res.data,
    );
};

export const fetchUser = (userId) => {
    return axios.get(`/api/users/${userId}`).then(
        res => res.data,
    );
};

export const fetchUserPerformance = userId => axios
    .get(`/api/quiz_results/${userId}`)
    .then(res => res.data);
