import './AdminSideBar.css';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import ButtonBox
    from '../../Basic/ButtonBox/ButtonBox';

const AdminSideBar = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const handleMenuClick = () => {
        collapseAfterClick();
        scrollToTop();
    }

    const handleCollapseToggle = () => {
        const SideBar = document.querySelectorAll(".Admin-Sidebar");
        SideBar.forEach((sideBar) => {
            sideBar.classList.toggle("Admin-Sidebar-show");
        });
    }

    const collapseAfterClick = () => {
        const SideBar = document.querySelectorAll(".Admin-Sidebar");
        SideBar.forEach((sideBar) => {
            sideBar.classList.remove("Admin-Sidebar-show");
        });
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="Admin-Sidebar">
            <div className="Admin-Sidebar-Menu">

                <div className="Admin-Sidebar-Menu-Title">
                    B2B SignUp Flow
                </div>

                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/account/institutions/new"
                        onClick={() => handleMenuClick()}
                    >
                        Step 1: Create Institution
                    </Link>

                    <Link
                        to="/account/license/new"
                        onClick={() => handleMenuClick()}
                    >
                        Step 2: Create License
                    </Link>

                    <Link
                        to="/account/create_user"
                        onClick={() => handleMenuClick()}
                    >
                        Step 3: Create User (B2B & Admin)
                    </Link>
                </div>

                <div className="Admin-Sidebar-Menu-Title">
                    Users
                </div>

                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/account/create_user"
                        onClick={() => handleMenuClick()}
                    >
                        Create User (B2B & Admin)
                    </Link>

                    <Link
                        to="/account/invite_users"
                        onClick={() => handleMenuClick()}
                    >
                        Invite Learners (B2B)
                    </Link>
                    <Link
                        to="/account/modify_user"
                        onClick={() => handleMenuClick()}
                    >
                        Modify User
                    </Link>
                    <Link
                        to="/account/user_access"
                        onClick={() => handleMenuClick()}
                    >
                        Manage User Access
                    </Link>

                    <Link
                        to="/account/stats/nps"
                        onClick={() => handleMenuClick()}
                    >
                        Net Promoter Scores
                    </Link>

                </div>

                <div className="Admin-Sidebar-Menu-Title">
                    Courses
                </div>

                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/creation"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Course Content
                    </Link>

                    <Link
                        to="/account/course_packages"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Course Packages
                    </Link>

                    <Link
                        to="/account/comments"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Comments
                    </Link>
                </div>

                <div className="Admin-Sidebar-Menu-Title">
                    Institution
                </div>
                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/account/institutions/new"
                        onClick={() => handleMenuClick()}
                    >
                        Create Institution
                    </Link>

                    <Link
                        to="/account/institutions"
                        onClick={() => handleMenuClick()}
                    >
                        Edit/Delete Institutions
                    </Link>

                    <Link
                        to="/account/stats/institutions"
                        onClick={() => handleMenuClick()}
                    >
                        Organization Report
                    </Link>

                    <Link
                        to="/account/stats/users"
                        onClick={() => handleMenuClick()}
                    >
                        Users Report
                    </Link>

                    <Link
                        to="/account/resource_library"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Resources
                    </Link>
                </div>

                <div className="Admin-Sidebar-Menu-Title">
                    License
                </div>
                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/account/license/new"
                        onClick={() => handleMenuClick()}
                    >
                        Create License
                    </Link>
                    <Link
                        to="/account/licenses"
                        onClick={() => handleMenuClick()}
                    >
                        Edit/Delete Licenses
                    </Link>
                    <Link
                        to="/account/license_management"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Licenses
                    </Link>
                </div>
                <div className="Admin-Sidebar-Menu-Title">
                    Canopy Credential
                </div>
                <div className="Admin-Sidebar-Menu-Items">
                    <Link
                        to="/account/credential/manage_exams"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Exams
                    </Link>

                    <Link
                        to="/account/credential/grant_access"
                        onClick={() => handleMenuClick()}
                    >
                        Grant Access
                    </Link>

                    <Link
                        to="/account/credential/manage_tests"
                        onClick={() => handleMenuClick()}
                    >
                        Manage Tests
                    </Link>

                    <Link
                        to="/account/user_resources/new"
                        onClick={() => handleMenuClick()}
                    >
                        User Resources
                    </Link>
                </div>
            </div>

            {
                isMobile &&
                <div
                    className="SideBar-Collapse-Button">
                    <ButtonBox
                        className="ButtonBox-SideBar"
                        text={<i className="fas fa-angle-double-left"></i>}
                        onClick={() => handleCollapseToggle()} />
                </div>
            }
        </div>
    );
};

export default AdminSideBar;
