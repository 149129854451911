import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/ActivityApiUtil';

export const RECEIVE_ACTIVITY = 'RECEIVE_ACTIVITY';
export const RECEIVE_ACTIVITY_ERRORS = 'RECEIVE_ACTIVITY_ERRORS';
export const RECEIVE_ACTIVITY_RESPONSE = 'RECEIVE_ACTIVITY_RESPONSE';
export const CLEAR_ACTIVITY_RESPONSES = 'CLEAR_ACTIVITY_RESPONSES';

export const receiveActivity = (payload) => ({
    type: RECEIVE_ACTIVITY,
    payload,
});

export const receiveErrors = (errors) => ({
    type: RECEIVE_ACTIVITY_ERRORS,
    errors,
});

export const receiveActivityResponse = payload => ({
    type: RECEIVE_ACTIVITY_RESPONSE,
    payload,
});

export const clearActivityResponses = () => ({
    type: CLEAR_ACTIVITY_RESPONSES,
});

export const fetchActivity = (lessonId) => dispatch => (
    APIUtil.fetchActivity(lessonId).then(
        payload => dispatch(receiveActivity(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createActivity = (activity) => dispatch => (
    APIUtil.createActivity(activity).then(
        payload => dispatch(receiveActivity(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editActivity = (activity) => dispatch => (
    APIUtil.editActivity(activity).then(
        payload => dispatch(receiveActivity(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteActivity = (activityId) => dispatch => (
    APIUtil.deleteActivity(activityId).then(
        payload => dispatch(receiveActivity(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const cloneActivity = (targetLessonId, activityId) => dispatch => (
    APIUtil.cloneActivity(targetLessonId, activityId).then(
        payload => payload,
        errors => ErrorResponseUtil(errors),
    )
);

export const submitActivityResponse = (activityResponseData) => dispatch => (
    APIUtil.submitActivityResponse(activityResponseData).then(
        payload => dispatch(receiveActivityResponse(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
