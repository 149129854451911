import * as APIUtil from '../Util/CourseTypeApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_COURSE_TYPES = 'RECEIVE_COURSE_TYPES';
export const RECEIVE_COURSE_TYPE_ERRORS = 'RECEIVE_COURSE_TYPE_ERRORS';
export const CLEAR_COURSE_TYPES = 'CLEAR_COURSE_TYPES';

export const receiveCourseTypes = payload => {
    return {
        type: RECEIVE_COURSE_TYPES,
        payload,
    };
};

export const receiveCourseTypeErrors = errors => ({
    type: RECEIVE_COURSE_TYPE_ERRORS,
    errors,
});

export const clearCourseTypes = () => {
    return {
        type: CLEAR_COURSE_TYPES,
    };
};

export const fetchCourseTypes = () => dispatch => (
    APIUtil.fetchCourseTypes().then(
        payload => dispatch(receiveCourseTypes(payload)),
        errors  => dispatch(receiveCourseTypeErrors(ErrorResponseUtil(errors))),
    )
);

export const updateCourseType = (course_type) => dispatch => (
    APIUtil.updateCourseType(course_type).then(
        payload => dispatch(receiveCourseTypes(payload)),
        errors  => dispatch(receiveCourseTypeErrors(ErrorResponseUtil(errors))),
    )
);
