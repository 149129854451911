import {
    CLEAR_LESSON_ERRORS,
    RECEIVE_LESSON,
    RECEIVE_LESSON_ERRORS,
    RECEIVE_LESSON_DELETE_SUCCESS,
} from '../../Actions/LessonActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_LESSON:
            if (action.message) {
                return { message: action.message };
            }
            return state;
        case RECEIVE_LESSON_DELETE_SUCCESS:
            if (action.message) {
                return { message: action.message };
            }
            return state;
        case RECEIVE_LESSON_ERRORS:
            return {};
        case CLEAR_LESSON_ERRORS:
            return {};
        default:
            return state;
    }
};
