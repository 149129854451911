import * as APIUtil from '../Util/CoursePackageApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_COURSE_PACKAGE_ERRORS = 'RECEIVE_COURSE_PACKAGE_ERRORS';
export const RECEIVE_COURSE_PACKAGES = 'RECEIVE_COURSE_PACKAGES';
export const CLEAR_COURSE_PACKAGES = 'CLEAR_COURSE_PACKAGES';
export const RECEIVE_COURSE_PACKAGE = 'RECEIVE_COURSE_PACKAGE';

export const receiveCoursePackages = payload => ({
    type: RECEIVE_COURSE_PACKAGES,
    payload,
});

export const clearCoursePackages = () => ({
    type: CLEAR_COURSE_PACKAGES,
});

export const receiveCoursePackage = payload => ({
    type: RECEIVE_COURSE_PACKAGE,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_COURSE_PACKAGE_ERRORS,
    errors,
});

export const fetchCoursePackages = () => dispatch => (
    APIUtil.fetchCoursePackages().then(
        payload => dispatch(receiveCoursePackages(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchProducts = () => dispatch => (
    APIUtil.fetchProducts().then(
        payload => dispatch(receiveCoursePackages(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchRenewProducts = () => dispatch => (
    APIUtil.fetchRenewProducts().then(
        payload => dispatch(receiveCoursePackages(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchCoursePackage = (coursePackageId) => dispatch => (
    APIUtil.fetchCoursePackage(coursePackageId).then(
        payload => dispatch(receiveCoursePackage(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createCoursePackage = (coursePackage) => dispatch => (
    APIUtil.createCoursePackage(coursePackage).then(
        payload => dispatch(receiveCoursePackage(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editCoursePackage = (coursePackage) => dispatch => (
    APIUtil.editCoursePackage(coursePackage).then(
        payload => dispatch(receiveCoursePackage(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteCoursePackage = coursePackageId => dispatch => (
    APIUtil.deleteCoursePackage(coursePackageId).then(
        payload => dispatch(receiveCoursePackage(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
