import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ImageBox from '../../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import Pagination from '../../../Composed/Pagination/Pagination';

import { hashToQueryString, humanizeDate } from '../../../../Util/Helper/GenericUtil';
import EnvironmentUtil from '../../../../Util/EnvironmentUtil';

import './Exhibition.scss';

const Exhibition = ({
  currentWorkplace,
  institutionResources, clearInstitutionResources, fetchInstitutionResources,
  platforms, clearPlatforms, fetchPlatforms,
  mediaTypes, clearMediaTypes, fetchMediaTypes,
  institutionResourceUserInteraction,
}) => {
  const [loading, setLoading] = useState(true);
  const [reacting, setReacting] = useState(null);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [filters, setFilters] = useState({ platformFilter: '', mediaTypeFilter: '', sortFilter: 'most_recently_updated', searchFilter: '' });
  const [page, setPage] = useState(1);

  const per = 6;

  useEffect(() => {
    Promise.all([
      clearPlatforms(), fetchPlatforms(),
      clearMediaTypes(), fetchMediaTypes(),
    ]).then(() => setSelectedInstitutionId(currentWorkplace.institution_id));
  }, [clearMediaTypes, clearPlatforms, fetchMediaTypes, fetchPlatforms]);

  useEffect(() => {
    if (selectedInstitutionId) {
      setLoading(true);

      Promise.all([
        clearInstitutionResources(),
        fetchInstitutionResources(selectedInstitutionId, buildQueryString()),
      ]).then(() => setLoading(false));
    }
  }, [selectedInstitutionId, clearInstitutionResources, fetchInstitutionResources, filters, page]);

  const buildQueryString = () => {
    let queryString = '';

    queryString += hashToQueryString(filters);
    queryString += `&page=${page}&per=${per}`;

    return queryString;
  };

  const handleInteraction = (institutionResource, attribute, value) => {
    if (institutionResource.archived_at) {
      return null;
    }

    setReacting(institutionResource.id);

    institutionResourceUserInteraction({
      institutionId: (institutionResource.institution_id === 1 ? 1 : selectedInstitutionId),
      institutionResourceId: institutionResource.id,
      [attribute]: value,
      index: institutionResource.index,
    }).then(() => setReacting(null));
  }

  const handleFilesDownload = (institutionResource) => {
    Object.values(institutionResource.files || {}).map(file => {
      const fileUrl = EnvironmentUtil().buildURL(file.url);

      fetch(fileUrl).then((res) => {
        return res.blob();
      }).then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const downloadLink = document.createElement('a');

        downloadLink.href = blobUrl;
        downloadLink.download = `${file.name}`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    });
  }

  const handleFilesPreview = (institutionResource) => (
    Object.values(institutionResource.files || {}).forEach(file => {
      if (previewableExtensions(file.name)) {
        let fileUrl = EnvironmentUtil().buildURL(file.url);
        window.open(fileUrl, '_blank', 'noreferrer');
      }
    })
  )

  const anyPreviewableFile = (institutionResource) => (
    Object.values(institutionResource.files || {}).find(file => previewableExtensions(file.name))
  )

  const previewableExtensions = (fileName) => (
    ['pdf', 'png', 'jpg', 'jpeg'].includes(fileName.split('.').pop())
  )

  const sortedResources = () => (
    Object.values(institutionResources.data || {}).sort((a, b) => a.index > b.index ? 1 : -1)
  );

  const renderInstitutionResource = (institutionResource, userInteraction) => (
    <div
      key={`institutionResource-${institutionResource.index}`}
      className={`Institution-Resource ${institutionResource.archived_at ? 'Archived' : ''} ${institutionResource.institution_id === 1 ? 'Global' : ''}`}
    >
      <div className="Head">
        <div className="Title">{institutionResource.title}</div>
      </div>

      <div className="Body">
        <div className="Content">
          <div className="Attribute">
            <div className="Name">Description</div>
            <div className="Value">{institutionResource.description}</div>
          </div>

          <div className="Attribute">
            <div className="Name">URL</div>
            <div className="Value">
              {
                institutionResource.url &&
                <a className="Link" href={institutionResource.url} target="_blank" rel="noopener noreferrer" >{institutionResource.url}</a>
              }
            </div>
          </div>

          <div className="Attribute">
            <div className="Name">Resource Type</div>
            <div className="Value">
              {Object.values(institutionResource.institutionResourceMediaTypes || {}).map(irmt => irmt.mediaType?.name).join(', ') || 'N/A'}
            </div>
          </div>

          <div className="Attribute">
            <div className="Name">First Uploaded</div>
            <div className="Value Date">
              {humanizeDate(institutionResource.created_at, true)}
            </div>
          </div>

          <div className="Attribute">
            <div className="Name">Last Updated</div>
            <div className="Value Date">
              {humanizeDate(institutionResource.updated_at, true)}
            </div>
          </div>
        </div>

        <div className="Actions">
          {
            institutionResource.archived_at ? (
              <div className="Archived">Archived</div>
            ) : (
              institutionResource.files &&
              <>
                <ButtonBox
                  className="Download-Button"
                  text="Download Attachment"
                  onClick={() => {
                    handleInteraction(institutionResource, 'downloads', (userInteraction ? (userInteraction.downloads + 1) : 1));
                    handleFilesDownload(institutionResource);
                  }}
                />

                {
                  anyPreviewableFile(institutionResource) ? (
                    <ButtonBox
                      className="Preview-Button"
                      src="eye.svg"
                      text="Preview Attachment"
                      imageClassName="Icon"
                      onClick={() => handleFilesPreview(institutionResource)}
                    />
                  ) : (
                    <ButtonBox
                      className="Preview-Button"
                      text="No Previewable Attachment"
                    />
                  )
                }
              </>
            )
          }
        </div>
      </div>

      <div className="Foot">
        {reacting === institutionResource.id && <div className="Reacting"><i className="fas fa-circle-notch fa-spin" /></div>}
        <div className="Reactions">
          <div className="Reaction">
            <ButtonBox
              className={`Icon Like ${userInteraction?.reaction === 'like' ? 'Liked' : ''}`}
              icon="fa-thumbs-up"
              onClick={(e) => handleInteraction(institutionResource, 'reaction', (userInteraction?.reaction === 'like' ? 'pending' : 'like'))}
            />
            <span className="Count">{institutionResource.institutionResourceUsers.likes}</span>
          </div>

          <div className="Reaction">
            <ButtonBox
              className={`Icon Dislike ${userInteraction?.reaction === 'dislike' ? 'Disliked' : ''}`}
              icon="fa-thumbs-down"
              onClick={(e) => handleInteraction(institutionResource, 'reaction', (userInteraction?.reaction === 'dislike' ? 'pending' : 'dislike'))}
            />
            <span className="Count">{institutionResource.institutionResourceUsers.dislikes}</span>
          </div>
        </div>

        <div className="Reactions">
          <div className="Reaction">
            <ButtonBox
              className="Icon Favorite"
              icon={(userInteraction?.favorite ? 'fa-heart' : 'fa-heart-o')}
              onClick={(e) => handleInteraction(institutionResource, 'favorite', !userInteraction?.favorite)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Institution-Resource-Exhibition-Page">
      {loading && <MirrorLoader message="Fetching Resources..."/>}

      <div className="Tabs">
        <div
          className={`Tab ${filters.platformFilter === '' ? 'Active' : ''}`}
          onClick={() => setFilters({ ...filters, platformFilter: '' })}
        >
          <span className="Name">All Resources</span>
        </div>
        <div
          className={`Tab ${filters.platformFilter === 'Favorites' ? 'Active' : ''}`}
          onClick={() => setFilters({...filters, platformFilter: 'Favorites' })}
        >
          <i className="fa fa-heart Icon" />
          <span className="Name">Favorites</span>
        </div>
        {
          Object.values(platforms).map((platform, idx) => (
            <div
              key={`filter-platform-${idx}`}
              className={`Tab ${filters.platformFilter === platform.name ? 'Active' : ''}`}
              onClick={() => setFilters({ ...filters, platformFilter: platform.name })}
            >
              <span className="Name">{platform.name}</span>
            </div>
          ))
        }
      </div>

      <div className="Page-Head">
        <div className="Title">Resources</div>
        <div className="Filters-Container">
          <div className="Dropdown">
            <ImageBox className="Image" src="up-down-triangles.svg" />
            <select
              className="Select"
              value={filters.sortFilter}
              onChange={e => setFilters({ ...filters, sortFilter: e.currentTarget.value })}
            >
              <option value='most_recently_updated' children='Most Recently Updated' />
              <option value='most_recently_uploaded' children='Most Recently Uploaded' />
              <option value='alphabetic' children='Alphabetic' />
            </select>
            <i className="fa fa-caret-down Icon"></i>
          </div>

          <div className="Dropdown">
            <ImageBox className="Image" src="increasing-lines.svg" />
            <select
              className="Select"
              value={filters.mediaTypeFilter}
              onChange={e => setFilters({ ...filters, mediaTypeFilter: e.currentTarget.value })}
            >
              <option value='' children='All Resource Types' />
              {
                Object.values(mediaTypes).map((mediaType, idx) => (
                  <option key={`filter-mediaType-${idx}`} value={mediaType.name} children={mediaType.name} />
                ))
              }
            </select>
            <i className="fa fa-caret-down Icon"></i>
          </div>

          <div className="Search">
            <i className="fa fa-search Icon" />
            <input
              className="Input"
              placeholder="Title or description"
              onBlur={(e) => setFilters({ ...filters, searchFilter: e.currentTarget.value })}
              onKeyPress={(e) => (e.key === 'Enter') && e.currentTarget.blur()}
            />
          </div>
        </div>
      </div>

      {
        institutionResources.total ? (
          <div className="Institution-Resources">
            {
              sortedResources().map(institutionResource => (
                renderInstitutionResource(institutionResource, institutionResource.institutionResourceUsers.currentUserInstitutionResource)
              ))
            }

            <Pagination
              totalRecords={institutionResources.total}
              page={page}
              setPage={(p) => setPage(p)}
              per={per}
            />
          </div>
        ) : (
          <div className="No-Records Flash-Message Danger">
            No resources were found! Try clearing the filters.
          </div>
        )
      }
    </div>
  )
};

Exhibition.propTypes = {
  currentWorkplace: PropTypes.object.isRequired,
  institutionResources: PropTypes.object.isRequired,
  platforms: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  clearInstitutionResources: PropTypes.func.isRequired,
  fetchInstitutionResources: PropTypes.func.isRequired,
  clearPlatforms: PropTypes.func.isRequired,
  fetchPlatforms: PropTypes.func.isRequired,
  clearMediaTypes: PropTypes.func.isRequired,
  fetchMediaTypes: PropTypes.func.isRequired,
  institutionResourceUserInteraction: PropTypes.func.isRequired,
};

export default Exhibition;
