import './SignInPage.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBarContainer';

class SignInPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
    }

    componentDidMount() {
        const { clearSessionErrors } = this.props;
        clearSessionErrors();
    }

    handleInputChange = field => e => this.setState({ [field]: e.currentTarget.value })

    handleSubmit = (e) => {
        e.preventDefault();
        const { login } = this.props;
        const user = { ...this.state };
        login(user);
    }

    render() {
        const { errors } = this.props;
        const { email, password } = this.state;

        return (
            <div className="AuthPage">
                <ProfileNavBar />
                <form
                    className="container"
                    onSubmit={this.handleSubmit}
                >
                    <p className="container__header">WELCOME BACK</p>
                    <p className="container__subheader">
                        Sign in to Canopy to continue learning.
                    </p>

                    <div className="container__label-row">
                        <p className="container__label">EMAIL</p>
                    </div>

                    <input
                        className={errors.message ? 'container__textinput-error' : 'container__textinput'}
                        type="text"
                        onChange={this.handleInputChange('email')}
                        value={email}
                    />

                    <div className="container__label-row">
                        <p className="container__label">PASSWORD</p>
                    </div>

                    <input
                        className={errors.message ? 'container__textinput-error' : 'container__textinput'}
                        type="password"
                        onChange={this.handleInputChange('password')}
                        value={password}
                    />

                    <ErrorFlashBox
                        errors={errors}
                        style={{ marginBottom: '20px' }}
                    />

                    <Link to="/password/new" className="container__link">
                        <TextBox
                            text="Forgot Your Password?"
                            style={{
                                color: COLORS.Blue1,
                            }}
                        />
                    </Link>

                    <button
                        className="container__button"
                        type="submit"
                    >
                        SIGN IN
                    </button>
                </form>
            </div>
        );
    }
}

SignInPage.propTypes = {
    clearSessionErrors: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
};

export default SignInPage;
