import './DashboardFormBox.css';

import PropTypes from 'prop-types';
import React from 'react';

import TextBox from '../../Basic/TextBox/TextBox';

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    hideTitle: PropTypes.bool,
};

const defaultProps = {
    hideTitle: false,
};

const DashboardFormBoxNoEdit = ({ children, title, hideTitle }) => (
    <div className="DashboardFormBox">
        <div className="DashboardFormBox-Title_container" style={{ display: `${hideTitle ? 'none' : 'flex'}` }}>
            <div className="DashboardFormBox-Title">
                <TextBox
                    text={title}
                    style={{
                        color: 'rgba(24,60,91,0.8)',
                    }}
                />
            </div>
        </div>

        <div className="DashboardFormBox-Content_container">
            {children}
        </div>
    </div>
);

DashboardFormBoxNoEdit.propTypes = propTypes;
DashboardFormBoxNoEdit.defaultProps = defaultProps;
export default DashboardFormBoxNoEdit;
