export const invitationStatusesList = {
  'invited':    'Invited',
  'affiliated': 'Signed Up',
  'improper':   'Invalid',
}

export const validFileTypesList = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]
