import dateFormat from 'dateformat';
import { sum, snakeCase } from 'lodash';

// Numbers
export const numberInRange = (number, min, max) => {
  if (number >= min && number <= max)
    return 0;

  if (number < min)
    return -1;

  if (number > max)
    return 1;
}

export const percentage = (obtained, total) => (
  Math.round((obtained / total) * 100)
);

// Array
export const shuffle = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const average = (array) => {
  if (array.length === 0) {
    return 0;
  }

  return Math.round(sum(array) / array.length);
}

// Hash
export const hashToQueryString = (hash) => {
  if (Object.keys(hash).length === 0)
    return '';

  return Object.entries(hash).map(([k, v]) => `${snakeCase(k)}=${v}`).join('&');
}

// HTML Content
export const instructionsContent = (instructions) => {
  let instructionsDoc = document.implementation.createHTMLDocument('New').body;
  instructionsDoc.innerHTML = instructions;

  let hasContent = (instructionsDoc.textContent || instructionsDoc.innerText || '').trim().length > 0
  let hasImage   = instructionsDoc.getElementsByTagName('img').length > 0
  let hasAudio   = instructionsDoc.getElementsByTagName('audio').length > 0
  let hasVideo   = instructionsDoc.getElementsByTagName('video').length > 0
  let hasLink    = instructionsDoc.getElementsByTagName('a').length > 0

  return (hasContent || hasImage || hasAudio || hasVideo || hasLink);
}

// Date Time

export const secondsToMiliseconds = (seconds = 0) => (seconds * 1000);

export const humanizeDate = (date, withTime = false) => {
  if (!date)
    return '';

  if (withTime) {
    return dateFormat(date, 'mmmm dd, yyyy hh:MM TT', true)
  } else {
    return dateFormat(date, 'mmmm dd, yyyy', true)
  }
};

export const humanizeTime = (date) => {
  if (!date)
    return '';

  date = new Date(date);
  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const hoursMinutes = (seconds) => {
  if (!seconds) {
    return '00:00';
  }

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}:${minutes}`
}

export const isDateInPast = (date) => {
  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  return inputDate < todayDate;
}
