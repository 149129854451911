import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import Filters from '../Filters/Filters';
import UserParticipation from './UserParticipation';
import UserPerformance from './UserPerformance';
import CredentialRosterManagement from '../UserRosterManagement/CredentialRosterManagement';
import Loader from '../../../Composed/Loader/Loader';

import './Credential.scss';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Credential = () => {
  const [loading, setLoading] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const formNames = ['OVERVIEW', 'USER ROSTER MANAGEMENT'];
  const [contentWidth, setContentWidth] = useState(0);

  useLayoutEffect (() => {
    function updateWidth() {
      let pageWidth = document.getElementsByClassName('Profile-Main')[0].clientWidth;

      if (window.outerWidth > 768) {
        pageWidth -= 450;
      } else if (window.outerWidth > 450 && window.outerWidth < 768) {
        pageWidth -= 150;
      } else {
        pageWidth -= 100;
      }

      setContentWidth(pageWidth);
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const renderDashboard = () => {
    switch(formIndex) {
      case 0:
        return (
          <div className="OverView-Container">
            <Filters
              showFilters={['timesFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {
              loading ? <Loader /> : (
                <>
                  <UserParticipation />
                  <UserPerformance contentWidth={contentWidth}/>
                </>
              )
            }
          </div>
        );
      case 1:
        return (
          <div className="User-Roster-Management-Container">
            <Filters
              showFilters={['examStatusesFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {loading ? <Loader /> : <CredentialRosterManagement />}
          </div>
        );
    }
  }

  return (
    <div className="B2B-Admin-Credential-Dashboard-Container">
      <div className="Cerdential-Dashboard-Tabs">
        {
          formNames.map((formName, idx) => (
            <div
              key={`compliance-dashboard-tab-${idx}`}
              className={`Tab-Button ${formIndex === idx ? 'Active' : '' }`}
              onClick={() => {formIndex === idx ? null : setLoading(true); setFormIndex(idx)}}
            >
              {formName}
            </div>
          ))
        }
      </div>

      {renderDashboard()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Credential);
