import {
  RECEIVE_TE_ADMIN_USERS_STATS,
  CLEAR_TE_ADMIN_USERS_STATS,
} from '../../../Actions/Stats/TEAdmin/UsersActions';

const UsersReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_TE_ADMIN_USERS_STATS:
      return Object.assign({}, state, {
        total_workplaces: action.payload.total_workplaces,
        processed_workplaces: ((state.processed_workplaces || 0) + action.payload.chunk_workplaces),
      });
    case CLEAR_TE_ADMIN_USERS_STATS:
      return {};
    default:
      return state;
  }
}

export default UsersReducer;
