import { connect } from 'react-redux';

import Details from './Details';

import { clearNetPromoterScores, fetchNPSDetails } from '../../../../../Actions/NetPromoterScoreActions';

const mapStateToProps = state => ({
  netPromoterScores: state.admin.netPromoterScores || {},
});

const mapDispatchToProps = dispatch => ({
  clearNetPromoterScores: () => dispatch(clearNetPromoterScores()),
  fetchNPSDetails: queryString => dispatch(fetchNPSDetails(queryString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
