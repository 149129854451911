import { connect } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { likeComment, flagComment } from '../../../Actions/CommentActions';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  likeComment: (criteria, reaction) => dispatch(likeComment(criteria, reaction)),
  flagComment: (criteria, reaction) => dispatch(flagComment(criteria, reaction))
});

const CommentReaction = ({ currentUser, likeComment, flagComment, commentableType, commentableId, comment, likes }) => {
  const [flagModal, setFlagModal] = useState(false);
  const [flagReason, setFlagReason] = useState({ reason_type: '', reason: '' });

  const likeHandler = () => {
    let paramsCriteria = { action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId };
    let reaction = { comment_id: comment.id, reaction: 'like' }

    likeComment(paramsCriteria, reaction);
    gaEvent('like_comment', { entity_id: commentableId, comment_id: comment.id });
  }

  const flagHandler = (e) => {
    e.preventDefault();

    let paramsCriteria = { action: 'render_comment', commentable_type: commentableType, commentable_id: commentableId };
    let reaction = { comment_id: comment.id, reaction: 'flag', ...flagReason };

    flagComment(paramsCriteria, reaction);
    gaEvent('flag_comment', { entity_id: commentableId, comment_id: comment.id });
  }

  const handleReasonChange = (e) => {
    let reason_type = e.target.value;
    let reason = reason_type === 'Other' ? e.target.nextElementSibling.value : e.target.nextElementSibling.innerText;

    setFlagReason({ reason_type, reason });
  }

  return (
    <div className="Reaction-Container">
      <div className="Like-Container">
        <ButtonBox
          className={`Btn ${likes.includes(currentUser.id) ? 'Liked' : ''}`}
          text={
            <div className="Like">
              <i className="Icon fa fa-thumbs-up"></i>
              <span className="Text">Helpful</span>
              <span className="Count">{likes.length}</span>
            </div>
          }
          onClick={() => likeHandler()}
        />
      </div>

      <div className="Flag-Container">
        <ButtonBox
          className="Btn"
          text={
            <div className="Flag">
              <i className="Icon fa fa-flag"></i>
            </div>
          }
          onClick={() => setFlagModal(true)}
        />

        {
          flagModal &&
          <div className="modal Inner-Modal Flag-Modal">
            <div className="modal-content">
              <div className="Title">
                <ButtonBox
                  className="Close"
                  text={<i className="fa fa-close"></i>}
                  onClick={() => setFlagModal(false)}
                />
                <form className="Form">
                  <h2 className="ReportReview">Report review</h2>
                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Off topic"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Off topic
                      <p className="Flag-P">Comment doesn't pertain to subject matter contained within this slide or CanopyLearn.</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Spam"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Spam
                      <p className="Flag-P">Comment is from a bot, a malicious account, or contains ads and promotions.</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Profanity"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Profanity
                      <p className="Flag-P">Comment contains swear words, has sexually explicit language, or details graphic violence.</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Bullying or harassment"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Bullying or harassment
                      <p className="Flag-P">Comment personally attacks a specific individual</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Discrimination or hate speech"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Discrimination or hate speech
                      <p className="Flag-P">Comment has harmful language about an individual or group based on identity</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Personal information"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Personal information
                      <p className="Flag-P">Comment contains personal information, such as an address or phone number</p>
                    </div>
                  </label>

                  <label>
                    <div className="Flag-RadioBox">
                      <input
                        className="Radio"
                        type="radio"
                        name="reason_type"
                        value="Other"
                        onClick={(e) => handleReasonChange(e)}
                      />
                      Other
                      <input className="Flag-I"></input>
                    </div>
                  </label>

                  <div className="Flag-Submit">
                    <ButtonBox
                      className="Primary-Btn"
                      text={<span className="Primary-Btn-Text">Submit Report</span>}
                      onClick={(e) => flagHandler(e)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

CommentReaction.propTypes = {
  currentUser: PropTypes.object.isRequired,
  likeComment: PropTypes.func.isRequired,
  flagComment: PropTypes.func.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  comment: PropTypes.object.isRequired,
  likes: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentReaction);
