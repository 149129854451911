import React, { useState } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

import './CreateEditActivity.scss';

const CreateEditActivity = ({ lesson, activity, submitActivity, closeModal }) => {
  const [activityData, setActivityData] = useState(
    activity ? { name: activity.name } : { name: '' }
  );

  const handleFieldChange = (field, value) => {
    setActivityData({ ...activityData, [field]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let activityParams = Object.assign({}, activityData);

    if (lesson) {
      activityParams.lesson_id = lesson.id;
    }

    if (activity) {
      activityParams.id = activity.id;
    }

    submitActivity(activityParams).then(res => closeModal());
  }

  return (
    <form
      className="CreationForms-Form"
      onSubmit={e => handleSubmit(e)}
    >
      <div className="CreationForms-InputBox">
        <InputBox
          className="InputBox-LogInBox"
          value={activityData.name}
          placeholder="Name"
          onChange={e => handleFieldChange('name', e.currentTarget.value)}
        />
      </div>

      <div className="CreationForms-Button">
        <ButtonBox
          className="CreationDashboards-ButtonBox"
          text="Submit"
        />
      </div>
    </form>
  )
}

export default CreateEditActivity;
