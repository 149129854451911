import { combineReducers } from 'redux';

import activities from './Entities/ActivitiesReducer';
import breadcrumbs from './Entities/BreadcrumbsReducer';
import comments from './Entities/CommentsReducer';
import courses from './Entities/CoursesReducer';
import coursesTree from './Entities/CoursesTreeReducer';
import courseTypes from './Entities/CourseTypesReducer';
import coursePackages from './Entities/CoursePackagesReducer';
import coursePledges from './Entities/CoursePledgesReducer';
import credentialTests from './Entities/CredentialTestsReducer'
import inventories from './Entities/InventoriesReducer';
import lessons from './Entities/LessonsReducer';
import mediaTypes from './Entities/MediaTypesReducer';
import topics from './Entities/TopicsReducer';
import quizzes from './Entities/QuizzesReducer';
import lingos from './Entities/LingosReducer';
import platforms from  './Entities/PlatformsReducer';
import pledges from './Entities/PledgesReducer';
import canopySubscriptions from './Entities/CanopySubscriptionsReducer';
import recurringSubscriptions from './Entities/RecurringSubscriptionReducer';

export default combineReducers({
    activities,
    breadcrumbs,
    comments,
    courses,
    coursesTree,
    courseTypes,
    coursePackages,
    credentialTests,
    inventories,
    lessons,
    mediaTypes,
    topics,
    quizzes,
    lingos,
    platforms,
    pledges,
    coursePledges,
    canopySubscriptions,
    recurringSubscriptions,
});
