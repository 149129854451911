import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { fetchProducts, clearCoursePackages } from '../../../Actions/CoursePackageActions';
import { fetchInventories } from '../../../Actions/InventoryActions';
import { clearStripeFlashAndErrors } from '../../../Actions/StripeActions';
import CoursePackageBox from '../../Presentational/CoursePackageBox/CoursePackageBox';
import Loader from '../../Composed/Loader/Loader';
import TextBox from '../../Basic/TextBox/TextBox';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { courseLanguageList } from '../../../Constants/CourseConstants';

const mapStateToProps = (state) => ({
    coursePackages: state.entities.coursePackages,
    inventories: state.entities.inventories,
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
    subscribedCourses: state.dashboard.courses,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInventories: () => dispatch(fetchInventories()),
    fetchProducts: () => dispatch(fetchProducts()),
    clearCoursePackages: () => dispatch(clearCoursePackages()),
    clearStripeFlashAndErrors: () => dispatch(clearStripeFlashAndErrors()),
});

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const UserDashboardCoursePackages = ({
    fetchProducts,
    fetchInventories,
    coursePackages,
    inventories,
    courses,
    currentUser,
    clearCoursePackages,
    subscribedCourses,
    clearStripeFlashAndErrors,
}) => {
    const [loading, setLoading] = useState(true)
    const [language, setLanguage] = useState('SP');
    const queryString = new URLSearchParams(useLocation().search);

    useEffect(() => {
      Promise.all([
        clearCoursePackages(),
        fetchProducts(),
        fetchInventories(),
        clearStripeFlashAndErrors()
      ]).then(() => setLoading(false));
    }, [fetchProducts, clearCoursePackages, clearStripeFlashAndErrors]);

    const handleLearnSwitch = (e) => {
      setLanguage(e.currentTarget.checked ? 'EN' : 'SP')
    }

    const handleCoursePackageRender = () => (
        Object.values(coursePackages).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1).map(
            coursePackage => {
              if (!coursePackage.course_languages?.includes(language)) {
                  return null;
              }

              return (
                  <CoursePackageBox
                      key={coursePackage.id}
                      courses={courses}
                      coursePackage={coursePackage}
                      currentUser={currentUser}
                  />
              )
            }
        )
    );

    if (loading) {
      return <Loader message="Loading, Hang on!" />
    }

    if (!Object.keys(coursePackages).length) {
        return <div />;
    }

    return (
        <div>
          {
            queryString.has('conf') && (
              <div className="Confirm-User-Message">
                <div className="Left">
                  <i className="fa fa-check" />
                  <span>Thanks! Your account has been confirmed</span>
                </div>
                <div className="Right">
                  <i
                    className="fa fa-times"
                    onClick={(e) => e.currentTarget.parentElement.parentElement.style.display = 'none'}
                  />
                </div>
              </div>
            )
          }

          <div className="UserDashboardCoursePackages container Learn mt-50">
              <div className="CoursePackage-Title-Container">
                  <TextBox
                      className="CoursePackage-Title"
                      text={`Improve your Medical ${courseLanguageList[language]} with Canopy`}
                      tag="h1"
                  />
              </div>

              <div className="Canopy-Type-Container">
                  <div className="Canopy-Type">
                      <TextBox
                          className="Canopy-Type-Name"
                          text="CANOPY LEARN"
                      />

                      <TextBox
                          className="Canopy-Type-Desc"
                          text=""
                      />
                  </div>

                  <div className="Canopy-Switch">
                    <div class="switch-button">
                      <input class="switch-button-checkbox" type="checkbox" onClick={(e) => handleLearnSwitch(e)}></input>
                      <label class="switch-button-label" for=""><span class="switch-button-label-span">SPANISH</span></label>
                    </div>
                  </div>
              </div>

              <div className="UserDashboardCoursePackages-Packages__container">
                  {
                      handleCoursePackageRender()
                  }
              </div>
          </div>
          <div className="UserDashboardCoursePackages container Credential mt-0">
              <div className="Canopy-Type-Container">
                  <div className="Canopy-Type">
                      <TextBox
                          className="Canopy-Type-Name"
                          text="Canopy Credential"
                      />

                      <TextBox
                          className="Canopy-Type-Desc"
                          text=""
                      />
                  </div>

                  <div className="Canopy-Switch">
                  </div>
              </div>

              <div className="UserDashboardCoursePackages-Packages__container">
                {
                  Object.values(inventories).map((inventory, i) => {
                    return (
                      <div className={`CoursePackageBox ${i === 0 ? 'w-628' : ''}`} >
                        <div className="CoursePackageBox-DataBox">
                            <div className="CoursePackageBox-Series">
                                <TextBox
                                    text={inventory.banner}
                                    tag="h5"
                                />
                            </div>

                            <div className="CoursePackageBox-Name">
                                <h5>{inventory.name}</h5>
                                <p>{inventory.variant}</p>
                            </div>

                            <div className="CoursePackageBox-Price">
                              <p>{inventory.description}</p>
                              <span>{inventory.price === 0 ? 'FREE' : `$ ${(inventory.price / 100)}`}</span>
                            </div>

                            <div className="CoursePackageBox-Benefits">
                              {
                                inventory.benefits.map((benefit) => {
                                  return (
                                    <p>{benefit}</p>
                                  )
                                })
                              }
                            </div>

                            <div className="CoursePackageBox-Certificates-label">
                              <ul className="list-disc">
                                <li>
                                  <TextBox
                                      text="Includes Certificate of Bilingual Proficiency"
                                  />
                                  <p className="InfoText">*{inventory.disclaimer}</p>
                                </li>
                              </ul>
                            </div>
                        </div>
                        <div className="CoursePackageBox-ButtonBox">
                            <button class="ButtonBox-CoursePackageBox" onClick={(e) => { window.location = `${REACT_APP_ONE_DOMAIN}/checkout/${inventory.id}` }}><p>SELECT</p></button>
                        </div>
                      </div>
                      )
                  })
                }
              </div>
          </div>
        </div>
    );
};

UserDashboardCoursePackages.propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    coursePackages: PropTypes.objectOf(PropTypes.object).isRequired,
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({
        trial: PropTypes.bool,
    }).isRequired,
    clearCoursePackages: PropTypes.func.isRequired,
    subscribedCourses: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardCoursePackages);
