import React, { useState } from 'react';

import './Pagination.scss';

const Pagination = ({ totalRecords, page, setPage, per }) => {
  const totalPages = () => (
    Math.ceil(totalRecords / per) || 0
  );

  const renderPrevious = () => (
    <div className="Previous">
      <div
        className={`Item ${page === 1 ? 'Inactive' : 'Active'}`}
        onClick={() => (page !== 1 && setPage(page - 1))}
      >
        <i className="fa fa-angle-double-left Icon"></i> Previous
      </div>
    </div>
  );

  const renderNumbers = () => {
    let skip;

    return [...Array(totalPages())].map((e, i) => {
      const idx = i + 1;

      if (
        (idx === 1) || (idx === totalPages()) || (Math.abs(idx - page) < 2)
      ) {
        skip = false;
        return (
          <div key={idx} className="Number">
            <div
              className={`Item ${idx === page ? 'Active' : ''}`}
              onClick={() => setPage(idx)}
            >
              {idx}
            </div>
          </div>
        )
      }

      if (!skip) {
        skip = true;
        return (
          <div key={idx} className="Number">
            <div className="Item">...</div>
          </div>
        )
      }

      return null;
    });
  }

  const renderNext = () => (
    <div className="Next">
      <div
        className={`Item ${page === totalPages() ? 'Inactive' : 'Active'}`}
        onClick={() => (page !== totalPages() && setPage(page + 1))}
      >
        Next <i className="fa fa-angle-double-right Icon"></i>
      </div>
    </div>
  );

  return (
    <div className="Pagination-Container">
      {renderPrevious()}
      {renderNumbers()}
      {renderNext()}
    </div>
  )
}

export default Pagination;
