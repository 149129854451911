import axios from './AxiosUtil';

export const fetchUserCredentialTests = (userId) => (
  axios.get(`/api/credential_tests/users/${userId}`).then(
    res => res.data
  )
);

export const resetCredentialTest = (credentialTestId) => (
  axios.put(`/api/credential_tests/${credentialTestId}/reset`).then(
    res => res.data
  )
)

export const waiveCredentialTest = (credentialTestId) => (
  axios.put(`/api/credential_tests/${credentialTestId}/waive`).then(
    res => res.data
  )
)
