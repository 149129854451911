import  {
  RECEIVE_INVENTORY,
  CLEAR_INVENTORIES,
  RECEIVE_INVENTORY_ERRORS
} from '../../Actions/InventoryActions';

const InventoriesReducer = (state = {}, action)  => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_INVENTORY:
      return Object.assign({}, state, action.payload.data);
    case CLEAR_INVENTORIES:
      return {};
    case RECEIVE_INVENTORY_ERRORS:
      return Object.assign({}, state, action.errors);
    default:
      return state;
  }
}

export default InventoriesReducer;
