import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorFlashBox from '../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import DashboardFormBoxNoEdit from '../../../Composed/DashboardFormBox/DashboardFormBoxNoEdit';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import './BatchProfileUpload.scss';

const BatchProfileUpload = ({ createBatchProfileUpload }) => {
  const [attrs, setAttrs] = useState({ file: null });
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [processing, setProcessing] = useState(false);

  const validateForm = () => {
    let errors = {};

    if (!attrs.file) {
      errors['Excel:'] = 'Please attach a file!';
    }

    return errors;
  }

  const handleUpload = (e) => {
    e.preventDefault();

    let errors = validateForm();
    setErrors(errors);
    setFlash({});

    if (Object.keys(errors).length === 0) {
      setProcessing(true);

      const formData = new FormData();
      formData.append('batch_profile_upload[file]', attrs.file);

      createBatchProfileUpload(formData).then((res) => {
        res.error ? setErrors({ '': res.error }) : setFlash({ message: res.flash });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setProcessing(false);
      });
    }
  }

  return (
    <div className="BatchProfileUpload-Container">
      <ErrorFlashBox errors={errors} flash={flash} />

      {processing && <MirrorLoader message="Processing..." />}

      <DashboardFormBoxNoEdit title="Learner Profiles Upload">
        <ul className="Instructions list-disc">
          <li>Upload an Excel file that contains information about your institution learners to fill out their profiles.</li>
          <li>The file should be a valid Excel file.</li>
          <li>The sample file can be downloaded by clicking <a href="https://prod-canopy-railsapi-media-bucket.s3.amazonaws.com/BatchProfileUploadSample.xlsx" className="Download-Sample" target="_blank" rel="noopener noreferrer">here</a>.</li>
        </ul>

        <div className="File-Field">
          <label className="Label">Excel:</label>
          <input
            type="file"
            className="Input"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => setAttrs({ ...attrs, file: e.currentTarget.files[0] })}
          />
        </div>

        <div className="Submit-Button">
          <ButtonBox
            className="Btn Primary-Btn"
            text={<span className="Primary-Btn-Text">Upload</span>}
            onClick={(e) => handleUpload(e)}
          />
        </div>
      </DashboardFormBoxNoEdit>
    </div>
  )
}

BatchProfileUpload.propTypes = {
  createBatchProfileUpload: PropTypes.func.isRequired,
}

export default BatchProfileUpload;
