import { CLEAR_INSTITUTION_RESOURCES, RECEIVE_INSTITUTION_RESOURCES, RECEIVE_INSTITUTION_RESOURCE } from '../../Actions/InstitutionResourceActions';
import { RECEIVE_INSTITUTION_RESOURCE_USERS } from '../../Actions/InstitutionResourceUserActions';

const InstitutionResourcesReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_INSTITUTION_RESOURCES:
      return Object.assign({}, state, { total: action.payload.total, data: action.payload.institutionResources });
    case RECEIVE_INSTITUTION_RESOURCE: case RECEIVE_INSTITUTION_RESOURCE_USERS:
      return Object.assign({}, state, { ...state, total: (state.total || 1), data: { ...state.data, [action.payload.id]: action.payload } });
    case CLEAR_INSTITUTION_RESOURCES:
      return {}
    default:
      return state;
  }
};

export default InstitutionResourcesReducer;
