import {
  RECEIVE_WORKPLACE,
  RECEIVE_COURSE_ID,
  RECEIVE_SECTION_INDEX,
  RECEIVE_QUESTION_INDEX,
  CLEAR_WORKPLACE,
  CLEAR_COURSE_ID,
  CLEAR_SECTION_INDEX,
  CLEAR_QUESTION_INDEX,
} from '../Actions/NavigationActions';

const _initialState = {
  currentCourseId: null,
  currentSectionIndex: null,
};

export default (state = _initialState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_WORKPLACE:
      return Object.assign({}, state, { currentWorkplace: action.workplace });
    case RECEIVE_COURSE_ID:
      return Object.assign({}, state, { currentCourseId: action.id });
    case RECEIVE_SECTION_INDEX:
      return Object.assign({}, state, { currentSectionIndex: action.index });
    case RECEIVE_QUESTION_INDEX:
      return Object.assign({}, state, { currentQuestionIndex: action.index });
    case CLEAR_WORKPLACE:
      return Object.assign({}, state, { currentWorkplace: null });
    case CLEAR_COURSE_ID:
      return Object.assign({}, state,  _initialState);
    case CLEAR_SECTION_INDEX:
      return Object.assign({}, state, { currentSectionIndex: null });
    case CLEAR_QUESTION_INDEX:
      return Object.assign({}, state, { currentQuestionIndex: null });
    default:
      return state;
  }
}
