import React, { useState } from 'react';

import OverviewContainer from './Overview/OverviewContainer';
import DetailsContainer from './Details/DetailsContainer';

import './NetPromoterScores.scss';

const NetPromoterScores = () => {
  const [activeTab, setActiveTab] = useState('Overview');

  const tabs = ['Overview', 'Details'];

  const renderContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <OverviewContainer />;
      case 'Details':
        return <DetailsContainer />;
      default:
        return null;
    }
  }

  return (
    <div className="NetPromoterScores-Container">
      <div className="Title">
        Net Promoter Scores
      </div>
      <div className="Block">
        <div className="Header">
          <div className="Tab-Holder">
            {
              tabs.map((tab, idx) => (
                <div
                  key={`tab-${idx}`}
                  className={`Tab ${activeTab === tab ? 'Active' : ''}`}
                  onClick={() => (activeTab !== tab && setActiveTab(tab))}
                >
                  {tab}
                </div>
              ))
            }
          </div>
        </div>

        <div className="Content">
          {renderContent()}
        </div>
      </div>
    </div>
  )
};

export default NetPromoterScores;
