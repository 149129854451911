import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../../Composed/Loader/Loader';
import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../../../Basic/ImageBox/ImageBox';

import { ranksList } from '../../../../../Constants/EngageConstants';

import './Leaderboard.scss';

const Leaderboard = ({ currentUser, leaderboard, streakType, close, clearLeaderboard, fetchLeaderboard }) => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('global');

  useEffect(() => {
    clearLeaderboard();
    fetchLeaderboard(streakType).then(() => setLoading(false));
  }, [clearLeaderboard, fetchLeaderboard, streakType]);

  const userDisplayName = (streak) => {
    if (currentUser.id === streak.user.id){
      return `${streak.user.firstName} ${streak.user.lastName}`;
    } else if (!streak.streakConfig?.opt_in?.[tab]) {
      return '*****';
    } else {
      return `${streak.user.firstName} ${streak.user.lastName}`;
    }
  }

  const buildRankImageSrc = (idx) => (
    ranksList[(idx + 1).toString()]?.imageSource
  )

  const renderTable = () => (
    <div className="Leaderboard-Table">
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th>Streak Score</th>
          </tr>
        </thead>
        <tbody>
          {
            leaderboard[tab].map((streak, idx) => (
              <tr
                key={`Streak-${tab}-${streak.id}`}
                className={`${currentUser.id === streak.user.id && 'CurrentUser-Row' }`}
              >
                <td>
                  {(idx < 3) ? <ImageBox src={buildRankImageSrc(idx)} /> : (idx + 1)}
                </td>
                <td className="tl">{userDisplayName(streak)}</td>
                <td>{streak.current}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );

  const DoNotHaveEnoughOptIns = () => {
    const optOuts = leaderboard[tab].filter(streak => (
      streak.streakConfig.opt_in.institutional === false
    ));

    return optOuts.length >= 1;
  }

  const renderLeaderbaord = () => {
    if ((leaderboard[tab] || []).length === 0) {
      return <div className="No-Record">No one has made progress yet. Check back later or be the first one to make progress!</div>
    }

    if ((tab === 'institutional') && ((leaderboard[tab] || []).length <= 3) && DoNotHaveEnoughOptIns()) {
      return <div className="Not-Enough-Optin">Not enough users at this institution have opted into the leaderboard to show results!</div>
    }

    return renderTable();
  }

  return (
    <div className="LeaderBoard-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="Modal-Title">
              <ImageBox className="Leaderboard-Banner" src="leaderboard_banner.png" />
              <div className="Title">Leaderboard</div>
            </div>
          </div>

          <div className="modal-body">
            <div className="LeaderBoard-Tabs">
              {
                ['global', 'institutional'].map((leaderboardType, idx) => (
                  <div
                    key={`LeaderBoard-Type-${idx}`}
                    className={`Tab ${leaderboardType === tab && 'Active' }`}
                    onClick={() => setTab(leaderboardType)}
                  >
                    {leaderboardType}
                  </div>
                ))
              }
            </div>
            {loading ? <Loader /> : renderLeaderbaord()}
          </div>

          <div className="modal-footer">
            <ButtonBox
              className="Secondary-Btn Close-Button"
              text={<span className="Secondary-Btn-Text">Close</span>}
              onClick={() => close()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Leaderboard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  leaderboard: PropTypes.object,
  streakType: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  clearLeaderboard: PropTypes.func.isRequired,
  fetchLeaderboard: PropTypes.func.isRequired,
}

export default Leaderboard;
