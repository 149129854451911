import { connect } from 'react-redux';

import {
    checkExistingUser,
    clearCreateUserErrors,
    createUser,
    createUserFormValidatonErrors,
} from '../../../Actions/CreateUserActions';
import { fetchInstitutions } from '../../../Actions/InstitutionActions';
import CreateUser from './CreateUser';

const mapStateToProps = (state) => (
    {
        currentUser: state.session.currentUser,
        errors: state.errors.createUser,
        flash: state.flash.createUser,
        groups: state.admin.groups,
    }
);

const mapDispatchToProps = (dispatch) => (
    {
        checkExistingUser: user => dispatch(checkExistingUser(user)),
        clearCreateUserErrors: () => dispatch(clearCreateUserErrors()),
        createUser: user => dispatch(createUser(user)),
        createUserFormValidatonErrors: errors => dispatch(createUserFormValidatonErrors(errors)),
        fetchInstitutions: () => dispatch(fetchInstitutions()),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateUser);
