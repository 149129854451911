import './AudioPlayButton.css';

import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import EnvironmentUtil from '../../../Util/EnvironmentUtil';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

/*
    Assume this component requires some design from this link
    to fix the errors we were having mentioned in the link:
    https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
*/
const AudioPlayButton = ({ src, autoplay, nextPlay, downloadable = false }) => {
    const audioRef = useRef(null);
    const iconRef = useRef(null);
    const downloadRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [srcPath, setSrcPath] = useState('');

    useEffect(() => {
        if (src && typeof src === 'string') {
            setSrcPath(EnvironmentUtil().buildURL(src));
        }

    }, [src]);

    useEffect(() => {
        // Assume we want to preload the audio file before playing it.
        // Assume that the user will be fetching a link from the back-end
        // to directly load the file from S3.
        setLoading(true)
        if (typeof srcPath === 'string' && srcPath.length > 0) {
            fetch(srcPath)
                .then(response => response.blob())
                .then(blob => {
                    setLoading(false);
                    if (audioRef?.current) {
                        audioRef.current.src = URL.createObjectURL(blob);
                        audioRef.current.load();
                    }
                    if (downloadRef?.current) {
                        downloadRef.current.href = URL.createObjectURL(blob);
                    }
                })
                .catch(errors => {
                    Sentry.withScope(scope => {
                        scope.setTag('component name', 'AudioPlayButton');
                        scope.setTag('component snapshot', {
                            audioRef: {
                                current: {
                                    src: audioRef?.current?.src,
                                },
                            },
                            props: {
                                ...autoplay,
                                ...src,
                            },
                            state: {
                                ...srcPath,
                            },
                        });
                        scope.setTag('environment', process.env.NODE_ENV);
                        Sentry.captureException(errors);
                    });
                });
        }
    }, [srcPath]);

    const playAudio = () => {
        const playPromise = audioRef.current.play();
        playPromise.then(() => {
            // Audio playback started
        }).catch(errors => {
            // Audio playback failed
            Sentry.captureException(errors);
        });
    };

    const handlePlayButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (audioRef.current.paused) {
            playAudio();
            iconRef.current.setAttribute('class', 'fa fa-pause');
        } else {
            audioRef.current.pause();
            iconRef.current.setAttribute('class', 'fa fa-play');
        }
    };

    const handleAudioEnd = () => {
        iconRef.current.setAttribute('class', 'fa fa-play');
        if (nextPlay) {
            nextPlay()
        }
    }

    return (
        <div className="Play-Download-Container">
            <div
                className={
                    autoplay
                        ? 'AudioPlayButton autoplay'
                        : 'AudioPlayButton'
                }
            >
                <ButtonBox
                    className="ButtonBox-AudioPlayButton"
                    disabled={loading}
                    text={<i className={loading ? "fas fa-circle-notch fa-spin" : "fa fa-play"} ref={iconRef} />}
                    tag="h3"
                    onClick={handlePlayButtonClick}
                />

                <audio
                    ref={audioRef}
                    onEnded={() => handleAudioEnd()}
                    onPlay={() => gaEvent('audio_play', { src })}
                />
            </div>

            {
                downloadable &&
                <div className="Download-Button-Holder">
                    <a
                        ref={downloadRef}
                        download="audio"
                        rel="noreferrer"
                    >
                        <ButtonBox
                            className="Download-Button"
                            disabled={loading}
                            text={<i className={loading ? "fas fa-circle-notch fa-spin" : "fa fa-download Download-Icon"}/>}
                        />
                    </a>
                </div>
            }
        </div>

    );
};

AudioPlayButton.propTypes = {
    autoplay: PropTypes.bool,
    nextPlay: PropTypes.func,
    src: PropTypes.string.isRequired,
};

AudioPlayButton.defaultProps = {
    autoplay: false,
};

export default AudioPlayButton;
