// Google Analytics
export const gaScript = (currentUserId, gaMeasurementId) => {
  if (document.querySelector("[data-identifier='google-analytics-4-script']")) {
    return null;
  }

  let scriptTag = document.createElement('script');

  scriptTag.setAttribute('data-identifier', 'google-analytics-4-script');
  scriptTag.append(
    document.createRange().createContextualFragment(`
      <script async src="https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}"></script>
      <script>
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${gaMeasurementId}', {
            'user_id': ${currentUserId}
          });
      </script>
    `)
  );

  document.getElementsByTagName('head')[0].append(scriptTag);
}

export const gaEvent = (event, eventParameters = {}) => (
  window.gtag('event', event, eventParameters)
)
