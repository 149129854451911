import axios from '../../AxiosUtil';

export const fetchPledges = () => {
  return axios.get(`/api/stats/users/compliances/pledges`).then(
    res => res.data
  )
};

export const fetchMyAchievements = () => {
  return axios.get(`/api/stats/users/compliances/my_achievements`).then(
    res => res.data
  )
};

export const fetchMyActivities = () => {
  return axios.get(`/api/stats/users/compliances/my_activities`).then(
    res => res.data
  )
};
