import { RECEIVE_QUIZ } from '../../Actions/QuizActions';
import { RECEIVE_TOPICS, RECEIVE_ONLY_TOPICS } from '../../Actions/TopicActions';
import { RECEIVE_SECTION } from '../../Actions/SectionActions';
import { RECEIVE_QUESTION } from '../../Actions/QuestionActions';
import { RECEIVE_ANSWER } from '../../Actions/AnswerActions';
import { RECEIVE_TEXT_CONTENT } from '../../Actions/TextContentActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_QUIZ:
            return Object.assign({}, state, { [action.payload.id]: action.payload });
        case RECEIVE_TOPICS:
            return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
        case RECEIVE_ONLY_TOPICS:
            return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
        case RECEIVE_SECTION:
            if (action.payload.sectionable_type === 'Quiz') {
                return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
            } 
            return state;
        case RECEIVE_QUESTION:
            if (action.payload.sectionable_type === 'Quiz') {
                return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
            }
            return state;
        case RECEIVE_ANSWER:
            if (action.payload.sectionable_type === 'Quiz') {
                return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
            }
            return state;
        case RECEIVE_TEXT_CONTENT:
            if (action.payload.sectionable_type === 'Quiz') {
                return Object.assign({}, state, { [action.payload.quiz.id]: action.payload.quiz });
            }
            return state;
        default:
            return state;
    }
};
