import axios from './AxiosUtil';

export const createCoursePledges = (course_id) => {
  return axios.post(`/api/course_pledges?course_id=${course_id}`).then(
    res => res.data
  )
};

export const fetchCoursePledges = () => {
  return axios.get('/api/course_pledges').then(
    res => res.data
  )
};

export const scaleUpCoursePledge = (course_pledge) => {
  return axios.patch(`/api/course_pledges/${course_pledge.id}/scale_up`, { course_pledge }).then(
    res => res.data
  );
};

export const scaleDownCoursePledge = (course_pledge) => {
  return axios.patch(`/api/course_pledges/${course_pledge.id}/scale_down`).then(
    res => res.data
  );
};
