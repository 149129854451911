import { RECEIVE_ACTIVITY } from '../../Actions/ActivityActions';
import { RECEIVE_TOPICS, RECEIVE_ONLY_TOPICS } from '../../Actions/TopicActions'
import { RECEIVE_SECTION } from '../../Actions/SectionActions';
import { RECEIVE_QUESTION } from '../../Actions/QuestionActions';
import { RECEIVE_ANSWER } from '../../Actions/AnswerActions';
import { RECEIVE_TEXT_CONTENT } from '../../Actions/TextContentActions';
import { RECEIVE_TABLE } from '../../Actions/TableActions';
import { RECEIVE_TABLE_ENTRY } from '../../Actions/TableEntryActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ACTIVITY:
      return Object.assign({}, state, { [action.payload.id]: action.payload });
    case RECEIVE_TOPICS:
      return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
    case RECEIVE_ONLY_TOPICS:
      return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
    case RECEIVE_SECTION:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    case RECEIVE_QUESTION:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    case RECEIVE_ANSWER:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    case RECEIVE_TEXT_CONTENT:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    case RECEIVE_TABLE:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    case RECEIVE_TABLE_ENTRY:
      if (action.payload.sectionable_type === 'Activity') {
        return Object.assign({}, state, { [action.payload.activity.id]: action.payload.activity });
      }
      return state;
    default:
      return state;
  }
};
