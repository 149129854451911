export const institutionTypeOptions = {
  '': 'Please select',
  'hospital': 'Hospital',
  'clinic': 'Clinic',
  'medical_school': 'Medical School',
  'nursing_school': 'Nursing School',
  'other': 'Other',
};

export const departmentsList = {
  '': 'Please select',
  'allergy_and_immunology': 'Allergy and immunology',
  'anesthesiology': 'Anesthesiology',
  'dermatology': 'Dermatology',
  'diagnostic_radiology': 'Diagnostic radiology',
  'emergency_medicine': 'Emergency medicine',
  'family_medicine': 'Family medicine',
  'internal_medicine': 'Internal medicine',
  'medical_genetics': 'Medical genetics',
  'neurology': 'Neurology',
  'nuclear_medicine': 'Nuclear medicine',
  'obstetrics_and_gynecology': 'Obstetrics and gynecology',
  'ophthalmology': 'Ophthalmology',
  'pathology': 'Pathology',
  'pediatrics': 'Pediatrics',
  'physical_medicine_and_rehabilitation': 'Physical medicine and rehabilitation',
  'preventive_medicine': 'Preventive medicine',
  'psychiatry': 'Psychiatry',
  'radiation_oncology': 'Radiation oncology',
  'surgery': 'Surgery',
  'urology': 'Urology',
};
