import { connect } from 'react-redux';

import {
  clearInstitutions,
  fetchInstitution,
  fetchInstitutions,
} from '../../../Actions/InstitutionActions';
import {
  clearInviteUsersErrors,
  createInviteUsers,
  inviteUsersFormValidatonErrors,
  receiveInviteUsersConfirmation,
} from '../../../Actions/InviteUserActions';

import InviteUsers from './InviteUsers';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  institutions: Object.values(state.admin.institutions),
  errors: state.errors.inviteUsers,
  flash: state.flash.inviteUsers,
});

const mapDispatchToProps = (dispatch) => ({
  clearInstitutions: () => dispatch(clearInstitutions()),
  fetchInstitutions: () => dispatch(fetchInstitutions()),
  fetchInstitution: (id) => dispatch(fetchInstitution(id)),
  clearInviteUsersErrors: () => dispatch(clearInviteUsersErrors()),
  createInviteUsers: (formData) => dispatch(createInviteUsers(formData)),
  inviteUsersFormValidatonErrors: errors => dispatch(inviteUsersFormValidatonErrors(errors)),
  receiveInviteUsersConfirmation: (message) => dispatch(receiveInviteUsersConfirmation(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUsers);
