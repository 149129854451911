import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Confirm from '../../Composed/Confirm/Confirm';
import AddUserToGroup from '../../Forms/AddUserToGroup/AddUserToGroup';
import { fetchUsers } from '../../../Actions/UserActions';
import { fetchGroup, editGroup, deleteGroup } from '../../../Actions/GroupActions';
import Loader from '../../Composed/Loader/Loader';

const propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        users: PropTypes.arrayOf(PropTypes.number),
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    users: PropTypes.objectOf(PropTypes.object).isRequired,
    groupId: PropTypes.string.isRequired,
    fetchGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    editGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    groupId: ownProps.match.params.groupId,
    group: state.admin.groups[ownProps.match.params.groupId],
    users: state.admin.users.users.data,
    hisory: ownProps.hisory,
});

const mapDispatchToProps = dispatch => ({
    fetchGroup: groupId => dispatch(fetchGroup(groupId)),
    fetchUsers: () => dispatch(fetchUsers()),
    deleteGroup: groupId => dispatch(deleteGroup(groupId)),
    editGroup: group => dispatch(editGroup(group)),
});

const GroupDetails = ({ group, groupId, users, history, fetchGroup, fetchUsers, deleteGroup, editGroup }) => {
    const [loading, setLoading] = useState(true);
    const [formOpen, setFormOpen] = useState(false);

    useEffect(() => {
        Promise.all([
            fetchGroup(groupId),
            fetchUsers()
        ]).then(() => setLoading(false));
    }, [fetchGroup, groupId, fetchUsers]);

    const handleDeleteGroup = id => () => {
        deleteGroup(id).then(
            () => history.push('/account/groups/'),
        );
    };

    const renderUsers = () => {
        const userList = users.filter(user => group.users.includes(user.id)).map(groupUser => (
            <div
                className="B2BAdminDashboard-GroupList__line"
                key={groupUser.id}
            >
                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={groupUser.first_name}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={groupUser.last_name}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={groupUser.email}
                />
            </div>
        ));
        return (
            <div className="B2BAdminDashboard-GroupList">
                <div className="B2BAdminDashboard-GroupList__line">
                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="First Name"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Last Name"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Email"
                        tag="h5"
                    />
                </div>
                {userList}
            </div>
        );
    };

    if (loading) {
        return <Loader />
    };

    if (!group) {
        return <div />;
    }

    return (
        <div className="B2BAdminDashboard">
            <div className="B2BAdminDashboard-Title">
                <TextBox
                    text={group.name}
                    tag="h4"
                    style={{
                        color: COLORS.Blue,
                    }}
                />

                <Confirm
                    text={`Are you sure you want to ${group.status === 'active' ? 'archive' : 'unarchive'} this group?`}
                    confirm={handleDeleteGroup(group.id)}
                >
                    {
                        open => (
                            <ButtonBox
                                text={`${group.status === 'active' ? "Archive Group" : "Unarchive Group"}`}
                                className={`B2BAdminDashboard-Button ${group.status === 'active' ? 'red' : ''}`}
                                onClick={open}
                            />
                        )
                    }
                </Confirm>
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <TextBox
                    text="CURRENT GROUP MEMBERS"
                    style={{
                        margin: '20px 0',
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                {
                    group.status === 'active' &&
                    <div className="B2BAdminDashboard-GroupList">
                        <ButtonBox
                            text="Add Members"
                            className="B2BAdminDashboard-Button green"
                            onClick={() => setFormOpen(true)}
                        />
                    </div>
                }

                {renderUsers()}
            </div>

            {
                formOpen
                && (
                    <AddUserToGroup
                        users={users}
                        setFormOpen={setFormOpen}
                        editGroup={editGroup}
                        group={group}
                    />
                )
            }
        </div>
    );
};

GroupDetails.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupDetails));
