import * as APIUtil from '../Util/UserPreferenceApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_USER_PREFERENCES = 'RECEIVE_USER_PREFERENCES';
export const RECEIVE_USER_PREFERENCES_ERRORS = 'RECEIVE_USER_PREFERENCES_ERRORS';

export const receiveUserPreferences = payload => ({
  type: RECEIVE_USER_PREFERENCES,
  payload,
});

export const receiveUserPreferencesErrors = errors => ({
  type: RECEIVE_USER_PREFERENCES_ERRORS,
  errors,
});

export const updateUserPreference = (userPreference) => dispatch => (
  APIUtil.updateUserPreference(userPreference).then(
    payload => dispatch(receiveUserPreferences(payload)),
    errors  => dispatch(receiveUserPreferencesErrors(ErrorResponseUtil(errors))),
  )
);
