import React from 'react';
import PropTypes from 'prop-types';

import './ScoreCardDescription.scss';

const ScoreCardDescription = ({
  title, canopyLevel, description,
  isQbsScore, unrateable, vertical,
}) => (
  <div className={`Score-Card-Description ${vertical ? 'Vertical' : 'Horizontal'}`}>
    <div className="Description-Title">
      {title}
    </div>
    {
      description ? (
        <>
          <div className={`Description-Level ${isQbsScore ? 'Qbs-Score' : 'Non-Qbs-Score'}`}>
            {canopyLevel && <div className="Canopy-Level">{canopyLevel}</div>}
          </div>
          <div className="Description-Desc">{description}</div>
        </>
      ) : (
        <>
          <div className={`Description-Level ${unrateable ? 'unrateable' : 'waived'}`}>
            <div className="Canopy-Level">
              <div>
                {unrateable ? 'Unrateable' : 'Self-Attestation'}
              </div>
            </div>
          </div>
          <div className="Description-Desc">
            {
              unrateable
                ? 'Based on your reponses to the assessment, we were not able to rate this skill.'
                : 'Based on your response to the Self-Assessment Questionnaire about language background (i.e., your native language, language of medical instruction, and dominant language currently in use), you indicate that you are a native or nativelike English speaker.'
            }
          </div>
        </>
      )
    }
  </div>
);

ScoreCardDescription.propTypes = {
  canopyLevel: PropTypes.string,
  description: PropTypes.string,
  isQbsScore: PropTypes.bool,
  title: PropTypes.string.isRequired,
  unrateable: PropTypes.bool,
  vertical: PropTypes.bool,
}

export default ScoreCardDescription;
