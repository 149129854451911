import React from 'react';

import './ScoreCardCopyright.scss';

const ScoreCardCopyright = () => (
  <div className="Score-Card-Copyright">
    Copyright © Canopy Innovations, Inc. Support for Canopy Credential was provided by the National Institutes of Health (NIH).
  </div>
)

export default ScoreCardCopyright;
