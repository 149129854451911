import React from 'react';

import './TextContentBox.css';
import { COLORS } from '../../../Style/Style';
import FunctionBox from '../../Composed/FunctionBox/FunctionBox';
import QuestionBox from '../../Quiz/QuestionBox/QuestionBox';
import TableBox from '../TableBox/TableBox';

import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';

const TextContentBox = ({
    section,
    editTextContent,
    deleteTextContent,
    uploadTextContentAudio,
    createTable,
    editTable,
    deleteTable,
    createTableEntry,
    editTableEntry,
    createQuestion,
    editAnswer,
    editQuestion,
    deleteQuestion,
    uploadAudio,
    creation,
    ...otherProps
}) => (
    <>
        {
            section.text_contents.map(
                (text_content, idx) => (
                    <div key={idx}>
                        {
                            creation
                                && (
                                    <div className="TextContentBox-FunctionBox">
                                        <FunctionBox
                                            submitMedia={uploadTextContentAudio}
                                            model="text_content"
                                            entity={text_content}
                                            editEntity={editTextContent}
                                            deleteEntity={deleteTextContent}
                                            createTable={createTable}
                                        />
                                    </div>
                                )
                        }

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <QuestionBox question={text_content} creation={creation}>
                                <div
                                    style={{ color: COLORS.Blue }}
                                    className="ql-editor"
                                    dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(text_content.text) }}
                                />
                            </QuestionBox>
                        </div>

                        {
                            text_content.table
                                && (
                                    <div className="TextContentBox-TableBox">
                                        {
                                            creation
                                                && (
                                                    <div style={{ marginBottom: "20px"}}>
                                                        <FunctionBox
                                                            model="table"
                                                            entity={text_content.table}
                                                            editEntity={editTable}
                                                            deleteEntity={deleteTable}
                                                        />
                                                    </div>
                                                )
                                        }

                                        <TableBox
                                            table={text_content.table}
                                            creation={creation}
                                            createTableEntry={createTableEntry}
                                            editTableEntry={editTableEntry}
                                            createQuestion={createQuestion}
                                            section={section}
                                            editAnswer={editAnswer}
                                            editQuestion={editQuestion}
                                            deleteQuestion={deleteQuestion}
                                            uploadAudio={uploadAudio}
                                            { ...otherProps }
                                        />
                                    </div>
                                )
                        }
                    </div>
                ),
            )
        }
    </>
);

export default TextContentBox;
