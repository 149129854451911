import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../AutoComplete/AutoComplete';
import RadioBox from '../../../Basic/RadioBox/RadioBox';

import '../SelectCourseEntity.scss';

const SelectSectionable = ({ courses, selectionCallBack }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedSectionable, setSelectedSectionable] = useState(null);

  const handleCourseSelection = (e) => {
    setSelectedCourse(e);
    setSelectedLesson(null);
    setSelectedSectionable(null);
    selectionCallBack({ model: null, id: null });
  }

  const renderCourseInput = () => {
    const selectableCourses = Object.values(courses || {});

    if (selectableCourses.length === 0) {
      return <div className="Empty-Options">No courses available for selection!</div>
    }

    return (
      <div className="Input-Container Course-Input-Container">
        <label className="Label">Course</label>
        <AutoComplete
          className="Select"
          options={selectableCourses.map((crs) => { return { value: crs.id, label: crs.name } })}
          value={selectedCourse}
          onChange={(e) => handleCourseSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  const handleLessonSelection = (e) => {
    setSelectedLesson(e);
    setSelectedSectionable(null);
    selectionCallBack({ model: null, id: null });
  }

  const renderLessonInput = () => {
    const selectableLessons = Object.values(courses[selectedCourse.value].lessons || {});

    if (selectableLessons.length === 0) {
      return <div className="Empty-Options">The selected course has no lessons in it!</div>
    }

    return (
      <div className="Input-Container Lesson-Input-Container">
        <label className="Label">Lesson</label>
        <AutoComplete
          className="Select"
          options={selectableLessons.map((lsn) => { return { value: lsn.id, label: lsn.name } })}
          value={selectedLesson}
          onChange={(e) => handleLessonSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  const handleSectionableSelection = (model, id) => {
    setSelectedSectionable({ model, id });
    selectionCallBack({ model, id });
  }

  const renderSectionableInput = () => {
    const selectableActivity = courses[selectedCourse.value].lessons[selectedLesson.value].activity;
    const selectableTopics   = courses[selectedCourse.value].lessons[selectedLesson.value].topics;
    const selectableQuiz     = courses[selectedCourse.value].lessons[selectedLesson.value].quiz;

    if (!selectableActivity && !selectableTopics && !selectableQuiz) {
      return <div className="Empty-Options">The selected lesson has no activity, topics, or quiz in it!</div>
    }

    return (
      <div className="Input-Container Sectionable-Input-Container">
        {
          selectableActivity &&
          <RadioBox
            className="sectionable"
            name="sectionable"
            text="Activity"
            checked={selectedSectionable?.model === 'Activity'}
            onChange={() => handleSectionableSelection('Activity', selectableActivity.id)}
          />
        }

        {
          selectableTopics &&
          <RadioBox
            className="sectionable"
            name="sectionable"
            text="Topic"
            checked={selectedSectionable?.model === 'Topic'}
            onChange={() => handleSectionableSelection('Topic', null)}
          />
        }

        {
          selectableQuiz &&
          <RadioBox
            className="sectionable"
            name="sectionable"
            text="Quiz"
            checked={selectedSectionable?.model === 'Quiz'}
            onChange={() => handleSectionableSelection('Quiz', selectableQuiz.id)}
          />
        }
      </div>
    )
  }

  const handleTopicSelection = (e) => {
    setSelectedSectionable({ model: 'Topic', id: e.value });
    selectionCallBack({ model: 'Topic', id: e.value });
  }

  const renderTopicInput = () => {
    const selectableTopics = Object.values(courses[selectedCourse.value].lessons[selectedLesson.value].topics || {});

    if (selectableTopics.length === 0) {
      return <div className="Empty-Options">The selected lesson has no topics in it!</div>
    }

    return (
      <div className="Input-Container Topic-Input-Container">
        <label className="Label">Topic</label>
        <AutoComplete
          className="Select"
          options={selectableTopics.map((tpc) => { return { value: tpc.id, label: tpc.name } })}
          onChange={e => handleTopicSelection(e)}
          isMulti={false}
        />
      </div>
    )
  }

  return (
    <div className="Select-Course-Entity-Container Select-Sectionable-Container">
      {renderCourseInput()}
      {selectedCourse && renderLessonInput()}
      {selectedLesson && renderSectionableInput()}
      {selectedSectionable?.model === 'Topic' && renderTopicInput()}
    </div>
  )
}

SelectSectionable.propTypes = {
  courses: PropTypes.object,
  selectionCallBack: PropTypes.func.isRequired,
}

export default SelectSectionable;
