import { connect } from 'react-redux';

import CreationCoursesDashboard  from './CreationCoursesDashboard';
import { createCourse, clearCourseErrors } from '../../../Actions/CourseActions';
import { updateCourseType } from '../../../Actions/CourseTypeActions';
import { clearLessons } from '../../../Actions/LessonActions';
import { clearCourseId } from '../../../Actions/NavigationActions';

const mapStateToProps = (state) => {
  return {
    courses: state.entities.courses,
    courseTypes: state.entities.courseTypes,
    errors: state.errors.courses,
    flash: state.flash.courses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCourse: (course) => dispatch(createCourse(course)),
    updateCourseType: (course_type) => dispatch(updateCourseType(course_type)),
    clearLessons: () => dispatch(clearLessons()),
    clearCourseErrors: () => dispatch(clearCourseErrors()),
    clearCourseId: () => dispatch(clearCourseId()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreationCoursesDashboard);
