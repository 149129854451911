import { connect } from 'react-redux';

import Leaderboard from './Leaderboard';

import { clearLeaderboard, fetchLeaderboard } from '../../../../../Actions/Engage/StreakActions';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  leaderboard: state.engage.streaks?.leaderboard || {},
});

const mapDispatchToProps = (dispatch) => ({
  clearLeaderboard: () => dispatch(clearLeaderboard()),
  fetchLeaderboard: (streakType) => dispatch(fetchLeaderboard(streakType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
