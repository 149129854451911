import { updateProfileOrganization } from '../../../../Util/ProfileApiUtil';

export const UPDATE_WORKPLACE_SCHOOL = 'UPDATE_WORKPLACE_SCHOOL';

export const updateWorkplaceSchool = (payload) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_WORKPLACE_SCHOOL,
        payload,
    });
    const updatedState = getState();
    try {
        const res = updateProfileOrganization(updatedState.session.currentUser.id, updatedState.profile.organizationOccupation);
        return res;
    } catch (error) {
        console.log(error);
    }
};
