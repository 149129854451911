import React, { Component } from 'react';

import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';

class CreateEditMatching extends Component {
  state = this.props.question ?
  {
    text: this.props.question.text,
    hint: this.props.question.hint,
    answers_attributes: this.props.question.answers,
  }
  :
  {
    text: "",
    hint: "",
    answers_attributes: [{ text: "" }],
  }

  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleAnswerInput = e => {
    const answers_attributes = [...this.state.answers_attributes];
    answers_attributes[0].text = e.currentTarget.value;
    this.setState({
      answers_attributes,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const question = Object.assign({}, this.state);

    if (this.props.section) {
      question.section_id = this.props.section.id;
      question.question_type = this.props.section.section_type;
    }

    if (this.props.question) {
      question.id = this.props.question.id;
    }

    this.props.submitQuestion(question).then(
      this.props.closeModal()
    );
  }

  handleBlur = () => e => {
    e.currentTarget.id = "";
  };

  handleFocus = () => e => {
    e.currentTarget.id = "active";
  };

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.text}
            placeholder="Text"
            onChange={this.handleInputChange('text')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.hint}
            placeholder="Hint (Optional)"
            onChange={this.handleInputChange('hint')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.answers_attributes[0].text}
            placeholder="Answer"
            onChange={this.handleAnswerInput}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>


        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditMatching;