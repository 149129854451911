import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { humanizeDate } from '../../../../Util/Helper/GenericUtil';

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const LatestAssessment = ({ latestAssessmentLoader, latestAssessment, hasLicense, contentWidth }) => {
  const sapioTest     = latestAssessment?.sapioTest;
  const preAssessment = latestAssessment?.preAsssessmentScore;

  const renderEnrollButton = () => (
    <a href={`${REACT_APP_ONE_DOMAIN}${hasLicense ? '/' : '/products'}`} target="_blank" className="Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text={`${hasLicense ? 'Go to Canopy Credential' : 'Enroll'}`}
      />
    </a>
  );

  const renderBox = (heading, arrow, icon, subHeading) => (
    <div className="BoxHolder">
      <h4 className="Heading">{heading}</h4>
      <ImageBox src={arrow} className="Arrow-Icon" />
      <div className="Content">
        <ImageBox src={icon} className="Icon" />
        <h5 className="Sub-Heading">{subHeading}</h5>
      </div>
    </div>
  );

  const renderLatestAssessment = () => (
    <div className="CardHolder">
      <div
        className="Content-Container Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {renderPreAssessment()}
        {renderAssessment()}
        {renderWaitingOrBadge()}
      </div>
    </div>
  );

  const renderPreAssessment = () => (
    preAssessment
    ? renderBox('Completed Pre-Assessment', 'arrow.svg', 'completed.svg', humanizeDate(preAssessment.created_at))
    : renderBox('Completed Pre-Assessment', 'arrow.svg', 'location.svg', 'You are here')
  );

  const renderAssessment = () => {
    if (!preAssessment || !sapioTest)
      return null;

    if ((sapioTest.status === 'assigned') || (sapioTest.status === 'active'))
      return renderBox('Completed Assessment', 'arrow-2.svg', 'location.svg', 'You are here');

    if ((sapioTest.status === 'done') || (sapioTest.status === 'rated'))
      return renderBox('Completed Assessment', 'arrow-2.svg', 'completed.svg', humanizeDate(sapioTest?.end_time));
  }

  const renderWaitingOrBadge = () => {
    if (!preAssessment || !sapioTest || (sapioTest.status === 'assigned') || (sapioTest.status === 'active'))
      return null;

    if (sapioTest.status === 'done')
      return renderBox('waiting for scores', 'arrow-2.svg', 'location.svg', 'You are here');

    if (sapioTest.status === 'rated')
      return(
          <div data-tip data-for="qbs-tooltip" >
            <div className="BoxHolder">
              <div className="Heading">QBS Status</div>
              <div className="Content">
                <i className="fa fa-question fa-3x" />
              </div>
            </div>
            <ReactTooltip id="qbs-tooltip" place="bottom" type="info" effect="solid" >
              <p>
                Your eligibility to function as "Qualified Bilingual Staff(QBS)"is determined by the institution where you work.
                Canopy provides a toolset for hospitals and other care delivery organizations to determine the applicable cutoff level on the Canopy Scale.
                Please consult with your institution for your QBS status.
              </p>
            </ReactTooltip>
          </div>
      )

    if (sapioTest.status === 'terminated')
      return renderBox('Terminated', 'arrow-2.svg', 'location.svg', 'Assessment Terminated');
  }

  const renderContent = () => {
    if (latestAssessmentLoader)
      return <Loader />;

    if (Object.keys(latestAssessment).length === 0)
      return renderEnrollButton();

    return renderLatestAssessment();
  }

  return (
    <div className="Latest-Assessment-Container">
      <h2 className="Title">Latest Assessment Section</h2>
      {renderContent()}
    </div>
  )
}

LatestAssessment.propTypes = {
  latestAssessmentLoader: PropTypes.bool.isRequired,
  latestAssessment: PropTypes.object.isRequired,
  hasLicense: PropTypes.bool.isRequired,
  contentWidth: PropTypes.number.isRequired
}

export default LatestAssessment;
