import {
  RECEIVE_TOPIC_ERRORS,
  RECEIVE_TOPICS,
  RECEIVE_TOPIC,
  CLEAR_TOPIC_ERRORS,
} from '../../Actions/TopicActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TOPIC_ERRORS:
      return action.errors;
    case RECEIVE_TOPICS:
      return {};
    case RECEIVE_TOPIC:
      return {};
    case CLEAR_TOPIC_ERRORS:
      return {};
    default:
      return state;
  }
};