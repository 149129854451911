import * as APIUtil from '../Util/AnswerApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_ANSWER = 'RECEIVE_ANSWER';
export const RECEIVE_ANSWER_ERRORS = 'RECEIVE_ANSWER_ERRORS';

export const receiveAnswer = (payload) => ({
  type: RECEIVE_ANSWER,
  payload,
});

export const receiveErrors = (errors) => ({
  type: RECEIVE_ANSWER_ERRORS,
  errors,
});

export const createAnswer = (answer) => dispatch => (
  APIUtil.createAnswer(answer).then(
    payload => dispatch(receiveAnswer(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editAnswer = (answer) => dispatch => (
  APIUtil.editAnswer(answer).then(
    payload => dispatch(receiveAnswer(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteAnswer = (answerId) => dispatch => (
  APIUtil.deleteAnswer(answerId).then(
    payload => dispatch(receiveAnswer(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
