import {
  RECEIVE_ALL_COMMENTS,
  RECEIVE_SPECIFIC_COMMENTS,
  CLEAR_ALL_COMMENTS,
  CLEAR_SPECIFIC_COMMENTS,
} from '../../Actions/CommentActions';

const CommentsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ALL_COMMENTS:
      return Object.assign({}, state, { all: action.payload.comments });
    case RECEIVE_SPECIFIC_COMMENTS:
      return Object.assign({}, state, { specific: action.payload.comments });
    case CLEAR_ALL_COMMENTS:
      return Object.assign({}, state, { all: {} });
    case CLEAR_SPECIFIC_COMMENTS:
      return Object.assign({}, state, { specific: {} });
    default:
      return state;
  }
}

export default CommentsReducer;
