import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchBookmarkGen } from '../../../Actions/BookmarkActions';

import Bookmark from './Bookmark';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Loader from '../../Composed/Loader/Loader';

import './Bookmark.css';

const mapStateToProps = (state) => ({
  currentCourseId: state.navigation.currentCourseId,
  bookmarks: state.statistics.bookmarks
});

const mapDispatchToProps = (dispatch) => ({
  fetchBookmarkGen: () => dispatch(fetchBookmarkGen())
});

const Bookmarks = ({ currentCourseId, bookmarks, fetchBookmarkGen }) => {
  const [isLoading, setIsLoading]       = useState(true);
  const [showBookmark, setShowBookmark] = useState({ show: false });

  useEffect(() => {
    fetchBookmarkGen().then(() => setIsLoading(false));
  }, [fetchBookmarkGen]);

  const renderLesson = () => {
    return (
      Object.keys(bookmarks?.gen?.sections || []).map((lesson, i) => {
        return (
          <div key={i} className="Bookmarks-Lesson-Container">
            <div className="Bookmarks-Lesson">
              <span className="Bookmarks-Counter">
                {i + 1}
              </span>
              <h2 className="Lesson-Title">
                <i className="fas fa-bookmark" />
                {lesson}
              </h2>
            </div>

            { renderToq(lesson) }
          </div>
        )
      })
    )
  }

  const renderToq = (lesson) => {
    return (
      Object.keys(bookmarks.gen.sections[lesson]).map((toq, i) => {
        return (
          <div key={i} className="Bookmarks-Toq-Container">
            <div className="Bookmarks-Toq">
              <h5>{toq}</h5>
            </div>

            { renderSection(lesson, toq) }
          </div>
        )
      })
    )
  }

  const renderSection = (lesson, toq) => {
    return (
      bookmarks.gen.sections[lesson][toq].list.map((section, i) => {
        return (
          <div key={i} className="Bookmarks-Section-Container">
            <div className="Bookmarks-Section-Link" onClick={() => setShowBookmark({ show: true, lesson, toq, section })}>
              <p>{section.name}</p>
              <i className="fas fa-chevron-right" />
            </div>
          </div>
        )
      })
    )
  }

  if (isLoading) {
    return <Loader message="Loading, Hang on!" />
  }

  if (showBookmark.show) {
    return (
      <Bookmark
        lesson={showBookmark.lesson}
        toq={showBookmark.toq}
        section={showBookmark.section}
        showBookmarkHandler={() => setShowBookmark({ show: false })}
      />
    )
  }

  return (
    <div className="Bookmarks-Container">
      <div className="Bookmarks-Title">
        <div className="Bookmarks-Back">
          <Link to={`/learn/courses/${currentCourseId}/lessons`}>
            <ButtonBox className="Secondary-Btn" text={(<span className="Secondary-Btn-Text">Return Home</span>)} />
          </Link>
        </div>
        <h1 className="Bookmarks-Heading">
          <i className="fas fa-bookmark" />
          All Bookmarks
        </h1>
      </div>
      <div className="Bookmarks-Content">
        { renderLesson() }
      </div>
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);
