import * as APIUtil from '../Util/LingoApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

import { receivePledges } from './PledgeActions';

export const RECEIVE_LINGOS = 'RECEIVE_LINGOS';
export const RECEIVE_LINGOS_ERRORS = 'RECEIVE_LINGOS_ERRORS';
export const CLEAR_LINGOS = 'CLEAR_LINGOS';

export const receiveLingos = payload => {
  return {
    type: RECEIVE_LINGOS,
    payload,
  };
};

export const receiveLingosErrors = errors => {
  return {
    type: RECEIVE_LINGOS_ERRORS,
    errors
  };
};

export const clearLingos = () => {
  return {
    type: CLEAR_LINGOS,
  };
};

export const fetchLingos = () => dispatch => (
  APIUtil.fetchLingos().then(
    payload => dispatch(receiveLingos(payload)),
    errors  => dispatch(receiveLingosErrors(ErrorResponseUtil(errors))),
  )
);

export const createLingo = (lingo) => dispatch => (
  APIUtil.createLingo(lingo).then(
    payload => dispatch(receiveLingos(payload)),
    errors  => dispatch(receiveLingosErrors(ErrorResponseUtil(errors))),
  )
);

export const updateLingo = (lingo) => dispatch => (
  APIUtil.updateLingo(lingo).then(
    payload => dispatch(receiveLingos(payload)),
    errors  => dispatch(receiveLingosErrors(ErrorResponseUtil(errors))),
  )
);

export const enablePledge = (lingo) => dispatch => (
  APIUtil.enablePledge(lingo).then(
    payload => dispatch(receivePledges(payload)),
    errors  => dispatch(receiveLingosErrors(ErrorResponseUtil(errors))),
  )
);
