import { connect } from 'react-redux';

import LearnNavBar from './LearnNavBar';

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    currentWorkplace: state.navigation.currentWorkplace,
    currentCourseId: state.navigation.currentCourseId,
    courses: state.entities.courses,
    courseProgresses: state.statistics.courseProgresses,
    lessons: state.entities.lessons
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnNavBar);
