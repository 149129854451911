import React, { Component } from 'react';
import ReactDropzone from "react-dropzone";
import { ScaleLoader } from 'react-spinners'

import './UploadMedia.css';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import InputBox from '../../Basic/InputBox/InputBox';

class UploadMedia extends Component {
  state = {
    mediaUrl: "",
    mediaFile: null,
    youTubeMediaUrl: this.props.entity?.media_url || ''
  }

  videoForTopicSection = () => {
    const { model, entity, media_type } = this.props;

    return (
      model === 'section' &&
      media_type === 'video' &&
      entity.sectionable_type === 'Topic'
    )
  };

  onDrop = (files) => {
    const reader = new FileReader();
    const file = files[0];
    reader.onloadend = () => this.setState(
      { mediaUrl: reader.result, mediaFile: file }
    );

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({ mediaUrl: "", mediaFile: null });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    document.getElementsByClassName('SubmitWait')[0].style['display'] = 'block';

    const file = this.state.mediaFile;
    const formData = new FormData();

    formData.append(`${this.props.model}[id]`, this.props.entity.id);

    if (this.videoForTopicSection()) {
      formData.append(`${this.props.model}[media_url]`, this.state.youTubeMediaUrl)
    }

    if (file) {
      if (this.props.subModel) {
        formData.append(`${this.props.model}[${this.props.subModel}][id]`, this.props.subEntity.id);
        formData.append(`${this.props.model}[${this.props.subModel}][${this.props.attachment}]`, file);
      } else {
        formData.append(`${this.props.model}[${this.props.attachment}]`, file);
      }
    }

    this.props.submitMedia(formData).then(res => {
      this.props.closeModal()
    });
  }

  render() {
    let preview;

    switch (this.props.media_type) {
      case "image":
        preview = <img alt="" src={this.state.mediaUrl} />;
        break;
      case "audio":
        preview = (
          <audio controls controlsList="nodownload">
            <source src={this.state.mediaUrl} type="audio/mp3" />
          </audio>
        )
        break;
      case "video":
        preview = (
          <video controls controlsList="nodownload" height="300" width="450">
            <source src={this.state.mediaUrl} type="video/mp4" />
          </video>
        )
        break;
      default:
        break;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="SubmitWait">
          <ScaleLoader color="#fff" />
        </div>
        <ReactDropzone
          onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div
              className="UploadMedia-dropzone"
              {...getRootProps()}>
              <input {...getInputProps()} />
              {
                this.state.mediaUrl ?
                  preview
                  :
                  <p>Drag 'n' drop file here, or click to select files</p>
              }
            </div>
          )}
        </ReactDropzone>
        {
          (this.videoForTopicSection()) &&
          <>
            <div className="Section-Separator"> -OR- </div>
            <div className="CreationForms-InputBox">
              <InputBox
                className="InputBox-LogInBox"
                value={this.state.youTubeMediaUrl}
                placeholder="Youtube Video Short Link"
                onChange={e => this.setState({ youTubeMediaUrl: e.currentTarget.value })}
              />
            </div>
          </>
        }

        <ButtonBox
          className="CreationDashboards-ButtonBox"
          text="Submit" />
      </form>
    );
  }
}

export default UploadMedia;
