import axios from './AxiosUtil';

export const checkExistingUser = user => (
    axios.post('/api/users/check', {
        user,
    }).then(res => res.data)
);

export const createUser = user => (
    axios.post('/api/users', {
        user,
    }).then(res => res.data)
);
