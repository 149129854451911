import React from 'react';
import { Redirect, withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const Auth = ({ component: Component, path, currentUser, exact }) => (
    <Route path={path} exact={exact} render={(props) => (
        !currentUser ?
        <Component {...props} />
        :
        <Redirect to="/account/learn" />
    )} />
);

const Protected = ({ component: Component, path, currentUser, exact }) => (
    <Route path={path} exact={exact} render={(props) => (
        currentUser ?
        <Component {...props} />
        :
        <Redirect to="/signin" />
    )} />
);

const B2bAdmin = ({ component: Component, path, currentUser, exact }) => (
    <Route path={path} exact={exact} render={(props) => (
        currentUser && currentUser.user_type === "B2B.Admin" ?
        <Component {...props} />
        :
        <Redirect to="/account/learn" />
    )} />
);

const Admin = ({ component: Component, path, currentUser, exact }) => (
    <Route path={path} exact={exact} render={(props) => (
        currentUser && currentUser.user_type === "TE.Admin" ?
        <Component {...props} />
        :
        <Redirect to="/learn" />
    )} />
);

const TrialLocked = ({ component: Component, path, currentUser, exact }) => (
    <Route path={path} exact={exact} render={(props) => {
        if (currentUser && !currentUser.trial) {
            return <Component {...props} />;
        }

        if (currentUser && props.match.params.courseId === '1'
            && (+props.match.params.lessonId < 5 && +props.match.params.lessonId > 1)
            && (!+props.match.params.topicId || +props.match.params.topicId < 16)) {
            return <Component {...props} />;
        }

        return <Redirect to="/learn" />;
    }} />
);

const mapStateToProps = state => {
    return {
        currentUser: state.session.currentUser,
    };
};

export const AuthRoute = withRouter(connect(mapStateToProps, null)(Auth));
export const ProtectedRoute = withRouter(connect(mapStateToProps, null)(Protected));
export const B2bAdminRoute = withRouter(connect(mapStateToProps, null)(B2bAdmin));
export const AdminRoute = withRouter(connect(mapStateToProps, null)(Admin));
export const TrialLockedRoute = withRouter(connect(mapStateToProps, null)(TrialLocked));
