import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import './CourseLeftover.scss';

const CourseLeftover = ({ course, courseProgress, quizResults }) => {
  const [openModal, setOpenModal] = useState(true);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [selectedTopicId, setSelectedTopicId] = useState(null);

  const calculateQuizPercentage = (quizResult) => (
    (quizResult?.num_correct / quizResult?.num_questions * 100) || 0
  )

  const passedQuiz = (lesson) => (
    calculateQuizPercentage(quizResults[lesson.quiz?.id]?.[0]) > course.pass_threshold
  )

  const renderSections = (lesson, topic) => (
    <div className="Section-Box Gray-Box">
      <p className="Heading">Following slides must be viewed:</p>
      <ul className="list-disc">
        {
          Object.values(topic.sections || {}).sort((a, b) => a.position > b.position ? 1 : -1).map((section, sidx) => {
            if (!Object.keys(courseProgress?.progress_data?.lessonProgresses?.[lesson.id]?.topicProgresses?.[topic.id]?.sectionProgresses || {}).includes(section.id.toString())) {
              return <li className="Text" key={`section-${sidx}`}>{section.name}</li>
            }
          })
        }
      </ul>
    </div>
  )

  const renderTopics = (lesson) => (
    <div className="Topics-Accordion Accordion-Container">
      {
        Object.values(lesson.topics || {}).sort((a, b) => a.position > b.position ? 1 : -1).map((topic, tidx) => {
          if (!topic.sections || (courseProgress?.progress_data?.lessonProgresses?.[lesson.id]?.completedTopics || []).includes(topic.id)) {
            return null;
          }

          return (
            <div className="Topic-Entity Entity" key={`topic-${tidx}`}>
              <ButtonBox
                key={`TopicEntity-${tidx}`}
                text={topic.name}
                className={`Topic-Title Title ${selectedTopicId === topic.id ? 'Active' : ''}`}
                onClick={() => setSelectedTopicId(selectedTopicId === topic.id ? null : topic.id)}
              />
              {selectedTopicId === topic.id && renderSections(lesson, topic)}
            </div>
          )
        })
      }
    </div>
  )

  const renderQuiz = (lesson) => (
    <div className="Quiz-Box Gray-Box">
      <p className="Text">
        The <strong>{lesson.quiz.name}</strong> must be attempted and score must be {course.pass_threshold}% or above.
        {course.course_type?.name === 'Learn' && <span> You can retake the quiz as well.</span>}
      </p>
    </div>
  )

  const renderLessons = () => (
    <div className="Lessons-Accordion Accordion-Container">
      {
        Object.values(course.tree.lessons).sort((a, b) => a.position > b.position ? 1 : -1).map((lesson, lidx) => {
          let displaySlides = !lesson.isTest && lesson.topics && !(courseProgress?.progress_data?.completedLessons || []).includes(lesson.id);
          let displayQuiz = !lesson.ignoreGrade && lesson.quiz && lesson.quiz.status === 'active' && !passedQuiz(lesson);

          if (!displaySlides && !displayQuiz) {
            return null;
          }

          return (
            <div className="Lesson-Entity Entity" key={`lesson-${lidx}`}>
              <ButtonBox
                key={`LessonEntity-${lidx}`}
                text={lesson.name}
                className={`Lesson-Title Title ${selectedLessonId === lesson.id ? 'Active' : ''}`}
                onClick={() => {
                  setSelectedLessonId(selectedLessonId === lesson.id ? null : lesson.id);
                  setSelectedTopicId(null);
                }}
              />
              {selectedLessonId === lesson.id && displaySlides && renderTopics(lesson)}
              {selectedLessonId === lesson.id && displayQuiz && renderQuiz(lesson)}
            </div>
          )
        })
      }
    </div>
  )

  if (openModal) {
    return (
      <div className="CourseLeftover-Container">
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="Heading">Course Progress Reminder</div>
              <ButtonBox
                src="close.svg"
                className="Button"
                onClick={() => setOpenModal(false)}
              />
            </div>

            <div className="modal-body">
              <p className="Description">
                Note: All slides/activities must be viewed and all quizzes & exams passed with a {course.pass_threshold}% or above in order to obtain your certificate.
              </p>

              <div className="Hierarchy">
                {renderLessons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null;
};

CourseLeftover.propTypes = {
  course: PropTypes.object.isRequired,
  courseProgress: PropTypes.object.isRequired,
  quizResults: PropTypes.object.isRequired,
}

export default CourseLeftover;
