import React from 'react';
import PropTypes from 'prop-types';

import './ScoreCardScale.scss';

const ScoreCardScale = ({
  title, showLegend = false, smallScale = false,
  baseOverlay, targetOverlay, labels, disableFirstItem = false,
  disclaimer,
}) => {
  const getOverlay = (language, type) => (
    <div className={`Scale-Tooltip ${type}-tooltip`}>
      <div className="Tooltip-Carat" />
      <div className="Tooltip-Label">
        <p>{language}</p>
      </div>
    </div>
  );

  return (
    <div className="Score-Card-Scale">
      <div className="Scale-Header">
        <h5>{title}</h5>
      </div>

      {
        showLegend && (
          <div className="Scale-Legend">
            <div className="Bar" />
            <div className="Text">
              As measured on the Canopy Scale
            </div>
          </div>
        )
      }

      <div
        className={`Scale
          ${smallScale ? 'Small-Scale' : ''}
          ${baseOverlay || targetOverlay ? 'Has-Overlay' : ''}
          ${baseOverlay && targetOverlay && baseOverlay.level === targetOverlay.level ? 'Same-Overlay' : ''}
        `}
      >
        {
          labels.map((label, i) => {
            const disableFirst = disableFirstItem && i === 0;

            return (
              <div
                key={i}
                className={`Scale-Item
                  ${label.isQbsScore ? 'Qbs-Score' : 'Non-Qbs-Score'}
                  ${label.isScoreable ? '' : 'Unscoreable'}
                  ${disableFirst ? 'Unscoreable-First' : ''}
                `}
              >
                <p>{`${label.canopyLevel}${disableFirst ? '*' : ''}`}</p>
                {baseOverlay && baseOverlay.level === label.canopyLevel && getOverlay(baseOverlay.language, 'base')}
                {targetOverlay && targetOverlay.level === label.canopyLevel && getOverlay(targetOverlay.language, 'target')}
              </div>
            );
          })
        }
      </div>

      {
        disclaimer && (
          <div className="Scale-Disclaimer">{`*${disclaimer}.`}</div>
        )
      }
    </div>
  )
};

ScoreCardScale.propTypes = {
  title: PropTypes.string.isRequired,
  showLegend: PropTypes.bool,
  smallScale: PropTypes.bool,
  baseOverlay: PropTypes.shape({
    level: PropTypes.string,
    language: PropTypes.string,
  }),
  targetOverlay: PropTypes.shape({
    level: PropTypes.string,
    language: PropTypes.string,
  }),
  labels: PropTypes.shape({
    level: PropTypes.string,
  }),
  disableFirstItem: PropTypes.bool,
  disclaimer: PropTypes.bool,
};

export default ScoreCardScale;
