import {
  RECEIVE_TE_ADMIN_INSTITUTIONS_STATS,
  RECEIVE_TE_ADMIN_INSTITUTIONS_STATS_ERRORS,
  CLEAR_TE_ADMIN_INSTITUTIONS_STATS,
} from '../../../Actions/Stats/TEAdmin/InstitutionsActions';

const InstitutionsReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_TE_ADMIN_INSTITUTIONS_STATS:
      return Object.assign({}, state, {
        total_licenses: action.payload.total_licenses,
        processed_licenses: ((state.processed_licenses || 0) + action.payload.chunk_licenses),
      });
    case CLEAR_TE_ADMIN_INSTITUTIONS_STATS:
      return {};
    default:
      return state;
  }
}

export default InstitutionsReducer;
