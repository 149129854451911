import * as APIUtil from '../Util/NetPromoterScoreApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_NET_PROMOTER_SCORES = 'CLEAR_NET_PROMOTER_SCORES';
export const RECEIVE_NET_PROMOTER_SCORES = 'RECEIVE_NET_PROMOTER_SCORES';
export const RECEIVE_NET_PROMOTER_SCORE_ERRORS = 'RECEIVE_NET_PROMOTER_SCORE_ERRORS';

export const clearNetPromoterScores = () => ({
  type: CLEAR_NET_PROMOTER_SCORES,
});

export const receiveNetPromoterScores = payload => ({
  type: RECEIVE_NET_PROMOTER_SCORES,
  payload,
});

export const receiveNetPromoterScoreErrors = errors => ({
  type: RECEIVE_NET_PROMOTER_SCORE_ERRORS,
  errors,
});

export const fetchNPSOverview = () => dispatch => (
  APIUtil.fetchNPSOverview().then(
    payload => dispatch(receiveNetPromoterScores(payload)),
    errors => dispatch(receiveNetPromoterScoreErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchNPSDetails = queryString => dispatch => (
  APIUtil.fetchNPSDetails(queryString).then(
    payload => dispatch(receiveNetPromoterScores(payload)),
    errors => dispatch(receiveNetPromoterScoreErrors(ErrorResponseUtil(errors))),
  )
);

export const createUserNetPromoterScore = netPromoterScore => dispatch => (
  APIUtil.createUserNetPromoterScore(netPromoterScore).then(
    payload => payload,
    errors => ErrorResponseUtil(errors),
  )
);
