import { connect } from 'react-redux';

import CredentialScoreCard from './CredentialScoreCard';
import { clearCredentialTests, fetchUserCredentialTests } from '../../../Actions/CredentialTestActions';

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  credentialTests: state.entities.credentialTests || {},
});

const mapDispatchToProps = dispatch => ({
  clearCredentialTests: () => dispatch(clearCredentialTests()),
  fetchUserCredentialTests: userId => dispatch(fetchUserCredentialTests(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CredentialScoreCard);
