import axios from './AxiosUtil';

export const updateUserType = (user) => (
    axios.put(`api/users/${user.id}/update_type`, { user }).then(res => res.data)
);

export const confirmUser = user => (
    axios.post(`/api/users/${user}/confirm`).then(res => res.data)
);

export const archiveUser = (userId) => (
    axios.patch(`/api/users/${userId}/archive`).then(res => res.data)
);

export const overidePassword = user => (
    axios.post(`/api/users/${user.id}/overide_password`, { user }).then(res => res.data)
);

export const deleteUser = user => (
    axios.delete(`/api/users/${user}`).then(res => res.data)
);
