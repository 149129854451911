import React, { Component } from 'react';

import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import InputBox
  from '../../Basic/InputBox/InputBox';
import { COLORS }
  from '../../../Style/Style';

const processOptions = options => {
  const output = {}
  options.forEach(
    option => {
      if (output[option.index]) {
        output[option.index].push(option)
      } else {
        output[option.index] = [option]
      }
    }
  )
  return output;
}

class CreateEditDropDown extends Component {
  state = this.props.question ?
  {
    text: this.props.question.text,
    hint: this.props.question.hint,
    answers: this.props.question.answers.map(answer => answer.index),
    options_attributes: processOptions(this.props.question.options)
  }
  :
  {
    text: "",
    hint: "",
    answers: [],
    options_attributes: {},
  }

  textArea = React.createRef();
  
  handleInputChange = (field) => {
    return (e) => this.setState({
      [field]: e.currentTarget.value
    });
  }

  handleOptionInput = (index, idx) => {
    return (e) => {
      const options_attributes = {...this.state.options_attributes};
      options_attributes[index][idx].text = e.currentTarget.value;
      this.setState({
        options_attributes,
      })
    }
  }

  handleAddAnswer = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const textAreaNode = this.textArea.current;
      const newText =
        prevState.text.slice(0, textAreaNode.selectionStart) + "__drop__" +
        prevState.text.slice(textAreaNode.selectionEnd, prevState.text.length);
      return {
        text: newText,
        answers: [...prevState.answers, prevState.answers.length ],
        options_attributes: {
          ...prevState.options_attributes,
          [prevState.answers.length]: [{ text: "", index: prevState.answers.length }] 
        },
      }
    });
  }

  handleAddOption = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      return {
        options_attributes: {
          ...prevState.options_attributes,
          [index]: [...prevState.options_attributes[index], { text: "", index }]
        }
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const options_attributes = Object.values(this.state.options_attributes).flat();

    const question = Object.assign({}, {
      text: this.state.text,
      hint: this.state.hint,
      options_attributes,
    });

    if(this.props.section) {
      question.section_id = this.props.section.id;
      question.number_of_answers = this.state.answers.length;
      question.question_type = this.props.section.section_type;
    }

    if (this.props.question) {
      question.id = this.props.question.id;
    }

    this.props.submitQuestion(question).then(
      this.props.closeModal()
    );
  }

  handleDropRender() {
    return this.state.answers.map(
      (_, idx) => (
        <div key={idx}>
          <TextBox 
            text={ "Dropdown" + (idx+1) }
            style={{
              color: COLORS.Blue
            }}/>

          {
            this.handleOptionsRender(idx)
          }

          <div
            className="CreationForms-Button">
            <ButtonBox
              className="CreationDashboards-ButtonBox"
              text="Add An Option"
              onClick={this.handleAddOption(idx)} />
          </div>
        </div>
      )
    );
  }

  handleOptionsRender(index) {
    return this.state.options_attributes[index].map(
      (_, idx) =>
        <div
          className="CreationForms-InputBox"
          key={idx}>
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.options_attributes[index][idx].text}
            placeholder={`Option ${idx + 1}`}
            onChange={this.handleOptionInput(index, idx)}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>
    );
  }

  handleBlur = () => e => {
    e.currentTarget.id = "";
  };

  handleFocus = () => e => {
    e.currentTarget.id = "active";
  };

  render() {
    return (
      <form
        className="CreationForms-Form"
        onSubmit={this.handleSubmit}>
        <div
          className="CreationForms-InputBox">
          <textarea
            ref={this.textArea}
            className="TextAreaBox-CreationForms"
            value={this.state.text}
            placeholder="Text Here"
            onChange={this.handleInputChange('text')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-AnswersBox">

          {
            this.handleDropRender()
          }

          <div
            className="CreationForms-Button">
            <ButtonBox
              className="CreationDashboards-ButtonBox"
              text="Insert A Dropdown"
              onClick={this.handleAddAnswer} />
          </div>
        </div>

        <div
          className="CreationForms-InputBox">
          <InputBox
            className="InputBox-LogInBox"
            value={this.state.hint}
            placeholder="Hint Optional"
            onChange={this.handleInputChange('hint')}
            onBlur={this.handleBlur()}
            onFocus={this.handleFocus()} />
        </div>

        <div
          className="CreationForms-Button">
          <ButtonBox
            className="CreationDashboards-ButtonBox"
            text="Submit" />
        </div>
      </form>
    );
  }
}

export default CreateEditDropDown;