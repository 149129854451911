import { combineReducers } from 'redux';

import languageBackground from './LanguageBackground';
import organizationOccupation from './OrganizationOccupation';
import subscriptionsBilling from './SubscriptionsBilling';

const profile = combineReducers({
    languageBackground,
    organizationOccupation,
    subscriptionsBilling,
});

export default profile;
