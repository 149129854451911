import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/LessonApiUtil';

export const RECEIVE_LESSON_ERRORS = 'RECEIVE_LESSON_ERRORS';
export const RECEIVE_LESSONS = 'RECEIVE_LESSONS';
export const RECEIVE_LESSON = 'RECEIVE_LESSON';
export const RECEIVE_LESSON_DELETE_SUCCESS = 'RECEIVE_LESSON_DELETE_SUCCESS';
export const RECEIVE_LESSON_POSITIONS = 'RECEIVE_LESSON_POSITIONS';
export const CLEAR_LESSONS = 'CLEAR_LESSONS';
export const CLEAR_LESSON_ERRORS = 'CLEAR_LESSON_ERRORS';
export const RECEIVE_CLONE_LESSON = 'RECEIVE_CLONE_LESSON';

export const receiveLessons = payload => ({
    type: RECEIVE_LESSONS,
    payload,
});

export const clearLessons = () => ({
    type: CLEAR_LESSONS,
});

export const receiveLesson = (payload, message) => ({
    type: RECEIVE_LESSON,
    payload,
    message,
});

export const receiveclonedLesson = (payload, message) => ({
    type: RECEIVE_CLONE_LESSON,
    payload,
    message,
});

export const receiveLessonDeleteSuccess = (payload, message) => ({
    type: RECEIVE_LESSON_DELETE_SUCCESS,
    payload,
    message,
});

export const receiveLessonPositions = (payload, message) => ({
    type: RECEIVE_LESSON_POSITIONS,
    payload,
    message,
});

export const receiveErrors = errors => ({
    type: RECEIVE_LESSON_ERRORS,
    errors,
});

export const clearLessonErrors = () => ({
    type: CLEAR_LESSON_ERRORS,
});

export const fetchLessons = (courseId) => dispatch => (
    APIUtil.fetchLessons(courseId).then(
        payload => dispatch(receiveLessons(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createLesson = (lesson) => dispatch => (
    APIUtil.createLesson(lesson).then(
        payload => dispatch(receiveLesson(payload, 'Lesson Created')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editLesson = (lesson) => dispatch => (
    APIUtil.editLesson(lesson).then(
        payload => dispatch(receiveLesson(payload, 'Lesson Updated')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteLesson = lessonId => dispatch => (
    APIUtil.deleteLesson(lessonId).then(
        payload => dispatch(receiveLessonDeleteSuccess(payload, 'Lesson Deleted')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveHigher = lessonId => dispatch => (
    APIUtil.moveHigher(lessonId).then(
        payload => dispatch(receiveLessonPositions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const moveLower = lessonId => dispatch => (
    APIUtil.moveLower(lessonId).then(
        payload => dispatch(receiveLessonPositions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const cloneLesson = (targetCourseId, lessonId) => dispatch => (
    APIUtil.cloneLesson(targetCourseId, lessonId).then(
        payload => dispatch(receiveclonedLesson(payload, 'Lesson Cloned Successfully')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const uploadImage = (formData) => dispatch => (
    APIUtil.uploadImage(formData).then(
        payload => dispatch(receiveLessons(payload, 'Lesson Updated')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
