import React from 'react';
import ReactPlayer from 'react-player'

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './YouTubeVideo.scss';

const YouTubeVideo = ({ shortUrl = '' }) => {
  return (
    <div className="YouTube-Video">
      <ReactPlayer
        url={shortUrl}
        controls={true}
        onPlay={() => gaEvent('video_start')}
      />
    </div>
  )
}

export default YouTubeVideo;
