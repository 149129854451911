import React, { Component } from 'react';
import ReactNumberFormat from 'react-number-format';

import './CoursePackageDashboard.css';
import TextBox from '../../../Basic/TextBox/TextBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import CourseBoxMini from '../../../Presentational/CourseBox/CourseBoxMini';
import Confirm from '../../../Composed/Confirm/Confirm';
import CreateEditCoursePackage from '../../../Forms/CreateEditCoursePackage/CreateEditCoursePackage';
import { COLORS } from '../../../../Style/Style';

class CoursePackageDashboard extends Component {
  state = {
    modalOpen: false,
    selectedInventoryIds: (this.props.coursePackage.inventories || []),
    inventorySubmitting: false
  }

  openModal = () => this.setState({ modalOpen: true })

  closeModal = () => this.setState({ modalOpen: false })

  componentDidMount() {
    if(!this.props.coursePackage) {
      this.props.fetchCoursePackage(this.props.coursePackageId);
    }

    this.props.fetchCourses();
    this.props.fetchInventories();
  }

  handleForSale = () => {
    const coursePackage = Object.assign({}, this.props.coursePackage);
    coursePackage.for_sale = !coursePackage.for_sale;

    if (!coursePackage.for_sale) {
      coursePackage.renew_only = false;
    }

    this.props.editCoursePackage(coursePackage);
  }

  handleRenewOnly = () => {
    const coursePackage = Object.assign({}, this.props.coursePackage);
    coursePackage.renew_only = !coursePackage.renew_only;

    this.props.editCoursePackage(coursePackage);
  }

  handleCourseList() {
    const { courses, coursePackage, editCoursePackage } = this.props;
    let courseList = [];
    Object.keys(courses).forEach(
      (courseId, index) => {
        if (!coursePackage.courses.includes(+courseId)) {
          courseList.push(
            <CourseBoxMini
              key={index}
              course={courses[courseId]}
              coursePackage={coursePackage}
              editCoursePackage={editCoursePackage}/>)
        }
      }
    )
    return courseList
  }

  handleInventoryChange = (e) => {
    let ids = Array.from(e.target.selectedOptions, option => parseInt(option.value));
    this.setState({ selectedInventoryIds: ids });
  }

  handleInventorySubmit = (e) => {
    e.preventDefault();

    const { coursePackage, editCoursePackage } = this.props;
    const { selectedInventoryIds } = this.state;

    this.setState({ inventorySubmitting: true })

    editCoursePackage({ ...coursePackage, selectedInventoryIds }).then(
      res => this.setState({ inventorySubmitting: false })
    );
  }

  handleInventoryList() {
    const { coursePackage, inventories } = this.props;
    const { selectedInventoryIds, inventorySubmitting } = this.state;

    if (coursePackage.for_sale) {
      return (
        <div className="Display-Inventory-List">
          {
            Object.values(inventories).map((inventory, idx) => (
              coursePackage.inventories.includes(inventory.id) &&
              <div key={idx}>
                {`${inventory.name} ${inventory.variant}`}
              </div>
            ))
          }
        </div>
      )
    }

    return (
      <div className ="Inventory-List">
        <select
          multiple={true}
          value={selectedInventoryIds}
          onChange={(e) => this.handleInventoryChange(e)}
        >
          {
            Object.values(inventories).map((inventory, idx) => (
              <option key={idx} value={inventory.id} children={`${inventory.name} ${inventory.variant}`} />
            ))
          }
        </select>

        <ButtonBox
          className="ButtonBox-SaveInventories CreationDashboards-ButtonBox"
          text="Include Inventories"
          disabled={inventorySubmitting}
          onClick={(e) => this.handleInventorySubmit(e)}
        />
      </div>
    )
  }

  render() {
    const { courses, coursePackage, editCoursePackage } = this.props;

    if(!coursePackage) {
      return <div></div>;
    }

    return (
      <div
        className="AdminDashboard-Pages">
        <div className="AdminDashboard-Manage">
          <div className="AdminDashboard-Title">
            <TextBox
              text={coursePackage.name}
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h2"
            />

            <div
              className="SectionBox-DataBox-Buttons">
              {
                this.props.coursePackage.for_sale ?
                <Confirm
                  text="Remove this package from sale?"
                  confirm={this.handleForSale}>
                  {
                    open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-ban"></i>}
                        onClick={open} />
                  }
                </Confirm>
                :
                <Confirm
                  text="Put this package up for sale? You won't be able to add or remove courses while it's for sale."
                  confirm={this.handleForSale}>
                  {
                    open =>
                      <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-dollar-sign"></i>}
                        onClick={open} />
                  }
                </Confirm>
              }
              <ButtonBox
                className="ButtonBox-EditQuestions"
                text={<i className="far fa-edit"></i>}
                onClick={this.openModal} />
              <ButtonBox
                className="ButtonBox-EditQuestions"
                text={<i className="far fa-trash-alt"></i>} />
            </div>
          </div>

          {
            this.state.modalOpen &&
            <CreateEditCoursePackage 
              closeModal={this.closeModal}
              coursePackage={coursePackage}
              submitCoursePackage={editCoursePackage}
              courses={courses}
            />
          }

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="Price:"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <ReactNumberFormat
              displayType="text"
              value={coursePackage.price / 100}
              prefix="$"
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              style={{
                color: COLORS.Blue,
                margin: "0 10px",
                fontWeight: "600",
                fontSize: "18px"
              }} />
          </div>

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="Renewal Price:"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <ReactNumberFormat
              displayType="text"
              value={coursePackage.renewal_price / 100}
              prefix="$"
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              style={{
                color: COLORS.Blue,
                margin: "0 10px",
                fontWeight: "600",
                fontSize: "18px"
              }} />
          </div>

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="For Sale:"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <TextBox
              text={coursePackage.for_sale ? "true" : "false"}
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                margin: "0 10px",
              }}
            />
          </div>

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="Stripe Initial Plan ID:"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <TextBox
              text={`${coursePackage.stripe_initial_plan_id || 'N/A'}`}
              style={{
                color: COLORS.Blue,
                fontWeight: "600",
                margin: "0 10px",
              }}
            />
          </div>

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="Stripe Recurring Plan ID:"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <TextBox
              text={`${coursePackage.stripe_recurring_plan_id || 'N/A'}`}
              style={{
                color: COLORS.Blue,
                fontWeight: "600",
                margin: "0 10px",
              }}
            />
          </div>

          <div
            className="AdminDashboard-InfoBox">
            <TextBox
              text="Active Subscriptions:"
              style={{
                color: COLORS.Blue,
                fontWeight: "600",
                textDecoration: "none",
              }}
            />

            <TextBox
              text={coursePackage.active_recurring_subscriptions}
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                margin: "0 10px",
              }}
            />
          </div>

          <div className="AdminDashboard-Title-mini">
            <TextBox
              text="Inventories"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h5"
            />
          </div>

          <div className="AdminDashboard-InventoryList">
            { this.handleInventoryList() }
          </div>

          <div className="AdminDashboard-Title-mini">
            <TextBox
              text="Courses"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h5"
            />
          </div>

          <div className="AdminDashboard-CourseList">
            {
              coursePackage.courses.length ?
              coursePackage.courses.map(
                (courseId, index) => 
                  <CourseBoxMini 
                    key={ index }
                    course={courses[courseId]}
                    coursePackage={coursePackage}
                    editCoursePackage={editCoursePackage}
                    remove />
              )
              :
              <TextBox 
                text="This Package Has No Courses"
                style={{
                  color: COLORS.Blue,
                  fontWeight: "600",
                  textDecoration: "none",
                }} />
            }
          </div>

          <div className="AdminDashboard-Title-mini">
            <TextBox
              text="Add More Courses"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h5"
            />
          </div>

          <div className="AdminDashboard-CourseList">
            { this.handleCourseList() }
          </div>
        </div>
      </div>
    );
  }
}

export default CoursePackageDashboard;
