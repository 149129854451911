import axios from './AxiosUtil';

export const fetchLingos = () => {
  return axios.get(`/api/lingos`).then(
    res => res.data,
  );
};

export const createLingo = (lingo) => {
  return axios.post('/api/lingos', { lingo }).then(
    res => res.data,
  );
};

export const updateLingo = (lingo) => {
  return axios.patch(`/api/lingos/${lingo.id}`, { lingo }).then(
    res => res.data,
  );
};

export const enablePledge = (lingo) => {
  return axios.patch(`/api/lingos/${lingo.id}/enable_pledge`).then(
    res => res.data
  );
};
