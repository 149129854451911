import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/StripeApiUtil';

export const RECEIVE_CHARGE_CONFIRMATION = 'RECEIVE_CHARGE_CONFIRMATION';
export const RECEIVE_CHARGE_ERRORS = ' RECEIVE_CHARGE_ERRORS';
export const CLEAR_STRIPE_FLASH_AND_ERRORS = 'CLEAR_STRIPE_FLASH_AND_ERROS';

export const receiveChargeConfirmation = payload => ({
    type: RECEIVE_CHARGE_CONFIRMATION,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_CHARGE_ERRORS,
    errors,
});

export const clearStripeFlashAndErrors = () => ({
    type: CLEAR_STRIPE_FLASH_AND_ERRORS,
});

export const createCharge = (token, coursePackageId, promoCodeId) => dispatch => (
    APIUtil.createCharge(token, coursePackageId, promoCodeId).then(
        payload => dispatch(receiveChargeConfirmation(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
