import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import './NavigationBox.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import NavigationLesson from './NavigationLesson';
import ImageBox  from '../../Basic/ImageBox/ImageBox';

const NavigationBox = ({ course, courseProgress, lessons, setNavigationOpen, pathname }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const lessonList = course.lessons.map( lessonId => lessons[lessonId]);

  const completionPercentage = () => {
    if (course.non_test_lessons === 0) {
      return 0;
    }

    return Math.round(
      (
        (courseProgress?.progress_data?.completedLessons?.length || 0)
        /
        (course.non_test_lessons || 0)
      ) * 100
    );
  }


  const handleLessonsRender = () => {
    return lessonList.map (
      lesson => 
        <NavigationLesson
          key={ lesson.id }
          course={course}
          courseProgress={courseProgress}
          lesson={lesson}
          setNavigationOpen={setNavigationOpen}
          pathname={pathname}
        />
    )
  }

  return (
    <div className="modal sidebar-modal">
      <div className="modal-content-navigation">
        <div className="NavigationBox">
          <div className="NavigationBox-Title">
            <div className="NavigationBox-Back">
              <Link 
                to="/learn"
                onClick={() => setNavigationOpen(false)}
              >
                <TextBox
                  text="Return to Dashboard"
                />
              </Link>
            </div>

            <div className="NavigationBox-Data">
              <TextBox
                tag="h4"
                text={course.name}
              />

              <button
                className="NavBar-Button SideBar-Closer"
                onClick={() => setNavigationOpen(false)}
              >
                <ImageBox className="ImageBox-Accordion" src="sidebar-close.svg" />
              </button>
            </div>

            <div className="NavigationBox-Progress">
              <span>
                {completionPercentage()}% complete
              </span>
            </div>
          </div>

          <div className="NavigationBox-Content">
            {handleLessonsRender()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationBox;
