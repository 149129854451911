import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/CommentApiUtil';

import { receiveSection } from './SectionActions';
import { receiveQuestion } from './QuestionActions';
import { receiveTextContent } from './TextContentActions';
import { receiveFlashcard } from './FlashcardActions';

export const RECEIVE_ALL_COMMENTS = 'RECEIVE_ALL_COMMENTS';
export const RECEIVE_SPECIFIC_COMMENTS = 'RECEIVE_SPECIFIC_COMMENTS';
export const CLEAR_ALL_COMMENTS = 'CLEAR_ALL_COMMENTS';
export const CLEAR_SPECIFIC_COMMENTS = 'CLEAR_SPECIFIC_COMMENTS';
export const RECEIVE_COMMENT_ERRORS = 'RECEIVE_COMMENT_ERRORS';

export const receiveAllComments = (payload) => ({
  type: RECEIVE_ALL_COMMENTS,
  payload
});

export const receiveSpecificComments = (criteria, payload) => dispatch => {
  if (criteria.action === 'render_entity') {
    if (criteria.commentable_type === 'Section') {
      dispatch(receiveSection(payload));
    } else if (criteria.commentable_type === 'Question') {
      dispatch(receiveQuestion(payload));
    } else if (criteria.commentable_type === 'TextContent') {
      dispatch(receiveTextContent(payload));
    } else if (criteria.commentable_type === 'Flashcard') {
      dispatch(receiveFlashcard(payload));
    }
  } else if (criteria.action === 'render_comment') {
    dispatch({
      type: RECEIVE_SPECIFIC_COMMENTS,
      payload,
    });
  }
};

export const clearAllComments = () => ({
  type: CLEAR_ALL_COMMENTS
});

export const clearSpecificComments = () => ({
  type: CLEAR_SPECIFIC_COMMENTS
});

export const receiveCommentErrors = errors => ({
  type: RECEIVE_COMMENT_ERRORS,
  errors
});

export const fetchAllComments = (pageQs) => dispatch => (
  APIUtil.fetchAllComments(pageQs).then(
    payload => dispatch(receiveAllComments(payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const bulkUnflag = (commentIds, pageQs) => dispatch => (
  APIUtil.bulkUnflag(commentIds, pageQs).then(
    payload => dispatch(receiveAllComments(payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const bulkDelete = (commentIds, pageQs) => dispatch => (
  APIUtil.bulkDelete(commentIds, pageQs).then(
    payload => dispatch(receiveAllComments(payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const fetchSpecificComments = (criteria) => dispatch => (
  APIUtil.fetchSpecificComments(criteria).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const createComment = (criteria, comment) => dispatch => (
  APIUtil.createComment(criteria, comment).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const updateComment = (criteria, comment) => dispatch => (
  APIUtil.updateComment(criteria, comment).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const destroyComment = (criteria, comment) => dispatch => {
  APIUtil.destroyComment(criteria, comment).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
};

export const unflagComment = (criteria, comment) => dispatch => {
  APIUtil.unflagComment(criteria, comment).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
};

export const likeComment = (criteria, reaction) => dispatch => (
  APIUtil.likeComment(criteria, reaction).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);

export const flagComment = (criteria, reaction) => dispatch => (
  APIUtil.flagComment(criteria, reaction).then(
    payload => dispatch(receiveSpecificComments(criteria, payload)),
    errors  => dispatch(receiveCommentErrors(ErrorResponseUtil(errors))),
  )
);
