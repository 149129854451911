import { connect } from 'react-redux';

import LessonClone from './LessonClone';

import { fetchCoursesTree } from '../../../../Actions/CourseActions';
import { cloneLesson } from '../../../../Actions/LessonActions';

const mapStateToProps = (state) => ({
  courses: state.entities.coursesTree,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoursesTree: () => dispatch(fetchCoursesTree()),
  cloneLesson: (targetCourseId, lessonId) => dispatch(cloneLesson(targetCourseId, lessonId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonClone);
