import React from 'react';

import './CircleProgressBar.scss';

const CircleProgressBar = ({ percent, heading, subHeading }) => {
  return (
    <div className="CircleProgressBar">
      <div className="Progress">
        <svg>
          <circle cx="75" cy="75" r="70"></circle>
          <circle cx="75" cy="75" r="70" style={{ "--percent": percent }}></circle>
        </svg>
        <div className="Text">
          <div className="Heading">{heading}</div>
          <div className="Sub-Heading">{subHeading}</div>
        </div>
      </div>
    </div>
  )
}

export default CircleProgressBar;
