import * as APIUtil from '../Util/PlatformApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_PLATFORMS = 'CLEAR_PLATFORMS';
export const RECEIVE_PLATFORMS = 'RECEIVE_PLATFORMS';
export const RECEIVE_PLATFORM_ERRORS = 'RECEIVE_PLATFORM_ERRORS';

export const clearPlatforms = () => ({
  type: CLEAR_PLATFORMS,
});

export const receivePlatforms = payload => ({
  type: RECEIVE_PLATFORMS,
  payload,
});

export const receivePlatformErrors = errors => ({
  type: RECEIVE_PLATFORM_ERRORS,
  errors,
});

export const fetchPlatforms = () => dispatch => (
  APIUtil.fetchPlatforms().then(
    payload => dispatch(receivePlatforms(payload)),
    errors => dispatch(receivePlatformErrors(ErrorResponseUtil(errors))),
  )
);
