import React from 'react';
import { Redirect } from 'react-router-dom';

import './LandingPage.css';
import ProfileNavBar from '../../NavBars/ProfileNavBar/ProfileNavBarContainer';

const landingPage = () => (
    <div className="LandingPage">
        <ProfileNavBar />

        <Redirect to="/signin" />
    </div>
);

export default landingPage;
