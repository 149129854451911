import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Filters from '../Filters/Filters';
import UserParticipation from './UserParticipation';
import UserPerformance from './UserPerformance';
import ComplianceRosterManagement from '../UserRosterManagement/ComplianceRosterManagement';
import Loader from '../../../Composed/Loader/Loader';

import './Compliance.scss';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Compliance = () => {
  const [loading, setLoading] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const formNames = ['OVERVIEW', 'USER MANAGEMENT'];

  const renderDashboard = () => {
    switch(formIndex) {
      case 0:
        return (
          <div className="OverView-Container">
            <Filters
              showFilters={['licenseFilter', 'timesFilter', 'departmentsFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {
              loading ? <Loader /> : (
                <>
                  <UserParticipation />
                  <UserPerformance />
                </>
              )
            }
          </div>
        );
      case 1:
        return (
          <div className="User-Roster-Management-Container">
            <Filters
              showFilters={['departmentsFilter']}
              forFilters={formNames[formIndex]}
              filtersLoaded={(flag) => setLoading(!flag)}
            />
            {loading ? <Loader /> : <ComplianceRosterManagement />}
          </div>
        );
    }
  }

  return (
    <div className="B2B-Admin-Compliance-Dashboard-Container">
      <div className="Compliance-Dashboard-Tabs">
        {
          formNames.map((formName, idx) => (
            <div
              key={`compliance-dashboard-tab-${idx}`}
              className={`Tab-Button ${formIndex === idx ? 'Active' : '' }`}
              onClick={() => {formIndex === idx ? null : setLoading(true); setFormIndex(idx)}}
            >
              {formName}
            </div>
          ))
        }
      </div>

      {renderDashboard()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);
