import React, { useState, useEffect } from 'react';

import './SpanishKeyboard.scss';

const SpanishKeyboard = ({ shouldFix = false }) => {
  const [isFixed, setIsFixed] = useState(false);

  const letters = ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ',
    'Á', 'É', 'Í', 'Ó', 'Ú', 'Ü', 'Ñ', '¿', '¡'];

  useEffect(() => {
    function handleScroll() {
      if (!shouldFix) {
        return null;
      }

      const elementProperties = document.getElementsByClassName('SpanishKeyboard')?.[0]?.getBoundingClientRect();

      if (elementProperties) {
        setIsFixed((elementProperties.top - 80) <= 0);
      }
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const buttons = letters.map(
    (letter, idx) => {
      return (
        <button
          key={ idx }
          onMouseDown={ (e) => {
            e.preventDefault();
            const input = document.getElementById("active");
            if (!input) {
              return;
            }
            let lastValue = input.value;
            input.value = 
              input.value.slice(0, input.selectionStart) + letter +
              input.value.slice(input.selectionEnd, input.value.length);
            const event = new Event('change', { bubbles: true });
            event.preventDefault();
            //following code only works for react 16
            let tracker = input._valueTracker;
            if (tracker) {
              tracker.setValue(lastValue);
            }
            //if upgrading find new way to diapatch a change event
            input.dispatchEvent(event);
          }}>
          {letter}
        </button>
      );
    }
  );

  return (
    <div className={`SpanishKeyboard ${isFixed ? 'Fixed' : ''}`}>
      <div className="Fixed-Spanish-Keyboard">
        { buttons }
      </div>
    </div>
  );
};

export default SpanishKeyboard;
