import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';

import { fetchCourses } from '../../../Actions/CourseActions';
import { fetchGroups } from '../../../Actions/GroupActions';
import { createReport, fetchReports } from '../../../Actions/ReportActions';
import { COLORS } from '../../../Style/Style';
import EnvironmentUtil from '../../../Util/EnvironmentUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import ErrorFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import Loader from '../../Composed/Loader/Loader';
import TutorialContainer from '../../Presentational/Tutorial/TutorialContainer';

const mapStateToProps = state => ({
    groups: state.admin.groups,
    reports: state.admin.reports,
    courses: state.entities.courses,
    currentUser: state.session.currentUser,
    flash: state.flash.reports,
    errors: state.errors.reports,
});

const mapDispatchToProps = dispatch => ({
    fetchGroups: () => dispatch(fetchGroups()),
    fetchReports: () => dispatch(fetchReports()),
    fetchCourses: () => dispatch(fetchCourses()),
    createReport: report => dispatch(createReport(report)),
});

const CourseProgress = ({
    groups,
    reports,
    courses,
    currentUser,
    fetchGroups,
    fetchReports,
    fetchCourses,
    createReport,
    errors,
    flash,
}) => {
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false);
    const [courseFilter, setCourseFilter] = useState('');
    const [groupFilter, setGroupFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');

    useEffect(() => {
        let startDate = new Date();
        let endDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        setStartDateFilter(dateFormat(startDate, 'yyyy-mm-dd'));
        setEndDateFilter(dateFormat(endDate, 'yyyy-mm-dd'));

        Promise.all([
            fetchGroups(),
            fetchReports(),
            fetchCourses(),
        ]).then(() => setLoading(false));
    }, [fetchGroups, fetchReports, fetchCourses]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const report = {
            institution_id: currentUser.institution_id,
            course_filter: courseFilter,
            group_filter: groupFilter,
            start_date_filter: startDateFilter,
            end_date_filter: endDateFilter,
            report_type: 'quiz_results',
        };

        setSubmitting(true);
        createReport(report).then(() => {
            gaEvent('generate_report', { institution_id: currentUser.institution_id });
            setSubmitting(false);
        })
    };

    const handleUrlBase = (src) => {
        return EnvironmentUtil().buildURL(src);
    };

    const renderReports = () => {
        const reportList = Object.values(reports).reverse().map(report => (
            <div
                className="B2BAdminDashboard-GroupList__line"
                key={report.id}
            >
                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={report.course_filter ? report.course_filter : 'All Courses'}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={report.group_filter ? report.group_filter : 'All Students'}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={report.start_date_filter || ''}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={report.end_date_filter || ''}
                />

                <TextBox
                    className="B2BAdminDashboard-GroupList__item"
                    text={report.created_at.split("T")[0]}
                />

                <a href={report?.file_url ? handleUrlBase(report.file_url) : ''} download>Download</a>
            </div>
        ));
        return (
            <div className="B2BAdminDashboard-GroupList">
                <div className="B2BAdminDashboard-GroupList__line">
                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Course Filter"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Group Filter"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Start Date Filter"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="End Date Filter"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Created At"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Actions"
                        tag="h5"
                    />
                </div>
                {reportList}
            </div>
        );
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="B2BAdminDashboard B2BAdminDashboard-CourseProgress-Report">
            {submitting && <MirrorLoader message="Generating Report!" />}

            <TutorialContainer />

            <div className="B2BAdminDashboard-Title">
                <TextBox
                    text="Course Progress"
                    tag="h4"
                    style={{
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <TextBox
                    text="To generate a course progress report, please specify course level(s) and user group:"
                    style={{
                        margin: '20px 0',
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <form
                    className="B2BAdminDashboard-GroupList SearchBox Tour-Step-Course-Progress-Filter-Form Form"
                >
                    <div className="SearchBox__label">
                        <span>COURSE</span>
                        <select
                            className="B2BAdminDashboard-Select"
                            defaultValue=""
                            onChange={e => setCourseFilter(e.currentTarget.value)}
                        >
                            <option value="">All Courses</option>
                            {
                                Object.values(courses).map(course => (
                                    <option
                                        key={course.id}
                                        value={course.name}
                                    >
                                        {course.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="SearchBox__label">
                        <span>GROUP</span>
                        <select
                            className="B2BAdminDashboard-Select"
                            defaultValue=""
                            onChange={e => setGroupFilter(e.currentTarget.value)}
                        >
                            <option value="">All Students</option>
                            {
                                Object.values(groups).map(group => (
                                    group.status === 'active' &&
                                    <option
                                        key={group.id}
                                        value={group.name}
                                    >
                                        {group.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="SearchBox__label">
                        <span>START DATE</span>
                        <InputBox
                            className="B2BAdminDashboard-Date"
                            type="date"
                            value={startDateFilter}
                            onChange={(e) => setStartDateFilter(e.currentTarget.value)}
                        />
                    </div>

                    <div className="SearchBox__label">
                        <span>END DATE</span>
                        <InputBox
                            className="B2BAdminDashboard-Date"
                            type="date"
                            value={endDateFilter}
                            onChange={(e) => setEndDateFilter(e.currentTarget.value)}
                        />
                    </div>

                    <div className="Generate-Report">
                        <ButtonBox
                            text="Generate Report"
                            className="B2BAdminDashboard-Button dark-blue"
                            onClick={(e) => handleSubmit(e)}
                        />
                    </div>
                </form>
            </div>

            <ErrorFlashBox
                errors={errors}
                flash={flash}
            />

            <div className="B2BAdminDashboard-GroupList__container">
                <TextBox
                    text="Recent Reports:"
                    style={{
                        margin: '20px 0',
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                { renderReports() }
            </div>
        </div>
    );
};

CourseProgress.propTypes = {
    groups: PropTypes.objectOf(PropTypes.object).isRequired,
    reports: PropTypes.objectOf(PropTypes.object).isRequired,
    courses: PropTypes.objectOf(PropTypes.object).isRequired,
    currentUser: PropTypes.shape({
        institution_id: PropTypes.number,
    }).isRequired,
    fetchGroups: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseProgress);
