import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MoonLoader } from 'react-spinners';

import { setCurrentWorkPlace } from '../../../Actions/WorkPlaceActions';
import { clearLingos } from '../../../Actions/LingoActions';
import { clearPledges } from '../../../Actions/PledgeActions';
import { syncSeatTime } from '../../../Actions/SeatTimeActions';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import './WorkplaceSwitch.scss';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentWorkPlace: (workplaceId) => dispatch(setCurrentWorkPlace(workplaceId)),
  clearLingos: () => dispatch(clearLingos()),
  clearPledges: () => dispatch(clearPledges()),
});

const WorkplaceSwitch = ({ currentUser, currentWorkplace, setCurrentWorkPlace, clearLingos, clearPledges }) => {
  const [switching, setSwitching] = useState(false);

  useEffect(() => {
    syncSeatTime();
  }, []);

  const institutionDisplayName = (workplace) => {
    if (workplace.institution_name) {
      return `${workplace.original_institution_name} (${workplace.institution_name})`;
    } else {
      return workplace.original_institution_name;
    }
  }

  return (
    <div className="Workplace-Switch-Container">
      {
        switching && (
          <div className="Switcher-Loader">
            <MoonLoader color="#fff" />
          </div>
        )
      }

      <select
        className="Switcher"
        value={currentWorkplace.id}
        onChange={(e) => {
          setSwitching(true);
          clearLingos();
          clearPledges();
          gaEvent('workplace_switch', { workplace_id: e.currentTarget.value });
          setCurrentWorkPlace(e.currentTarget.value).then(
            res => window.location.reload()
          )
        }}
      >
        {
          currentUser.workplaces.map((workplace, idx) => (
            <option
              key={idx}
              value={workplace.id}
              children={institutionDisplayName(workplace)}
            />
          ))
        }
      </select>
    </div>
  );
}

WorkplaceSwitch.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentWorkplace: PropTypes.object.isRequired,
  setCurrentWorkPlace: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSwitch);
