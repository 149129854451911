import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectLesson from '../../../Composed/SelectCourseEntity/SelectLesson/SelectLesson';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import ErrorFlashBox from '../../../Composed/ErrorFlashBox/ErrorsFlashBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import '../Clone.scss';

const SectionableClone = ({ courses, model, entity, fetchCoursesTree, cloneActivity, cloneTopic, cloneQuiz }) => {
  const [loading, setLoading] = useState(true);
  const [cloning, setCloning] = useState(false);
  const [errors, setErrors] = useState({});
  const [flash, setFlash] = useState({});
  const [targetLessonId, setTargetLessonId] = useState(null);

  useEffect(() => {
    fetchCoursesTree().then((res) => {
      setLoading(false);
    });
  }, [fetchCoursesTree]);

  const resetErrorAndFlash = () => {
    setErrors({});
    setFlash({});
  }

  const handleLessonSelection = (e) => {
    resetErrorAndFlash();
    setTargetLessonId(e.id);
  }

  const callModelApi = () => {
    switch (model) {
      case 'Activity': return cloneActivity(targetLessonId, entity.id);
      case 'Topic': return cloneTopic(targetLessonId, entity.id);
      case 'Quiz': return cloneQuiz(targetLessonId, entity.id);
      default: return ''
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrorAndFlash();

    if (!targetLessonId) {
      setErrors({ '': 'Please select target lesson first!' });
      return null;
    }

    setCloning(true);
    callModelApi().then((res) => {
      res.message ? setFlash({ message: res.message }) : setErrors({ '': res.error });
      setCloning(false);
    });
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="Clone-Modal-Container Sectionable-Clone-Container">
      {cloning && <MirrorLoader message={`Cloning ${model}!`} />}

      <ErrorFlashBox errors={errors} flash={flash} />

      <SelectLesson
        courses={courses}
        selectionCallBack={e => handleLessonSelection(e)}
      />

      <div className="Buttons-Container">
        <ButtonBox
          className="CreationDashboards-ButtonBox Clone-Button"
          text={`Clone ${model}`}
          onClick={e => handleSubmit(e)}
        />
      </div>
    </div>
  )
}

SectionableClone.propTypes = {
  courses: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  fetchCoursesTree: PropTypes.func.isRequired,
  cloneActivity: PropTypes.func.isRequired,
  cloneTopic: PropTypes.func.isRequired,
  cloneQuiz: PropTypes.func.isRequired,
};

export default SectionableClone;
