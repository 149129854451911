import { combineReducers } from 'redux';

import users from './Stats/UsersReducer';
import b2bAdmin from './Stats/B2BAdminReducer';
import teAdmin from './Stats/TEAdminReducer';

export default combineReducers({
  users,
  b2bAdmin,
  teAdmin,
});
