import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { clearBreadcrumbs } from '../../../Actions/BreadcrumbActions';

const mapStateToProps = (state) => ({
  breadcrumbs: state.entities.breadcrumbs,
});

const mapDispatchToProps = (dispatch) => ({
  clearBreadcrumbs: () => dispatch(clearBreadcrumbs()),
});

class ReactBreadcrumbs extends Component {
  componentDidMount() {
    this.props.clearBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  render() {
    const { className, hidden, separator, children, breadcrumbs } = this.props;

    return (
      <div className={className}>
        <nav className={`breadcrumbs ${hidden ? 'breadcrumbs--hidden' : ''}`}>
          <div className="breadcrumbs__inner">
            {
              breadcrumbs.map((crumb, idx) => (
                <span key={`crumb-${crumb.id}`} className="breadcrumbs__section">
                  <NavLink
                    exact
                    className="breadcrumbs__crumb"
                    activeClassName="breadcrumbs__crumb--active"
                    to={{
                      pathname: crumb.pathname,
                      search: crumb.search,
                      state: crumb.state
                    }}
                  >
                    <div className="Breadcrumb-Item">
                      <h6 className="Breadcrumb-Item-Title">{crumb.title?.title}</h6>
                      <h4 className="Breadcrumb-Item-Value">{crumb.title?.value}</h4>
                    </div>
                  </NavLink>

                  {
                    (idx < breadcrumbs.length - 1)
                      ? <span className="breadcrumbs__separator">{separator}</span>
                      : null
                  }
                </span>
              ))
            }
          </div>
        </nav>

        {children}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactBreadcrumbs);
