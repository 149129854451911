import * as APIUtil from '../Util/CourseProgressApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_COURSE_PROGRESSES = 'RECEIVE_COURSE_PROGRESSES';
export const RECEIVE_COURSE_PROGRESS = 'RECEIVE_COURSE_PROGRESS';
export const RECEIVE_COURSE_PROGRESS_AND_UPDATE = 'RECEIVE_COURSE_PROGRESS_AND_UPDATE';
export const RECEIVE_PROGRESS_ERRORS = 'RECEIVE_PROGRESS_ERRORS';
export const CLEAR_COURSE_PROGRESSES = 'CLEAR_COURSE_PROGRESSES';

export const receiveCourseProgresses = payload => ({
    type: RECEIVE_COURSE_PROGRESSES,
    payload,
});

export const receiveCourseProgress = payload => ({
    type: RECEIVE_COURSE_PROGRESS,
    payload,
});

export const receiveCourseProgressAndUpdate = payload => ({
    type: RECEIVE_COURSE_PROGRESS_AND_UPDATE,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_PROGRESS_ERRORS,
    errors,
});

export const clearCourseProgresses = () => ({
    type: CLEAR_COURSE_PROGRESSES
});

export const fetchCourseProgresses = () => dispatch => (
    APIUtil.fetchCourseProgresses().then(
        payload => dispatch(receiveCourseProgresses(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const saveCourseProgress = (courseProgress, sectionProgress, lessonId, topicId, sectionId) => dispatch => (
    APIUtil.saveCourseProgress(courseProgress, sectionProgress, lessonId, topicId, sectionId).then(
        payload => dispatch(receiveCourseProgress(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const saveCourseProgressAndUpdate = courseProgress => dispatch => (
    APIUtil.saveCourseProgress(courseProgress).then(
        payload => dispatch(receiveCourseProgressAndUpdate(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const retake = (courseId) => dispatch => (
  APIUtil.retake(courseId).then(
    payload => payload,
    errors => errors,
  )
);

export const importProgress = (importParams) => dispatch => (
    APIUtil.importProgress(importParams).then(
        payload => payload,
        errors => ErrorResponseUtil(errors),
    )
);
