import axios from './AxiosUtil';

export const fetchNPSOverview = () => (
  axios.get('/api/net_promoter_scores/overview').then(
    res => res.data
  )
);

export const fetchNPSDetails = queryString => (
  axios.get(`/api/net_promoter_scores/details?${queryString}`).then(
    res => res.data
  )
);

export const createUserNetPromoterScore = netPromoterScore => (
  axios.post('/api/net_promoter_scores', { net_promoter_score: netPromoterScore }).then(
    res => res.data
  )
);
