import React from 'react';

import './CheckBox.css';

const CheckBox = (props) => {
    return (
        <label className="CheckBox">
            <input type="checkbox" {...props}/>
            <span className="CheckBox-Custom" />
        </label>
    );
};

export default CheckBox;
