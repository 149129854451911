import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

const collect = (monitor) => {
  return {
    answerData: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
};

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    pointerEvents: 'none',
    transform: transform,
    WebkitTransform: transform,
    position: 'absolute',
    top: `${ window.scrollY }px`,
    left: `${ window.scrollX }px`,
    zIndex: 999999,
  };
};

const DragPreview = ({ answerData, isDragging, currentOffset }) => {
  if (!isDragging) {
    return <div></div>;
  }

  return (
    <div
      className="DragElement"
      style={getItemStyles(currentOffset)}>
      { answerData.answer }
    </div>
  );
};

DragPreview.propTypes = {
  answerData: PropTypes.object,
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  isDragging: PropTypes.bool
};

export default DragLayer(collect)(DragPreview);
