import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/QuestionApiUtil';

export const RECEIVE_QUESTION_ERRORS = 'RECEIVE_QUESTION_ERRORS';
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS';
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
export const RECEIVE_QUESTION = 'RECEIVE_QUESTION';

export const receiveQuestions = payload => {
  return {
    type: RECEIVE_QUESTIONS,
    payload,
  };
};

export const clearQuestions = () => {
  return {
    type: CLEAR_QUESTIONS,
  };
};

export const receiveQuestion = payload => ({
  type: RECEIVE_QUESTION,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_QUESTION_ERRORS,
  errors
});

export const createQuestion = (question) => dispatch => (
  APIUtil.createQuestion(question).then(
    payload => dispatch(receiveQuestion(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const editQuestion = (question) => dispatch => (
  APIUtil.editQuestion(question).then(
    payload => dispatch(receiveQuestion(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteQuestion = questionId => dispatch => (
  APIUtil.deleteQuestion(questionId).then(
    payload => dispatch(receiveQuestion(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const uploadAudio = formData => dispatch => (
  APIUtil.uploadAudio(formData).then(
    payload => dispatch(receiveQuestion(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
