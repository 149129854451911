export const COLORS = {
    black:          '#1F1F1F',
    white:          '#F0F0F0',
    Blue:           '#183D5B',
    Blue1:          '#3d6280',
    greyBlue:       '#8c9dac',
    greyBlue1:      '#c5ced6',
    lightBlue:      '#669dac',
    lightBlue1:     '#7fc3d5',
    lightBlue2:     '#a2daea',
    lightBlue3:     '#c4e3fa',
    lightBlue4:     '#d6edf5',
    lightBlueWhite: '#f8fcfd',
    grey:           '#777777',
    grey1:          '#999999',
    greyWhite:      '#f0f0f0',
    red:            '#c62323',
    redPink:        '#D47e7e',
    green:          '#7de3bb',
    green1:         '#cef5d3',
    greenBlue:      '#73e7e2',
    yellow:         '#e3db88',
    yellowWhite:    '#f4f4e5',
};
