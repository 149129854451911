import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AdminRoute } from '../../../Util/RouteUtil';
import LearnBreadcrumbs from '../../Composed/LearnBreadcrumbs/LearnBreadcrumbsContainer';
import Loader from '../../Composed/Loader/Loader';
import Footer from '../../Basic/Footer/Footer';
import LearnNavBar from '../../NavBars/LearnNavBar/LearnNavBarContainer';
import CreationCoursesDashboard from '../../Dashboards/CreationCoursesDashboard/CreationCoursesDashboardContainer';
import CreationLearningDashboard from '../../Dashboards/CreationLearningDashboard/CreationLearningDashboardContainer';
import CreationLessonsDashboard from '../../Dashboards/CreationLessonsDashboard/CreationLessonsDashboardContainer';
import CreationQuizDashboard from '../../Dashboards/CreationQuizDashbord/CreationQuizDashboardContainer';
import CreationActivityDashboard from '../../Dashboards/CreationActivityDashboard/CreationActivityDashboardContainer';
import CreationTopicsDashboard from '../../Dashboards/CreationTopicsDashboard/CreationTopicsDashboardContainer';

const LearnCreationPage = ({ clearCourses, fetchCourses, clearCourseTypes, fetchCourseTypes }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([
            clearCourses(),
            fetchCourses(),
            clearCourseTypes(),
            fetchCourseTypes(),
        ]).then(() => setLoading(false));
    }, [clearCourses, fetchCourses, clearCourseTypes, fetchCourseTypes]);

    if (loading) {
        return (
            <div className="CanopyLearnPage">
                <div>
                    <Route path="/" component={LearnNavBar} />
                    <div className="CanopyLearnPage-Loading">
                        <Loader />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="CanopyLearnPage">
            <div>
                <Route path="/" component={LearnNavBar} />

                <LearnBreadcrumbs />

                <Switch>
                    <AdminRoute
                        path="/creation/courses/:courseId/lessons/:lessonId/activity"
                        component={CreationActivityDashboard}
                    />

                    <AdminRoute
                        path="/creation/courses/:courseId/lessons/:lessonId/topics/:topicId"
                        component={CreationLearningDashboard}
                    />

                    <AdminRoute
                        path="/creation/courses/:courseId/lessons/:lessonId/quiz"
                        component={CreationQuizDashboard}
                    />

                    <AdminRoute
                        path="/creation/courses/:courseId/lessons/:lessonId"
                        component={CreationTopicsDashboard}
                    />

                    <AdminRoute
                        path="/creation/courses/:courseId/lessons"
                        component={CreationLessonsDashboard}
                    />

                    <AdminRoute
                        path="/creation"
                        component={CreationCoursesDashboard}
                    />
                </Switch>
            </div>

            <Footer />
        </div>
    );
};

export default LearnCreationPage;
