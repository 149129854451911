import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

import './BarChart.scss';

Exporting(Highcharts);

const BarChart = ({ id, data }) => {
  Highcharts.chart(id, {
    chart: {
      type: 'bar'
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: data.xAxis,
      title: {
        text: null
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: data.yAxisTitle,
        align: 'high'
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    },
    series: data.series
  });

  return null;
}

export default BarChart;
