import axios from '../AxiosUtil';

export const fetchUserStreaks = () => {
  return axios.get('/api/engage/streaks/user_streaks').then(
    res => res.data
  )
};

export const fetchLeaderboard = (streakType) => {
  return axios.get(`/api/engage/streaks/leaderboard?streak_type=${streakType}`).then(
    res => res.data
  )
};
