import {
  RECEIVE_INSTITUTIONS,
  RECEIVE_INSTITUTION,
  RECEIVE_INSTITUTION_ERRORS,
  CLEAR_INSTITUTION_ERRORS
} from '../../Actions/InstitutionActions';

export default (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_INSTITUTION_ERRORS:
      return action.errors;
    case RECEIVE_INSTITUTIONS:
      return {};
    case RECEIVE_INSTITUTION:
      return {};
    case CLEAR_INSTITUTION_ERRORS:
      return {};
    default:
      return state;
  }
};