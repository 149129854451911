import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { MoonLoader, ScaleLoader } from 'react-spinners';

import { clearAllComments, fetchAllComments, bulkUnflag, bulkDelete } from  '../../../Actions/CommentActions';

import './ManageAllComments.scss';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';

const mapStateToProps = (state) => ({
  comments: state.entities.comments.all
});

const mapDispatchToProps = (dispatch) => ({
  clearAllComments: () => dispatch(clearAllComments()),
  fetchAllComments: (pageQs) => dispatch(fetchAllComments(pageQs)),
  bulkUnflag: (commentIds, pageQs) => dispatch(bulkUnflag(commentIds, pageQs)),
  bulkDelete: (commentIds, pageQs) => dispatch(bulkDelete(commentIds, pageQs)),
});

const ManageAllComments = ({ comments, clearAllComments, fetchAllComments, bulkDelete, bulkUnflag }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ flag: 1, all: 1 });
  const [showBulkAction, setShowBulkAction] = useState({ flag: false, all: false });
  const [selectedComments, setSelectedComments] = useState({ flag: [], all: [] });
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setLoading(true);
    clearAllComments();
    fetchAllComments(pageQs()).then(
      res => resetState()
    );
  }, [page]);

  const pageQs = () => (
    `flag_page=${page.flag}&all_page=${page.all}`
  );

  const totalPages = (pool) => (
    Math.ceil(comments[`total_${pool}`] / 20)
  );

  const renderPageNumbers = (pool) => {
    let skip;
    let pagesCount = totalPages(pool);

    return [...Array(pagesCount)].map((e, i) => {
      const idx = i + 1;

      if (
        (idx === 1) || (idx === pagesCount) || (Math.abs(idx - page[pool]) <= 2)
      ) {
        skip = false;
        return (
          <div className="Number">
            <div
              className={`item ${idx === page[pool] ? 'active' : ''}`}
              onClick={() => setPage({ ...page, [pool]: idx })}
            >
              { idx }
            </div>
          </div>
        )
      }

      if (!skip) {
        skip = true;
        return (
          <div className="Number">
            <div className="item">...</div>
          </div>
        )
      }

      return null;
    })
  };

  const commentTime = (created_at) => {
    let time = new Date(created_at);

    return `${time.toLocaleString('default', { month: 'short' })} ${time.getDate()}, ${time.getFullYear()}`
  };

  const buildUserSlideLink = (val) => {
    let nextToq = val.commentable.topic_id ? `topics/${val.commentable.topic_id}` : 'quiz';
    let nextSec = val.commentable.position - 1;
    let link = `#/learn/courses/${val.commentable.course_id}/lessons/${val.commentable.lesson_id}/?nextToq=${nextToq}&nextSec=${nextSec}&copn=true`;

    return (
      <a href={link} target="_blank">
        User Side
      </a>
    )
  };

  const buildAdminSlideLink = (val) => {
    let nextLes = val.commentable.lesson_id;
    let nextToq = val.commentable.topic_id ? `/topics/${val.commentable.topic_id}` : '/quiz';
    let nextSec = val.commentable.position - 1;
    let link = `#/creation/courses/${val.commentable.course_id}/?nextLes=${nextLes}&nextToq=${nextToq}&nextSec=${nextSec}`;

    return (
      <a href={link} target="_blank">
        Admin Side
      </a>
    )
  };

  const handleSelectComment = (e, val, pool) => {
    let newSelection = selectedComments[pool];

    if (e.currentTarget.checked) {
      newSelection.push(val.comment.id);
    } else {
      let idIndex = newSelection.indexOf(val.comment.id);
      newSelection.splice(idIndex, 1);
    }

    setSelectedComments({ ...selectedComments, [pool]: newSelection });
  };

  const handleBulkUnflag = (e, commentId, pool) => {
    e.preventDefault();
    setProcessing(true);
    let newPool;

    if (commentId) {
      newPool = [commentId]
    } else {
      newPool = selectedComments[pool]
    }

    bulkUnflag(newPool, pageQs()).then(
      res => resetState()
    );
  };

  const handleBulkDelete = (e, commentId, pool) => {
    e.preventDefault();
    setProcessing(true);
    let newPool;

    if (commentId) {
      newPool = [commentId]
    } else {
      newPool = selectedComments[pool]
    }

    bulkDelete(newPool, pageQs()).then(
      res => resetState()
    );
  };

  const resetState = () => {
    setLoading(false);
    setShowBulkAction({ flag: false, all: false });
    setSelectedComments({ flag: [], all: [] });
    setProcessing(false);
  }

  if (loading) {
    return (
      <div className="All-Comments-Loader">
        <MoonLoader color="#000" />
      </div>
    )
  };

  return (
    <div className="Admin-Dashboard-Main">
      <div className="AdminDashboard-Pages">
        <div className="AdminDashboard-Manage">
          <h2>Manage Comments</h2>
          {
            processing && (
              <div className="Comments-Load">
                <ScaleLoader color="#fff" />
              </div>
            )
          }
          <div className="Flag-Comments-Container">
            <h3>
              <ImageBox className="" src="comment-warning.svg" />Flagged Comments
            </h3>
            <div className="Bulk-Action">
              <ButtonBox
                className="bulk-actions-btn"
                text={
                  <div className="fa bulk-actions">
                    <span>&#9881; Bulk Actions</span><span>&#9660;</span>
                  </div>
                }
                onClick={() => setShowBulkAction({ ...showBulkAction, 'flag': !showBulkAction.flag })}
              />
              {
                showBulkAction.flag && (
                  <div className="bulk-actions-section">
                    <div className="action">
                      <ButtonBox
                        text="Reinstate comments"
                        onClick={(e) => handleBulkUnflag(e, null, 'flag')}
                      />
                    </div>
                    <div className="action danger">
                      <ButtonBox
                        text="Archive comments"
                        onClick={(e) => handleBulkDelete(e, null, 'flag')}
                      />
                    </div>
                  </div>
                )
              }
            </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Comment</th>
                  <th>Reason</th>
                  <th>Flag Date</th>
                  <th>Course/Slide</th>
                  <th>Commenter's Account Email</th>
                  <th>Flagged By</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  comments.flag?.map((val, idx) => (
                    <tr key={`flag-${val.comment.id}-${val.flag.id}`}>
                      <td>
                        <CheckBox
                          name="flag"
                          checked={selectedComments.flag.indexOf(val.comment.id) !== -1}
                          onClick={(e) => handleSelectComment(e, val, 'flag')}
                        />
                      </td>
                      <td>{val.comment.text}</td>
                      <td>{val.flag.reason_type}</td>
                      <td>{commentTime(val.flag.created_at)}</td>
                      <td>
                        <div className="Course-Slide">
                          {val.commentable.name}
                          <div className="Links">
                            <span className="Slide">{buildUserSlideLink(val)}</span>|
                            <span className="Slide">{buildAdminSlideLink(val)}</span>
                          </div>
                        </div>
                      </td>
                      <td><span className="Email">{val.user.email}</span></td>
                      <td><span className="Email">{val.flagger.email}</span></td>
                      <td>
                        <div className="input-group select-group">
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-pushpin" aria-hidden="true"></span>
                          </span>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              if (e.currentTarget.value == 'unflag') {
                                handleBulkUnflag(e, val.comment.id, null)
                              } else if (e.currentTarget.value == 'delete') {
                                handleBulkDelete(e, val.comment.id, null)
                              }
                            }}
                          >
                            <option value="">Actions</option>
                            <option value="unflag">Reinstate comment</option>
                            <option value="delete">Archive comment</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Prev">
              <div
                className={`item ${page.flag === 1 ? 'inactive' : 'active'}`}
                onClick={() => (page.flag !== 1 && setPage({ ...page, ['flag']: page.flag - 1 }))}
              >
                <i className="fa fa-angle-double-left"></i> Previous
              </div>
            </div>

            { renderPageNumbers('flag') }

            <div className="Next">
              <div
                className={`item ${page.flag === totalPages('flag') ? 'inactive' : 'active'}`}
                onClick={() => (page.flag !== totalPages('flag') && setPage({ ...page, ['flag']: page.flag + 1 }))}
              >
                Next <i className="fa fa-angle-double-right"></i>
              </div>
            </div>
          </div>

          <div className="Recent-Comments-Container">
            <h3>
              <ImageBox className="" src="comment-icon.svg" /> All Comments
            </h3>
            <div className="Bulk-Action">
              <ButtonBox
                className="bulk-actions-btn"
                text={
                  <div className="fa bulk-actions">
                    <span>&#9881; Bulk Actions</span><span>&#9660;</span>
                  </div>
                }
                onClick={() => setShowBulkAction({ ...showBulkAction, 'all': !showBulkAction.all })}
              />
              {
                showBulkAction.all && (
                  <div className="bulk-actions-section">
                    <div className="action danger">
                      <ButtonBox
                        text="Archive comments"
                        onClick={(e) => handleBulkDelete(e, null, 'all')}
                      />
                    </div>
                  </div>
                )
              }
            </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Comment</th>
                  <th>Comment Date</th>
                  <th>Course/Slide</th>
                  <th>User Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  comments.recent.map((val, idx) => (
                    <tr key={`all-${val.comment.id}${idx}`}>
                      <td>
                        <CheckBox
                          name="all"
                          checked={selectedComments.all.indexOf(val.comment.id) !== -1}
                          onClick={(e) => handleSelectComment(e, val, 'all')}
                        />
                      </td>
                      <td>{val.comment.text}</td>
                      <td>{commentTime(val.comment.created_at)}</td>
                      <td>
                        <div className="Course-Slide">
                          {val.commentable.name}
                          <div className="Links">
                            <span className="Slide">{buildUserSlideLink(val)}</span>|
                            <span className="Slide">{buildAdminSlideLink(val)}</span>
                          </div>
                        </div>
                      </td>
                      <td><span className="Email">{val.user.email}</span></td>
                      <td>
                        <div className="input-group select-group">
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-pushpin" aria-hidden="true"></span>
                          </span>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              if (e.currentTarget.value == 'delete') {
                                handleBulkDelete(e, val.comment.id, null)
                              }
                            }}
                          >
                            <option value="">Actions</option>
                            <option value="delete">Archive Comment</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Prev">
              <div
                className={`item ${page.all === 1 ? 'inactive' : 'active'}`}
                onClick={() => (page.all !== 1 && setPage({ ...page, ['all']: page.all - 1 }))}
              >
                <i className="fa fa-angle-double-left"></i> Previous
              </div>
            </div>

            { renderPageNumbers('all') }

            <div className="Next">
              <div
                className={`item ${page.all === totalPages('all') ? 'inactive' : 'active'}`}
                onClick={() => (page.all !== totalPages('all') && setPage({ ...page, ['all']: page.all + 1 }))}
              >
                Next <i className="fa fa-angle-double-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAllComments);
