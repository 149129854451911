import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Switch from '../../Basic/Switch/Switch';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';

import { updateUserPreference } from '../../../Actions/UserPreferenceActions';

import './CreateEditUserPreference.scss';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateUserPreference: (userPreference) => dispatch(updateUserPreference(userPreference))
});

const CreateEditUserPreference = ({ updateUserPreference, userPreference }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    let userPreferenceParams = { id: userPreference.id, enabled: e.currentTarget.checked };

    setError('');
    setSubmitting(true);
    updateUserPreference(userPreferenceParams).then((res) => {
      if (res.errors) {
        setError(res.errors.message);
      }

      setSubmitting(false);
    });
  }

  return (
    <form className="User-Preference-Form">
      {submitting && <MirrorLoader message="Updating Preference!" />}

      {
        error &&
        <ul className="Error list-disc">
          <li>{error}</li>
        </ul>
      }

      <div className="Preference-Item">
        <Switch
          className="Name"
          name={userPreference.preference.name}
          value={userPreference.preference.name}
          checked={userPreference.enabled}
          onChange={e => handleSubmit(e)}
        />

        <div className="Description">
          {userPreference.preference.description}
        </div>
      </div>
    </form>
  )
}

CreateEditUserPreference.propTypes = {
  updateUserPreference: PropTypes.func.isRequired,
  userPreference: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUserPreference);
