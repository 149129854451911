import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/ReportApiUtil';

export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export const RECEIVE_REPORT_ERRORS = 'RECEIVE_REPORT_ERRORS';
export const RECEIVE_REPORT = 'RECEIVE_REPORT';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export const receiveReports = payload => {
    return {
        type: RECEIVE_REPORTS,
        payload,
    };
};

export const clearReports = () => {
    return {
        type: CLEAR_REPORTS,
    };
};

export const receiveReport = payload => ({
    type: RECEIVE_REPORT,
    payload,
});

export const receiveErrors = errors => ({
    type: RECEIVE_REPORT_ERRORS,
    errors,
});

export const fetchReports = () => dispatch => (
    APIUtil.fetchReports().then(
        payload => dispatch(receiveReports(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createReport = (report) => dispatch => (
    APIUtil.createReport(report).then(
        payload => dispatch(receiveReport(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
