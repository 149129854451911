import React from 'react';

import './TableBox.css';
import TableEntryBox
  from '../../Presentational/TableEntryBox/TableEntryBox';
import FillInTheBlankBox
  from '../../Quiz/FillInTheBlankBox/FillInTheBlankBox';
import FillInTheBlankBoxMini
  from '../../Quiz/FillInTheBlankBox/FillInTheBlankBoxMini';
import CreateEditTableEntry
  from '../../Forms/CreateEditTableEntry/CreateEditTableEntry';
import Modal
  from '../../Composed/Modal/Modal';

const TableBox = ({ section, table, createTableEntry, editTableEntry,
                    editAnswer, editQuestion, deleteQuestion, uploadAudio,
                    creation, ...otherProps }) => {
  const data = [...Array(table.num_rows)].map(_ => [...Array(table.num_cols)]);
  
  if (section.section_type === "text") {
    table.table_entries.forEach( tableEntry => {
      data[tableEntry.row_index][tableEntry.col_index] = tableEntry;
    })
  } else if (section.section_type === "table_fill_in") {
    section.questions.forEach((question, index) => {
      data[Math.floor(index / table.num_cols)][index % table.num_cols] = question;
    })
  }

  if (creation && section.section_type === "text") {
    return (
      <table className={`${section.section_type}-Container`}>
        <tbody>
          {
            data.map((row, idx) => {
              return (
                <tr key={idx}>
                  {
                    (table.has_header && idx === 0) ?
                      row.map((entry, idx2) =>
                        <th key={idx2}>
                          <Modal
                            title={entry ? "Edit Entry" : "Add An Entry"}
                            form={
                              entry ?
                              close =>
                                <CreateEditTableEntry
                                  tableEntry={entry}
                                  closeModal={close}
                                  submitTableEntry={editTableEntry}
                                  section={section} />
                              :
                              close =>
                                <CreateEditTableEntry
                                  rowIndex={idx}
                                  colIndex={idx2}
                                  table={table}
                                  closeModal={close}
                                  submitTableEntry={createTableEntry}
                                  section={section} />
                            }>
                            {
                              open =>
                                <TableEntryBox 
                                  tableEntry={entry}
                                  onClick={open}
                                  section={section}
                                  creation={creation}
                                />
                            }
                          </Modal>
                        </th>
                      )
                      :
                      row.map((entry, idx2) =>
                        <td key={idx2}>
                          <Modal
                            title={entry ? "Edit Entry" : "Add An Entry"}
                            form={
                              entry ?
                                close =>
                                  <CreateEditTableEntry
                                    tableEntry={entry}
                                    closeModal={close}
                                    submitTableEntry={editTableEntry}
                                    section={section} />
                                :
                                close =>
                                  <CreateEditTableEntry
                                    rowIndex={idx}
                                    colIndex={idx2}
                                    table={table}
                                    closeModal={close}
                                    submitTableEntry={createTableEntry}
                                    section={section} />
                            }>
                            {
                              open =>
                                <TableEntryBox
                                  tableEntry={entry}
                                  onClick={open}
                                  section={section}
                                  creation={creation}
                                />
                            }
                          </Modal>
                        </td>
                      )
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }

  if (creation && section.section_type === 'table_fill_in') {
    return (
      <table className={`${section.section_type}-Container`}>
        <tbody>
          {
            data.map((row, idx) => {
              return (
                <tr key={idx}>
                  {
                    (table.has_header && idx === 0)
                    ? row.map((entry, idx2) =>
                      <th key={idx2}>
                        {
                          entry
                            ? <div className="TableEntryBox">
                                <FillInTheBlankBoxMini
                                  question={section.questions[(idx * table.num_cols + idx2)]}
                                  section={section}
                                  editAnswer={editAnswer}
                                  editQuestion={editQuestion}
                                  deleteQuestion={deleteQuestion}
                                  uploadAudio={uploadAudio}
                                  creation
                                  small
                                  { ...otherProps }
                                />
                              </div>
                            : <TableEntryBox
                                tableEntry={entry}
                                section={section}
                                creation={creation}
                              />
                        }
                      </th>
                    )
                    : row.map((entry, idx2) =>
                      <td key={idx2}>
                        {
                          entry
                            ? <div className="TableEntryBox">
                                <FillInTheBlankBoxMini
                                  question={section.questions[(idx * table.num_cols + idx2)]}
                                  section={section}
                                  editAnswer={editAnswer}
                                  editQuestion={editQuestion}
                                  deleteQuestion={deleteQuestion}
                                  uploadAudio={uploadAudio}
                                  creation
                                  small
                                  { ...otherProps }
                                />
                              </div>
                            : <TableEntryBox
                                tableEntry={entry}
                                section={section}
                                creation={creation}
                              />
                        }
                      </td>
                    )
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  return (
    <table className={`${section.section_type}-Container`}>
      <tbody>
        {
          data.map((row, idx) => {
            return (
              <tr key={idx}>
                {
                  (table.has_header && idx === 0) ?
                  row.map((entry, idx2) =>
                    <th key={idx2}>
                      {
                        section.section_type === "table_fill_in" && entry ?
                          <div className="TableEntryBox">
                            <FillInTheBlankBox
                              questions={section.questions}
                              currentQuestionIndex={idx * table.num_cols + idx2}
                              section={section}
                              small
                              { ...otherProps } />
                          </div>
                          :
                          <TableEntryBox 
                          tableEntry={entry}
                          section={section}
                          creation={creation}
                        />
                      }
                    </th>)
                  :
                  row.map((entry, idx2) =>
                    <td key={idx2}>
                      {
                        section.section_type === "table_fill_in" && entry ?
                          <div className="TableEntryBox">
                            <FillInTheBlankBox
                              questions={section.questions}
                              currentQuestionIndex={idx * table.num_cols + idx2}
                              section={section}
                              small
                              { ...otherProps } />
                          </div>
                          :
                          <TableEntryBox
                            tableEntry={entry}
                            section={section}
                            creation={creation}
                          />
                      }
                    </td>
                  )
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default TableBox;
