import React from 'react'

import TextBox from '../../Basic/TextBox/TextBox';
import QuestionBox from '../QuestionBox/QuestionBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

import { COLORS } from '../../../Style/Style';

const MultiButtonAnswerBox = (props) => {
  const { section, lessonId, questions, currentQuestionIndex, quizProgress } = props;
  const currentQuestion = questions[currentQuestionIndex];
  const answerTexts = currentQuestion.answers.map(a => a.text);

  const pinUnansweredRender = () => {
    return (
      props.pinUnanswered &&
      !Object.keys(quizProgress?.[section.id]?.[currentQuestion.id] || {}).length &&
      (
        <div className="Pin-Unanswered">
          <ImageBox className="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  const toggleClickHandler = () => {
    gaEvent('multiple_answer_option_select', { section_id: section.id });
    return (e) => {
      let idx = Number(e.currentTarget.dataset.id);
      let opt = currentQuestion.options[idx];

      if (quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.id) {
        unSelectOption(opt, idx);
      } else {
        selectOption(opt, idx);
      }
    };
  };

  const selectOption = (userOption, idx) => {
    let answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            [idx]: {
              id:         userOption.id,
              text:       userOption.text,
              is_correct: answerTexts.includes(userOption.text)
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  };

  const unSelectOption = (userOption, idx) => {
    let answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            [idx]: {
              id: '',
              text: '',
              is_correct: ''
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  }

  const revealAnswersHandler = (option, idx) => {
    if (answerTexts.includes(option.text)) {
      if (!quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.is_correct) {
        selectOption(option, idx);
      }
    } else {
      if (quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.id) {
        unSelectOption(option, idx);
      }
    }
  }

  const checkAnswersHandler = (option, idx) => {
    let correct = (<i class="fas fa-check icon-correct ml-10"/>);
    let incorrect = (<i class="fas fa-times icon-wrong ml-10"/>);

    if (answerTexts.includes(option.text)) {
      if (quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.is_correct) {
        return correct;
      } else {
        return incorrect;
      }
    } else {
      if (quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.id) {
        return incorrect;
      }
    }
  }

  const buttons = () => {
    if (currentQuestion.options.length === 0 || currentQuestion.answers.length === 0) {
      return (
        <div className="MultiButtonChoiceBox-ButtonBox-container">
          This question is incomplete, missing answer.
        </div>
      )
    }

    return (
      currentQuestion.options.map((option, idx) => {
        props.revealAnswers && revealAnswersHandler(option, idx)

        return (
          <div
            className="MultiButtonChoiceBox-ButtonBox-div"
            key={ option.id }
          >
            { option.audio_url && <AudioPlayButton src={ option.audio_url }/> }

            <ButtonBox
              className={(quizProgress?.[section.id]?.[currentQuestion.id]?.[idx]?.id === option.id) ? 'TrueOrFalseBox-Button__selected' : 'TrueOrFalseBox-Button'}
              onClick={toggleClickHandler()}
              text={option.text}
              id={idx}
            />

            {props.checkAnswers && checkAnswersHandler(option, idx)}
          </div>
        )
      })
    )
  }

  return (
    <div className="MultiButtonChoiceBox">
      {pinUnansweredRender()}
      <div className="MultiButtonChoiceBox-QuestionBox">
        {
          !props.isComplianceCourse && (
            !props.small &&
            <div className="FillInTheBlankBox-QuestionBox-Position">
              <TextBox text={currentQuestion.position} />
            </div>
          )
        }

        <QuestionBox question={currentQuestion}>
          <TextBox
            text={currentQuestion.text}
            tag='p'
            style={{ color: COLORS.Blue }}
          />
        </QuestionBox>
      </div>

      <div className="MultiButtonChoiceBox-ButtonBox-container MultiButtonAnswerStack">
        {buttons()}
      </div>
    </div>
  )
}

export default MultiButtonAnswerBox;
