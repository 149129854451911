import { RECEIVE_LINGOS, CLEAR_LINGOS, RECEIVE_LINGOS_ERRORS } from '../../Actions/LingoActions';

const LingosReducer = (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_LINGOS:
      return Object.assign({}, state, action.payload.lingos);
    case CLEAR_LINGOS:
      return {};
    default:
      return state;  
  }
}

export default LingosReducer;
