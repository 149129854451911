import { updateProfileOccupation } from '../../../../Util/ProfileApiUtil';

export const UPDATE_PROFESSIONAL_INFORMATION = 'UPDATE_PROFESSIONAL_INFORMATION';

export const updateProfessionallInformation = (payload) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_PROFESSIONAL_INFORMATION,
        payload,
    });
    const updatedState = getState();
    try {
        const res = updateProfileOccupation(updatedState.session.currentUser.id, updatedState.profile.organizationOccupation);
        return res;
    } catch (error) {
        console.log(error);
    }
};
