import React, { Component } from 'react';

import './CreationQuizDashboard.css'
import { COLORS }
  from '../../../Style/Style';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import Modal
  from '../../Composed/Modal/Modal';
import CreateEditQuiz
  from '../../Forms/CreateEditQuiz/CreateEditQuiz';
import CreateEditSection
  from '../../Forms/CreateEditSection/CreateEditSection';
import SectionSummaryBox
  from '../../Composed/SectionSummaryBox/SectionSummaryBoxContainer';

class CreationQuizDashboard extends Component {

  componentDidMount() {
    let queryString = new URLSearchParams(this.props.location.search);

    this.props.fetchQuiz(this.props.currentLessonId).then(() => {
      if (queryString.has('nextSec')) {
        let scrollElement = document.getElementsByClassName('SectionBox')[parseInt(queryString.get('nextSec'))];
        window.scrollTo({ top: (scrollElement.offsetTop - 100), behavior: 'smooth' });
        scrollElement.querySelector('.Comments-Container .Comment-Btn').click();
      }
    });
  }

  render() {
    const { course, quizzes, editQuiz, createSection, currentLessonId, lessons } = this.props;
    const lesson = lessons[currentLessonId];
    const quiz = quizzes[lesson.quiz_id];

    if (!quiz.sections) {
      return <div className="CreationDashboards"></div>
    }

    return (
      <div
        className="CreationDashboards">
        <div
          className="CreationDashboards-Menu">
          <TextBox
            text={quiz.name}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Edit Quiz"
            form={
              close =>
                <CreateEditQuiz
                  quiz={quiz}
                  submitQuiz={editQuiz}
                  closeModal={close} />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Edit Quiz"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <div
          className="CreationDashboards-DescriptionBox">
          <TextBox
            text="Time Limit: "
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h5"
          />

          <TextBox
            text={quiz.time_limit ? `${quiz.time_limit} minutes` : "none"}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="p"
          />
        </div>

        <div
          className="CreationDashboards-Menu">
          <TextBox
            text="Sections"
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Add A Section"
            form ={
              close =>
                <CreateEditSection
                  quiz={quiz}
                  submitSection={createSection}
                  closeModal={close} />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Add A Section"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <SectionSummaryBox quizId={quiz.id} course={course} />
      </div>
    );
  }
}

export default CreationQuizDashboard;
