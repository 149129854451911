import { checkExistingUser as checkExistingUserApi, createUser as createUserApi } from '../Util/CreateUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_CREATE_USER_ERRORS = 'CLEAR_CREATE_USER_ERRORS';
export const CREATE_USER_VALIDATION_ERRORS = 'CREATE_USER_VALIDATION_ERRORS';
export const RECEIVE_CREATE_USER_CONFIRMATION = 'RECEIVE_CREATE_USER_CONFIRMATION';
export const RECEIVE_CREATE_USER_ERRORS = 'RECEIVE_CREATE_USER_ERRORS';
export const RECEIVE_CHECK_USER_CONFIRMATION = 'RECEIVE_CHECK_USER_CONFIRMATION';
export const RECEIVE_CHECK_USER_ERRORS = 'RECEIVE_CHECK_USER_ERRORS';

export const clearCreateUserErrors = () => ({
    type: CLEAR_CREATE_USER_ERRORS,
});

export const createUserFormValidatonErrors = errors => ({
    type: CREATE_USER_VALIDATION_ERRORS,
    errors,
});

export const receiveCheckUserConfirmation = payload => ({
    type: RECEIVE_CHECK_USER_CONFIRMATION,
    message: payload?.message,
    exists: payload?.exists,
});

export const recieveCheckUserErrors = errors => ({
    type: RECEIVE_CHECK_USER_ERRORS,
    errors,
});

export const receiveCreateUserConfirmation = payload => ({
    type: RECEIVE_CREATE_USER_CONFIRMATION,
    message: payload?.message,
});

export const recieveCreateUserErrors = errors => ({
    type: RECEIVE_CREATE_USER_ERRORS,
    errors,
});

export const checkExistingUser = user => dispatch => (
    checkExistingUserApi(user).then(
        payload => dispatch(receiveCheckUserConfirmation(payload)),
        errors => dispatch(recieveCheckUserErrors(ErrorResponseUtil(errors))),
    )
);

export const createUser = user => dispatch => (
    createUserApi(user).then(
        payload => dispatch(receiveCreateUserConfirmation(payload)),
        errors => dispatch(recieveCreateUserErrors(ErrorResponseUtil(errors))),
    )
);
