import React, { Component } from 'react';
import sanitizeHtml from 'sanitize-html';

import './Flashcard.css';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';

class FlashCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'presentation',
            front: 'spanish',
            cardIndex: 0,
            cards: Array.from(this.props.section.flashcards),
            total: this.props.section.flashcards.length,
            review: 0,
            got: 0,
            autoPlay: null,
            autoPlayIntervalId: null,
            Completed: null,
        };
    }

    componentDidUpdate(_null, prevState) {
        const that = this;

        if (this.props.section.id != this.state.cards[0]?.section_id) {
            this.setState({
                mode: 'presentation',
                front: 'spanish',
                cardIndex: 0,
                cards: Array.from(this.props.section.flashcards),
                total: this.props.section.flashcards.length,
                review: 0,
                got: 0,
                autoPlay: null,
                autoPlayIntervalId: null,
                Completed: null,
            });
        }

        if (this.state.autoPlayIntervalId && this.state.cardIndex === this.state.total - 1) {
            clearInterval(this.state.autoPlayIntervalId);
            that.handleAutoPlayRender();
            setTimeout(() => {
                let newState = Object.assign({}, this.state);
                newState.autoPlayIntervalId = null;
                newState.autoPlay = null;
                newState.cardIndex = 0;
                this.setState(newState);
            }, 6500);
        }
        if (!prevState.autoPlay && that.state.autoPlay) {
            that.handleAutoPlayRender();
            setTimeout(() => {
                let autoPlayIntervalId = setInterval(() => {
                    that.handleAutoPlayRender();
                }, 7000);
                let newState = Object.assign({}, that.state);
                newState.autoPlayIntervalId = autoPlayIntervalId;
                that.setState(newState);
            }, 0);
        }
    }

    handleSwitchButtonText = () => {
        if (this.state.mode === 'presentation') {
            return "Switch to study mode";
        }
        if (this.state.mode === 'study') {
            return "Switch to presentation mode";
        }
    }

    handleSwitchButtonClick = () => {
        gaEvent('flashcard_mode_change', { section_id: this.props.section.id });

        let that = this;
        return (e) => {
            clearInterval(this.state.autoPlayIntervalId);
            e.preventDefault();
            let newState = Object.assign({}, that.state);
            that.handleDefaultSide();
            newState.front = 'spanish';
            newState.cards = Array.from(that.props.section.flashcards);
            newState.total = that.props.section.flashcards.length;
            newState.cardIndex = 0;
            newState.autoPlay = null;
            newState.autoPlayIntervalId = null;
            newState.completed = null;
            newState.review = 0;
            newState.got = 0;
            if (that.state.mode === 'presentation') {
                newState.mode = 'study';
            } else {
                newState.mode = 'presentation';
            }
            that.setState(newState);
        };
    }

    handleRefresh = () => {
        gaEvent('flashcard_reset', { section_id: this.props.section.id });

        let that = this;
        return (e) => {
            clearInterval(this.state.autoPlayIntervalId);
            e.preventDefault();
            let newState = Object.assign({}, that.state);
            that.handleDefaultSide();
            newState.front = 'spanish';
            newState.cards = Array.from(that.props.section.flashcards);
            newState.total = that.props.section.flashcards.length;
            newState.review = 0;
            newState.got = 0;
            newState.cardIndex = 0;
            newState.autoPlay = null;
            newState.autoPlayIntervalId = null;
            newState.completed = null;
            that.setState(newState);
        };
    }

    handleSwitchButtonRender = () => {
        if (!this.props.section.study_mode)
            return '';

        if (!this.state.autoPlay) {
            return (
                <div className="FlashCard-Switch">
                    {
                        (this.state.mode === 'study')
                            && (
                                <ButtonBox
                                    className="ButtonBox-FlashCard Normal-Btn"
                                    text="Reset"
                                    onClick={this.handleRefresh()}
                                />
                            )
                    }
                    <ButtonBox
                        className="ButtonBox-FlashCard Normal-Btn"
                        text={this.handleSwitchButtonText()}
                        onClick={this.handleSwitchButtonClick()}
                    />
                </div>
            );
        }

        return (
            <div className="FlashCard-Switch">
                {
                    (this.state.mode === 'study')
                    && (
                        <ButtonBox
                            className="ButtonBox-FlashCard Normal-Btn"
                            text="Reset"
                            onClick={this.handleRefresh()}
                        />
                    )
                }

                <ButtonBox
                    className="ButtonBox-FlashCard Normal-Btn"
                    text={this.handleSwitchButtonText()}
                    onClick={this.handleSwitchButtonClick()}
                    disabled
                />
            </div>
        );
    }

    handleCardFlip = () => e =>{
        e.preventDefault();
        gaEvent('flashcard_flip_card', { section_id: this.props.section.id });
        let card = document.querySelectorAll(".flip-card .flip-card-inner")[0];
        card.setAttribute("style", "transform: rotateY(180deg)");
    }

    handleCardFlipBack = () => e => {
        e.preventDefault();
        gaEvent('flashcard_flip_card_back', { section_id: this.props.section.id });
        let card = document.querySelectorAll(".flip-card .flip-card-inner")[0];
        card.setAttribute("style", "transform: none");
    }

    handleDefaultSide = () => {
        let card = document.querySelectorAll(".flip-card .flip-card-inner")[0];
        card.setAttribute("style", "transform: none");
        card.setAttribute("style", "transition: 0.1s");
    }

    handleBack = () => {
        gaEvent('flashcard_previous_card', { section_id: this.props.section.id });
        return (e) => {
            this.handleDefaultSide()
            e.preventDefault();
            if (this.state.cardIndex !== 0) {
                let newState = Object.assign({}, this.state);
                newState.cardIndex -= 1;
                this.setState(newState);
            }
        };
    }

    handleForward = () => {
        gaEvent('flashcard_next_card', { section_id: this.props.section.id });
        return (e) => {
            this.handleDefaultSide();
            e.preventDefault();
            let newState = Object.assign({}, this.state);
            if (this.state.cardIndex !== this.state.total - 1) {
                newState.cardIndex += 1;
            } else {
                newState.cardIndex = 0;
            }
            this.setState(newState);
        }
    }

    handleAutoPlayStart = () => {
        return (e) => {
            let newState = Object.assign({}, this.state);
            if (newState.cardIndex === newState.total - 1) {
                newState.cardIndex = 0;
            }
            newState.autoPlay = 'on';
            this.setState(newState);
        };
    }

    handleAutoPlayPause = () => {
        return (e) => {
            clearInterval(this.state.autoPlayIntervalId);
            let newState = Object.assign({}, this.state);
            newState.autoPlayIntervalId = null;
            newState.autoPlay = null;
            this.setState(newState);
        };
    }

    delay = (t, v) => {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t)
        });
    }

    handleAutoPlayRender = () => {
        let that = this;
        let audioButton = document.querySelectorAll(".ButtonBox-AudioPlayButton")[0];
        let flipButton = document.querySelectorAll(".ButtonBox-FlashCard-icon")[0];
        let nextButton = document.querySelectorAll(".forward")[0];
        audioButton.click();
        that.delay(3000).then(() => {
            flipButton.click();
        }).then(() => {
            that.delay(3000).then(() => {
                nextButton.click();
            });
        });
    }

    handleAutoRenderButtonRender = () => {
        if (this.state.autoPlay) {
            return (
                <ButtonBox
                    className="ButtonBox-FlashCard Normal-Btn"
                    text={<i className="fas fa-pause" />}
                    onClick={this.handleAutoPlayPause()}
                />
            );
        }
        return (
            <ButtonBox
                className="ButtonBox-FlashCard Normal-Btn"
                text={<i className="fas fa-play" />}
                onClick={this.handleAutoPlayStart()}
            />
        );
    }

    handleCardShow = () => {
        let cardContent = this.state.cards[this.state.cardIndex];
        let card;
        if (this.state.front === 'spanish' && !this.state.completed) {
            card = (
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="flip-card-front-buttons">
                                {
                                    cardContent.audio_url
                                        ? <AudioPlayButton src={cardContent.audio_url} />
                                        : <div />
                                }
                                <ButtonBox
                                    className="ButtonBox-FlashCard-icon"
                                    text={<ImageBox src="rotate.svg" />}
                                    style={(this.state.autoPlay) ? { visibility: "hidden" } : { visibility: "visible" }}
                                    onClick={this.handleCardFlip()}
                                />
                            </div>
                            <div className="flip-card-front-text">
                                <TextBox
                                    text={cardContent.text}
                                    tag="h1"
                                />
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-front-buttons">
                                <div />
                                <ButtonBox
                                    className="ButtonBox-FlashCard-icon"
                                    text={<ImageBox src="rotate.svg" />}
                                    style={(this.state.autoPlay) ? { visibility: "hidden" } : { visibility: "visible" }}
                                    onClick={this.handleCardFlipBack()}
                                />
                            </div>
                            <div className="flip-card-front-text">
                                <TextBox
                                    text={cardContent.back_text}
                                    tag="h1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.front !== 'spanish' && !this.state.completed) {
            card = (
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="flip-card-front-buttons">
                                <div />
                                <ButtonBox
                                    className="ButtonBox-FlashCard-icon"
                                    text={<ImageBox src="rotate.svg" />}
                                    style={(this.state.autoPlay) ? { visibility: "hidden" } : { visibility: "visible" }}
                                    onClick={this.handleCardFlip()}
                                />
                            </div>
                            <div className="flip-card-front-text">
                                <TextBox
                                    text={cardContent.back_text}
                                    tag="h1"
                                />
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-front-buttons">
                                {
                                    cardContent.audio_url
                                        ? <AudioPlayButton src={cardContent.audio_url} />
                                        : <div />
                                }
                                <ButtonBox
                                    className="ButtonBox-FlashCard-icon"
                                    text={<ImageBox src="rotate.svg" />}
                                    style={(this.state.autoPlay) ? { visibility: "hidden" } : { visibility: "visible" }}
                                    onClick={this.handleCardFlipBack()}
                                />
                            </div>
                            <div className="flip-card-front-text">
                                <TextBox
                                    text={cardContent.text}
                                    tag="h1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.completed) {
            card = (
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="flip-card-front-text">
                                {
                                    this.state.got === this.state.total
                                    ? (
                                        <TextBox
                                            text="Congratulations!!! You have mastered all the words!"
                                            tag="h4"
                                        />
                                    )
                                    : (
                                        <div>
                                            <TextBox
                                                text={`Needs Review ${this.state.review}. Mastered words ${this.state.got}.`}
                                                tag="h4"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <ButtonBox
                                className="FlashCard-NavBar-Right-Flip"
                                text="Finish!"
                                onClick={this.handleSwitchButtonClick()}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return card;
    }

    handleKeepPracticing = () => {
        gaEvent('flashcard_practice_card', { section_id: this.props.section.id });

        return (e) => {
            e.preventDefault();
            this.handleDefaultSide();
            let newState = Object.assign({}, this.state);

            if (this.state.cardIndex !== (this.state.total - 1)) {
                newState.cardIndex += 1;
                newState.review += 1;
            } else {
                newState.review += 1;
                newState.completed = 'done';
            }

            this.setState(newState);
        };
    }

    handleIKnowThis = () => {
        gaEvent('flashcard_know_card', { section_id: this.props.section.id });

        return (e) => {
            e.preventDefault();
            this.handleDefaultSide();
            let newState = Object.assign({}, this.state);

            if (this.state.cardIndex !== (this.state.total - 1)) {
                newState.cardIndex += 1;
                newState.got += 1;
            } else {
                newState.got += 1;
                newState.completed = 'done';
            }

            this.setState(newState);
        };
    }

    handleNavButtonsRender = () => {
        if (this.state.mode === 'presentation' && !this.state.completed) {
            return (
                <div className="FlashCard-NavBar">
                    <div className="FlashCard-NavBar-Left">
                        <ButtonBox
                            className="ButtonBox-FlashCard Normal-Btn backward"
                            text={<i className="fa fa-play"></i>}
                            onClick={this.handleBack()}
                        />
                    </div>
                    <div className="FlashCard-NavBar-Middle">
                        <TextBox text={`Card ${this.state.cardIndex + 1} of ${this.state.total}`} />
                    </div>
                    <div className="FlashCard-NavBar-Right">
                        <ButtonBox
                            className="ButtonBox-FlashCard Normal-Btn forward"
                            text={<i className="fa fa-play"></i>}
                            onClick={this.handleForward()}
                        />
                    </div>
                </div>
            );
        }
        if (this.state.mode === 'study' && !this.state.completed) {
            return (
                <div className="FlashCard-NavBar">
                    <div className="FlashCard-NavBar-Left">
                        <ButtonBox
                            className="ButtonBox-FlashCard Review-Btn"
                            text="Keep reviewing"
                            onClick={this.handleKeepPracticing()}
                        />
                        <div className="Review-Count">
                            <div className="Count">{ this.state.review }</div>
                        </div>
                    </div>
                    <div className="FlashCard-NavBar-Middle">
                        <TextBox text={`Card ${this.state.cardIndex + 1} of ${this.state.total}`} />
                    </div>
                    <div className="FlashCard-NavBar-Right">
                        <ButtonBox
                            className="ButtonBox-FlashCard Got-Btn"
                            text="I got this"
                            onClick={this.handleIKnowThis()}
                        />
                        <div className="Got-Count">
                            <div className="Count">{ this.state.got }</div>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.completed) {
            return (
                <div className="FlashCard-NavBar">
                    <div className="FlashCard-NavBar-Right">
                        <ButtonBox
                            className="ButtonBox-FlashCard Normal-Btn"
                            text={this.state.got === this.state.total ? 'Finish!' : 'Keep Reviewing'}
                            onClick={this.handleSwitchButtonClick()}
                        />
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="FlashCard">
                {this.handleSwitchButtonRender()}
                <div className="FlashCard-Cards">
                    {this.handleCardShow()}
                </div>
                {this.handleNavButtonsRender()}
            </div>
        );
    }
}

export default FlashCard;
