import { connect } from 'react-redux';

import SectionableClone from './SectionableClone';

import { fetchCoursesTree } from '../../../../Actions/CourseActions';
import { cloneActivity } from '../../../../Actions/ActivityActions';
import { cloneTopic } from '../../../../Actions/TopicActions';
import { cloneQuiz } from '../../../../Actions/QuizActions';

const mapStateToProps = (state) => ({
  courses: state.entities.coursesTree,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoursesTree: () => dispatch(fetchCoursesTree()),
  cloneActivity: (targetLessonId, activityId) => dispatch(cloneActivity(targetLessonId, activityId)),
  cloneTopic: (targetLessonId, topicId) => dispatch(cloneTopic(targetLessonId, topicId)),
  cloneQuiz: (targetLessonId, quizId) => dispatch(cloneQuiz(targetLessonId, quizId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionableClone);
