import React from 'react';
import PropTypes from 'prop-types';

import ImageBox from '../../Basic/ImageBox/ImageBox';
import CommentReaction from './CommentReaction';

import { isTEAdmin } from '../../../Util/Helper/UserWorkplaceUtil';

const CommentDetail = ({
  commentableType, commentableId,
  parentCommentData, commentData,
  showReactions, showReplies
}) => {
  const userImage = () => (
    isTEAdmin(commentData.user)
      ? 'favicon.ico'
      : commentData.user.image ? commentData.user.image : 'default_user.png'
  );

  const userName = () => (
    isTEAdmin(commentData.user)
      ? 'Canopy Admin'
      : `${commentData.user.first_name} ${commentData.user.last_name}`
  );

  const commentTime = (timestamp) => {
    let time = new Date(timestamp);

    return `${time.toLocaleString('default', { month: 'short' })} ${time.getDate()}, ${time.getFullYear()}`
  }

  const handleShowMoreLess = (e) => {
    let textElement = e.currentTarget.parentElement.previousElementSibling;

    if (textElement.classList.contains('expand')) {
      textElement.classList.remove('expand');
      textElement.style = 'max-height: 60px;';
      e.currentTarget.innerText = 'Full Comment';
    } else {
      textElement.classList.add('expand');
      textElement.style = 'max-height: ' + textElement.scrollHeight  + 'px;';
      e.currentTarget.innerText = 'Collapse';
    }
  }

  return (
    <div className="Comment-Container">
      <div className="Image">
        <ImageBox src={userImage()} />
      </div>

      <div className="Comment">
        <div className="Info">
          <div className="User">
            {userName()}
          </div>

          <div className="Time">
            {commentTime(commentData.comment.created_at)}
          </div>
        </div>

        <div className="Text">
          {commentData.comment.text}
        </div>
        {
          commentData.comment.text.length >= 170 &&
          <div className="Show-More-Less-Btn">
            <button
              className="More-Less"
              onClick={(e) => handleShowMoreLess(e)}
            >
              Full Comment
            </button>
          </div>
        }

        {
          showReactions &&
          <div className="Reaction">
            <CommentReaction
              commentableType={commentableType}
              commentableId={commentableId}
              comment={commentData.comment}
              likes={commentData.likes}
            />
          </div>
        }

        {
          showReplies &&
          ((commentData.replies || []).length !== 0) &&
          <div className="Replies">
            {
              commentData.replies.map((replyData, idx) => (
                <CommentDetail
                  key={`reply-${idx}`}
                  commentableType={commentableType}
                  commentableId={commentableId}
                  parentCommentData={commentData}
                  commentData={replyData}
                />
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}

CommentDetail.propTypes = {
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  parentCommentData: PropTypes.object,
  commentData: PropTypes.object.isRequired,
  showReactions: PropTypes.bool,
  showReplies: PropTypes.bool
}

export default CommentDetail;
