import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputBox from '../../Basic/InputBox/InputBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

const CreateEditSorting = ({
    section,
    question,
    submitQuestion,
    closeModal,
}) => {
    const [text, setText] = useState(question ? question.text : '');
    const [answersAttributes, setAnswersAttributes] = useState(question ? question.answers : []);

    const handleBlur = e => {
        e.currentTarget.id = '';
    };

    const handleFocus = e => {
        e.currentTarget.id = 'active';
    };

    const handleInputChange = e => setText(e.currentTarget.value);

    const handleAddAnswer = e => {
        e.preventDefault();
        setAnswersAttributes([...answersAttributes, { text: '', index: answersAttributes.length }]);
    };

    const handleAnswerInput = idx => e => {
        const newAnswersAttributes = [...answersAttributes];
        newAnswersAttributes[idx].text = e.currentTarget.value;
        setAnswersAttributes(newAnswersAttributes);
    };

    const handleAnswersRender = () => {
        return answersAttributes.map(
            (_, idx) => (
                <div
                    className="CreationForms-InputBox"
                    key={idx}
                >
                    <InputBox
                        className="InputBox-LogInBox"
                        value={answersAttributes[idx].text}
                        placeholder={`Word ${idx + 1}`}
                        onChange={handleAnswerInput(idx)}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </div>
            ),
        );
    };

    const handleSubmit = e => {
        e.preventDefault();
        const questionData = {
            text,
            answers_attributes: answersAttributes,
        };

        if (section) {
            questionData.section_id = section.id;
            questionData.number_of_answers = answersAttributes.length;
            questionData.question_type = section.section_type;
        }

        if (question) {
            questionData.id = question.id;
        }

        submitQuestion(questionData).then(
            closeModal(),
        );
    };

    return (
        <form
            className="CreationForms-Form"
            onSubmit={handleSubmit}
        >
            <div className="CreationForms-InputBox">
                <InputBox
                    className="InputBox-LogInBox"
                    value={text}
                    placeholder="Text Here"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />
            </div>

            <div className="CreationForms-AnswersBox">
                {
                    handleAnswersRender()
                }

                <div className="CreationForms-Button">
                    <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Insert A Word"
                        onClick={handleAddAnswer}
                    />
                </div>
            </div>

            <div className="CreationForms-Button">
                <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Submit"
                />
            </div>
        </form>
    );
};

CreateEditSorting.propTypes = {
    section: PropTypes.shape({
        id: PropTypes.number,
        section_type: PropTypes.string,
    }),
    question: PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        answers: PropTypes.array,
    }),
    submitQuestion: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default CreateEditSorting;
