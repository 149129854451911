import React from 'react';
import ProgressBar from '../../Basic/ProgressBar/ProgressBar';

import './Loader.scss';

const ProgressLoader = ({ message, completed = 0, total = 0 }) => {
  const percentage = () => {
    if (total === 0) {
      return 0;
    }

    return (completed / total * 100).toFixed(2);
  }

  return (
    <div className="Progress-Loader">
      <div className="Loader">
        <div className="Message">{message}</div>
        <ProgressBar percent={percentage()} />
      </div>
    </div>
  )
}

export default ProgressLoader;
