import React from 'react';

const Activity = ({activity}) => (
    <table style={{ tableLayout: 'fixed', width: '97%' }}>
        <thead>
            <tr>
                <td
                    style={{
                        padding: 20,
                        width: 100,
                        backgroundColor: '#7DE3BB',
                        textAlign: 'center',
                        color: '#ffffff',
                    }}
                >
                    Last Login
                </td>
                <td style={{
                    padding: 20,
                    width: 100,
                    backgroundColor: '#7DE3BB',
                    textAlign: 'center',
                    color: '#ffffff',
                }}
                >
                    Seat Time
                </td>
            </tr>
        </thead>

        <tbody>
            {
                activity.map(entry => (
                    <tr>
                        <td style={{
                            padding: 20,
                            width: 100,
                            textAlign: 'center',
                            backgroundColor: 'white',
                        }}
                        >
                            {entry.session_date}
                        </td>
                        <td style={{
                            padding: 20,
                            width: 100,
                            textAlign: 'center',
                            backgroundColor: 'white',
                        }}
                        >
                            {entry.session_time}
                        </td>
                    </tr>
                ))
            }
        </tbody>

    </table>
);

export default Activity;
