import * as APIUtil from '../Util/DashboardApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const FETCH_ACTIVITY_ERROR = 'FETCH_ACTIVITY_ERROR';
export const FETCH_ACTIVITY_REQUEST = 'FETCH_ACTIVITY_REQUEST';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ALL_LESSONS_ERROR = 'FETCH_ALL_LESSONS_ERROR';
export const FETCH_ALL_LESSONS_REQUEST = 'FETCH_ALL_LESSONS_REQUEST';
export const FETCH_ALL_LESSONS_SUCCESS = 'FETCH_ALL_LESSONS_SUCCESS';
export const FETCH_COURSES_ERROR = 'FETCH_COURSES_ERROR';
export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSE_PROGRESSES_ERROR = 'FETCH_COURSE_PROGRESSES_ERROR';
export const FETCH_COURSE_PROGRESSES_REQUEST = 'FETCH_COURSE_PROGRESSES_REQUEST';
export const FETCH_COURSE_PROGRESSES_SUCCESS = 'FETCH_COURSE_PROGRESSES_SUCCESS';
export const FETCH_TOPICS_COUNTS_ERROR = 'FETCH_TOPICS_COUNTS_ERROR';
export const FETCH_TOPICS_COUNTS_REQUEST = 'FETCH_TOPICS_COUNTS_REQUEST';
export const FETCH_TOPICS_COUNTS_SUCCESS = 'FETCH_TOPICS_COUNTS_SUCCESS';

export const fetchActivityError = errors => ({
    type: FETCH_ACTIVITY_ERROR,
    errors,
});

export const fetchActivityRequest = () => ({
    type: FETCH_ACTIVITY_REQUEST,
});

export const fetchActivitySuccess = payload => ({
    type: FETCH_ACTIVITY_SUCCESS,
    payload,
});

export const fetchAllLessonsError = errors => ({
    type: FETCH_ALL_LESSONS_ERROR,
    errors,
});

export const fetchAllLessonsRequest = () => ({
    type: FETCH_ALL_LESSONS_REQUEST,
});

export const fetchAllLessonsSuccess = payload => ({
    type: FETCH_ALL_LESSONS_SUCCESS,
    payload,
});

export const fetchCoursesError = errors => ({
    type: FETCH_COURSES_ERROR,
    errors,
});

export const fetchCoursesRequest = () => ({
    type: FETCH_COURSES_REQUEST,
});

export const fetchCoursesSuccess = payload => ({
    type: FETCH_COURSES_SUCCESS,
    payload,
});

export const fetchCourseProgressesError = errors => ({
    type: FETCH_COURSE_PROGRESSES_ERROR,
    errors,
});

export const fetchCourseProgressesRequest = () => ({
    type: FETCH_COURSE_PROGRESSES_REQUEST,
});

export const fetchCourseProgressesSuccess = payload => ({
    type: FETCH_COURSE_PROGRESSES_SUCCESS,
    payload,
});

export const fetchTopicsCountError = errors => ({
    type: FETCH_TOPICS_COUNTS_ERROR,
    errors,
});

export const fetchTopicsCountRequest = () => ({
    type: FETCH_TOPICS_COUNTS_REQUEST,
});

export const fetchTopicsCountSuccess = payload => ({
    type: FETCH_TOPICS_COUNTS_SUCCESS,
    payload,
});

export const fetchAllLessons = () => async dispatch => {
    dispatch(fetchAllLessonsRequest());
    try {
        const response = await APIUtil.fetchAllLessons();
        dispatch(fetchAllLessonsSuccess(response));
    } catch (errors) {
        dispatch(fetchAllLessonsError(ErrorResponseUtil(errors)));
    }
};

export const fetchCourses = () => async dispatch => {
    dispatch(fetchCoursesRequest());
    try {
        const response = await APIUtil.fetchCourses();
        dispatch(fetchCoursesSuccess(response));
    } catch (errors) {
        dispatch(fetchCoursesError(ErrorResponseUtil(errors)));
    }
};

export const fetchCourseProgresses = () => async dispatch => {
    dispatch(fetchCourseProgressesRequest());
    try {
        const response = await APIUtil.fetchCourseProgresses();
        dispatch(fetchCourseProgressesSuccess(response));
    } catch (errors) {
        dispatch(fetchCourseProgressesError(ErrorResponseUtil(errors)));
    }
};

export const fetchTopicsCount = () => async dispatch => {
    dispatch(fetchTopicsCountRequest());
    try {
        const response = await APIUtil.fetchTopicsCount();
        dispatch(fetchTopicsCountSuccess(response));
    } catch (errors) {
        dispatch(fetchTopicsCountError(ErrorResponseUtil(errors)));
    }
};

export const fetchActivity = id => async dispatch => {
    dispatch(fetchActivityRequest());
    try {
        const response = await APIUtil.fetchActivity(id);
        dispatch(fetchActivitySuccess(response));
    } catch (errors) {
        dispatch(fetchActivityError(ErrorResponseUtil(errors)));
    }
};
