import { merge } from 'lodash';
import {
    RECEIVE_LESSONS,
    CLEAR_LESSONS,
    RECEIVE_LESSON,
    RECEIVE_LESSON_DELETE_SUCCESS,
    RECEIVE_LESSON_POSITIONS,
} from '../../Actions/LessonActions';
import {
    RECEIVE_TOPIC,
    RECEIVE_TOPIC_DELETE_SUCCESS,
    RECEIVE_TOPIC_POSITIONS,
} from '../../Actions/TopicActions';
import { RECEIVE_QUIZ } from '../../Actions/QuizActions';
import { RECEIVE_ACTIVITY } from '../../Actions/ActivityActions';

const LessonReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_LESSONS:
            return Object.assign({}, action.payload.lessons);
        case RECEIVE_LESSON:
            let newState = Object.assign({}, state, action.payload.lessons);
            newState = merge(newState, action.payload.lessons);
            return newState;
        case RECEIVE_LESSON_DELETE_SUCCESS:
            return Object.assign({}, action.payload.siblings);
        case RECEIVE_LESSON_POSITIONS:
            return Object.assign({}, action.payload.lessons, action.payload.siblings);
        case CLEAR_LESSONS:
            return {};
        case RECEIVE_TOPIC:
            return Object.assign({}, state, action.payload.lessons);
        case RECEIVE_TOPIC_DELETE_SUCCESS:
            return Object.assign({}, action.payload.lessons);
        case RECEIVE_TOPIC_POSITIONS:
            return Object.assign({}, action.payload.lessons);
        case RECEIVE_QUIZ:
            let current = state[action.payload.lesson_id]
            return Object.assign({}, state, { [current.id]: { ...current, quiz_id: action.payload.id } });
        case RECEIVE_ACTIVITY:
            let currentLesson = state[action.payload.lesson_id]
            return Object.assign({}, state, { [currentLesson.id]: { ...currentLesson, activity_id: action.payload.id } });
        default:
            return state;
    }
};

export default LessonReducer;
