import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchOnlyTopics } from '../../../Actions/TopicActions';
import NavigationTopic from './NavigationTopic';
import NavigationQuiz from './NavigationQuiz';
import TextBox from '../../Basic/TextBox/TextBox';
import { MoonLoader } from 'react-spinners';
import { COLORS } from '../../../Style/Style';

const mapStateToProps = (state) => {
  return {
    topics: state.entities.topics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOnlyTopics: lessonId => dispatch(fetchOnlyTopics(lessonId)),
  };
};

const NavigationTopics = ({ 
  course,
  courseProgress,
  lesson,
  topics,
  setNavigationOpen,
  fetchOnlyTopics,
  pathname,
 }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOnlyTopics(lesson.id).then(
      () => setLoading(false)
    )
  }, [fetchOnlyTopics, lesson.id])

  const handleTopicsRender = () => (
    lesson.topics.map((topicId) => (
      topics[topicId] &&
      <NavigationTopic
        key={topicId}
        course={course}
        courseProgress={courseProgress}
        lesson={lesson}
        topic={topics[topicId]}
        setNavigationOpen={setNavigationOpen}
        pathname={pathname}
      />
    ))
  );

  if (loading) {
    return <div className="NavigationTopics-Loader">
      <MoonLoader color={COLORS.white} />
    </div>
  }

  return (
    <div className="NavigationTopics">
      { handleTopicsRender() }
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTopics);
