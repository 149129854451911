import axios from './AxiosUtil';

export const createTableEntry = (formData) => {
  return axios({
    method: "POST",
    url: `/api/table_entries`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  );
};

export const editTableEntry = (formData) => {
  return axios({
    method: "PATCH",
    url: `/api/table_entries/${formData.get("table_entry[id]")}`,
    processData: false,
    contentType: false,
    dataType: 'json',
    data: formData,
  }).then(
    res => res.data
  );
};