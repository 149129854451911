import React from 'react';
import PropTypes from 'prop-types';

import './ButtonBox.css'
import ImageBox from '../ImageBox/ImageBox';
import TextBox from '../TextBox/TextBox';

/**
* @function - ButtonBox
* @{ props } - { Object }
 * className
 * onClick - setState from the parent
 * src - image src if provided
 * text - button text if provided
* @return { JSXObject }
*/
const ButtonBox = ({ className, imageClassName, onClick, src, text, id, disabled, tag, icon }) => {

    // propTypes for the component
    ButtonBox.propTypes = {
        className: PropTypes.string,
        imageClassName: PropTypes.string,
        onClick: PropTypes.func,
        src: PropTypes.string,
        text: PropTypes.oneOfType([
            PropTypes.string, PropTypes.object
        ]),
        id: PropTypes.string,
        disabled: PropTypes.bool,
        tag: PropTypes.string,
        icon: PropTypes.string,
    };

    ButtonBox.defaultProps = {
        className: 'ButtonBox',
        imageClassName: 'ImageBox',
    };

    return (
        <button
            className={className}
            onClick={onClick}
            disabled={disabled}
            data-id={id}
        >
            { src && <ImageBox className={ imageClassName } src={ src } /> }

            { text && <TextBox text={text} tag={ tag ? tag : "p" }/> }

            { icon && <i className={`fa ${icon}`} />}

        </button>
    );
};

export default ButtonBox;
