// MicroSoft Ad
export const microsoftAdScript = () => {
  let existingScriptTag = document.querySelector("[data-identifier='microsoft-ad-uet']");
  if (existingScriptTag)
    existingScriptTag.remove();

  let scriptTag = document.createElement('script');
  scriptTag.setAttribute('data-identifier', 'microsoft-ad-uet');

  let scriptTagContent = document.createRange().createContextualFragment(`
    (function(w,d,t,r,u)
      {
        var f,n,i;
        w[u]=w[u]||[],f=function()
        {
            var o={ti:" 136026220"};
            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
        },
        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
        {
            var s=this.readyState;
            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
        },
        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
      })
    (window,document,"script","//bat.bing.com/bat.js","uetq");
  `);

  scriptTag.append(scriptTagContent);

  document.getElementsByTagName('head')[0].append(scriptTag);
}
