import { merge } from 'lodash';
import {
    RECEIVE_REPORTS,
    RECEIVE_REPORT,
    CLEAR_REPORTS,
} from '../../Actions/ReportActions';

export default (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_REPORTS:
            return { ...action.payload };
        case RECEIVE_REPORT: {
            let newState = { ...state };
            newState = merge(newState, { [action.payload.id]: action.payload });
            return newState;
        }
        case CLEAR_REPORTS: {
            return {};
        }
        default:
            return state;
    }
};