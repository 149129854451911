import { connect } from 'react-redux';
import LearnBreadcrumbs from './LearnBreadcrumbs';

const mapStateToProps = (state) => ({
    currentCourseId: state.navigation.currentCourseId,
    courses: state.entities.courses,
    lessons: state.entities.lessons,
    activities: state.entities.activities,
    topics: state.entities.topics,
    quizzes: state.entities.quizzes,
    navigationSectionIndex: state.navigation.currentSectionIndex,
    navigationQuestionIndex: state.navigation.currentQuestionIndex
});

export default connect(mapStateToProps, null)(LearnBreadcrumbs);
