import React
  from 'react';

import './MultiButtonChoiceBoxMini.css';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton
  from '../AudioPlayButton/AudioPlayButton';
import FunctionBox
  from '../../Composed/FunctionBox/FunctionBox';
import UploadMedia
  from '../../Forms/UploadMedia/UploadMedia';
import Modal
  from '../../Composed/Modal/Modal';
import Confirm
  from '../../Composed/Confirm/Confirm';
import { COLORS }
  from '../../../Style/Style';
import RevealHideBox
  from '../../Composed/RevealHideBox/RevealHideBox';

/**
* @class - MultiButtonChoiceBoxMini - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const MultiButtonChoiceBoxMini = (props) => {
  const { course, question, editQuestion, deleteQuestion, createAnswer, editAnswer, 
          uploadAudio, creation, quizProgress, section
        } = props;

  const handleChoiceSelect = (e) => {
    if (creation) {
      if (question.answers[0]) {
        editAnswer({
          id: question.answers[0].id,
          question_id: question.id,
          text: question.options[e.currentTarget.dataset.id].text
        })
      } else {
        createAnswer({
          question_id: question.id,
          text: question.options[e.currentTarget.dataset.id].text
        })
      }
    } 
  }

  const buttons = question.options.map((option, idx) => {
    return (
      <div
        className="MultiButtonChoiceBoxMini-ButtonBox-div"
        key={option.id}>
        <div className="Manage-Audio-Btns">
          <Modal
            title="Upload An Audio"
            small
            form={
              close =>
                <UploadMedia
                  submitMedia={uploadAudio}
                  media_type="audio"
                  entity={question}
                  model="question"
                  subEntity={option}
                  subModel="options_attributes"
                  attachment="audio"
                  closeModal={close} />
            }>
            {
              open =>
                <ButtonBox
                  className="ButtonBox-EditQuestions mr-10 mb-5"
                  text={<i className="fas fa-headphones"></i>}
                  onClick={open} />
            }
          </Modal>
          {
            option.audio_url &&
            <Confirm
              text="Are you sure you want to delete audio for this option?"
              confirm={(e) => editQuestion({ id: question.id, options_attributes: [{ id: option.id, purge_audio: true }] })}
            >
              {
                open =>
                <ButtonBox
                  className="ButtonBox-EditQuestions mb-5"
                  text={<i className="fa fa-volume-off"></i>}
                  onClick={open}
                />
              }
            </Confirm>
          }
          {option.audio_url && <AudioPlayButton src={option.audio_url} downloadable={creation} />}
        </div>
        <ButtonBox
          className={
            creation ?
            (
              question.answers[0] && question.answers[0].text === option.text ?
              "ButtonBox-MultiButtonChoiceBoxMini-correct"
              :
              "ButtonBox-MultiButtonChoiceBoxMini"
            ) :
            (
              quizProgress && quizProgress[section.id] && 
              quizProgress[section.id][question.id] && 
              quizProgress[section.id][question.id][0] &&
              quizProgress[section.id][question.id][0].id === option.id ?
              (
                quizProgress[section.id][question.id][0].is_correct ?
                "ButtonBox-MultiButtonChoiceBoxMini-correct"
                :
                "ButtonBox-MultiButtonChoiceBoxMini-wrong"
              )
              :
              "ButtonBox-MultiButtonChoiceBoxMini"
            )
          }
          text={ option.text }
          id={idx} 
          onClick={handleChoiceSelect}/>
      </div>
    );
  });

  return (
    <div className='MultiButtonChoiceBoxMini'>
      <div
        className="QuestionMini-QuestionBox">
        <div
          className="QuestionMini-QuestionBox-Position"
          style={
            creation ?
            (
            question.answers[0] ?
              {
                backgroundColor: COLORS.green
              }
              :
              {
                backgroundColor: COLORS.redPink
              }
            ) :
            {
              backgroundColor: COLORS.lightBlue2
            }
          }>
          <TextBox
            text={question.position}
            style={{
              color: COLORS.Blue
            }} />
        </div>


        <div
          className="QuestionMini-QuestionBox-Question">
          <QuestionBox question={question} creation={props.creation}>
            <TextBox
              text={ question.text }
              style={{
                color: COLORS.Blue
              }} />
          </QuestionBox>
        </div>

        {
          props.creation &&
          <FunctionBox
            submitMedia={uploadAudio}
            model="question"
            entity={question}
            editEntity={editQuestion}
            deleteEntity={deleteQuestion}
            course={course}
          />
        }
      </div>

      {
        question.hint &&
        <RevealHideBox className="ml-6" buttonText="hint" text={question.hint} />
      }

      <div className='MultiButtonChoiceBoxMini-ButtonBox-container'>
        {buttons}
      </div>
    </div>
  );
};

export default MultiButtonChoiceBoxMini;
