import React from 'react';
import PropTypes from 'prop-types';

import './CourseBoxMini.css';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { COLORS } from '../../../Style/Style';

const CourseBoxMini = ({
    course,
    coursePackage,
    editCoursePackage,
    remove,
}) => {
    const handleClick = () => {
        if (remove) {
            editCoursePackage({ ...coursePackage, remove_id: course.id });
        } else {
            editCoursePackage({ ...coursePackage, add_id: course.id });
        }
    };

    return (
        <div className="CourseBoxMini">
            <TextBox
                text={course.name}
                style={{
                    color: COLORS.Blue,
                }}
            />

            {
                !coursePackage.for_sale
                    && (
                        <ButtonBox
                            className="ButtonBox-EditQuestions"
                            text={
                                remove
                                    ? <i className="fas fa-minus" />
                                    : <i className="fas fa-plus" />
                            }
                            onClick={handleClick}
                        />
                    )
            }
        </div>
    );
};

CourseBoxMini.propTypes = {
    course: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    coursePackage: PropTypes.shape({
        for_sale: PropTypes.bool,
    }).isRequired,
    editCoursePackage: PropTypes.func.isRequired,
    remove: PropTypes.bool,
};

CourseBoxMini.defaultProps = {
    remove: false,
};

export default CourseBoxMini;
