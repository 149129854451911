import React from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';

import './UserRosterUploadProgress.scss';

const UserRosterUploadProgress = ({ progressData, close }) => {
  const queuesToProcess = () => (
    progressData.event === 'error' ? ['users_new'] : ['user_exist', 'user_created', 'user_failed']
  );

  return (
    <div className="UserRosterUploadProgress-Container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            {
              progressData.finished &&
              <ButtonBox
                src="close.svg"
                className="Close-Button"
                onClick={() => close()}
              />
            }
          </div>

          <div className="modal-body">
            <div className={`Progress-Message ${progressData.event}`}>
              {progressData.message || 'Uploading Roster'}
            </div>
            <div className="Tables-Container">
              {
                queuesToProcess().map((queue, qIdx) => (
                  progressData[queue].emails.length > 0 &&
                  <div className="Table-Container">
                    <div className="Header">
                      {progressData[queue].name}
                    </div>
                    <div className="Table">
                      <table key={`table-${qIdx}`}>
                        <tbody>
                          {
                            progressData[queue].emails.map((email, eIdx) => (
                              <tr>
                                <td>{email}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UserRosterUploadProgress.propTypes = {
  progressData: PropTypes.shape({
    event: PropTypes.string.isRequired,
    finished: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    users_new: PropTypes.shape({}),
    user_exist: PropTypes.shape({}),
    user_created: PropTypes.shape({}),
    user_failed: PropTypes.shape({}),
  }),
  close: PropTypes.func.isRequired,
}

export default UserRosterUploadProgress;
