import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

let dndContext;

export const TimerContext = React.createContext();

export function withDragDropContext(Component) {
  if (!dndContext) {
    let dndBackend;

    try {
      document.createEvent('TouchEvent');
      dndBackend = TouchBackend({ enableMouseEvents: true });
    } catch (errors) {
      dndBackend = HTML5Backend;
    }

    dndContext = DragDropContext(dndBackend);
  }

  return dndContext(Component);
}
