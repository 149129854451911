import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TextBox from '../../Basic/TextBox/TextBox';
import { COLORS } from '../../../Style/Style';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Modal from '../../Composed/Modal/Modal';
import Loader from '../../Composed/Loader/Loader';
import CreateEditGroup from '../../Forms/CreateEditGroup/CreateEditGroup';
import { fetchGroups, createGroup } from '../../../Actions/GroupActions';

const mapStateToProps = state => ({
    currentUser: state.session.currentUser,
    groups: state.admin.groups,
});

const mapDispatchToProps = dispatch => ({
    fetchGroups: () => dispatch(fetchGroups()),
    createGroup: group => dispatch(createGroup(group)),
});

const ManageGroups = ({ currentUser, groups, fetchGroups, createGroup }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchGroups().then(() => setLoading(false));
    }, [fetchGroups]);

    const renderGroups = (status) => {
        const groupList = Object.values(groups).map(group =>
            group.status === status &&
            (
                <div
                    className="B2BAdminDashboard-GroupList__line"
                    key={group.id}
                >
                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text={group.name}
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text={group.users.length}
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text={group.created_at.split("T")[0]}
                    />

                    <Link to={`/account/groups/${group.id}`}>View Details</Link>
                </div>
            )
        );

        return (
            <div className="B2BAdminDashboard-GroupList">
                <div className="B2BAdminDashboard-GroupList__line">
                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Group Name"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="No. of Students"
                        tag="h5"
                    />

                    <TextBox
                        className="B2BAdminDashboard-GroupList__item"
                        text="Created At"
                        tag="h5"
                    />
                </div>
                {groupList}
            </div>
        );
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="B2BAdminDashboard">
            <div className="B2BAdminDashboard-Title">
                <TextBox
                    text="Manage Groups"
                    tag="h4"
                    style={{
                        color: COLORS.Blue,
                    }}
                />

                <Modal
                    title="Create A Group"
                    form={
                        close => (
                            <CreateEditGroup
                                closeModal={close}
                                createGroup={createGroup}
                                currentUser={currentUser}
                            />
                        )
                    }
                >
                    {
                        open => (
                            <ButtonBox
                                text="Create New Group"
                                className="B2BAdminDashboard-Button"
                                onClick={open}
                            />
                        )
                    }
                </Modal>
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <h3>Active Groups</h3>
                { renderGroups('active') }
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <h3>Archive Groups</h3>
                { renderGroups('archive') }
            </div>
        </div>
    );
};

ManageGroups.propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    groups: PropTypes.objectOf(PropTypes.object).isRequired,
    fetchGroups: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroups);
