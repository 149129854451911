import * as APIUtil from '../../../Util/Stats/B2BAdmin/ComplianceApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_B2B_ADMIN_COMPLIANCE_STATS = 'RECEIVE_B2B_ADMIN_COMPLIANCE_STATS';
export const RECEIVE_B2B_ADMIN_COMPLIANCE_STAT_ERRORS = 'RECEIVE_B2B_ADMIN_COMPLIANCE_STAT_ERRORS';
export const CLEAR_B2B_ADMIN_COMPLIANCE_STATS = 'CLEAR_B2B_ADMIN_COMPLIANCE_STATS';


export const receiveComplianceStats = (payload) => ({
  type: RECEIVE_B2B_ADMIN_COMPLIANCE_STATS,
  payload,
});

export const receiveComplianceStatErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_COMPLIANCE_STAT_ERRORS,
  errors,
});

export const clearComplianceStats = (key) => {
  return {
    type: CLEAR_B2B_ADMIN_COMPLIANCE_STATS,
    key,
  };
};

export const fetchUserParticipation = (queryString) => dispatch => (
  APIUtil.fetchUserParticipation(queryString).then(
    payload => dispatch(receiveComplianceStats(payload)),
    errors => dispatch(receiveComplianceStatErrors(ErrorResponseUtil(errors)))
  )
)

export const fetchUserPerformance = (queryString) => dispatch => (
  APIUtil.fetchUserPerformance(queryString).then(
    payload => dispatch(receiveComplianceStats(payload)),
    errors => dispatch(receiveComplianceStatErrors(ErrorResponseUtil(errors)))
  )
)
