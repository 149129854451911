import {
  RECEIVE_ACTOR_ENABLES,
  CLEAR_ENABLES,
} from '../../Actions/FlipperActions';

const FlippersReducer =( state = {}, action ) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ACTOR_ENABLES:
      return Object.assign({}, state, { actor: action.payload.features });
    case CLEAR_ENABLES:
      return {};
    default:
      return state;
  }
}

export default FlippersReducer;
