import ErrorResponseUtil from '../Util/ErrorResponseUtil';
import * as APIUtil from '../Util/InstitutionApiUtil';

export const RECEIVE_INSTITUTIONS = 'RECEIVE_INSTITUTIONS';
export const RECEIVE_INSTITUTION = 'RECEIVE_INSTITUTION';
export const RECEIVE_INSTITUTION_ERRORS = 'RECEIVE_INSTITUTION_ERRORS';
export const CLEAR_INSTITUTIONS = 'CLEAR_INSTITUTIONS';
export const CLEAR_INSTITUTION_ERRORS = 'CLEAR_INSTITUTION_ERRORS';

export const receiveInstitutions = payload => ({
    type: RECEIVE_INSTITUTIONS,
    payload,
});

export const receiveInstitution = (payload, message) => ({
    type: RECEIVE_INSTITUTION,
    payload,
    message,
});

export const receiveErrors = errors => ({
    type: RECEIVE_INSTITUTION_ERRORS,
    errors,
});

export const clearInstitutions = () => ({
    type: CLEAR_INSTITUTIONS,
});

export const clearInstitutionErrors = () => ({
    type: CLEAR_INSTITUTION_ERRORS,
});

export const fetchInstitution = (id) => dispatch => (
    APIUtil.fetchInstitution(id).then(
        payload => dispatch(receiveInstitution(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const fetchInstitutions = () => dispatch => (
    APIUtil.fetchInstitutions().then(
        payload => dispatch(receiveInstitutions(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const createInstitution = institution => dispatch => (
    APIUtil.createInstitution(institution).then(
        payload => dispatch(receiveInstitution(payload, 'Institution Created')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const editInstitution = institution => dispatch => (
    APIUtil.editInstitution(institution).then(
        payload => dispatch(receiveInstitution(payload, 'Institution Updated')),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);

export const deleteInstitution = institutionId => dispatch => (
    APIUtil.deleteInstitution(institutionId).then(
        payload => dispatch(receiveInstitution(payload)),
        errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
    )
);
