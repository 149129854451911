import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import humanizeDuration from 'humanize-duration';

import Loader from '../../../Composed/Loader/Loader';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import StackedChart from '../../../HighCharts/VerticalStackedChart/StackedChart';

import { clearLearnStats, fetchUserParticipation } from '../../../../Actions/Stats/B2BAdmin/LearnActions';

import { shortMonthsList, fullMonthsList } from '../../../../Constants/TimeConstants';
import { average, hashToQueryString } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  participations: (state.stats.b2bAdmin.learns.userParticipation || {}),
  participationsError: state.stats.b2bAdmin.learns.userParticipationError,
  filters: (state.filters || {})
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchUserParticipation: (queryString) => dispatch(fetchUserParticipation(queryString))
});

const UserParticipation = ({ participations, participationsError, filters, clearLearnStats, fetchUserParticipation }) => {
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [averageTimes, setAverageTimes] = useState({});

  let humanizeConfig = { units: ['d', 'h', 'm'], round: true, conjunction: ' & ', language: 'short', languages: { short: { d: () => 'd', h: () => 'h', m: () => 'm' } } };

  useEffect(() => {
    setLoading(true);
    setShowChart(false);
    clearLearnStats('userParticipation');
    fetchUserParticipation(hashToQueryString(filters)).then(() => setLoading(false));
  }, [clearLearnStats, fetchUserParticipation, filters]);

  useEffect(() => {
    if (!loading) {
      let newAverageTimes = {};

      Object.entries(participations).map(([level, levelData], idx) => {
        let completedUsers = Object.values(levelData.users || {}).filter(u => u.completed_at);
        let completedAverage = average(completedUsers.map(u => u.durations));
        newAverageTimes[level] = { perLevel: completedAverage, perLesson: Math.round(completedAverage / levelData.lessons) }
      });

      setAverageTimes(newAverageTimes);
    }
  }, [loading, participations]);

  useEffect(()=> {
    if (!loading)
      setShowChart(true)
  }, [loading]);

  const courseCompletionStats = () => (
    <div className="Level-Container">
      {
        Object.entries(participations).map(([level, levelData], idx) => (
          <div key={`start-complete-${idx}`} className="Level">
            <div className="Arrow-Holder">
              <h3 className="Top-Text">{`Started ${level}`}</h3>
              <div className="Arrow">
                <ImageBox src={`${idx === 0 ? 'arrow.svg' : 'arrow-2.svg'}`} className="Arrow-Icon" />
                <h4 className="Inside-Text"><span>{Object.keys(levelData.users || {}).length}</span> users</h4>
              </div>
            </div>

            <div className="Badge-Holder">
              <h3 className="Top-Text" data-tip="Courses Completed" data-for="Course-Completed-Tooltip">
                {`Completed ${level}`}
                <i className="fa fa-info" />
                <ReactTooltip id="Course-Completed-Tooltip" place="bottom" type="info" effect="solid" >
                  The number of users completed the level out of the users who started in this time period.
                  A course is marked as completed when a user views all of its slides and attempts all of its quizzes.
                </ReactTooltip>
              </h3>
              <div className="Badge">
                <ImageBox src="combo.svg" className="Badge-Icon" />
                <h4 className="Inside-Text"><span>{Object.values(levelData.users || {}).filter(cp => cp.completed_at).length}</span> users</h4>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );

  const userParticipationChart = () => {
    const userParticipationSeriesData = {};
    const monthlyStats = (!filters.timesFilter || filters.timesFilter.endsWith('year'));

    Object.values(participations).map(levelData => (
      Object.values(levelData.users || {}).map(user => {
        if (user.completed_at) {
          let dateObj = new Date(user.completed_at);
          let date = dateObj.getUTCDate();
          let shortMonth = shortMonthsList[dateObj.getUTCMonth()];
          let year = dateObj.getUTCFullYear();

          let key = monthlyStats ? shortMonth : `${date} ${shortMonth}`;

          userParticipationSeriesData[key] = userParticipationSeriesData[key] || { levelCompleted: { y: 0, fullDate: `${fullMonthsList[shortMonth]} ${monthlyStats ? '' : date}, ${year}` } }

          userParticipationSeriesData[key]['levelCompleted']['y'] += 1;
        }
      })
    ));

    const userParticipationChartData = {
      title: '',
      xAxis: Object.keys(userParticipationSeriesData),
      yAxisTitle: '',
      tooltip:  `<p class="heading">{{POINT_0_POINT_FULL_DATE}}</p>` +
                `<div class="completed">` +
                  `<span class="box" style="backgroundColor: {{POINT_0_COLOR}}"></span>` +
                  `<span class="Users">{{POINT_0_POINT_TOTAL}} Completed a Level</span>` +
                `</div>`,
      series: [
        { name: 'Completed a Level',  color: '#80cbc4', data: Object.values(userParticipationSeriesData).map(d => d.levelCompleted)}
      ]
    };

    if (userParticipationChartData.xAxis.length === 0) {
      return null;
    }

    return (
      <div className="User-Participation-Chart-Container">
        <div
          className="User-Participation-Chart VerticalStackedChart"
          id="User-Participation-Stacked-Chart"
        >
          {
            showChart &&
            <StackedChart
              id="User-Participation-Stacked-Chart"
              data={userParticipationChartData}
            />
          }
        </div>
      </div>
    )
  }

  const averageTimeStats = () => {
    let totalAvgPerLevel = Object.values(averageTimes).reduce((a, b) => a + b.perLevel, 0);
    let totalAvgPerLesson = average(Object.values(averageTimes).map(avgTime => avgTime.perLesson).filter(avgTime => avgTime));

    if (
      !(totalAvgPerLesson >= 60) &&
      !Object.values(averageTimes).find(avgTime => avgTime.perLevel >= 60) &&
      !(totalAvgPerLevel >= 60)
    ) {
      return null;
    }

    return (
      <div className="Average-Container">
        {
          (totalAvgPerLesson >= 60) &&
          <div className="Avg-Box">
            <p>Avg Time to Complete Lesson</p>
            <h4>{humanizeDuration(totalAvgPerLesson * 1000, humanizeConfig)}</h4>
          </div>
        }

        {
          Object.entries(averageTimes).map(([level, avgTime], idx) => (
            (avgTime.perLevel >= 60) &&
            <div key={`Average-Time-${idx}`} className="Avg-Box">
              <p>{`Avg Time to Complete ${level}`}</p>
              <h4>{humanizeDuration(avgTime.perLevel * 1000, humanizeConfig)}</h4>
            </div>
          ))
        }

        {
          (totalAvgPerLevel >= 60) &&
          <div className="Avg-Box">
            <p>Avg Time to Complete All Levels</p>
            <h4>{humanizeDuration(totalAvgPerLevel * 1000, humanizeConfig)}</h4>
          </div>
        }
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <h2 className="Title">User Participation</h2>
          <Loader />
        </>
      )
    }

    if (participationsError) {
      return (
        <>
          <h2 className="Title">User Participation</h2>
          <div className="No-Data">
            Oops! something went wrong.
          </div>
        </>
      )
    }

    if (Object.keys(participations).length === 0)
      return null

    return (
      <>
        <h2 className="Title">User Participation</h2>
        {courseCompletionStats()}
        {userParticipationChart()}
        {averageTimeStats()}
      </>
    )
  }

  return (
    <div className="User-Participation">
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserParticipation);
