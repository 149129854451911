import * as APIUtil from '../Util/InstitutionResourceApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_INSTITUTION_RESOURCES = 'CLEAR_INSTITUTION_RESOURCES';
export const RECEIVE_INSTITUTION_RESOURCES = 'RECEIVE_INSTITUTION_RESOURCES';
export const RECEIVE_INSTITUTION_RESOURCE = 'RECEIVE_INSTITUTION_RESOURCE';
export const RECEIVE_INSTITUTION_RESOURCE_ERRORS = 'RECEIVE_INSTITUTION_RESOURCE_ERRORS';

export const clearInstitutionResources = () => ({
  type: CLEAR_INSTITUTION_RESOURCES,
});

export const receiveInstitutionResources = payload => ({
  type: RECEIVE_INSTITUTION_RESOURCES,
  payload,
});

export const receiveInstitutionResource = payload => ({
  type: RECEIVE_INSTITUTION_RESOURCE,
  payload,
});

export const receiveInstitutionResourceErrors = errors => ({
  type: RECEIVE_INSTITUTION_RESOURCE_ERRORS,
  errors,
});

export const fetchInstitutionResources = (institutionId, queryString) => dispatch => (
  APIUtil.fetchInstitutionResources(institutionId, queryString).then(
    payload => dispatch(receiveInstitutionResources(payload)),
    errors  => dispatch(receiveInstitutionResourceErrors(ErrorResponseUtil(errors))),
  )
);

export const createInstitutionResource = (formData) => dispatch => (
  APIUtil.createInstitutionResource(formData).then(
    payload => dispatch(receiveInstitutionResource(payload)),
    errors  => dispatch(receiveInstitutionResourceErrors(ErrorResponseUtil(errors))),
  )
);

export const updateInstitutionResource = (formData) => dispatch => (
  APIUtil.updateInstitutionResource(formData).then(
    payload => dispatch(receiveInstitutionResource(payload)),
    errors  => dispatch(receiveInstitutionResourceErrors(ErrorResponseUtil(errors))),
  )
);
