import { connect } from 'react-redux';

import TrialPage from './TrialPage';
import { updateAccount } from '../../../Actions/SessionActions';

const mapStateToProps = (state) => ({
    currentUser: state.session.currentUser,
    subscribedCourses: state.dashboard.courses,
});

const mapDispatchToProps = dispatch => ({
    updateAccount: user => dispatch(updateAccount(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialPage);
