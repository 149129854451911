import * as ApiUtil from '../Util/ManageAccessApiUtil';
import { updateAccount } from '../Util/SessionApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_USER_COURSES = 'RECEIVE_USER_COURSES';
export const CLEAR_USER_COURSES = 'CLEAR_USER_COURSES';
export const RECEIVE_USER_COURSES_ERRORS = 'RECEIVE_USER_COURSES_ERRORS';
export const RECEIVE_SUBSCRIPTION_CONFIRMATION = 'RECEIVE_SUBSCRIPTION_CONFIRMATION';

export const recieveUserCourses = (payload, message) => ({
    type: RECEIVE_USER_COURSES,
    payload,
    message,
});

export const receiveUserCoursesErrors = errors => ({
    type: RECEIVE_USER_COURSES_ERRORS,
    errors,
});

export const receiveSubscriptionConfirmation = payload => ({
    type: RECEIVE_SUBSCRIPTION_CONFIRMATION,
    payload,
});

export const clearUserCourses = () => ({
    type: CLEAR_USER_COURSES,
});

export const getUserCourses = user => dispatch => (
    ApiUtil.getUserCourses(user).then(
        payload => dispatch(recieveUserCourses(payload, 'User Found!')),
        errors => dispatch(receiveUserCoursesErrors(ErrorResponseUtil(errors))),
    )
);

export const updateUser = user => dispatch => (
    updateAccount(user).then(
        payload => dispatch(recieveUserCourses(payload, 'User Updated!')),
        errors => dispatch(receiveUserCoursesErrors(ErrorResponseUtil(errors))),
    )
);

export const addSubscription = subscription => dispatch => (
    ApiUtil.addSubscription(subscription).then(
        payload => dispatch(receiveSubscriptionConfirmation(payload)),
        errors => dispatch(receiveUserCoursesErrors(ErrorResponseUtil(errors))),
    )
);
