import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

import ButtonBox from '../../../../Basic/ButtonBox/ButtonBox';
import RadioBox from '../../../../Basic/RadioBox/RadioBox';
import CheckBox from '../../../../Basic/CheckBox/CheckBox';
import AutoComplete from '../../../../Composed/AutoComplete/AutoComplete';

import { daysCategoriesList, weekDaysList, timeZonesList } from '../../../../../Constants/TimeConstants';

import './StreakConfig.scss'

const StreakConfig = ({ createStreakConfig }) => {
  const durations = [5, 10, 15, 20, 30, 60, 120];
  const computedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [daysCategory, setDaysCategory] = useState(Object.keys(daysCategoriesList)[0]);
  const [streakConfigAttrs, setStreakConfigAttrs] = useState({ days: daysCategoriesList[daysCategory]['dayNumbers'], duration: 20, timezone: computedTimezone, opt_in: { global: true, institutional: true } });
  const [showForm, setShowForm] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [streakConfigErrors, setStreakConfigErrors] = useState({});

  const handleDaysCategoryChange = (value) => {
    setDaysCategory(value);
    setStreakConfigAttrs({ ...streakConfigAttrs, days: daysCategoriesList[value]['dayNumbers'] });
  };

  const handleCustomDaySelect = (value) => {
    const newDays = streakConfigAttrs.days;

    const idx = newDays.indexOf(value);
    (idx > -1) ? newDays.splice(idx, 1) : newDays.push(value);

    setStreakConfigAttrs({ ...streakConfigAttrs, days: newDays.sort() });
  }

  const validateForm = () => {
    let errors = {}

    if (streakConfigAttrs.timezone === '') {
      errors['timezone'] = 'Please select your timezone';
    }

    if (streakConfigAttrs.days.length < 5) {
      errors['days'] = 'Please select at least 5 days';
    }

    if (streakConfigAttrs.duration === '') {
      errors['duration'] = 'Please select a duration';
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm();

    setStreakConfigErrors(errors);

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      createStreakConfig(streakConfigAttrs).then(() => {
        setSubmitting(false);
        setShowForm(false);
      });
    }
  };

  if (!showForm) {
    return null;
  }

  return (
    <div className="StreakConfig">
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header Modal-Title">
            <h3 className="Title">
              Hi there,
            </h3>

            <div className="Description">
              Learning goals are best achieved when you set aside just a little bit of time on a regular basis.
            </div>

            <div className="Description">
              To start your streak, set a goal here.
            </div>
          </div>

          <div className="modal-body">
            <div className="Errors-Container">
              {
                (Object.keys(streakConfigErrors).length > 0) &&
                <ul className="Errors list-disc pl-10">
                  {
                    Object.entries(streakConfigErrors).map(([k, v], idx) => (
                      <li key={`error-${idx}`} children={v} />
                    ))
                  }
                </ul>
              }
            </div>

            <div className="Input-Container">
              <div className="Label">
                Timezone
                <div className="ToolTip" data-tip data-for="timezone-tooltip">
                  <i className="fa fa-question" />
                  <ReactTooltip
                    id="timezone-tooltip"
                    type="info"
                    place={isMobile ? 'top' : 'right'}
                    effect="solid"
                    children="Your study timezone."
                  />
                </div>
              </div>

              <div className="Select-List">
                <AutoComplete
                  className="TimeZone"
                  options={Object.entries(timeZonesList).map(([value, label]) => { return { value, label } })}
                  defaultValue={{ value: streakConfigAttrs.timezone, label: timeZonesList[streakConfigAttrs.timezone] }}
                  onChange={(e) => setStreakConfigAttrs({ ...streakConfigAttrs, timezone: e.value })}
                  isMulti={false}
                  styles={{ fontSize: '18px' }}
                />
              </div>
            </div>

            <div className="Input-Container">
              <div className="Label">
                Study Days
                <div className="ToolTip" data-tip data-for="days-tooltip">
                  <i className="fa fa-question" />
                  <ReactTooltip
                    id="days-tooltip"
                    type="info"
                    place={isMobile ? 'top' : 'right'}
                    effect="solid"
                    children="Your preferred study days."
                  />
                </div>
              </div>
              <div className="Radio-Buttons">
                {
                  Object.entries(daysCategoriesList).map(([k, v], idx) => (
                    <RadioBox
                      key={`days-category-${idx}`}
                      className="Radio-Button"
                      name="days_category"
                      checked={daysCategory === k}
                      value={k}
                      text={v.text}
                      onChange={(e) => handleDaysCategoryChange(e.currentTarget.value)}
                    />
                  ))
                }
              </div>

              {
                daysCategory === 'custom_days' &&
                <div className="Selection-Buttons">
                  {
                    Object.entries(weekDaysList).map(([k, v], idx) => (
                      <ButtonBox
                        key={`custom-day-${idx}`}
                        className={`Selection-Button ${streakConfigAttrs.days.includes(parseInt(k)) && 'Active'}`}
                        text={v}
                        onClick={() => handleCustomDaySelect(parseInt(k))}
                      />
                    ))
                  }
                </div>
              }
            </div>

            <div className="Input-Container">
              <div className="Label">
                Study Duration
                <div className="ToolTip" data-tip data-for="duration-tooltip">
                  <i className="fa fa-question" />
                  <ReactTooltip
                    id="duration-tooltip"
                    type="info"
                    place={isMobile ? 'top' : 'right'}
                    effect="solid"
                    children="Your preferred duration of study on the selected days."
                  />
                </div>
              </div>
              <div className="Selection-Buttons">
                {
                  durations.map((duration, idx) => (
                    <ButtonBox
                      key={`duration-${idx}`}
                      className={`Selection-Button ${(streakConfigAttrs.duration === duration) && 'Active'}`}
                      text={duration >= 60 ? `${duration / 60} hr` : `${duration} mins`}
                      onClick={() => setStreakConfigAttrs({ ...streakConfigAttrs, duration: duration })}
                    />
                  ))
                }
              </div>
            </div>

            <div className="Checkbox-Container">
              <div className="Label">
                Leaderboard Opt-In
                <div className="ToolTip" data-tip data-for="opt-in-tooltip">
                  <i className="fa fa-question" />
                  <ReactTooltip
                    id="opt-in-tooltip"
                    type="info"
                    place={isMobile ? 'top' : 'right'}
                    effect="solid"
                    children="By opting into the leaderboard, your login will be included on Global and Institutional leaderboard rankings of all CanopyLearn students. By opting out, your login will be anonymized. It's recommended (and fun!) to opt in."
                  />
                </div>
              </div>
              <CheckBox
                className="Checkbox"
                text="Yes"
                checked={streakConfigAttrs.opt_in.global && streakConfigAttrs.opt_in.institutional}
                onClick={() => setStreakConfigAttrs({ ...streakConfigAttrs, opt_in: { global: !streakConfigAttrs.opt_in.global, institutional: !streakConfigAttrs.opt_in.institutional } })}
              />
            </div>
          </div>

          <div className="modal-footer">
            <ButtonBox
              className="Primary-Btn Submit-Button"
              text={<span className="Primary-Btn-Text">Submit</span>}
              disabled={submitting}
              onClick={(e) => handleSubmit(e)}
            />

            <ButtonBox
              className="Secondary-Btn Close-Button"
              text={<span className="Secondary-Btn-Text">Close</span>}
              disabled={submitting}
              onClick={() => setShowForm(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

StreakConfig.propTypes = {
  createStreakConfig: PropTypes.func.isRequired,
}

export default StreakConfig;
