import { connect } from 'react-redux';

import NewPasswordPage from './NewPasswordPage';
import { setNewPassword } from '../../../Actions/SessionActions';

const mapStateToProps = (state, ownProps) => ({
    errors: state.errors.session,
    token: ownProps.match.params.token,
});

const mapDispatchToProps = (dispatch) => ({
    setNewPassword: user => dispatch(setNewPassword(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);