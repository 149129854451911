import { checkExistingUser as checkExistingUserApi } from '../Util/CreateUserApiUtil';
import * as ModifyUserApi from '../Util/ModifyUserApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION = 'RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION';
export const RECEIVE_CHECK_USER_ERRORS_FOR_MODIFICATION = 'RECEIVE_CHECK_USER_ERRORS_FOR_MODIFICATION';
export const RECEIVE_MODIFY_USER_CONFIRMATION = 'RECEIVE_MODIFY_USER_CONFIRMATION';
export const RECEIVE_MODIFY_USER_ERRORS = 'RECEIVE_MODIFY_USER_ERRORS';
export const CLEAR_MODIFY_USER_ERRORS = 'CLEAR_MODIFY_USER_ERRORS';

export const clearModifyUserErrors = () => ({
    type: CLEAR_MODIFY_USER_ERRORS,
});

export const receiveCheckUserConfirmationForModification = payload => ({
    type: RECEIVE_CHECK_USER_CONFIRMATION_FOR_MODIFICATION,
    message: payload?.message,
    exists: payload?.exists,
    user: payload?.user,
});

export const receiveCheckUserErrorsForModification = errors => ({
    type: RECEIVE_CHECK_USER_ERRORS_FOR_MODIFICATION,
    errors,
});

export const receiveModifyUserConfirmation = payload => ({
    type: RECEIVE_MODIFY_USER_CONFIRMATION,
    message: payload?.message,
});

export const receiveModifyUserErrors = errors => ({
    type: RECEIVE_MODIFY_USER_ERRORS,
    errors,
});

export const deleteUser = user => dispatch => (
    ModifyUserApi.deleteUser(user).then(
        payload => dispatch(receiveModifyUserConfirmation(payload)),
        errors => dispatch(receiveModifyUserErrors(ErrorResponseUtil(errors))),
    )
);

export const updateUserType = (user) => dispatch => (
    ModifyUserApi.updateUserType(user).then(
        payload => dispatch(receiveModifyUserConfirmation(payload)),
        errors => dispatch(receiveModifyUserErrors(ErrorResponseUtil(errors))),
    )
);

export const confirmUser = user => dispatch => (
    ModifyUserApi.confirmUser(user).then(
        payload => dispatch(receiveModifyUserConfirmation(payload)),
        errors => dispatch(receiveModifyUserErrors(ErrorResponseUtil(errors))),
    )
);

export const archiveUser = userId => dispatch => (
    ModifyUserApi.archiveUser(userId).then(
        payload => dispatch(receiveModifyUserConfirmation(payload)),
        errors => dispatch(receiveModifyUserErrors(ErrorResponseUtil(errors))),
    )
);

export const overidePassword = user => dispatch => (
    ModifyUserApi.overidePassword(user).then(
        payload => dispatch(receiveModifyUserConfirmation(payload)),
        errors => dispatch(receiveModifyUserErrors(ErrorResponseUtil(errors))),
    )
);

export const checkExistingUser = user => dispatch => (
    checkExistingUserApi(user).then(
        payload => dispatch(receiveCheckUserConfirmationForModification(payload)),
        errors => dispatch(receiveCheckUserErrorsForModification(ErrorResponseUtil(errors))),
    )
);
